import { updatePharmacyManagementError, getPharmacyManagementSuccess, getEditHistorySuccess, getEditHistoryFailure, getRemovePharmacyReasonsSuccess, getRemovePharmacyReasonsError, downloadEditHistorySuccess, downloadEditHistoryFailure } from '../pharmacymanagement/actioncreators';
import { fetchDownloadEditHistory, fetchEditHistoryInfo, fetchPharmacyManagement, fetchRemovePharmacyReasons } from '../../../api/coveredentity/pharamacymanagement/PharmacyRequests';
import { call, put, all, takeEvery, select } from 'redux-saga/effects';
import { DOWNLOAD_EDIT_HISTORY, GET_EDIT_HISTORY_INFO, GET_PHARMACY_MANAGEMENT, GET_REMOVE_PHARMACY_REASONS, UPDATE_PHARMACY_MANAGEMENT_FILTERS, UPDATE_PHARMACY_MANAGEMENT_PAGE, UPDATE_PHARMACY_MANAGEMENT_SORT_FILTERS } from '../pharmacymanagement/constants';
import { RootState } from '../../RootState';
import { API_PAGE_SIZE, UI_PAGE_SIZE } from '../../applicationConstants';
import { canUseCachedRecords, getApiPageToFetch } from '../../../utils/datatableHelper';
import { getActiveHealthSystemId } from '../../teammanagement/selectors';
import { AssociatedPharmaciesFiltersModel } from './types';
import FileDownload from 'js-file-download';
import { formatMMDDYYYY } from '../../../utils/dateHelper';

export const getPharmacyManagementState = (state: RootState) => state.PharmacyManagementState.associatedPharmaciesModel;
export const getPharmacyManagementFilters = (state: RootState) => state.PharmacyManagementState.associatedPharmaciesModel.filters

const getTableFilters = (pharmacyManagementFilters: AssociatedPharmaciesFiltersModel) => {
  const filters: any = [];

  Object.keys(pharmacyManagementFilters).forEach(key => {
    const val = (pharmacyManagementFilters as { [key: string]: any })[key];
    if (Array.isArray(val)) {
      filters.push({ 'field': key, 'operator': 'in', 'value': val.join() })
    } else if (val) {
      if (key === 'startDate' || key === 'endDate' || key === 'termStartDate' || key === 'termEndDate') {
        const dateUTCString = new Date(val).toUTCString();
        const dateUTC = new Date(dateUTCString);
        const operator = key === 'startDate' || key === 'termStartDate' ? 'gte' : 'lte';
        const field = key === 'startDate' || key === 'endDate' ? 'startDate' : 'termDate';

        filters.push({ 'field': field, 'operator': operator, 'value': dateUTC })
      } else {
        filters.push({ 'field': key, 'operator': 'like', 'value': val })
      }
    }
  })

  const filterQueryString = filters.map((obj: any) => `Filters=${JSON.stringify(obj)}`).join('&');

  return filterQueryString;
}

export function* getPharmacyManagementSaga () {
  try {
    const activeHealthSystemId = yield select(getActiveHealthSystemId);
    const { sortBy, isSortDesc, currentPage, id340B } = yield select(getPharmacyManagementState);
    const pageToFetch = getApiPageToFetch(currentPage, UI_PAGE_SIZE, API_PAGE_SIZE);
    const pharmacyManagementFilters = yield select(getPharmacyManagementFilters);
    const filterQuery = getTableFilters(pharmacyManagementFilters);
    const response = yield call(fetchPharmacyManagement, sortBy, isSortDesc, pageToFetch, id340B, activeHealthSystemId, filterQuery);
    yield put(getPharmacyManagementSuccess(response));
  }
  catch (e) {
    yield put(updatePharmacyManagementError(e))
  }
}

export function* getEditHistoryInfoSaga () {
  try {
    const response = yield call(fetchEditHistoryInfo);
    yield put(getEditHistorySuccess(response));
  }
  catch (e) {
    yield put(getEditHistoryFailure())
  }
}

export function* downloadEditHistorySaga () {
  try {
    const currentDate = formatMMDDYYYY(Date.now());
    const { id340B, coveredEntityName } = yield select(getPharmacyManagementState);
    const response = yield call(fetchDownloadEditHistory, id340B, coveredEntityName);
    yield put(downloadEditHistorySuccess());
    FileDownload(response, `PharmacyHistory_${id340B}_${currentDate}.csv`)
  }
  catch (e) {
    yield put(downloadEditHistoryFailure());
  }
}

export function* checkPageFetchPharmacyManagementSaga () {
  const { currentPage, currentApiPage } = yield select(getPharmacyManagementState);

  if (!canUseCachedRecords(currentPage, currentApiPage, UI_PAGE_SIZE, API_PAGE_SIZE)) {
    yield (getPharmacyManagementSaga());
  }
}

export function* getRemovePharmacyReasonsSaga () {
  try {
    const response = yield call(fetchRemovePharmacyReasons);
    yield put(getRemovePharmacyReasonsSuccess(response));
  }
  catch (e) {
    yield put(getRemovePharmacyReasonsError(e.message))
  }
}

function* watchGetPharmacyManagement () {
  yield takeEvery([GET_PHARMACY_MANAGEMENT, UPDATE_PHARMACY_MANAGEMENT_SORT_FILTERS, UPDATE_PHARMACY_MANAGEMENT_FILTERS], getPharmacyManagementSaga)
}

function* watchGetEditHistoryInfo () {
  yield takeEvery(GET_EDIT_HISTORY_INFO, getEditHistoryInfoSaga)
}

function* watchPharmacyManagementChange () {
  yield takeEvery(UPDATE_PHARMACY_MANAGEMENT_PAGE, checkPageFetchPharmacyManagementSaga);
}

function* watchGetRemovePharmacyReasons () {
  yield takeEvery(GET_REMOVE_PHARMACY_REASONS, getRemovePharmacyReasonsSaga);
}

function* watchDowloadEditHistory () {
  yield takeEvery(DOWNLOAD_EDIT_HISTORY, downloadEditHistorySaga)

}

export default function* watchAllPharmacyManagement () {
  yield all([
    watchGetPharmacyManagement(),
    watchPharmacyManagementChange(),
    watchGetEditHistoryInfo(),
    watchGetRemovePharmacyReasons(),
    watchDowloadEditHistory()
  ])
}
