import { BusinessInfoModel, ErrorModel, UserInfoModel } from '../applicationTypes';

export const GET_BUSINESS_INFORMATION_REQUEST = 'GET_BUSINESS_INFORMATION_REQUEST';
export const GET_BUSINESS_INFORMATION_SUCCESS = 'GET_BUSINESS_INFORMATION_SUCCESS';
export const GET_BUSINESS_INFORMATION_ERROR = 'GET_BUSINESS_INFORMATION_ERROR';

export const GET_USER_INFORMATION_REQUEST = 'GET_USER_INFORMATION_REQUEST';
export const GET_USER_INFORMATION_SUCCESS = 'GET_USER_INFORMATION_SUCCESS';
export const GET_USER_INFORMATION_ERROR = 'GET_USER_INFORMATION_ERROR';

interface GetBusinessInfoRequest {
  type: typeof GET_BUSINESS_INFORMATION_REQUEST
}

interface GetBusinessInfoSuccess {
  type: typeof GET_BUSINESS_INFORMATION_SUCCESS,
  payload: BusinessInfoModel
}

interface GetBusinessInfoError {
  type: typeof GET_BUSINESS_INFORMATION_ERROR,
  payload: ErrorModel
}

interface GetUserInfoRequest {
  type: typeof GET_USER_INFORMATION_REQUEST
}

interface GetUserInfoSuccess {
  type: typeof GET_USER_INFORMATION_SUCCESS,
  payload: UserInfoModel
}

interface GetUserInfoError {
  type: typeof GET_USER_INFORMATION_ERROR,
  payload: ErrorModel
}

export type AccountInfoActionTypes =
GetBusinessInfoError |
GetBusinessInfoSuccess |
GetBusinessInfoRequest |
GetUserInfoRequest |
GetUserInfoSuccess |
GetUserInfoError;
