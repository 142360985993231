export function setSuggestions () {
  const script_three = document.createElement('script');
  script_three.src = '/zenDesk.js'
  script_three.type = 'text/javascript'
  document.body.appendChild(script_three);
}
  
export const settings = {
  webWidget: {
    helpCenter: {
      filter: {
        label_names: 'Request'
      }
    }
  }
}
  