import { PharmacyManagementActionTypes,
  GET_PHARMACY_MANAGEMENT,
  GET_PHARMACY_MANAGEMENT_SUCCESS,
  GET_PHARMACY_MANAGEMENT_ERROR,
  UPDATE_PHARMACY_MANAGEMENT_SORT_FILTERS,
  UPDATE_PHARMACY_MANAGEMENT_PAGE,
  GET_EDIT_HISTORY_INFO_FAILURE,
  GET_EDIT_HISTORY_INFO_SUCCESS,
  GET_EDIT_HISTORY_INFO,
  CLEAR_PHARMACY_MANAGEMENT,
  GET_REMOVE_PHARMACY_REASONS,
  GET_REMOVE_PHARMACY_REASONS_SUCCESS,
  GET_REMOVE_PHARMACY_REASONS_ERROR,
  UPDATE_GPOWAC_INDEX,
  UPDATE_340BPAY_INDEX,
  UPDATE_PHARMACY_MANAGEMENT_FILTERS,
  DOWNLOAD_EDIT_HISTORY,
  DOWNLOAD_EDIT_HISTORY_SUCCESS,
  DOWNLOAD_EDIT_HISTORY_FAILURE
} from './constants';
import { ErrorModel } from '../../applicationTypes';
import { AssociatedPharmaciesFiltersModel, PharmacyEditHistoryModel, PharmacyManagementDetails, PharmacyManagementResponse } from '../pharmacymanagement/types';
import { SortingFilter } from '../../appcommon/types';


export function getPharmacyManagement (id340B: string): PharmacyManagementActionTypes {
  return {
    type: GET_PHARMACY_MANAGEMENT,
    payload: id340B
  }
}

export function getPharmacyManagementSuccess (pharmacyManagementResponse: PharmacyManagementResponse): PharmacyManagementActionTypes {
  return {
    type: GET_PHARMACY_MANAGEMENT_SUCCESS,
    payload: pharmacyManagementResponse
  }
}

export function updatePharmacyManagementError (errorModel: ErrorModel) : PharmacyManagementActionTypes {
  return {
    type: GET_PHARMACY_MANAGEMENT_ERROR,
    payload: errorModel
  }
}

export function getEditHistory (): PharmacyManagementActionTypes {
  return {
    type: GET_EDIT_HISTORY_INFO,
  }
}
export function getEditHistorySuccess (pharmacyEditHistoryModel: PharmacyEditHistoryModel): PharmacyManagementActionTypes {
  return {
    type: GET_EDIT_HISTORY_INFO_SUCCESS,
    payload: pharmacyEditHistoryModel
  }
}
export function getEditHistoryFailure (): PharmacyManagementActionTypes {
  return {
    type: GET_EDIT_HISTORY_INFO_FAILURE,
  }
}
export function updatePharmacyManagementSortFilters (sortFilters: SortingFilter): PharmacyManagementActionTypes {
  return {
    type: UPDATE_PHARMACY_MANAGEMENT_SORT_FILTERS,
    payload: sortFilters
  }
}

export function updatePharmacyManagementPage (pageNumber: number): PharmacyManagementActionTypes {
  return {
    type: UPDATE_PHARMACY_MANAGEMENT_PAGE,
    payload: pageNumber
  }
}

export function clearPharmacyManagement (): PharmacyManagementActionTypes {
  return {
    type: CLEAR_PHARMACY_MANAGEMENT
  }
}

export function updateGPOWACIndex (index: number, pharmacy: PharmacyManagementDetails): PharmacyManagementActionTypes {
  return {
    type: UPDATE_GPOWAC_INDEX,
    payload: { index, pharmacy }
  }
}

export function update340BPayIndex (index: number, pharmacy: PharmacyManagementDetails): PharmacyManagementActionTypes {
  return {
    type: UPDATE_340BPAY_INDEX,
    payload: { index, pharmacy }
  }
}
export function getRemovePharmacyReasons (): PharmacyManagementActionTypes {
  return {
    type: GET_REMOVE_PHARMACY_REASONS
  }
}

export function getRemovePharmacyReasonsSuccess (removeReasons: string[]): PharmacyManagementActionTypes {
  return {
    type: GET_REMOVE_PHARMACY_REASONS_SUCCESS,
    payload: removeReasons
  }
}

export function getRemovePharmacyReasonsError (errorText: string): PharmacyManagementActionTypes {
  return {
    type: GET_REMOVE_PHARMACY_REASONS_ERROR,
    payload: errorText
  }
}

export function updatePharmacyManagementFilters (filters: AssociatedPharmaciesFiltersModel) {
  return {
    type: UPDATE_PHARMACY_MANAGEMENT_FILTERS,
    payload: filters
  }
}

export function downloadEditHistory () {
  return {
    type: DOWNLOAD_EDIT_HISTORY,
  }
}
export function downloadEditHistorySuccess () {
  return {
    type: DOWNLOAD_EDIT_HISTORY_SUCCESS,
  }
}
export function downloadEditHistoryFailure () {
  return {
    type: DOWNLOAD_EDIT_HISTORY_FAILURE,
  }
}