import React from 'react';
import { Modal } from 'semantic-ui-react';

import '../../../themes/kalderos/Common/Modals/StandardModal.scss';



export interface StandardModalProps {
  children: React.ReactNode;
  handleModalClose: () => void;
}

export const StandardModal: React.FunctionComponent<StandardModalProps> = (props: StandardModalProps) => {

  return (
    <Modal open={true} size={'large'} onClose={props.handleModalClose} className="standard-modal">
      {props.children}
    </Modal>
  )
}


export default StandardModal
