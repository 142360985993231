import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import cx from 'classnames';
import { Menu, Segment, Popup } from 'semantic-ui-react';
import { RootState } from '../../store/RootState';
import PageHeader from '../common/navigation/PageHeader';
import SubmitMenu from './SubmitMenu';
import FileUpload from './FileUpload/FileUpload';
import { connect } from 'react-redux';
import { REQUEST_CENTER_TAB } from '../../store/requestcenter/constants';
import RequestForm from './RequestForm/RequestForm';
import RestrictedByRole from '../teammanagement/RestrictedByRole';
import { RECIPIENT_TEAM_MANAGEMENT_USER_ROLES } from '../../store/teammanagement/constants';
import { hasVerifiedFundingSource } from '../../store/teammanagement/selectors';
import { isAdminAllAccess } from '../../utils/roleUtil'
import RequestCenterTransactions from './RequestCenterTransactions';
import { isRequestCenterTransactionsLoading } from '../../store/requestcenter/selectors';
import { getRequestCenterTransactionCounts, getRequestCenterTransactions } from '../../store/requestcenter/actionCreators';

const RecipientRequestCenterTabMap: { [key: string]: number } = {
  'all': REQUEST_CENTER_TAB.ALL,
  'awaiting': REQUEST_CENTER_TAB.AWAITING,
  'processing': REQUEST_CENTER_TAB.PROCESSING,
  'processed': REQUEST_CENTER_TAB.PROCESSED,
}

export function getRecipientRequestCenterTabIndex (path: string): number {
  return RecipientRequestCenterTabMap[path.substr(path.lastIndexOf('/') + 1)];
}

export interface RequestCenterProps extends RouteComponentProps {
  activeIndex: REQUEST_CENTER_TAB;
  isLoading: boolean;
  onboardingCompleted: boolean;
  hasVerifiedFundingSource: boolean;
  healthSystemId: number;
  userRoles: string[] | null;
  getRequestCenterTransactionCounts: (tab: REQUEST_CENTER_TAB) => void;
  getRequestCenterTransactions: (tab: REQUEST_CENTER_TAB) => void;
}

interface RequestCenterState {
  isFileUploadOpen: boolean;
  isRequestFormOpen: boolean;
}


export class RequestCenter extends React.Component<RequestCenterProps, RequestCenterState> {
  constructor (props: Readonly<RequestCenterProps>) {
    super(props);
    this.state = {
      isFileUploadOpen: false,
      isRequestFormOpen: false,
    };
  }

  reloadData = () =>{
    if (this.props.activeIndex === REQUEST_CENTER_TAB.AWAITING || this.props.activeIndex === REQUEST_CENTER_TAB.ALL) {
      if (this.props.activeIndex === REQUEST_CENTER_TAB.AWAITING) {
        this.props.getRequestCenterTransactionCounts(this.props.activeIndex);
      }
      this.props.getRequestCenterTransactions(this.props.activeIndex)
    } else {
      this.handleTabChange(REQUEST_CENTER_TAB.ALL);
    }
  }

  openFileUploadForm = () => {
    this.setState({ isFileUploadOpen: true });
  }

  closeFileUploadForm = (isSuccess: boolean) => {
    this.setState({ isFileUploadOpen: false, });

    if (isSuccess) {
      this.reloadData();
    }
  }

  openRequestForm = () => {
    this.setState({ isRequestFormOpen: true });
  }

  closeRequestForm = (isSuccess: boolean) => {
    this.setState({ isRequestFormOpen: false });

    if (isSuccess) {
      this.reloadData();
    }
  }

  handleTabChange = (index: number) => {
    const path = Object.keys(RecipientRequestCenterTabMap).find(k => RecipientRequestCenterTabMap[k] === index);
    this.props.history.push(`/recipients/requestcenter/${path}`);
  }

  render () {
    const { activeIndex, onboardingCompleted, hasVerifiedFundingSource, userRoles } = this.props;

    const submitAllowed = onboardingCompleted && hasVerifiedFundingSource;
    const submitDisabled = this.props.isLoading || !submitAllowed || isAdminAllAccess(userRoles || []);

    let popupContent = '';
    if (!onboardingCompleted) {
      popupContent = 'Discount Requests cannot be submitted until your business has completed onboarding.';
    } else if (!hasVerifiedFundingSource) {
      popupContent = 'Discount Requests cannot be submitted until a bank account has been linked and verified.';
    }

    return (
      <div className={cx('recipient-request-center', { 'y-scroll-hidden': this.props.isLoading })}>
        <div className="recipient-request-center-circles-container">
          <div className="recipient-request-center-header-div">
            <PageHeader noBorder text='Discount Requests' />
            <RestrictedByRole allowedRoles={[RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.SUBMITTER, RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN]}>
              <Popup
                disabled={submitAllowed}
                inverted
                hoverable
                position='bottom center'
                size='tiny'
                content={popupContent}
                trigger={
                  <SubmitMenu
                    onFileUploadClick={this.openFileUploadForm}
                    onManualEntryClick={this.openRequestForm}
                    disabled={submitDisabled}
                  />
                }
              />
            </RestrictedByRole>
          </div>
          <div className="recipient-request-center-info-circle3"></div>
          <div className="recipient-request-center-info-circle4"></div>
          <FileUpload isOpen={this.state.isFileUploadOpen} onClose={this.closeFileUploadForm} />
          <RequestForm isOpen={this.state.isRequestFormOpen} onClose={this.closeRequestForm} />

          {/* Fake the Semantic Tab layout */}
          <div className="tab-controller recipient-request-center-tabs">
            <Menu secondary pointing>
              <Menu.Item
                name='All'
                className="disReqAllTab_Pendo"
                active={activeIndex === REQUEST_CENTER_TAB.ALL}
                disabled={this.props.isLoading}
                onClick={() => this.handleTabChange(REQUEST_CENTER_TAB.ALL)}
              />
              <Menu.Item
                name='Awaiting Processing'
                className="disReqAwaitingProcessingTab_Pendo"
                active={activeIndex === REQUEST_CENTER_TAB.AWAITING}
                disabled={this.props.isLoading}
                onClick={() => this.handleTabChange(REQUEST_CENTER_TAB.AWAITING)}
              />
              <Menu.Item
                name='Processing'
                className="disReqProcessingTab_Pendo"
                active={activeIndex === REQUEST_CENTER_TAB.PROCESSING}
                disabled={this.props.isLoading}
                onClick={() => this.handleTabChange(REQUEST_CENTER_TAB.PROCESSING)}
              />
              <Menu.Item
                name='Processed'
                className="disReqProcessedTab_Pendo"
                active={activeIndex === REQUEST_CENTER_TAB.PROCESSED}
                disabled={this.props.isLoading}
                onClick={() => this.handleTabChange(REQUEST_CENTER_TAB.PROCESSED)}
              />
            </Menu>
            <Segment className="active tab">
              <RequestCenterTransactions tab={this.props.activeIndex} />
            </Segment>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  counts: state.RequestCenterState.counts,
  isLoading: isRequestCenterTransactionsLoading(state),
  onboardingCompleted: state.OnboardingState.onboardingStatusModel.onboardingCompleted,
  hasVerifiedFundingSource: hasVerifiedFundingSource(state),
  healthSystemId: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.healthSystemId,
  userRoles: state.LoginState.user.roles
});

const mapDispatchToProps = (dispatch: any) => ({
  getRequestCenterTransactionCounts: (tab: REQUEST_CENTER_TAB) => dispatch(getRequestCenterTransactionCounts(tab)),
  getRequestCenterTransactions: (tab: REQUEST_CENTER_TAB) => dispatch(getRequestCenterTransactions(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestCenter)
