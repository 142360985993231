import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import {
  GET_REMITTANCE_INVOICE_DETAILS,
  GET_REMITTANCE_MONTH_SUMMARY,
  GET_REMITTANCE_PERIOD_DETAILS,
  GET_REMITTANCE_SUMMARY_PERIODS,
  GET_REMITTANCE_TRANSACTION_COUNTS,
  GET_REMITTANCE_TRANSACTIONS,
  REMITTANCE_DETAILS_TAB,
  UI_PAGE_SIZE,
  API_PAGE_SIZE
} from './constants';
import {
  RemittanceMonthSummary,
  RemittanceSummaryPeriodByYear,
  RemittanceTransactionsModel,
  RemittancePeriodSummary,
  RemittanceDetailsCountsModel,
  RemittancePeriodSummaryDetail
} from './types';
import {
  fetchRemittanceSummaryPeriods,
  fetchRemittanceSummaryByMonth,
  fetchRemittanceSummaryByInvoice,
  fetchRemittanceDetailCounts,
  fetchRemittanceDetailTransactions,
} from '../../api/remittance/RemittanceAdvice';
import {
  getRemittanceTransactionsCountsFailure,
  getRemittanceTransactionsCountsSuccess,
  getRemittancePeriodDetailsFailure,
  getRemittancePeriodDetailsSuccess,
  getRemittanceMonthSummaryFailure,
  getRemittanceMonthSummarySuccess,
  getRemittanceSummaryPeriodsFailure,
  getRemittanceSummaryPeriodsSuccess,
  getRemittanceTransactionsFailure,
  getRemittanceTransactionsSuccess,
  getRemittanceInvoiceDetailsSuccess,
  getRemittanceInvoiceDetailsFailure,
} from './actionCreators';
import { RootState } from '../RootState';
import { getApiPageToFetch } from '../../utils/datatableHelper';
import { getActiveHealthSystemId } from '../teammanagement/selectors';
import { DiscountRequestResponseModel } from '../transactionscommon/types';
import logRocketUtil from '../../utils/logRocketUtil';
import { addDiscountRequestAdditionalFields } from '../transactionscommon/sagas';

const getRemittanceTransactionsState = (state: RootState) => state.RemittanceState.transactions;

const getRemittanceDetailsFilterQueryString = (tab: REMITTANCE_DETAILS_TAB, state: RemittanceTransactionsModel) => {

  const filtersToSend: any = [];

  if (tab === REMITTANCE_DETAILS_TAB.FAILED) {
    filtersToSend.push({ 'field': 'statusText', 'operator': 'eq', 'value': 'Failed' });
  } else if (tab === REMITTANCE_DETAILS_TAB.ADJUSTED) {
    filtersToSend.push({ 'field': 'isAdjusted', 'operator': 'eq', 'value': true }, { 'field': 'statusText', 'operator': 'eq', 'value': 'Paid' });
  } else if (tab === REMITTANCE_DETAILS_TAB.APPROVED) {
    filtersToSend.push({ 'field': 'isAdjusted', 'operator': 'eq', 'value': false }, { 'field': 'statusText', 'operator': 'eq', 'value': 'Paid' });
  } else if (tab === REMITTANCE_DETAILS_TAB.PROCESSED) {
    filtersToSend.push({ 'field': 'originalDiscountRequestId', 'operator': 'ne', 'value': null });
  }

  Object.keys(state.filter).forEach(key => {
    const val = (state.filter as { [key: string]: any })[key];
    if (Array.isArray(val)) {
      filtersToSend.push({ 'field': key, 'operator': 'in', 'value': val.join() })
    } else if (val) {
      if (key === 'fillStartDate' || key === 'fillEndDate') {
        const dateUTCString = new Date(val).toUTCString();
        const dateUTC = new Date(dateUTCString);
        const operator = key === 'fillStartDate' ? 'gte' : 'lte';

        filtersToSend.push({ 'field': 'fillDate', 'operator': operator, 'value': dateUTC })
      } else {
        filtersToSend.push({ 'field': key, 'operator': 'like', 'value': val })
      }
    }

  })

  return filtersToSend.map((obj: any) => `Filters=${JSON.stringify(obj)}`).join('&');
}

export function* getRemittanceSummaryPeriodsSaga () {
  try {
    const activeHealthSystemId: number = yield select(getActiveHealthSystemId);
    const summaryPeriods: RemittanceSummaryPeriodByYear[] = yield call(fetchRemittanceSummaryPeriods, activeHealthSystemId);

    yield put(getRemittanceSummaryPeriodsSuccess(summaryPeriods));
  } catch (e) {
    yield put(getRemittanceSummaryPeriodsFailure(logRocketUtil(e)));
  }
}

export function* getRemittanceMonthSummarySaga (action: any) {
  try {
    const { year, month } = action.payload;
    const activeHealthSystemId: number = yield select(getActiveHealthSystemId);

    const response: RemittancePeriodSummary[] = yield call(fetchRemittanceSummaryByMonth, year, month, activeHealthSystemId);

    const remittanceMonthSummary: RemittanceMonthSummary = {
      year: year,
      month: month,
      periods: response
    };

    yield put(getRemittanceMonthSummarySuccess(remittanceMonthSummary));
  } catch (e) {
    yield put(getRemittanceMonthSummaryFailure(logRocketUtil(e)));
  }
}

export function* getRemittancePeriodDetailsSaga (action: any) {

  try {
    const { year, month, period }: { year: number, month: number, period: number } = action.payload;
    const activeHealthSystemId: number = yield select(getActiveHealthSystemId);

    const response: RemittancePeriodSummary[] = yield call(fetchRemittanceSummaryByMonth, year, month, activeHealthSystemId);
    const summary = response.find(p => p.id === period);
    if (summary) {
      yield put(getRemittancePeriodDetailsSuccess(summary));
    } else {
      throw new Error('Invalid Summary Period');
    }

  } catch (e) {
    yield put(getRemittancePeriodDetailsFailure(logRocketUtil(e)));
  }
}

export function* getRemittanceInvoiceDetailsSaga (action: any) {

  try {
    const { remittanceId, participantId }: { remittanceId: number, participantId: string } = action.payload;
    const activeHealthSystemId: number = yield select(getActiveHealthSystemId);

    const response: RemittancePeriodSummaryDetail = yield call(fetchRemittanceSummaryByInvoice, remittanceId, participantId, activeHealthSystemId);
    yield put(getRemittanceInvoiceDetailsSuccess(response));
  } catch (e) {
    yield put(getRemittanceInvoiceDetailsFailure(logRocketUtil(e)));
  }
}

export function* getRemittanceTransactionsCountsSaga () {

  try {
    const activeHealthSystemId: number = yield select(getActiveHealthSystemId);
    const { params }: RemittanceTransactionsModel = yield select(getRemittanceTransactionsState);

    const response: RemittanceDetailsCountsModel = yield call(fetchRemittanceDetailCounts, params, activeHealthSystemId);

    yield put(getRemittanceTransactionsCountsSuccess(response));
  } catch (e) {
    yield put(getRemittanceTransactionsCountsFailure(logRocketUtil(e)));
  }
}

export function* getRemittanceTransactionsSaga (action: any) {

  try {
    const activeHealthSystemId: number = yield select(getActiveHealthSystemId);
    const transactionsState: RemittanceTransactionsModel = yield select(getRemittanceTransactionsState);
    const { sortBy, isSortAsc, currentPage, params } = transactionsState;

    const pageToFetch = getApiPageToFetch(currentPage, UI_PAGE_SIZE, API_PAGE_SIZE);
    const filters = getRemittanceDetailsFilterQueryString(action.payload, transactionsState);

    const response: DiscountRequestResponseModel = yield call(fetchRemittanceDetailTransactions, params, pageToFetch, sortBy, isSortAsc, filters, activeHealthSystemId);
    response.discountRequests = yield call(addDiscountRequestAdditionalFields, response.discountRequests);

    yield put(getRemittanceTransactionsSuccess(response));
  } catch (e) {
    yield put(getRemittanceTransactionsFailure(logRocketUtil(e)));
  }
}

function* watchGetRemittanceSummaryPeriodsSaga () {
  yield takeEvery(GET_REMITTANCE_SUMMARY_PERIODS, getRemittanceSummaryPeriodsSaga);
}

function* watchGetRemittanceMonthSummarySaga () {
  yield takeEvery(GET_REMITTANCE_MONTH_SUMMARY, getRemittanceMonthSummarySaga);
}

function* watchGetRemittancePeriodDetailsSaga () {
  yield takeEvery(GET_REMITTANCE_PERIOD_DETAILS, getRemittancePeriodDetailsSaga);
}

function* watchGetRemittanceInvoiceDetailsSaga () {
  yield takeEvery(GET_REMITTANCE_INVOICE_DETAILS, getRemittanceInvoiceDetailsSaga);
}

function* watchGetRemittanceDetailsCountsSaga () {
  yield takeEvery(GET_REMITTANCE_TRANSACTION_COUNTS, getRemittanceTransactionsCountsSaga);
}

function* watchGetRemittanceTransactionsSaga () {
  yield takeEvery(GET_REMITTANCE_TRANSACTIONS, getRemittanceTransactionsSaga);
}

export default function* watchAllRemittance () {
  yield all([
    watchGetRemittanceSummaryPeriodsSaga(),
    watchGetRemittanceMonthSummarySaga(),
    watchGetRemittancePeriodDetailsSaga(),
    watchGetRemittanceInvoiceDetailsSaga(),
    watchGetRemittanceDetailsCountsSaga(),
    watchGetRemittanceTransactionsSaga()
  ])
}
