import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { ErrorBanner } from '../common/errors/ErrorBanner';
import OnboardingSteps from './OnboardingSteps';

import '../../themes/kalderos/Onboarding/Layout.scss';
import '../../themes/kalderos/Onboarding/Common.scss';

export interface LayoutProps {
  isLoading?: boolean,
  errorText?: string,
  children: React.ReactNode,
  stepNumber?: number,
}

export class Layout extends React.Component<LayoutProps>
{

  render () {
    const { isLoading, children, stepNumber, errorText } = this.props;
    return (
      <div className="onboarding-layout-root">
        <Dimmer active={isLoading} inverted>
          <Loader inverted content='Loading' />
        </Dimmer>
        <div className="onboarding-layout-container">
          <div className="onboarding-layout-step-name-container">
            <OnboardingSteps stepNumber={stepNumber} />
          </div>
          <div className="onboarding-layout-content-container">
            {errorText && (
              <ErrorBanner errorText={errorText} />
            )}
            {children}
          </div>
        </div>
      </div>
    )
  }
}

export default Layout;
