import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import RequestConfig from './app.config';


async function  logRocketInit () {
  const enabled = await RequestConfig('logRocketEnabled');
  if (enabled) {
    LogRocket.init('rpcaov/yosemite', {
      console: {
        isEnabled: false,
      },
      shouldDebugLog: false,
      //@ts-ignore
      network: {
        requestSanitizer: (request) => {
        //@ts-ignore
        //currently blocking all request being sent
          request.headers['Authorization'] = undefined;
          request.body = undefined
          return request;
        },
        //currently blocking all API responses
        responseSanitizer: response => {
          response.body = undefined
          return response
        }
      }
    });
    setupLogRocketReact(LogRocket);
  }
}

export async function logRocketIdentify ({ email }: { email: string }) {
  const enabled = await RequestConfig('logRocketEnabled');
  if (enabled) {
    LogRocket.identify(email, {
      userEmail: email
    });
  }
}

export default logRocketInit;
