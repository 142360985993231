import { SortingFilter } from '../appcommon/types';
import { ErrorModel } from '../applicationTypes';
import { DiscountRequestResponseModel, TransactionsFilterModel } from '../transactionscommon/types';
import {
  CoveredEntityModel,
  DiscountProgramModel,
  DiscountProgramFieldsModel,
  DiscountRequestFieldSubmitModel,
  DiscountRequestRequiredFieldsModel,
  RequestCenterTransactionCountsModel,
  DiscountRequestSubmitResponseModel
} from './types';

export const UI_PAGE_SIZE = 25;
export const API_PAGE_SIZE = 100;

export enum REQUEST_CENTER_TAB {
  ALL = 0,
  AWAITING = 1,
  PROCESSING = 2,
  PROCESSED = 3,
}

export enum REQUEST_CENTER_BUCKET {
  NONE = 0,
  ALL = 1,
  SUBMITTED = 2,
  IN_MFR_REVIEW = 3,
  FAILED = 4,
  ADJUSTED_AND_PAID = 5,
  APPROVED_AND_PAID = 6
}


export const GET_REQUEST_CENTER_TRANSACTION_COUNTS = 'GET_REQUEST_CENTER_TRANSACTION_COUNTS';
export const GET_REQUEST_CENTER_TRANSACTION_COUNTS_SUCCESS = 'GET_REQUEST_CENTER_TRANSACTION_COUNTS_SUCCESS';
export const GET_REQUEST_CENTER_TRANSACTION_COUNTS_FAILURE = 'GET_REQUEST_CENTER_TRANSACTION_COUNTS_FAILURE';

export const GET_REQUEST_CENTER_TRANSACTIONS = 'GET_REQUEST_CENTER_TRANSACTIONS';
export const GET_REQUEST_CENTER_TRANSACTIONS_SUCCESS = 'GET_REQUEST_CENTER_TRANSACTIONS_SUCCESS';
export const GET_REQUEST_CENTER_TRANSACTIONS_FAILURE = 'GET_REQUEST_CENTER_TRANSACTIONS_FAILURE';

export const UPDATE_REQUEST_CENTER_TRANSACTIONS_BUCKET = 'UPDATE_REQUEST_CENTER_TRANSACTIONS_BUCKET';
export const UPDATE_REQUEST_CENTER_TRANSACTIONS_PAGE = 'UPDATE_REQUEST_CENTER_TRANSACTIONS_PAGE';
export const UPDATE_REQUEST_CENTER_TRANSACTIONS_SORT = 'UPDATE_REQUEST_CENTER_TRANSACTIONS_SORT';
export const UPDATE_REQUEST_CENTER_TRANSACTIONS_FILTER = 'UPDATE_REQUEST_CENTER_TRANSACTIONS_FILTER';

export const RESET_REQUEST_CENTER_TRANSACTION_COUNTS = 'RESET_REQUEST_CENTER_TRANSACTION_COUNTS';
export const RESET_REQUEST_CENTER_TRANSACTIONS = 'RESET_REQUEST_CENTER_TRANSACTIONS';

export const GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES = 'GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES';
export const GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES_SUCCESS = 'GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES_SUCCESS';
export const GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES_FAILURE = 'GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES_FAILURE';

export const GET_DISCOUNT_REQUEST_CONTRACT_FIELDS = 'GET_DISCOUNT_REQUEST_CONTRACT_FIELDS';
export const GET_DISCOUNT_REQUEST_CONTRACT_FIELDS_SUCCESS = 'GET_DISCOUNT_REQUEST_CONTRACT_FIELDS_SUCCESS';
export const GET_DISCOUNT_REQUEST_CONTRACT_FIELDS_FAILURE = 'GET_DISCOUNT_REQUEST_CONTRACT_FIELDS_FAILURE';

export const SUBMIT_DISCOUNT_REQUEST = 'SUBMIT_DISCOUNT_REQUEST';
export const SUBMIT_DISCOUNT_REQUEST_SUCCESS = 'SUBMIT_DISCOUNT_REQUEST_SUCCESS';
export const SUBMIT_DISCOUNT_REQUEST_FAILURE = 'SUBMIT_DISCOUNT_REQUEST_FAILURE';

export const CORRECT_DISCOUNT_REQUEST = 'CORRECT_DISCOUNT_REQUEST';
export const CORRECT_DISCOUNT_REQUEST_SUCCESS = 'CORRECT_DISCOUNT_REQUEST_SUCCESS';
export const CORRECT_DISCOUNT_REQUEST_FAILURE = 'CORRECT_DISCOUNT_REQUEST_FAILURE';

export const RESET_DISCOUNT_REQUEST_FORM = 'RESET_DISCOUNT_REQUEST_FORM';

interface GetRequestCenterTransactionCountsAction {
  type: typeof GET_REQUEST_CENTER_TRANSACTION_COUNTS
  payload: REQUEST_CENTER_TAB
}

interface GetRequestCenterTransactionCountsSuccessAction {
  type: typeof GET_REQUEST_CENTER_TRANSACTION_COUNTS_SUCCESS
  payload: RequestCenterTransactionCountsModel
}

interface GetRequestCenterTransactionCountsFailureAction {
  type: typeof GET_REQUEST_CENTER_TRANSACTION_COUNTS_FAILURE
  payload: ErrorModel
}

interface GetRequestCenterTransactionsAction {
  type: typeof GET_REQUEST_CENTER_TRANSACTIONS
  payload: REQUEST_CENTER_TAB,
}

interface GetRequestCenterTransactionsSuccessAction {
  type: typeof GET_REQUEST_CENTER_TRANSACTIONS_SUCCESS
  payload: DiscountRequestResponseModel
}

interface GetRequestCenterTransactionsFailureAction {
  type: typeof GET_REQUEST_CENTER_TRANSACTIONS_FAILURE
  payload: ErrorModel
}

interface UpdateRequestCenterTransactionsBucketAction {
  type: typeof UPDATE_REQUEST_CENTER_TRANSACTIONS_BUCKET
  payload: REQUEST_CENTER_BUCKET
}

interface UpdateRequestCenterTransactionsPageAction {
  type: typeof UPDATE_REQUEST_CENTER_TRANSACTIONS_PAGE
  payload: number
}

interface UpdateRequestCenterTransactionsSortAction {
  type: typeof UPDATE_REQUEST_CENTER_TRANSACTIONS_SORT,
  payload: SortingFilter
}

interface UpdateRequestCenterTransactionsFilterAction {
  type: typeof UPDATE_REQUEST_CENTER_TRANSACTIONS_FILTER,
  payload: {
    tab: REQUEST_CENTER_TAB,
    filter: TransactionsFilterModel
  }
}

interface ResetRequestCenterTransactionCountsAction {
  type: typeof RESET_REQUEST_CENTER_TRANSACTION_COUNTS,
}

interface ResetRequestCenterTransactionsAction {
  type: typeof RESET_REQUEST_CENTER_TRANSACTIONS,
}

interface GetDiscountRequestProgramsAndCoveredEntitiesAction {
  type: typeof GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES
}

interface GetDiscountRequestProgramsAndCoveredEntitiesSuccessAction {
  type: typeof GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES_SUCCESS,
  payload: {
    discountPrograms: DiscountProgramModel[],
    coveredEntities: CoveredEntityModel[]
  }
}

interface GetDiscountRequestProgramsAndCoveredEntitiesFailureAction {
  type: typeof GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES_FAILURE,
  payload: ErrorModel
}

interface GetDiscountRequestContractFieldsAction {
  type: typeof GET_DISCOUNT_REQUEST_CONTRACT_FIELDS,
  payload: DiscountRequestRequiredFieldsModel
}

interface GetDiscountRequestContractFieldsSuccessAction {
  type: typeof GET_DISCOUNT_REQUEST_CONTRACT_FIELDS_SUCCESS,
  payload: DiscountProgramFieldsModel[],
}

interface GetDiscountRequestContractFieldsFailureAction {
  type: typeof GET_DISCOUNT_REQUEST_CONTRACT_FIELDS_FAILURE,
  payload: ErrorModel
}

interface SubmitDiscountRequestAction {
  type: typeof SUBMIT_DISCOUNT_REQUEST
  payload: DiscountRequestFieldSubmitModel
}

interface SubmitDiscountRequestSuccessAction {
  type: typeof SUBMIT_DISCOUNT_REQUEST_SUCCESS
  payload: DiscountRequestSubmitResponseModel[]
}

interface SubmitDiscountRequestFailureAction {
  type: typeof SUBMIT_DISCOUNT_REQUEST_FAILURE
  payload: string[]
}

interface ResetDiscountRequestFromAction {
  type: typeof RESET_DISCOUNT_REQUEST_FORM,
}

interface CorrectDiscountRequestAction {
  type: typeof CORRECT_DISCOUNT_REQUEST,
  payload: {
    id: number,
    fields: DiscountRequestFieldSubmitModel
  }
}

interface CorrectDiscountRequestSuccessAction {
  type: typeof CORRECT_DISCOUNT_REQUEST_SUCCESS,
  payload: DiscountRequestSubmitResponseModel[]
}

interface CorrectDiscountRequestFailureAction {
  type: typeof CORRECT_DISCOUNT_REQUEST_FAILURE
  payload: string[]
}


export type RecipientActionTypes =
  GetRequestCenterTransactionCountsAction |
  GetRequestCenterTransactionCountsSuccessAction |
  GetRequestCenterTransactionCountsFailureAction |
  GetRequestCenterTransactionsAction |
  GetRequestCenterTransactionsSuccessAction |
  GetRequestCenterTransactionsFailureAction |
  UpdateRequestCenterTransactionsBucketAction |
  UpdateRequestCenterTransactionsPageAction |
  UpdateRequestCenterTransactionsSortAction |
  UpdateRequestCenterTransactionsFilterAction |
  ResetRequestCenterTransactionCountsAction |
  ResetRequestCenterTransactionsAction |
  GetDiscountRequestProgramsAndCoveredEntitiesAction |
  GetDiscountRequestProgramsAndCoveredEntitiesSuccessAction |
  GetDiscountRequestProgramsAndCoveredEntitiesFailureAction |
  GetDiscountRequestContractFieldsAction |
  GetDiscountRequestContractFieldsSuccessAction |
  GetDiscountRequestContractFieldsFailureAction |
  SubmitDiscountRequestAction |
  SubmitDiscountRequestSuccessAction |
  SubmitDiscountRequestFailureAction |
  CorrectDiscountRequestAction |
  CorrectDiscountRequestSuccessAction |
  CorrectDiscountRequestFailureAction |
  ResetDiscountRequestFromAction;