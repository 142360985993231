import React, { useState } from 'react';
import { Form, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { RootState } from '../../../../store/RootState';
import { sendRequestForAuthorizingOfficialSignUp, resetAuthorizingOfficialSignUp } from '../../../../store/onboarding/actionCreators';
import cx from 'classnames';
import * as yup from 'yup';
import { Formik } from 'formik';
import AuthorizingOfficialSignUpSuccess from './AuthorizingOfficialSignUpSuccess';
import AuthorizingOfficialSignUpError from './AuthorizingOfficialSignUpError'
import { clearUserDetails } from '../../../../store/login/actionCreators';

import { RouteComponentProps } from 'react-router-dom';
import { SUPPORT_EMAIL, SUPPORT_PHONE, SUPPORT_EMAIL_LINK } from '../../../../store/applicationConstants';
import KalderosLogo from '../../../../themes/kalderos/static/media/Kalderos_Wordmark_GreyDarker.svg';
import { RequestLogoIconLogin } from '../../../../themes/kalderos/Common/Icons';

interface AuthorizingOfficialSignUpProps extends RouteComponentProps {
  isLoading: boolean;
  authorizingOfficialSignUpSuccess: boolean;
  authorizingOfficialSignUpErrorText: string;
  sendEmailToApi: (email: string) => void;
  resetAuthorizingOfficialSignUp: () => void;
  clearUserDetails: () => void;
}

const Schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required()
})

const intialValues: { email: string } = {
  email: ''
}

const AuthorizingOfficialSignUp: React.FunctionComponent<AuthorizingOfficialSignUpProps> = ({ isLoading, authorizingOfficialSignUpSuccess, authorizingOfficialSignUpErrorText, sendEmailToApi, resetAuthorizingOfficialSignUp, clearUserDetails, history }) => {

  const [email, setEmail] = useState<string>('');

  const handleSubmit = (values: { email: string }) => {
    setEmail(values.email);
    sendEmailToApi(values.email)
  }

  const handleClickToHomepage = () => {
    resetAuthorizingOfficialSignUp();
    clearUserDetails();
    history.push('/');
  }

  if (authorizingOfficialSignUpSuccess) {
    return (<AuthorizingOfficialSignUpSuccess email={email} onReset={resetAuthorizingOfficialSignUp} onClose={handleClickToHomepage} />)
  }

  if (authorizingOfficialSignUpErrorText) {
    return (
      <AuthorizingOfficialSignUpError email={email} onReset={resetAuthorizingOfficialSignUp} onClose={handleClickToHomepage} message={authorizingOfficialSignUpErrorText} />
    )
  }

  return (
    <div className="login-page ao-sign-up bg-request">
      <div className="login-page-layout two-column">
        <div className="login-page-column-left">
          <div className="login-welcome-container">
            <img src={KalderosLogo} alt="Kalderos" className="logo" />
            <div className="welcome-header">
              Welcome to Kalderos Request Signup! 
            </div>
            <div className="welcome-text">
              In order to begin using Request to receive 340B rebates, the Authorizing Official (AO) from your covered entity must create your initial account here.
            </div>
            <div className="welcome-text">
              If you are not the AO and would like to sign up, please contact your AO directly. AOs should have received an email and letter themselves with more detailed instructions.
            </div>
          </div>
        </div>
        <div className="login-page-column-right">
          <div className="login-form-container">
            {isLoading && <Dimmer className="dimmer" active inverted><Loader inverted content='Loading' /></Dimmer>}
            <Formik
              initialValues={intialValues}
              onSubmit={handleSubmit}
              validationSchema={Schema}
              validateOnMount={true}
            >
              {({ handleSubmit, errors, touched, isValid, handleChange, handleBlur, values, dirty }) => (
                <Form className='login-form' onSubmit={handleSubmit}>
                  <div className="login-form-logo"><RequestLogoIconLogin /></div>
                  <div className="login-form-header">Covered Entity Signup</div>
                  <div className="login-form-body">
                    <div className="login-form-description">
                      If you are the Authorizing Official (AO) as listed with HRSA, please enter your email address below.
                    </div>
                    <Form.Input 
                      name='email'
                      type="email"
                      label="Email Address"
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={cx({ 'input-error-field': errors.email && touched.email && values.email })}
                    />
                    {errors.email && touched.email && values.email &&
                      <div className='input-error-text'>That is not a valid email address</div>
                    }
                  </div>
                  <div className="login-form-footer">
                    <button type="submit" className="app-button cta" id='startSignUp_Pendo' disabled={!isValid || !dirty}>Start Sign Up</button>
                    <button type="button" className="app-button link-text" onClick={handleClickToHomepage}>Go back to Sign-In</button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="login-page-column-right-footer">
            If you have any questions, please contact Kalderos Support at <a href={SUPPORT_EMAIL_LINK}>{SUPPORT_EMAIL}</a> or {SUPPORT_PHONE} for assistance.
          </div>
        </div>
      </div>
    </div>

  )
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.OnboardingState.isLoading,
  authorizingOfficialSignUpSuccess: state.OnboardingState.authorizingOfficialSignUpStepModel.authorizingOfficialSignUp.authorizingOfficialSignUpSuccess,
  authorizingOfficialSignUpErrorText: state.OnboardingState.authorizingOfficialSignUpStepModel.authorizingOfficialSignUp.authorizingOfficialSignUpErrorText

})

const mapDispatchToProps = (dispatch: any) => {
  return {
    sendEmailToApi: (email: string) => { dispatch(sendRequestForAuthorizingOfficialSignUp(email)) },
    resetAuthorizingOfficialSignUp: () => { dispatch(resetAuthorizingOfficialSignUp()) },
    clearUserDetails: () => { dispatch(clearUserDetails()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizingOfficialSignUp)
