import { SortingFilter } from '../../appcommon/types';
import { ErrorModel } from '../../applicationTypes';
import { AssociatedPharmaciesFiltersModel, PharmacyEditHistoryModel, PharmacyManagementDetails, PharmacyManagementResponse } from '../pharmacymanagement/types';

export const GET_PHARMACY_MANAGEMENT = 'GET_PHARMACY_MANAGEMENT'
export const GET_PHARMACY_MANAGEMENT_SUCCESS = 'GET_PHARMACY_MANAGEMENT_SUCCESS'
export const GET_PHARMACY_MANAGEMENT_ERROR = 'GET_PHARMACY_MANAGEMENT_ERROR'
export const CLEAR_PHARMACY_MANAGEMENT = 'CLEAR_PHARMACY_MANAGEMENT';

export const GET_EDIT_HISTORY_INFO = 'GET_EDIT_HISTORY_INFO'
export const GET_EDIT_HISTORY_INFO_SUCCESS = 'GET_EDIT_HISTORY_INFO_SUCCESS'
export const GET_EDIT_HISTORY_INFO_FAILURE = 'GET_EDIT_HISTORY_INFO_FAILURE'

export const UPDATE_PHARMACY_MANAGEMENT_SORT_FILTERS = 'UPDATE_PHARMACY_MANAGEMENT_SORT_FILTERS'
export const UPDATE_PHARMACY_MANAGEMENT_PAGE = 'UPDATE_PHARMACY_MANAGEMENT_PAGE'

export const GET_REMOVE_PHARMACY_REASONS = 'GET_REMOVE_PHARMACY_REASONS';
export const GET_REMOVE_PHARMACY_REASONS_SUCCESS = 'GET_REMOVE_PHARMACY_REASONS_SUCCESS';
export const GET_REMOVE_PHARMACY_REASONS_ERROR = 'GET_REMOVE_PHARMACY_REASONS_ERROR';

export const UI_PAGE_SIZE = 25;
export const API_PAGE_SIZE = 100;

export const NO_EDIT_HISTORY_HEADER = 'No Edit History';
export const NO_EDIT_HISTORY_MESSAGE = 'You can view and download the edit history once changes are made to this covered entity.';
export const NO_ASSOCIATED_PHARMACIES_HEADER = 'No Associated Pharmacies';
export const NO_ASSOCIATED_PHARMACIES_MESSAGE = 'This is where you’ll find the pharmacies associated with this covered entity and their respective 340B Pay settings.';

export const GPOWAC_TOOLTIP_MESSAGE = 'Non-prohibited contract pharamcies and prohibited pharmacies will be set to WAC';
export const PAY340B_TOOLTIP_MESSAGE = 'Contract pharmacies are automatically set to use 340B Pay';

export const UPDATE_GPOWAC_INDEX = 'UPDATE_GPOWAC_INDEX';
export const UPDATE_340BPAY_INDEX = 'UPDATE_340BPAY_INDEX';

export const UPDATE_PHARMACY_MANAGEMENT_FILTERS = 'UPDATE_PHARMACY_MANAGEMENT_FILTERS';

export const PAY340B_DIALOG_MESSAGE = 'Are you sure you want to change the 340B Pay setting for this pharmacy? If this change is made, this will apply to all claims in the future.';
export const PAY340B_DIALOG_HEADER = 'Change 340B Pay setting?';
export const GPOWAC_DIALOG_MESSAGE = 'Are you sure you want to switch the GPO/WAC setting for this pharmacy? If this change is made, this pricing change will apply to all requests in the future.';
export const GPOWAC_DIALOG_HEADER = 'Change GPO/WAC setting?';

export const PHARMACY_TYPE_FILTER_OPTIONS = [
  { key: 'ceOwned', value: 'CE-owned', text: 'CE-owned' },
  { key: 'contract', value: 'Contract', text: 'Contract' },
];

export const DOWNLOAD_EDIT_HISTORY = 'DOWNLOAD_EDIT_HISTORY';
export const DOWNLOAD_EDIT_HISTORY_SUCCESS = 'DOWNLOAD_EDIT_HISTORY_SUCCESS';
export const DOWNLOAD_EDIT_HISTORY_FAILURE = 'DOWNLOAD_EDIT_HISTORY_SUCCESS';

interface GetPharmacyManagementAction {
  type: typeof GET_PHARMACY_MANAGEMENT,
  payload: string
}

interface GetPharmacyManagementSuccessAction {
  type: typeof GET_PHARMACY_MANAGEMENT_SUCCESS,
  payload: PharmacyManagementResponse

}

interface GetPharmacyManagementErrorAction {
  type: typeof GET_PHARMACY_MANAGEMENT_ERROR,
  payload: ErrorModel
}

interface UpdatePharmacyManagementSortFilters {
  type: typeof UPDATE_PHARMACY_MANAGEMENT_SORT_FILTERS,
  payload: SortingFilter
}
interface ClearPharmacyManagement {
  type: typeof CLEAR_PHARMACY_MANAGEMENT
}

interface GetEditHistoryInfoAction {
  type: typeof GET_EDIT_HISTORY_INFO
}

interface GetEditHistoryInfoSuccessAction {
  type: typeof GET_EDIT_HISTORY_INFO_SUCCESS,
  payload: PharmacyEditHistoryModel
}

interface GetEditHistoryInfoFailureAction {
  type: typeof GET_EDIT_HISTORY_INFO_FAILURE
}

interface UpdatePharmacyManagementPage {
  type: typeof UPDATE_PHARMACY_MANAGEMENT_PAGE,
  payload: number
}

interface GetRemovePharmacyReasonsAction {
  type: typeof GET_REMOVE_PHARMACY_REASONS,
}

interface GetRemovePharmacyReasonsSuccessAction {
  type: typeof GET_REMOVE_PHARMACY_REASONS_SUCCESS,
  payload: string[]
}

interface GetRemovePharmacyReasonsErrorAction {
  type: typeof GET_REMOVE_PHARMACY_REASONS_ERROR,
  payload: string
}
interface UpdateGPOWACIndexAction {
  type: typeof UPDATE_GPOWAC_INDEX,
  payload: {
    index: number,
    pharmacy: PharmacyManagementDetails
  }
}

interface Update340BPayIndexAction {
  type: typeof UPDATE_340BPAY_INDEX,
  payload: {
    index: number,
    pharmacy: PharmacyManagementDetails
  }
}

interface UpdatePharmacyManagementFilters {
  type: typeof UPDATE_PHARMACY_MANAGEMENT_FILTERS,
  payload: AssociatedPharmaciesFiltersModel
}

interface DownloadEditHistoryAction {
  type: typeof DOWNLOAD_EDIT_HISTORY
}
interface DownloadEditHistorySuccessAction {
  type: typeof DOWNLOAD_EDIT_HISTORY_SUCCESS
}
interface DownloadEditHistoryFailureAction {
  type: typeof DOWNLOAD_EDIT_HISTORY_FAILURE
}

export type PharmacyManagementActionTypes = 
  GetPharmacyManagementAction | GetPharmacyManagementSuccessAction | GetPharmacyManagementErrorAction | UpdatePharmacyManagementSortFilters |
  GetEditHistoryInfoAction | GetEditHistoryInfoSuccessAction | GetEditHistoryInfoFailureAction | UpdatePharmacyManagementPage | ClearPharmacyManagement |
  GetRemovePharmacyReasonsAction | GetRemovePharmacyReasonsSuccessAction | GetRemovePharmacyReasonsErrorAction | UpdateGPOWACIndexAction | Update340BPayIndexAction |
  UpdatePharmacyManagementFilters | DownloadEditHistoryAction | DownloadEditHistorySuccessAction | DownloadEditHistoryFailureAction;
