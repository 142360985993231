import { createSelector } from 'reselect';

import { OPEN_DISPUTES_TAB } from './constants';
import { ClaimModel } from './types';
import { UI_PAGE_SIZE, API_PAGE_SIZE } from '../../applicationConstants';
import { RootState } from '../../RootState';
import { getPagedDataSelector } from '../../../utils/datatableHelper';
import { formatRebateQuarterDropdown } from '../../../utils/dataUtil';
import { LabelerOptionsModel } from './types';

export const getManufacturerClaims = (state: RootState) => state.MdrpState.disputesCenter.disputesReview.manufacturerDisputes.claims;
export const getManufacturerReviewCurrentPage = (state: RootState) => state.MdrpState.disputesCenter.disputesReview.manufacturerDisputes.currentPage;
export const getActiveTab = (state: RootState) => state.MdrpState.disputesCenter.disputesReview.manufacturerDisputes.activeTabIndex;
export const getActiveTabFilters = (state: RootState) => state.MdrpState.disputesCenter.disputesReview.manufacturerDisputesFilters; 
export const getSelectedClaimsIndex = (state: RootState) => state.MdrpState.disputesCenter.disputesReview.manufacturerDisputes.selectedClaimIndex;

export const getDisputeCodeOptions = (state: RootState) => state.MdrpState.disputesCenter.disputesReview.manufacturerDisputesFilterOptions.advancedFilterOptions?.manualDisputeCodes?.map((code: string, index: number) => ({ key: index, value: code, text: code })) || [];
export const getLabelerOptions = (state: RootState) => state.MdrpState.disputesCenter.disputesReview.manufacturerDisputesFilterOptions.primaryFilterOptions?.labelers.map((labeler: LabelerOptionsModel, index: number) => ({ key: index, value: labeler.labelerCode, text: `${labeler.labelerCode} (${labeler.count} Claims)` })) || [];
export const getPharmacyNpiOptions = (state: RootState) => state.MdrpState.disputesCenter.disputesReview.manufacturerDisputesFilterOptions.advancedFilterOptions?.pharmacyNpis?.map((npi: string, index: number) => ({ key: index, value: npi, text: npi })) || [];
export const getProductNameOptions = (state: RootState) => state.MdrpState.disputesCenter.disputesReview.manufacturerDisputesFilterOptions.advancedFilterOptions?.productNames?.map((name: string, index: number) => ({ key: index, value: name, text: name })) || [];
export const getProgramNameOptions = (state: RootState) => state.MdrpState.disputesCenter.disputesReview.manufacturerDisputesFilterOptions.advancedFilterOptions?.programNames?.map((name: string, index: number) => ({ key: index, value: name, text: name })) || [];
export const getRebateQtrOptions = (state: RootState) => state.MdrpState.disputesCenter.disputesReview.manufacturerDisputesFilterOptions.primaryFilterOptions?.rebateQtrs.map((qtr: number, index: number) => ({ key: index, value: qtr.toString(), text: formatRebateQuarterDropdown(qtr.toString()) })) || [];

export const getNdcOptions = (state: RootState) => {
  const ndcOptions: any = [];
  state.MdrpState.disputesCenter.disputesReview.manufacturerDisputesFilterOptions.primaryFilterOptions?.labelers.forEach((labeler: LabelerOptionsModel, index: number) => {
    labeler.ndcs.forEach((ndc: any) => {
      ndcOptions.push({ key: index, value: ndc, text: ndc })
    })
  });

  return ndcOptions;
};

export const getPagedManufacturerClaims = getPagedDataSelector(getManufacturerClaims, getManufacturerReviewCurrentPage, UI_PAGE_SIZE, API_PAGE_SIZE);

export const selectManufacturerReviewActiveTabFilters = createSelector(
  [getActiveTab, getActiveTabFilters], (activeTab, filters) => {
    if (activeTab === OPEN_DISPUTES_TAB.NEW) {
      return filters.newDisputes;
    } else if (activeTab === OPEN_DISPUTES_TAB.SENT_TO_MFR) {
      return filters.sentToMfr;
    } else {
      return filters.impasse;
    }
  }
);

export const selectManufacturerReviewActiveTab = createSelector(
  [getActiveTab], (activeTab) => {
    if (activeTab === OPEN_DISPUTES_TAB.NEW) {
      return 'newDisputes'
    } else if (activeTab === OPEN_DISPUTES_TAB.SENT_TO_MFR) {
      return 'sentToMfr'
    } else {
      return 'impasse'
    }
  }
);

export const getNextClaimIndex = createSelector(
  [getSelectedClaimsIndex, getManufacturerClaims, getManufacturerReviewCurrentPage],
  (selectedIndex: number, list: ClaimModel[], currentPage: number) => {
    const nextClaim = list[selectedIndex + 1];
    const nextIndex = nextClaim ? nextClaim.index : undefined;
    const x = currentPage % 4;
    const y = x === 0 ? 4 : x;
    const nextIndexIsNextPage = nextIndex=== (UI_PAGE_SIZE * y);

    if (nextIndex && nextIndexIsNextPage) {
      return undefined;
    }
    return nextIndex;
  }
)

export const getPreviousClaimIndex = createSelector(
  [getSelectedClaimsIndex, getManufacturerClaims, getManufacturerReviewCurrentPage],
  (selectedIndex: number, list: ClaimModel[], currentPage: number) => {
    const previousClaim = list[selectedIndex - 1];
    const previousIndex = previousClaim ? previousClaim.index : undefined;
    const x = currentPage % 4;
    const y = x === 0 ? 4 : x;
    const unreachablePreviousIndex = (UI_PAGE_SIZE * y) - (UI_PAGE_SIZE + 1);
    const previousIndexIsUnreachable = previousIndex === unreachablePreviousIndex;

    if (previousIndex && previousIndexIsUnreachable) {
      return undefined;
    }
    return previousIndex;
  }
)
