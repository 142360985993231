import { call, put, all, takeEvery, select } from 'redux-saga/effects';
import {
  getInviteAccountAdminStepInput,
  getAccountAdminInfoStepInput,
  getInviteControllerStepInput,
  getControllerInfoStepInput,
  getSelectedHealthSystem,
  getHealthSystemNameForCheck,
  makeSelectIsAuthorizingOfficial
} from './selectors';
import { ROLES } from '../applicationConstants';
import {
  CHECK_ONBOARDING_STATUS,
  GET_TERMS_AND_CONDITIONS,
  COMPLETE_TERMS_AND_CONDITIONS_STEP,
  COMPLETE_INVITE_ACCOUNT_ADMIN_STEP,
  COMPLETE_ACCOUNT_ADMIN_INFO_STEP,
  GET_COVERED_ENTITY_INFO,
  GET_ACCOUNT_ADMIN_SUMMARY,
  COMPLETE_ACCOUNT_ADMIN_CONFIRM_INFO_STEP,
  COMPLETE_INVITE_CONTROLLER_STEP,
  COMPLETE_CONTROLLER_INFO_STEP,
  COMPLETE_CONTROLLER_CONFIRM_INFO_STEP,
  COMPLETE_CONTROLLER_BANK_INFO_STEP,
  SEND_AUTHORIZING_OFFICIAL_SIGN_UP,
  REFRESH_DATA_AFTER_ONBOARDING,
  GET_CANDIDATE_CE_LIST,
  CHECK_UNIQUE_HEALTH_SYSTEM_NAME,
  GET_HEALTH_SYSTEM_ACCOUNT_ADMIN,
  COMPLETE_STEP_ERROR_MESSAGE,
  GET_CONTROLLER_STATUS,
  ONBOARDING_STEPS
} from './constants';
import {
  checkOnboardingStatus,
  checkOnboardingStatusSuccess,
  checkOnboardingStatusFailed,
  getTermsAndConditionsSuccess,
  getTermsAndConditionsFailure,
  completeTermsAndConditionsStepSuccess,
  completeInviteAccountAdminStepSuccess,
  completeAccountAdminInfoStepSuccess,
  getCoveredEntityInfoSuccess,
  getCoveredEntityInfoFailure,
  getAccountAdminSummarySuccess,
  getAccountAdminSummaryFailure,
  completeAccountAdminConfirmInfoStepSuccess,
  completeInviteControllerStepSuccess,
  completeControllerInfoStepSuccess,
  completeControllerConfirmInfoStepSuccess,
  completeControllerBankInfoStepSuccess,
  completeRecipientStepFailure,
  sendRequestForAuthorizingOfficialSignUpSuccess,
  sendRequestForAuthorizingOfficialSignUpError,
  getCandidateCeListSuccess,
  getCandidateCeListFailure,
  checkUniqueHealthSystemNameSuccess,
  checkUniqueHealthSystemNameFailure,
  getHealthSystemAccountAdminSuccess,
  getHealthSystemAccountAdminFailure,
  getControllerStatusSuccess,
  getControllerStatusFailure,
  updateOnboardingStep,
  getCandidateCeList
} from './actionCreators';
import {
  businessStatus,
  fetchTermsAndConditions,
  completeTermsAndConditionsStepRequest,
  completeInviteAccountAdminStepRequest,
  completeAccountAdminInfoStepRequest,
  fetchCoveredEntityInfo,
  fetchAccountAdminSummary,
  completeAccountAdminConfirmInfoStepRequest,
  completeInviteControllerStepRequest,
  completeControllerInfoStepRequest,
  completeControllerConfirmInfoStepRequest,
  completeControllerBankInfoStepRequest,
  createHealthSystemAndBusinesses,
  getCandidateCeListRequest,
  checkUniqueHealthSystemRequest,
  getHealthSystemAccountAdminRequest,
  fetchControllerStatusRequest,
} from '../../api/onboarding/RecipientOnboarding';
import { InviteUserStepInputModel, CandidateHealthSystem, CandidateCoveredEntity } from './types';
import { unFormatPhone, formatPhone } from '../../utils/phoneFormatHelper';
import { authorizingOfficialSignUpApi } from '../../api/onboarding/RecipientOnboarding'
import { updateSelectedHealthSystemInfoForOnboarding, getHealthSystems, updateSelectedHealthSystemOnboardingRoles, clearHealthSystemsError } from '../teammanagement/actionCreators';
import { getActiveHealthSystemId, selectHealthSystemOnboardingRoles } from '../teammanagement/selectors';
import { RECIPIENT_TEAM_MANAGEMENT_USER_ROLES } from '../teammanagement/constants';
import { ErrorModel } from '../applicationTypes';
import { selectLegalDocuments } from '../appcommon/selectors';
import { LegalDocumentsModel } from '../appcommon/types';
import { getUserInfo } from '../account/actionCreators';
import logRocketUtil from '../../utils/logRocketUtil';

export function* checkOnboardingStatusSaga (action: any) {
  try {
    const response = yield call(businessStatus, action.payload);
    yield put(checkOnboardingStatusSuccess(response));
  } catch (e) {
    logRocketUtil(e)
    yield put(checkOnboardingStatusFailed(e.message));
  }
}

export function* getTermsAndConditionsSaga () {
  try {
    const docs: LegalDocumentsModel = yield select(selectLegalDocuments);
    const response = yield call(fetchTermsAndConditions, docs.termsAndConditionsText);
    yield put(getTermsAndConditionsSuccess(response));
  } catch (e) {
    yield put(getTermsAndConditionsFailure(logRocketUtil(e)));
  }
}

export function* completeTermsAndConditionsStepSaga () {
  try {
    const newHealthSystem = yield select(getSelectedHealthSystem);

    let activeHealthSystemId = newHealthSystem.healthSystemId;

    const businessesToCreate = newHealthSystem.ceList.map((ce: any) => ({
      Id340B: ce.id340B
    })) || [];
    if (newHealthSystem.orphanCeList && newHealthSystem.orphanCeList.length > 0) {
      newHealthSystem.orphanCeList.map(
        (ce: any) => (
          businessesToCreate.push({
            Id340B: ce.id340B
          }))
      );
    }

    // if this is the first AO
    if (!newHealthSystem.partialOnboard) {
      const createRequest: any = {
        HealthSystem: { HealthSystemName: newHealthSystem.healthSystemName.trim() },
        Businesses: businessesToCreate
      };
      if (newHealthSystem.healthSystemId) {
        createRequest.HealthSystem.TeamId = newHealthSystem.healthSystemId;
      }
      const createResponse = yield call(createHealthSystemAndBusinesses, createRequest);
      activeHealthSystemId = createResponse.healthSystemId;
    } else { // nth AO needs to just add businesses
      const createRequest: any = {
        HealthSystem: { teamId: activeHealthSystemId },
        Businesses: businessesToCreate
      };
      yield call(createHealthSystemAndBusinesses, createRequest);
    }

    // fake new active health system until we get out of onboarding
    yield put(updateSelectedHealthSystemInfoForOnboarding({
      healthSystemId: activeHealthSystemId,
      healthSystemName: newHealthSystem.healthSystemName,
      role: 'Admin',
      onboardingRoles: [ROLES.RECIPIENT_AUTHORIZING_OFFICIAL],
      hasVerifiedFundingSource: false,
    }));

    const just340bIds = businessesToCreate.map((ce: any) => (ce.Id340B))
    const termsAndConditionsResponse = yield call(completeTermsAndConditionsStepRequest, activeHealthSystemId, just340bIds);
    yield put(completeTermsAndConditionsStepSuccess(termsAndConditionsResponse));
    yield put(updateOnboardingStep(ONBOARDING_STEPS.AUTHORIZING_OFFICIAL_INVITE_ACCOUNT_ADMIN));

    // the following must be done after the above success action because it will trip
    // the route protection loader and potentially remount the current component

    // sets UserIsNewAO to false
    yield put(clearHealthSystemsError());

    // fake current onboarding status so IsLoaded = true
    yield put(checkOnboardingStatusSuccess({ onboardingCompleted: false, onboardingStatusValue: 2 }));

    //load user info since this was skipped earlier due to no HS
    yield put(getUserInfo());

    //reload candidate ce list to update switcher
    yield put(getCandidateCeList());

  } catch (e) {
    yield put(completeRecipientStepFailure(logRocketUtil(e)));
  }
}

export function* completeInviteAccountAdminStepSaga () {
  try {
    const activeHealthSystemId = yield select(getActiveHealthSystemId);
    const input: InviteUserStepInputModel = yield select(getInviteAccountAdminStepInput);
    yield call(completeInviteAccountAdminStepRequest, input, activeHealthSystemId);
    if (input.addRoleToCurrentUser) {
      const roles = yield select(selectHealthSystemOnboardingRoles);
      if (roles && roles.indexOf(ROLES.RECIPIENT_ACCOUNT_ADMIN) < 0) {
        const newRoles = [...roles, ROLES.RECIPIENT_ACCOUNT_ADMIN, RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN];
        yield put(updateSelectedHealthSystemOnboardingRoles(newRoles));
      }
    } else if (input.removeRoleFromCurrentUser) {
      const roles = yield select(selectHealthSystemOnboardingRoles);
      if (roles && roles.indexOf(ROLES.RECIPIENT_ACCOUNT_ADMIN) > -1) {
        const indexOfAA = roles.indexOf(ROLES.RECIPIENT_ACCOUNT_ADMIN);
        roles.splice(indexOfAA, 1);
        yield put(updateSelectedHealthSystemOnboardingRoles(roles));
      }
    }
    yield put(completeInviteAccountAdminStepSuccess());
    
    const roles = yield select(selectHealthSystemOnboardingRoles);
    if (roles && roles.indexOf(ROLES.RECIPIENT_ACCOUNT_ADMIN) > -1) {
      yield put(updateOnboardingStep(ONBOARDING_STEPS.ACCOUNT_ADMIN_INFO));
    }

  } catch (e) {
    if (e.message?.includes('User cannot be') || e.message?.includes('A person cannot')) {
      yield put(completeRecipientStepFailure(logRocketUtil(e)));
    } else {
      logRocketUtil(e)
      const errorModel: ErrorModel = {
        message: COMPLETE_STEP_ERROR_MESSAGE,
        traceId: e.traceId
      }
      yield put(completeRecipientStepFailure(errorModel));
    }
  }
}

export function* completeAccountAdminInfoStepSaga () {
  try {
    const activeHealthSystemId = yield select(getActiveHealthSystemId);
    const input = yield select(getAccountAdminInfoStepInput);
    input.phone = unFormatPhone(input.phone);
    yield call(completeAccountAdminInfoStepRequest, input, activeHealthSystemId);
    yield put(completeAccountAdminInfoStepSuccess());
    yield put(getUserInfo());
    yield put(updateOnboardingStep(ONBOARDING_STEPS.ACCOUNT_ADMIN_CONFIRM_INFO));

  } catch (e) {
    yield put(completeRecipientStepFailure(logRocketUtil(e)));
  }
}

export function* getCoveredEntityInfoSaga () {
  try {
    const activeHealthSystemId = yield select(getActiveHealthSystemId);
    const response = yield call(fetchCoveredEntityInfo, activeHealthSystemId);
    yield put(getCoveredEntityInfoSuccess(response));
  } catch (e) {
    yield put(getCoveredEntityInfoFailure(logRocketUtil(e)));
  }
}

export function* getAccountAdminSummarySaga () {
  try {
    const activeHealthSystemId = yield select(getActiveHealthSystemId);
    const response = yield call(fetchAccountAdminSummary, activeHealthSystemId);
    response.accountAdminInfo.phone = formatPhone(response.accountAdminInfo.phone);
    yield put(getAccountAdminSummarySuccess(response));
  } catch (e) {
    yield put(getAccountAdminSummaryFailure(logRocketUtil(e)));
  }
}

export function* completeAccountAdminConfirmInfoStepSaga () {
  try {
    const activeHealthSystemId = yield select(getActiveHealthSystemId);
    yield call(completeAccountAdminConfirmInfoStepRequest, activeHealthSystemId);
    yield put(completeAccountAdminConfirmInfoStepSuccess());
    yield put(updateOnboardingStep(ONBOARDING_STEPS.ACCOUNT_ADMIN_INVITE_CONTROLLER));
  } catch (e) {
    yield put(completeRecipientStepFailure(logRocketUtil(e)));
  }
}

export function* completeInviteControllerStepSaga () {
  try {
    const activeHealthSystemId = yield select(getActiveHealthSystemId);
    const input: InviteUserStepInputModel = yield select(getInviteControllerStepInput);
    yield call(completeInviteControllerStepRequest, input, activeHealthSystemId);

    if (input.addRoleToCurrentUser) {
      const roles = yield select(selectHealthSystemOnboardingRoles);
      if (roles && roles.indexOf(ROLES.RECIPIENT_CONTROLLER) < 0) {
        const newRoles = [...roles, ROLES.RECIPIENT_CONTROLLER, RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.PAYMENT];
        yield put(updateSelectedHealthSystemOnboardingRoles(newRoles));
      }
    } else if (input.removeRoleFromCurrentUser) {
      const roles = yield select(selectHealthSystemOnboardingRoles);
      if (roles && roles.indexOf(ROLES.RECIPIENT_CONTROLLER) > -1) {
        const indexOfController = roles.indexOf(ROLES.RECIPIENT_CONTROLLER);
        roles.splice(indexOfController, 1);
        yield put(updateSelectedHealthSystemOnboardingRoles(roles));
      }
    }

    yield put(completeInviteControllerStepSuccess());

    const roles = yield select(selectHealthSystemOnboardingRoles);
    if (roles && roles.indexOf(ROLES.RECIPIENT_CONTROLLER) > -1) {
      yield put(updateOnboardingStep(ONBOARDING_STEPS.CONTROLLER_BANK_INFO));
    }

  } catch (e) {
    if (e.message?.includes('User cannot be') || e.message?.includes('User is already')) {
      const errorModel: ErrorModel = {
        message: e.message,
        traceId: e.traceId
      };
      yield put(completeRecipientStepFailure(errorModel));
    } else {
      logRocketUtil(e)
      const errorModel: ErrorModel = {
        message: COMPLETE_STEP_ERROR_MESSAGE,
        traceId: e.traceId
      }
      yield put(completeRecipientStepFailure(errorModel));
    }
  }
}

export function* completeControllerInfoStepSaga () {
  try {
    const activeHealthSystemId = yield select(getActiveHealthSystemId);
    const input = yield select(getControllerInfoStepInput);
    input.phone = unFormatPhone(input.phone);
    yield call(completeControllerInfoStepRequest, input, activeHealthSystemId);
    yield put(completeControllerInfoStepSuccess());
    yield put(getUserInfo());
    yield put(updateOnboardingStep(ONBOARDING_STEPS.CONTROLLER_CONFIRM_INFO));
  } catch (e) {
    yield put(completeRecipientStepFailure(logRocketUtil(e)));
  }
}

export function* completeControllerConfirmInfoStepSaga () {
  try {
    const activeHealthSystemId = yield select(getActiveHealthSystemId);
    yield call(completeControllerConfirmInfoStepRequest, activeHealthSystemId);
    yield put(completeControllerConfirmInfoStepSuccess());
    yield put(updateOnboardingStep(ONBOARDING_STEPS.CONTROLLER_BANK_INFO));
  } catch (e) {
    yield put(completeRecipientStepFailure(logRocketUtil(e)));
  }
}

export function* completeControllerBankInfoStepSaga () {
  try {
    const activeHealthSystemId = yield select(getActiveHealthSystemId);
    yield call(completeControllerBankInfoStepRequest, activeHealthSystemId);
    yield put(completeControllerBankInfoStepSuccess());
  } catch (e) {
    yield put(completeRecipientStepFailure(logRocketUtil(e)));
  }
}

export function* fetchAuthorizingOfficialSignUpSaga (action: any) {
  try {
    yield call(authorizingOfficialSignUpApi, action.payload)
    yield put(sendRequestForAuthorizingOfficialSignUpSuccess());
  } catch (e) {
    yield put(sendRequestForAuthorizingOfficialSignUpError(logRocketUtil(e)));
  }
}

export function* refreshDataAfterOnboardingSaga () {
  try {
    const healthSystemId = yield select(getActiveHealthSystemId);
    const isAo = yield select(makeSelectIsAuthorizingOfficial);
    yield put(checkOnboardingStatus(healthSystemId));
    yield put(getHealthSystems());
    if (isAo) {
      yield put(getCandidateCeList());
    }
  } catch (e) {
    // do nothing
  }
}

export function* getCandidateCeListSaga () {
  try {
    const response = yield call(getCandidateCeListRequest);
    const orphanedCeHealthSystem: CandidateHealthSystem[] = response.healthSystems.filter((hs: any) => hs.healthSystemId === undefined);
    const orphanedCes: CandidateCoveredEntity[] = orphanedCeHealthSystem && orphanedCeHealthSystem.length > 0 ? orphanedCeHealthSystem[0].ceList : [];
    const candidateHealthSystems: CandidateHealthSystem[] = response.healthSystems.filter((hs: any) => hs.healthSystemId !== undefined && hs.healthSystemId !== null);

    candidateHealthSystems.forEach((healthSystem: any) => {
      healthSystem.ceList.forEach((ce: any) => {
        if (ce.onboardState === 2) {
          healthSystem.partialOnboard = true;
        }
      })
    })
    const aoInfo = { aoFirstName: response.aoFirstName, aoLastName: response.aoLastName }
    yield put(getCandidateCeListSuccess(aoInfo, candidateHealthSystems, orphanedCes));
  } catch (e) {
    yield put(getCandidateCeListFailure(logRocketUtil(e)));
  }
}

export function* checkUniqueHealthSystemNameSaga () {
  try {
    const nameToCheck = yield select(getHealthSystemNameForCheck);
    const response = yield call(checkUniqueHealthSystemRequest, nameToCheck);
    const responseAsBoolean = (response === 'true' ? true : false) || false;
    yield put(checkUniqueHealthSystemNameSuccess(responseAsBoolean));
  } catch (e) {
    yield put(checkUniqueHealthSystemNameFailure(logRocketUtil(e)));
  }
}

export function* getHealthSystemAccountAdminSaga () {
  try {
    const healthSystemId = yield select(getActiveHealthSystemId);
    const response = yield call(getHealthSystemAccountAdminRequest, healthSystemId);
    yield put(getHealthSystemAccountAdminSuccess(response));
  } catch (e) {
    yield put(getHealthSystemAccountAdminFailure(logRocketUtil(e)));
  }
}

export function* getControllerStatusSaga () {
  try {
    const healthSystemId = yield select(getActiveHealthSystemId);
    const response = yield call(fetchControllerStatusRequest, healthSystemId);
    yield put(getControllerStatusSuccess(response));
  } catch (e) {
    logRocketUtil(e)
    const errorModel: ErrorModel = {
      message: COMPLETE_STEP_ERROR_MESSAGE,
      traceId: e.traceId
    }
    yield put(getControllerStatusFailure(errorModel));
  }
}

function* watchCheckOnboardingStatus () {
  yield takeEvery(CHECK_ONBOARDING_STATUS, checkOnboardingStatusSaga);
}

function* watchGetTermsAndConditionsSaga () {
  yield takeEvery(GET_TERMS_AND_CONDITIONS, getTermsAndConditionsSaga);
}


function* watchCompleteTermsAndConditionsStepSaga () {
  yield takeEvery(COMPLETE_TERMS_AND_CONDITIONS_STEP, completeTermsAndConditionsStepSaga);
}

function* watchCompleteInviteAccountAdminStepSaga () {
  yield takeEvery(COMPLETE_INVITE_ACCOUNT_ADMIN_STEP, completeInviteAccountAdminStepSaga);
}

function* watchCompleteAccountAdminInfoStepSaga () {
  yield takeEvery(COMPLETE_ACCOUNT_ADMIN_INFO_STEP, completeAccountAdminInfoStepSaga);
}

function* watchGetCoveredEntityInfoSaga () {
  yield takeEvery(GET_COVERED_ENTITY_INFO, getCoveredEntityInfoSaga);
}


function* watchGetAccountAdminSummarySaga () {
  yield takeEvery(GET_ACCOUNT_ADMIN_SUMMARY, getAccountAdminSummarySaga);
}

function* watchCompleteAccountAdminConfirmInfoStepSaga () {
  yield takeEvery(COMPLETE_ACCOUNT_ADMIN_CONFIRM_INFO_STEP, completeAccountAdminConfirmInfoStepSaga);
}

function* watchCompleteInviteControllerStepSaga () {
  yield takeEvery(COMPLETE_INVITE_CONTROLLER_STEP, completeInviteControllerStepSaga);
}

function* watchCompleteControllerInfoStepSaga () {
  yield takeEvery(COMPLETE_CONTROLLER_INFO_STEP, completeControllerInfoStepSaga);
}

function* watchCompleteControllerConfirmInfoStepSaga () {
  yield takeEvery(COMPLETE_CONTROLLER_CONFIRM_INFO_STEP, completeControllerConfirmInfoStepSaga);
}

function* watchCompleteControllerBankInfoStepSaga () {
  yield takeEvery(COMPLETE_CONTROLLER_BANK_INFO_STEP, completeControllerBankInfoStepSaga);
}

function* watchAuthorizingOfficialSignUp () {
  yield takeEvery(SEND_AUTHORIZING_OFFICIAL_SIGN_UP, fetchAuthorizingOfficialSignUpSaga)
}

function* watchRefreshDataAfterOnboardingSaga () {
  yield takeEvery(REFRESH_DATA_AFTER_ONBOARDING, refreshDataAfterOnboardingSaga);
}

function* watchGetCandidateCeListSaga () {
  yield takeEvery(GET_CANDIDATE_CE_LIST, getCandidateCeListSaga);
}

function* watchCheckUniqueHealthSystemNameSaga () {
  yield takeEvery(CHECK_UNIQUE_HEALTH_SYSTEM_NAME, checkUniqueHealthSystemNameSaga);
}

function* watchGetHealthSystemAccountAdminSaga () {
  yield takeEvery(GET_HEALTH_SYSTEM_ACCOUNT_ADMIN, getHealthSystemAccountAdminSaga);
}

function* watchGetControllerStatusSaga () {
  yield takeEvery(GET_CONTROLLER_STATUS, getControllerStatusSaga);
}

export default function* watchAllOnboarding () {
  yield all([
    watchCheckOnboardingStatus(),
    watchGetTermsAndConditionsSaga(),
    watchCompleteTermsAndConditionsStepSaga(),
    watchCompleteInviteAccountAdminStepSaga(),
    watchCompleteAccountAdminInfoStepSaga(),
    watchGetCoveredEntityInfoSaga(),
    watchGetAccountAdminSummarySaga(),
    watchCompleteAccountAdminConfirmInfoStepSaga(),
    watchCompleteInviteControllerStepSaga(),
    watchCompleteControllerInfoStepSaga(),
    watchCompleteControllerConfirmInfoStepSaga(),
    watchCompleteControllerBankInfoStepSaga(),
    watchAuthorizingOfficialSignUp(),
    watchRefreshDataAfterOnboardingSaga(),
    watchGetCandidateCeListSaga(),
    watchCheckUniqueHealthSystemNameSaga(),
    watchGetHealthSystemAccountAdminSaga(),
    watchGetControllerStatusSaga()
  ])
}
