import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Icon, Checkbox } from 'semantic-ui-react';
import { RootState } from '../../../store/RootState';
import Layout from '../Layout';
import { UserInfoModel } from '../../../store/applicationTypes';
import { completeControllerConfirmInfoStep, updateOnboardingStep } from '../../../store/onboarding/actionCreators';
import { makeSelectIsLoading } from '../../../store/onboarding/selectors';
import { AddressInfo } from '../AddressInfo';
import { ONBOARDING_STEPS } from '../../../store/onboarding/constants';

import '../../../themes/kalderos/Onboarding/Recipient/ConfirmController.scss';

interface ConfirmControllerProps  {
  controllerInfo: UserInfoModel;
  isLoading: boolean;
  errorText: string;
  completeControllerConfirmInfoStep: () => void;
  updateOnboardingStep: (step: number) => void;
}

interface ConfirmControllerState {
  confirmChecked: boolean,
}

export class ConfirmController extends React.Component<ConfirmControllerProps, ConfirmControllerState>{
  constructor (props: ConfirmControllerProps) {
    super(props);
    this.state = {
      confirmChecked: false,
    }
  }

  onNextClick = () => {
    this.props.completeControllerConfirmInfoStep();
  }

  onBackClick = () => {
    this.props.updateOnboardingStep(ONBOARDING_STEPS.CONTROLLER_INFO);
  }

  onConfirmCheck = () => {
    this.setState({ confirmChecked: !this.state.confirmChecked });
  }

  render () {
    const { controllerInfo } = this.props;
    return (
      <Layout 
        isLoading={this.props.isLoading}
        errorText={this.props.errorText}
        stepNumber={2}>
        {!this.props.isLoading &&
          <Fragment>
            <div className="onboarding-layout-header">
              <div className="title">2. Confirm your information</div>
              <div className="sub-title">
                Please review the information you provided and verify that it&apos;s correct before moving on to setting up payment for your company.
              </div>
            </div>
            <div className="onboarding-layout-children">
              <div className="onboarding-layout-body dr-controller-confirmation">
                <div className="controller-info">
                  <div className="section-header">Contact Information</div>
                  <div className="detail-field">
                    <div className="value" data-private>{controllerInfo?.firstName} {controllerInfo?.lastName}</div>
                    <div className="label">NAME</div>
                  </div>
                  <div className="detail-field">
                    <div className="value" data-private>{controllerInfo?.email}</div>
                    <div className="label">EMAIL</div>
                  </div>
                  <div className="detail-field">
                    <div className="value" data-private>{controllerInfo?.jobTitle}</div>
                    <div className="label">JOB TITLE</div>
                  </div>
                  <div className="detail-field multiple">
                    <div>
                      <div className="value" data-private>{controllerInfo?.phone}</div>
                      <div className="label">PHONE</div>
                    </div>
                    {controllerInfo?.phoneExtension &&
                      <div className="flex-grow">
                        <div className="value" data-private>{controllerInfo?.phoneExtension}</div>
                        <div className="label">EXT</div>
                      </div>
                    }
                  </div>
                  <AddressInfo address={controllerInfo?.address} label='OFFICE ADDRESS' />
                </div>
                <div className="confirm-check">
                  <div className="onboarding-checkbox">
                    <Checkbox data-private label="I hereby certify, to the best of my knowledge, that the information provided above is complete and correct."
                      checked={this.state.confirmChecked}
                      onChange={this.onConfirmCheck}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="onboarding-layout-footer">
              <div className="onboarding-layout-navigation">
                <button type="button" className="app-button" onClick={this.onBackClick}>
                  <Icon name="chevron left" size="large" />Back
                </button>
              </div>
              <div className="onboarding-layout-cta">
                <button className="app-button button-dark" disabled={!this.state.confirmChecked} onClick={this.onNextClick} type="button">Confirm and Next</button>
              </div>
            </div>
          </Fragment>
        }
      </Layout>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  controllerInfo: state.AccountInformationState.userInfo,
  isLoading: makeSelectIsLoading(state),
  errorText: state.OnboardingState.errorText,
});

const mapDispatchToProps = (dispatch: any) => ({
  completeControllerConfirmInfoStep: () => dispatch(completeControllerConfirmInfoStep()),
  updateOnboardingStep: (step: number) => dispatch(updateOnboardingStep(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmController);