import React, { Fragment } from 'react';
import cx from 'classnames';

import { ChevronLeft, ChevronRight } from 'react-feather';
import { connect } from 'react-redux';
import { Transition, TransitionablePortal } from 'semantic-ui-react';

import '../../../../themes/kalderos/Common/Drawer/Drawer.scss';

interface PrimaryDrawerProps {
  isOpen: boolean;
  children: React.ReactNode;
  hasSecondaryDrawer?: boolean;
  secondaryDrawer?: React.ReactNode;
  hasMultipleAttached?: boolean;
  secondaryDrawerButtonText?: string | number;
  closeDrawer: () => void;
  onClickOutside: () => void;
}

const PrimaryDrawer: React.FunctionComponent<PrimaryDrawerProps> = (props: PrimaryDrawerProps) => {
  const [isSecondaryDrawerOpen, setIsSecondaryDrawerOpen] = React.useState(false);
  const { isOpen, closeDrawer, children,
    hasSecondaryDrawer, secondaryDrawer, hasMultipleAttached, secondaryDrawerButtonText, onClickOutside } = props;

  function onClose () {
    if (isSecondaryDrawerOpen) {
      setIsSecondaryDrawerOpen(false);
    }
    closeDrawer();
  }
  return (
    <Fragment>
      {isOpen &&
        <div
          onClick={(e) => { e.stopPropagation(); onClickOutside();}}
          className={cx('drawer outside-zone active')} // , { 'active': false }
        />}
      <TransitionablePortal
        closeOnDocumentClick={false}
        open={isOpen}
        onClose={() => onClose()}
        transition={{ animation: 'fade left', duration: 400 }}>
        <div className={cx('drawer portal', {'hasSecondary': (hasSecondaryDrawer && !isSecondaryDrawerOpen), 'hasMultipleAttached': hasMultipleAttached})}>
          {(hasSecondaryDrawer && !isSecondaryDrawerOpen) && <div className="secondary-tab" />}
          {children}

          {hasSecondaryDrawer &&
            <button
              onClick={() => setIsSecondaryDrawerOpen(!isSecondaryDrawerOpen)}
              type="button"
              className={cx('secondary-button', { 'secondary-drawer-open': isSecondaryDrawerOpen, 'with-text': !isSecondaryDrawerOpen && secondaryDrawerButtonText })}>
              {isSecondaryDrawerOpen ? <ChevronRight size={16} /> : <span><ChevronLeft size={16} /> {secondaryDrawerButtonText}</span>}
            </button>
          }
          {hasSecondaryDrawer &&
          <Transition visible={isSecondaryDrawerOpen} duration={400} animation="fade left">
            <div className={cx('secondary-drawer', { 'hasMultipleAttached': hasMultipleAttached})}>
              {secondaryDrawer}
            </div>
          </Transition>
          }
        </div>
      </TransitionablePortal>
    </Fragment>

  )

}

export default connect(null, null )(PrimaryDrawer);
