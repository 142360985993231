import getUserToken, { removeUserToken } from '../localstore/LocalStore';
import jwt from 'jsonwebtoken';

export const fetchWrapper = (url: any, isAuthenticated: Boolean, options?: any) => {
  if (!options) {
    options = {};
  }
  
  const token = getUserToken();
  if (token != null){
    const decoded = jwt.decode(token, {complete: true, json:true}) as {[key:string]:any};
    if (decoded !== null){
      const jwtExpiry = decoded.payload.exp;
      const currentTime = new Date().getTime()/1000;
      if (jwtExpiry < currentTime){
        removeUserToken();
        window.location.href ='/logout';
      }
    }
  }

  if (isAuthenticated) {
    if (!options.headers) {
      options.headers = {};
    }
    options.headers['Authorization'] = 'Bearer ' + token;
  }
  return fetch(url, options);
}

export default fetchWrapper;

