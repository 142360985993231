import React, { Fragment } from 'react';
import { Modal } from 'semantic-ui-react';
import {  CloseIconAlt } from '../../../themes/kalderos/Common/Icons';


export interface InformationalModalProps {
  header: string;
  message: string;
  handleModalClose:() => void;
}

export const InformationalModal: React.FunctionComponent<InformationalModalProps>=({ header, message, handleModalClose }) => {
  return (
    <Modal open={true} className='informational-modal'>
      <Fragment>
        <div className='flex-column informational-modal-container'>
          <div className='flex-row informational-modal-header-container'>
            <div className='beta informational-modal-header'>{header}</div>
            <button data-testid='modalClose' className='modal-close-x-icon cursor-pointer' onClick={handleModalClose} type="button">
              <CloseIconAlt />
            </button>
          </div>
          <div className='informational-modal-body'>
            <div className='delta informational-modal-message'>{message}</div>
            <button data-testid='modalClose' onClick={handleModalClose} className='app-button button-dark informational-modal-close-btn' type="button">Close</button>
          </div>
        </div>
      </Fragment>
    </Modal>
  )
}

