import { LoginModel, UserModel, UserErrorModel } from './types';

export const VALIDATE_USER = 'VALIDATE_USER'
export const VALIDATE_USER_ERROR = 'VALIDATE_USER_ERROR'

export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS'
export const CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS'

export const SEND_STARTUP_REQUESTS = 'SEND_STARTUP_REQUESTS'
export const STARTUP_COMPLETE = 'STARTUP_COMPLETE'

export enum USER_TYPE {
  PAYER = 'DDM.DP',
  RECIPIENT = 'DDM.DR',
  STAKEHOLDER = 'DDM.DS',
  REVIEW = 'GrappaCoveredEntity'
}

interface ValidateUserAction {
  type: typeof VALIDATE_USER
  payload: LoginModel
}

interface ValidateUserErrorAction {
  type: typeof VALIDATE_USER_ERROR
  payload: UserErrorModel
}

interface UpdateUserDetailsAction {
  type: typeof UPDATE_USER_DETAILS
  payload: {
    user: UserModel
  }
}

interface ClearUserDetailsAction {
  type: typeof CLEAR_USER_DETAILS
}

interface SendStartupRequestsAction {
  type: typeof SEND_STARTUP_REQUESTS
}

interface StartupCompleteAction {
  type: typeof STARTUP_COMPLETE
}

export type LoginActionTypes =
  ValidateUserAction |
  ValidateUserErrorAction |
  UpdateUserDetailsAction |
  ClearUserDetailsAction |
  SendStartupRequestsAction |
  StartupCompleteAction;