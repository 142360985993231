import RequestConfig from '../../app.config';
import fetchWrapper from '../FetchWrapper';
import { commonResponseHandler } from '../../utils/responseUtils';
import { HealthSystemSearchInput } from '../../store/teammanagement/types';
import { createUrlParametersString } from '../../utils/requestUtil';

export const healthSystems = async () => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/healthSystem`, true);
  if (!response.ok && response.status === 400) {
    const data = await response.json();
    return data;
  } else {
    return commonResponseHandler(response);
  }
}

export const healthSystemBasicInfo = async (healthSystemId: number) => {
  const params = new URLSearchParams({ healthSystemId: healthSystemId.toString() });
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/healthSystem/basicInfo?${params}`, true);
  if (!response.ok && response.status === 400) {
    const data = await response.json();
    return data;
  } else {
    return commonResponseHandler(response);
  }
}

export const healthSystemInfo = async (healthSystemId: number) => {
  const params = new URLSearchParams({ healthSystemId: healthSystemId.toString() });
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/healthSystem/healthSystemInfo?${params}`, true);
  return commonResponseHandler(response);
};


export const healthSystemSearch = async (input: HealthSystemSearchInput) => {
  const name = input.name ? '&' + createUrlParametersString({ name: input.name }) : '';
  const email = input.email ? '&' + createUrlParametersString({ email: input.email }) : '';
  const id340b = input.id340b ? '&' +  createUrlParametersString({ id340b: input.id340b }) : '';

  const params = `${name}${email}${id340b}`;
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/healthSystem/search?${params}`, true);
  return commonResponseHandler(response);
};

