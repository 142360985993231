import React, { ChangeEvent } from 'react';
import { Form } from 'semantic-ui-react';
import cx from 'classnames';

import StateDropdown from '../../common/StateDropdown';
import { AddressModel } from '../../../store/applicationTypes';

import '../../../themes/kalderos/Onboarding/AddressForm.scss'


interface AddressFormProps {
  address?: AddressModel,
  values: any,
  setFieldValue: (x: any, y: any) => void;
  handleChange?: { (e: ChangeEvent<any>): void; <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any> ? void : (e: string | ChangeEvent<any>) => void };
  namePrefix?: string
  errors: any
  touched: any,
  handleBlur?: {(e: ChangeEvent<any>): void; <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any> ? void : (e: string | ChangeEvent<any>) => void}; 

}

const AddressFields: React.FunctionComponent<AddressFormProps> = ({ values, setFieldValue, handleChange, handleBlur, errors, touched, namePrefix = 'address' }) => {
  const countries = [{ key: 1, text: 'US', value: 'US' }];
  const address1 = `${namePrefix}.address1`;
  const address2 = `${namePrefix}.address2`;
  const city = `${namePrefix}.city`;
  const state = `${namePrefix}.state`;
  const country = `${namePrefix}.country`;
  const zip = `${namePrefix}.zip`;
  const addressErrors = errors[namePrefix];
  const addressTouched = touched[namePrefix];
  return (
    <React.Fragment>
      <Form.Input data-private name={address1} width={11} label="Office Address" defaultValue={values?.address1} onChange={handleChange} onBlur={handleBlur} className={cx({'input-error': addressErrors && addressErrors['address1'] && addressTouched && addressTouched['address1']})} />
      {addressErrors &&  addressErrors['address1'] && addressTouched && addressTouched['address1'] && <div className="error-text">Office address invalid</div>}
      <Form.Input data-private name={address2} width={11} label="Floor, Suite, PO Box (optional)" defaultValue={values?.address2} onChange={handleChange} onBlur={handleBlur}  className={cx({'input-error':  addressErrors && addressErrors['address2'] && addressTouched && addressTouched['address2']})} />
      {addressErrors &&  addressErrors['address2'] && addressTouched && addressTouched['address2'] && <div className="error-text">Floor, Suite, PO Box invalid</div>}
      <Form.Group>
        <Form.Input data-private name={city} width={8} label="City" defaultValue={values?.city} onChange={handleChange} onBlur={handleBlur}  className={cx({'input-error':  addressErrors && addressErrors['city'] && addressTouched && addressTouched['city']})} />
        <Form.Field data-private name={state} width={3} >
          <label>State</label>
          <StateDropdown onChange={(e, d) => setFieldValue(`${namePrefix}.state`, d.value as string)} value={values?.state} upward={true} />
        </Form.Field>
        <Form.Select data-private name={country} width={4} fluid label="Country" options={countries} onChange={(e, d) => { setFieldValue('country', d.value as string) }} value={countries[0].value} disabled />
      </Form.Group>
      {addressErrors &&  addressErrors['city'] && addressTouched && addressTouched['city'] && <div className="error-text">City invalid</div>}
      <Form.Input data-private name={zip} width={4} label="Zip Code" defaultValue={values?.zip} onChange={handleChange} onBlur={handleBlur} className={cx({'input-error':  addressErrors && addressErrors['zip'] && addressTouched && addressTouched['zip']})} />
      {addressErrors &&  addressErrors['zip'] && addressTouched && addressTouched['zip'] && <div className="error-text">Zip Code invalid</div>}
    </React.Fragment>
  )
}

export default AddressFields;