import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import CloseButton from '../../common/CloseButton';
import { SUPPORT_PHONE } from '../../../store/applicationConstants';
export interface AddPharmacyDialogProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const AddPharmacyDialog: React.FunctionComponent<AddPharmacyDialogProps> = ({ isOpen, closeModal }) => {
  return (
    <Modal className="app-modal"
      open={isOpen}>
      <div className="app-modal-header">Add Pharmacy</div>
      <div className="modal-close-button">
        <CloseButton isDisabled={false} onClick={closeModal} />
      </div>
      <div className="delta">
        If you would like to add a pharmacy to this covered entity’s list, please contact our support team at {SUPPORT_PHONE}.
      </div>
      <div className="app-modal-btn-container">
        <Button onClick={closeModal} className="app-button button-dark">
          Close
        </Button>
      </div>
    </Modal>
  )
};

export default AddPharmacyDialog;
