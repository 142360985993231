import {
  RemittanceActionTypes,
  GET_REMITTANCE_INVOICE_DETAILS_FAILURE,
  GET_REMITTANCE_INVOICE_DETAILS_SUCCESS,
  GET_REMITTANCE_INVOICE_DETAILS,
  GET_REMITTANCE_MONTH_SUMMARY_FAILURE,
  GET_REMITTANCE_MONTH_SUMMARY_SUCCESS,
  GET_REMITTANCE_MONTH_SUMMARY,
  GET_REMITTANCE_PERIOD_DETAILS_FAILURE,
  GET_REMITTANCE_PERIOD_DETAILS_SUCCESS,
  GET_REMITTANCE_PERIOD_DETAILS,
  GET_REMITTANCE_SUMMARY_PERIODS_FAILURE,
  GET_REMITTANCE_SUMMARY_PERIODS_SUCCESS,
  GET_REMITTANCE_SUMMARY_PERIODS,
  GET_REMITTANCE_TRANSACTION_COUNTS_FAILURE,
  GET_REMITTANCE_TRANSACTION_COUNTS_SUCCESS,
  GET_REMITTANCE_TRANSACTION_COUNTS,
  GET_REMITTANCE_TRANSACTIONS_FAILURE,
  GET_REMITTANCE_TRANSACTIONS_SUCCESS,
  GET_REMITTANCE_TRANSACTIONS,
  REMITTANCE_DETAILS_TAB,
  RESET_REMITTANCE_DETAILS,
  RESET_REMITTANCE_TRANSACTIONS,
  UPDATE_REMITTANCE_PERIOD_DETAILS_FILTER,
  UPDATE_REMITTANCE_TRANSACTIONS_FILTER,
  UPDATE_REMITTANCE_TRANSACTIONS_PAGE,
  UPDATE_REMITTANCE_TRANSACTIONS_SORT,
} from './constants';
import {
  RemittanceDetailsCountsModel,
  RemittanceDetailsHeaderFilterModel,
  RemittanceMonthSummary,
  RemittancePeriodSummary,
  RemittancePeriodSummaryDetail,
  RemittanceSummaryPeriodByYear,
} from './types';
import { SortingFilter } from '../appcommon/types';
import { ErrorModel } from '../applicationTypes';
import { DiscountRequestResponseModel, TransactionsFilterModel } from '../transactionscommon/types';

export function getRemittanceSummaryPeriods (): RemittanceActionTypes {
  return {
    type: GET_REMITTANCE_SUMMARY_PERIODS
  }
}

export function getRemittanceSummaryPeriodsSuccess (remittanceSummaryPeriods: RemittanceSummaryPeriodByYear[]): RemittanceActionTypes {
  return {
    type: GET_REMITTANCE_SUMMARY_PERIODS_SUCCESS,
    payload: remittanceSummaryPeriods
  }
}

export function getRemittanceSummaryPeriodsFailure (error: ErrorModel): RemittanceActionTypes {
  return {
    type: GET_REMITTANCE_SUMMARY_PERIODS_FAILURE,
    payload: error
  }
}

export function getRemittanceMonthSummary (year: number, month: number): RemittanceActionTypes {
  return {
    type: GET_REMITTANCE_MONTH_SUMMARY,
    payload: {
      year: year,
      month: month
    }
  }
}

export function getRemittanceMonthSummarySuccess (summary: RemittanceMonthSummary): RemittanceActionTypes {
  return {
    type: GET_REMITTANCE_MONTH_SUMMARY_SUCCESS,
    payload: summary
  }
}

export function getRemittanceMonthSummaryFailure (error: ErrorModel): RemittanceActionTypes {
  return {
    type: GET_REMITTANCE_MONTH_SUMMARY_FAILURE,
    payload: error
  }
}

export function getRemittancePeriodDetails (year: number, month: number, period: number): RemittanceActionTypes {
  return {
    type: GET_REMITTANCE_PERIOD_DETAILS,
    payload: {
      year,
      month,
      period
    }
  }
}

export function getRemittancePeriodDetailsSuccess (details: RemittancePeriodSummary): RemittanceActionTypes {
  return {
    type: GET_REMITTANCE_PERIOD_DETAILS_SUCCESS,
    payload: details
  }
}

export function getRemittancePeriodDetailsFailure (error: ErrorModel): RemittanceActionTypes {
  return {
    type: GET_REMITTANCE_PERIOD_DETAILS_FAILURE,
    payload: error
  }
}

export function updateRemittancePeriodDetailsFilter (filter: RemittanceDetailsHeaderFilterModel): RemittanceActionTypes {
  return {
    type: UPDATE_REMITTANCE_PERIOD_DETAILS_FILTER,
    payload: filter
  }
}

export function getRemittanceInvoiceDetails (remittanceId: number, participantId: string): RemittanceActionTypes {
  return {
    type: GET_REMITTANCE_INVOICE_DETAILS,
    payload: {
      remittanceId,
      participantId,
    }
  }
}

export function getRemittanceInvoiceDetailsSuccess (details: RemittancePeriodSummaryDetail): RemittanceActionTypes {
  return {
    type: GET_REMITTANCE_INVOICE_DETAILS_SUCCESS,
    payload: details
  }
}

export function getRemittanceInvoiceDetailsFailure (error: ErrorModel): RemittanceActionTypes {
  return {
    type: GET_REMITTANCE_INVOICE_DETAILS_FAILURE,
    payload: error
  }
}

export function getRemittanceTransactionsCounts (): RemittanceActionTypes {
  return {
    type: GET_REMITTANCE_TRANSACTION_COUNTS,
  }
}

export function getRemittanceTransactionsCountsSuccess (counts: RemittanceDetailsCountsModel): RemittanceActionTypes {
  return {
    type: GET_REMITTANCE_TRANSACTION_COUNTS_SUCCESS,
    payload: counts
  }
}

export function getRemittanceTransactionsCountsFailure (error: ErrorModel): RemittanceActionTypes {
  return {
    type: GET_REMITTANCE_TRANSACTION_COUNTS_FAILURE,
    payload: error
  }
}


export function getRemittanceTransactions (tab: REMITTANCE_DETAILS_TAB): RemittanceActionTypes {
  return {
    type: GET_REMITTANCE_TRANSACTIONS,
    payload: tab
  }
}

export function getRemittanceTransactionsSuccess (response: DiscountRequestResponseModel): RemittanceActionTypes {
  return {
    type: GET_REMITTANCE_TRANSACTIONS_SUCCESS,
    payload: response
  }
}

export function getRemittanceTransactionsFailure (error: ErrorModel): RemittanceActionTypes {
  return {
    type: GET_REMITTANCE_TRANSACTIONS_FAILURE,
    payload: error
  }
}

export function updateRemittanceTransactionsPage (page: number): RemittanceActionTypes {
  return {
    type: UPDATE_REMITTANCE_TRANSACTIONS_PAGE,
    payload: page
  }
}

export function updateRemittanceTransactionsSort (sortFilter: SortingFilter): RemittanceActionTypes {
  return {
    type: UPDATE_REMITTANCE_TRANSACTIONS_SORT,
    payload: sortFilter
  }
}

export function updateRemittanceTransactionsFilter (filter: TransactionsFilterModel): RemittanceActionTypes {
  return {
    type: UPDATE_REMITTANCE_TRANSACTIONS_FILTER,
    payload: filter
  }
}

export function resetRemittanceDetails (): RemittanceActionTypes {
  return {
    type: RESET_REMITTANCE_DETAILS
  }
}

export function resetRemittanceTransactions (): RemittanceActionTypes {
  return {
    type: RESET_REMITTANCE_TRANSACTIONS
  }
}
