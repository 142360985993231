import React, { Fragment } from 'react';
import { Dropdown, Table } from 'semantic-ui-react';
import cx from 'classnames';

import ManufacturerReviewAdvancedFilters from './ManufacturerReviewAdvancedFilters';
import DataPopup from '../../../common/DataPopup';
import DisputeCodeList from '../../../common/DisputeCodeList';
import ErrorImage from '../../../common/errors/ErrorImage';
import { FilterValueSummary } from '../../../common/filter/FilterToggleButton';
import LoadingBar from '../../../common/LoadingBar';
import SortIconSet from '../../../common/SortIconSet';
import StatusLabel from '../../../common/StatusLabel';
import EmptyTransactions from '../../../common/transactions/EmptyTransactions';
import TransactionsTablePagingMenu from '../../../common/transactions/TransactionsTablePagingMenu';
import { SortingFilter } from '../../../../store/appcommon/types';
import { NO_DISPUTE_FILTER_RESULTS_HEADER, NO_DISPUTE_FILTER_RESULTS_MESSAGE, OPEN_DISPUTES_TAB, STATE_RESPONSE } from '../../../../store/mdrp/disputescenter/constants';
import { ClaimModel, ManufacturerDisputesFilterModel } from '../../../../store/mdrp/disputescenter/types';
import { UI_PAGE_SIZE } from '../../../../store/requestcenter/constants';
import NeutralImage from '../../../../themes/kalderos/static/media/neutral-green.svg';
import { formatMMDDYYYY } from '../../../../utils/dateHelper';
import { formatEmpty, formatRebateQuarter } from '../../../../utils/dataUtil';
import { formatDollar, formatMdrpUnits, formatNdc11 } from '../../../../utils/numberFormatHelper';

export interface ManufacturerReviewTableProps {
  activeIndex: number;
  claims: ClaimModel[];
  currentPage: number;
  errorHeader: string;
  errorMessage: string;
  filters: ManufacturerDisputesFilterModel;
  filterSummary: FilterValueSummary[];
  handleCellSorting: (filter: SortingFilter) => void;
  handleInlineResponseClick: (claim: ClaimModel, response: string) => void;
  handlePageChange: (e: any, d: any) => void;
  isFilterOpen: boolean;
  isLoading: boolean;
  onApplyFilters: (values: ManufacturerDisputesFilterModel) => void;
  onFilterClear: () => void;
  onFilterToggle: (isOpen: boolean) => void;
  onRowClick: (id: number) => void;
  selectedClaimIndex: number | null;
  tableHeaderRef: any;
  totalCount: number;
}

const ManufacturerReviewTable = (props: ManufacturerReviewTableProps) => {
  const { activeIndex, claims, currentPage, errorHeader, errorMessage, filters, filterSummary, handleCellSorting, handleInlineResponseClick, handlePageChange, 
    isFilterOpen, isLoading, onApplyFilters, onFilterClear, onFilterToggle, onRowClick, selectedClaimIndex, tableHeaderRef, totalCount } = props;

  const areFiltersApplied = Object.values(filters).some(filter => (filter !== null && filter !== ''));
  const filterCount = filterSummary.length;

  const getClaimsStatusLabel = () => {
    if (activeIndex === OPEN_DISPUTES_TAB.NEW) {
      return <StatusLabel text="New Dispute" color="lightblue" />
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      {(claims.length === 0 && !isLoading && !areFiltersApplied && totalCount === 0) ?
        <ErrorImage
          header={errorHeader}
          image={NeutralImage}
          message={errorMessage}
        /> :
        <div className="mfr-review-table">
          <div className="table-header basic" ref={tableHeaderRef}>
            <TransactionsTablePagingMenu
              className="mfr-review-table-paging-menu"
              currentPage={currentPage}
              filterCount={filterCount}
              filterSummary={filterSummary}
              handlePageChange={handlePageChange}
              isDisabled={isLoading}
              isFilterOpen={isFilterOpen}
              onFilterClear={onFilterClear}
              onFilterToggle={onFilterToggle}
              pageSize={UI_PAGE_SIZE}
              showFilterToggleButton
              totalCount={totalCount}
            />
            {isFilterOpen && 
              <ManufacturerReviewAdvancedFilters
                filters={filters}
                isLoading={isLoading}
                onApplyFilter={onApplyFilters}
              />
            }
          </div>
          <div className={cx({'data-table-with-action-container mfr-review-table-with-action-container': activeIndex === OPEN_DISPUTES_TAB.NEW})}>
            <div className="data-table-wrapper">
              <Table className="data-table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className="eta header-cell status-header">
                      Status
                    </Table.HeaderCell>
                    <Table.HeaderCell className="eta header-cell">
                      Rebate Qtr
                      <SortIconSet field="rebateQtr" handleCellSorting={handleCellSorting} isDisabled={isLoading} />
                    </Table.HeaderCell>
                    <Table.HeaderCell className="eta header-cell">
                      State ICN
                      <SortIconSet field="icn" handleCellSorting={handleCellSorting} isDisabled={isLoading} />
                    </Table.HeaderCell>
                    <Table.HeaderCell className="eta header-cell">
                      Program Name
                    </Table.HeaderCell>
                    <Table.HeaderCell className="eta header-cell">
                      NDC11
                      <SortIconSet field="ndC11" handleCellSorting={handleCellSorting} isDisabled={isLoading} />
                    </Table.HeaderCell>
                    <Table.HeaderCell className="eta header-cell">
                      Product Name
                    </Table.HeaderCell>
                    <Table.HeaderCell className="eta header-cell text-align-right">
                      Units Invoiced
                      <SortIconSet field="units" handleCellSorting={handleCellSorting} isDisabled={isLoading} />
                    </Table.HeaderCell>
                    <Table.HeaderCell className="eta header-cell text-align-right">
                      Units Labeler Accepted
                    </Table.HeaderCell>
                    <Table.HeaderCell className="eta header-cell text-align-right">
                      Dispute Amount
                    </Table.HeaderCell>
                    <Table.HeaderCell className="eta header-cell">
                      CMS Dispute Codes
                    </Table.HeaderCell>
                    <Table.HeaderCell className="eta header-cell">
                      Date of Service
                      <SortIconSet field="dateOfService" handleCellSorting={handleCellSorting} isDisabled={isLoading} />
                    </Table.HeaderCell>
                    <Table.HeaderCell className="eta header-cell">
                      Provider NPI
                      <SortIconSet field="pharmacyNpi" handleCellSorting={handleCellSorting} isDisabled={isLoading} />
                    </Table.HeaderCell>
                    <Table.HeaderCell className="eta header-cell">
                      Rx Number
                      <SortIconSet field="rxNumber" handleCellSorting={handleCellSorting} isDisabled={isLoading} />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {claims.length > 0 && claims.map((claim: any, index) => {
                    return (
                      <Table.Row active={selectedClaimIndex === claim.index} className="selectable-row" key={claim.id} onClick={() => onRowClick(claim.index)}>
                        <Table.Cell>{getClaimsStatusLabel()}</Table.Cell>
                        <Table.Cell>{formatRebateQuarter(claim.rebateQtr)}</Table.Cell>
                        <Table.Cell>{formatEmpty(claim.icn)}</Table.Cell>
                        <Table.Cell>
                          <DataPopup
                            content={formatEmpty(claim.programName)}
                            id={`program-name-${index}`}
                          />
                        </Table.Cell>
                        <Table.Cell>{formatNdc11(claim.ndC11)}</Table.Cell>
                        <Table.Cell>
                          <DataPopup
                            content={formatEmpty(claim.productName)}
                            id={`product-name-${index}`}
                          />
                        </Table.Cell>
                        <Table.Cell className="text-align-right">{formatMdrpUnits(claim.units)}</Table.Cell>
                        <Table.Cell className="text-align-right">{formatMdrpUnits(claim.labelerAcceptedUnits)}</Table.Cell>
                        <Table.Cell className="text-align-right">{formatDollar(claim.disputeAmount)}</Table.Cell>
                        <Table.Cell className="dispute-code-cell">
                          <DisputeCodeList disputeCodes={claim.combinedDisputes} isTableList />
                        </Table.Cell>
                        <Table.Cell>{formatMMDDYYYY(claim.dateOfService)}</Table.Cell>
                        <Table.Cell>{formatEmpty(claim.pharmacyNpi)}</Table.Cell>
                        <Table.Cell>{formatEmpty(claim.rxNumber)}</Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table>
            </div>

            <div className="action-table-wrapper">
              <Table className="action-table absolute-right mfr-review-action-table">
                <Table.Header>
                  <Table.Row className='claims-review-table-header-row'>
                    <Table.HeaderCell className='eta header-cell'>
                      Dispute Response
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {claims.length > 0 && claims.map((claim: any) => {
                    return (
                      <Table.Row key={claim.id}>
                        <Table.Cell className="action-cell">
                          <div className="transaction-select-container">
                            {claim.isLoading === true && <LoadingBar />}
                            <Dropdown
                              className="transactions-select-action"
                              direction='right'
                              trigger={claim.stateResponse === STATE_RESPONSE.NONE ?
                                <span className="action-text">Needs Review</span> :
                                <span className={cx('status-text', {green: claim.stateResponse === STATE_RESPONSE.ACCEPT, red: claim.stateResponse === STATE_RESPONSE.CHALLENGE})}>{claim.stateResponse}</span>
                              }>
                              <Dropdown.Menu className="action-menu">
                                <Dropdown.Item 
                                  className="action-inline-menu-text"
                                  onClick={() => handleInlineResponseClick(claim, 'Accept')}
                                  text="Accept"
                                />
                                <Dropdown.Item
                                  className="action-inline-menu-text"
                                  onClick={() => handleInlineResponseClick(claim, 'Challenge')}
                                  text="Challenge"
                                />
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table>
            </div>
          </div>
          {(claims.length === 0 && !isLoading && areFiltersApplied) &&
            <EmptyTransactions
              emptyTransactionsHeader={NO_DISPUTE_FILTER_RESULTS_HEADER}
              emptyTransactionsMessage={NO_DISPUTE_FILTER_RESULTS_MESSAGE}
            />
          }
        </div>
      }
    </Fragment>
  );
};

export default ManufacturerReviewTable;
