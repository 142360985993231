import { LoginModel, UserModel, UserErrorModel } from './types';
import { 
  LoginActionTypes, 
  VALIDATE_USER, 
  UPDATE_USER_DETAILS, 
  VALIDATE_USER_ERROR, 
  CLEAR_USER_DETAILS,
  SEND_STARTUP_REQUESTS,
  STARTUP_COMPLETE
} from './constants';

export function validateUser (loginModel: LoginModel): LoginActionTypes {
  return {
    type: VALIDATE_USER,
    payload: loginModel
  }
}

export function validateUserError (userErrorModel: UserErrorModel): LoginActionTypes {
  return {
    type: VALIDATE_USER_ERROR,
    payload: userErrorModel
  }
}

export function updateUserDetails (userModel: UserModel): LoginActionTypes {
  return {
    type: UPDATE_USER_DETAILS,
    payload: {
      user: userModel
    }
  }
}

export function clearUserDetails (): LoginActionTypes {
  return {
    type: CLEAR_USER_DETAILS
  }
}

export function sendStartupRequests (): LoginActionTypes {
  return {
    type: SEND_STARTUP_REQUESTS
  }
}

export function startupComplete (): LoginActionTypes {
  return {
    type: STARTUP_COMPLETE
  }
}