import React, { Fragment } from 'react';
import { formatMMDDYYYY, getEndOfTheDayNonUTC, getStartOfTheDayNonUTC } from '../../utils/dateHelper';
import { TRANSACTION_FILTER_LABELS } from '../../store/transactionscommon/constants';
import { UI_PAGE_SIZE } from '../../store/reversals/constants';
import ReversalsDateRangeFilter from './ReversalsDateRangeFilter';
import { AdjustReasonsModel } from '../../store/appcommon/types';
import TransactionsTablePagingMenu from '../common/transactions/TransactionsTablePagingMenu';
import { FilterValueSummary } from '../common/filter/FilterToggleButton';
import TransactionFilters from '../common/transactions/TransactionFilters';
import { TransactionsFilterModel } from '../../store/transactionscommon/types';

type focusedInputType = 'startDate' | 'endDate' | null;

export interface ReversalsTransactionsTableHeaderProps {
  filter: TransactionsFilterModel;
  currentPage: number,
  totalCount: number,
  showDateFilter: boolean;
  isLoading: boolean;
  adjustReasons: AdjustReasonsModel;
  handlePageChange: (page: number) => void;
  handleApplyFilter: (filter: TransactionsFilterModel) => void;
}

interface ReversalsTransactionsTableHeaderState {
  isFilterOpen: boolean;
  focusedInput: focusedInputType;
}

export class ReversalsTransactionsTableHeader extends React.Component<ReversalsTransactionsTableHeaderProps, ReversalsTransactionsTableHeaderState> {
  tableHeaderRef: React.RefObject<HTMLDivElement>;

  constructor (props: Readonly<ReversalsTransactionsTableHeaderProps>) {
    super(props)
    this.state = {
      isFilterOpen: false,
      focusedInput: null
    }
    this.tableHeaderRef = React.createRef<HTMLDivElement>();
  }

  componentDidMount () {
    document.addEventListener('mouseup', (e) => this.handleMouseUp(e));
  }

  componentWillUnmount () {
    document.removeEventListener('mouseup', (e) => this.handleMouseUp(e));
  }

  handleMouseUp = (event: MouseEvent) => {
    if (this.state.isFilterOpen) {
      if (this.tableHeaderRef && !this.tableHeaderRef.current?.contains(event.target as Node) && !this.props.isLoading) {
        this.setState({ isFilterOpen: false })
      }
    }
  }

  handlePageChange = (e: any, d: any) => {
    const nextPage = d.value === 'page-left' ? this.props.currentPage - 1 : this.props.currentPage + 1;
    this.props.handlePageChange(nextPage);
  }

  handleFocusChange = (input: focusedInputType) => {
    if (this.props.isLoading && input === null) {
      this.setState({ focusedInput: 'endDate' });
    } else {
      this.setState({ focusedInput: input });
    }
  }

  handleFilterToggle = (isOpen: boolean) => {
    this.setState({ isFilterOpen: isOpen });
  }

  handleApplyTableFilter = (filter: TransactionsFilterModel) => {
    this.props.handleApplyFilter(filter);
  }

  handleApplyDateFilter = (startDate: Date, endDate: Date) => {
    const newFilter: TransactionsFilterModel = {
      ...this.props.filter,
      startDate: getStartOfTheDayNonUTC(startDate),
      endDate: getEndOfTheDayNonUTC(endDate),
    }
    this.props.handleApplyFilter(newFilter);

  }

  getFilterSummary = () => {
    const summary: FilterValueSummary[] = [];

    Object.keys(this.props.filter).forEach((key) => {
      if (key !== 'startDate' && key !== 'endDate') {
        const val = (this.props.filter as { [key: string]: any })[key]

        if (Array.isArray(val)) {
          summary.push({ name: TRANSACTION_FILTER_LABELS[key], values: val })
        } else if (val) {
          if (key === 'fillStartDate' || key === 'fillEndDate') {
            summary.push({ name: TRANSACTION_FILTER_LABELS[key], values: [formatMMDDYYYY(val)] })
          } else {
            summary.push({ name: TRANSACTION_FILTER_LABELS[key], values: [val] })
          }
        }
      }
    });

    return summary;
  }

  clearFilter = () => {
    const emptyFilters: TransactionsFilterModel = {
      startDate: this.props.filter.startDate,
      endDate: this.props.filter.endDate,
      claimTypeText: null,
      ndc11: '',
      fillStartDate: '',
      fillEndDate: '',
      pharmacyNpi: '',
      rxNumber: '',
      adjustmentReasons: null,
      failureReasons: null,
      idCode: ''
    };

    this.handleApplyTableFilter(emptyFilters);
    this.setState({ isFilterOpen: false });
  }

  render () {
    const { currentPage, totalCount, showDateFilter, filter } = this.props;
    const { isFilterOpen } = this.state;

    const filterSummary = this.getFilterSummary();
    const filterCount = filterSummary.length;

    return (
      <Fragment>
        {showDateFilter &&
          <ReversalsDateRangeFilter
            startDate={filter.startDate}
            endDate={filter.endDate}
            applyFilter={this.handleApplyDateFilter}
          />
        }

        <div className={'table-header basic recipient-transactions-table-header'} ref={this.tableHeaderRef}>
          <TransactionsTablePagingMenu
            totalCount={totalCount}
            currentPage={currentPage}
            pageSize={UI_PAGE_SIZE}
            handlePageChange={this.handlePageChange}
            showFilterToggleButton={true}
            isFilterOpen={isFilterOpen}
            filterCount={filterCount}
            filterSummary={filterSummary}
            onFilterToggle={this.handleFilterToggle}
            onFilterClear={this.clearFilter}
          />
          {isFilterOpen &&
            <TransactionFilters
              adjustReasons={this.props.adjustReasons}
              filters={filter}
              focusedInput={this.state.focusedInput}
              isAdjustmentFilterVisible={true}
              isRemittance={false}
              onApplyFilter={this.handleApplyTableFilter}
              onFocusChange={this.handleFocusChange}
            />
          }
        </div>
      </Fragment>
    );
  }
}

export default ReversalsTransactionsTableHeader
