import React from 'react';
import { Icon } from 'semantic-ui-react';
import cx from 'classnames';

import { PaginationHelper } from '../../common/paginationHelper';
import FilterToggleButton, { FilterValueSummary } from '../filter/FilterToggleButton';

interface TransactionsTablePagingMenuProps {
  handlePageChange: (e: any, d: any) => void;
  currentPage: number;
  totalCount: number;
  pageSize: number;
  showFilterToggleButton?: boolean;
  isFilterOpen?: boolean;
  filterCount?: number;
  filterSummary?: FilterValueSummary[];
  className?: string;
  onFilterToggle?: (isFilterOpen: boolean) => void;
  onFilterClear?: () => void;
  isDisabled?: boolean;
}

const TransactionsTablePagingMenu: React.FunctionComponent<TransactionsTablePagingMenuProps> = (props: TransactionsTablePagingMenuProps) => {
  const { handlePageChange, currentPage, totalCount, pageSize, showFilterToggleButton, isFilterOpen, filterCount, filterSummary, className, onFilterToggle, onFilterClear, isDisabled } = props;

  const stringValue = PaginationHelper(pageSize, currentPage, totalCount || 0);

  return (
    <div className={cx('sub-menu-container flex-row', className)}>
      {showFilterToggleButton && (
        <div className="sub-menu-left-side-components">
          <FilterToggleButton
            filterCount={filterCount || 0}
            filterSummary={filterSummary || []}
            isDisabled={isDisabled || false}
            isOpen={isFilterOpen || false}
            onClear={onFilterClear || (() => {})}
            onToggle={onFilterToggle || (() => {})}
          />
        </div>
      )}
      <div className="sub-menu-right-side-components flex-row">
        <div className="sub-menu-pagination-container flex-row">
          <div className="pagination-controls">{stringValue}</div>
          <Icon
            id="pagination-left"
            className={currentPage === 1 || isDisabled ? 'disabled' : 'cursor-pointer'}
            onClick={currentPage === 1 ? undefined : handlePageChange}
            value='page-left'
            name="chevron left"
            disabled={isDisabled}
          />
          <Icon
            id="pagination-right"
            className={currentPage * pageSize >= totalCount || isDisabled ? 'disabled' : 'cursor-pointer'}
            value='page-right'
            onClick={currentPage * pageSize >= totalCount ? undefined : handlePageChange}
            name="chevron right"
            disabled={isDisabled}
          />
        </div>
      </div>
    </div>
  )
}
export default TransactionsTablePagingMenu
