import React, { Fragment } from 'react';
import { formatMMDDYYYY } from '../../../utils/dateHelper';
import { TRANSACTION_FILTER_LABELS } from '../../../store/transactionscommon/constants';
import { UI_PAGE_SIZE, REMITTANCE_DETAILS_TAB } from '../../../store/remittance/constants';
import { AdjustReasonsModel } from '../../../store/appcommon/types';
import TransactionsTablePagingMenu from '../../common/transactions/TransactionsTablePagingMenu';
import { FilterValueSummary } from '../../common/filter/FilterToggleButton';
import TransactionFilters from '../../common/transactions/TransactionFilters';
import { DiscountRequestModel, TransactionsFilterModel } from '../../../store/transactionscommon/types';
import { Button, Popup } from 'semantic-ui-react';
import { RotateCcw } from 'react-feather';

type focusedInputType = 'startDate' | 'endDate' | null;

export interface RemittanceTransactionsTableHeaderProps {
  tab: REMITTANCE_DETAILS_TAB,
  filter: TransactionsFilterModel;
  currentPage: number,
  totalCount: number,
  selectedTransactions: DiscountRequestModel[],
  isLoading: boolean;
  adjustReasons: AdjustReasonsModel;
  hasVerifiedFundingSource: boolean;
  handlePageChange: (page: number) => void;
  handleApplyFilter: (filter: TransactionsFilterModel) => void;
  handleReverseSelection: () => void;
  handleClearSelection: () => void;

}

interface RemittanceTransactionsTableHeaderState {
  isFilterOpen: boolean;
  focusedInput: focusedInputType;
}

export class RemittanceTransactionsTableHeader extends React.Component<RemittanceTransactionsTableHeaderProps, RemittanceTransactionsTableHeaderState> {
  tableHeaderRef: React.RefObject<HTMLDivElement>;

  constructor (props: Readonly<RemittanceTransactionsTableHeaderProps>) {
    super(props)
    this.state = {
      isFilterOpen: false,
      focusedInput: null
    }
    this.tableHeaderRef = React.createRef<HTMLDivElement>();
  }

  componentDidMount () {
    document.addEventListener('mouseup', (e) => this.handleMouseUp(e));
  }

  componentWillUnmount () {
    document.removeEventListener('mouseup', (e) => this.handleMouseUp(e));
  }

  handleMouseUp = (event: MouseEvent) => {
    if (this.state.isFilterOpen) {
      if (this.tableHeaderRef && !this.tableHeaderRef.current?.contains(event.target as Node) && !this.props.isLoading) {
        this.setState({ isFilterOpen: false })
      }
    }
  }

  handlePageChange = (e: any, d: any) => {
    const nextPage = d.value === 'page-left' ? this.props.currentPage - 1 : this.props.currentPage + 1;
    this.props.handlePageChange(nextPage);
  }

  handleFocusChange = (input: focusedInputType) => {
    if (this.props.isLoading && input === null) {
      this.setState({ focusedInput: 'endDate' });
    } else {
      this.setState({ focusedInput: input });
    }
  }

  handleFilterToggle = (isOpen: boolean) => {
    this.setState({ isFilterOpen: isOpen });
  }

  handleApplyTableFilter = (filter: TransactionsFilterModel) => {
    this.props.handleApplyFilter(filter);
  }

  getFilterSummary = () => {
    const summary: FilterValueSummary[] = [];

    Object.keys(this.props.filter).forEach((key) => {
      const val = (this.props.filter as { [key: string]: any })[key]

      if (Array.isArray(val)) {
        summary.push({ name: TRANSACTION_FILTER_LABELS[key], values: val })
      } else if (val) {
        if (key === 'fillStartDate' || key === 'fillEndDate') {
          summary.push({ name: TRANSACTION_FILTER_LABELS[key], values: [formatMMDDYYYY(val)] })
        } else {
          summary.push({ name: TRANSACTION_FILTER_LABELS[key], values: [val] })
        }
      }

    });

    return summary;
  }

  clearFilter = () => {
    const emptyFilters: TransactionsFilterModel = {
      startDate: '',
      endDate: '',
      claimTypeText: null,
      ndc11: '',
      fillStartDate: '',
      fillEndDate: '',
      pharmacyNpi: '',
      rxNumber: '',
      adjustmentReasons: null,
      failureReasons: null,
      idCode: ''
    };

    this.handleApplyTableFilter(emptyFilters);
    this.setState({ isFilterOpen: false });
  }

  render () {
    const { tab, currentPage, totalCount, filter, selectedTransactions, hasVerifiedFundingSource } = this.props;
    const { isFilterOpen } = this.state;

    const filterSummary = this.getFilterSummary();
    const filterCount = filterSummary.length;

    const showAdjustmentInfo = [REMITTANCE_DETAILS_TAB.ALL, REMITTANCE_DETAILS_TAB.ADJUSTED, REMITTANCE_DETAILS_TAB.PROCESSED].includes(tab);
    const showFailureInfo = [REMITTANCE_DETAILS_TAB.ALL, REMITTANCE_DETAILS_TAB.FAILED].includes(tab);

    const canReverse = selectedTransactions.filter((t) => t.statusText !== 'Failed' && !t.reverseDiscountRequestId && !t.originalDiscountRequestId).length > 0;

    return (
      <Fragment>
        {selectedTransactions.length > 0 &&
          <div className="selected-transactions-message">
            <div className="selected-transactions-message-header">
              <span>
                {selectedTransactions.length}
                {selectedTransactions.length === 1 ? ' transaction selected' : ' transactions selected'}
              </span>
            </div>
            <div className="selected-transactions-message-btns-container">
              <Popup
                position='top center'
                size='small'
                inverted
                disabled={hasVerifiedFundingSource}
                content={'Discount Requests cannot be reversed until a bank account has been linked and verified'}
                trigger={
                  <div>
                    <Button
                      className="selected-btn"
                      content='Reverse Now'
                      icon={<RotateCcw size={18} />}
                      labelPosition="left"
                      disabled={!canReverse || !hasVerifiedFundingSource}
                      onClick={this.props.handleReverseSelection} />
                  </div>
                }

              />
              <div className="selected-divider">|</div>
              <Button onClick={this.props.handleClearSelection} className="selected-btn" content='Cancel' labelPosition='left' />
            </div>
          </div>
        }        
        {selectedTransactions.length === 0 &&
          <div className={'table-header basic recipient-transactions-table-header'} ref={this.tableHeaderRef}>
            <TransactionsTablePagingMenu
              totalCount={totalCount}
              currentPage={currentPage}
              pageSize={UI_PAGE_SIZE}
              handlePageChange={this.handlePageChange}
              showFilterToggleButton={true}
              isFilterOpen={isFilterOpen}
              filterCount={filterCount}
              filterSummary={filterSummary}
              onFilterToggle={this.handleFilterToggle}
              onFilterClear={this.clearFilter}
            />
            {isFilterOpen &&
              <TransactionFilters
                adjustReasons={this.props.adjustReasons}
                filters={filter}
                focusedInput={this.state.focusedInput}
                isAdjustmentFilterVisible={showAdjustmentInfo}
                isFailureFilterVisible={showFailureInfo}
                isRemittance={true}
                onApplyFilter={this.handleApplyTableFilter}
                onFocusChange={this.handleFocusChange}
              />
            }
          </div>
        }
      </Fragment>
    );
  }
}

export default RemittanceTransactionsTableHeader
