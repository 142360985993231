export const formatDollar = (value: any) => {
  if (value === undefined) {
    return '- -';
  }

  const positiveValue = value < 0 ? Math.abs(value) : value;
  const amountToFormat = positiveValue ? parseFloat(positiveValue) : 0;

  if (value < 0) {
    return '(' + amountToFormat.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ')';
  }
  else {
    return amountToFormat.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }
}

export const formatCredits = (value: any) => {
  if (value === 0) {
    return '- -';
  }
  else {
    return formatDollar(value);
  }
}

export const formatPositiveDollarToNegative = (value: any) => {
  const amountToFormat = value ? parseFloat(value) : 0;
  if (amountToFormat === 0) {
    return amountToFormat.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }
  return '(' + amountToFormat.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ')';
}

export const formatUraDollar = (value: any) => {
  if (value === undefined) {
    return '- -';
  }

  const positiveValue = value < 0 ? Math.abs(value) : value;
  const amountToFormat = positiveValue ? parseFloat(positiveValue) : 0;

  if (value < 0) {
    return '(' + amountToFormat.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 6 }) + ')';
  }
  else {
    return amountToFormat.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 6 });
  }
}

export const formatNumberWithCommas = (value: any) => value !== undefined ? new Intl.NumberFormat().format(value) : '0';

export const formatUnits = (number: any) => number !== undefined ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 3 }).format(number) : '0';
export const formatMdrpUnits = (number: any) => number !== undefined ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 3, minimumFractionDigits: 3 }).format(number) : '0';
export const formatDiscountPerUnit = (number: any) => number !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 6 }).format(number) : '0';
export const formatDiscountAmount = (number: any) => number > 0 ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(number) : number < 0 ? '(' + Math.abs(number).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ')' : '0';

export const formatPrecision = (number: any, min: number = 6, max: number = 6) => number !== undefined ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: min, maximumFractionDigits: max }).format(number) : '- -';

export const formatNdc11 = (value : string) => {
  if (value) {
    return value.replace(/(\d{5})(\d{4})(\d{2})/, '$1-$2-$3')
  }
  return '- -'
}
