
import RequestConfig from '../../app.config';
import { createUrlParametersString } from '../../utils/requestUtil';
import fetchWrapper from '../FetchWrapper';
import { commonResponseHandler } from '../../utils/responseUtils';

export const fetchBusinessInfo = async (healthSystemId: number) => {
  const urlParams = healthSystemId !== 0 ? createUrlParametersString({ healthSystemId: healthSystemId }) : '';
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/business?${urlParams}`, true, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });
  return commonResponseHandler(response);
}

export const fetchUserInfo = async (healthSystemId: number) => {
  const urlParams = healthSystemId !== 0 ? createUrlParametersString({ healthSystemId: healthSystemId }) : '';
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/business/user?${urlParams}`, true, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });
  return commonResponseHandler(response);
}

