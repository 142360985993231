import {
  TransactionsCommonActionTypes,
  REVERSE_TRANSACTIONS,
  REVERSE_TRANSACTIONS_SUCCESS,
  REVERSE_TRANSACTIONS_FAILURE,
  GET_CONTRACT_ELEMENTS,
  GET_CONTRACT_ELEMENTS_SUCCESS,
  GET_CONTRACT_ELEMENTS_FAILURE,
} from './constants';

import { ErrorModel } from '../applicationTypes';
import { ContractElementsModel } from './types';

export function reverseTransactions (transactionIds: number[]): TransactionsCommonActionTypes {
  return {
    type: REVERSE_TRANSACTIONS,
    payload: transactionIds
  }
}

export function reverseTransactionsSuccess (): TransactionsCommonActionTypes {
  return {
    type: REVERSE_TRANSACTIONS_SUCCESS
  }
}

export function reverseTransactionsFailure (error: ErrorModel): TransactionsCommonActionTypes {
  return {
    type: REVERSE_TRANSACTIONS_FAILURE,
    payload: error
  }
}

export function getContractElements (): TransactionsCommonActionTypes {
  return {
    type: GET_CONTRACT_ELEMENTS,
  }
}

export function getContractElementsSuccess (elements: ContractElementsModel): TransactionsCommonActionTypes {
  return {
    type: GET_CONTRACT_ELEMENTS_SUCCESS,
    payload: elements
  }
}

export function getContractElementsFailure (error: ErrorModel): TransactionsCommonActionTypes {
  return {
    type: GET_CONTRACT_ELEMENTS_FAILURE,
    payload: error
  }
}
