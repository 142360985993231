import React, { Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Layout from '../Layout';
import AddBankPayment from '../AddBankPayment';
import { RootState } from '../../../store/RootState';
import { makeSelectIsAuthorizingOfficial, makeSelectIsAccountAdmin, makeSelectIsController } from '../../../store/onboarding/selectors';
import PaymentSuccess from './PaymentSuccess';
import { completeControllerBankInfoStep, completeOnboarding, refreshDataAfterOnboarding, updateOnboardingStep } from '../../../store/onboarding/actionCreators';
import AddFundingSourceModal from '../../common/dwolla/AddFundingSourceModal';
import GoToRequestFooter from './GoToRequestFooter';
import { DwollaError } from '../../common/dwolla/DwollaError';
import { ONBOARDING_STEPS } from '../../../store/onboarding/constants';

export interface PaymentProps extends RouteComponentProps {
  isAuthorizingOfficial: boolean
  isAccountAdmin: boolean
  isController: boolean
  isLoading: boolean
  onboardingCompleted: boolean
  fundingSourceId: string
  fundingSourceTokenError: string
  completeControllerBankInfoStep: () => void
  refreshDataAfterOnboarding: () => void
  updateOnboardingStep: (step: number) => void
  completeOnboarding: () => void
}

interface PaymentState {
  partOneComplete: boolean
  addFundingSourceModalClosed: boolean
}

export class Payment extends React.Component<PaymentProps, PaymentState>{
  constructor (props: Readonly<PaymentProps>) {
    super(props);
    this.state = {
      partOneComplete: false,
      addFundingSourceModalClosed: false,
    };
  }

  onModalClose = () => {
    this.setState({ addFundingSourceModalClosed: true });
    this.props.completeOnboarding();
  }

  onNextClick = () => {
    this.setState({ partOneComplete: true })

  }
  onBackClick = () => {

    if (this.state.partOneComplete) {
      this.setState({ partOneComplete: false })
    } else {
      const prevStep = this.props.isAccountAdmin ? ONBOARDING_STEPS.ACCOUNT_ADMIN_INVITE_CONTROLLER : ONBOARDING_STEPS.CONTROLLER_CONFIRM_INFO
      this.props.updateOnboardingStep(prevStep);
    }
  }

  onGoToAppClick = () => {
    this.props.refreshDataAfterOnboarding();
    this.props.history.push('/recipients/requestcenter');
  }

  renderNext () {
    const { partOneComplete } = this.state;
    if (!partOneComplete) {
      return (
        <button
          data-testid="nextButton"
          className="app-button button-dark"
          onClick={this.onNextClick}
          type="button"
        >
          Next
        </button>
      )
    }

    return (
      <AddFundingSourceModal
        modalTriggerText={'Next'}
        modalTriggerStyles={'button-dark'}
        onClose={this.onModalClose}
        onSuccess={this.props.completeControllerBankInfoStep}
      />
    )
  }

  renderBack () {

    if (this.props.onboardingCompleted) {
      return null;
    }

    return (
      <button data-testid="backButton" className="app-button" onClick={this.onBackClick} type="button">
        <Icon name="chevron left" size="large" />Back
      </button>
    );
  }

  render () {
    const { isAuthorizingOfficial, isAccountAdmin, onboardingCompleted, fundingSourceTokenError } = this.props;
    const { partOneComplete, addFundingSourceModalClosed } = this.state;
    const stepNumber = isAuthorizingOfficial ? 6 : isAccountAdmin ? 3 : 3;

    return (
      <Layout stepNumber={stepNumber}>
        {fundingSourceTokenError && addFundingSourceModalClosed ? <DwollaError /> :
          <Fragment>
            {(onboardingCompleted && addFundingSourceModalClosed) ?
              <Fragment>
                <PaymentSuccess />
                <GoToRequestFooter />
              </Fragment> :
              <Fragment>
                <div className="onboarding-layout-header">
                  <div className="title">{stepNumber}. Add bank account</div>
                  <div className="sub-title">
                    Link a financial account for the purpose of viewing or transferring funds through our trusted third-party payment partner, Dwolla. Kalderos does not store or view your financial information. </div>
                </div>
                <div className="onboarding-layout-children">
                  <div className="onboarding-layout-body">
                    <AddBankPayment partOneComplete={partOneComplete} />
                  </div>
                </div>
                <div className="onboarding-layout-footer">
                  <div className="onboarding-layout-navigation">
                    {this.renderBack()}
                  </div>
                  <div className="onboarding-layout-cta">
                    {this.renderNext()}
                  </div>
                </div>
              </Fragment>
            }
          </Fragment>
        }
      </Layout>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  isAuthorizingOfficial: makeSelectIsAuthorizingOfficial(state),
  isAccountAdmin: makeSelectIsAccountAdmin(state),
  isController: makeSelectIsController(state),
  isLoading: state.OnboardingState.isLoading,
  onboardingCompleted: state.OnboardingState.onboardingStatusModel.onboardingCompleted,
  fundingSourceId: state.PaymentsState.common.dwollaAddFundingSource.fundingSourceId,
  fundingSourceTokenError: state.PaymentsState.common.fundingSourceToken.errorText
})

const mapDispatchToProps = (dispatch: any) => ({
  completeControllerBankInfoStep: () => dispatch(completeControllerBankInfoStep()),
  refreshDataAfterOnboarding: () => dispatch(refreshDataAfterOnboarding()),
  updateOnboardingStep: (step: number) => dispatch(updateOnboardingStep(step)),
  completeOnboarding: () => dispatch(completeOnboarding())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Payment));
