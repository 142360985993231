import React, { Fragment } from 'react';
import { Modal } from 'semantic-ui-react';
import cx from 'classnames';
import { CandidateCoveredEntity } from '../../../../store/onboarding/types';


import '../../../../themes/kalderos/Onboarding/Recipient/CoveredEntityModal.scss';

interface CoveredEntityModalProps {
  healthSystemName?: string;
  ceList: CandidateCoveredEntity[],
  triggerText: string;
  triggerSize: 'small' | 'regular';
}

interface CoveredEntityModalState {
  modalOpen: boolean;
}

class CoveredEntityModal extends React.Component<CoveredEntityModalProps, CoveredEntityModalState> {
  constructor (props: CoveredEntityModalProps) {
    super(props);
    this.state = {
      modalOpen: false
    }
  }
  onOpen = () => {
    this.setState({ modalOpen: true })
  }

  onClose = () => {
    this.setState({ modalOpen: false })
  }

  render () {
    const { triggerText, triggerSize, healthSystemName, ceList } = this.props;

    const myCeList = ceList.filter((ce) => ce.allowedToOnboard);
    const notMyCeList = ceList.filter((ce) => !ce.allowedToOnboard);
    return (
      <Modal
        trigger={

          <button
            type="button"
            onClick={this.onOpen}
            className={cx('covered-entity-modal-trigger', {'regular': triggerSize === 'regular'})}
          >
            {triggerText}
          </button>
        }
        open={this.state.modalOpen}
        onClose={this.onClose}
      >
        <div className="app-modal covered-entity-modal">
          {healthSystemName ? <div className="app-modal-header">Covered Entities with {healthSystemName}</div> :
            <div className="app-modal-header">Covered Entities Not in a Health System</div>}
          <div className="body">
            {notMyCeList && notMyCeList.length > 0 && <div className="entity-count">Your Entities ({myCeList.length})</div>}
            <div className="table-heading">
              <span>Name</span>
              <span>340B ID</span>
            </div>
            {
              myCeList.map(ce => {
                return (
                  <div key={ce.id340B} className="covered-entity">
                    <span data-private>{ce.entityName}</span>
                    <span data-private>{ce.id340B}</span>
                  </div>
                )
              })
            }
            {notMyCeList && notMyCeList.length > 0 &&
          <Fragment>
            <div className="other-entities entity-count">Other Entities ({notMyCeList.length})</div>
            <div className="table-heading">
              <span>Name</span>
              <span>340B ID</span>
            </div>
            {
              notMyCeList.map(ce => {
                return (
                  <div key={ce.id340B} className="covered-entity">
                    <span data-private>{ce.entityName}</span>
                    <span data-private>{ce.id340B}</span>
                  </div>
                )
              })
            }
          </Fragment>}
          </div>
          <div className="footer">
            <button
              type="button"
              onClick={this.onClose}
              className="app-button button-white"
            >
          Close
            </button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default CoveredEntityModal;
