import React, { useEffect } from 'react'
import { Button } from 'semantic-ui-react';
import { Formik } from 'formik';
import { DiscountProgramFieldsModel, DiscountRequestAdditionalFieldsModel, DiscountRequestRequiredFieldsModel } from '../../../store/requestcenter/types';
import { getRequestFormSchema, orderDiscountProgramFields } from './RequestFormHelper';
import RequestFormFieldField from './RequestFormField';
import FieldInfo from './FieldInfo';

interface DataEntryProps {
  contractFields: DiscountProgramFieldsModel[];
  requiredFieldValues: DiscountRequestRequiredFieldsModel;
  additionalFieldValues: DiscountRequestAdditionalFieldsModel;
  onSubmit: (values: DiscountRequestAdditionalFieldsModel) => void;
}

export const DataEntry: React.FunctionComponent<DataEntryProps> = (props) => {

  const { contractFields, requiredFieldValues, additionalFieldValues, onSubmit } = props;

  const initialValues: DiscountRequestAdditionalFieldsModel = {
    ...additionalFieldValues
  };

  const sortedContractFields = orderDiscountProgramFields(contractFields, requiredFieldValues.claimType);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={getRequestFormSchema(contractFields)}
      validateOnMount={true}
      enableReinitialize={true}
    >
      {formikProps => {
        const validateForm = formikProps.validateForm;
        useEffect(() => {
          validateForm();
        }, [validateForm]);

        return (

          <React.Fragment>
            <div className='request-form-body flex-row flex-grow scroll-hidden'>
              {props.children}
              <div className='request-form-details flex-column v-scroll-auto'>
                <div className='form-container flex-column flex-grow'>
                  <div className='form-title gamma'>Enter Data</div>
                  <div className='field-info-row flex-row'>
                    {/* <FieldInfo value={claimType} text='RETAIL/MEDICAL REQUEST' /> */}
                    {/* <FieldInfo name="discountProgram" value={requiredFieldValues.discountProgram} label='DISCOUNT PROGRAM' /> */}
                    <FieldInfo name="idCode" value={requiredFieldValues.idCode} label='340B ID' />
                    <FieldInfo name="ndc11" value={requiredFieldValues.ndc11} label='NDC11' />
                    <FieldInfo name="fillDate" value={requiredFieldValues.fillDate} label='FILL DATE' />
                  </div>
                  <form onSubmit={formikProps.handleSubmit} className='ui form'>
                    <div className='field-info-group'>
                      <div className='form-row'>
                        {
                          sortedContractFields.map(fld => {
                            return <RequestFormFieldField key={fld.name} field={fld} formProps={formikProps} />
                          })
                        }
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='request-form-footer flex-column justify-center'>
              <div className='flex-row justify-flex-end'>
                <div className='actions'>
                  <Button onClick={() => formikProps.handleSubmit()} secondary disabled={!formikProps.isValid} type="submit">Save and Next</Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      }}
    </Formik>
  )
}

export default DataEntry;