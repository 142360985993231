import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import cx from 'classnames';
import { ChevronLeft } from 'react-feather';

interface PageHeaderProps  extends RouteComponentProps {
  text: string;
  defaultBack?: boolean;
  backLink?: string;
  className?: string;
  noBorder?: boolean;
  isEditMode?: boolean;
  icon?: JSX.Element;
  handleBackLinkClicked?: () => void
}

class PageHeader extends React.Component<PageHeaderProps> {

  renderBackLink = () => {
    const { backLink, handleBackLinkClicked, defaultBack, history } = this.props;
    const backIcon = <ChevronLeft size={30} />;

    if (backLink) {
      return <Link to={backLink}>{backIcon}</Link>
    }

    if (handleBackLinkClicked) {
      return <button type="button" onClick={handleBackLinkClicked}>{backIcon}</button>
    }

    if (defaultBack) {
      return <button type="button" onClick={() => history.goBack()}>{backIcon}</button>
    }

    return null;
  }

  render () {
    const { className, noBorder, text, isEditMode, icon } = this.props;

    return (
      <div className={cx('page-header', className, { 'no-border': noBorder })}>
        <div>{this.renderBackLink()}</div>
        <div className="page-header-text-wrapper">
          {icon && <div className="page-header-icon">{icon}</div>}
          {isEditMode && <span className='page-header-text editing'>EDITING </span>}
          <h2 className='page-header-text'>{text}</h2>
        </div>
      </div>
    )
  }
}

export default withRouter(PageHeader);
