import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

export interface EnableUserModalProps {
  open: boolean;
  userId: number;
  closeModal: () => void;
  enableUser: (data: any) => void;
}

export const EnableUserModal: React.FunctionComponent<EnableUserModalProps> = ({ open, closeModal, userId, enableUser }) => {
  return (
    <Modal
      className="app-modal medium-modal"
      open={open}>
      <div className="beta">Are you sure?</div>
      <div className="app-modal-content">
        <p className="delta">You are about to reactivate this user.</p>
      </div>
      <div className="app-modal-btn-container">
        <Button onClick={closeModal} className="app-button button-white app-modal-cancel-button">Cancel</Button>
        <Button onClick={() => enableUser(userId)} className="app-button button-dark app-modal-submit-button">Confirm</Button>
      </div>
    </Modal>
  )
};

export default EnableUserModal