import { SHOW_TOAST, HIDE_TOAST, CREATE_TOAST, ToastActionTypes } from './constants';
import { Toast } from './types';

export function createToast (toast: Toast): ToastActionTypes {
  return {
    type: CREATE_TOAST,
    payload: {
      toast: toast
    }
  }
}

export function showToast (toast: Toast): ToastActionTypes {
  return {
    type: SHOW_TOAST,
    payload: toast
  }
}

export function hideToast (toast: Toast): ToastActionTypes {
  return {
    type: HIDE_TOAST,
    payload: toast
  }
}
