import { UserInfoModel, ErrorModel } from '../../applicationTypes';
import { PaymentHistoryModel, CoveredEntityModel, CreditsPerManufacturerModel } from './types';
import { SortingFilter } from '../../appcommon/types';

export const RECIPIENT_PAYMENT_SETTINGS_TABLE_HEADERS = [
  { header: 'Account Name', sortValue: 'name' },
  { header: 'Bank Name', sortValue: 'bankName' }
];
export const RECIPIENT_PAYMENT_HISTORY_TABLE_HEADERS = [
  { header: 'Payment Received', sortValue: 'datePaid' },
  { header: 'Remittance ID', sortValue: 'remittanceNumber' },
  { header: '340B ID', sortValue: 'IdCode' },
  { header: 'Account', sortValue: 'accountName' },
  { header: 'Manufacturer', sortValue: 'manufacturerName' },
  { header: 'Amount', sortValue: 'amountPaid' }
];

export const RECIPIENT_CREDITS_PER_MANUFACTURER_HEADERS = [
  { header: '340B ID', sortValue: 'id340b' },
  { header: '340B Name', sortValue: 'name340b' },
  { header: 'Manufacturer Name', sortValue: 'manufacturerName' },
  { header: 'Credits', sortValue: 'credits' }
];

export const NO_CREDITS_HEADER = 'No Credits';
export const NO_CREDITS_MESSAGE = 'You currently do not have any credits with associated manufacturers. If applicable, credits can be viewed here after remittance.';

export const GET_RECIPIENT_PAYMENT_HISTORY = 'GET_RECIPIENT_PAYMENT_HISTORY';
export const UPDATE_RECIPIENT_PAYMENT_HISTORY = 'UPDATE_RECIPIENT_PAYMENT_HISTORY';
export const GET_RECIPIENT_PAYMENT_HISTORY_ERROR = 'GET_RECIPIENT_PAYMENT_HISTORY_ERROR';
export const UPDATE_RECIPIENT_PAYMENTS_HISTORY_SORT_FILTERS = 'UPDATE_RECIPIENT_PAYMENTS_HISTORY_SORT_FILTERS';
export const UPDATE_RECIPIENT_PAYMENT_HISTORY_PAGE = 'UPDATE_RECIPIENT_PAYMENT_HISTORY_PAGE';

export const RESET_RECIPIENT_PAYMENT_STATE = 'RESET_RECIPIENT_PAYMENT_STATE';

export const NO_PAYMENT_HISTORY_HEADER = 'No Payment History';
export const NO_PAYMENT_HISTORY_MESSAGE = 'You can view your payment history here after you receive your first payment.';

export const NO_COVERED_ENTITY_BILLING_INFO_HEADER = 'No Associated Covered Entity Information';
export const NO_COVERED_ENTITY_BILLING_INFO_MESSAGE = 'Set up associated covered entities to see their information.';

export const COVERED_ENTITY_BILLING_INFO_ERROR_HEADER = 'Error loading Covered Entity Information';
export const COVERED_ENTITY_BILLING_INFO_ERROR_MESSAGE = 'Please try refreshing the page to reload the Covered Entity Info.';

export const RETRY_PAYMENTS = 'RETRY_PAYMENTS'
export const RETRY_PAYMENTS_SUCCESS = 'RETRY_PAYMENTS_SUCCESS'
export const RETRY_PAYMENTS_FAILURE = 'RETRY_PAYMENTS_FAILURE'

export const GET_CREDITS_PER_MANUFACTURER = 'GET_CREDITS_PER_MANUFACTURER'
export const GET_CREDITS_PER_MANUFACTURER_SUCCESS = 'GET_CREDITS_PER_MANUFACTURER_SUCCESS'
export const GET_CREDITS_PER_MANUFACTURER_FAILURE = 'GET_CREDITS_PER_MANUFACTURER_FAILURE'
export const UPDATE_CREDITS_PER_MANUFACTURER_SORT_FILTERS = 'UPDATE_CREDITS_PER_MANUFACTURER_SORT_FILTERS'
export const UPDATE_CREDITS_PER_MANUFACTURER_PAGE = 'UPDATE_CREDITS_PER_MANUFACTURER_PAGE';

export const GET_ASSOCIATED_COVERED_ENTITIES = 'GET_ASSOCIATED_COVERED_ENTITIES';
export const GET_ASSOCIATED_COVERED_ENTITIES_SUCCESS = 'GET_ASSOCIATED_COVERED_ENTITIES_SUCCESS';
export const GET_ASSOCIATED_COVERED_ENTITIES_FAILURE = 'GET_ASSOCIATED_COVERED_ENTITIES_FAILURE';
export const UPDATE_ASSOCIATED_COVERED_ENTITIES_SORT = 'UPDATE_ASSOCIATED_COVERED_ENTITIES_SORT';

export const GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO = 'GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO';
export const GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO_SUCCESS = 'GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO_SUCCESS';
export const GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO_FAILURE = 'GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO_FAILURE';

interface GetRecipientPaymentHistory {
  type: typeof GET_RECIPIENT_PAYMENT_HISTORY
}

interface UpdateRecipientPaymentHistory {
  type: typeof UPDATE_RECIPIENT_PAYMENT_HISTORY,
  payload: {
    paymentTransactions: PaymentHistoryModel[];
    totalCount: number;
    page: number;
  }
}

interface GetRecipientPaymentHistoryError {
  type: typeof GET_RECIPIENT_PAYMENT_HISTORY_ERROR,
  payload: ErrorModel
}

interface UpdateRecipientPaymentsHistorySortFilters {
  type: typeof UPDATE_RECIPIENT_PAYMENTS_HISTORY_SORT_FILTERS,
  payload: SortingFilter
}

interface UpdateRecipientPaymentHistoryPage {
  type: typeof UPDATE_RECIPIENT_PAYMENT_HISTORY_PAGE,
  payload: number
}

interface ResetRecipientPaymentState {
  type: typeof RESET_RECIPIENT_PAYMENT_STATE
}

interface RetryPaymentsAction {
  type: typeof RETRY_PAYMENTS
}

interface RetryPaymentsSuccessAction {
  type: typeof RETRY_PAYMENTS_SUCCESS,
  payload: any
}

interface RetryPaymentsFailureAction {
  type: typeof RETRY_PAYMENTS_FAILURE,
  payload: ErrorModel
}

interface GetCreditsPerManufacturer {
  type: typeof GET_CREDITS_PER_MANUFACTURER
}

interface CreditsPerManufacturerSuccessAction {
  type: typeof GET_CREDITS_PER_MANUFACTURER_SUCCESS,
  payload: {
    creditsPerManufacturer: CreditsPerManufacturerModel[],
    currentApiPage: number,
    totalCount: number
  }
}

interface CreditsPerManufacturerFailureAction {
  type: typeof GET_CREDITS_PER_MANUFACTURER_FAILURE
}

interface UpdateCreditsPerManufacturerSortFilters {
  type: typeof UPDATE_CREDITS_PER_MANUFACTURER_SORT_FILTERS,
  payload: SortingFilter
}

interface UpdateCreditsPerManufacturerPage {
  type: typeof UPDATE_CREDITS_PER_MANUFACTURER_PAGE,
  payload: number
}

interface GetAssociatedCoveredEntitiesAction {
  type: typeof GET_ASSOCIATED_COVERED_ENTITIES
}

interface GetAssociatedCoveredEntitiesSuccessAction {
  type: typeof GET_ASSOCIATED_COVERED_ENTITIES_SUCCESS,
  payload: {
    coveredEntities: CoveredEntityModel[],
  }
}

interface GetAssociatedCoveredEntitiesFailureAction {
  type: typeof GET_ASSOCIATED_COVERED_ENTITIES_FAILURE,
  payload: ErrorModel
}

interface UpdateAssociatedCoveredEntitiesSortAction {
  type: typeof UPDATE_ASSOCIATED_COVERED_ENTITIES_SORT,
  payload: SortingFilter
}

interface GetCoveredEntityHealthSystemInfoAction {
  type: typeof GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO
}

interface GetCoveredEntityHealthSystemInfoSuccessAction {
  type: typeof GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO_SUCCESS,
  payload: {
    accountAdmin: UserInfoModel, 
    controller: UserInfoModel
  }
}

interface GetCoveredEntityHealthSystemInfoFailureAction {
  type: typeof GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO_FAILURE,
  payload: ErrorModel
}


export type PaymentsRecipientActionTypes =
  GetRecipientPaymentHistory |
  UpdateRecipientPaymentHistory |
  GetRecipientPaymentHistoryError |
  UpdateRecipientPaymentsHistorySortFilters |
  UpdateRecipientPaymentHistoryPage |
  ResetRecipientPaymentState |
  RetryPaymentsAction |
  RetryPaymentsSuccessAction |
  RetryPaymentsFailureAction |
  GetCreditsPerManufacturer |
  CreditsPerManufacturerSuccessAction |
  CreditsPerManufacturerFailureAction |
  UpdateCreditsPerManufacturerSortFilters |
  UpdateCreditsPerManufacturerPage |
  GetAssociatedCoveredEntitiesAction | 
  GetAssociatedCoveredEntitiesSuccessAction | 
  GetAssociatedCoveredEntitiesFailureAction |
  UpdateAssociatedCoveredEntitiesSortAction |
  GetCoveredEntityHealthSystemInfoAction | 
  GetCoveredEntityHealthSystemInfoSuccessAction | 
  GetCoveredEntityHealthSystemInfoFailureAction;