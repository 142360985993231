import React from 'react';
import { connect } from 'react-redux';
import jwt from 'jsonwebtoken';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import getUserToken, { removeUserToken } from '../../localstore/LocalStore';
import { RootState } from '../../store/RootState';
import { UserModel } from '../../store/login/types';
import { updateUserDetails, clearUserDetails, sendStartupRequests } from '../../store/login/actionCreators';
import { pendoInitialize } from '../../pendoInit';
import { logRocketIdentify } from '../../logRocketInit';
import { setPreSelectedHealthSystemId } from '../../store/teammanagement/actionCreators';

const NO_AUTH_PATHS = [
  '/signup',
  '/acceptInvite',
  '/forgotpassword',
  '/expiredpassword',
  '/initialLogin'
];

export interface AuthLoaderProps extends RouteComponentProps {
  isAuthenticated?: boolean;
  updateUserDetails: (userModel: UserModel) => void;
  clearUserDetails: () => void;
  sendStartupRequests: () => void;
  setPreSelectedHealthSystemId: (preselectedHealthSystemId: number) => void;
}

export class AuthLoader extends React.Component<AuthLoaderProps> {

  componentDidMount () {
    this.checkAuthStatus();
  }

  componentDidUpdate () {
    this.checkAuthStatus();
  }

  checkAuthStatus () {

    if (NO_AUTH_PATHS.some(p => this.props.location.pathname.includes(p))) {
      return
    }

    if (!this.props.isAuthenticated) {
      this.checkJWTTokenInLocalStore();      
    }
  }

  checkJWTTokenInLocalStore = () => {
    const userToken = getUserToken();
    let valid = false;
    if (userToken != null) {
      const decoded = jwt.decode(userToken, { complete: true, json: true }) as { [key: string]: any };
      if (decoded !== null) {
        const userModel: UserModel = {
          exp: decoded.payload.exp,
          family_name: decoded.payload.family_name,
          given_name: decoded.payload.given_name,
          userName: decoded.payload.userName,
          roles: decoded.payload.roles,
          isAuthenticated: true,
        }
        const currentTime = new Date().getTime() / 1000;
        if (userModel.exp < currentTime) {
          removeUserToken();
          this.props.clearUserDetails();
        } else {
          this.props.updateUserDetails(userModel);
          pendoInitialize(decoded.payload.sub);
          logRocketIdentify({ email: decoded.payload.userName });

          const pathName = this.props.location.pathname;
          const params = new URLSearchParams(this.props.location.search)
          const healthSystemId = params.get('healthSystemId');

          if (pathName === '/' && healthSystemId && parseInt(healthSystemId)) {
            this.props.setPreSelectedHealthSystemId(parseInt(healthSystemId));
          } 
          this.props.sendStartupRequests();
        
          valid = true;
        }
      }
    }

    if (!valid && this.props.isAuthenticated === undefined) {
      //sets isAuthenticated to false to update that we loaded auth info (or lack there of)
      this.props.clearUserDetails();
    }
    return valid;
  }

  render () {
    return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.LoginState.user.isAuthenticated
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateUserDetails: (userModel: UserModel) => dispatch(updateUserDetails(userModel)),
    clearUserDetails: () => dispatch(clearUserDetails()),
    sendStartupRequests: () => dispatch(sendStartupRequests()),
    setPreSelectedHealthSystemId: (preselectedHealthSystemId: number) => dispatch(setPreSelectedHealthSystemId(preselectedHealthSystemId))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthLoader));
