import React from 'react';
import { AddressModel } from '../../store/applicationTypes';

export const AddressInfo = ({ address, label }: { address?: AddressModel, label?: string }) =>
  <div className="detail-field">
    <div data-testid='address-field' className="value" data-private>{address?.address1}</div>
    <div data-testid='address2-field' className="value" data-private>{address?.address2}</div>
    <div data-testid='address3-field' className="value" data-private>{address?.city}, {address?.state} {address?.zip}</div>
    <div data-testid='address4-field' className="value" data-private>{address?.country}</div>
    <div data-testid='label-field' className="label" data-private>{label}</div>
  </div>
