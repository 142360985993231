import { CommonPaymentsState, AddFundingSourceModel, PaymentSettingsModel, FundingSourcesModel, PaymentAlertModel,
  InitiateMicroDepositsModel,
  FundingSourceTokenModel,
  DwollaAddFundingSourceModel, } from './types';

import {
  PaymentsActionTypes,
  SHOW_ADD_FUNDS_MODAL,
  SHOW_WITHDRAW_FUNDS_MODAL,
  HIDE_ADD_WITHDRAW_FUNDS_MODAL,
  UPDATE_MICRO_DEPOSIT_STATUS,
  VERIFY_MICRO_DEPOSITS,
  VERIFY_MICRO_DEPOSITS_MODAL_CLOSE,
  ADD_FUNDING_SOURCE,
  ADD_FUNDING_SOURCE_SUCCESS,
  ADD_FUNDING_SOURCE_FAILURE,
  FETCH_DWOLLA_ENVIRONMENT,
  FETCH_DWOLLA_ENVIRONMENT_SUCCESS,
  FETCH_DWOLLA_ENVIRONMENT_FAILURE,
  GET_PAYMENT_SUMMARY,
  UPDATE_PAYMENT_SUMMARY,
  UPDATE_PAYMENTS_SUMMARY_SORT_FILTERS,
  UPDATE_PAYMENT_SUMMARY_ERROR,
  UPDATE_WITHDRAWN_AMOUNT_SUCCESS,
  UPDATE_WITHDRAWN_AMOUNT_FAILURE,
  UPDATE_WITHDRAWN_AMOUNT,
  UPDATE_FUNDING_SOURCE_AMOUNT_SUCCESS,
  RESET_MICRO_DEPOSIT_STATUS,
  INITIATE_MICRO_DEPOSITS,
  INITIATE_MICRO_DEPOSITS_SUCCESS,
  INITIATE_MICRO_DEPOSITS_FAILURE,
  GET_FUNDING_SOURCE_TOKEN,
  GET_FUNDING_SOURCE_TOKEN_SUCCESS,
  GET_FUNDING_SOURCE_TOKEN_FAILURE,
  DWOLLA_ADD_FUNDING_SOURCE_SUCCESS,
  DWOLLA_ADD_FUNDING_SOURCE_FAILURE,
  RESET_ADD_FUNDING_SOURCE,
  GET_PAYMENT_ALERT,
  GET_PAYMENT_ALERT_FAILURE,
  GET_PAYMENT_ALERT_SUCCESS, REMOVE_FUNDING_SOURCE
} from './constants';

const PaymentAlertState : PaymentAlertModel ={
  fundingSourceId:'',
  fundingSourceName:'',
  achReturnCode:'',
  achReturnCodeDescription:'',
  description: ''
}
const AddFundingSourceDefaultState: AddFundingSourceModel = {
  isSuccess: false,
  isLoading: false,
  errorText: ''
}

const PaymentSummaryDefaultState: PaymentSettingsModel = {
  dwollaBalance: 0,
  fundingSources: [],
  approvedFundingSources: [],
  isLoading: false,
  sortBy: 'name',
  isSortDesc: false,
  errorStatus: null,
  errorAccount: {} as FundingSourcesModel
}


const InitiateMicroDepositsDefaultState: InitiateMicroDepositsModel = {
  sourceId: 0,
  isLoading: false,
  isSuccess: false,
  errorText: '',
}

const FundingSourceTokenDefaultState: FundingSourceTokenModel = {
  token: '',
  isLoading: false,
  errorText: '',
}

const DwollaAddFundingSourceDefaultState: DwollaAddFundingSourceModel = {
  fundingSourceId: '',
  errorText: ''
}

const initialState: CommonPaymentsState = {
  paymentSettings: PaymentSummaryDefaultState,
  fundingModalState: {
    isAddFundsOpen: false,
    isWithdrawFundsOpen: false,
    addFunds: undefined,
    bankAccount: '',
    amount: 0,
    verifyMicroDepositStatus: null,
    isVerificationInProcess: false,
    removeFundingSourceErrorMsg: '',
    AddWithdrawFundsErrorMsg:''
  },
  addFundingSource: AddFundingSourceDefaultState,
  dwollaEnvironment: {
    environment: 'sandbox',
    isLoading: false,
    errorTxt: '',
  },
  paymentAlert: PaymentAlertState,
  initiateMicroDeposit: InitiateMicroDepositsDefaultState,
  fundingSourceToken: FundingSourceTokenDefaultState,
  dwollaAddFundingSource: DwollaAddFundingSourceDefaultState
};



export function commonPaymentsReducer (
  state = initialState,
  action: PaymentsActionTypes
): CommonPaymentsState {
  switch (action.type) {
    case REMOVE_FUNDING_SOURCE:
    case GET_PAYMENT_SUMMARY:
      return {
        ...state,
        paymentSettings: {
          ...state.paymentSettings,
          isLoading: true
        }
      }

    case UPDATE_PAYMENT_SUMMARY:
      return {
        ...state,
        paymentSettings: {
          ...state.paymentSettings,
          isLoading: false,
          fundingSources: action.payload.fundingSources,
          dwollaBalance: action.payload.dwollaBalance,
          approvedFundingSources: action.payload.approvedFundingSources
        }
      }

    case UPDATE_PAYMENTS_SUMMARY_SORT_FILTERS:
      return {
        ...state,
        paymentSettings: {
          ...state.paymentSettings,
          sortBy: action.payload.sortBy,
          isSortDesc: !action.payload.isSortAsc
        }
      }

    case UPDATE_PAYMENT_SUMMARY_ERROR:
      return {
        ...state,
        paymentSettings: {
          ...state.paymentSettings,
          isLoading: false,
          errorStatus: action.payload.errorStatus,
          errorAccount: action.payload.errorAccount
        }
      }

    case SHOW_ADD_FUNDS_MODAL:
      return {
        ...state,
        fundingModalState: {
          ...state.fundingModalState,
          isWithdrawFundsOpen: false,
          isAddFundsOpen: true,
          addFunds: true
        }
      }

    case SHOW_WITHDRAW_FUNDS_MODAL:
      return {
        ...state,
        fundingModalState: {
          ...state.fundingModalState,
          isAddFundsOpen: false,
          isWithdrawFundsOpen: true,
          addFunds: false
        }
      }

    case HIDE_ADD_WITHDRAW_FUNDS_MODAL:
      return {
        ...state,
        fundingModalState: {
          ...state.fundingModalState,
          isAddFundsOpen: false,
          isWithdrawFundsOpen: false
        }
      }
    case UPDATE_WITHDRAWN_AMOUNT:
      return {
        ...state,
        fundingModalState:{
          ...state.fundingModalState
        }
      }
    case UPDATE_FUNDING_SOURCE_AMOUNT_SUCCESS:
    case UPDATE_WITHDRAWN_AMOUNT_SUCCESS:
      return {
        ...state,
        paymentSettings: {
          ...state.paymentSettings,
          dwollaBalance: action.payload.amount
        }
      }

    case UPDATE_WITHDRAWN_AMOUNT_FAILURE:
      return {
        ...state,
        fundingModalState :{
          ...state.fundingModalState,
          AddWithdrawFundsErrorMsg: action.payload.message
        }
      }


    case RESET_MICRO_DEPOSIT_STATUS:
      return {
        ...state,
        fundingModalState: {
          ...state.fundingModalState,
          verifyMicroDepositStatus: null
        }
      }

    case UPDATE_MICRO_DEPOSIT_STATUS:
      return {
        ...state,
        fundingModalState: {
          ...state.fundingModalState,
          verifyMicroDepositStatus: action.payload.status,
          isVerificationInProcess: false
        }
      }
    case VERIFY_MICRO_DEPOSITS:
      return {
        ...state,
        fundingModalState: {
          ...state.fundingModalState,
          isVerificationInProcess: true
        }
      }
    case VERIFY_MICRO_DEPOSITS_MODAL_CLOSE:
      return {
        ...state,
        fundingModalState: {
          ...state.fundingModalState,
          verifyMicroDepositStatus: null
        }
      }
    case ADD_FUNDING_SOURCE:
      return {
        ...state,
        addFundingSource: {
          isSuccess: false,
          isLoading: true,
          errorText: ''
        }
      }
    case ADD_FUNDING_SOURCE_SUCCESS:
      return {
        ...state,
        addFundingSource: {
          isSuccess: true,
          isLoading: false,
          errorText: ''
        }
      }
    case ADD_FUNDING_SOURCE_FAILURE:
      return {
        ...state,
        addFundingSource: {
          isSuccess: false,
          isLoading: false,
          errorText: action.payload.message
        }
      }
    case FETCH_DWOLLA_ENVIRONMENT:
      return {
        ...state,
        dwollaEnvironment: {
          environment: 'sandbox',
          isLoading: true,
          errorTxt: '',
        }
      }
    case FETCH_DWOLLA_ENVIRONMENT_SUCCESS:
      return {
        ...state,
        dwollaEnvironment: {
          environment: action.payload === 'production' ? 'prod' : action.payload,
          isLoading: false,
          errorTxt: '',
        }
      }
    case FETCH_DWOLLA_ENVIRONMENT_FAILURE:
      return {
        ...state,
        dwollaEnvironment: {
          environment: 'sandbox',
          isLoading: false,
          errorTxt: action.payload.message
        }
      }
    case GET_PAYMENT_ALERT:
    case GET_PAYMENT_ALERT_FAILURE:
      return {
        ...state,
        paymentAlert: PaymentAlertState
      }
    case GET_PAYMENT_ALERT_SUCCESS:
      return {
        ...state,
        paymentAlert: action.payload
      }

    case INITIATE_MICRO_DEPOSITS:
      return {
        ...state,
        initiateMicroDeposit: {
          sourceId: action.payload.sourceId,
          isLoading: true,
          isSuccess: false,
          errorText: '',
        },
        paymentSettings: {
          ...state.paymentSettings,
          isLoading: true
        }
      }
    case INITIATE_MICRO_DEPOSITS_SUCCESS:
      return {
        ...state,
        initiateMicroDeposit: {
          sourceId: action.payload,
          isLoading: false,
          isSuccess: true,
          errorText: '',
        },
        paymentSettings: {
          ...state.paymentSettings,
          isLoading: false
        }
      }
    case INITIATE_MICRO_DEPOSITS_FAILURE:
      return {
        ...state,
        initiateMicroDeposit: {
          sourceId: 0,
          isLoading: false,
          isSuccess: false,
          errorText: action.payload.message
        },
        paymentSettings: {
          ...state.paymentSettings,
          isLoading: false
        }
      }
    case GET_FUNDING_SOURCE_TOKEN:
      return {
        ...state,
        fundingSourceToken: {
          token: '',
          isLoading: true,
          errorText: ''
        }
      }
    case GET_FUNDING_SOURCE_TOKEN_SUCCESS:
      return {
        ...state,
        fundingSourceToken: {
          token: action.payload,
          isLoading: false,
          errorText: ''
        }
      }
    case GET_FUNDING_SOURCE_TOKEN_FAILURE:
      return {
        ...state,
        fundingSourceToken: {
          token: '',
          isLoading: false,
          errorText: action.payload.message
        }
      }
    case RESET_ADD_FUNDING_SOURCE:
      return {
        ...state,
        dwollaAddFundingSource: DwollaAddFundingSourceDefaultState,
        addFundingSource: AddFundingSourceDefaultState,
        initiateMicroDeposit: InitiateMicroDepositsDefaultState
      }
    case DWOLLA_ADD_FUNDING_SOURCE_SUCCESS:
      return {
        ...state,
        dwollaAddFundingSource: {
          fundingSourceId: action.payload,
          errorText: ''
        }
      }
    case DWOLLA_ADD_FUNDING_SOURCE_FAILURE:
      return {
        ...state,
        dwollaAddFundingSource: {
          fundingSourceId: '',
          errorText: action.payload.message
        }
      }
    default:
      return state
  }
}
