import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';

import ManufacturerReviewDrawer from './common/ManufacturerReviewDrawer';
import ManufacturerReviewFooter from './common/ManufacturerReviewFooter';
import ManufacturerReviewHeader from './common/ManufacturerReviewHeader';
import ManufacturerReviewPrimaryFilters from './common/ManufacturerReviewPrimaryFilters';
import ManufacturerReviewTable from './common/ManufacturerReviewTable';
import { FilterValueSummary } from '../../common/filter/FilterToggleButton';
import { SortingFilter } from '../../../store/appcommon/types';
import { clearManufacturerDisputes, getManufacturerDisputes, getManufacturerDisputesAdvancedFilterOptions, getManufacturerDisputesPrimaryFilterOptions, getManufacturerDisputesResponseTotals, getManufacturerSummary, updateManufacturerDisputesFilters, updateManufacturerDisputesPageNumber, updateManufacturerDisputesSelectedClaim, updateManufacturerDisputesSort, updateSingleClaimResponse } from '../../../store/mdrp/disputescenter/actionCreators';
import { OpenDisputesTabMap, NO_NEW_DISPUTES_HEADER, NO_NEW_DISPUTES_MESSAGE, OpenDisputesStatusTypeMap, OPEN_DISPUTES_TAB, ManufacturerReviewFilterLabels } from '../../../store/mdrp/disputescenter/constants';
import { getPagedManufacturerClaims, selectManufacturerReviewActiveTab, selectManufacturerReviewActiveTabFilters } from '../../../store/mdrp/disputescenter/selectors';
import { ClaimModel, ManufacturerDisputesFilterModel, ManufacturerDisputesFilterOptionsModel, ManufacturerSummaryModel } from '../../../store/mdrp/disputescenter/types';
import { API_PAGE_SIZE, UI_PAGE_SIZE } from '../../../store/requestcenter/constants';
import { RootState } from '../../../store/RootState';
import '../../../themes/kalderos/MDRP/DisputesCenter/ManufacturerReview.scss';
import { canUseCachedRecords } from '../../../utils/datatableHelper';
import { formatMMDDYYYY } from '../../../utils/dateHelper';

export interface ManufacturerReviewProps extends RouteComponentProps {
  activeIndex: number;
  activeTab: string;
  clearManufacturerDisputes: () => void;
  currentApiPage: number;
  currentPage: number;
  filterOptions: ManufacturerDisputesFilterOptionsModel;
  filters: ManufacturerDisputesFilterModel;
  getManufacturerDisputes: (stateCode: string, manufacturerId: number, disputeStatus: string, activeTabIndex: number) => void;
  getManufacturerDisputesAdvancedFilterOptions: (stateCode: string, manufacturerId: number, disputeStatus: string, filters: ManufacturerDisputesFilterModel) => void;
  getManufacturerDisputesPrimaryFilterOptions: (stateCode: string, manufacturerId: number, disputeStatus: string) => void;
  getManufacturerDisputesResponseTotals: (stateCode: string, manufacturerId: number) => void;
  getManufacturerSummary: (stateCode: string, manufacturerId: number) => void;
  isLoading: boolean;
  manufacturerId: number;
  manufacturerSummary: ManufacturerSummaryModel;
  pagedClaims: ClaimModel[];
  totalCount: number;
  updateManufacturerDisputesFilters: (stateCode: string, manufacturerId: number, disputeStatus: string, activeTab: string, filters: ManufacturerDisputesFilterModel) => void;
  updateManufacturerDisputesPageNumber: (pageNumber: number) => void;
  updateManufacturerDisputesSelectedClaim: (index: number) => void;
  updateManufacturerDisputesSort: (stateCode: string, manufacturerId: number, disputeStatus: string, isSortAsc: boolean, sortBy: string) => void;
  updateSingleClaimResponse: (stateCode: string, manufacturerId: number, claimId: number, stateResponse: string, index: number) => void;
}

export const ManufacturerReview = (props: ManufacturerReviewProps) => {
  const { activeIndex, activeTab, clearManufacturerDisputes, currentApiPage, currentPage, filterOptions, filters, getManufacturerDisputes, getManufacturerDisputesAdvancedFilterOptions, getManufacturerDisputesPrimaryFilterOptions, getManufacturerDisputesResponseTotals, getManufacturerSummary, 
    history, isLoading, manufacturerId, manufacturerSummary, pagedClaims, totalCount, updateManufacturerDisputesFilters, updateManufacturerDisputesPageNumber, updateManufacturerDisputesSelectedClaim, updateManufacturerDisputesSort, updateSingleClaimResponse } = props;

  const tableHeaderRef = useRef<HTMLDivElement>(null);

  const [errorHeader, setErrorHeader] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [advancedFilterSummary, setAdvancedFilterSummary] = useState<FilterValueSummary[]>([]);
  const [areAdvancedFiltersOpen, setAreAdvancedFiltersOpen] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedClaimIndex, setSelectedClaimIndex] = useState<number | null>(null);

  const [isInitialOptionsLoadDone, setIsInitialOptionsLoadDone] = useState<boolean>(false);
  const [isInitialSummaryLoadDone, setIsInitialSummaryLoadDone] = useState<boolean>(false);

  const { advancedFilterOptions, primaryFilterOptions } = filterOptions;
  const isNewDisputesTab = activeIndex === OPEN_DISPUTES_TAB.NEW;
  const disputeStatus = OpenDisputesStatusTypeMap[activeIndex];

  const setErrorText = () => {
    if (isNewDisputesTab) {
      setErrorHeader(NO_NEW_DISPUTES_HEADER)
      setErrorMessage(NO_NEW_DISPUTES_MESSAGE)
    } else {
      setErrorHeader('')
      setErrorMessage('')
    }
  };

  const setFilterSummary = () => {
    const summary: FilterValueSummary[] = [];

    Object.keys(filters).forEach((key) => {
      const val = (filters as { [key: string]: any })[key];
      if (key !== 'labeler' && key !== 'ndc' && key !== 'rebateQtr') {
        if (Array.isArray(val)) {
          summary.push({ name: ManufacturerReviewFilterLabels[key], values: val })
        } else if (val) {
          if (key === 'minDateOfService' || key === 'maxDateOfService') {
            summary.push({ name: ManufacturerReviewFilterLabels[key], values: [formatMMDDYYYY(val)] })
          } else {
            summary.push({ name: ManufacturerReviewFilterLabels[key], values: [val] })
          }
        }
      }
    });

    setAdvancedFilterSummary(summary);
  };

  const routeToTab = (type: number) => {
    const tab = Object.keys(OpenDisputesTabMap).find(key => OpenDisputesTabMap[key] === type);

    if (tab) {
      history.push(`/states/disputes/open/manufacturer/${manufacturerId}/${tab}`);
    }
  };

  const handleCellSorting = (sort: SortingFilter) => {
    const { isSortAsc, sortBy } = sort;
    updateManufacturerDisputesSort('AK', manufacturerId, disputeStatus, isSortAsc, sortBy);
  }

  const handlePageChange = (e: any, d: any) => {
    const activePage = d.value === 'page-left' ? currentPage - 1 : currentPage + 1;
    updateManufacturerDisputesPageNumber(activePage);

    if (!canUseCachedRecords(activePage, currentApiPage, UI_PAGE_SIZE, API_PAGE_SIZE)) {
      getManufacturerDisputes('AK', manufacturerId, disputeStatus, activeIndex);
    }
  };

  const applyFilters = (values: ManufacturerDisputesFilterModel, isPrimaryFilter: boolean = false) => {
    if (isPrimaryFilter) {
      const filterValues = {
        icn: '',
        labeler: values.labeler,
        ndc: values.ndc,
        // manualDisputeCode: null,
        maxDateOfService: '',
        minDateOfService: '',
        pharmacyNpi: null,
        productName: null,
        programName: null,
        rebateQtr: values.rebateQtr,
        rxNumber: ''
      };

      updateManufacturerDisputesFilters('AK', manufacturerId, disputeStatus, activeTab, filterValues);
      getManufacturerDisputesAdvancedFilterOptions('AK', manufacturerId, disputeStatus, filterValues);
    } else {
      updateManufacturerDisputesFilters('AK', manufacturerId, disputeStatus, activeTab, values);
    }
  };

  const clearAdvancedFilters = () => {
    const emptyFilters: ManufacturerDisputesFilterModel = {
      icn: '',
      labeler: filters.labeler,
      ndc: filters.ndc,
      // manualDisputeCode: null,
      maxDateOfService: '',
      minDateOfService: '',
      pharmacyNpi: null,
      productName: null,
      programName: null,
      rebateQtr: filters.rebateQtr,
      rxNumber: ''
    };

    applyFilters(emptyFilters);
    setAreAdvancedFiltersOpen(false);
  };

  const handleFilterToggle = (isOpen: boolean) => {
    setAreAdvancedFiltersOpen(isOpen);
  };

  const handleSelectClaim = (claimIndex: number) => {
    setSelectedClaimIndex(claimIndex);
    setIsDrawerOpen(true);
    updateManufacturerDisputesSelectedClaim(claimIndex)
  };

  const closeDrawer = () => {
    setSelectedClaimIndex(null);
    setIsDrawerOpen(false);
  };

  const handleInlineResponseClick = (claim: ClaimModel, response: string) => {
    updateSingleClaimResponse('AK', manufacturerId, claim.id, response, claim.index)
  };

  useEffect(() => {
    getManufacturerSummary('AK', manufacturerId);
    getManufacturerDisputesPrimaryFilterOptions('AK', manufacturerId, disputeStatus);
    getManufacturerDisputesAdvancedFilterOptions('AK', manufacturerId, disputeStatus, filters);
    getManufacturerDisputesResponseTotals('AK', manufacturerId)

    return () => {
      clearManufacturerDisputes();
    }
  }, [])

  useEffect(() => {
    getManufacturerDisputes('AK', manufacturerId, disputeStatus, activeIndex);
    setErrorText();
  }, [activeIndex])

  useEffect(() => {
    const handleMouseUp = (event: MouseEvent) => {
      if (areAdvancedFiltersOpen) {
        if (tableHeaderRef && !tableHeaderRef.current?.contains(event.target as Node) && !isLoading) {
          setAreAdvancedFiltersOpen(false);
        }
      }
    };
    document.addEventListener('mouseup', (e) => handleMouseUp(e));

    return () => {
      document.removeEventListener('mouseup', (e) => handleMouseUp(e));
    }
  }, [areAdvancedFiltersOpen]);

  useEffect(() => {
    if (!isInitialOptionsLoadDone && primaryFilterOptions.rebateQtrs.length > 0 && advancedFilterOptions.programNames.length > 0) {
      setIsInitialOptionsLoadDone(true);
    }

    if (!isInitialSummaryLoadDone && manufacturerSummary.name !== '') {
      setIsInitialSummaryLoadDone(true);
    }
  }, [filterOptions, manufacturerSummary])

  useEffect(() => {
    setFilterSummary();
  }, [filters])

  return (
    <Fragment>
      {!isInitialSummaryLoadDone ?
        <Dimmer active inverted><Loader inverted content='Loading' /></Dimmer> :
        <Fragment>
          <div className="mfr-review scroll-page">
            <ManufacturerReviewHeader
              activeIndex={activeIndex}
              isLoading={isLoading}
              manufacturer={manufacturerSummary}
              onTabClick={routeToTab}
            />
            <div className="mfr-review-body">
              {(isNewDisputesTab && isInitialOptionsLoadDone) && 
              <Fragment>
                <ManufacturerReviewPrimaryFilters 
                  filters={filters}
                  isLoading={isLoading}
                  onApplyFilters={applyFilters}
                  options={primaryFilterOptions}
                />
                <ManufacturerReviewTable
                  activeIndex={activeIndex}
                  claims={pagedClaims}
                  currentPage={currentPage}
                  errorHeader={errorHeader}
                  errorMessage={errorMessage}
                  filters={filters}
                  filterSummary={advancedFilterSummary}
                  handleCellSorting={handleCellSorting}
                  handleInlineResponseClick={handleInlineResponseClick}
                  handlePageChange={handlePageChange}
                  isFilterOpen={areAdvancedFiltersOpen}
                  isLoading={isLoading}
                  onApplyFilters={applyFilters}
                  onFilterClear={clearAdvancedFilters}
                  onFilterToggle={handleFilterToggle}
                  onRowClick={handleSelectClaim}
                  selectedClaimIndex={selectedClaimIndex}
                  tableHeaderRef={tableHeaderRef}
                  totalCount={totalCount}
                />
                {isDrawerOpen &&
                  <ManufacturerReviewDrawer
                    activeIndex={activeIndex}
                    handleSelectClaim={handleSelectClaim}
                    isOpen={isDrawerOpen}
                    onClose={closeDrawer}
                  />
                }
              </Fragment>
              }
              {(isLoading || !isInitialOptionsLoadDone) && <Loader active className="mfr-review-body-loader" content='Loading' />}
            </div>
          </div>
          {(isNewDisputesTab && isInitialOptionsLoadDone) && <ManufacturerReviewFooter />}
        </Fragment>
      }
    </Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  activeTab: selectManufacturerReviewActiveTab(state),
  currentApiPage: state.MdrpState.disputesCenter.disputesReview.manufacturerDisputes.page,
  currentPage: state.MdrpState.disputesCenter.disputesReview.manufacturerDisputes.currentPage,
  filterOptions: state.MdrpState.disputesCenter.disputesReview.manufacturerDisputesFilterOptions,
  filters: selectManufacturerReviewActiveTabFilters(state),
  isLoading: state.MdrpState.disputesCenter.disputesReview.manufacturerDisputes.isLoading,
  manufacturerSummary: state.MdrpState.disputesCenter.disputesReview.manufacturerSummary.manufacturer,
  pagedClaims: getPagedManufacturerClaims(state),
  totalCount: state.MdrpState.disputesCenter.disputesReview.manufacturerDisputes.totalCount,
});

const mapDispatchToProps = (dispatch: any) => ({
  clearManufacturerDisputes: () => dispatch(clearManufacturerDisputes()),
  getManufacturerDisputes: (stateCode: string, manufacturerId: number, disputeStatus: string, activeTabIndex: number) => dispatch(getManufacturerDisputes(stateCode, manufacturerId, disputeStatus, activeTabIndex)),
  getManufacturerDisputesAdvancedFilterOptions: (stateCode: string, manufacturerId: number, disputeStatus: string, filters: ManufacturerDisputesFilterModel) => dispatch(getManufacturerDisputesAdvancedFilterOptions(stateCode, manufacturerId, disputeStatus, filters)),
  getManufacturerDisputesPrimaryFilterOptions: (stateCode: string, manufacturerId: number, disputeStatus: string) => dispatch(getManufacturerDisputesPrimaryFilterOptions(stateCode, manufacturerId, disputeStatus)),
  getManufacturerDisputesResponseTotals: (stateCode: string, manufacturerId: number) => dispatch(getManufacturerDisputesResponseTotals(stateCode, manufacturerId)),
  getManufacturerSummary: (stateCode: string, manufacturerId: number) => dispatch(getManufacturerSummary(stateCode, manufacturerId)),
  updateManufacturerDisputesFilters: (stateCode: string, manufacturerId: number, disputeStatus: string, activeTab: string, filters: ManufacturerDisputesFilterModel) => dispatch(updateManufacturerDisputesFilters(stateCode, manufacturerId, disputeStatus, activeTab, filters)),
  updateManufacturerDisputesPageNumber: (pageNumber: number) => dispatch(updateManufacturerDisputesPageNumber(pageNumber)),
  updateManufacturerDisputesSelectedClaim: (index: number) => dispatch(updateManufacturerDisputesSelectedClaim(index)),
  updateManufacturerDisputesSort: (stateCode: string, manufacturerId: number, disputeStatus: string, isSortAsc: boolean, sortBy: string) => dispatch(updateManufacturerDisputesSort(stateCode, manufacturerId, disputeStatus, isSortAsc, sortBy)),
  updateSingleClaimResponse: (stateCode: string, manufacturerId: number, claimId: number, stateResponse: string, index: number) => dispatch(updateSingleClaimResponse(stateCode, manufacturerId, claimId, stateResponse, index))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManufacturerReview));
