import { ErrorModel } from '../store/applicationTypes';

export enum RESPONSE_TYPE {
  JSON,
  BLOB,
  TEXT,
  NONE
}

export async function commonResponseHandler (response: Response, type: RESPONSE_TYPE = RESPONSE_TYPE.JSON) {
  if (response.ok) {
    switch (type) {
      case RESPONSE_TYPE.JSON:
        return await response.json();
      case RESPONSE_TYPE.BLOB:
        return await response.blob();
      case RESPONSE_TYPE.TEXT:
        return await response.text();
      case RESPONSE_TYPE.NONE:
        return;
    }
  } else {
    let error;
    let traceId;
    let statusCode;
    try {

      //Try JSON first (API errors)
      try {
        const respObj = await response.json();
        error = respObj.Message;
        traceId = respObj.traceId;
        statusCode = respObj.StatusCode;
      } catch {
        error = null;
      }

      //Try Text next (external/browser errors)
      if (!error) {
        try {
          error = (await response.text()); //Other Error
        } catch {
          error = null;
        }
      }

      //If all else fails, just use the HTTP status
      if (!error) {
        error = response.statusText ? response.statusText : response.status;
      }

      // Check status code too
      if (!statusCode) {
        statusCode = response.status;
      }

    } catch (ex) {
      error = response.statusText ? response.statusText : response.status;
    }

    //make sure we throw *something*
    if (!error) {
      error = 'An unknown error occurred';
    }

    const errorModel: ErrorModel = {
      message: error,
      traceId: traceId,
      statusCode: statusCode
    }
    throw errorModel;
  }
}
