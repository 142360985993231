import { SortingFilter } from '../appcommon/types';
import { ErrorModel } from '../applicationTypes';
import { DiscountRequestResponseModel, TransactionsFilterModel } from '../transactionscommon/types';
import {
  RemittanceSummaryPeriodByYear,
  RemittancePeriodSummaryDetail,
  RemittanceMonthSummary,
  RemittancePeriodSummary,
  RemittanceDetailsHeaderFilterModel,
  RemittanceDetailsCountsModel,
} from './types';

export enum REMITTANCE_DETAILS_TAB {
  ALL = 0,
  FAILED = 1,
  ADJUSTED = 2,
  APPROVED = 3,
  PROCESSED = 4
}

export const UI_PAGE_SIZE = 25;
export const API_PAGE_SIZE = 100;

export const REMITTANCE_CLIENT_ERROR_HEADER = 'Oh no! We can’t load this page.';
export const REMITTANCE_CLIENT_ERROR_MESSAGE = 'Try selecting a different Remittance Advice.';

export const GET_REMITTANCE_SUMMARY_PERIODS = 'GET_REMITTANCE_SUMMARY_PERIODS';
export const GET_REMITTANCE_SUMMARY_PERIODS_SUCCESS = 'GET_REMITTANCE_SUMMARY_PERIODS_SUCCESS';
export const GET_REMITTANCE_SUMMARY_PERIODS_FAILURE = 'GET_REMITTANCE_SUMMARY_PERIODS_FAILURE';

export const GET_REMITTANCE_MONTH_SUMMARY = 'GET_REMITTANCE_MONTH_SUMMARY';
export const GET_REMITTANCE_MONTH_SUMMARY_SUCCESS = 'GET_REMITTANCE_MONTH_SUMMARY_SUCCESS';
export const GET_REMITTANCE_MONTH_SUMMARY_FAILURE = 'GET_REMITTANCE_MONTH_SUMMARY_FAILURE';

export const GET_REMITTANCE_PERIOD_DETAILS = 'GET_REMITTANCE_PERIOD_DETAILS';
export const GET_REMITTANCE_PERIOD_DETAILS_SUCCESS = 'GET_REMITTANCE_PERIOD_DETAILS_SUCCESS';
export const GET_REMITTANCE_PERIOD_DETAILS_FAILURE = 'GET_REMITTANCE_PERIOD_DETAILS_FAILURE';
export const UPDATE_REMITTANCE_PERIOD_DETAILS_FILTER = 'UPDATE_REMITTANCE_PERIOD_DETAILS_FILTER';

export const GET_REMITTANCE_INVOICE_DETAILS = 'GET_REMITTANCE_INVOICE_DETAILS';
export const GET_REMITTANCE_INVOICE_DETAILS_SUCCESS = 'GET_REMITTANCE_INVOICE_DETAILS_SUCCESS';
export const GET_REMITTANCE_INVOICE_DETAILS_FAILURE = 'GET_REMITTANCE_INVOICE_DETAILS_FAILURE';

export const GET_REMITTANCE_TRANSACTION_COUNTS = 'GET_REMITTANCE_TRANSACTION_COUNTS';
export const GET_REMITTANCE_TRANSACTION_COUNTS_SUCCESS = 'GET_REMITTANCE_TRANSACTION_COUNTS_SUCCESS';
export const GET_REMITTANCE_TRANSACTION_COUNTS_FAILURE = 'GET_REMITTANCE_TRANSACTION_COUNTS_FAILURE';

export const GET_REMITTANCE_TRANSACTIONS = 'GET_REMITTANCE_TRANSACTIONS';
export const GET_REMITTANCE_TRANSACTIONS_SUCCESS = 'GET_REMITTANCE_TRANSACTIONS_SUCCESS';
export const GET_REMITTANCE_TRANSACTIONS_FAILURE = 'GET_REMITTANCE_TRANSACTIONS_FAILURE';

export const UPDATE_REMITTANCE_TRANSACTIONS_PAGE = 'UPDATE_REMITTANCE_TRANSACTIONS_PAGE';
export const UPDATE_REMITTANCE_TRANSACTIONS_SORT = 'UPDATE_REMITTANCE_TRANSACTIONS_SORT';
export const UPDATE_REMITTANCE_TRANSACTIONS_FILTER = 'UPDATE_REMITTANCE_TRANSACTIONS_FILTER';

export const RESET_REMITTANCE_DETAILS = 'RESET_REMITTANCE_DETAILS';
export const RESET_REMITTANCE_TRANSACTIONS = 'RESET_REMITTANCE_TRANSACTIONS';

interface GetRemittanceSummaryPeriodsAction {
  type: typeof GET_REMITTANCE_SUMMARY_PERIODS
}

interface GetRemittanceSummaryPeriodsSuccessAction {
  type: typeof GET_REMITTANCE_SUMMARY_PERIODS_SUCCESS,
  payload: RemittanceSummaryPeriodByYear[]
}

interface GetRemittanceSummaryPeriodsFailureAction {
  type: typeof GET_REMITTANCE_SUMMARY_PERIODS_FAILURE,
  payload: ErrorModel
}

interface GetRemittanceMonthSummaryAction {
  type: typeof GET_REMITTANCE_MONTH_SUMMARY,
  payload: {
    year: number,
    month: number
  }
}

interface GetRemittanceMonthSummarySuccessAction {
  type: typeof GET_REMITTANCE_MONTH_SUMMARY_SUCCESS,
  payload: RemittanceMonthSummary
}

interface GetRemittanceMonthSummaryFailureAction {
  type: typeof GET_REMITTANCE_MONTH_SUMMARY_FAILURE
  payload: ErrorModel
}

interface GetRemittancePeriodDetailsAction {
  type: typeof GET_REMITTANCE_PERIOD_DETAILS,
  payload: {
    year: number;
    month: number;
    period: number;
  }
}

interface GetRemittancePeriodDetailsSuccessAction {
  type: typeof GET_REMITTANCE_PERIOD_DETAILS_SUCCESS,
  payload: RemittancePeriodSummary
}

interface GetRemittancePeriodDetailsFailureAction {
  type: typeof GET_REMITTANCE_PERIOD_DETAILS_FAILURE,
  payload: ErrorModel
}

interface UpdateRemittancePeriodDetailsFilterAction {
  type: typeof UPDATE_REMITTANCE_PERIOD_DETAILS_FILTER,
  payload: RemittanceDetailsHeaderFilterModel
}

interface GetRemittanceInvoiceDetailsAction {
  type: typeof GET_REMITTANCE_INVOICE_DETAILS,
  payload: {
    remittanceId: number;
    participantId: string;
  }
}

interface GetRemittanceInvoiceDetailsSuccessAction {
  type: typeof GET_REMITTANCE_INVOICE_DETAILS_SUCCESS,
  payload: RemittancePeriodSummaryDetail
}

interface GetRemittanceInvoiceDetailsFailureAction {
  type: typeof GET_REMITTANCE_INVOICE_DETAILS_FAILURE,
  payload: ErrorModel
}

interface GetRemittanceTransactionsCountsAction {
  type: typeof GET_REMITTANCE_TRANSACTION_COUNTS
}

interface GetRemittanceTransactionsCountsSuccessAction {
  type: typeof GET_REMITTANCE_TRANSACTION_COUNTS_SUCCESS,
  payload: RemittanceDetailsCountsModel
}

interface GetRemittanceTransactionsCountsFailureAction {
  type: typeof GET_REMITTANCE_TRANSACTION_COUNTS_FAILURE,
  payload: ErrorModel
}

interface GetRemittanceTransactionsAction {
  type: typeof GET_REMITTANCE_TRANSACTIONS,
  payload: REMITTANCE_DETAILS_TAB
}

interface GetRemittanceTransactionsSuccessAction {
  type: typeof GET_REMITTANCE_TRANSACTIONS_SUCCESS,
  payload: DiscountRequestResponseModel
}

interface GetRemittanceTransactionsFailureAction {
  type: typeof GET_REMITTANCE_TRANSACTIONS_FAILURE,
  payload: ErrorModel
}

interface UpdateRemittanceTransactionsPageAction {
  type: typeof UPDATE_REMITTANCE_TRANSACTIONS_PAGE
  payload: number
}

interface UpdateRemittanceTransactionsSortAction {
  type: typeof UPDATE_REMITTANCE_TRANSACTIONS_SORT,
  payload: SortingFilter
}

interface UpdateRemittanceTransactionsFilterAction {
  type: typeof UPDATE_REMITTANCE_TRANSACTIONS_FILTER,
  payload: TransactionsFilterModel
}

interface ResetRemittanceDetailsAction {
  type: typeof RESET_REMITTANCE_DETAILS,
}

interface ResetRemittanceTransactionsAction {
  type: typeof RESET_REMITTANCE_TRANSACTIONS,
}

export type RemittanceActionTypes =
  GetRemittanceSummaryPeriodsAction |
  GetRemittanceSummaryPeriodsSuccessAction |
  GetRemittanceSummaryPeriodsFailureAction |
  GetRemittanceMonthSummaryAction |
  GetRemittanceMonthSummarySuccessAction |
  GetRemittanceMonthSummaryFailureAction |
  GetRemittancePeriodDetailsAction |
  GetRemittancePeriodDetailsSuccessAction |
  GetRemittancePeriodDetailsFailureAction |
  UpdateRemittancePeriodDetailsFilterAction |
  GetRemittanceInvoiceDetailsAction |
  GetRemittanceInvoiceDetailsSuccessAction |
  GetRemittanceInvoiceDetailsFailureAction |
  GetRemittanceTransactionsCountsAction |
  GetRemittanceTransactionsCountsSuccessAction |
  GetRemittanceTransactionsCountsFailureAction |
  GetRemittanceTransactionsAction |
  GetRemittanceTransactionsSuccessAction |
  GetRemittanceTransactionsFailureAction |
  UpdateRemittanceTransactionsPageAction |
  UpdateRemittanceTransactionsSortAction |
  UpdateRemittanceTransactionsFilterAction |
  ResetRemittanceDetailsAction |
  ResetRemittanceTransactionsAction;
