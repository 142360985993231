import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import TeamListComponent from './TeamListComponent';
import CeList from './CeList';
import { RootState } from '../../../store/RootState';
import { TeamMember, CoveredEntity, HealthSystemInfo, HealthSystem } from '../../../store/teammanagement/types';
import { getHealthSystemInfo, getCEListForHSModal, closeChangeHealthSystemsModal, setMockNewAO } from '../../../store/teammanagement/actionCreators';
import { hasAdditionalCesToOnboard, makeSelectOnboardingRole } from '../../../store/onboarding/selectors';
import { OnboardingStatusResponseModel } from '../../../store/onboarding/types';
import { ROLES } from '../../../store/applicationConstants';
import { checkOnboardingStatusSuccess, getCandidateCeList } from '../../../store/onboarding/actionCreators';
import LoadingBar from '../../common/LoadingBar';

export interface HealthSystemModalProps extends RouteComponentProps {
  coveredEntities: CoveredEntity[];
  entitledHealthSystems: HealthSystem[];
  activeHealthSystemInfo: HealthSystemInfo;
  members: TeamMember[];
  isOpen: boolean;
  isRequired: boolean;
  modalWaiting: boolean;
  ceListError: string;
  onboardingRole: string,
  hasAdditionalCesToOnboard: boolean,
  ceListLoading: boolean,
  getHealthSystemInfo: (healthSystemId: number, switchingHs: boolean) => void;
  getCEListForHSModal: (healthSystemId: number) => void;
  checkOnboardingStatusSuccess: (model: OnboardingStatusResponseModel) => void;
  closeChangeHealthSystemsModal: () => void;
  getCandidateCeList: () => void;
  setMockNewAO: () => void;
}

interface HealthSystemModalState {
  showTeamList: boolean;
  name: string;
  showId: number | null;
  continueOnboarding: boolean;
  hasOnboardingInProgress: boolean;
}

export class HealthSystemModal extends React.Component<HealthSystemModalProps, HealthSystemModalState> {
  constructor (props: Readonly<HealthSystemModalProps>) {
    super(props);
    this.state = {
      showTeamList: true,
      name: '',
      showId: null,
      continueOnboarding: false,
      hasOnboardingInProgress: false
    };
  }

  componentDidMount () {
    if (this.props.onboardingRole === ROLES.RECIPIENT_AUTHORIZING_OFFICIAL) {
      this.props.getCandidateCeList();
    } 
  }

  componentDidUpdate (prevProps: HealthSystemModalProps) {

    if (prevProps.onboardingRole !== ROLES.RECIPIENT_AUTHORIZING_OFFICIAL 
      && this.props.onboardingRole === ROLES.RECIPIENT_AUTHORIZING_OFFICIAL) {
      this.props.getCandidateCeList();
    }
    if ((prevProps.entitledHealthSystems !== this.props.entitledHealthSystems)&& this.props.entitledHealthSystems) {
      const reqOnboarding = this.props.entitledHealthSystems.filter((hs: HealthSystem) => hs.onboardingInProgress);
      if (reqOnboarding && reqOnboarding.length > 0) {
        this.setState({ hasOnboardingInProgress: true });
      } else if (this.state.continueOnboarding) {
        this.setState({ continueOnboarding: false });
      }
    } 
      
  }

  goToOnboarding = () => {
    this.close();
    this.props.checkOnboardingStatusSuccess({ onboardingCompleted: false, onboardingStatusValue: 1 });
    // if the user hasn't selected a HS yet, the app will be confused. reset to new AO state to let them onboard another.
    if (this.props.activeHealthSystemInfo.healthSystemId === 0) {
      this.props.setMockNewAO();
    }
    this.props.history.push('/onboarding');
  }

  close = () => {
    this.setState({ showId: null })
    this.props.closeChangeHealthSystemsModal();
  };

  showCeList = (id: number, name: string) => {
    this.props.getCEListForHSModal(id)
    this.setState({ showTeamList: false, name: name, showId: id });
  };

  handleBack = () => {
    this.setState({ showTeamList: true });
  };

  switchTeam = () => {
    this.props.closeChangeHealthSystemsModal();
    this.props.getHealthSystemInfo(this.state.showId!, true)
    this.props.history.push('/')
  };

  setShowId = (id: number | null) => {
    this.setState({ showId: id });
    const hs: HealthSystem[] = this.props.entitledHealthSystems.filter((hs: HealthSystem) => hs.healthSystemId === id);
    if (hs && hs.length > 0 && hs[0].onboardingInProgress) {
      this.setState({ continueOnboarding: true });
    } else if (this.state.continueOnboarding) {
      this.setState({ continueOnboarding: false })
    }
  };

  render () {
    const { showTeamList, name, showId, hasOnboardingInProgress} = this.state;
    const { isOpen, isRequired, coveredEntities, modalWaiting, entitledHealthSystems, activeHealthSystemInfo, ceListError, hasAdditionalCesToOnboard, ceListLoading } = this.props;
    return (
      <Modal
        open={isOpen}
        closeOnDimmerClick={false}
        closeOnEscape={!isRequired}
        onClose={this.close}
        className="switch-hs-modal app-modal medium-modal">
        {(ceListLoading && !hasOnboardingInProgress) && <LoadingBar />}
        {(hasAdditionalCesToOnboard && !hasOnboardingInProgress) && <div className="onboarding-info">
            You have Health Systems and/or CEs that have not been onboarded. 
            Click <button type="button" onClick={() => this.goToOnboarding()}><b>here</b></button> to finish onboarding.
        </div>}
        <div className="app-modal-header">
          {'Select Your Health System'}
          {showTeamList ? null : <p className="sub-header overflow-ellipsis">Covered Entities with {name}</p>}
        </div>
        <div className="app-modal-content">
          {
            showTeamList ?
              <TeamListComponent 
                setShowId={this.setShowId} 
                waiting={modalWaiting}
                showId={showId !== null ? showId : activeHealthSystemInfo.healthSystemId} 
                healthSystems={entitledHealthSystems} 
                showCeList={this.showCeList}
              /> :
              <CeList ceListError={ceListError} waiting={modalWaiting} ceList={coveredEntities} />
          }
        </div>
        <div className="app-modal-btn-container">
          {showTeamList && !isRequired && <Button onClick={() => this.close()} className="app-button button-white app-modal-cancel-button">Cancel</Button>}
          {showTeamList ? 
            <Button 
              className='app-button button-dark' 
              disabled={showId === null || activeHealthSystemInfo.healthSystemId === showId} 
              onClick={() => this.switchTeam()}>
              {this.state.continueOnboarding ? 'Continue Onboarding' : isRequired ? 'Select' : 'Switch'}
            </Button> : 
            <Button 
              disabled={showTeamList} 
              className="app-button button-dark app-modal-submit-button" 
              onClick={() => this.handleBack()}>
            Back
            </Button>}
        </div>
      </Modal>
    )
  }
}



const mapStateToProps = (state: RootState) => ({
  members: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.activeMembers,
  activeHealthSystemInfo: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo,
  isOpen: state.TeamManagementState.changeHealthSystemModal.isOpen,
  isRequired: state.TeamManagementState.changeHealthSystemModal.isRequired,
  coveredEntities: state.TeamManagementState.changeHealthSystemModal.coveredEntities,
  entitledHealthSystems: state.TeamManagementState.entitledHealthSystems,
  modalWaiting: state.TeamManagementState.changeHealthSystemModal.modalWaiting,
  ceListError: state.TeamManagementState.changeHealthSystemModal.errorText,
  onboardingRole: makeSelectOnboardingRole(state),
  hasAdditionalCesToOnboard: hasAdditionalCesToOnboard(state),
  ceListLoading: state.OnboardingState.associateCesAndHsStepModel.isLoading
});



const mapDispatchToProps = (dispatch: any) => ({
  getHealthSystemInfo: (healthSystemId: number, switchingHS: boolean) => dispatch(getHealthSystemInfo(healthSystemId, switchingHS)),
  getCEListForHSModal: (healthSystemId: number) => dispatch(getCEListForHSModal(healthSystemId)),
  checkOnboardingStatusSuccess: (model: OnboardingStatusResponseModel) => dispatch(checkOnboardingStatusSuccess(model)),
  closeChangeHealthSystemsModal: () => dispatch(closeChangeHealthSystemsModal()),
  getCandidateCeList: () => dispatch(getCandidateCeList()),
  setMockNewAO: () => dispatch(setMockNewAO())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HealthSystemModal))
