import React from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { RootState } from '../../store/RootState';
import { AdjustReasonsModel, SortingFilter } from '../../store/appcommon/types';
import { REQUEST_CENTER_TAB, REQUEST_CENTER_BUCKET, UI_PAGE_SIZE, API_PAGE_SIZE } from '../../store/requestcenter/constants';
import { RequestCenterTransactionCountsModel, RequestCenterTransactionsFilters } from '../../store/requestcenter/types';
import RequestCenterBuckets from './RequestCenterBuckets';
import { getRequestCenterTransactionCounts, getRequestCenterTransactions, resetRequestCenterTransactionCounts, resetRequestCenterTransactions, updateRequestCenterTransactionsBucket, updateRequestCenterTransactionsFilter, updateRequestCenterTransactionsPage, updateRequestCenterTransactionsSort } from '../../store/requestcenter/actionCreators';
import { DiscountRequestModel, TransactionsFilterModel } from '../../store/transactionscommon/types';
import RequestCenterTransactionsTableHeader from './RequestCenterTransactionsTableHeader';
import { getPagedRequestCenterTransactions, isRequestCenterTransactionsLoading } from '../../store/requestcenter/selectors';
import { getActiveHealthSystemRole, hasVerifiedFundingSource } from '../../store/teammanagement/selectors';
import { canUseCachedRecords } from '../../utils/datatableHelper';
import { reverseTransactions } from '../../store/transactionscommon/actionCreators';
import EmptyTransactions from '../common/transactions/EmptyTransactions';
import { NO_DISCOUNT_REQUESTS_HEADER, NO_DISCOUNT_REQUESTS_MESSAGE, NO_FILTER_RESULTS_HEADER, NO_FILTER_RESULTS_MESSAGE } from '../../store/applicationConstants';
import { get30DaysAgo, getEndOfTheDayNonUTC, getStartOfTheDayNonUTC } from '../../utils/dateHelper';
import RequestCenterTransactionsTable from './RequestCenterTransactionsTable';
import { RECIPIENT_TEAM_MANAGEMENT_USER_ROLES } from '../../store/teammanagement/constants';
import { isAdminAllAccess } from '../../utils/roleUtil';
import RequestCenterTransactionsDrawer from './RequestCenterTransactionsDrawer';
import ReversalModal from '../common/transactions/ReversalModal';
import CorrectRequestForm from './RequestForm/CorrectRequestForm';

const MODIFIER_ROLES: string[] = [RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN, RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.SUBMITTER];

export interface RequestCenterTransactionsProps {
  tab: REQUEST_CENTER_TAB,
  bucket: REQUEST_CENTER_BUCKET,
  counts: RequestCenterTransactionCountsModel,
  transactions: DiscountRequestModel[],
  currentApiPage: number,
  currentPage: number,
  totalCount: number,
  isLoading: boolean,
  reversalSuccess: boolean;
  filters: RequestCenterTransactionsFilters,
  adjustReasons: AdjustReasonsModel;
  activeHealthSystemRole: string;
  hasVerifiedFundingSource: boolean;
  userRoles: string[];
  getRequestCenterTransactionCounts: (tab: REQUEST_CENTER_TAB) => void;
  getRequestCenterTransactions: (tab: REQUEST_CENTER_TAB) => void;
  updateRequestCenterTransactionsBucket: (bucket: REQUEST_CENTER_BUCKET) => void;
  updateRequestCenterTransactionsPage: (pageNumber: number) => void;
  updateRequestCenterTransactionsSort: (sort: SortingFilter) => void;
  updateRequestCenterTransactionsFilter: (tab: REQUEST_CENTER_TAB, filters: TransactionsFilterModel) => void;
  reverseTransactions: (transactionIds: number[]) => void;
  resetRequestCenterTransactionCounts: () => void;
  resetRequestCenterTransactions: () => void;
}

interface RequestCenterTransactionsState {
  activeTransaction?: DiscountRequestModel,
  selectedTransactions: DiscountRequestModel[],
  isDrawerOpen: boolean;
  isReversalModalOpen: boolean;
  isCorrectFormOpen: boolean;
  isDrawerReversal: boolean;
}

const initialState: RequestCenterTransactionsState = {
  activeTransaction: undefined,
  selectedTransactions: [],
  isDrawerOpen: false,
  isReversalModalOpen: false,
  isCorrectFormOpen: false,
  isDrawerReversal: false
}

export class RequestCenterTransactions extends React.Component<RequestCenterTransactionsProps, RequestCenterTransactionsState> {
  constructor (props: Readonly<RequestCenterTransactionsProps>) {
    super(props);
    this.state = initialState;
  }

  componentDidMount () {
    this.props.updateRequestCenterTransactionsBucket(this.getDefaultBucket());
    this.loadData();
  }

  // It seems switching tabs doesn't mount/unmount just updates props
  componentDidUpdate (prevProps: RequestCenterTransactionsProps) {
    if ((this.props.tab !== prevProps.tab) || (this.props.reversalSuccess && !prevProps.reversalSuccess)) {
      this.setState({ ...initialState });
      this.props.resetRequestCenterTransactionCounts();
      this.props.resetRequestCenterTransactions();
      this.props.updateRequestCenterTransactionsBucket(this.getDefaultBucket());
      this.loadData();
    }
  }

  componentWillUnmount () {
    this.props.resetRequestCenterTransactions();
  }

  loadData = () => {
    this.props.getRequestCenterTransactions(this.props.tab);
    if (this.props.tab === REQUEST_CENTER_TAB.AWAITING || this.props.tab === REQUEST_CENTER_TAB.PROCESSED) {
      this.props.getRequestCenterTransactionCounts(this.props.tab);
    }
  }

  getDefaultBucket = () => {
    if (this.props.tab === REQUEST_CENTER_TAB.AWAITING || this.props.tab === REQUEST_CENTER_TAB.PROCESSED) {
      return REQUEST_CENTER_BUCKET.ALL;
    } else {
      return REQUEST_CENTER_BUCKET.NONE;
    }
  }

  handleBucketClick = (bucket: REQUEST_CENTER_BUCKET) => {
    this.props.resetRequestCenterTransactions();
    this.props.updateRequestCenterTransactionsBucket(bucket);
    this.props.getRequestCenterTransactions(this.props.tab);
  }


  handleRowClick = (transactionId: number) => {
    const transaction = this.props.transactions.find((d) => d.id === transactionId);
    if (transaction) {
      this.setState({ activeTransaction: transaction, isDrawerOpen: true });
    }
  }

  handlePageChange = (page: number) => {
    this.props.updateRequestCenterTransactionsPage(page);
    if (!canUseCachedRecords(page, this.props.currentApiPage, UI_PAGE_SIZE, API_PAGE_SIZE)) {
      this.props.getRequestCenterTransactions(this.props.tab);
    }
  }

  handleSort = (sort: SortingFilter) => {
    this.setState({ selectedTransactions: [], activeTransaction: undefined });
    this.props.updateRequestCenterTransactionsSort(sort);
    this.props.getRequestCenterTransactions(this.props.tab);
  }

  handleApplyFilter = (filter: TransactionsFilterModel) => {
    this.setState({ selectedTransactions: [], activeTransaction: undefined });
    this.props.updateRequestCenterTransactionsFilter(this.props.tab, filter);
    this.props.getRequestCenterTransactions(this.props.tab);
  }

  handleDrawerNext = () => {
    const index = this.props.transactions.indexOf(this.state.activeTransaction!);
    if (index !== -1 && index + 1 < this.props.transactions.length) {
      this.setState({ activeTransaction: this.props.transactions[index + 1] });
    }
  }

  handleDrawerPrevious = () => {
    const index = this.props.transactions.indexOf(this.state.activeTransaction!);
    if (index > 0) {
      this.setState({ activeTransaction: this.props.transactions[index - 1] });
    }
  }

  handleDrawerClose = () => {
    this.setState({ isDrawerOpen: false });
  }

  handleDrawerCorrect = () => {
    this.setState({ isCorrectFormOpen: true, isDrawerOpen: false });
  }

  handleDrawerReverse = () => {
    this.setState({ isReversalModalOpen: true, isDrawerReversal: true })
  }

  handleReverseSelection = () => {
    this.setState({ isReversalModalOpen: true, isDrawerReversal: false })
  }

  handleReverseModalSubmit = (transactionIds: number[]) => {
    this.props.reverseTransactions(transactionIds);
    this.setState({ isReversalModalOpen: false, isDrawerReversal: false, isDrawerOpen: false });
  }

  handleReverseModalClose = () => {
    this.setState({ isReversalModalOpen: false, isDrawerReversal: false });
  }

  handleCorrectSubmissionClose = (isSuccess: boolean) => {
    this.setState({ isCorrectFormOpen: false });
    if (isSuccess) {
      this.loadData();
    }
  }

  handleCheckboxClick = (transactionId: number, isChecked: boolean) => {
    const transaction = this.props.transactions.find(t => t.id === transactionId);
    if (transaction) {
      if (isChecked && !this.state.selectedTransactions.includes(transaction)) {
        this.setState({ selectedTransactions: [...this.state.selectedTransactions, transaction] })
      }
      if (!isChecked && this.state.selectedTransactions.includes(transaction)) {
        this.setState({ selectedTransactions: this.state.selectedTransactions.filter(t => t.id !== transactionId) });
      }
    }
  }

  handleCheckboxAllClick = (isChecked: boolean) => {
    if (isChecked) {
      this.setState({ selectedTransactions: [...this.props.transactions] })
    } else {
      this.setState({ selectedTransactions: [] })
    }
  }

  handleClearSelection = () => {
    this.setState({ selectedTransactions: [] })
  }

  render () {
    const { tab, bucket, counts, transactions, filters, isLoading, currentPage, totalCount, adjustReasons, activeHealthSystemRole, hasVerifiedFundingSource, userRoles } = this.props;
    const { isDrawerOpen, isReversalModalOpen, isDrawerReversal, isCorrectFormOpen, activeTransaction, selectedTransactions } = this.state;

    const filter = filters[tab];
    const areFiltersApplied = Object.values({ ...filter, 'startDate': '', 'endDate': '' }).some(filter => (filter !== null && filter !== ''));
    const areDatesApplied = filter.startDate !== getStartOfTheDayNonUTC(get30DaysAgo(new Date())) || filter.endDate !== getEndOfTheDayNonUTC(new Date());

    const isReadOnly = !MODIFIER_ROLES.includes(activeHealthSystemRole);

    const index = activeTransaction ? transactions.indexOf(activeTransaction) : -1;
    const nextEnabled = index !== -1 && index + 1 < transactions.length;
    const prevEnabled = index > 0;

    return (
      <div className='recipient-transactions-tab'>

        {isLoading &&
          <Dimmer active inverted>
            <Loader className="recipient-request-center-loader" inverted content="Loading" />
          </Dimmer>
        }

        <RequestCenterBuckets
          tab={tab}
          counts={counts}
          selectedBucket={bucket}
          onBucketClick={this.handleBucketClick}
        />
        <div className="padded-tab">
          <RequestCenterTransactionsTableHeader
            tab={tab}
            bucket={bucket}
            filter={filter}
            currentPage={currentPage}
            totalCount={totalCount}
            selectedTransactions={selectedTransactions}
            isLoading={isLoading}
            adjustReasons={adjustReasons}
            hasVerifiedFundingSource={hasVerifiedFundingSource}
            handleApplyFilter={this.handleApplyFilter}
            handlePageChange={this.handlePageChange}
            handleReverseSelection={this.handleReverseSelection}
            handleClearSelection={this.handleClearSelection}
          />

          <RequestCenterTransactionsTable
            tab={tab}
            bucket={bucket}
            transactions={transactions}
            activeTransactionId={activeTransaction?.id}
            selectedTransactionIds={selectedTransactions.map(t => t.id)}
            isReadOnly={isReadOnly}
            isAdminAllAccess={isAdminAllAccess(userRoles)}
            handleCellSorting={this.handleSort}
            handleRowClick={this.handleRowClick}
            handleCheckboxClick={this.handleCheckboxClick}
            handleCheckboxAllClick={this.handleCheckboxAllClick}
          />

          {isDrawerOpen &&
            <RequestCenterTransactionsDrawer
              selectedTransaction={activeTransaction!}
              nextEnabled={nextEnabled}
              prevEnabled={prevEnabled}
              hasVerifiedFundingSource={hasVerifiedFundingSource}
              handleClose={this.handleDrawerClose}
              handleNext={this.handleDrawerNext}
              handlePrevious={this.handleDrawerPrevious}
              handleCorrect={this.handleDrawerCorrect}
              handleReverse={this.handleDrawerReverse}
            />
          }

          {isCorrectFormOpen &&
            <CorrectRequestForm
              transaction={activeTransaction!}
              onClose={this.handleCorrectSubmissionClose}
            />
          }

          {isReversalModalOpen &&
            <ReversalModal
              transactions={isDrawerReversal ? [activeTransaction!] : selectedTransactions}
              hasVerifiedFundingSource={hasVerifiedFundingSource}
              onReverseClick={this.handleReverseModalSubmit}
              onCloseClick={this.handleReverseModalClose}
            />
          }

          {!isLoading && transactions.length === 0 &&
            <EmptyTransactions
              emptyTransactionsHeader={areFiltersApplied || areDatesApplied ? NO_FILTER_RESULTS_HEADER : NO_DISCOUNT_REQUESTS_HEADER}
              emptyTransactionsMessage={areFiltersApplied || areDatesApplied ? NO_FILTER_RESULTS_MESSAGE : NO_DISCOUNT_REQUESTS_MESSAGE}
            />
          }

        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  counts: state.RequestCenterState.counts,
  transactions: getPagedRequestCenterTransactions(state),
  bucket: state.RequestCenterState.transactions.bucket,
  currentApiPage: state.RequestCenterState.transactions.currentApiPage,
  currentPage: state.RequestCenterState.transactions.currentPage,
  totalCount: state.RequestCenterState.transactions.totalCount,
  isLoading: isRequestCenterTransactionsLoading(state),
  reversalSuccess: state.TransactionsCommon.reversalsModal.isSuccess,
  filters: state.RequestCenterState.filters,
  adjustReasons: state.AppCommonState.adjustReasons,
  activeHealthSystemRole: getActiveHealthSystemRole(state),
  hasVerifiedFundingSource: hasVerifiedFundingSource(state),
  userRoles: state.LoginState.user.roles || [],
});

const mapDispatchToProps = (dispatch: any) => ({
  getRequestCenterTransactionCounts: (tab: REQUEST_CENTER_TAB) => dispatch(getRequestCenterTransactionCounts(tab)),
  getRequestCenterTransactions: (tab: REQUEST_CENTER_TAB) => dispatch(getRequestCenterTransactions(tab)),
  updateRequestCenterTransactionsBucket: (bucket: REQUEST_CENTER_BUCKET) => dispatch(updateRequestCenterTransactionsBucket(bucket)),
  updateRequestCenterTransactionsPage: (pageNumber: number) => dispatch(updateRequestCenterTransactionsPage(pageNumber)),
  updateRequestCenterTransactionsSort: (sort: SortingFilter) => dispatch(updateRequestCenterTransactionsSort(sort)),
  updateRequestCenterTransactionsFilter: (tab: REQUEST_CENTER_TAB, filters: TransactionsFilterModel) => dispatch(updateRequestCenterTransactionsFilter(tab, filters)),
  reverseTransactions: (transactionIds: number[]) => dispatch(reverseTransactions(transactionIds)),
  resetRequestCenterTransactionCounts: () => dispatch(resetRequestCenterTransactionCounts()),
  resetRequestCenterTransactions: () => dispatch(resetRequestCenterTransactions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestCenterTransactions)
