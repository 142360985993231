import {
  AppCommonActionTypes,
  TOGGLE_LEFT_NAVIGATION,
  GET_STATE_LIST,
  GET_STATE_LIST_SUCCESS,
  GET_STATE_LIST_FAILURE,
  GET_LEGAL_DOCS,
  GET_LEGAL_DOCS_SUCCESS,
  GET_LEGAL_DOCS_FAILURE,
  GET_340B_IDS,
  GET_340B_IDS_SUCCESS,
  GET_340B_IDS_FAILURE,
  GET_ADJUST_REASONS,
  GET_ADJUST_REASONS_SUCCESS,
  GET_ADJUST_REASONS_FAILURE
} from './constants';
import { AdjustReasonsModel, CoveredEntityModel, LegalDocumentsModel, StateModel } from './types';
import { ErrorModel } from '../applicationTypes';

// this allows other app components to react to left navigation expand/collapse
export function reportToggleLeftNavigation (isExpanded: boolean): AppCommonActionTypes {
  return {
    type: TOGGLE_LEFT_NAVIGATION,
    payload: isExpanded
  }
}

export function getStateList (): AppCommonActionTypes {
  return {
    type: GET_STATE_LIST
  }
}

export function getStateListSuccess (states: StateModel[]): AppCommonActionTypes {
  return {
    type: GET_STATE_LIST_SUCCESS,
    payload: states
  }
}

export function getStateListFailure (errorModel: ErrorModel): AppCommonActionTypes {
  return {
    type: GET_STATE_LIST_FAILURE,
    payload: errorModel
  }
}

export function getLegalDocuments (): AppCommonActionTypes {
  return {
    type: GET_LEGAL_DOCS
  }
}

export function getLegalDocumentsSuccess (response: LegalDocumentsModel): AppCommonActionTypes {
  return {
    type: GET_LEGAL_DOCS_SUCCESS,
    payload: response
  }
}

export function getLegalDocumentsFailure (errorModel: ErrorModel): AppCommonActionTypes {
  return {
    type: GET_LEGAL_DOCS_FAILURE,
    payload: errorModel
  }
}


export function get340bIds (): AppCommonActionTypes {
  return {
    type: GET_340B_IDS
  }
}

export function get340bIdsSuccess (response: CoveredEntityModel[]): AppCommonActionTypes {
  return {
    type: GET_340B_IDS_SUCCESS,
    payload: response
  }
}

export function get340bIdsFailure (errorModel: ErrorModel): AppCommonActionTypes {
  return {
    type: GET_340B_IDS_FAILURE,
    payload: errorModel
  }
}

export function getAdjustReasons (): AppCommonActionTypes {
  return {
    type: GET_ADJUST_REASONS
  }
}

export function getAdjustReasonsSuccess (data: AdjustReasonsModel): AppCommonActionTypes {
  return {
    type: GET_ADJUST_REASONS_SUCCESS,
    payload: data
  }
}

export function getAdjustReasonsFailure (error: ErrorModel): AppCommonActionTypes {
  return {
    type: GET_ADJUST_REASONS_FAILURE,
    payload: error
  }
}