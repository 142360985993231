import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteProps } from 'react-router';
import { RootState } from './store/RootState';

export interface ProtectedRouteProps extends RouteProps {
  isAuthenticated?: boolean;
  allowedRoles?: string[];
  onboardingRoles?: string[];
  userRoles: string[] | null,
}

export class ProtectedRoute extends React.Component<ProtectedRouteProps> {

  render () {

    const { isAuthenticated, allowedRoles, userRoles, onboardingRoles } = this.props;

    if (isAuthenticated) {

      if (!allowedRoles) {
        return <Route {...this.props} />;
      }
      else {
        if (userRoles) {
          if (allowedRoles.some(r => userRoles.includes(r))) {
            return <Route {...this.props} />;
          }
        }
        if (onboardingRoles) {
          if (allowedRoles.some(r => onboardingRoles.includes(r))) {
            return <Route {...this.props} />;
          }
        }
      }

      return null;
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.LoginState.user.isAuthenticated,
  userRoles: state.LoginState.user.roles,
  onboardingRoles: state.TeamManagementState.teamManagementModel.currentOnboardingRoles,
});

export default connect(mapStateToProps)(ProtectedRoute);