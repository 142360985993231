import React from 'react'
import { Icon, TransitionablePortal, Button, Table, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../../store/RootState';
import CorrectDataEntry from './CorrectDataEntry';
import { formatMMDDYYYY } from '../../../utils/dateHelper';
import { formatUnits, formatDiscountPerUnit, formatDiscountAmount } from '../../../utils/numberFormatHelper';
import { getActiveHealthSystemId } from '../../../store/teammanagement/selectors';
import { LEFT_NAV_WIDTH } from '../../../store/applicationConstants';
import { DiscountProgramFieldsModel, DiscountRequestAdditionalFieldsModel, DiscountRequestFieldSubmitModel, DiscountRequestRequiredFieldsModel, RequestCenterCorrectRequestModel } from '../../../store/requestcenter/types';
import { DiscountRequestModel } from '../../../store/transactionscommon/types';
import { ContractElementsModel } from '../../../store/transactionscommon/types';
import { correctDiscountRequest, resetDiscountRequestForm } from '../../../store/requestcenter/actionCreators';

import CloseButton from '../../common/CloseButton';

export interface CorrectRequestFormProps {
  correct: RequestCenterCorrectRequestModel;
  transaction: DiscountRequestModel;
  contractElements: ContractElementsModel;
  activeHealthSystemId: number;
  isLeftNavExpanded: boolean;
  correctDiscountRequest: (id: number, fields: DiscountRequestFieldSubmitModel) => void;
  resetDiscountRequestForm: () => void;
  onClose: (isSuccess: boolean) => void;
}

export const UneditableFields = ({ fields }: { fields: DiscountRequestRequiredFieldsModel }) => {
  return (
    <div className='request-form-uneditable flex-column flex-grow'>
      {/* <div className='flex-column'>
        <div className='flex-row'>
          <div className='eta'>{claimTypeId}</div>
        </div>
        <div className='flex-row'>
          <div className='uneditable-label eta'>RETAIL/MEDICAL REQUEST</div>
        </div>
      </div> */}
      <div className='flex-column uneditable-field'>
        <div className='flex-row'>
          <div className='eta'>{fields.discountProgram}</div>
        </div>
        <div className='flex-row'>
          <div className='uneditable-label eta'>DISCOUNT PROGRAM</div>
        </div>
      </div>
      <div className='flex-column uneditable-field'>
        <div className='flex-row'>
          <div className='eta'>{fields.idCode}</div>
        </div>
        <div className='flex-row'>
          <div className='uneditable-label eta'>340B ID</div>
        </div>
      </div>
      <div className='flex-column uneditable-field'>
        <div className='flex-row'>
          <div className='eta'>{fields.ndc11}</div>
        </div>
        <div className='flex-row'>
          <div className='uneditable-label eta'>NDC11</div>
        </div>
      </div>
      <div className='flex-column uneditable-field'>
        <div className='flex-row'>
          <div className='eta'>{formatMMDDYYYY(fields.fillDate)}</div>
        </div>
        <div className='flex-row'>
          <div className='uneditable-label eta'>FILL DATE</div>
        </div>
      </div>
    </div>
  )
}

export class CorrectRequestForm extends React.Component<CorrectRequestFormProps>{

  getRequiredFieldValues = () => {
    const requiredFieldValues: DiscountRequestRequiredFieldsModel = {
      discountProgram: this.props.transaction.discountProgramDescription,
      ndc11: this.props.transaction.ndc11,
      fillDate: this.props.transaction.fillDate,
      idCode: this.props.transaction.idCode,
      claimType: this.props.transaction.claimTypeId
    }

    return requiredFieldValues;
  }

  closeWindow = () => {
    this.props.onClose(this.props.correct.isSuccess);
    this.props.resetDiscountRequestForm();
  }

  onSubmit = (values: DiscountRequestAdditionalFieldsModel) => {
    this.props.correctDiscountRequest(this.props.transaction.id, { ...values, ...this.getRequiredFieldValues() });
  }

  renderNavigationButton = (resultType: string, requestId: number, statusText: string) => {
    if (resultType === 'Original') {
      return requestId;
    } else if (resultType === 'Reversed') {
      let path = '';
      if (statusText === 'Pending Reversal') {
        path = '/recipients/reversals/pending';
      } else {
        path = '/recipients/reversals/processed';
      }
      return (
        <Link to={path} id='reversedTransaction_Pendo' onClick={() => this.closeWindow()} >
          {requestId}
        </Link>
      );
    } else {
      return (
        <Link to={'/recipients/requestcenter'} id='SubmittedTransaction_Pendo' onClick={() => this.closeWindow()} >
          {requestId}
        </Link>
      );
    }
  }

  render () {
    const { correct, transaction, contractElements } = this.props;
    const leftNavWidth = this.props.isLeftNavExpanded ? LEFT_NAV_WIDTH.EXPANDED : LEFT_NAV_WIDTH.COLLAPSED;

    const requiredFieldValues = this.getRequiredFieldValues();

    let discountProgramFields: DiscountProgramFieldsModel[] = [];
    if (transaction) {
      //const medicalContractElementSet = contractElements.medicalContractElements.find(e => e.contractId === selectedTransaction.contractId)!;
      const retailContractElementSet = contractElements.retailContractElements.find(e => e.contractId === transaction.contractId)!;
      //const allContractElements = selectedTransaction.claimTypeId === 'Medical' ? concat(medicalContractElementSet.requiredFields, medicalContractElementSet.additionalFields) : concat(retailContractElementSet.requiredFields, retailContractElementSet.additionalFields);
      const allContractElements = [...retailContractElementSet.requiredFields, ...retailContractElementSet.additionalFields];

      discountProgramFields = allContractElements
        .filter(item => item.name !== 'discountProgram' && item.name !== 'fillDate' && item.name !== 'ndc11')
        .map(f => { return { ...f } }); //same model properties
    }

    return (
      <TransitionablePortal open={true} transition={{ animation: 'slide up', duration: 200 }} closeOnDocumentClick={false} closeOnEscape={false}>
        <div className='request-form' style={{ width: `calc(100% - ${leftNavWidth}px)`, left: leftNavWidth }}>
          <div className='request-form-content flex-column flex-grow scroll-hidden'>
            <div className='request-form-header flex-column justify-center'>
              <div className='flex-row justify-space-between'>
                <div className='beta'>{'Correct & Resubmit'}</div>
                <CloseButton onClick={() => this.closeWindow()} />
              </div>
            </div>
            {correct.isLoading && <Dimmer active inverted><Loader inverted content={'Submitting'} /></Dimmer>}

            {!this.props.correct.isSuccess &&
              <CorrectDataEntry
                contractFields={discountProgramFields}
                transaction={transaction}
                onSubmit={this.onSubmit}
                submitErrors={correct.errors}
              >
                <UneditableFields fields={requiredFieldValues} />
              </CorrectDataEntry >
            }

            {this.props.correct.isSuccess &&
              <React.Fragment>
                <div className='request-form-body flex-row flex-grow scroll-hidden'>
                  <UneditableFields fields={requiredFieldValues} />

                  <div className='request-form-details request-form-success flex-column'>
                    <div className='flex-column'>
                      <div className='checkicon-outline'><Icon name='check circle outline' size='big' className='checkicon' /></div>
                    </div>
                    <div className='beta'>Requests successfully corrected and resubmitted!</div>
                    <div className='flex-column'>
                      <div style={{ width: '75%', marginLeft: '135px', marginTop: '50px' }}>
                        <Table>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell className='eta header-cell'>
                              </Table.HeaderCell>
                              <Table.HeaderCell className='eta header-cell'>
                                Transaction ID
                              </Table.HeaderCell>
                              <Table.HeaderCell className="eta header-cell">
                                Units
                              </Table.HeaderCell>
                              <Table.HeaderCell className="eta header-cell">
                                Discount/Unit
                              </Table.HeaderCell>
                              <Table.HeaderCell className="eta header-cell">
                                Amount
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {
                              correct.result.map((responseRow, i) => {
                                return (
                                  <Table.Row key={i}>
                                    <Table.Cell>{responseRow.additionalInformation.ResultType + ' Transaction'}</Table.Cell>
                                    <Table.Cell>{this.renderNavigationButton(responseRow.additionalInformation.ResultType, responseRow.requestId, responseRow.status)}</Table.Cell>
                                    <Table.Cell>{formatUnits(responseRow.additionalInformation.RequestedUnits)}</Table.Cell>
                                    <Table.Cell>{formatDiscountPerUnit(responseRow.additionalInformation.RequestedDiscountPerUnit)}</Table.Cell>
                                    <Table.Cell>{formatDiscountAmount(responseRow.additionalInformation.RequestedAmount)}</Table.Cell>
                                  </Table.Row>
                                )
                              })
                            }
                          </Table.Body>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='request-form-footer flex-column justify-center'>
                  <div className='flex-row justify-flex-end'>
                    <div className='actions'>
                      <Button secondary onClick={() => this.closeWindow()}>Close</Button>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          </div>
        </div>
      </TransitionablePortal>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  correct: state.RequestCenterState.requestForm.correct,
  contractElements: state.TransactionsCommon.contractElements,
  isLeftNavExpanded: state.AppCommonState.navigationState.isLeftNavExpanded,
  activeHealthSystemId: getActiveHealthSystemId(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  correctDiscountRequest: (id: number, fields: DiscountRequestFieldSubmitModel) => dispatch(correctDiscountRequest(id, fields)),
  resetDiscountRequestForm: () => dispatch(resetDiscountRequestForm())
});


export default connect(mapStateToProps, mapDispatchToProps)(CorrectRequestForm);
