import React from 'react';

import '../../themes/kalderos/Common/LoadingBar.scss';

const LoadingBar = () => (
  <div className="loading-bar">
    <div className="loading"/>
  </div>
)

export default LoadingBar;