import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import cx from 'classnames';
import { Modal, Button, Form, Dimmer, Loader } from 'semantic-ui-react';
import { RootState } from '../../../store/RootState';
import { addUser, closeAddUserModal } from '../../../store/teammanagement/actionCreators';
import { RECIPIENT_USER_ROLE_OPTIONS } from '../../../store/teammanagement/constants';

export interface AddUserModalProps {
  isOpen: boolean,
  isLoading: boolean,
  errorText: string,
  addUser: (email: string, role: string) => void;
  closeAddUserModal: () => void;
}

export interface AddUserFormInputModel {
  email: string,
  role: string
}

const initialValues: AddUserFormInputModel = {
  email: '',
  role: '',
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
  role: yup
    .string()
    .required(),
});

export const AddUserModal: React.FunctionComponent<AddUserModalProps> = ({ isOpen, isLoading, errorText, addUser, closeAddUserModal }: AddUserModalProps) => {

  const [apiErrorText, setApiErrorText] = useState(errorText);

  useEffect(() => {
    setApiErrorText(errorText)
  }, [errorText]);

  function handleCancelClick () {
    closeAddUserModal();
  }

  function handleOnSubmit (input: AddUserFormInputModel) {
    addUser(input.email, input.role);
  }

  return (
    <Modal className="team-management-modal" open={isOpen}>
      <Formik
        initialValues={initialValues}
        validateOnMount={true}
        validationSchema={schema}
        onSubmit={(values) => {
          handleOnSubmit(values);
        }}>
        {props => {

          const formErrorText = (props.errors.email && props.touched.email && props.values.email.length > 0) ? 'That is not a valid email address' : ''

          return (
            <Form className="flex-column flex-grow" onSubmit={props.handleSubmit}>
              <div className="team-management-modal-header">Add new user</div>
              {isLoading && <Dimmer className="dimmer" active inverted><Loader inverted /></Dimmer>}
              <div className="team-management-modal-body">
                <Form.Group>
                  <Form.Input width={9} fluid
                    name="email"
                    label="Email Address"
                    defaultValue={props.values.email}
                    onChange={(e) => { setApiErrorText(''); props.handleChange(e) }}
                    onBlur={props.handleBlur}
                    placeholder="Enter their email address..."
                    className={cx({ 'team-management-modal-input-error': !!formErrorText || !!apiErrorText })}
                  />
                  <Form.Select width={7} fluid
                    name='role'
                    label="Role"
                    options={RECIPIENT_USER_ROLE_OPTIONS}
                    onChange={(e, d) => { props.setFieldValue('role', d.value as string) }}
                    onBlur={props.handleBlur}
                    value={props.values.role} placeholder="Choose a role..."
                  />
                </Form.Group>
                {(formErrorText || apiErrorText) && <div className="team-management-modal-error-text">{formErrorText || apiErrorText}</div>}
              </div>
              <div className="team-management-modal-footer">
                <Button className="app-button button-white" type="button" onClick={handleCancelClick}>Cancel</Button>
                <Button className="app-button button-dark" type="submit" disabled={!props.isValid || !props.dirty}>Submit</Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

const mapStateToProps = (state: RootState) => ({
  isOpen: state.TeamManagementState.addUserModal.isOpen,
  isLoading: state.TeamManagementState.addUserModal.isLoading,
  errorText: state.TeamManagementState.addUserModal.errorText,
});

const mapDispatchToProps = (dispatch: any) => ({
  addUser: (email: string, role: string) => dispatch(addUser(email, role)),
  closeAddUserModal: () => dispatch(closeAddUserModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal)