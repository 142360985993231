import React from 'react';
import { Accordion } from 'semantic-ui-react';

import '../../../themes/kalderos/Common/Card/Card.scss';

export interface ExpandableCardProps {
  content: React.ReactNode;
  isCardExpanded: boolean;
  onClick: () => void;
  title: React.ReactNode;
}

const ExpandableCard = (props: ExpandableCardProps) => {
  const { content, isCardExpanded, onClick, title } = props;

  return (
    <Accordion className="card-container">
      <Accordion.Title className="card-title" onClick={onClick}>
        {title}
      </Accordion.Title>
      <Accordion.Content active={isCardExpanded}>
        {content}
      </Accordion.Content>
    </Accordion>
  );
};

export default ExpandableCard;
