import React from 'react';
import CloseButton from '../../CloseButton';


export interface ModalHeaderContentProps {
  header: string;
  hasCloseButton: boolean;
  handleModalClose: () => void;
}

export const ModalHeaderContent: React.FunctionComponent<ModalHeaderContentProps> = (props: ModalHeaderContentProps) => {

  const { header, hasCloseButton, handleModalClose } = props;
  return (
    <div className='modal-header'>
      <div className='standard-modal-header'>{header}</div>
      {hasCloseButton && <CloseButton size={24} onClick={handleModalClose} />}
    </div>
  )
}


export default ModalHeaderContent
