import React from 'react';
import { connect } from 'react-redux';
import { getCoveredEntityHealthSystemInfo } from '../../store/payments/recipient/actionCreators';
import { UserInfoModel } from '../../store/applicationTypes';
import { RootState } from '../../store/RootState';
import { Dimmer, Loader } from 'semantic-ui-react';
import EmptyTransactions from '../common/transactions/EmptyTransactions';
import { COVERED_ENTITY_BILLING_INFO_ERROR_HEADER, COVERED_ENTITY_BILLING_INFO_ERROR_MESSAGE } from '../../store/payments/recipient/constants';


export const PaymentOfficial = ({ paymentOfficial, paymentOfficialTitle }: { paymentOfficial?: UserInfoModel, paymentOfficialTitle: string }) => {
  return (
    <div className='health-system-info-content-wrapper'>
      <div className="content-section">
        <label className="content-heading">{paymentOfficialTitle}</label>
        {paymentOfficial ?
          <div className="content-body">
            <div className='content-col'>
              <div className='content-row'>
                <div className="sub-heading">Name</div>
                <label data-private className='content'> {paymentOfficial.firstName} {paymentOfficial.lastName}</label>
              </div>
              <div className="content-row">
                <div className="sub-heading">Title</div>
                <label data-private className='content'>{paymentOfficial.jobTitle ? paymentOfficial.jobTitle : '--'}</label>
              </div>
              <div className='content-row'>
                <div className="sub-heading">Phone</div>
                <label data-private className='content' data-testid='phone'>{paymentOfficial.phone ? paymentOfficial.phone : '--'}</label>
                <div className='content-row'>
                  <label data-private className='content' data-testid='phone-extension'>Ext. {paymentOfficial.phoneExtension ? paymentOfficial.phoneExtension : '--'}</label>
                </div>
              </div>
            </div>
            <div className='content-col'>
              <div className='content-row'>
                <div className="sub-heading">Email</div>
                <label data-private className='content'>{paymentOfficial.email}</label>
              </div>
              <div className='content-row'>
                <div className="sub-heading" >Office Address</div>
                {paymentOfficial.address ? <div>
                  <div><label data-private className='content' data-testid='address1'>{paymentOfficial.address?.address1}</label></div>
                  <div><label data-private className='content' data-testid='address2'>{paymentOfficial.address?.address2}</label></div>
                  {paymentOfficial.address &&
                    <div className='content-row'><label data-private className='content' data-testid='city-state-zip'>{paymentOfficial.address?.city}, {paymentOfficial.address?.state} {paymentOfficial.address?.zip}</label></div>
                  }</div>
                  : '--'}
              </div>
            </div>
          </div>
          :
          <div className='payment-official-error-message'>
            <label className='content' data-testid='error-message'> - - </label>
          </div>
        }
      </div>
    </div>
  );
}
interface HealthSystemInfoProps {
  controller?: UserInfoModel;
  accountAdmin?: UserInfoModel;
  isBillingInfoLoading: boolean;
  isBillingInfoError: boolean;
  getCoveredEntityHealthSystemInfo: () => void;
  healthSystemName: string;
}

export class HealthSystemInfo extends React.Component<HealthSystemInfoProps>{
  componentDidMount () {
    this.props.getCoveredEntityHealthSystemInfo();
  }

  render () {
    const { controller, accountAdmin, isBillingInfoLoading, isBillingInfoError, healthSystemName } = this.props;
    if (isBillingInfoLoading) {
      return <Dimmer active inverted><Loader inverted content='Loading' /></Dimmer>;
    }

    if (isBillingInfoError) {
      return <EmptyTransactions emptyTransactionsHeader={COVERED_ENTITY_BILLING_INFO_ERROR_HEADER} emptyTransactionsMessage={COVERED_ENTITY_BILLING_INFO_ERROR_MESSAGE} />;
    }
    return (
      <div className='receipt-health-system-info'>
        <div className="health-system-name">
          <div className="label">Health System</div>
          <div className="value" data-private>{healthSystemName}</div>
        </div>
        <PaymentOfficial paymentOfficial={accountAdmin} paymentOfficialTitle={'Account Admin Information'} />
        <PaymentOfficial paymentOfficial={controller} paymentOfficialTitle={'Controller Information'} />
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  healthSystemName: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.healthSystemName,
  controller: state.PaymentsState.recipient.coveredEntityBillingInfo.controller,
  accountAdmin: state.PaymentsState.recipient.coveredEntityBillingInfo.accountAdmin,
  isBillingInfoLoading: state.PaymentsState.recipient.coveredEntityBillingInfo.isBillingInfoLoading,
  isBillingInfoError: state.PaymentsState.recipient.coveredEntityBillingInfo.isBillingInfoError
});

const mapDispatchToProps = (dispatch: any) => ({
  getCoveredEntityHealthSystemInfo: () => dispatch(getCoveredEntityHealthSystemInfo()),
});
export default connect(mapStateToProps, mapDispatchToProps)(HealthSystemInfo);
