import React from 'react';

import '../../../../themes/kalderos/Common/Drawer/Components/Footer.scss';

export interface FooterProps {
  children: React.ReactNode
}


export const Footer: React.FunctionComponent<FooterProps> = (props: FooterProps) => {
  return (
    <div className='drawer-footer'>
      {props.children}
    </div>
  )
}

export default Footer;
