import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { AppSwitcher } from '../../themes/kalderos/Common/Icons';
import { HealthSystemInfo } from '../../store/onboarding/types';
import { RootState } from '../../store/RootState';
import { openChangeHealthSystemsModal, openSearchHealthSystemsModal } from '../../store/teammanagement/actionCreators';
import { selectRoles } from '../../store/login/loginSelector';
import { isAdminSupport } from '../../utils/roleUtil';

export interface HealthSystemSwitcherProps {
  activeHealthSystemInfo: HealthSystemInfo;
  expanded: boolean;
  showExpandedContent: boolean;
  roles: string[];
  openChangeHealthSystemsModal: (isRequired: boolean) => void;
  openSearchHealthSystemsModal: (isRequired: boolean) => void;
}

const HealthSystemSwitcher: React.FunctionComponent<HealthSystemSwitcherProps> = (props: HealthSystemSwitcherProps) => {

  const handleClick = () => {
    if (isAdminSupport(props.roles)) {
      props.openSearchHealthSystemsModal(false);
    } else {
      props.openChangeHealthSystemsModal(false);
    }
  }

  return (
    <Button className={cx('switch-healthsys-button cursor-normal', { 'justify-center': !props.expanded })} onClick={handleClick}>
      {props.expanded &&
        <div className={cx('team-text transition', { 'visible': props.showExpandedContent })}>
          <div className='switch-team-container'>
            <div className="switch-team-label overflow-ellipsis">Health System</div>
            <div className='overflow-ellipsis' title={props.activeHealthSystemInfo.healthSystemName}>{props.activeHealthSystemInfo.healthSystemName}</div>
          </div>
        </div>
      }
      <span><AppSwitcher /></span>
    </Button>
  )
}

const mapStateToProps = (state: RootState) => ({
  activeHealthSystemInfo: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo,
  roles: selectRoles(state) || []
});

const mapDispatchToProps = (dispatch: any) => ({
  openChangeHealthSystemsModal: (isRequired: boolean) => dispatch(openChangeHealthSystemsModal(isRequired)),
  openSearchHealthSystemsModal: (isRequired: boolean) => dispatch(openSearchHealthSystemsModal(isRequired)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HealthSystemSwitcher)
