import { RootState } from '../../RootState';
import { getPagedDataSelector } from '../../../utils/datatableHelper';
import { UI_PAGE_SIZE, API_PAGE_SIZE } from '../../applicationConstants';

export const getPaymentHistory = (state: RootState) => state.PaymentsState.recipient.paymentHistory.paymentTransactions
export const getCurrentPage = (state: RootState) => state.PaymentsState.recipient.paymentHistory.currentPage

export const getCreditsPerManufacturer = (state: RootState) => state.PaymentsState.recipient.creditsPerManufacturerModel.creditsPerManufacturer
export const getCurrentCreditsPage = (state: RootState) => state.PaymentsState.recipient.creditsPerManufacturerModel.currentPage

export const getPagedPaymentHistory = getPagedDataSelector(getPaymentHistory, getCurrentPage, UI_PAGE_SIZE, API_PAGE_SIZE);
export const getPagedCreditsPerManufacturer = getPagedDataSelector(getCreditsPerManufacturer, getCurrentCreditsPage, UI_PAGE_SIZE, API_PAGE_SIZE);