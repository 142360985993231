import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import CloseButton from '../../common/CloseButton';
import { Formik } from 'formik';
import { SingleDatePicker } from 'react-dates';
import { isBeforeDay, isAfterDay } from '../../../utils/dateHelper'
import moment from 'moment';
import cx from 'classnames'
import { Moment } from 'moment-timezone';
import * as Yup from 'yup';
import classnames from 'classnames';
interface EditDateOverrideModalProps {
  isOpen: boolean;
  closeModal: () => void;
  affirmativeClick: () => void;
  startDate: string;
  startDateOverride?: string;
  termDate?: string;
  termDateOverride?: string;
}

const handleNextClick = async () => { };

export const EditDateOverrideModal: React.FunctionComponent<EditDateOverrideModalProps> = ({ isOpen, closeModal, startDate, startDateOverride, termDate, termDateOverride, affirmativeClick }) => {

  const typeDate = (date: string | undefined) => {
    return date ? moment(date) : null;
  };

  const initialValues = {
    startDate: startDate,
    startDateOverride: startDateOverride,
    termDate: termDate,
    termDateOverride: termDateOverride
  };

  const [isStartDateFocused, setIsStartDateFocused] = useState(false);
  const [isStartDateOverrideFocused, setIsStartDateOverrideFocused] = useState(false);
  const [isTermDateFocused, setIsTermDateFocused] = useState(false);
  const [isTermDateOverrideFocused, setIsTermDateOverrideFocused] = useState(false);
  const [hasStartOverrideDate, setHasStartOverrideDate] = useState(!!startDateOverride);
  const [hasTermOverrideDate, setHasTermOverrideDate] = useState(!!termDateOverride);

  const dateSchema = Yup.object({
    startDate: Yup.date().label('Start Date').min(startDate ? startDate : new Date(), 'You can not set a Start Date Override after the HRSA Term Date.'),
    termDate: Yup.date().label('Term Date').min(termDate ? termDate : new Date(), 'You can not set a Term Date Override after the HRSA Term Date.')
  })
  const [isDisabled, setIsDisabled] = useState(true);

  return (
    <Formik initialValues={initialValues}
      onSubmit={handleNextClick}
      validationSchema={dateSchema}
      validateOnMount={true}
      enableReinitialize={true}
    >
      {({ setFieldValue, setFieldTouched, values, errors, touched }) => {

        const initalVisibleStartDateOverride = () => {
          return values.startDateOverride ? moment(values.startDateOverride) : moment(values.startDate);
        }

        const initialVisibleTermDateOverride = () => {
          return values.termDateOverride ? moment(values.termDateOverride) : moment(values.termDate);
        }


        const handleDate = (date: Moment | null, dateField: string) => {
          const dateChange = date ? new Date(date.toDate()) : null;
          setFieldValue(dateField, dateChange);
          setFieldTouched(dateField);
          setIsDisabled(false)
          if (dateField === 'startDateOverride') {
            setHasStartOverrideDate(true);
          }
          if (dateField === 'termDateOverride') {
            setHasTermOverrideDate(true)
          }
        }

        const isOutsideStartOverrideRange = (date: Moment) => {
          const startDate = moment(values.startDate);
          if (values.termDate) {
            const termDate = moment(values.termDate);
            if (values.termDateOverride) {
              const termDateOverride = moment(values.termDateOverride);
              return isAfterDay(date, startDate) || !isBeforeDay(date, moment(termDateOverride).subtract(1, 'd'))
            }
            return isAfterDay(date, startDate) || !isBeforeDay(date, moment(termDate).subtract(1, 'd'));
          } return isAfterDay(date, startDate);

        }

        const isOutsideTermDateOverrideRange = (date: Moment) => {
          const startDate = moment(values.startDate);
          const termDate = moment(values.termDate);
          if (values.startDateOverride) {
            const startDateOverride = moment(values.startDateOverride);
            return isAfterDay(date, startDateOverride) || !isBeforeDay(date, moment(termDate).subtract(1, 'd'));
          }
          return isAfterDay(date, startDate) || !isBeforeDay(date, moment(termDate).subtract(1, 'd'))
        }

        return (
          <Modal
            className="app-modal edit-date-override"
            open={isOpen}
            closeOnDimmerClick={false}
          >
            <div className={classnames('flex-column edit-date-override-modal')}>
              <div className="flex-row edit-date-override-header-container justify-space-between">
                <div className="beta edit-date-override-header">
                  Change Date Override
                </div>
                <CloseButton
                  onClick={closeModal} />
              </div>
              <div data-private className="delta edit-date-override-message">{`Are you sure you want to add or edit the start or the end date override? If this change is made, this will apply to all claims in the future.`}</div>
              <div className="date-container">
                <div>
                  <div className={cx('zeta', { 'start-date-override-label-disabled': !values.startDate })}>Start Date</div>
                  <SingleDatePicker
                    placeholder=''
                    isOutsideRange={() => false}
                    numberOfMonths={1}
                    noBorder
                    required
                    enableOutsideDays={true}
                    date={typeDate(values.startDate)}
                    onDateChange={(date) => handleDate(date, 'startDate')}
                    focused={isStartDateFocused}
                    onFocusChange={({ focused }) => setIsStartDateFocused(focused)}
                    id="startDate"
                    small
                    disabled

                  />
                </div>
                <div className='start-date-override-container'>
                  <div className={classnames('zeta', { 'has-error': errors, touched })}>Start Date Override</div>
                  <SingleDatePicker
                    placeholder=''
                    numberOfMonths={1}
                    required
                    enableOutsideDays={true}
                    date={typeDate(values.startDateOverride)}
                    onDateChange={(date) => handleDate(date, 'startDateOverride')}
                    focused={isStartDateOverrideFocused}
                    onFocusChange={({ focused }) => setIsStartDateOverrideFocused(focused)}
                    id="startDateOverride" // using the same id for pendo tagging, if any changes please update in pendo also
                    small
                    readOnly
                    initialVisibleMonth={initalVisibleStartDateOverride}
                    disabled={!values.startDate}
                    isOutsideRange={isOutsideStartOverrideRange}
                  />
                </div>
              </div>
              {hasStartOverrideDate && (
                <div className='remove-start-override-container'>
                  <button id='removeOverrideLinkStartDate_Pendo' type='button' className="app-button ghost" onClick={() => {
                    setFieldValue('startDateOverride', null);
                    setHasStartOverrideDate(false);
                    setIsDisabled(false);
                  }}>Remove Override</button>
                </div>
              )}
              <div className="date-container-two">
                <div>
                  <div className="zeta">Term Date</div>
                  <SingleDatePicker
                    placeholder=''
                    isOutsideRange={() => false}
                    numberOfMonths={1}
                    noBorder
                    required
                    date={typeDate(values.termDate)}
                    onDateChange={(date) => handleDate(date, 'termDate')}
                    focused={isTermDateFocused}
                    onFocusChange={({ focused }) => setIsTermDateFocused(focused)}
                    id="termDate"
                    small
                    disabled
                  />
                </div>
                <div className='term-date-override-container'>
                  <div className={cx('zeta', { 'term-date-override-label-disabled': !values.termDate })}>Term Date Override</div>
                  <div className={cx({ 'term-date-override-date-picker-disabled': !values.termDate })}>
                    <SingleDatePicker
                      placeholder=''
                      isOutsideRange={isOutsideTermDateOverrideRange}
                      numberOfMonths={1}
                      required
                      enableOutsideDays={true}
                      date={typeDate(values.termDateOverride)}
                      onDateChange={(date) => handleDate(date, 'termDateOverride')}
                      focused={isTermDateOverrideFocused}
                      onFocusChange={({ focused }) => setIsTermDateOverrideFocused(focused)}
                      id="termDateOverride" // using the same id for pendo tagging, if any changes please update in pendo also
                      small
                      readOnly
                      initialVisibleMonth={initialVisibleTermDateOverride}
                      openDirection='up'
                      disabled={!values.termDate}
                    />
                  </div>
                </div>
              </div>
              {hasTermOverrideDate && (<div className='remove-term-override-container'>
                <button id='removeOverrideLinkTermDate_Pendo' type='button' className="app-button ghost" onClick={() => {
                  setFieldValue('termDateOverride', null);
                  setHasTermOverrideDate(false);
                  setIsDisabled(false);
                }}>Remove Override</button>
              </div>)}

              <div className="flex-row justify-flex-end">
                <button
                  type="button"
                  className="app-button button-white"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  disabled={isDisabled}
                  id='saveOverrideBtn_Pendo'
                  type="button"
                  className="app-button button-dark" onClick={affirmativeClick}
                >
                  Yes, change
                </button>
              </div>
            </div>
          </Modal>
        )
      }}
    </Formik >
  );
};

export default EditDateOverrideModal;