import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store/RootState';
import { Toast } from '../../../store/toast/types';
import { TOAST_TYPE } from '../../../store/toast/constants';
import { hideToast } from '../../../store/toast/actionCreators';
import { Message, Transition } from 'semantic-ui-react';
import classnames from 'classnames';
interface ToastCompProps {
  toast: Toast;
  className?: string;
  hideToast: (e: any) => void;
}

export interface ToastsProps {
  toasts: Toast[],
  hideToast: (toast: Toast) => any;
}

const ToastComp = ({ toast, hideToast, className }: ToastCompProps) => (
  <div className={classnames('toast', toast.type || TOAST_TYPE.INFO, toast.className, className)}>
    <Message
      onDismiss={hideToast}
      header=''
      content={toast.text}
    />
  </div >
);

const Toasts = ({ toasts, hideToast }: ToastsProps) => {
  const toastsCount = toasts.length;
  if (!toastsCount) return null;
  return (
    <div className="toast-container">
      <Transition.Group duration={200} animation='slide left'>
        {toasts.map((toast: Toast, index) =>
          <ToastComp key={index} toast={toast} hideToast={() => hideToast(toast)} />
        )}
      </Transition.Group>
    </div >
  );
}


const mapStateToProps = (state: RootState) => ({
  toasts: state.ToastState.toasts
});

const mapDispatchToProps = (dispatch: any) => ({
  hideToast: (toast: Toast) => dispatch(hideToast(toast))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Toasts);
