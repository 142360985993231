import React from 'react';

export interface ModalBodyContentProps {
  bodyText: string;
  bodyContent?: React.ReactNode;
}

export const ModalBodyContent: React.FunctionComponent<ModalBodyContentProps> = (props: ModalBodyContentProps) => {

  const { bodyText, bodyContent } = props;
  return (
    <div className='modal-body'>
      <div className='body-text'>{bodyText}</div>
      {bodyContent}
    </div>
  )
}


export default ModalBodyContent
