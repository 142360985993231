import RequestConfig from '../../app.config';
import { commonResponseHandler, RESPONSE_TYPE } from '../../utils/responseUtils';
import fetchWrapper from '../FetchWrapper';
import { InviteUserStepInputModel, UserInfoInputModel } from '../../store/onboarding/types';
import { createUrlParametersString, trimInputFields } from '../../utils/requestUtil';

export const businessStatus = async (healthSystemId?: number) => {
  const urlParams = healthSystemId ? createUrlParametersString({ healthSystemId: healthSystemId }) : '';
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/business/status?${urlParams}`, true);
  return commonResponseHandler(response);
};

export const fetchTermsAndConditions = async (termsUrl: string) => {
  const response = await fetch(termsUrl);
  return commonResponseHandler(response, RESPONSE_TYPE.TEXT);
}

export const completeTermsAndConditionsStepRequest = async (healthSystemId: number, input: string[]) => {
  const urlParams = createUrlParametersString({ healthSystemId: healthSystemId })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/onboarding/authorizingofficial/termsandconditions/submit?${urlParams}`, true,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(input)
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.NONE);
}

export const getHealthSystemAccountAdminRequest = async (healthSystemId: number) => {
  const urlParams = createUrlParametersString({ healthSystemId: healthSystemId })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/onboarding/authorizingofficial/inviteaccountadmin/info?${urlParams}`, true,
    {
      method: 'GET'
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.JSON);
}

export const completeInviteAccountAdminStepRequest = async (input: InviteUserStepInputModel, healthSystemId: number) => {
  const urlParams = createUrlParametersString({ healthSystemId: healthSystemId })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/onboarding/authorizingofficial/inviteaccountadmin/submit?${urlParams}`, true,
    {
      method: 'POST',
      body: trimInputFields(JSON.stringify(input)),
      headers: {
        'Content-Type': 'application/json',
      }
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.NONE);
}

export const completeAccountAdminInfoStepRequest = async (input: UserInfoInputModel, healthSystemId: number) => {
  const urlParams = createUrlParametersString({ healthSystemId: healthSystemId })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/onboarding/accountadmin/info/submit?${urlParams}`, true,
    {
      method: 'POST',
      body: trimInputFields(JSON.stringify({
        AccountAdminInfo: input
      })),
      headers: {
        'Content-Type': 'application/json',
      }
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.NONE);
}

export const fetchCoveredEntityInfo = async (healthSystemId: number) => {
  const urlParams = createUrlParametersString({ SortField: 'idCode', SortDesc: false, healthSystemId: healthSystemId })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/business/authorizedEntities?${urlParams}`, true);
  return commonResponseHandler(response);
};

export const fetchAccountAdminSummary = async (healthSystemId: number) => {
  const urlParams = createUrlParametersString({ healthSystemId: healthSystemId })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/onboarding/accountadmin/summary?${urlParams}`, true);
  return commonResponseHandler(response);
};

export const completeAccountAdminConfirmInfoStepRequest = async (healthSystemId: number) => {
  const urlParams = createUrlParametersString({ healthSystemId: healthSystemId })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/onboarding/accountadmin/summary/submit?${urlParams}`, true,
    {
      method: 'POST',
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.NONE);
}

export const completeInviteControllerStepRequest = async (input: InviteUserStepInputModel, healthSystemId: number) => {
  const urlParams = createUrlParametersString({ healthSystemId: healthSystemId })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/onboarding/accountadmin/invitecontroller/submit?${urlParams}`, true,
    {
      method: 'POST',
      body: trimInputFields(JSON.stringify(input)),
      headers: {
        'Content-Type': 'application/json',
      }
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.NONE);
}

export const completeControllerInfoStepRequest = async (input: UserInfoInputModel, healthSystemId: number) => {
  const urlParams = createUrlParametersString({ healthSystemId: healthSystemId })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/onboarding/controller/info/submit?${urlParams}`, true,
    {
      method: 'POST',
      body: trimInputFields(JSON.stringify({
        ControllerInfo: input
      })),
      headers: {
        'Content-Type': 'application/json',
      }
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.NONE);
}

export const completeControllerConfirmInfoStepRequest = async (healthSystemId: number) => {
  const urlParams = createUrlParametersString({ healthSystemId: healthSystemId })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/onboarding/controller/confirminfo/submit?${urlParams}`, true,
    {
      method: 'POST',
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.NONE);
}

export const completeControllerBankInfoStepRequest = async (healthSystemId: number) => {
  const urlParams = createUrlParametersString({ healthSystemId: healthSystemId })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/onboarding/controller/bankinfo/submit?${urlParams}`, true,
    {
      method: 'POST',
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.NONE);
}

export const authorizingOfficialSignUpApi = async (userName: string) => {
  const response = await fetch(`${await RequestConfig()}/request/v1/onboarding/authorizingofficialsignup`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ 'userName': userName })
  });
  if (response.status === 404) {
    // user is not an AO
    throw new Error('The email address you entered does not match an Authorizing Official email as listed in HRSA. Please confirm the email address and try again.');
  }
  else if (response.status === 424) {
    // CE is not fully setup in backend
    throw new Error('Additional information required.')
  }
  else if ((response.status !== 204) && (response.status !== 200)) {
    // unexpected error
    throw new Error('Unable to process your request at this time.')
  }

  return commonResponseHandler(response, RESPONSE_TYPE.NONE);
}

export const getCandidateCeListRequest = async () => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/onboarding/getcandidatecelist`, true,
    {
      method: 'GET',
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.JSON);
}

export const createHealthSystemAndBusinesses = async (input: any) => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/onboarding/createhealthsystemwithbusinesses`, true,
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(input)
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.JSON);
}

export const checkUniqueHealthSystemRequest = async (input: string) => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/onboarding/isuniquehealthsystemname?name=${input}`, true,
    {
      method: 'GET'
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.TEXT);
}

export const fetchControllerStatusRequest = async (healthSystemId: number) => {
  const urlParams = createUrlParametersString({ healthSystemId: healthSystemId })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/onboarding/controller/status?${urlParams}`, true,
    {
      method: 'GET',
    }
  );
  return commonResponseHandler(response);
}
