import React, { ChangeEvent } from 'react';
import { Form } from 'semantic-ui-react';
import cx from 'classnames';
import Cleave from 'cleave.js/react';

interface TitleAndPhoneFieldsProps {
  values: any,
  handleChange?: {(e: ChangeEvent<any>): void; <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any> ? void : (e: string | ChangeEvent<any>) => void}; 
  errors: any,
  touched: any,
  handleBlur?: {(e: ChangeEvent<any>): void; <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any> ? void : (e: string | ChangeEvent<any>) => void}; 
}

const TitleAndPhoneFields: React.FunctionComponent<TitleAndPhoneFieldsProps> = ({ values, handleChange, handleBlur, errors, touched }) => {
  return (
    <React.Fragment>
      <Form.Input data-private width={11} name="jobTitle" label="Job Title" defaultValue={values.jobTitle} onChange={handleChange} onBlur={handleBlur} className={cx({'input-error': errors?.jobTitle && touched?.jobTitle})} />
      {errors?.jobTitle && touched?.jobTitle && <div className="error-text">Job title invalid</div>}
      <Form.Group>
        <div className={cx('five wide field', {'input-error': errors?.phone && touched?.phone})}>
          <label htmlFor="phone">Primary Phone</label>
          <div className="ui input">
            <Cleave data-private
              name="phone" 
              value={values.phone} 
              onChange={handleChange} 
              onBlur={handleBlur} 
              options={{ delimiters: ['(', ')', ' ', '-'], blocks: [0, 3, 0, 3, 4]}} 
            />
          </div>
        </div>
        <Form.Input data-private width={3} name="phoneExtension" label="Ext (optional)" defaultValue={values.phoneExtension} onChange={handleChange} onBlur={handleBlur} className={cx({'input-error': errors?.phoneExtension && touched?.phoneExtension})} />
      </Form.Group>
      {errors?.phone && touched?.phone && <div className="error-text">Primary phone invalid</div>}
      {errors?.phoneExtension && touched?.phoneExtension && <div className="error-text">Ext invalid</div>}
    </React.Fragment>
  )
}

export default TitleAndPhoneFields;