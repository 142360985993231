import React from 'react';

import '../../../themes/kalderos/Common/PageSummary/PageSummary.scss';

export interface PageSummaryProps {
  summaryHeader: React.ReactNode;
  summaryTotals: React.ReactNode;
  progressCard?: React.ReactNode;
}

const PageSummary: React.FunctionComponent<PageSummaryProps> = (props: PageSummaryProps) => {
  return (
    <div className="page-summary">
      {props.summaryHeader}
      <div className="page-summary-body">
        <div className="page-summary-totals">
          {props.summaryTotals}
        </div>
        {props.progressCard &&
          <div className="page-summary-bar">
            {props.progressCard}
          </div>
        }
      </div>
    </div>
  )
}

export default PageSummary;