import React from 'react'
import { Popup } from 'semantic-ui-react';
import { DISCOUNT_PER_UNIT_REVIEW_AND_SUBMIT_TOOL_TIP_MESSAGE } from '../../../store/appcommon/constants';
import { getFormattedFieldValue } from './RequestFormHelper';

const FieldInfo: React.FunctionComponent<{ name: string, label: string, value: any }> = ({ name, label, value }) => {

  return (
    <div className='field-info-item'>
      <div className='epsilon field-info-item-value'>{getFormattedFieldValue(name, value)}</div>
      <div className='eta-regular field-info-item-text'>
        {name == 'discountPerUnit' && !value
          ? <Popup inverted trigger={<span>{label}</span>}
            position='left center'
            content={DISCOUNT_PER_UNIT_REVIEW_AND_SUBMIT_TOOL_TIP_MESSAGE}
            size='tiny' />

          : label
        }
      </div>
    </div>
  );
}

export default FieldInfo;