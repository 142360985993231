import {
  GET_REVERSALS,
  GET_REVERSALS_FAILURE,
  GET_REVERSALS_SUCCESS,
  RESET_REVERSALS,
  RESET_REVERSALS_TRANSACTIONS,
  ReversalActionTypes,
  REVERSALS_TAB,
  UPDATE_REVERSALS_FILTER,
  UPDATE_REVERSALS_PAGE,
  UPDATE_REVERSALS_SORT,
} from './constants';
import { ReversalsState, ReversalTransactionsModel } from './types';
import { getStartOfTheDayNonUTC, getEndOfTheDayNonUTC, get30DaysAgo } from '../../utils/dateHelper';
import { TransactionsFilterModel } from '../transactionscommon/types';

const thirtyDaysAgo = get30DaysAgo(new Date());

const filterInitialState: TransactionsFilterModel = {
  startDate: getStartOfTheDayNonUTC(thirtyDaysAgo),
  endDate: getEndOfTheDayNonUTC(new Date()),
  claimTypeText: null,
  ndc11: '',
  fillStartDate: '',
  fillEndDate: '',
  pharmacyNpi: '',
  rxNumber: '',
  adjustmentReasons: null,
  idCode: '',
  failureReasons: null
};

const reversalsInitialState: ReversalTransactionsModel = {
  transactions: [],
  currentPage: 1,
  currentApiPage: 1,
  totalCount: 0,
  sortBy: 'submittedDate',
  isSortAsc: false,
  errorText: '',
  isLoading: false
};

const initialState: ReversalsState = {
  reversals: reversalsInitialState,
  filters: {
    [REVERSALS_TAB.PENDING]: filterInitialState,
    [REVERSALS_TAB.PROCESSED]: filterInitialState
  },
};

export function reversalsReducer (state = initialState, action: ReversalActionTypes): ReversalsState {
  switch (action.type) {
    case GET_REVERSALS:
      return {
        ...state,
        reversals: {
          ...state.reversals,
          isLoading: true,
          errorText: '',
        }
      }
    case GET_REVERSALS_SUCCESS:
      return {
        ...state,
        reversals: {
          ...state.reversals,
          transactions: action.payload.discountRequests,
          currentApiPage: action.payload.page,
          totalCount: action.payload.totalCount,
          isLoading: false,
        }
      }
    case GET_REVERSALS_FAILURE:
      return {
        ...state,
        reversals: {
          ...state.reversals,
          isLoading: false,
          errorText: action.payload.message
        }
      }

    case UPDATE_REVERSALS_PAGE:
      return {
        ...state,
        reversals: {
          ...state.reversals,
          currentPage: action.payload,
        }
      }

    case UPDATE_REVERSALS_SORT:
      return {
        ...state,
        reversals: {
          ...state.reversals,
          currentPage: 1,
          sortBy: action.payload.sortBy,
          isSortAsc: action.payload.isSortAsc,
        }
      }

    case UPDATE_REVERSALS_FILTER:
      return {
        ...state,
        reversals: {
          ...state.reversals,
          currentPage: 1,
          sortBy: 'submittedDate',
          isSortAsc: false,
        },
        filters: {
          ...state.filters,
          [action.payload.tab]: action.payload.filter
        }
      }

    case RESET_REVERSALS_TRANSACTIONS:
      return {
        ...state,
        reversals: {
          ...state.reversals,
          transactions: [],
          currentPage: 1,
          sortBy: 'submittedDate',
          isSortAsc: false,
        },
      }

    case RESET_REVERSALS:
      return {
        ...state,
        reversals: reversalsInitialState,
      }

    default:
      return state
  }
}
