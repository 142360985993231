import React  from 'react';
import { connect } from 'react-redux'
import { RootState } from '../../../store/RootState';
import { RecipientBasicTable } from './index'
import { Dimmer, Loader } from 'semantic-ui-react';
import { RECIPIENT_PAYMENT_HISTORY_TABLE_HEADERS } from '../../../store/payments/recipient/constants'
import { updateRecipientPaymentsHistorySortFilters, getRecipientPaymentsHistory, resetRecipientPaymentsState, updateRecipientPaymentsHistoryPage } from '../../../store/payments/recipient/actionCreators'
import { SortingFilter } from '../../../store/appcommon/types';
import TransactionsTablePagingMenu from '../../common/transactions/TransactionsTablePagingMenu';
import { UI_PAGE_SIZE, API_PAGE_SIZE, SUPPORT_EMAIL_LINK, SUPPORT_EMAIL } from '../../../store/applicationConstants';
import { PaymentHistoryModel } from '../../../store/payments/recipient/types';
import { NO_PAYMENT_HISTORY_HEADER, NO_PAYMENT_HISTORY_MESSAGE } from '../../../store/payments/recipient/constants'
import { PAYMENT_HISTORY_ERROR_HEADER, PAYMENT_HISTORY_ERROR_MESSAGE } from '../../../store/payments/common/constants';
import EmptyTransactions from '../../common/transactions/EmptyTransactions';
import { canUseCachedRecords } from '../../../utils/datatableHelper';
import { getPagedPaymentHistory } from '../../../store/payments/recipient/paymentsSelectors';
import { InfoBanner} from '../../common/errors/InfoBanner';
import { Link } from 'react-router-dom';
import { PaymentAlertModel } from '../../../store/payments/common/types';
import { getPaymentAlertInfo} from '../../../store/payments/common/actionCreators';

interface PaymentHistoryProps {
  paymentTransactions: PaymentHistoryModel[];
  activeHealthSystemId: number;
  isPaymentHistoryError: boolean;
  isPaymentHistoryLoading: boolean;
  currentPage: number;
  currentApiPage: number;
  totalCount: number;
  paymentAlert: PaymentAlertModel;
  updateRecipientPaymentsHistorySortFilters: (sortFilters: SortingFilter) => void;
  getRecipientPaymentsHistory: () => void;
  updateRecipientPaymentsHistoryPage: (page: number) => void;
  resetRecipientPaymentsState: () => void;
  getPaymentAlertInfo:() => void;
}

export class PaymentHistory extends React.Component<PaymentHistoryProps>{

  componentDidMount (){
    this.props.getPaymentAlertInfo();
    if (this.props.activeHealthSystemId !== 0){
      this.props.getRecipientPaymentsHistory();
    }
  }

  componentDidUpdate (prevProps: PaymentHistoryProps){
    if (prevProps.activeHealthSystemId !== this.props.activeHealthSystemId){
      this.props.getRecipientPaymentsHistory();
    }
  }

  componentWillUnmount () {
    this.props.resetRecipientPaymentsState();
  }

  handleSortColumn = (sort: SortingFilter) => {
    this.props.updateRecipientPaymentsHistorySortFilters(sort)
  }

  handlePageChange = (e: any, d: any) => {
    const activePage = d.value === 'page-left' ? this.props.currentPage - 1 : this.props.currentPage + 1;
    this.props.updateRecipientPaymentsHistoryPage(activePage);
    if (!canUseCachedRecords(activePage, this.props.currentApiPage, UI_PAGE_SIZE, API_PAGE_SIZE)) {
      this.props.getRecipientPaymentsHistory();
    }
  }

  render () {
    const { paymentTransactions, isPaymentHistoryLoading, isPaymentHistoryError, currentPage, totalCount, paymentAlert } = this.props;
    return (
      <div className="recipient-payments-history">
        {paymentAlert?.description?.length > 0 &&
        <InfoBanner
          title={'You have one or more payments waiting for you.'}
          messageWithNode={<span>Go to <Link className="link" to='/recipients/payments/settings'>Payment Settings</Link> or contact kalderos support at <a href={SUPPORT_EMAIL_LINK}>{SUPPORT_EMAIL}</a> to resolve.</span>}
        />}
        {isPaymentHistoryLoading && <Dimmer active inverted><Loader inverted content='Loading' /></Dimmer>}
        {paymentTransactions?.length > 0 &&
          <div className="recipient-payment-body">
            <TransactionsTablePagingMenu currentPage={currentPage} totalCount={totalCount} pageSize={UI_PAGE_SIZE} handlePageChange={this.handlePageChange} />
            <RecipientBasicTable
              table="paymentHistory"
              handleSortClick={this.handleSortColumn}
              data={paymentTransactions}
              headers={RECIPIENT_PAYMENT_HISTORY_TABLE_HEADERS}
            />
          </div>
        }
        {!isPaymentHistoryLoading && !isPaymentHistoryError && paymentTransactions?.length === 0 &&
          <EmptyTransactions emptyTransactionsHeader={NO_PAYMENT_HISTORY_HEADER} emptyTransactionsMessage={NO_PAYMENT_HISTORY_MESSAGE} />
        }
        {!isPaymentHistoryLoading && isPaymentHistoryError &&
          <EmptyTransactions emptyTransactionsHeader={PAYMENT_HISTORY_ERROR_HEADER} emptyTransactionsMessage={PAYMENT_HISTORY_ERROR_MESSAGE} />
        }
      </div>
    )
  }
}


const mapStateToProps = (state: RootState) => ({
  paymentTransactions: getPagedPaymentHistory(state),
  isPaymentHistoryError: state.PaymentsState.recipient.paymentHistory.isPaymentHistoryError,
  isPaymentHistoryLoading: state.PaymentsState.recipient.paymentHistory.isPaymentHistoryLoading,
  currentPage: state.PaymentsState.recipient.paymentHistory.currentPage,
  currentApiPage: state.PaymentsState.recipient.paymentHistory.currentApiPage,
  totalCount: state.PaymentsState.recipient.paymentHistory.totalCount,
  paymentAlert: state.PaymentsState.common.paymentAlert,
  activeHealthSystemId: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.healthSystemId
});

const mapDispatchToProps = (dispatch: any) => ({
  updateRecipientPaymentsHistorySortFilters: (sortFilters: SortingFilter) => dispatch(updateRecipientPaymentsHistorySortFilters(sortFilters)),
  getRecipientPaymentsHistory: () => dispatch(getRecipientPaymentsHistory()),
  updateRecipientPaymentsHistoryPage: (page: number) => dispatch(updateRecipientPaymentsHistoryPage(page)),
  resetRecipientPaymentsState: () => dispatch(resetRecipientPaymentsState()),
  getPaymentAlertInfo: () => dispatch(getPaymentAlertInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory)
