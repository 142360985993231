import React from 'react';
import { connect } from 'react-redux';
import { updateOnboardingDismissedWelcome } from '../../store/onboarding/actionCreators';
import { ONBOARDING_STEPS } from '../../store/onboarding/constants';
import { makeSelectCurrentOnboardingStep, makeSelectIsAccountAdmin, makeSelectIsAuthorizingOfficial, makeSelectIsController, makeSelectOnboardingComplete, makeSelectShowOnboardingWelcome, selectUserIsNewAO, selectUserRequiresOnboarding } from '../../store/onboarding/selectors';
import { RootState } from '../../store/RootState';
import AccountAdmin from './Recipient/AccountAdmin';
import AssociateCesAndHealthSystems from './Recipient/AuthorizingOfficial/AssociateCesAndHealthSystems';
import InviteAccountAdmin from './Recipient/AuthorizingOfficial/InviteAccountAdmin';
import ConfirmController from './Recipient/ConfirmController';
import ConfirmCoveredEntity from './Recipient/ConfirmCoveredEntity';
import Controller from './Recipient/Controller';
import InviteController from './Recipient/InviteController';
import OnboardingCompleteFullScreen from './Recipient/OnboardingCompleteFullScreen';
import Payment from './Recipient/Payment';
import PaymentSuccessFullScreen from './Recipient/PaymentSuccessFullScreen';
import Welcome from './Welcome';

export interface OnboardingManagerProps {
  currentStep: number,
  showWelcome: boolean,
  onboardingComplete: boolean,
  userRequiresOnboarding: boolean,
  isAuthorizingOfficial: boolean,
  isAccountAdmin: boolean,
  isController: boolean,
  updateOnboardingDismissedWelcome: (dismissed: boolean) => void
}

export class OnboardingManager extends React.Component<OnboardingManagerProps>{

  render () {
    const { currentStep, showWelcome, onboardingComplete, userRequiresOnboarding, isAuthorizingOfficial, isAccountAdmin, isController } = this.props;

    if (isAuthorizingOfficial || isAccountAdmin || isController) {

      if (onboardingComplete || !userRequiresOnboarding) {
        if (isController) {
          return <PaymentSuccessFullScreen />
        } else {
          return <OnboardingCompleteFullScreen />
        }
      }

      if (showWelcome) {
        return <Welcome handleNextClick={() => this.props.updateOnboardingDismissedWelcome(true)} />
      }

      if (currentStep === ONBOARDING_STEPS.AUTHORIZING_OFFICIAL_ASSOCIATE_HS && isAuthorizingOfficial) {
        return <AssociateCesAndHealthSystems />
      }

      if (currentStep === ONBOARDING_STEPS.AUTHORIZING_OFFICIAL_INVITE_ACCOUNT_ADMIN && isAuthorizingOfficial) {
        return <InviteAccountAdmin />
      }

      if (currentStep === ONBOARDING_STEPS.ACCOUNT_ADMIN_INFO && isAccountAdmin) {
        return <AccountAdmin />
      }

      if (currentStep === ONBOARDING_STEPS.ACCOUNT_ADMIN_CONFIRM_INFO && isAccountAdmin) {
        return <ConfirmCoveredEntity />
      }

      if (currentStep === ONBOARDING_STEPS.ACCOUNT_ADMIN_INVITE_CONTROLLER && isAccountAdmin) {
        return <InviteController />
      }

      if (currentStep === ONBOARDING_STEPS.CONTROLLER_INFO && isController) {
        return <Controller />
      }

      if (currentStep === ONBOARDING_STEPS.CONTROLLER_CONFIRM_INFO && isController) {
        return <ConfirmController />
      }

      if (currentStep === ONBOARDING_STEPS.CONTROLLER_BANK_INFO && isController) {
        return <Payment />
      }

      // if (currentStep === ONBOARDING_STEPS.ONBOARDING_COMPLETE && (isAuthorizingOfficial || isAccountAdmin || isController)) {
      //   return <OnboardingCompleteFullScreen />
      // }
    }

    return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  currentStep: makeSelectCurrentOnboardingStep(state),
  showWelcome: makeSelectShowOnboardingWelcome(state) && !state.OnboardingState.onboardingStatusModel.dismissedWelcome,
  onboardingComplete: makeSelectOnboardingComplete(state),
  userRequiresOnboarding: selectUserRequiresOnboarding(state),
  isAuthorizingOfficial: makeSelectIsAuthorizingOfficial(state) || selectUserIsNewAO(state),
  isAccountAdmin: makeSelectIsAccountAdmin(state),
  isController: makeSelectIsController(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  updateOnboardingDismissedWelcome: (dismissed: boolean) => dispatch(updateOnboardingDismissedWelcome(dismissed)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingManager);
