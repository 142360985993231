import RequestConfig from '../../app.config';
import fetchWrapper from '../FetchWrapper';
import { commonResponseHandler } from '../../utils/responseUtils';

export const fetchStates = async () => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/reference/states`, true);
  return commonResponseHandler(response);
};

export const fetchAdjustReasons = async () => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/reference/overrideReasons`, true);
  return commonResponseHandler(response);
};

export const fetchLegalDocuments = async () => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/config/legal/docs`, true);
  return commonResponseHandler(response);
}

export const fetch340bIds = async () => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/reference/340BIds`, true);
  return commonResponseHandler(response);
}
