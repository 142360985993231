import React, { Fragment } from 'react'
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Checkbox, Form, Icon } from 'semantic-ui-react';

import { RootState } from '../../../../store/RootState';
import Layout from '../../Layout';
import NameAndEmailFields from '../../forms/NameAndEmailFields';
import { basicSchema } from '../../forms/schemas';
import { completeInviteAccountAdminStep, getHealthSystemAccountAdmin } from '../../../../store/onboarding/actionCreators';
import { InviteUserStepInputModel } from '../../../../store/onboarding/types';
import { UserInfoModel } from '../../../../store/applicationTypes';
import { SUPPORT_EMAIL, SUPPORT_EMAIL_LINK, SUPPORT_PHONE } from '../../../../store/applicationConstants';
import GoToRequestFooter from '../GoToRequestFooter';
import { UserModel } from '../../../../store/login/types';

import '../../../../themes/kalderos/Onboarding/Recipient/InviteAccountAdmin.scss'

interface InviteAccountAdminProps {
  userInfo: UserModel;
  isEmailSent: boolean;
  healthSystemName: string;
  isLoading: boolean;
  errorText: string;
  hasAccountAdmin: boolean;
  accountAdminInfo?: UserInfoModel;
  getHealthSystemAccountAdmin: () => void;
  completeInviteAccountAdminStep: (input: InviteUserStepInputModel) => void;
}

interface InviteAccountAdminState {
  isAccountAdmin: boolean,
  isAdminReviewed: boolean,
}

export class InviteAccountAdmin extends React.Component<InviteAccountAdminProps, InviteAccountAdminState>{
  constructor (props: Readonly<InviteAccountAdminProps>) {
    super(props);
    this.state = {
      isAccountAdmin: false,
      isAdminReviewed: false,
    };
  }

  componentDidMount () {
    this.props.getHealthSystemAccountAdmin();
  }

  componentDidUpdate (prevProps: InviteAccountAdminProps) {
    if (prevProps !== this.props) {
      if (this.isCurrentUserAlreadyAccountAdmin()) {
        this.setState({ isAccountAdmin: true });
      }
    }
  }

  onAccountAdminCheck = () => {
    const isAccountAdmin = !this.state.isAccountAdmin;
    this.setState({ isAccountAdmin: isAccountAdmin });
  }

  onNextClick = () => {
    if (this.isAnotherAdminAlreadyAssigned()) {
      this.setState({ isAdminReviewed: true });
    } else {
      this.props.completeInviteAccountAdminStep({ addRoleToCurrentUser: true })
    }
  }

  onSendEmailClick = (input: InviteUserStepInputModel) => {

    const inputCopy: InviteUserStepInputModel = {
      ...input
    };
    const currentUserWasAA = this.props.hasAccountAdmin && this.props.accountAdminInfo?.userName === this.props.userInfo.userName;
    if (currentUserWasAA) {
      inputCopy.removeRoleFromCurrentUser = true;
    }

    this.props.completeInviteAccountAdminStep(inputCopy)
  }

  isAnotherAdminAlreadyAssigned = () => {
    return this.props.hasAccountAdmin && this.props.accountAdminInfo?.userName !== this.props.userInfo.userName;
  }

  isCurrentUserAlreadyAccountAdmin = () => {
    return this.props.hasAccountAdmin && this.props.accountAdminInfo?.userName === this.props.userInfo.userName;
  }

  render () {
    const { userInfo, healthSystemName, accountAdminInfo } = this.props;
    const isAdminAlreadyAssigned = this.isAnotherAdminAlreadyAssigned();
    const nextDisabled = !this.state.isAccountAdmin && !isAdminAlreadyAssigned
    const initialValues = {
      firstName: '',
      lastName: '',
      email: '',
      addRoleToCurrentUser: this.state.isAccountAdmin
    };

    return (
      <Layout
        isLoading={this.props.isLoading}
        errorText={this.props.errorText}
        stepNumber={3}
      >
        {!this.props.isEmailSent && !this.state.isAdminReviewed &&
          <Fragment>
            <div className="onboarding-layout-header">
              <div className="title">3. Invite Account Admin</div>
              <div className="sub-title">
                {!isAdminAlreadyAssigned &&
                  <Fragment>
                    Please provide the contact information for the Account Admin. They will be responsible for setting up the rest of the account, inviting other team members, and be the Kalderos contact for your company. If you will be the Account Admin, check the box and continue setting up the account.
                  </Fragment>
                }
                {isAdminAlreadyAssigned &&
                  <Fragment>
                    An Account Admin for your Health System has already been assigned. Please review the information below. If any of the information is incorrect, contact Kalderos support at <a href={SUPPORT_EMAIL_LINK}>{SUPPORT_EMAIL}</a> or {SUPPORT_PHONE}, and we will work with you on how to proceed.
                  </Fragment>
                }
              </div>
            </div>
            <div className="onboarding-layout-children">
              <div className="onboarding-layout-body invite-account-admin">
                <div className="detail-field">
                  <div className="value" data-private>{healthSystemName}</div>
                  <div className="label no-border">HEALTH SYSTEM NAME</div>
                </div>
                {!isAdminAlreadyAssigned &&
                  <div className="onboarding-checkbox">
                    <Checkbox data-private label="I will be the Account Admin, responsible for setting up the rest of the account, inviting other team members, and be the Kalderos contact for my company."
                      checked={this.state.isAccountAdmin}
                      onChange={this.onAccountAdminCheck}
                    />
                  </div>
                }
                <div className="section-header margin-top">Account Admin&apos;s Information</div>
                {!this.state.isAccountAdmin && !isAdminAlreadyAssigned &&
                  <Formik
                    initialValues={initialValues}
                    validateOnMount={true}
                    validationSchema={yup.object().shape({ ...basicSchema })}
                    onSubmit={(values) => { this.onSendEmailClick(values) }}
                  >
                    {props => (
                      <Form className="input-form" onSubmit={props.handleSubmit}>
                        <NameAndEmailFields values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} includeEmail errors={props.errors} touched={props.touched} />
                        <button className="app-button button-dark" type="submit" disabled={this.state.isAccountAdmin || !props.isValid || !props.dirty}>Send Email to Admin</button>
                      </Form>
                    )}
                  </Formik>
                }
                {this.state.isAccountAdmin &&
                  <Fragment>
                    <div className="detail-field">
                      <div className="value" data-private>{userInfo.given_name} {userInfo.family_name}</div>
                      <div className="label">NAME</div>
                    </div>
                    <div className="detail-field">
                      <div className="value" data-private>{userInfo.userName}</div>
                      <div className="label">EMAIL</div>
                    </div>
                  </Fragment>
                }
                {isAdminAlreadyAssigned &&
                  <Fragment>
                    <div className="detail-field">
                      <div className="value" data-private>{accountAdminInfo?.firstName} {accountAdminInfo?.lastName}</div>
                      <div className="label">NAME</div>
                    </div>
                    <div className="detail-field">
                      <div className="value" data-private>{accountAdminInfo?.email}</div>
                      <div className="label">EMAIL</div>
                    </div>
                  </Fragment>
                }
              </div>
            </div>
            <div className="onboarding-layout-footer">
              <div className="onboarding-layout-cta">
                <button className="app-button button-dark"
                  onClick={this.onNextClick}
                  disabled={this.props.isLoading || nextDisabled}
                  type="button"
                >
                  {isAdminAlreadyAssigned ? 'Next' : 'Confirm and Next'}
                </button>
              </div>
            </div>
          </Fragment>
        }
        {(this.props.isEmailSent || this.state.isAdminReviewed) &&
          <Fragment>
            <div className="full-screen-message">
              <div className='checkicon-outline'><Icon name='check circle outline' size='big' className='checkicon' /></div>
              <div className="header">Account Admin&apos;s Information Received!</div>
              <div className="details">Thanks! We have received the information we needed. We will contact you if we need more information to complete the sign up process. </div>
            </div>
            <GoToRequestFooter />
          </Fragment>
        }
      </Layout>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  userInfo: state.LoginState.user,
  isEmailSent: state.OnboardingState.inviteAccountAdminStepModel.isEmailSent,
  isLoading: state.OnboardingState.isLoading,
  errorText: state.OnboardingState.errorText,
  healthSystemName: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.healthSystemName,
  hasAccountAdmin: state.OnboardingState.inviteAccountAdminStepModel.hasAccountAdmin,
  accountAdminInfo: state.OnboardingState.inviteAccountAdminStepModel.accountAdminInfo
});

const mapDispatchToProps = (dispatch: any) => ({
  getHealthSystemAccountAdmin: () => dispatch(getHealthSystemAccountAdmin()),
  completeInviteAccountAdminStep: (input: InviteUserStepInputModel) => dispatch(completeInviteAccountAdminStep(input)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InviteAccountAdmin);
