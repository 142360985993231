import React, { ChangeEvent } from 'react';
import { Formik} from 'formik';
import { connect } from 'react-redux';
import *  as yup from 'yup';
import { Button, Modal, Form, Icon, List } from 'semantic-ui-react';
import {  CloseIconAlt } from '../../../themes/kalderos/Common/Icons';
import { VerifyMicroDepositsModel } from '../../../store/payments/common/types';
import { verifyMicroDeposits, verifyMicroDepositsModalClose, resetMicroDepositStatus, getPaymentSummary } from '../../../store/payments/common/actionCreators';
import { RootState } from '../../../store/RootState';
import { ErrorBanner } from '../../common/errors/ErrorBanner';
import Cleave from 'cleave.js/react';
import cx from 'classnames';
import CloseButton from '../../common/CloseButton';

export interface VerifyMicroDepositsModalProps {
  sourceId: number;
  accountName: string;
  bankName: string;
  verifyMicroDepositStatus: number | null;
  isVerificationInProcess: boolean;
  verifyMicroDeposits: (verifyMicroDepositsModel: VerifyMicroDepositsModel) => void;
  verifyMicroDepositsModalClose: () => void;
  getPaymentSummary: () => void;
  resetMicroDepositStatus: () => void;
}

interface VerifyMicroDepositsModalState{
  isVerifyMicroDepositsOpen: boolean;
}

interface MicroDepositErrorModalProps {
  accountName: string;
  bankName: string;
  handleModalClose:() => void;
}

//ERROR MODAL - shown if a user attempts to verify micro deposits but fails 3 times
const VerifyMicroDepositErrorModal: React.FunctionComponent<MicroDepositErrorModalProps> = ({ accountName, bankName, handleModalClose }) => {
  return (
    <div className='flex-column verify-error-container'>
      <ErrorBanner errorText={'Wrong amount(s). You have no attempts left.'} />
      <div className='flex-column verify-error-sub-container'>
        <div className='flex-row verify-error-header'>
          <div className='beta verify-header'>Too many attempts</div>
          <Button className='app-btn verify-close-btn' onClick={handleModalClose}>
            <CloseIconAlt />
          </Button>
        </div>
        <div className='delta verify-error-instructions'>The maximum attempts allowed to verify your account have been reached. Begin the account verification process again after 48 hours.</div>
        <List className='verify-error-list' bulleted>
          <List.Item>
            <div className='epsilon'>No action needed now. Wait 48 hours, then re-add.</div>
            <div data-private className='eta verify-error-sub-instructions'>{`Pending funding source ${accountName} ${bankName} had been removed. After 48 hours you can re-add this funding source.`}</div>
          </List.Item>
          <List.Item>
            <div className='epsilon'>Verify new micro deposits</div>
            <div className='eta verify-error-sub-instructions'>Newly posted micro deposits will arrive in 1-3 business days after re-adding. Verify the new amounts.</div>
          </List.Item>
        </List>
        <div className='verify-deposit-error-btn-row'>
          <Button onClick={handleModalClose} className='app-button button-dark'>Close</Button>
        </div>
      </div>
    </div>
  )
}

interface VerifyMicroDepositsSuccessModalProps {
  handleModalClose:() => void;
}

//SUCCESS MODAL - shown if a successful API call for verifying micro deposits is received.
const VerifyMicroDepositsSuccessModal: React.FunctionComponent<VerifyMicroDepositsSuccessModalProps> = ({ handleModalClose }) => {
  return (
    <div>
      <div className='flex-row verify-success-header'>
        <Button className='app-button verify-close-btn' onClick={handleModalClose}>
          <CloseIconAlt />
        </Button>
      </div>
      <div className='flex-column verify-deposit-success-column'>
        <div className='verify-checkmark-container'>
          <Icon className='verify-success-check' name='check circle outline' />
        </div>
        <div className='beta verify-success-header-content'>Funding Source Verified</div>
        <div className='delta verify-success-header-message'>Funding source successfully added to your account.</div>
        <div className='verify-deposit-success-btn-row'>
          <Button onClick={handleModalClose} className='app-button button-dark'>Close</Button>
        </div>
      </div>
    </div>
  )
}



export class VerifyMicroDepositsModal extends React.Component<VerifyMicroDepositsModalProps, VerifyMicroDepositsModalState> {
  constructor (props: Readonly<VerifyMicroDepositsModalProps>){
    super(props);
    this.state = {
      isVerifyMicroDepositsOpen : false
    }
  }


  handleSubmittingVerifyMicroDeposits = (values: any) => {
    const verifyMicroDepositsModel : VerifyMicroDepositsModel = {
      fundingSourceId : this.props.sourceId,
      amount1 : parseFloat(values.microDepositOne),
      amount2 : parseFloat(values.microDepositTwo)
    };
    this.props.resetMicroDepositStatus();
    this.props.verifyMicroDeposits(verifyMicroDepositsModel);
  }

  handleVerifyModalClose = () => {
    this.setState({isVerifyMicroDepositsOpen: false})
    this.props.verifyMicroDepositsModalClose();
    this.props.getPaymentSummary();
  }




  render (){

    const initialValues = {
      microDepositOne: 0.00,
      microDepositTwo: 0.00
    }
    const { isVerifyMicroDepositsOpen } = this.state;
    const { verifyMicroDepositStatus, accountName, bankName, isVerificationInProcess } = this.props;

    const schema = yup.object().shape({
      microDepositOne: yup
        .number()
        .moreThan(0),
      microDepositTwo: yup
        .number()
        .moreThan(0)
    })

    return (
      <Modal className='verify-micro-deposits-modal' closeOnDimmerClick={false} open={isVerifyMicroDepositsOpen} trigger={<button type='button' className='verify-now-btn' data-testid="modalTrigger" onClick={() => this.setState({ isVerifyMicroDepositsOpen : true })}>Verify Now</button>}>
        { verifyMicroDepositStatus === 400 &&
        <ErrorBanner errorText={'Wrong amount(s).'} />
        }
        { verifyMicroDepositStatus === 500 &&
        <ErrorBanner errorText={'An error occurred while verifying. Please try again.'} />
        }
        {verifyMicroDepositStatus !== 200 && verifyMicroDepositStatus !== 403 &&
          <Modal.Content>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={(values) => {
                this.handleSubmittingVerifyMicroDeposits(values)
              }}
            >
              {props => (<Form onSubmit={props.handleSubmit}>
                <div className='flex-row header-row'>
                  <div className='beta verify-funds-header'>Verify bank account</div>
                  <CloseButton isDisabled={isVerificationInProcess} data-testid='verifyCloseBtn' onClick={this.handleVerifyModalClose} />
                </div>
                <div className='delta verify-instructions'>To verify your account, enter the two micro deposits below. If you don&apos;t see them, they should arrive in 1-3 business days.</div>
                <div className='flex-column verify-deposits-lower-column'>
                  <div className='verify-deposit-input-row'>
                    <div className='zeta verify-input-header'>Micro Deposit #1</div>
                    <div className='ui input verify-deposit-input'>
                      <Cleave
                        name='microDepositOne'
                        value={props.values.microDepositOne}
                        onChange={(e: ChangeEvent<any>) => props.setFieldValue('microDepositOne', e.target.rawValue)}
                        options={{ delimiters: ['$'], blocks: [0, 21] }}
                        onBlur={() => props.setFieldTouched('microDepositOne')}
                      />
                    </div>
                  </div>
                  <div className='verify-deposit-input-row'>
                    <div className='zeta verify-input-header'>Micro Deposit #2</div>
                    <div className='ui input verify-deposit-input'>
                      <Cleave
                        name='microDepositTwo'
                        value={props.values.microDepositTwo}
                        onChange={(e: ChangeEvent<any>) => props.setFieldValue('microDepositTwo', e.target.rawValue)}
                        options={{ delimiters: ['$'], blocks: [0, 21] }}
                        onBlur={() => props.setFieldTouched('microDepositTwo')}
                      />
                    </div>
                  </div>
                  <div className={cx('verify-deposit-btn-row', {'with-error': (props.errors?.microDepositOne || props.errors?.microDepositTwo) && (props.touched.microDepositOne && props.touched.microDepositTwo)})}>
                    {(props.errors?.microDepositOne || props.errors?.microDepositTwo) && (props.touched.microDepositOne && props.touched.microDepositTwo) && <div className="error-text">Micro Deposit Amount Invalid</div>}
                    <Button id='verifyMicroDepositBtn' type='submit' disabled={(props.errors.microDepositOne || props.errors.microDepositTwo || !props.touched.microDepositOne || !props.touched.microDepositTwo || isVerificationInProcess) ? true : false} className='app-button button-dark'>Verify Account</Button>
                  </div>
                </div>
              </Form>)}
            </Formik>
          </Modal.Content>
        }
        {verifyMicroDepositStatus === 200 &&
          <Modal.Content>
            <VerifyMicroDepositsSuccessModal
              handleModalClose={this.handleVerifyModalClose}
            />
          </Modal.Content>
        }
        {verifyMicroDepositStatus === 403 &&
          <Modal.Content className='verify-micro-deposit-error-content'>
            <VerifyMicroDepositErrorModal
              accountName={accountName}
              bankName={bankName}
              handleModalClose={this.handleVerifyModalClose}
            />
          </Modal.Content>
        }
      </Modal>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  verifyMicroDepositStatus: state.PaymentsState.common.fundingModalState.verifyMicroDepositStatus,
  isVerificationInProcess: state.PaymentsState.common.fundingModalState.isVerificationInProcess
});

const mapDispatchToProps = (dispatch: any) => ({
  verifyMicroDeposits: (verifyMicroDepositsModel: VerifyMicroDepositsModel) => dispatch(verifyMicroDeposits(verifyMicroDepositsModel)),
  verifyMicroDepositsModalClose: () => dispatch(verifyMicroDepositsModalClose()),
  resetMicroDepositStatus: () => dispatch(resetMicroDepositStatus()),
  getPaymentSummary: () => dispatch(getPaymentSummary()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyMicroDepositsModal);
