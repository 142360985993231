import React from 'react';
import cx from 'classnames';
import { Button, Popup } from 'semantic-ui-react';
import { X } from 'react-feather';
import { FilterIcon } from '../../../themes/kalderos/Common/Icons';

export interface FilterValueSummary {
  name: string,
  values: string[]
}

interface FilterToggleButtonProps {
  isDisabled: boolean;
  isOpen: boolean;
  filterCount: number;
  filterSummary: FilterValueSummary[];
  onToggle: (isOpen: boolean) => void,
  onClear: () => void,
}

const FilterToggleButton: React.FunctionComponent<FilterToggleButtonProps> = ({ isDisabled, isOpen, filterCount, filterSummary, onToggle, onClear }: FilterToggleButtonProps) => {

  const toggleFilter = () => {
    onToggle(!isOpen);
  }

  return (
    <div className="filter-toggle-button-container">
      <Popup className="filter-summary" position="bottom left" mouseEnterDelay={500} disabled={filterCount === 0 || isOpen} hoverable flowing trigger={
        <Button className={cx('filter-toggle-button', { 'open': isOpen })} disabled={isDisabled} onClick={toggleFilter}>
          <FilterIcon />{isOpen ? 'Hide' : 'Show'} Filters {filterCount > 0 && `(${filterCount})`}
        </Button>
      }>
        <div className="filter-tooltip">
          {filterSummary.map((f, i) => {
            if (f.values.length > 0) {
              return (
                <div key={i} className="filter-tooltip-col">
                  <div className="filter-tooltip-header">{f.name}</div>
                  {f.values.map((v, i) => {
                    return <div key={i} className="filter-tooltip-value">{v}</div>
                  })}
                </div>
              )
            } else { return '' }
          })}
        </div>
      </Popup>
      {filterCount > 0 && 
        <Button className="filter-clear-button" disabled={isDisabled} onClick={onClear}>
          <div className="filter-clear-button-text">
            Clear All Filters <X size={16} />
          </div>
        </Button>}
    </div>
  )
}

export default FilterToggleButton