import React from 'react';
import { Radio } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { CheckCircle } from 'react-feather';

import CoveredEntityModal from './CoveredEntityModal';
import Layout from '../../Layout';
import { RootState } from '../../../../store/RootState';
import { CandidateHealthSystem, CandidateCoveredEntity } from '../../../../store/onboarding/types';
import { setSelectedHealthSystem } from '../../../../store/onboarding/actionCreators';
import { getCandidateHealthSystems, getOrphanedCes } from '../../../../store/onboarding/selectors';
import HealthSystemInformationModal from './HealthSystemInformationModal';

interface AssociateHealthSystemsProps {
  isLoading: boolean,
  errorText: string,
  candidateHealthSystems: CandidateHealthSystem[],
  orphanedCes: CandidateCoveredEntity[],

  onNext: () => void,
  setSelectedHealthSystem: (selected: CandidateHealthSystem) => void
}

interface AssociateHealthSystemsState {
  selectedOption: number;
}

export class AssociateHealthSystems extends React.Component<AssociateHealthSystemsProps, AssociateHealthSystemsState> {
  constructor (props: Readonly<AssociateHealthSystemsProps>) {
    super(props);
    this.state = {
      selectedOption: 0,
    };
  }

  onNext = () => {
    let selected = this.props.candidateHealthSystems.find(hs => hs.healthSystemId === this.state.selectedOption);
    if (selected === undefined) {
      selected = {
        healthSystemName: '',
        healthSystemId: undefined,
        onboardState: 0,
        ceList: [],
        orphanCeList: [],
        isExistingHealthSystem: false
      }
    } 

    this.props.setSelectedHealthSystem(selected);

    this.props.onNext();
  }

  render () {
    
    const { isLoading, errorText, candidateHealthSystems, orphanedCes } = this.props;
    const alreadyOnboardedHealthSystems = candidateHealthSystems.filter(hs => hs.healthSystemId !== undefined && hs.healthSystemId !== null && hs.onboardState > 1);
    const notYetOnboardedHealthSystems = candidateHealthSystems.filter(hs => hs.healthSystemId !== undefined && hs.healthSystemId !== null && hs.onboardState === 0);
  
    return (
      <Layout
        isLoading={isLoading}
        errorText={errorText}
        stepNumber={1}>
        <div className="onboarding-layout-header">
          <div className="title">1. Associate Covered Entities &amp; Health Systems</div>
          <div className="sub-title">
            We&apos;ve found the following Health Systems associated with your email as the Authorizing Official in the HRSA Database. <HealthSystemInformationModal />
            <br /><br />
            Each Health System needs to be set up separately. You  may need to create a new Health System if any of the unassociated covered entities don&apos;t fit within the existing ones.
          </div>
        </div>
        <div className="onboarding-layout-children associate-covered-entities">
          <div className="onboarding-layout-body">
            <div className="health-system-instruction">Select a Health System to set up:</div>
            <div className="heading">
              <div>Health System Name</div> <div><span className="ce">Associated Covered Entities</span></div>
            </div>
            {alreadyOnboardedHealthSystems && alreadyOnboardedHealthSystems.length > 0 && 
            alreadyOnboardedHealthSystems.map(healthSystem => {
              return (
                <div key={healthSystem.healthSystemId} className="candidate-health-system read-only">
                  <CheckCircle data-private size={16} className="candidate-radio" />
                  <div className="candidate-health-system-text">
                    <span>{healthSystem.healthSystemName}</span> 
                    <div>
                      <span className="ce">{healthSystem.ceList && <CoveredEntityModal healthSystemName={healthSystem.healthSystemName} ceList={healthSystem.ceList} triggerText={`View (${healthSystem.ceList.length})`} triggerSize={'small'} />}</span>
                    </div>
                  </div>
                </div>
              )
            })}
            {notYetOnboardedHealthSystems && notYetOnboardedHealthSystems.length > 0 &&
              notYetOnboardedHealthSystems.map(healthSystem => {
                return (
                  <div key={healthSystem.healthSystemId} className="candidate-health-system">
                    <Radio data-private className="candidate-radio" value={healthSystem.healthSystemId} checked={this.state.selectedOption === healthSystem.healthSystemId} onChange={(e: any, data: any) => this.setState({ selectedOption: data.value})} />
                    <div className="candidate-health-system-text">
                      <span>{healthSystem.healthSystemName}</span> 
                      <div>
                        <span className="ce">{healthSystem.ceList && <CoveredEntityModal healthSystemName={healthSystem.healthSystemName} ceList={healthSystem.ceList} triggerText={`View (${healthSystem.ceList.length})`} triggerSize={'small'} />}</span>
                      </div>
                    </div>
                  </div>
                )
              })
            }
            {orphanedCes && orphanedCes.length > 0 &&
              <div className="candidate-health-system">
                <Radio data-private className="candidate-radio" value={-1} checked={this.state.selectedOption === -1} onChange={(e: any, data: any) => this.setState({ selectedOption: data.value})}/>
                <div className="candidate-health-system-text">
                  <span>Create a new Health System</span>
                </div>
              </div>
            }
          </div>
          {orphanedCes && orphanedCes.length > 0 && 
          <div className="orphan-footer">
            <div className="description">We&apos;ve found the following covered entities associated with your email as the Authorizing Official in the HRSA Database. You can assign the entities below to the selected Health System in the next step.  </div>
            <div className="orphan-ces">
              <span>Covered Entities Not in a Health System</span>
              <span className="count"><CoveredEntityModal ceList={orphanedCes} triggerText={`View (${orphanedCes.length})`} triggerSize={'small'} /></span>
            </div>
          </div>
          }
        </div>
        <div className="onboarding-layout-footer">
          <div className="onboarding-layout-cta">
            <button className="app-button button-dark" onClick={this.onNext} disabled={isLoading || this.state.selectedOption === 0} type="button">Next</button>
          </div>
        </div>
      </Layout>
    );
  }
}


const mapStateToProps = (state: RootState) => ({
  info: state.OnboardingState.authorizingOfficialInfoStepModel.info,
  isLoading: state.OnboardingState.isLoading,
  errorText: state.OnboardingState.errorText,
  candidateHealthSystems: getCandidateHealthSystems(state),
  orphanedCes: getOrphanedCes(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedHealthSystem: (selected: CandidateHealthSystem) => dispatch(setSelectedHealthSystem(selected)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssociateHealthSystems);