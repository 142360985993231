export const LEFT_NAV_WIDTH = {
  EXPANDED: 248,
  COLLAPSED: 100
};

export const UI_PAGE_SIZE = 25;
export const API_PAGE_SIZE = 100;

export const NO_TRANSACTIONS_HEADER = 'No Transactions';
export const NO_TRANSACTIONS_MESSAGE = 'Looks like there were no claims to review during this invoicing period or the claims were rolled over onto your next Active Invoice.';

export const NO_REMITTANCE_ADVICE_HEADER = 'No Remittance Advice';
export const NO_REMITTANCE_ADVICE_MESSAGE = 'You can view your Remittance Advice here after you receive your first payment.';

export const NO_DISCOUNT_REQUESTS_HEADER = 'No Discount Requests';
export const NO_DISCOUNT_REQUESTS_MESSAGE = 'We haven’t received any discount requests from you yet. When you submit discount requests, they will show up here.';

export const NO_FILTER_RESULTS_HEADER = 'No Filter Results';
export const NO_FILTER_RESULTS_MESSAGE = 'Try adjusting your filter criteria to see more requests.';

export const NO_PROCESSED_REVERSALS_HEADER = 'No processed reversals for this time period.';
export const NO_PROCESSED_REVERSALS_MESSAGE = `Newly processed reversals will show up here once they've gone through processing and have been sent to the manufacturer.`;

export const NO_PENDING_REVERSALS_HEADER = 'All reversals have been processed!';
export const NO_PENDING_REVERSALS_MESSAGE = 'You will see new reversals that are waiting to be processed show up here in the future.';

export const SERVER_ERROR_HEADER = 'An error occurred while loading.';
export const SERVER_ERROR_MESSAGE = 'Please refresh the page to try again.';

export const CLIENT_ERROR_HEADER = 'Oh no! This page doesn’t exist.';
export const CLIENT_ERROR_MESSAGE = 'Error code: 404 Page not found';

export enum ROLES {
  PAYER = 'DDM.DP.Admin',
  RECIPIENT = 'DDM.DR.Admin',
  STAKEHOLDER = 'DDM.DS.Admin',

  RECIPIENT_AUTHORIZING_OFFICIAL = 'DDM.DR.AuthorizingOfficial',
  RECIPIENT_ACCOUNT_ADMIN = 'DDM.DR.AccountAdmin',
  RECIPIENT_CONTROLLER = 'DDM.DR.Controller',

  ADMIN_ALL_ACCESS = 'Kalderos.Admin.AllAccess',
  ADMIN_SUPPORT = 'Kalderos.Admin.Support'
}

export enum FEATURE_FLAGS {
  PHARMACY_MANAGEMENT = 'pharmacyManagement'
}

export const SUPPORT_EMAIL = 'support@kalderos.com';
export const SUPPORT_EMAIL_LINK = `mailto:${SUPPORT_EMAIL}`;
export const SUPPORT_PHONE = '+1 (844) 930-2322';

export const ZENDESK_KEY = 'afc8609b-968c-42fa-8e76-5dec19c1b1c7'; 