import RequestConfig from '../../app.config';
import { formatYYYYMMDD } from '../../utils/dateHelper';
import { createUrlParametersString } from '../../utils/requestUtil';
import fetchWrapper from '../FetchWrapper';
import { commonResponseHandler, RESPONSE_TYPE } from '../../utils/responseUtils';
import { DiscountRequestFieldSubmitModel, DiscountRequestRequiredFieldsModel, RequestCenterFileUploadResponseModel } from '../../store/requestcenter/types';
import moment from 'moment';
import getUserToken from '../../localstore/LocalStore';

export const fetchDiscountPrograms = async (healthSystemId?: number) => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/contracts/discountPrograms?healthSystemId=${healthSystemId}`, true);
  return commonResponseHandler(response, RESPONSE_TYPE.JSON)
};

export const fetchAssociatedEntities = async (sortBy: string, isSortDesc: boolean, healthSystemId: number) => {
  const urlParams = createUrlParametersString({ SortField: sortBy, SortDesc: isSortDesc, healthSystemId: healthSystemId })
  const apiUrl = `${await RequestConfig()}/request/v1/business/authorizedEntities?${urlParams}`;
  const response = await fetchWrapper(apiUrl, true);
  return commonResponseHandler(response);
};

export const fetchDiscountProgramFields = async (values: DiscountRequestRequiredFieldsModel) => {
  const urlParams = createUrlParametersString({
    discountProgram: values.discountProgram,
    ndc11: values.ndc11.trim(),
    fillDate: formatYYYYMMDD(values.fillDate),
    idCode: values.idCode,
    //isMedical: values.claimType === 'Medical'
  });
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/contracts/elements?${urlParams}`, true)
  return commonResponseHandler(response);
};

export const submitDiscountRequest = async (fields: DiscountRequestFieldSubmitModel, healthSystemId: number) => {
  if (!fields.discountPerUnit) { fields.discountPerUnit = undefined }
  if (fields.fillDate) { fields.fillDate = moment(fields.fillDate).utcOffset(0, true).toDate(); }
  if (fields.claimPaidDate) { fields.claimPaidDate = moment(fields.claimPaidDate).utcOffset(0, true).toDate(); }
  if (fields.claimDate) { fields.claimDate = moment(fields.claimDate).utcOffset(0, true).toDate(); }
  if (fields.lastServiceDate) { fields.lastServiceDate = moment(fields.lastServiceDate).utcOffset(0, true).toDate(); }

  const requestBody = [fields];   // to submit one dr at a time(api takes a list)
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/discountrequests/submit?healthSystemId=${healthSystemId}`, true, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestBody)
  });
  return commonResponseHandler(response);
};

export const correctDiscountRequest = async (discountRequestId: number, fields: DiscountRequestFieldSubmitModel, healthSystemId: number) => {
  if (!fields.discountPerUnit) { fields.discountPerUnit = undefined }
  if (fields.fillDate) { fields.fillDate = moment(fields.fillDate).utcOffset(0, true).toDate(); }
  if (fields.claimPaidDate) { fields.claimPaidDate = moment(fields.claimPaidDate).utcOffset(0, true).toDate(); }
  if (fields.claimDate) { fields.claimDate = moment(fields.claimDate).utcOffset(0, true).toDate(); }
  if (fields.lastServiceDate) { fields.lastServiceDate = moment(fields.lastServiceDate).utcOffset(0, true).toDate(); }

  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/discountrequests/${discountRequestId}/correct?healthSystemId=${healthSystemId}`, true, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(fields)
  });
  return commonResponseHandler(response);
};

export const uploadDiscountRequests = async (file: any, progressCallback: (percent: number) => void) => {

  const apiUrl = `${await RequestConfig()}/request/v1/discountrequests/file`;

  return new Promise<RequestCenterFileUploadResponseModel>((resolve, reject) => {

    const req = new XMLHttpRequest();

    req.upload.addEventListener('progress', event => {
      if (event.lengthComputable) {
        const progressPercent = Math.round((event.loaded / event.total) * 100);
        // always set progress less than or equal to 80, remaining 20% will be used for processing
        progressCallback(progressPercent < 80 ? progressPercent : 80);
      }
    });

    req.upload.addEventListener('load', () => {
      // on file upload complete set the progress value to be between 80 to 100
      progressCallback(80 + Math.floor(Math.random() * (20 - 1)) + 1);
    });

    req.upload.addEventListener('error', () => {
      // reject promise if any error in processing
      reject(req.response);
    });

    const formData = new FormData();
    formData.append('file', file, file.name);
    req.open('POST', apiUrl);
    req.setRequestHeader('Authorization', 'Bearer ' + getUserToken());
    req.onreadystatechange = () => {
      if (req.readyState === XMLHttpRequest.DONE) {
        if (req.status === 200) {
          resolve(JSON.parse(req.response));
        } else {
          reject(req.response);
        }
      }
    };
    req.send(formData);
  });
}