import RequestConfig from '../../app.config';
import fetchWrapper from '../FetchWrapper';
import { createUrlMultiParameterString, createUrlParametersString } from '../../utils/requestUtil';
import { API_PAGE_SIZE } from '../../store/requestcenter/constants';
import { RESPONSE_TYPE, commonResponseHandler } from '../../utils/responseUtils';
import { RemittanceDetailsParamModel } from '../../store/remittance/types';

export const fetchRemittanceSummaryPeriods = async (healthSystemId: number) => {
  const urlParams = createUrlParametersString({ healthSystemId: healthSystemId });
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/remittance/periods?${urlParams}`, true)
  return commonResponseHandler(response);
}

export const fetchRemittanceSummaryByDate = async (startDate: any, endDate: any, healthSystemId: number) => {
  const urlParams = createUrlParametersString({ healthSystemId: healthSystemId, startDate: startDate, endDate: endDate })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/remittance/summary?${urlParams}`, true)
  return commonResponseHandler(response);
}

export const fetchRemittanceSummaryByMonth = async (year: number, month: number, healthSystemId: number) => {
  const urlParams = createUrlParametersString({ healthSystemId: healthSystemId })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/remittance/summary/${year}/${month}?${urlParams}`, true)
  return commonResponseHandler(response);
}

export const fetchRemittanceSummaryByInvoice = async (invoiceId: number, participantId: string, healthSystemId: number) => {
  const urlParams = createUrlParametersString({ healthSystemId: healthSystemId})
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/remittance/summary/invoice/${invoiceId}/${participantId}/?${urlParams}`, true);
  return commonResponseHandler(response, RESPONSE_TYPE.JSON);
}

export const fetchRemittanceDetailCounts = async (inputs: RemittanceDetailsParamModel, healthSystemId: number) => {
  const hsParam =  createUrlParametersString({ healthSystemId: healthSystemId });
  const idsParam = inputs.remittanceIds ? '&' + createUrlMultiParameterString('remittanceIds', inputs.remittanceIds) : '';
  const startDateParam =  inputs.startDate ? '&' + createUrlParametersString({ startDate: inputs.startDate }) : '';
  const endDateParam = inputs.endDate ? '&' + createUrlParametersString({ endDate: inputs.endDate }) : '';
  const participantParam = inputs.participantId ? '&' + createUrlParametersString({ participantId: inputs.participantId }) : '';
  
  const urlParams = `${hsParam}${idsParam}${startDateParam}${endDateParam}${participantParam}`

  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/remittance/details/count?${urlParams}`, true)
  return commonResponseHandler(response);

}

export const fetchRemittanceDetailTransactions = async (inputs: RemittanceDetailsParamModel, pageToFetch: number, sortBy: string, isSortAsc: boolean, queryFilter: string, healthSystemId: number) => {
  let multiSortBy = 'remittanceDate desc, idCode, fillDate';
  
  if (sortBy) {
    multiSortBy = '';
  }

  if (sortBy === 'statusTextSort') {
    sortBy = ''
    multiSortBy = isSortAsc ? 'statusText asc, isAdjusted' : 'statusText desc, isAdjusted desc'
  }

  const modifierParams = createUrlParametersString({ page: pageToFetch, pageSize: API_PAGE_SIZE, SortField: sortBy, SortDesc: !isSortAsc, multiSortField: multiSortBy, healthSystemId: healthSystemId });
 
  const idsParam = inputs.remittanceIds ? '&' + createUrlMultiParameterString('remittanceIds', inputs.remittanceIds) : '';
  const startDateParam =  inputs.startDate ? '&' + createUrlParametersString({ startDate: inputs.startDate }) : '';
  const endDateParam = inputs.endDate ? '&' + createUrlParametersString({ endDate: inputs.endDate }) : '';
  const participantParam = inputs.participantId ? '&' + createUrlParametersString({ participantId: inputs.participantId }) : '';

  const urlParams = `${modifierParams}${idsParam}${startDateParam}${endDateParam}${participantParam}`

  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/remittance/details?${urlParams}&${queryFilter}`, true)
  return commonResponseHandler(response);
}


