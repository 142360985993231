import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Icon } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as yup from 'yup';

import { RootState } from '../../../store/RootState';
import { UserInfoInputModel } from '../../../store/onboarding/types';
import { completeAccountAdminInfoStep, updateOnboardingStep } from '../../../store/onboarding/actionCreators';
import { makeSelectIsAuthorizingOfficial, makeSelectIsLoading } from '../../../store/onboarding/selectors';

import { UserInfoModel } from '../../../store/applicationTypes';
import Layout from '../Layout';
import AddressFields from '../forms/AddressFields';
import TitleAndPhoneFields from '../forms/TitleAndPhoneFields';
import { addressSchema, userSchema } from '../forms/schemas';
import { ONBOARDING_STEPS } from '../../../store/onboarding/constants';

interface AccountAdminProps {
  userInfo: UserInfoModel;
  isLoading: boolean;
  errorText: string;
  isAuthorizingOfficial: boolean;
  completeAccountAdminInfoStep: (input: UserInfoInputModel) => void;
  updateOnboardingStep: (step: number) => void;
}

class AccountAdmin extends React.Component<AccountAdminProps>{

  onNextClick = (input: any) => {
    this.props.completeAccountAdminInfoStep({
      jobTitle: input.jobTitle,
      phone: input.phone,
      phoneExtension: input.phoneExtension,
      address: input.address
    });
  }

  onBackClick = () => {
    this.props.updateOnboardingStep(ONBOARDING_STEPS.AUTHORIZING_OFFICIAL_INVITE_ACCOUNT_ADMIN);
  }

  render () {
    const { isAuthorizingOfficial, userInfo } = this.props;
    const details = isAuthorizingOfficial ?
      'We need to collect a little more information for the Account Admin before proceeding to the next steps.' :
      'We need to collect the information for the Account Admin of the Kalderos platform. Please provide their name and contact information.';
    const name = isAuthorizingOfficial ? `${userInfo.firstName} ${userInfo.lastName}` : `${userInfo?.firstName} ${userInfo?.lastName}`;
    const email = isAuthorizingOfficial ? userInfo.email : userInfo?.email;
    const jobTitle = isAuthorizingOfficial ? userInfo.jobTitle : userInfo?.jobTitle;
    const stepNumber = isAuthorizingOfficial ? 4 : 1;
    const initialValues = {
      jobTitle: jobTitle,
      phone: userInfo?.phone,
      phoneExtension: userInfo?.phoneExtension,
      address: userInfo?.address ? userInfo?.address : {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: ''
      }
    };

    const authorizingOfficialSchema = yup.object().shape({
      address: addressSchema
    });

    const accountAdminSchema = yup.object().shape({
      ...userSchema,
      address: addressSchema
    });

    const schema = isAuthorizingOfficial ? authorizingOfficialSchema : accountAdminSchema;
    return (
      <Layout
        isLoading={this.props.isLoading}
        errorText={this.props.errorText}
        stepNumber={stepNumber}
      >
        {!this.props.isLoading && // don't show  until finished loading
          <Formik
            initialValues={initialValues}
            validateOnMount={true}
            validationSchema={schema}
            onSubmit={(values) => {
              this.onNextClick(values);
            }}
          >
            {props => {

              const validateForm = props.validateForm;
              useEffect(() => {
                validateForm();
              }, [validateForm]);

              return (
                <Form className="input-form full" onSubmit={props.handleSubmit}>
                  <div className="onboarding-layout-header">
                    <div className="title">{stepNumber}. Account Admin&apos;s Information</div>
                    <div className="sub-title">
                      {details}
                    </div>
                  </div>
                  <div className="onboarding-layout-children">
                    <div className="onboarding-layout-body dr-account-admin-info">
                      <div className="section-header">{isAuthorizingOfficial && 'Authorizing Official / '}Account Admin Information</div>
                      <div className="detail-field">
                        <div className="value" data-private>{name}</div>
                        <div className="label">NAME</div>
                      </div>
                      {isAuthorizingOfficial &&
                        <div className="detail-field">
                          <div className="value" data-private>{jobTitle}</div>
                          <div className="label">JOB TITLE</div>
                        </div>
                      }
                      <div className="detail-field">
                        <div className="value" data-private>{email}</div>
                        <div className="label">EMAIL</div>
                      </div>
                      {isAuthorizingOfficial &&
                        <div className="detail-field multiple">
                          <div>
                            <div className="value" data-private>{userInfo?.phone}</div>
                            <div className="label">PHONE</div>
                          </div>
                          {userInfo?.phoneExtension &&
                            <div className="flex-grow">
                              <div className="value" data-private>{userInfo?.phoneExtension}</div>
                              <div className="label">EXT</div>
                            </div>
                          }
                        </div>
                      }
                      {!isAuthorizingOfficial &&
                        <TitleAndPhoneFields values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched} />
                      }
                      <div className="section-header margin-top">Account Admin Address</div>
                      <AddressFields values={props.values.address} setFieldValue={props.setFieldValue} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched} />
                    </div>
                  </div>
                  <div className="onboarding-layout-footer">
                    {isAuthorizingOfficial &&
                      <div className="onboarding-layout-navigation">
                        <button type="button" className="app-button" onClick={this.onBackClick}>
                          <Icon name="chevron left" size="large" />Back
                        </button>
                      </div>
                    }
                    <div className="onboarding-layout-cta">
                      <button className="app-button button-dark" type="submit" disabled={!props.isValid || this.props.isLoading}>Next</button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        }
      </Layout>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  userInfo: state.AccountInformationState.userInfo,
  isLoading: makeSelectIsLoading(state),
  errorText: state.OnboardingState.errorText,
  isAuthorizingOfficial: makeSelectIsAuthorizingOfficial(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  completeAccountAdminInfoStep: (input: UserInfoInputModel) => dispatch(completeAccountAdminInfoStep(input)),
  updateOnboardingStep: (step: number) => dispatch(updateOnboardingStep(step)),

});


export default connect(mapStateToProps, mapDispatchToProps)(AccountAdmin);
