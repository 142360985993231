import {
  InviteUserStepInputModel,
  TermsAndConditionsConfirmation,
  UserInfoInputModel,
  CoveredEntityInfoModel,
  AccountAdminConfirmInfoStepModel,
  CandidateHealthSystem,
  CandidateCoveredEntity,
  AoInfo,
  ControllerStatusModel,
  OnboardingStatusResponseModel,
} from './types';
import {
  RecipientOnboardingActionTypes,
  RESET_ONBOARDING_STATE,
  CHECK_ONBOARDING_STATUS,
  CHECK_ONBOARDING_STATUS_SUCCESS,
  CHECK_ONBOARDING_STATUS_FAILED,
  RESET_ONBOARDING_STATUS,
  GET_TERMS_AND_CONDITIONS,
  GET_TERMS_AND_CONDITIONS_SUCCESS,
  GET_TERMS_AND_CONDITIONS_FAILURE,
  COMPLETE_TERMS_AND_CONDITIONS_STEP,
  COMPLETE_TERMS_AND_CONDITIONS_STEP_SUCCESS,
  COMPLETE_INVITE_ACCOUNT_ADMIN_STEP,
  COMPLETE_INVITE_ACCOUNT_ADMIN_STEP_SUCCESS,
  COMPLETE_ACCOUNT_ADMIN_INFO_STEP,
  COMPLETE_ACCOUNT_ADMIN_INFO_STEP_SUCCESS,
  GET_COVERED_ENTITY_INFO,
  GET_COVERED_ENTITY_INFO_SUCCESS,
  GET_COVERED_ENTITY_INFO_FAILURE,
  GET_ACCOUNT_ADMIN_SUMMARY,
  GET_ACCOUNT_ADMIN_SUMMARY_SUCCESS,
  GET_ACCOUNT_ADMIN_SUMMARY_FAILURE,
  COMPLETE_ACCOUNT_ADMIN_CONFIRM_INFO_STEP,
  COMPLETE_ACCOUNT_ADMIN_CONFIRM_INFO_STEP_SUCCESS,
  COMPLETE_INVITE_CONTROLLER_STEP,
  COMPLETE_INVITE_CONTROLLER_STEP_SUCCESS,
  COMPLETE_CONTROLLER_INFO_STEP,
  COMPLETE_CONTROLLER_INFO_STEP_SUCCESS,
  COMPLETE_CONTROLLER_CONFIRM_INFO_STEP,
  COMPLETE_CONTROLLER_CONFIRM_INFO_STEP_SUCCESS,
  COMPLETE_CONTROLLER_BANK_INFO_STEP,
  COMPLETE_CONTROLLER_BANK_INFO_STEP_SUCCESS,
  COMPLETE_RECIPIENT_STEP_FAILURE,
  SEND_AUTHORIZING_OFFICIAL_SIGN_UP,
  SEND_AUTHORIZING_OFFICIAL_SIGN_UP_SUCCESS,
  SEND_AUTHORIZING_OFFICIAL_SIGN_UP_ERROR,
  RESET_AUTHORIZING_OFFICIAL_SIGN_UP,
  REFRESH_DATA_AFTER_ONBOARDING,
  GET_CANDIDATE_CE_LIST,
  GET_CANDIDATE_CE_LIST_SUCCESS,
  GET_CANDIDATE_CE_LIST_FAILURE,
  SET_SELECTED_HEALTH_SYSTEM,
  SET_SELECTED_ORPHAN_CES,
  SET_HEALTH_SYSTEM_NAME,
  CHECK_UNIQUE_HEALTH_SYSTEM_NAME,
  CHECK_UNIQUE_HEALTH_SYSTEM_NAME_SUCCESS,
  CHECK_UNIQUE_HEALTH_SYSTEM_NAME_FAILURE,
  GET_HEALTH_SYSTEM_ACCOUNT_ADMIN,
  GET_HEALTH_SYSTEM_ACCOUNT_ADMIN_SUCCESS,
  GET_HEALTH_SYSTEM_ACCOUNT_ADMIN_FAILURE,
  GET_CONTROLLER_STATUS,
  GET_CONTROLLER_STATUS_SUCCESS,
  GET_CONTROLLER_STATUS_FAILURE,
  UPDATE_ONBOARDING_STEP,
  UPDATE_ONBOARDING_DISMISSED_WELCOME,
  COMPLETE_ONBOARDING,
} from './constants';
import { UserInfoModel, ErrorModel } from '../applicationTypes';

export function resetOnboardingState (): RecipientOnboardingActionTypes {
  return {
    type: RESET_ONBOARDING_STATE
  }
}

export function resetOnboardingStatus (): RecipientOnboardingActionTypes {
  return {
    type: RESET_ONBOARDING_STATUS
  }
}

export function checkOnboardingStatus (healthSystemId?: number): RecipientOnboardingActionTypes {
  return {
    type: CHECK_ONBOARDING_STATUS,
    payload: healthSystemId
  }
}

export function checkOnboardingStatusSuccess (onboardingStatusModel: OnboardingStatusResponseModel): RecipientOnboardingActionTypes {
  return {
    type: CHECK_ONBOARDING_STATUS_SUCCESS,
    payload: onboardingStatusModel
  }
}

export function checkOnboardingStatusFailed (error: ErrorModel): RecipientOnboardingActionTypes {
  return {
    type: CHECK_ONBOARDING_STATUS_FAILED,
    payload: error
  }
}

export function updateOnboardingStep (step: number): RecipientOnboardingActionTypes {
  return {
    type: UPDATE_ONBOARDING_STEP,
    payload: step
  }
}

export function updateOnboardingDismissedWelcome (dismissed: boolean): RecipientOnboardingActionTypes {
  return {
    type: UPDATE_ONBOARDING_DISMISSED_WELCOME,
    payload: dismissed
  }
}

export function getTermsAndConditions (): RecipientOnboardingActionTypes {
  return {
    type: GET_TERMS_AND_CONDITIONS,
  }
}

export function getTermsAndConditionsSuccess (terms: string): RecipientOnboardingActionTypes {
  return {
    type: GET_TERMS_AND_CONDITIONS_SUCCESS,
    payload: terms
  }
}

export function getTermsAndConditionsFailure (errorModel: ErrorModel): RecipientOnboardingActionTypes {
  return {
    type: GET_TERMS_AND_CONDITIONS_FAILURE,
    payload: errorModel
  }
}

export function completeTermsAndConditionsStep (): RecipientOnboardingActionTypes {
  return {
    type: COMPLETE_TERMS_AND_CONDITIONS_STEP,
  }
}

export function completeTermsAndConditionsStepSuccess (confirmation: TermsAndConditionsConfirmation): RecipientOnboardingActionTypes {
  return {
    type: COMPLETE_TERMS_AND_CONDITIONS_STEP_SUCCESS,
    payload: confirmation
  }
}


export function completeInviteAccountAdminStep (input: InviteUserStepInputModel): RecipientOnboardingActionTypes {
  return {
    type: COMPLETE_INVITE_ACCOUNT_ADMIN_STEP,
    payload: input
  }
}

export function completeInviteAccountAdminStepSuccess (): RecipientOnboardingActionTypes {
  return {
    type: COMPLETE_INVITE_ACCOUNT_ADMIN_STEP_SUCCESS,
  }
}


export function completeAccountAdminInfoStep (input: UserInfoInputModel): RecipientOnboardingActionTypes {
  return {
    type: COMPLETE_ACCOUNT_ADMIN_INFO_STEP,
    payload: input
  }
}

export function completeAccountAdminInfoStepSuccess (): RecipientOnboardingActionTypes {
  return {
    type: COMPLETE_ACCOUNT_ADMIN_INFO_STEP_SUCCESS,
  }
}

export function getCoveredEntityInfo (): RecipientOnboardingActionTypes {
  return {
    type: GET_COVERED_ENTITY_INFO
  }
}

export function getCoveredEntityInfoSuccess (response: CoveredEntityInfoModel[]): RecipientOnboardingActionTypes {
  return {
    type: GET_COVERED_ENTITY_INFO_SUCCESS,
    payload: response
  }
}

export function getCoveredEntityInfoFailure (errorModel: ErrorModel): RecipientOnboardingActionTypes {
  return {
    type: GET_COVERED_ENTITY_INFO_FAILURE,
    payload: errorModel
  }
}


export function getAccountAdminSummary (): RecipientOnboardingActionTypes {
  return {
    type: GET_ACCOUNT_ADMIN_SUMMARY
  }
}

export function getAccountAdminSummarySuccess (response: AccountAdminConfirmInfoStepModel): RecipientOnboardingActionTypes {
  return {
    type: GET_ACCOUNT_ADMIN_SUMMARY_SUCCESS,
    payload: response
  }
}

export function getAccountAdminSummaryFailure (errorModel: ErrorModel): RecipientOnboardingActionTypes {
  return {
    type: GET_ACCOUNT_ADMIN_SUMMARY_FAILURE,
    payload: errorModel
  }
}

export function completeAccountAdminConfirmInfoStep (): RecipientOnboardingActionTypes {
  return {
    type: COMPLETE_ACCOUNT_ADMIN_CONFIRM_INFO_STEP
  }
}

export function completeAccountAdminConfirmInfoStepSuccess (): RecipientOnboardingActionTypes {
  return {
    type: COMPLETE_ACCOUNT_ADMIN_CONFIRM_INFO_STEP_SUCCESS,
  }
}

export function completeInviteControllerStep (input: InviteUserStepInputModel): RecipientOnboardingActionTypes {
  return {
    type: COMPLETE_INVITE_CONTROLLER_STEP,
    payload: input
  }
}

export function completeInviteControllerStepSuccess (): RecipientOnboardingActionTypes {
  return {
    type: COMPLETE_INVITE_CONTROLLER_STEP_SUCCESS,
  }
}

export function completeControllerInfoStep (input: UserInfoInputModel): RecipientOnboardingActionTypes {
  return {
    type: COMPLETE_CONTROLLER_INFO_STEP,
    payload: input
  }
}

export function completeControllerInfoStepSuccess (): RecipientOnboardingActionTypes {
  return {
    type: COMPLETE_CONTROLLER_INFO_STEP_SUCCESS,
  }
}

export function completeControllerConfirmInfoStep (): RecipientOnboardingActionTypes {
  return {
    type: COMPLETE_CONTROLLER_CONFIRM_INFO_STEP
  }
}

export function completeControllerConfirmInfoStepSuccess (): RecipientOnboardingActionTypes {
  return {
    type: COMPLETE_CONTROLLER_CONFIRM_INFO_STEP_SUCCESS,
  }
}

export function completeControllerBankInfoStep (): RecipientOnboardingActionTypes {
  return {
    type: COMPLETE_CONTROLLER_BANK_INFO_STEP
  }
}

export function completeControllerBankInfoStepSuccess (): RecipientOnboardingActionTypes {
  return {
    type: COMPLETE_CONTROLLER_BANK_INFO_STEP_SUCCESS,
  }
}

export function completeRecipientStepFailure (errorModel: ErrorModel): RecipientOnboardingActionTypes {
  return {
    type: COMPLETE_RECIPIENT_STEP_FAILURE,
    payload: errorModel
  }
}

export function sendRequestForAuthorizingOfficialSignUp (email: string): RecipientOnboardingActionTypes {
  return {
    type: SEND_AUTHORIZING_OFFICIAL_SIGN_UP,
    payload: email
  }
}

export function sendRequestForAuthorizingOfficialSignUpSuccess (): RecipientOnboardingActionTypes {
  return {
    type: SEND_AUTHORIZING_OFFICIAL_SIGN_UP_SUCCESS,
  }
}

export function sendRequestForAuthorizingOfficialSignUpError (errorModel: ErrorModel): RecipientOnboardingActionTypes {
  return {
    type: SEND_AUTHORIZING_OFFICIAL_SIGN_UP_ERROR,
    payload: errorModel
  }
}

export function resetAuthorizingOfficialSignUp (): RecipientOnboardingActionTypes {
  return {
    type: RESET_AUTHORIZING_OFFICIAL_SIGN_UP,
  }
}

export function refreshDataAfterOnboarding (): RecipientOnboardingActionTypes {
  return {
    type: REFRESH_DATA_AFTER_ONBOARDING,
  }
}


export function getCandidateCeList (): RecipientOnboardingActionTypes {
  return {
    type: GET_CANDIDATE_CE_LIST
  }
}

export function getCandidateCeListSuccess (aoInfo: AoInfo, candidateHealthSystems: CandidateHealthSystem[], orphanedCes: CandidateCoveredEntity[]): RecipientOnboardingActionTypes {
  return {
    type: GET_CANDIDATE_CE_LIST_SUCCESS,
    payload: {
      aoInfo: aoInfo,
      candidateHealthSystems: candidateHealthSystems,
      orphanedCes: orphanedCes
    }
  }
}

export function getCandidateCeListFailure (errorModel: ErrorModel): RecipientOnboardingActionTypes {
  return {
    type: GET_CANDIDATE_CE_LIST_FAILURE,
    payload: errorModel
  }
}

export function setSelectedHealthSystem (selectedHealthSystem: CandidateHealthSystem): RecipientOnboardingActionTypes {
  return {
    type: SET_SELECTED_HEALTH_SYSTEM,
    payload: selectedHealthSystem
  }
}

export function setSelectedOrphanCes (selectedOrphans: CandidateCoveredEntity[]): RecipientOnboardingActionTypes {
  return {
    type: SET_SELECTED_ORPHAN_CES,
    payload: selectedOrphans
  }
}
export function setHealthSystemName (name: string): RecipientOnboardingActionTypes {
  return {
    type: SET_HEALTH_SYSTEM_NAME,
    payload: name
  }
}

export function checkUniqueHealthSystemName (input: string): RecipientOnboardingActionTypes {
  return {
    type: CHECK_UNIQUE_HEALTH_SYSTEM_NAME,
    payload: input
  }
}

export function checkUniqueHealthSystemNameSuccess (response: boolean): RecipientOnboardingActionTypes {
  return {
    type: CHECK_UNIQUE_HEALTH_SYSTEM_NAME_SUCCESS,
    payload: response
  }
}

export function checkUniqueHealthSystemNameFailure (errorModel: ErrorModel): RecipientOnboardingActionTypes {
  return {
    type: CHECK_UNIQUE_HEALTH_SYSTEM_NAME_FAILURE,
    payload: errorModel
  }
}

export function getHealthSystemAccountAdmin (): RecipientOnboardingActionTypes {
  return {
    type: GET_HEALTH_SYSTEM_ACCOUNT_ADMIN
  }
}

export function getHealthSystemAccountAdminSuccess (response: { hasAccountAdmin: boolean, accountAdminInfo: UserInfoModel }): RecipientOnboardingActionTypes {
  return {
    type: GET_HEALTH_SYSTEM_ACCOUNT_ADMIN_SUCCESS,
    payload: response
  }
}

export function getHealthSystemAccountAdminFailure (errorModel: ErrorModel): RecipientOnboardingActionTypes {
  return {
    type: GET_HEALTH_SYSTEM_ACCOUNT_ADMIN_FAILURE,
    payload: errorModel
  }
}

export function getControllerStatus (): RecipientOnboardingActionTypes {
  return {
    type: GET_CONTROLLER_STATUS,
  }
}

export function getControllerStatusSuccess (response: ControllerStatusModel): RecipientOnboardingActionTypes {
  return {
    type: GET_CONTROLLER_STATUS_SUCCESS,
    payload: response
  }
}

export function getControllerStatusFailure (errorModel: ErrorModel): RecipientOnboardingActionTypes {
  return {
    type: GET_CONTROLLER_STATUS_FAILURE,
    payload: errorModel
  }
}

export function completeOnboarding () {
  return {
    type: COMPLETE_ONBOARDING
  }
}