import { ROLES } from '../../store/applicationConstants';

export const allSteps = [
  {
    // Covered Entity Authorizing Official
    roleName: ROLES.RECIPIENT_AUTHORIZING_OFFICIAL,
    inviteMessage: '',
    steps: [    
      'Associate Covered Entities & Health Systems',
      'e-Sign Terms and Conditions',
      'Invite Account Admin'
    ]
  },
  {
    // Covered Entity Account Admin
    roleName: ROLES.RECIPIENT_ACCOUNT_ADMIN,
    inviteMessage: "You've been invited to sign up as an Account Admin for your company",
    steps: [
      'Account Admin information',
      'Confirm information',
      'Add bank account'
    ]
  },
  {
    // Covered Entity Financial Setup
    roleName: ROLES.RECIPIENT_CONTROLLER,
    inviteMessage: "You've been invited to sign up and provide the bank information needed for your company",
    steps: [
      'Your contact information',
      'Confirm your information',
      'Add bank information'
    ]
  }
];
