import React from 'react';

export interface SummaryItemProps {
  title: string;
  value: number | string;
}

export const SummaryItem: React.FunctionComponent<SummaryItemProps> = (props: SummaryItemProps) => {
  return (
    <div className="summary-item-container">
      <div className='summary-item-title'>{props.title}</div>
      <div data-private className='summary-item-value'>{props.value}</div>
    </div>
  );
}

export default SummaryItem;