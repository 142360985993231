import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Checkbox, Form, Icon } from 'semantic-ui-react';
import { Formik } from 'formik';

import Layout from '../Layout';
import { RootState } from '../../../store/RootState';
import { UserInfoModel } from '../../../store/applicationTypes';
import { makeSelectIsAuthorizingOfficial } from '../../../store/onboarding/selectors';
import { completeInviteControllerStep, getControllerStatus, updateOnboardingStep } from '../../../store/onboarding/actionCreators';
import { ControllerStatusModel, InviteUserStepInputModel } from '../../../store/onboarding/types';

import '../../../themes/kalderos/Onboarding/Recipient/InviteController.scss'
import NameAndEmailFields from '../forms/NameAndEmailFields';
import { basicSchema } from '../forms/schemas';
import GoToRequestFooter from './GoToRequestFooter';
import { ONBOARDING_STEPS } from '../../../store/onboarding/constants';

interface InviteControllerProps {
  adminInfo: UserInfoModel;
  isAuthorizingOfficial: boolean;
  isEmailSent: boolean;
  isLoading: boolean;
  errorText: string;
  controllerStatus: ControllerStatusModel;
  completeInviteControllerStepRequest: (input: InviteUserStepInputModel) => void;
  getControllerStatus: () => void;
  updateOnboardingStep: (step: number) => void;
}

interface InviteControllerState {
  isController: boolean,
  isDirty: boolean,
}

class InviteController extends React.Component<InviteControllerProps, InviteControllerState>{
  constructor (props: InviteControllerProps) {
    super(props);
    this.state = {
      isController: false,
      isDirty: false,
    }
  }

  componentDidMount () {
    this.props.getControllerStatus();
  }

  componentDidUpdate () {

    if (!this.state.isDirty && this.props.controllerStatus.completed) {
      if (!this.props.controllerStatus.invited && !this.state.isController) {
        this.setState({ isController: true });
      }
    }
  }

  onControllerCheck = () => {
    const isController = !this.state.isController;
    this.setState({ isController: isController, isDirty: true });
  }

  onNextClick = () => {
    if (!this.props.controllerStatus.completed) {
      this.props.completeInviteControllerStepRequest({ addRoleToCurrentUser: true })
    } else if (this.state.isController) {
      this.props.updateOnboardingStep(ONBOARDING_STEPS.CONTROLLER_BANK_INFO);
    }
  }

  onBackClick = () => {
    this.props.updateOnboardingStep(ONBOARDING_STEPS.ACCOUNT_ADMIN_CONFIRM_INFO);
  }

  onSendEmailClick = (input: InviteUserStepInputModel) => {
    const inputCopy: InviteUserStepInputModel = {
      ...input,
      removeRoleFromCurrentUser: true
    };
    this.props.completeInviteControllerStepRequest(inputCopy)
  }

  render () {
    const { isAuthorizingOfficial, controllerStatus, adminInfo } = this.props;
    const { isController } = this.state;
    const stepNumber = isAuthorizingOfficial ? 6 : 3;

    const initialValues = {
      firstName: '',
      lastName: '',
      email: '',
      addRoleToCurrentUser: isController
    };

    return (
      <Layout
        isLoading={this.props.isLoading}
        errorText={this.props.errorText}
        stepNumber={stepNumber}
      >
        {!this.props.isEmailSent &&
          <Fragment>
            <div className="onboarding-layout-header">
              <div className="title">{stepNumber}. Add bank account</div>
              <div className="sub-title">
                We need the login information for the bank account you wish to be linked with this account. Payments will be processed through our trusted third-party data partner, Dwolla. Kalderos does not store your financial information.
              </div>
            </div>
            <div className="onboarding-layout-children">
              <div className="onboarding-layout-body invite-controller">
                {controllerStatus.invited &&
                  <Fragment>
                    <div className="controller-info">A controller has already been invited for your company.</div>
                  </Fragment>
                }
                {!controllerStatus.invited &&
                  <Fragment>
                    <div className="controller-title">
                      Can you provide the financial information? If not, please provide the contact info for the relevant person at your company.
                    </div>
                    <div className="onboarding-checkbox controller-checkbox">
                      <Checkbox data-private label="I can provide the financial information needed for my company."
                        checked={isController}
                        onClick={() => this.onControllerCheck()}
                      />
                    </div>
                  </Fragment>
                }
                <div className="controller-info bold">Controller&apos;s Information</div>
                {!this.state.isController &&
                  <Formik
                    initialValues={initialValues}
                    validateOnMount={true}
                    validationSchema={yup.object().shape({ ...basicSchema })}
                    onSubmit={(values) => { this.onSendEmailClick(values) }}
                  >
                    {props => (
                      <Form className="input-form" onSubmit={props.handleSubmit}>
                        <NameAndEmailFields values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} includeEmail errors={props.errors} touched={props.touched} />
                        <button className="app-button button-dark" type="submit" disabled={this.state.isController || !props.isValid || !props.dirty}>Send Email</button>
                      </Form>
                    )}
                  </Formik>
                }

                {this.state.isController &&
                  <Fragment>
                    <div className="detail-field">
                      <div className="value" data-private>{adminInfo?.firstName} {adminInfo?.lastName}</div>
                      <div className="label">NAME</div>
                    </div>
                    <div className="detail-field">
                      <div className="value" data-private>{adminInfo?.email}</div>
                      <div className="label">EMAIL</div>
                    </div>
                  </Fragment>
                }

                {controllerStatus.invited &&
                  <Fragment>
                    <div className="detail-field">
                      <div className="value" data-private>{controllerStatus.controllerInfo?.firstName} {controllerStatus.controllerInfo?.lastName}</div>
                      <div className="label">NAME</div>
                    </div>
                    <div className="detail-field">
                      <div className="value" data-private>{controllerStatus.controllerInfo?.email}</div>
                      <div className="label">EMAIL</div>
                    </div>
                  </Fragment>
                }

              </div>
            </div>
            <div className="onboarding-layout-footer">
              <div className="onboarding-layout-navigation">
                <button type="button" className="app-button" onClick={this.onBackClick}>
                  <Icon name="chevron left" size="large" />Back
                </button>
              </div>
              <div className="onboarding-layout-cta">
                <button className="app-button button-dark" onClick={this.onNextClick} disabled={!this.state.isController} type="button">Next</button>
              </div>
            </div>
          </Fragment>
        }
        {this.props.isEmailSent &&
          <Fragment>
            <div className="full-screen-message">
              <div className='checkicon-outline'><Icon name='check circle outline' size='big' className='checkicon' /></div>
              <div className="header">Colleague&apos;s Information Received</div>
              <div className="details">Thanks! We have received the information we needed. We will contact them to provide the bank information we need to finish setting up your company’s account.</div>
            </div>
            <GoToRequestFooter />
          </Fragment>
        }

      </Layout >
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  adminInfo: state.AccountInformationState.userInfo,
  isAuthorizingOfficial: makeSelectIsAuthorizingOfficial(state),
  isEmailSent: state.OnboardingState.inviteControllerStepModel.isEmailSent,
  isLoading: state.OnboardingState.isLoading,
  errorText: state.OnboardingState.errorText,
  controllerStatus: state.OnboardingState.controllerStatusModel,
})

const mapDispatchToProps = (dispatch: any) => ({
  completeInviteControllerStepRequest: (input: InviteUserStepInputModel) => dispatch(completeInviteControllerStep(input)),
  getControllerStatus: () => dispatch(getControllerStatus()),
  updateOnboardingStep: (step: number) => dispatch(updateOnboardingStep(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteController);
