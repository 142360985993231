import React from 'react';
import { Link } from 'react-router-dom';

import '../../../themes/kalderos/Common/Errors/ErrorImage.scss';

interface ErrorImageProps {
  header: string;
  image: string;
  link?: string;
  linkText?: string;
  message: string;
}

const ErrorImage = (props: ErrorImageProps) => {
  const { header, image, link, linkText, message } = props;

  return (
    <div className="error-image">
      <img src={image} alt="error" />
      <p className="error-image-header">{header}</p>
      <div className="error-image-message-container">
        <p>{message}</p>
      </div>
      {link && (
        <div className="error-image-link-container"> 
          <Link className="app-link blue-link" to={link}>
            {linkText}
          </Link>
        </div>
      )}
    </div>
  );
};

export default ErrorImage;