import React from 'react';
import { connect } from 'react-redux'
import { retryPayments } from '../../../store/payments/recipient/actionCreators';

interface RetryPaymentsProps {
  retryPayments: () => void
}

class RetryPayments extends React.Component<RetryPaymentsProps>{

  render () {
    return (
      <button 
        className="app-button button-dark mini-button retry-payments" 
        onClick={() => this.props.retryPayments()}
        type="button">
        Retry Payment
      </button>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  retryPayments: () => dispatch(retryPayments())
});

export default connect(null, mapDispatchToProps)(RetryPayments);