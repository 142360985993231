import { ErrorModel } from '../applicationTypes';
import { ContractElementsModel } from './types';


export const TRANSACTION_FILTER_LABELS: { [key: string]: string } = {
  claimTypeText: 'Medical/Retail',
  ndc11: 'NDC11',
  fillStartDate: 'Fill Date Start',
  fillEndDate: 'Fill Date End',
  pharmacyNpi: 'Pharmacy NPI',
  rxNumber: 'Rx Number',
  adjustmentReasons: 'Adjustment Code',
  failureReasons: 'Failure Code',
  idCode: '340B ID',
}

export const REVERSE_TRANSACTIONS = 'REVERSE_TRANSACTIONS';
export const REVERSE_TRANSACTIONS_SUCCESS = 'REVERSE_TRANSACTIONS_SUCCESS';
export const REVERSE_TRANSACTIONS_FAILURE = 'REVERSE_TRANSACTIONS_FAILURE';

export const GET_CONTRACT_ELEMENTS = 'GET_CONTRACT_ELEMENTS';
export const GET_CONTRACT_ELEMENTS_SUCCESS = 'GET_CONTRACT_ELEMENTS_SUCCESS';
export const GET_CONTRACT_ELEMENTS_FAILURE = 'GET_CONTRACT_ELEMENTS_FAILURE';

export const TRANSACTION_STATUS_TEXT = {
  FAILED: 'Failed',
  IN_MFR_REVIEW: 'In Mfr Review',
  NULL_REVERSAL: 'Null Reversal',
  PAID: 'Paid',
  PENDING_REVERSAL: 'Pending Reversal',
  PROCESSING: 'Processing',
  REVERSED: 'Reversed',
  REVIEW: 'Review',
  SUBMITTED: 'Submitted'
}

interface ReverseTransactionsAction {
  type: typeof REVERSE_TRANSACTIONS,
  payload: number[]
}

interface ReverseTransactionsSuccessAction {
  type: typeof REVERSE_TRANSACTIONS_SUCCESS,
}

interface ReverseTransactionsFailureAction {
  type: typeof REVERSE_TRANSACTIONS_FAILURE,
  payload: ErrorModel
}

interface GetContractElementsAction {
  type: typeof GET_CONTRACT_ELEMENTS,
}

interface GetContractElementsSuccessAction {
  type: typeof GET_CONTRACT_ELEMENTS_SUCCESS,
  payload: ContractElementsModel
}

interface GetContractElementsFailureAction {
  type: typeof GET_CONTRACT_ELEMENTS_FAILURE,
  payload: ErrorModel
}

export type TransactionsCommonActionTypes =
  ReverseTransactionsAction |
  ReverseTransactionsSuccessAction |
  ReverseTransactionsFailureAction |
  GetContractElementsAction |
  GetContractElementsSuccessAction |
  GetContractElementsFailureAction;
