import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import AccountDrawer from '../../account/AccountDrawer';
import { connect } from 'react-redux';
import { RootState } from '../../../store/RootState';
import { UserModel } from '../../../store/login/types';
import { ZendeskAPI } from 'react-zendesk';
import { Button } from 'semantic-ui-react';
import { HelpIcon } from '../../../themes/kalderos/Common/Icons';

const NO_RIGHT_NAV_PATHS = [
  '/login/',
  '/welcome/',
  '/acceptInvite',
  '/forgotpassword',
  '/expiredpassword',
  '/initialLogin',
  '/authorizingofficialsignup',
  '/onboarding',
  '/signuperror'
];

export interface RightNavigationProps extends RouteComponentProps {
  user: UserModel;
}

export class RightNavigation extends React.Component<RightNavigationProps> {
  componentDidMount () {
    ZendeskAPI('webWidget', 'helpCenter:setSuggestions', { search: 'request' })
  }
  
  render () {
    const isHidden = this.props.location.pathname === '/'  || NO_RIGHT_NAV_PATHS.some(p => this.props.location.pathname.includes(p));
    if (!this.props.user.isAuthenticated || isHidden) return null;
    return (
      <div className='right-navigation flex-column flex-grow'>
        <div className='right-navigation-menu flex-column flex-grow scroll-hidden v-scroll-auto'>
          <div className="flex-column flex-center flex-grow justify-center flex-noshrink">
            <AccountDrawer />
            <span className="flex-grow"></span>
            <Button className="help-button flex-column zero-padding flex-center" onClick={() => ZendeskAPI('webWidget', 'open')}>
              <HelpIcon />
              <span>Help</span>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.LoginState.user
});

export default withRouter(connect(mapStateToProps)(RightNavigation));
