import {
  CLEAR_MANUFACTURER_DISPUTES,
  DisputesCenterMdrpActionTypes,
  GET_DISPUTES_CENTER_SUMMARY,
  GET_DISPUTES_CENTER_SUMMARY_FAILURE,
  GET_DISPUTES_CENTER_SUMMARY_SUCCESS,
  GET_MANUFACTURER_DISPUTES,
  GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS,
  GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS_FAILURE,
  GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS_SUCCESS,
  GET_MANUFACTURER_DISPUTES_FAILURE,
  GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS,
  GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS_FAILURE,
  GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS_SUCCESS,
  GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS,
  GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS_FAILURE,
  GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS_SUCCESS,
  GET_MANUFACTURER_DISPUTES_SUCCESS,
  GET_MANUFACTURER_SUMMARIES,
  GET_MANUFACTURER_SUMMARIES_FAILURE,
  GET_MANUFACTURER_SUMMARIES_SUCCESS,
  GET_MANUFACTURER_SUMMARY,
  GET_MANUFACTURER_SUMMARY_FAILURE,
  GET_MANUFACTURER_SUMMARY_SUCCESS,
  OPEN_DISPUTES_TAB,
  UPDATE_FILTERS,
  UPDATE_MANUFACTURER_DISPUTES_FILTERS,
  UPDATE_MANUFACTURER_DISPUTES_PAGE_NUMBER,
  UPDATE_MANUFACTURER_DISPUTES_SELECTED_CLAIM,
  UPDATE_MANUFACTURER_DISPUTES_SORT,
  UPDATE_SINGLE_CLAIM_RESPONSE,
  UPDATE_SINGLE_CLAIM_RESPONSE_FAILURE,
  UPDATE_SINGLE_CLAIM_RESPONSE_SUCCESS
} from './constants';
import { DisputesCenterModel, DisputesCenterHomeModel, DisputesReviewModel, ManufacturerSummaryModel, ManufacturerDisputesFilterModel, ManufacturerDisputesFilterOptionsModel, ClaimModel, ResponseTotalsModel } from './types';

const manufacturerSummaryDefaultState: ManufacturerSummaryModel = {
  businessId: 0,
  labelerCount: 0,
  labelers: [],
  name: '',
  ndcCount: 0,
  totalAmount: 0,
  totalClaims: 0
}

const manufacturerDisputesFilterDefaultState: ManufacturerDisputesFilterModel = {
  icn: '',
  labeler: null,
  // manualDisputeCode: null,
  maxDateOfService: '',
  minDateOfService: '',
  ndc: null,
  pharmacyNpi: null,
  productName: null,
  programName: null,
  rebateQtr: null,
  rxNumber: ''
}

const manufacturerDisputesFilterOptionsDefaultState: ManufacturerDisputesFilterOptionsModel = {
  advancedFilterOptions: {
    manualDisputeCodes: [],
    pharmacyNpis: [],
    productNames: [],
    programNames: [],
  },
  primaryFilterOptions: {
    labelers: [],
    rebateQtrs: []
  },
  isLoading: false,
  errorText: ''
}

const disputesCenterHomeDefaultState: DisputesCenterHomeModel = {
  disputesSummary: {
    openDisputedClaims: 0,
    totalImpasseAmount: 0,
    totalNewDisputeAmount: 0,
    totalSentToManufacturerAmount: 0,
    isLoading: false,
    errorText: ''
  },
  manufacturerSummaries: {
    manufacturers: [],
    isLoading: false,
    errorText: ''
  }
}

const disputeResponseTotalsDefaultState: ResponseTotalsModel = {
  totalAccepted: 0,
  totalPendingManufacturerReview: 0
}

const disputesReviewDefaultState: DisputesReviewModel = {
  manufacturerDisputes: {
    activeTabIndex: OPEN_DISPUTES_TAB.NEW,
    currentPage: 1,
    claims: [],
    errorText: '',
    isLoading: false,
    isSortAsc: false,
    page: 1,
    responseTotals: disputeResponseTotalsDefaultState,
    selectedClaimIndex: 0,
    sortBy: 'rebateQtr',
    totalCount: 0,
    updateClaimStatusErrorText: '',
  },
  manufacturerDisputesFilters: {
    newDisputes: manufacturerDisputesFilterDefaultState,
    sentToMfr: manufacturerDisputesFilterDefaultState,
    impasse: manufacturerDisputesFilterDefaultState
  },
  manufacturerDisputesFilterOptions: manufacturerDisputesFilterOptionsDefaultState,
  manufacturerSummary: {
    manufacturer: manufacturerSummaryDefaultState,
    isLoading: false,
    errorText: ''
  }
}

const initialState: DisputesCenterModel = {
  disputesCenterHome: disputesCenterHomeDefaultState,
  disputesReview: disputesReviewDefaultState
}

export function disputesCenterReducer (
  state = initialState,
  action: DisputesCenterMdrpActionTypes
): DisputesCenterModel {
  switch (action.type) {
    case GET_DISPUTES_CENTER_SUMMARY:
      return {
        ...state,
        disputesCenterHome: {
          ...state.disputesCenterHome,
          disputesSummary: {
            ...state.disputesCenterHome.disputesSummary,
            isLoading: true,
            errorText: ''
          }
        }
      }

    case GET_DISPUTES_CENTER_SUMMARY_SUCCESS:
      return {
        ...state,
        disputesCenterHome: {
          ...state.disputesCenterHome,
          disputesSummary: {
            ...action.payload,
            isLoading: false,
            errorText: ''
          }
        }
      }

    case GET_DISPUTES_CENTER_SUMMARY_FAILURE:
      return {
        ...state,
        disputesCenterHome: {
          ...state.disputesCenterHome,
          disputesSummary: {
            ...disputesCenterHomeDefaultState.disputesSummary,
            isLoading: false,
            errorText: action.payload.message
          }
        }
      }

    case GET_MANUFACTURER_SUMMARIES:
      return {
        ...state,
        disputesCenterHome: {
          ...state.disputesCenterHome,
          manufacturerSummaries: {
            ...state.disputesCenterHome.manufacturerSummaries,
            isLoading: true,
            errorText: ''
          }
        }
      }

    case GET_MANUFACTURER_SUMMARIES_SUCCESS:
      return {
        ...state,
        disputesCenterHome: {
          ...state.disputesCenterHome,
          manufacturerSummaries: {
            manufacturers: action.payload,
            isLoading: false,
            errorText: ''
          }
        }
      }

    case GET_MANUFACTURER_SUMMARIES_FAILURE:
      return {
        ...state,
        disputesCenterHome: {
          ...state.disputesCenterHome,
          manufacturerSummaries: {
            ...disputesCenterHomeDefaultState.manufacturerSummaries,
            isLoading: false,
            errorText: action.payload.message
          }
        }
      }

    case GET_MANUFACTURER_SUMMARY:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerSummary: {
            ...state.disputesReview.manufacturerSummary,
            isLoading: true,
            errorText: ''
          }
        }
      }
  
    case GET_MANUFACTURER_SUMMARY_SUCCESS:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerSummary: {
            manufacturer: action.payload,
            isLoading: false,
            errorText: ''
          }
        }
      }
  
    case GET_MANUFACTURER_SUMMARY_FAILURE:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerSummary: {
            ...disputesReviewDefaultState.manufacturerSummary,
            isLoading: false,
            errorText: action.payload.message
          }
        }
      }

    case GET_MANUFACTURER_DISPUTES:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputes: {
            ...state.disputesReview.manufacturerDisputes,
            activeTabIndex: action.payload.activeTabIndex,
            claims: [],
            errorText: '',
            isLoading: true,
          }
        }
      }
  
    case GET_MANUFACTURER_DISPUTES_SUCCESS:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputes: {
            ...state.disputesReview.manufacturerDisputes,
            claims: action.payload.claims,
            errorText: '',
            isLoading: false,
            page: action.payload.page,
            totalCount: action.payload.totalCount
          }
        }
      }
  
    case GET_MANUFACTURER_DISPUTES_FAILURE:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputes: {
            ...disputesReviewDefaultState.manufacturerDisputes,
            isLoading: false,
            errorText: action.payload.message
          }
        }
      }

    case GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputesFilterOptions: {
            ...state.disputesReview.manufacturerDisputesFilterOptions,
            isLoading: true,
            errorText: ''
          }
        }
      }

    case GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS_SUCCESS:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputesFilterOptions: {
            ...state.disputesReview.manufacturerDisputesFilterOptions,
            advancedFilterOptions: action.payload,
            isLoading: false,
            errorText: ''
          }
        }
      }

    case GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS_FAILURE:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputesFilterOptions: {
            ...disputesReviewDefaultState.manufacturerDisputesFilterOptions,
            isLoading: false,
            errorText: action.payload.message
          }
        }
      }

    case GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputesFilterOptions: {
            ...state.disputesReview.manufacturerDisputesFilterOptions,
            isLoading: true,
            errorText: ''
          }
        }
      }

    case GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS_SUCCESS:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputesFilterOptions: {
            ...state.disputesReview.manufacturerDisputesFilterOptions,
            primaryFilterOptions: action.payload,
            isLoading: false,
            errorText: ''
          }
        }
      }

    case GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS_FAILURE:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputesFilterOptions: {
            ...disputesReviewDefaultState.manufacturerDisputesFilterOptions,
            isLoading: false,
            errorText: action.payload.message
          }
        }
      }

    case UPDATE_FILTERS:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputesFilters: {
            ...state.disputesReview.manufacturerDisputesFilters,
            [action.payload.activeTab]: action.payload.filters
          }
        }
      }

    case UPDATE_MANUFACTURER_DISPUTES_FILTERS:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputes: {
            ...state.disputesReview.manufacturerDisputes,
            claims: [],
            currentPage: 1,
            errorText: '',
            isLoading: true,
            isSortAsc: false,
            sortBy: 'rebateQtr',
          },
          manufacturerDisputesFilters: {
            ...state.disputesReview.manufacturerDisputesFilters,
            [action.payload.activeTab]: action.payload.filters
          }
        }
      }

    case UPDATE_MANUFACTURER_DISPUTES_PAGE_NUMBER:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputes: {
            ...state.disputesReview.manufacturerDisputes,
            currentPage: action.payload
          }
        }
      }

    case UPDATE_MANUFACTURER_DISPUTES_SORT:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputes: {
            ...state.disputesReview.manufacturerDisputes,
            claims: [],
            currentPage: 1,
            isLoading: true,
            isSortAsc: action.payload.isSortAsc,
            page: 1,
            sortBy: action.payload.sortBy,
          }
        }
      }

    case UPDATE_MANUFACTURER_DISPUTES_SELECTED_CLAIM:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputes: {
            ...state.disputesReview.manufacturerDisputes,
            selectedClaimIndex: action.payload
          }
        }
      }

    case GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputes: {
            ...state.disputesReview.manufacturerDisputes,
            errorText: '',
          }
        }
      }
    
    case GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS_SUCCESS:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputes: {
            ...state.disputesReview.manufacturerDisputes,
            errorText: '',
            responseTotals: {
              totalAccepted: action.payload.totalAccepted,
              totalPendingManufacturerReview: action.payload.totalPendingManufacturerReview
            }
          }
        }
      }
    
    case GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS_FAILURE:
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputes: {
            ...disputesReviewDefaultState.manufacturerDisputes,
            errorText: action.payload.message
          }
        }
      }

    case UPDATE_SINGLE_CLAIM_RESPONSE:
    {
      const index = action.payload.index;
      const tempClaims = [...state.disputesReview.manufacturerDisputes.claims];
      tempClaims.forEach((claim: ClaimModel) => {
        if (claim.index === index) {
          claim.isLoading = true;
        }
      })
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputes: {
            ...state.disputesReview.manufacturerDisputes,
            updateClaimStatusErrorText: '',
            claims: tempClaims,
          }
        }
      }
    }

    case UPDATE_SINGLE_CLAIM_RESPONSE_SUCCESS:
    {
      const claimIndex = action.payload.index;
      const updatedClaim = action.payload.claim;
      const tempClaimToUpdate = [...state.disputesReview.manufacturerDisputes.claims];
      tempClaimToUpdate.splice(claimIndex, 1, updatedClaim);
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputes: {
            ...state.disputesReview.manufacturerDisputes,
            updateClaimStatusErrorText: '',
            claims: tempClaimToUpdate,
          }
        }
      }
    }

    case UPDATE_SINGLE_CLAIM_RESPONSE_FAILURE:
    {
      const index = action.payload.index;
      const tempClaims = [...state.disputesReview.manufacturerDisputes.claims];
      tempClaims.forEach((claim: ClaimModel) => {
        if (claim.index === index) {
          claim.isLoading = false;
        }
      })
      return {
        ...state,
        disputesReview: {
          ...state.disputesReview,
          manufacturerDisputes: {
            ...state.disputesReview.manufacturerDisputes,
            updateClaimStatusErrorText: action.payload.errorModel.message,
            claims: tempClaims,
          }
        }
      }
    }

    case CLEAR_MANUFACTURER_DISPUTES:
      return {
        ...state,
        disputesReview: {
          ...disputesReviewDefaultState
        }
      }

    default:
      return state
  }
}
