import React from 'react';
import { Redirect } from 'react-router';


const ManageUserExpiry = ({ message }: { message: any }) => {
  if (message.length === 0) return null;
  if (message.message === 'The submitted password has expired') {
    return <Redirect to="/expiredpassword" />
  } else {
    return <Redirect to="/logout" />
  }
}

export default ManageUserExpiry;
