import * as React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { RemittancePeriodSummary, RemittancePeriodSummaryDetail } from '../../../store/remittance/types';
import { formatDollar, formatPositiveDollarToNegative } from '../../../utils/numberFormatHelper';

export const RemittanceInvoiceDetailRow: React.FunctionComponent<{ detail: RemittancePeriodSummaryDetail, showRemittanceNumber: boolean }> = ({ detail, showRemittanceNumber }) => {
  return (
    <div className="flex-row">
      <div className="flex-column manufacturer-item-first manufacturer-name">
        <div className="beta data">{detail.manufacturerName}</div>
        {(detail.remittanceNumber && showRemittanceNumber) &&
          <div className="manufacturer-item-label eta data manufacturer-sub-item">{'Remittance ID: ' + detail.remittanceNumber}</div>
        }
      </div>
      <div className={detail.amountFailed > 0 ? 'flex-column manufacturer-item-no-left-border' : 'flex-column manufacturer-item-no-left-border'}>
        <div className="epsilon data">{formatDollar(detail.amountFailed)}</div>
        <div className="manufacturer-item-label eta label">Net Amount Failed</div>
      </div>
      <div className={detail.amountPaid > 0 ? 'manufacturer-item' : 'manufacturer-item'}>
        <div className="epsilon data">{formatDollar(detail.amountPaid)}</div>
        <div className="manufacturer-item-label eta label">Net Amount Paid</div>
      </div>
      <div className={detail.amountReversed > 0 ? 'flex-column manufacturer-item' : 'flex-column manufacturer-item'}>
        <div className="epsilon data">{formatPositiveDollarToNegative(detail.amountReversed)}</div>
        <div className="manufacturer-item-label eta label">Net Amount Reversed</div>
      </div>
    </div>
  );
}

export interface RemittanceManufacturerFilterProps {
  period: RemittancePeriodSummary;
  selectedCoveredEntity: string;
  selectedRemittanceId: number;
  onFilterChange: (remittanceId: number) => void;
}

const RemittanceManufacturerFilter: React.FunctionComponent<RemittanceManufacturerFilterProps> = (props) => {
  const { period, selectedRemittanceId, selectedCoveredEntity, onFilterChange } = props;

  const getAllDetails = (): RemittancePeriodSummaryDetail[] => {
    const total: RemittancePeriodSummaryDetail = {
      manufacturerName: 'All Manufacturers',
      remittanceId: 0,
      remittanceNumber: '',
      amountPaid: period.netPaid,
      amountFailed: period.netFailed,
      amountReversed: period.netReversed,
      creditsGenerated: period.netCreditsGenerated,
      creditsUsed: period.netCreditsUsed,
    };
    const sums = period.remittanceIds.map(id => {
      const option: RemittancePeriodSummaryDetail = {
        manufacturerName: '',
        remittanceNumber: id.toString(),
        remittanceId: id,
        amountPaid: 0,
        amountFailed: 0,
        amountReversed: 0,
        creditsGenerated: 0,
        creditsUsed: 0,
      };
      period.remittanceCoveredEntitySummaryDetails.forEach(ce => {
        const detail = ce.remittanceSummaryDetails.find(d => d.remittanceId === id);
        if (detail) {
          option.amountPaid += detail.amountPaid;
          option.amountFailed += detail.amountFailed;
          option.amountReversed += detail.amountReversed;
          option.creditsGenerated += detail.creditsGenerated;
          option.creditsUsed += detail.creditsUsed;
          option.manufacturerName = detail.manufacturerName;
        }
      })
      return option;
    });

    return [total, ...sums];
  }

  const getCoveredEntitySpecificDetails = (coveredEntityId: string): RemittancePeriodSummaryDetail[] => {
    const ceSummary = period.remittanceCoveredEntitySummaryDetails.find(d => d.coveredEntityId === coveredEntityId);
    if (ceSummary) {
      return [
        {
          manufacturerName: 'All Manufacturers',
          remittanceId: 0,
          remittanceNumber: '',
          amountPaid: ceSummary.netPaid,
          amountFailed: ceSummary.netFailed,
          amountReversed: ceSummary.netReversed,
          creditsGenerated: ceSummary.netCreditsGenerated,
          creditsUsed: ceSummary.netCreditsUsed,
        },
        ...ceSummary.remittanceSummaryDetails
      ]
    }

    return [];
  }


  const getOptions = () => {
    if (selectedCoveredEntity && selectedCoveredEntity !== 'ALL') {
      return getCoveredEntitySpecificDetails(selectedCoveredEntity).map((detail) => ({
        key: detail.remittanceId,
        value: detail.remittanceId,
        text: <RemittanceInvoiceDetailRow detail={detail} showRemittanceNumber />
      }));
    } else {
      return getAllDetails().map((detail) => ({
        key: detail.remittanceId,
        value: detail.remittanceId,
        text: <RemittanceInvoiceDetailRow detail={detail} showRemittanceNumber={false} />
      }));
    }
  }

  return (
    <div className='manufacturer-filter'>
      <div className='zeta title'>Manufacturer Filter</div>
      <Dropdown
        scrolling
        selection
        className='manufacturer-filter-dropdown remittanceDetailManufacturerFilter_Pendo'
        icon={'chevron down'}
        onChange={(e, d) => onFilterChange(d.value as number)}
        options={getOptions()}
        value={selectedRemittanceId}
      />
    </div>)
}

export default RemittanceManufacturerFilter;
