import React, { useState } from 'react';
import { ArrowRight, ChevronDown, ChevronUp } from 'react-feather';

import CardItem from '../../../common/card/CardItem';
import ExpandableCard from '../../../common/card/ExpandableCard';
import { LabelerModel, ManufacturerSummaryModel } from '../../../../store/mdrp/disputescenter/types';
import '../../../../themes/kalderos/MDRP/DisputesCenter/ManufacturerSummaryCard.scss';
import { formatEmpty } from '../../../../utils/dataUtil';
import { formatDollar } from '../../../../utils/numberFormatHelper';


export interface ManufacturerSummaryCardProps {
  manufacturer: ManufacturerSummaryModel;
  onViewClick: (manufacturerCode: number, labelerCode?: string) => void;
}

const ManufacturerSummaryCard = (props: ManufacturerSummaryCardProps) => {
  const { manufacturer, onViewClick } = props;

  const [isCardExpanded, setIsCardExpanded] = useState<boolean>(false);

  const renderCardCaret = () => {
    if (isCardExpanded) {
      return <ChevronUp size={22} />
    } else {
      return <ChevronDown size={22} />
    }
  };

  const handleButtonClick = (e: React.MouseEvent, labelerCode?: number) => {
    e.stopPropagation();
    if (labelerCode) {
      onViewClick(manufacturer.businessId, labelerCode.toString());
    } else  {
      onViewClick(manufacturer.businessId);
    }
  };

  return (
    <ExpandableCard
      isCardExpanded={isCardExpanded}
      onClick={() => setIsCardExpanded(!isCardExpanded)}
      title={
        <div className="mfr-summary-card">
          <div className="mfr-summary-card-left-container">
            <div className="mfr-summary-card-header">{formatEmpty(manufacturer.name)}</div>
            <div className="mfr-summary-card-items">
              <CardItem label="Labelers" value={manufacturer.labelerCount} />
              <CardItem label="NDCs" value={manufacturer.ndcCount} />
              <CardItem label="Total Claims" value={manufacturer.totalClaims} />
              <CardItem label="Total Dispute Amount" value={formatDollar(manufacturer.totalAmount)} />
            </div>
          </div>
          <div className="mfr-summary-card-right-container">
            <button
              className="app-button button-white mfr-summary-card-button view-content"
              onClick={handleButtonClick} 
              type="button">
              View Claims
              <ArrowRight size={16} />
            </button>
            {renderCardCaret()}
          </div>
        </div>
      }
      content={
        manufacturer.labelers.map((labeler: LabelerModel, index) => {
          return (
            <button className="mfr-summary-card sub-row" key={index} onClick={(e) => handleButtonClick(e, labeler.labelerCode)} type="button">
              <div className="mfr-summary-card-left-container">
                <div className="mfr-summary-card-items">
                  <CardItem isSubItem value={labeler.labelerCode} />
                  <CardItem isSubItem label="NDCs" value={labeler.ndcCount} />
                  <CardItem isSubItem label="Claims" value={labeler.claimsCount} />
                  <CardItem isSubItem label="Total Dispute Amount" value={formatDollar(labeler.totalAmount)} />
                </div>
              </div>
              <div className="mfr-summary-card-right-container">
                <div className="sub-row-view">
                  View by Labeler
                  <ArrowRight size={16} />
                </div>
              </div>
            </button>
          )
        })
      }
    />
  )
};

export default ManufacturerSummaryCard;