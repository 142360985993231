import React from 'react';
import { Formik } from 'formik';
import { Form } from 'semantic-ui-react'
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import moment from 'moment';
import { Moment } from 'moment-timezone';
import { ArrowRight } from 'react-feather';

interface ReversalsDateRangeFilterProps {
  startDate: string;
  endDate: string;
  applyFilter: (startDate: Date, endDate: Date) => void;
}

interface ReversalsDateRangeFilterState {
  focusedInput: 'startDate' | 'endDate' | null;
}

interface ReversalsDateRangeFilterModel {
  startDate: Date;
  endDate: Date;
}

class ReversalsDateRangeFilter extends React.Component<ReversalsDateRangeFilterProps, ReversalsDateRangeFilterState>{
  constructor (props: Readonly<ReversalsDateRangeFilterProps>) {
    super(props);
    this.state = {
      focusedInput: null
    }
  }

  handleSubmit = (values: ReversalsDateRangeFilterModel) => {
    this.props.applyFilter(values.startDate, values.endDate);
  }

  render () {
    const { startDate, endDate } = this.props;

    // wait for values to populate
    if (!startDate || !endDate) {
      return null;
    }

    const initialValues: ReversalsDateRangeFilterModel = {
      startDate: new Date(startDate),
      endDate: new Date(endDate)
    }

    return (
      <div id="recipientDiscountRequestFilters_Pendo" className='recipient-discount-request-filters'>
        <Formik
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          enableReinitialize={true}>
          {({ values, handleSubmit, setFieldValue, setFieldTouched }) => {

            const handleDate = async (startDate: Moment | null, endDate: Moment | null) => {
              await setFieldValue('startDate', startDate?.toDate());
              setFieldTouched('startDate');

              await setFieldValue('endDate', endDate?.toDate());
              setFieldTouched('endDate');

              if (startDate !== null && endDate !== null) {
                handleSubmit();
              }
            }

            return (
              <Form className="date-form">
                <div className="date-range-picker">
                  <label>Submitted Request Date Range</label>
                  <DateRangePicker
                    noBorder
                    isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                    startDate={moment(values.startDate)}
                    startDateId='startDate'
                    endDate={values.endDate ? moment(values.endDate) : null}
                    endDateId='endDate'
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                    onDatesChange={({ startDate, endDate }) => handleDate(startDate, endDate)}
                    customArrowIcon={<div className="react-dates-arrow"><ArrowRight size={18} /></div>}
                    minimumNights={0} // allows selecting same start and end date
                  />
                </div>
              </Form>
            )
          }
          }
        </Formik>
      </div>
    )
  }
}

export default ReversalsDateRangeFilter;
