import * as React from 'react'
import { X } from 'react-feather';
import '../../themes/kalderos/Common/Buttons.scss';

export interface CloseButtonProps {
  onClick: () => void;
  isDisabled?: boolean;
  size?: number | 24;
}

export const CloseButton = (props: CloseButtonProps) =>
  <button disabled={props.isDisabled} className="close-button" type="button" onClick={props.onClick}>
    <X size={props.size}  />
  </button>

export default CloseButton
