import React from 'react';

import '../../../../themes/kalderos/Common/Drawer/Components/SecondaryHeader.scss';

interface SecondaryHeaderProps {
  header: string;
  subHeader: string;
  headerLabel?: React.ReactNode
}

const SecondaryHeader: React.FunctionComponent<SecondaryHeaderProps> = (props: SecondaryHeaderProps) => {
  return (
    <div className="secondary-drawer-header">
      <div className="header-text">
        <div className="value">{props.header}</div>
        <div>{props.headerLabel}</div>
      </div>
      <div className="sub-header">{props.subHeader}</div>
    </div>
  )
}

export default SecondaryHeader;
