import React from 'react';
import { SUPPORT_EMAIL_LINK, SUPPORT_EMAIL, SUPPORT_PHONE } from '../../../../store/applicationConstants';
import { Mail } from 'react-feather';

const AuthorizingOfficialSignUpSuccess = (props: { email: string, onReset: () => void, onClose: () => void }) => {
  return (
    <div className="login-page ao-sign-up-success">
      <div className="login-page-layout">
        <div className="login-page-column-right">
          <div className="login-form-container">
            <div className="login-form-header">Check your email inbox</div>
            <div className="login-form-body">
              <div className="message-box success-box">
                <Mail size={20} />
                <div className="message-box-text">You should shortly receive an email at {props.email} with instructions to set up your password and finish creating the account for your associated covered entities.</div>
              </div>
              <div>
                If you do not receive the email within 5 minutes, please check your spam or junk folders and confirm you are using the correct email address as listed in HRSA.
              </div>
              <div className="request-another-link">Still don’t see an email? <button className="link-button" type="button" onClick={props.onReset}>Request another link.</button></div>

            </div>
            <div className="login-form-footer">
              <button type="submit" className="app-button cta" onClick={props.onClose}>Return to Sign-In</button>
            </div>
          </div>
          <div className="login-page-column-right-footer">
            If you have any questions, please contact Kalderos Support at <a href={SUPPORT_EMAIL_LINK}>{SUPPORT_EMAIL}</a> or {SUPPORT_PHONE} for assistance.
          </div> 
        </div>
      </div>
    </div>
  )
}

export default AuthorizingOfficialSignUpSuccess
