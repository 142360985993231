import React from 'react';
import { Button } from 'semantic-ui-react';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import { formatMMDDYYYY, formatMD, formatYYYY } from '../../../utils/dateHelper';
import { formatPositiveDollarToNegative } from '../../../utils/numberFormatHelper';
import { DiscountRequestModel } from '../../../store/transactionscommon/types';
import { CloseIconAlt } from '../../../themes/kalderos/Common/Icons';
import FakeModal from '../modals/FakeModal';

const converter = require('number-to-words');

interface ReversalModalProps {
  transactions: DiscountRequestModel[];
  hasVerifiedFundingSource: boolean;
  onReverseClick: (transactionIds: number[]) => void;
  onCloseClick: () => void;
}

interface ReversalModalSummaryRow {
  reversedAmount: number,
  productName: string,
  totalCount: number,
  endDate: Date,
  submittedCount: number,
  selectedAreSubmitted: boolean,
  areMultiplesSelected: boolean
}

const getSummaryByProduct = (transactions: DiscountRequestModel[]) => {
  const transactionsGroupedByProductName = groupBy(transactions, 'ndcName');
  const productNames = Object.keys(transactionsGroupedByProductName);

  const response = productNames.map((productName) => {
    const largestDate = transactionsGroupedByProductName[productName].filter(d => d.processDate !== undefined).map(d => d.processDate!).reduce((a, b) => { return a < b ? a : b }, new Date());
    const submittedCount = transactionsGroupedByProductName[productName].filter((i: { statusText: string; }) => i.statusText === 'Submitted').length;
    const model: ReversalModalSummaryRow = {
      reversedAmount: transactionsGroupedByProductName[productName].map(p => p.currentAmount).reduce((a, b) => { return a + b; }, 0),
      productName: transactionsGroupedByProductName[productName].map(p => p.ndcName)[0],
      totalCount: transactionsGroupedByProductName[productName].length,
      endDate: largestDate,
      submittedCount: submittedCount,
      selectedAreSubmitted: submittedCount === transactionsGroupedByProductName[productName].length ? true : false,
      areMultiplesSelected: transactions.length > 1 ? true : false
    }
    return model;
  })


  return response;
}

const getExpectedReversalProcessDateText = (selection: ReversalModalSummaryRow) => {
  if (!selection.areMultiplesSelected && selection.selectedAreSubmitted) {
    return formatMMDDYYYY(Date.now());
  } else if (!selection.areMultiplesSelected && !selection.selectedAreSubmitted) {
    return formatMMDDYYYY(selection.endDate);
  } else if (selection.areMultiplesSelected && selection.selectedAreSubmitted) {
    return formatMMDDYYYY(Date.now());
  } else if (selection.totalCount === 1 && !selection.selectedAreSubmitted) {
    return formatMMDDYYYY(selection.endDate);
  } else if (selection.areMultiplesSelected && selection.submittedCount) {
    const currentDate = moment(Date.now());
    const selectionEndDate = moment(selection.endDate);
    const earlierDate = currentDate < selectionEndDate ? Date.now() : selection.endDate;
    const laterDate = currentDate < selectionEndDate ? selection.endDate : Date.now();

    return `${formatMD(earlierDate)} - ${formatMD(laterDate)}/${formatYYYY(laterDate)}`
  }
  return formatMMDDYYYY(selection.endDate);
}


const ReversalModal: React.FunctionComponent<ReversalModalProps> = (props) => {
  const { transactions, hasVerifiedFundingSource, onCloseClick, onReverseClick } = props;

  const reversableTransactions = transactions.filter(b => !b.reverseDiscountRequestId && !b.originalDiscountRequestId && b.statusText !== 'Failed');

  const reversableCount = reversableTransactions.length;
  const reversedCount = transactions.filter(b => b.statusText !== 'Failed' && (b.reverseDiscountRequestId || b.originalDiscountRequestId)).length;
  const failedCount = transactions.filter(b => b.statusText === 'Failed').length;
  const totalCount = transactions.length;

  const totalAmount = reversableTransactions.map((t) => t.currentAmount).reduce((a, b) => { return a + b }, 0);

  const summary = getSummaryByProduct(reversableTransactions);
  const numberToWord = converter.toWords(totalCount - reversableCount - failedCount).charAt(0).toUpperCase() + converter.toWords(totalCount - reversableCount - failedCount).slice(1);
  return (
    <FakeModal className="reversal-modal">
      <div className="modal-header-row">
        <div className="beta modal-header">Reverse Now</div>
        <div onClick={onCloseClick} className="close-icon-container">
          <CloseIconAlt />
        </div>
      </div>
      <div className="modal-subheader-row">
        <div className="delta modal-sub-header">
          {/* displayed if selected transaction count matches reversable count */}
          {(totalCount === reversableCount) ? `You're reversing ${totalCount} transactions for a total of ${formatPositiveDollarToNegative(totalAmount ? totalAmount : 0)}.`
            // displayed if selected transactions includes failed transactions and reversed transactions
            : (reversedCount > 0 && failedCount > 0) ? `You're reversing ${reversableCount} of ${totalCount} selected transactions for a total of ${formatPositiveDollarToNegative(totalAmount ? totalAmount : 0)}. ${numberToWord} selected ${numberToWord === 'One' ? 'transaction has' : 'transactions have'} been previously reversed. Failed transactions cannot be reversed.`
              //displayed if selected transactions includes reversed transactions only
              : reversedCount > 0 && failedCount === 0 ? `You're reversing ${reversableCount} of ${totalCount} selected transactions for a total of ${formatPositiveDollarToNegative(totalAmount ? totalAmount : 0)}. ${numberToWord} selected ${numberToWord === 'One' ? 'transaction has' : 'transactions have'} been previously reversed.`
                //display if selected transactions includes failed transactions only
                : failedCount > 0 && reversedCount === 0 ? `You're reversing ${reversableCount} of ${totalCount} selected transactions for a total of ${formatPositiveDollarToNegative(totalAmount ? totalAmount : 0)}. Failed transactions cannot be reversed.` : null}</div>
      </div>
      <div className="modal-product-row">
        <div className="eta reverse-info">Product</div>
        <div className="eta reverse-info">Exp. Reversal Process Date</div>
        <div className="eta reverse-info"># Requests</div>
        <div className="eta reverse-info">Amount</div>
      </div>
      <div className="modal-summary-container">
        {summary.map((d, i) => {
          return (
            <div key={i} className="modal-summary-row">
              <div className="eta reverse-info-sub">{d.productName || '- -'}</div>
              <div className="eta reverse-info-sub">{getExpectedReversalProcessDateText(d)} </div>
              <div className="eta reverse-info-sub">{d.totalCount || '- -'}</div>
              <div className="eta reverse-info-sub">{formatPositiveDollarToNegative(d.reversedAmount)}</div>
            </div>
          )
        })}
      </div>
      <div className="modal-button-row">
        <div className="flex-row">
          {/* <div className="btn-header">Download</div>
            <Icon className='download-icon' name='download' color="grey" /> */}
        </div>
        <div>
          <Button onClick={onCloseClick} className="cancel-btn">Cancel</Button>
          <Button onClick={() => onReverseClick(reversableTransactions.map(t => t.id))} className="reverse-btn" active secondary disabled={!hasVerifiedFundingSource}>Reverse Now</Button>
        </div>
      </div>
    </FakeModal>
  )
}


export default ReversalModal;