const luhnCheck = (input: string) => {

  if (input === undefined || input === null) {
    return false;
  }

  if (input.length !== 15) {
    return false;
  }

  const doubled = Array.prototype.map.call(input, (s, idx) => {
    if (idx % 2 === input.length % 2) {
      return (parseInt(s) * 2).toString();
    } else {
      return s;
    }
  }).join('');

  const sum: any = Array.prototype.reduce.call(doubled, (acc: any, n) => acc + parseInt(n), 0);

  return sum % 10 === 0;
}

export default luhnCheck;
