import React, { Fragment } from 'react';
import { CoveredEntity } from '../../../store/teammanagement/types';
import { Loader } from 'semantic-ui-react';

export interface CeListProps {
  ceList: CoveredEntity[];
  waiting: boolean;
  ceListError: string;
}

export const CeList: React.FunctionComponent<CeListProps> = ({ ceList, waiting, ceListError }) => {
  if (waiting) return <Loader className="modal-loader" active />;
  if (ceListError.length > 0) return null
  return (
    <Fragment>
      <div className="team-list-top flex-row">
        <span>Entity Name</span>
        <span>Covered Entities</span>
      </div>
      <div className="team-list-wrapper">
        {
          ceList.length ?
            <div className="middle-small">
              {
                ceList.map(ce => {
                  return (
                    <div key={ce.coveredEntityId} className="content">
                      <div className="first"><span>{ce.entityName}</span>
                      </div>
                      <span className="second">{ce.id340B}</span>
                    </div>
                  )
                })
              }
            </div> :
            <div className="no-ce-msg">You are not assigned to any covered entities for this health system.</div>
        }
      </div>
    </Fragment>
  )
};

export default CeList;
