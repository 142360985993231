import React from 'react';
import { connect } from 'react-redux';
import { Divider } from 'semantic-ui-react';

import DetailsHeader from '../../../common/drawer/components/DetailsHeader';
import Header from '../../../common/drawer/components/Header';
import Item from '../../../common/drawer/components/Item';
import PrimaryDrawer from '../../../common/drawer/components/PrimaryDrawer';
import StatusLabel from '../../../common/StatusLabel';
import { OPEN_DISPUTES_TAB } from '../../../../store/mdrp/disputescenter/constants';
import { getNextClaimIndex, getPreviousClaimIndex } from '../../../../store/mdrp/disputescenter/selectors';
import { ClaimModel } from '../../../../store/mdrp/disputescenter/types';
import { RootState } from '../../../../store/RootState';
import { formatMMDDYYYY } from '../../../../utils/dateHelper';
import { formatDollar, formatMdrpUnits, formatNdc11, formatUraDollar } from '../../../../utils/numberFormatHelper';
import DisputeCodeList from '../../../common/DisputeCodeList';

export interface ManufacturerReviewDrawerProps {
  activeIndex: number;
  handleSelectClaim: (index: number) => void;
  isOpen: boolean;
  nextClaimIndex?: number;
  onClose: () => void;
  previousClaimIndex?: number;
  selectedClaim: ClaimModel;
}

export const ManufacturerReviewDrawer = (props: ManufacturerReviewDrawerProps) => {
  const { activeIndex, handleSelectClaim, isOpen, nextClaimIndex, onClose, previousClaimIndex, selectedClaim } = props;

  const getClaimsStatusLabel = () => {
    if (activeIndex === OPEN_DISPUTES_TAB.NEW) {
      return <StatusLabel text="New Dispute" color="lightblue" />
    } else {
      return null;
    }
  };

  const handleChangeClaim = (index: number | undefined) => {
    if (index !== undefined) {
      handleSelectClaim(index);
    }
  }

  return (
    <PrimaryDrawer
      closeDrawer={onClose}
      isOpen={isOpen}
      onClickOutside={onClose}
    >
      <div className="drawer-layout">
        <Header
          header={selectedClaim.productName}
          headerLabel={getClaimsStatusLabel()}
          isNextDisabled={nextClaimIndex === undefined}
          isPrevDisabled={previousClaimIndex === undefined}
          onClose={onClose}
          onNextTransactionClick={() => handleChangeClaim(nextClaimIndex)}
          onPrevTransactionClick={() => handleChangeClaim(previousClaimIndex)}
          subHeader={formatNdc11(selectedClaim.ndC11)}
        />
        <div className="drawer-content">
          <div className="drawer-body">
            <div className="flex-row">
              <Item label="DISPUTE AMOUNT" value={formatDollar(selectedClaim.disputeAmount ? selectedClaim.disputeAmount : selectedClaim.rebateAmount)} />
            </div>
            <div className="flex-row">
              <Item label="UNITS DISPUTED" value={formatMdrpUnits(selectedClaim.disputedUnits)} />
            </div>
            <div className="flex-row justify-space-between">
              <Item label="URA" value={formatUraDollar(selectedClaim.unitRebateAmount)} />
              <Item label="CMS DISPUTE CODES" value={<DisputeCodeList disputeCodes={selectedClaim.combinedDisputes} />} />
            </div>
            <Divider />
            <DetailsHeader header="Claim Overview" />
            <Item displayAsRow label="State ICN" value={selectedClaim.icn} />
            <Item displayAsRow label="Rx Number" value={selectedClaim.rxNumber} />
            <Item displayAsRow displayPopup label="Program Name" value={selectedClaim.programName} />
            <Item displayAsRow label="MCO Plan ID" value={selectedClaim.planGroup} />
            <Item displayAsRow label="Rebate QTR" value={selectedClaim.rebateQtr} />
            <Item displayAsRow label="Duplicate Group ID" value="(placeholder)" />
            <Item displayAsRow label="Provider NPI" value={selectedClaim.pharmacyNpi} />
            <Item displayAsRow label="Date of Service" value={formatMMDDYYYY(selectedClaim.dateOfService)} />
            <Item displayAsRow label="Paid Date" value={formatMMDDYYYY(selectedClaim.paidDate)} />
            <Item displayAsRow label="Units Invoiced" value={formatMdrpUnits(selectedClaim.units)} />
            <Item displayAsRow label="Orig. Rebate Amount" value={formatDollar(selectedClaim.rebateAmount)} />
            <Item displayAsRow label="Units Labeler Accepted" value="(placeholder)" />
            <Item displayAsRow label="Dispute Created Date" value={formatMMDDYYYY(selectedClaim.updatedDate)} />
            <DetailsHeader hasMarginTop header="Additional Claim Information" />
            <Item displayAsRow label="CMS Adjust. Indicator " value={selectedClaim.adjustmentIndicator} />
            <Item displayAsRow label="Refill Code" value={selectedClaim.refillCode} />
            <Item displayAsRow label="Kalderos ID" value={selectedClaim.id} />
          </div>
        </div>
      </div>
    </PrimaryDrawer>
  );
};

const mapStateToProps = (state: RootState) => {
  const { selectedClaimIndex } = state.MdrpState.disputesCenter.disputesReview.manufacturerDisputes;
  return ({
    nextClaimIndex: getNextClaimIndex(state),
    previousClaimIndex: getPreviousClaimIndex(state),
    selectedClaim: state.MdrpState.disputesCenter.disputesReview.manufacturerDisputes.claims[selectedClaimIndex],
  })
}

export default connect(mapStateToProps)(ManufacturerReviewDrawer);
