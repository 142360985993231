import React from 'react';
import { connect } from 'react-redux'
import { RootState } from '../../store/RootState';
import { Dimmer, Loader, Table } from 'semantic-ui-react';
import { getAssociatedCoveredEntities, updateAssociatedCoveredEntitiesSort, resetRecipientPaymentsState } from '../../store/payments/recipient/actionCreators';
import SortIconSet from '../common/SortIconSet';
import { SortingFilter } from '../../store/appcommon/types';
import { CoveredEntityModel } from '../../store/payments/recipient/types';
import { NO_COVERED_ENTITY_BILLING_INFO_HEADER, NO_COVERED_ENTITY_BILLING_INFO_MESSAGE, COVERED_ENTITY_BILLING_INFO_ERROR_HEADER, COVERED_ENTITY_BILLING_INFO_ERROR_MESSAGE } from '../../store/payments/recipient/constants';
import EmptyTransactions from '../common/transactions/EmptyTransactions';
import CoveredEntityBillingInfoRow from './CoveredEntityBillingInfoRow';

export interface CoveredEntityInfoProps {
  coveredEntities: CoveredEntityModel[];
  isBillingInfoLoading: boolean;
  isBillingInfoError: boolean;
  featureFlags: string[];
  updateAssociatedCoveredEntitiesSort: (sortFilters: SortingFilter) => void;
  getAssociatedCoveredEntities: () => void;
  resetRecipientPaymentsState: () => void;
}

export class CoveredEntityInfo extends React.Component<CoveredEntityInfoProps>{
  componentDidMount () {
    this.props.getAssociatedCoveredEntities();
  }

  handleSortColumn = (sort: SortingFilter) => {
    this.props.updateAssociatedCoveredEntitiesSort(sort)
  }

  renderBillingInfoContent () {
    const { coveredEntities, isBillingInfoLoading, isBillingInfoError, featureFlags } = this.props;

    if (isBillingInfoLoading) {
      return <Dimmer active inverted><Loader inverted content='Loading' /></Dimmer>;
    }

    if (isBillingInfoError) {
      return <EmptyTransactions emptyTransactionsHeader={COVERED_ENTITY_BILLING_INFO_ERROR_HEADER} emptyTransactionsMessage={COVERED_ENTITY_BILLING_INFO_ERROR_MESSAGE} />;
    }

    if (coveredEntities && coveredEntities.length > 0) {
      return (
        <div className='basic-table-wrapper receipient-payment-billing-info'>
          <Table unstackable className='basic-table'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className='eta header-cell accordionIcon'></Table.HeaderCell>
                <Table.HeaderCell className='eta header-cell idCode'>
                  340B ID
                  <SortIconSet field='idCode' handleCellSorting={this.handleSortColumn} />
                </Table.HeaderCell>
                <Table.HeaderCell className='eta header-cell name340B'>
                  Covered Entity
                  <SortIconSet field='name340B' handleCellSorting={this.handleSortColumn} />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                coveredEntities.map((i: CoveredEntityModel, index: any) => {
                  return <CoveredEntityBillingInfoRow key={index} coveredEntity={i} featureFlags={featureFlags} />
                })
              }
            </Table.Body>
          </Table>
        </div>
      );
    }

    return <EmptyTransactions emptyTransactionsHeader={NO_COVERED_ENTITY_BILLING_INFO_HEADER} emptyTransactionsMessage={NO_COVERED_ENTITY_BILLING_INFO_MESSAGE} />;
  }

  render () {
    return (
      <div className='recipient-payment-billing-info'>
        <div className='recipient-payment-billing-info-ce-info'>
          {this.renderBillingInfoContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  coveredEntities: state.PaymentsState.recipient.coveredEntityBillingInfo.coveredEntities,
  isBillingInfoLoading: state.PaymentsState.recipient.coveredEntityBillingInfo.isBillingInfoLoading,
  isBillingInfoError: state.PaymentsState.recipient.coveredEntityBillingInfo.isBillingInfoError,
  featureFlags: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.featureFlags
});

const mapDispatchToProps = (dispatch: any) => ({
  updateAssociatedCoveredEntitiesSort: (sortFilters: SortingFilter) => dispatch(updateAssociatedCoveredEntitiesSort(sortFilters)),
  getAssociatedCoveredEntities: () => dispatch(getAssociatedCoveredEntities()),
  resetRecipientPaymentsState: () => dispatch(resetRecipientPaymentsState())
});

export default connect(mapStateToProps, mapDispatchToProps)(CoveredEntityInfo)
