import * as yup from 'yup';
import { DiscountProgramFieldsModel } from '../../../store/requestcenter/types';
import { formatEmpty } from '../../../utils/dataUtil';
import { formatMMDDYYYY } from '../../../utils/dateHelper';
import luhnCheck from '../../../utils/luhnCheck';
import { formatDollar } from '../../../utils/numberFormatHelper';

export const MIN_DATE = '1900-01-01';
export const MAX_DATE = '2078-12-31';

// *** TYPES *** 

export const RequestFormFieldTypes = {
  text: [
    'adjustmentIndicator',
    'bin',
    'claimNumber',
    'claimStatus',
    'compoundCode',
    'crossoverIndicator',
    'daw',
    'discountProgram',
    'hcpcs',
    'icn',
    'idCode',
    'memberId',
    'ndc11',
    'payerCode',
    'pcn',
    'pharmacyNpi',
    'planGroup',
    'planName',
    'prescriberNpi',
    'refillCode',
    'rxNumber',
    'statePlanId'
  ],
  number: [
    'invoiceQtr',
    'lineNumber',
    'rebateQtr',
    'daysSupply',
    'discountPerUnit',
    'packageQty',
    'providerQty',
    'units'
  ],
  money: [
    'billedAmount',
    'compoundIngredientReimbursement',
    'copayAmount',
    'dispenseFeeAmount',
    'paidAmount',
    'rebateClaimed',
    'thirdPartyAmount'
  ],
  date: [
    'claimDate',
    'claimPaidDate',
    'fillDate',
    'lastServiceDate'
  ]
}



// *** VALIDATION *** 

const regexTest = (value: any, pattern: string | RegExp) => {
  if (value !== undefined && value !== null) {
    return new RegExp(pattern).test(value);
  }
  return true;
}

const schemaDictionary: { [key: string]: yup.AnySchema } = {
  adjustmentIndicator: yup
    .string()
    .required()
    .max(3),
  billedAmount: yup
    .number()
    .required()
    .max(1)
    .test('regex', 'Invalid value',
      function (value: any) {
        return regexTest(value, /^(0|-?\d{0,14}(\.\d{0,2})?)$/)
      }),
  bin: yup
    .string()
    .required()
    .max(10),
  claimDate: yup
    .date()
    .required()
    .min(new Date(MIN_DATE))
    .max(new Date(MAX_DATE)),
  claimNumber: yup
    .string()
    .required()
    .max(20),
  claimPaidDate: yup
    .date()
    .required()
    .min(new Date(MIN_DATE))
    .max(new Date(MAX_DATE)),
  claimStatus: yup
    .string()
    .required()
    .max(5),
  compoundCode: yup
    .string()
    .required()
    .max(10),
  compoundIngredientReimbursement: yup
    .number()
    .required()
    .test('regex', 'Invalid value',
      function (value: any) {
        return regexTest(value, /^(0|-?\d{0,14}(\.\d{0,2})?)$/)
      }),
  copayAmount: yup
    .number()
    .required()
    .test('regex', 'Invalid value',
      function (value: any) {
        return regexTest(value, /^(0|-?\d{0,14}(\.\d{0,2})?)$/)
      }),
  crossoverIndicator: yup
    .string()
    .required()
    .max(10),
  daw: yup
    .string()
    .required()
    .max(3),
  daysSupply: yup
    .number()
    .required()
    .test('regex', 'Invalid value',
      function (value: any) {
        return regexTest(value, /^(0|-?\d{0,8}(\.\d{0,2})?)$/)
      }),
  discountPerUnit: yup
    .number()
    .notRequired()
    .moreThan(0)
    .test('regex', 'Invalid value',
      function (value: any) {
        return regexTest(value, /^(?!0*(\.0+)?$)(0|-?\d{0,5}(\.\d{0,6})?)$/)
      }),
  discountProgram: yup
    .string()
    .required()
    .max(50),
  dispenseFeeAmount: yup
    .number()
    .required()
    .test('regex', 'Invalid value',
      function (value: any) {
        return regexTest(value, /^(0|-?\d{0,14}(\.\d{0,2})?)$/)
      }),
  fillDate: yup
    .date()
    .required()
    .min(new Date(MIN_DATE))
    .max(new Date(MAX_DATE)),
  hcpcs: yup
    .string()
    .required()
    .max(5),
  icn: yup
    .string()
    .required()
    .max(25),
  idCode: yup
    .string()
    .required(),
  invoiceQtr: yup
    .number()
    .required()
    .integer(),
  lastServiceDate: yup
    .date()
    .required()
    .min(new Date(MIN_DATE))
    .max(new Date(MAX_DATE)),
  lineNumber: yup
    .number()
    .required()
    .integer(),
  memberId: yup
    .string()
    .required()
    .max(20)
    .test('regex', 'Invalid value',
      function (value: any) {
        return regexTest(value, /^([A-Za-z]|[0-9]|_)+$/)
      }),
  ndc11: yup
    .string()
    .required()
    .max(11),
  packageQty: yup
    .number()
    .required()
    .test('regex', 'Invalid value',
      function (value: any) {
        return regexTest(value, /^(0|-?\d{0,14}(\.\d{0,4})?)$/)
      }),
  paidAmount: yup
    .number()
    .required()
    .test('regex', 'Invalid value',
      function (value: any) {
        return regexTest(value, /^(0|-?\d{0,14}(\.\d{0,2})?)$/)
      }),
  payerCode: yup
    .string()
    .required()
    .max(12)
    .test('regex', 'Invalid value',
      function (value: any) {
        return regexTest(value, /^([A-Za-z]|[0-9]|_)+$/)
      }),
  pcn: yup
    .string()
    .required()
    .max(10),
  pharmacyNpi: yup
    .string()
    .required('${path} is required')
    .max(10)
    .test('luhnCheck', 'Invalid NPI',
      function (value: any) {
        return luhnCheck('80840' + value)
      }),
  planGroup: yup
    .string()
    .required()
    .max(10),
  planName: yup
    .string()
    .required()
    .max(150)
    .test('regex', 'Invalid value',
      function (value: any) {
        return regexTest(value, /^([A-Za-z]|[0-9]|_|\s)+$/)
      }),
  prescriberNpi: yup
    .string()
    .required()
    .max(10)
    .test('luhnCheck', 'Invalid NPI',
      function (value: any) {
        return luhnCheck('80840' + value)
      }),
  providerQty: yup
    .number()
    .required()
    .test('regex', 'Invalid value',
      function (value: any) {
        return regexTest(value, /^(0|-?\d{0,14}(\.\d{0,2})?)$/)
      }),
  rebateClaimed: yup
    .number()
    .required()
    .test('regex', 'Invalid value',
      function (value: any) {
        return regexTest(value, /^(0|-?\d{0,14}(\.\d{0,2})?)$/)
      }),
  rebateQtr: yup
    .number()
    .required()
    .integer(),
  refillCode: yup
    .string()
    .required()
    .max(5),
  rxNumber: yup
    .string()
    .required()
    .max(15)
    .test('regex', 'Invalid value',
      function (value: any) {
        return regexTest(value, /^[0-9]+$/)
      }),
  statePlanId: yup
    .string()
    .required()
    .max(15),
  thirdPartyAmount: yup
    .number()
    .required()
    .test('regex', 'Invalid value',
      function (value: any) {
        return regexTest(value, /^(0|-?\d{0,14}(\.\d{0,2})?)$/)
      }),
  units: yup
    .number()
    .required()
    .moreThan(0)
    .test('regex', 'Invalid value',
      function (value: any) {
        return regexTest(value, /^(?!0*(\.0+)?$)(0|-?\d{0,8}(\.\d{0,3})?)$/)
      }),
}

export const getRequestFormSchema = (discountProgramFields: DiscountProgramFieldsModel[]) => {
  const schema: any = {};

  discountProgramFields.forEach((fld) => {
    schema[fld.name] = schemaDictionary[fld.name].label(fld.display);
  });

  return yup.object().shape(schema);
}

// *** MISC ***
export const orderDiscountProgramFields = (discountProgramFields: DiscountProgramFieldsModel[], claimType: string) => {

  if (claimType === 'Retail') {
    // Show Discount Per Unit last
    const discountPerUnit = discountProgramFields.filter(fld => fld.name === 'discountPerUnit');
    const otherFields = discountProgramFields.filter(fld => fld.name !== 'discountPerUnit');

    return [...otherFields, ...discountPerUnit];
  }

  if (claimType === 'Medical') {
    // Show Pharmacy NPI and Prescriber NPI first, Discount Per Unit last
    const discountPerUnit = discountProgramFields.filter(fld => fld.name === 'discountPerUnit');
    const npiArray = discountProgramFields.filter(fld => fld.name === 'pharmacyNpi' || fld.name === 'prescriberNpi');
    const otherFields = discountProgramFields.filter(fld => fld.name !== 'pharmacyNpi' && fld.name !== 'prescriberNpi' && fld.name !== 'discountPerUnit');

    return [...npiArray, ...otherFields, ...discountPerUnit];
  }

  return discountProgramFields;
};

export const getFormattedFieldValue = (name: string, value: any) => {
  if (RequestFormFieldTypes.date.includes(name)) {
    return formatMMDDYYYY(value);
  }
  if (RequestFormFieldTypes.money.includes(name)) {
    return formatDollar(value);
  }
  return formatEmpty(value);
}