import *  as yup from 'yup';

export const firstNameSchema = {
  firstName: yup
    .string()
    .required()
    .max(100)
    .trim()
};
export const lastNameSchema = {
  lastName: yup
    .string()
    .required()
    .max(100)
    .trim()
};

export const emailSchema = {
  email: yup
    .string()
    .required()
    .email()
    .max(100)
}

export const basicSchema = {
  ...firstNameSchema,
  ...lastNameSchema,
  ...emailSchema,
};

export const addressSchema = yup.object().shape({
  address1: yup
    .string()
    .required()
    .max(55)
    .trim(),
  address2: yup
    .string()
    .notRequired()
    .max(55),
  city: yup
    .string()
    .required()
    .max(30)
    .trim(),
  state: yup
    .string()
    .required(),
  zip: yup
    .string()
    .required()
    .test(
      'zip',
      'should be 5 digits',
      function (item) {
        if (item) {
          const isAllNumbers = /^[0-9]*$/.test(item);
          return isAllNumbers && item.length === 5;
        }
        return true;
      }),
});

export const userSchema ={
  jobTitle: yup
    .string()
    .required()
    .max(100)
    .trim(),
  phone: yup
    .string()
    .required()
    .test(
      'phone',
      'should be 10 digits',
      function (item) {
        if (item) {
          return /^(\(\d{3}\) \d{3}-\d{4})$/.test(item);
        }
        return true;
      }),
  phoneExtension: yup
    .string()
    .test(
      'phone-extension',
      'should not be more than 6, should only be digits',
      function (item) {
        if (item) {
          const isAllNumbers = /^[0-9]*$/.test(item);
          return isAllNumbers && item.length <= 6;
        }
        return true;
      }
    )};

export const companyInfoShape = {
  name: yup
    .string()
    .required()
    .max(250)
    .trim(),
  type: yup
    .string()
    .required()
    .trim(),
  corporateAddress: addressSchema,
  ein: yup
    .string()
    .required()
    .test('ein',
      'should be 9 numbers separated by a -',
      function (item) {
        if (item) {
          return /^(\d{2}-\d{7})$/.test(item);
        }
        return true;
      }),
};
