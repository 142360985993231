import React from 'react';

import Layout from '../Layout';
import GoToRequestFooter from './GoToRequestFooter';
import OnboardingComplete from './OnboardingComplete';

class OnboardingCompleteFullScreen extends React.Component {
  render () {
    return (
      <Layout stepNumber={7}      >
        <OnboardingComplete />
        <GoToRequestFooter />
      </Layout>
    )
  }
}

export default OnboardingCompleteFullScreen;