import RequestConfig from '../../app.config';
import { ChangePasswordModel, AcceptInviteInput } from '../../store/password/types';
import { getUserToken } from '../../localstore/LocalStore';
import { LoginModel } from '../../store/login/types';
import fetchWrapper from '../FetchWrapper';
import { commonResponseHandler, RESPONSE_TYPE } from '../../utils/responseUtils';
import { createUrlParametersString } from '../../utils/requestUtil';

export const validateResetPasswordToken = async (token: string, healthSystemId: number | null) => {
  const urlParams = healthSystemId ? createUrlParametersString({ welcomeToken: token, teamId: healthSystemId }) : createUrlParametersString({ welcomeToken: token });
  const response = await fetch(`${await RequestConfig()}/request/api/access/initialLogin/?${urlParams}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });
  return commonResponseHandler(response);
}


export const resetPasswordApi = async (loginModel: LoginModel) => {
  const token = getUserToken();
  const response = await fetch(`${await RequestConfig()}/request/api/access/resetPassword`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(loginModel)
  })
  return commonResponseHandler(response);
}

export const changePasswordApi = async (changePasswordModel: ChangePasswordModel) => {
  const token = getUserToken();
  const response = await fetch(`${await RequestConfig()}/request/api/access/changePassword`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(changePasswordModel)
  })
  return commonResponseHandler(response, RESPONSE_TYPE.NONE);
}

export const forgotPasswordApi = async (userName: string) => {
  const response = await fetch(`${await RequestConfig()}/request/api/access/forgotPassword`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ 'userName': userName })
  });
  return commonResponseHandler(response, RESPONSE_TYPE.NONE);
}

export const validateInviteRecipientApi  = async (token: string, healthSystemId: number) => {
  const urlParams = healthSystemId ? createUrlParametersString({ welcomeToken: token, healthSystemId: healthSystemId }) : createUrlParametersString({ welcomeToken: token });
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/healthSystem/validateInvite?${urlParams}`, false, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    }
  });
  return commonResponseHandler(response, RESPONSE_TYPE.JSON);
};

export const acceptInviteRecipientApi = async (input: AcceptInviteInput) => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/healthSystem/acceptInvite`, true, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(input)
  });
  return commonResponseHandler(response, RESPONSE_TYPE.NONE);
};