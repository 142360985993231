import { RecipientTeamManagementState, HealthSystemInfo, CoveredEntity, ErrorModel } from './types';
import {
  RecipientTeamManagementActionTypes,
  GET_HEALTH_SYSTEM_INFO,
  GET_HEALTH_SYSTEM_INFO_SUCCESS,
  GET_HEALTH_SYSTEM_INFO_FAILED,
  GET_HEALTH_SYSTEMS,
  GET_HEALTH_SYSTEMS_SUCCESS,
  GET_HEALTH_SYSTEMS_FAILED,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  ASSIGN_USER_CE,
  ASSIGN_USER_CE_SUCCESS,
  ASSIGN_USER_CE_FAILURE,
  ASSIGN_CE_USERS,
  ASSIGN_CE_USERS_SUCCESS,
  ASSIGN_CE_USERS_FAILURE,
  OPEN_ADD_USER_MODAL,
  CLOSE_ADD_USER_MODAL,
  OPEN_ASSIGN_CE_MODAL,
  CLOSE_ASSIGN_CE_MODAL,
  OPEN_EDIT_TEAM_MODAL,
  CLOSE_EDIT_TEAM_MODAL,
  OPEN_CANCEL_INVITE_MODAL,
  CLOSE_CANCEL_INVITE_MODAL,
  UPDATE_SELECTED_HEALTH_SYSTEM_INFO,
  UPDATE_USER_ROLE,
  UPDATE_USER_ROLE_ERROR,
  UPDATE_USER_ERROR,
  CANCEL_INVITE,
  CANCEL_INVITE_SUCCESS,
  CANCEL_INVITE_FAILURE,
  GET_CE_LIST_FOR_HS_MODAL,
  GET_CE_LIST_FOR_HS_MODAL_SUCCESS,
  GET_CE_LIST_FOR_HS_MODAL_ERROR,
  OPEN_CHANGE_HEALTH_SYSTEM_MODAL,
  CLOSE_CHANGE_HEALTH_SYSTEM_MODAL,
  OPEN_ONBOARDING_INVITE_MODAL,
  CLOSE_ONBOARDING_INVITE_MODAL,
  SEND_ONBOARDING_INVITE,
  SEND_ONBOARDING_INVITE_SUCCESS,
  SEND_ONBOARDING_INVITE_FAILURE,
  UPDATE_SELECTED_HEALTH_SYSTEM_INFO_FOR_ONBOARDING,
  UPDATE_SELECTED_HEALTH_SYSTEM_ONBOARDING_ROLES,
  UPDATE_CE_LEAD_CONTACT_SUCCESS,
  UPDATE_USER_SUCCESS,
  STATES,
  DISABLE_USER,
  DISABLE_USER_FAILURE,
  DISABLE_USER_SUCCESS,
  ENABLE_USER,
  ENABLE_USER_FAILURE,
  ENABLE_USER_SUCCESS,
  SET_PRESELECTED_HEALTH_SYSTEM_ID,
  CLEAR_HEALTH_SYSTEMS_ERROR,
  SET_MOCK_NEW_AO,
  USER_NOT_APART_OF_A_HEALTH_SYSTEM,
  OPEN_SEARCH_HEALTH_SYSTEMS_MODAL,
  CLOSE_SEARCH_HEALTH_SYSTEMS_MODAL,
  SEARCH_HEALTH_SYSTEMS,
  SEARCH_HEALTH_SYSTEMS_SUCCESS,
  SEARCH_HEALTH_SYSTEMS_FAILURE
} from './constants';

const errorModel: ErrorModel = {
  isError: false,
  message: 'Error'
};

const healthSystemInfoInitialState: HealthSystemInfo = {
  healthSystemId: 0,
  healthSystemName: '',
  activeMembers: [],
  disabledMembers: [],
  coveredEntities: [],
  viewDisabledUsers: false,
  disableUserWaiting: false,
  isError: errorModel,
  featureFlags: [],
};

const emptyCoveredEntity: CoveredEntity = {
  coveredEntityId: -1,
  entityName: '',
  id340B: '',
  ddmLeadContactId: -1,
  userIdsAssigned: []
}

const initialState: RecipientTeamManagementState = {
  entitledHealthSystems: [],
  userId: 0,
  teamManagementModel: {
    selectedHealthSystemId: 0,
    activeHealthSystemInfo: healthSystemInfoInitialState,
    currentRole: '',
    currentOnboardingRoles: [],
    hasVerifiedFundingSource: false,
    isLoading: false,
    errorText: '',
    isLoaded: false,
  },
  addUserModal: {
    isOpen: false,
    isLoading: false,
    errorText: '',
    newUserAdded: false,
  },
  assignCEModal: {
    userId: -1,
    ceIds: [],
    isOpen: false,
    isLoading: false,
    errorText: '',
  },
  editTeamModal: {
    coveredEntity: emptyCoveredEntity,
    isOpen: false,
    isLoading: false,
    errorText: '',
  },
  cancelInviteModal: {
    isOpen: false,
    isLoading: false,
    errorText: '',
  },
  changeHealthSystemModal: {
    isOpen: false,
    isRequired: false,
    modalWaiting: false,
    coveredEntities: [],
    errorText: ''
  },
  searchHealthSystemsModal: {
    healthSystems: [],
    isOpen: false,
    isRequired: false,
    isLoading: false,
    errorText: ''
  },
  onboardingInviteModal: {
    isOpen: false,
    role: '',
    isLoading: false,
    errorText: ''
  },
  preSelectedHealthSystemId: null
};

export function teamManagementReducer (
  state = initialState,
  action: RecipientTeamManagementActionTypes
): RecipientTeamManagementState {
  switch (action.type) {
    case UPDATE_USER_ROLE:
    case GET_HEALTH_SYSTEMS:
      return {
        ...state,
        teamManagementModel: {
          ...state.teamManagementModel,
          activeHealthSystemInfo: healthSystemInfoInitialState,
          isLoading: true,
          errorText: '',
        }
      };
    case GET_HEALTH_SYSTEMS_SUCCESS:
      return {
        ...state,
        entitledHealthSystems: action.payload.healthSystems,
        userId: action.payload.userId,
        teamManagementModel: {
          ...state.teamManagementModel,
          isLoading: false,
          isLoaded: true,
        }
      };
    case GET_HEALTH_SYSTEM_INFO:
      return {
        ...state,
        teamManagementModel: {
          ...state.teamManagementModel,
          selectedHealthSystemId: action.payload.healthSystemId,
          activeHealthSystemInfo: healthSystemInfoInitialState,
          isLoading: true,
          errorText: '',
          isLoaded: true,
        }
      };
    case GET_HEALTH_SYSTEM_INFO_SUCCESS:
      return {
        ...state,
        teamManagementModel: {
          ...state.teamManagementModel,
          activeHealthSystemInfo: {
            ...action.payload,
          },
          isLoading: false
        },
        changeHealthSystemModal: {
          ...state.changeHealthSystemModal,
          modalWaiting: false
        }
      };

    case GET_HEALTH_SYSTEMS_FAILED:
    case GET_HEALTH_SYSTEM_INFO_FAILED: {
      return {
        ...state,
        teamManagementModel: {
          ...state.teamManagementModel,
          activeHealthSystemInfo: {
            ...state.teamManagementModel.activeHealthSystemInfo,
          },
          isLoading: false,
          errorText: action.payload.message,
        }
      };
    }
    case CLEAR_HEALTH_SYSTEMS_ERROR: {
      return {
        ...state,
        teamManagementModel: {
          ...state.teamManagementModel,
          errorText: ''
        }
      };
    }
    case UPDATE_SELECTED_HEALTH_SYSTEM_INFO:
      return {
        ...state,
        teamManagementModel: {
          ...state.teamManagementModel,
          currentRole: action.payload.role,
          currentOnboardingRoles: action.payload.onboardingRoles,
          hasVerifiedFundingSource: action.payload.hasVerifiedFundingSource
        }
      }
    case UPDATE_SELECTED_HEALTH_SYSTEM_INFO_FOR_ONBOARDING: {
      return {
        ...state,
        teamManagementModel: {
          ...state.teamManagementModel,
          activeHealthSystemInfo: {
            healthSystemId: action.payload.healthSystemId,
            healthSystemName: action.payload.healthSystemName,
            activeMembers: [],
            disabledMembers: [],
            coveredEntities: [],
            viewDisabledUsers: false,
            disableUserWaiting: false,
            isError: {} as ErrorModel,
            featureFlags: [],
          },
          selectedHealthSystemId: action.payload.healthSystemId,
          currentRole: action.payload.role,
          currentOnboardingRoles: action.payload.onboardingRoles,
          hasVerifiedFundingSource: action.payload.hasVerifiedFundingSource
        }
      }
    }
    case UPDATE_SELECTED_HEALTH_SYSTEM_ONBOARDING_ROLES:
      return {
        ...state,
        teamManagementModel: {
          ...state.teamManagementModel,
          currentOnboardingRoles: action.payload,
        }
      }
    case OPEN_ADD_USER_MODAL:
      return {
        ...state,
        addUserModal: {
          ...state.addUserModal,
          isOpen: true,
          newUserAdded: false,
        }
      }
    case CLOSE_ADD_USER_MODAL:
      return {
        ...state,
        addUserModal: {
          ...state.addUserModal,
          isOpen: false,
          isLoading: false,
          errorText: '',
        }
      }
    case OPEN_ASSIGN_CE_MODAL:
      return {
        ...state,
        assignCEModal: {
          ...state.assignCEModal,
          isOpen: true,
          userId: action.payload.userId,
          ceIds: action.payload.ceIds,
        }
      }
    case CLOSE_ASSIGN_CE_MODAL:
      return {
        ...state,
        assignCEModal: {
          ...state.assignCEModal,
          isOpen: false,
          userId: -1,
          ceIds: [],
          isLoading: false,
          errorText: '',
        }
      }
    case OPEN_EDIT_TEAM_MODAL:
      return {
        ...state,
        editTeamModal: {
          ...state.editTeamModal,
          isOpen: true,
          coveredEntity: action.payload
        }
      }
    case CLOSE_EDIT_TEAM_MODAL:
      return {
        ...state,
        editTeamModal: {
          ...state.editTeamModal,
          isOpen: false,
          coveredEntity: emptyCoveredEntity,
          isLoading: false,
          errorText: '',
        }
      }
    case OPEN_CANCEL_INVITE_MODAL:
      return {
        ...state,
        cancelInviteModal: {
          ...state.cancelInviteModal,
          isOpen: true,
          user: action.payload,
        }
      }
    case CLOSE_CANCEL_INVITE_MODAL:
      return {
        ...state,
        cancelInviteModal: {
          ...state.cancelInviteModal,
          isOpen: false,
          user: undefined,
          isLoading: false,
          errorText: '',
        }
      }
    case ADD_USER:
      return {
        ...state,
        addUserModal: {
          ...state.addUserModal,
          isLoading: true,
          errorText: '',
          newUserAdded: false,
        }
      }
    case ADD_USER_SUCCESS:
      return {
        ...state,
        addUserModal: {
          ...state.addUserModal,
          isLoading: false,
          newUserAdded: true
        }
      }
    case ADD_USER_FAILURE:
      return {
        ...state,
        addUserModal: {
          ...state.addUserModal,
          isLoading: false,
          errorText: action.payload.message,
        }
      }
    case ASSIGN_USER_CE:
      return {
        ...state,
        assignCEModal: {
          ...state.assignCEModal,
          isLoading: true,
          errorText: '',
        }
      }
    case ASSIGN_USER_CE_SUCCESS:
      return {
        ...state,
        assignCEModal: {
          ...state.assignCEModal,
          isLoading: false,
        }
      }
    case ASSIGN_USER_CE_FAILURE:
      return {
        ...state,
        assignCEModal: {
          ...state.assignCEModal,
          isLoading: false,
          errorText: action.payload.message
        }
      }
    case ASSIGN_CE_USERS:
      return {
        ...state,
        editTeamModal: {
          ...state.editTeamModal,
          isLoading: true,
          errorText: '',
        }
      }
    case ASSIGN_CE_USERS_SUCCESS:
      return {
        ...state,
        editTeamModal: {
          ...state.editTeamModal,
          isLoading: false,
        }
      }
    case ASSIGN_CE_USERS_FAILURE:
      return {
        ...state,
        editTeamModal: {
          ...state.editTeamModal,
          isLoading: false,
          errorText: action.payload.message,
        }
      }
    case UPDATE_USER_ROLE_ERROR:
      return {
        ...state,
        teamManagementModel: {
          ...state.teamManagementModel,
          isLoading: false,
          errorText: ''
        }
      }
    case UPDATE_USER_ERROR:
      return {
        ...state,
        teamManagementModel: {
          ...state.teamManagementModel,
          isLoading: false,
          errorText: ''
        }
      }
    case CANCEL_INVITE:
      return {
        ...state,
        cancelInviteModal: {
          ...state.cancelInviteModal,
          isLoading: true,
          errorText: '',
        }
      }
    case CANCEL_INVITE_SUCCESS:
      return {
        ...state,
        cancelInviteModal: {
          ...state.cancelInviteModal,
          isLoading: false,
        }
      }
    case CANCEL_INVITE_FAILURE:
      return {
        ...state,
        cancelInviteModal: {
          ...state.cancelInviteModal,
          isLoading: false,
          errorText: action.payload.message,
        }
      }

    case GET_CE_LIST_FOR_HS_MODAL:
      return {
        ...state,
        changeHealthSystemModal: {
          ...state.changeHealthSystemModal,
          modalWaiting: true
        }
      }

    case GET_CE_LIST_FOR_HS_MODAL_SUCCESS:
      return {
        ...state,
        changeHealthSystemModal: {
          ...state.changeHealthSystemModal,
          modalWaiting: false,
          coveredEntities: action.payload.coveredEntities
        }
      }

    case GET_CE_LIST_FOR_HS_MODAL_ERROR:
      return {
        ...state,
        changeHealthSystemModal: {
          ...state.changeHealthSystemModal,
          errorText: action.payload.message
        }
      }
    case OPEN_CHANGE_HEALTH_SYSTEM_MODAL:
      return {
        ...state,
        changeHealthSystemModal: {
          ...state.changeHealthSystemModal,
          isOpen: true,
          isRequired: action.payload
        }
      }
    case CLOSE_CHANGE_HEALTH_SYSTEM_MODAL:
      return {
        ...state,
        changeHealthSystemModal: {
          ...state.changeHealthSystemModal,
          isOpen: false
        }
      }

    case OPEN_SEARCH_HEALTH_SYSTEMS_MODAL:
      return {
        ...state,
        searchHealthSystemsModal: {
          ...state.searchHealthSystemsModal,
          isOpen: true,
          isRequired: action.payload
        }
      }

    case CLOSE_SEARCH_HEALTH_SYSTEMS_MODAL:
      return {
        ...state,
        searchHealthSystemsModal: {
          ...state.searchHealthSystemsModal,
          healthSystems: [],
          isOpen: false
        }
      }

    case SEARCH_HEALTH_SYSTEMS:
      return {
        ...state,
        searchHealthSystemsModal: {
          ...state.searchHealthSystemsModal,
          isLoading: true,
          errorText: ''
        }
      }

    case SEARCH_HEALTH_SYSTEMS_SUCCESS:
      return {
        ...state,
        searchHealthSystemsModal: {
          ...state.searchHealthSystemsModal,
          isLoading: false,
          healthSystems: action.payload
        }
      }

    case SEARCH_HEALTH_SYSTEMS_FAILURE:
      return {
        ...state,
        searchHealthSystemsModal: {
          ...state.searchHealthSystemsModal,
          isLoading: false,
          errorText: action.payload.message
        }
      }

    case OPEN_ONBOARDING_INVITE_MODAL:
      return {
        ...state,
        onboardingInviteModal: {
          ...state.onboardingInviteModal,
          isOpen: true,
          role: action.payload,
        }
      }
    case CLOSE_ONBOARDING_INVITE_MODAL:
      return {
        ...state,
        onboardingInviteModal: {
          ...state.onboardingInviteModal,
          isOpen: false,
          role: '',
          errorText: '',
        }
      }
    case SEND_ONBOARDING_INVITE:
      return {
        ...state,
        onboardingInviteModal: {
          ...state.onboardingInviteModal,
          isLoading: true,
          errorText: '',
        }
      }
    case SEND_ONBOARDING_INVITE_SUCCESS:
      return {
        ...state,
        onboardingInviteModal: {
          ...state.onboardingInviteModal,
          isLoading: false,
        }
      }
    case SEND_ONBOARDING_INVITE_FAILURE:
      return {
        ...state,
        onboardingInviteModal: {
          ...state.onboardingInviteModal,
          isLoading: false,
          errorText: action.payload.message,
        }
      }
    case UPDATE_CE_LEAD_CONTACT_SUCCESS:
    {
      const udpatedCeDetails = state.teamManagementModel.activeHealthSystemInfo.coveredEntities;
      udpatedCeDetails.forEach(ce => {
        if (ce.id340B === action.payload.id340B) {
          ce.ddmLeadContactId = action.payload.userId;
          if (!ce.userIdsAssigned.includes(action.payload.userId)) {
            ce.userIdsAssigned.push(action.payload.userId);
          }
        }
      });
      return {
        ...state,
        teamManagementModel: {
          ...state.teamManagementModel,
          activeHealthSystemInfo: {
            ...state.teamManagementModel.activeHealthSystemInfo,
            coveredEntities: udpatedCeDetails
          }
        }
      };
    }
    case UPDATE_USER_SUCCESS:
    {
      const team = { ...state.teamManagementModel.activeHealthSystemInfo };
      if (team.healthSystemId) {
        team.activeMembers.forEach(t => {
          if (t.userId === action.payload.userID) {
            if (action.payload.stateId) {
              STATES.forEach(s => {
                if (s.key === action.payload.stateId) {
                  t.address.state = s.text;
                }
              })
            }
            for (const i in action.payload) {
              if (i === 'address1' || i === 'address2' || i === 'city' || i === 'zip' || i === 'state') {
                if (i === 'state') {
                  t.address.state = action.payload[i]
                } else {
                  t.address[i] = action.payload[i]
                }
              }
            }
          }
        });
      }
      return {
        ...state,
        teamManagementModel: {
          ...state.teamManagementModel,
          activeHealthSystemInfo: team
        }
      };
    }
    case DISABLE_USER:
      return {
        ...state,
        teamManagementModel: {
          ...state.teamManagementModel,
          isLoading: false,
          activeHealthSystemInfo: {
            ...state.teamManagementModel.activeHealthSystemInfo,
            disableUserWaiting: true
          }
        }
      };
    case DISABLE_USER_FAILURE:
      return {
        ...state,
        teamManagementModel: {
          ...state.teamManagementModel,
          activeHealthSystemInfo: {
            ...state.teamManagementModel.activeHealthSystemInfo,
            disableUserWaiting: false,
            isError: {
              isError: true,
              message: action.payload
            }
          }
        }
      };
    case DISABLE_USER_SUCCESS:
      return {
        ...state,
        teamManagementModel: {
          ...state.teamManagementModel,
          isLoading: false,
          activeHealthSystemInfo: {
            ...state.teamManagementModel.activeHealthSystemInfo,
            disableUserWaiting: false
          }
        }
      };

    case ENABLE_USER:
      return {
        ...state,
        teamManagementModel: {
          ...state.teamManagementModel,
          isLoading: false,
          activeHealthSystemInfo: {
            ...state.teamManagementModel.activeHealthSystemInfo
          }
        }
      };
    case ENABLE_USER_FAILURE:
      return {
        ...state,
        teamManagementModel: {
          ...state.teamManagementModel,
          activeHealthSystemInfo: {
            ...state.teamManagementModel.activeHealthSystemInfo,
            isError: {
              isError: true,
              message: action.payload
            }
          }
        }
      };
    case ENABLE_USER_SUCCESS:
      return {
        ...state,
        teamManagementModel: {
          ...state.teamManagementModel,
          isLoading: false,
          activeHealthSystemInfo: {
            ...state.teamManagementModel.activeHealthSystemInfo
          }
        }
      };
    case SET_MOCK_NEW_AO:
      return {
        ...state,
        teamManagementModel: {
          ...state.teamManagementModel,
          activeHealthSystemInfo: {
            ...state.teamManagementModel.activeHealthSystemInfo,
            healthSystemId: 0,
          },
          selectedHealthSystemId: 0,
          errorText: USER_NOT_APART_OF_A_HEALTH_SYSTEM
        }
      }
    case SET_PRESELECTED_HEALTH_SYSTEM_ID:
      return {
        ...state,
        preSelectedHealthSystemId: action.payload
      }
    default:
      return state
  }
}
