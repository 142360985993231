import { HealthSystemInfo, EditRoleModel, EditUserModel, PendingUser, CoveredEntity, TeamMember, HealthSystemsResponse, HealthSystem, OnboardingUserInviteModel, HealthSystemSearchInput } from './types';
import { ErrorModel } from '../applicationTypes';

export const USER_INFO = {
  isAdmin: false,
  disabled: false,
  isActive: false,
  role: '',
  id: 0,
  userId: 0,
  assignedCoveredEntities: [],
  userName: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  phoneExtension: '',
  jobTitle: '',
  address: {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: ''
  }

};
export const STATES = [
  { text: 'Alabama', key: 2, value: 'AL' },
  { text: 'Alaska', key: 1, value: 'AK' },
  { text: 'Arizona', key: 4, value: 'AZ' },
  { text: 'Arkansas', key: 3, value: 'AR' },
  { text: 'California', key: 5, value: 'CA' },
  { text: 'Colorado', key: 6, value: 'CO' },
  { text: 'Connecticut', key: 7, value: 'CT' },
  { text: 'Delaware', key: 9, value: 'DE' },
  { text: 'District Of Columbia', key: 8, value: 'DC' },
  { text: 'Florida', key: 10, value: 'FL' },
  { text: 'Georgia', key: 11, value: 'GA' },
  { text: 'Guam', key: 12, value: 'GU' },
  { text: 'Hawaii', key: 13, value: 'HI' },
  { text: 'Idaho', key: 15, value: 'ID' },
  { text: 'Illinois', key: 16, value: 'IL' },
  { text: 'Indiana', key: 17, value: 'IN' },
  { text: 'Iowa', key: 14, value: 'IA' },
  { text: 'Kansas', key: 18, value: 'KS' },
  { text: 'Kentucky', key: 19, value: 'KY' },
  { text: 'Louisiana', key: 20, value: 'LA' },
  { text: 'Maine', key: 24, value: 'ME' },
  { text: 'Maryland', key: 23, value: 'MD' },
  { text: 'Massachusetts', key: 22, value: 'MA' },
  { text: 'Michigan', key: 25, value: 'MI' },
  { text: 'Minnesota', key: 26, value: 'MN' },
  { text: 'Mississippi', key: 29, value: 'MS' },
  { text: 'Missouri', key: 27, value: 'MO' },
  { text: 'Montana', key: 30, value: 'MT' },
  { text: 'Nebraska', key: 33, value: 'NE' },
  { text: 'Nevada', key: 37, value: 'NV' },
  { text: 'New Hampshire', key: 34, value: 'NH' },
  { text: 'New Jersey', key: 35, value: 'NJ' },
  { text: 'New Mexico', key: 36, value: 'NM' },
  { text: 'New York', key: 38, value: 'NY' },
  { text: 'North Carolina', key: 31, value: 'NC' },
  { text: 'North Dakota', key: 32, value: 'ND' },
  { text: 'Ohio', key: 39, value: 'OH' },
  { text: 'Oklahoma', key: 40, value: 'OK' },
  { text: 'Oregon', key: 41, value: 'OR' },
  { text: 'Pennsylvania', key: 42, value: 'PA' },
  { text: 'Puerto Rico', key: 43, value: 'PR' },
  { text: 'Rhode Island', key: 44, value: 'RI' },
  { text: 'South Carolina', key: 45, value: 'SC' },
  { text: 'South Dakota', key: 46, value: 'SD' },
  { text: 'Tennessee', key: 47, value: 'TN' },
  { text: 'Texas', key: 48, value: 'TX' },
  { text: 'Utah', key: 49, value: 'UT' },
  { text: 'Vermont', key: 52, value: 'VT' },
  { text: 'Virgin Islands', key: 51, value: 'VI' },
  { text: 'Virginia', key: 50, value: 'VA' },
  { text: 'Washington', key: 53, value: 'WA' },
  { text: 'West Virginia', key: 55, value: 'WV' },
  { text: 'Wisconsin', key: 54, value: 'WI' },
  { text: 'Wyoming', key: 56, value: 'WY' }
]

export const UPDATE_INFO = {
  firstName: null,
  lastName: null,
  jobTitle: null,
  phone: null,
  phoneExtension: null,
  address1: null,
  address2: null,
  city: null,
  stateId: null,
  zip: null,
  teamID: null,
  userID: null
};

export const GET_HEALTH_SYSTEMS = 'GET_HEALTH_SYSTEMS';
export const GET_HEALTH_SYSTEMS_SUCCESS = 'GET_HEALTH_SYSTEMS_SUCCESS';
export const GET_HEALTH_SYSTEMS_FAILED = 'GET_HEALTH_SYSTEMS_FAILED';
export const CLEAR_HEALTH_SYSTEMS_ERROR = 'CLEAR_HEALTH_SYSTEMS_ERROR';
export const USER_NOT_APART_OF_A_HEALTH_SYSTEM = 'USER_NOT_APART_OF_A_HEALTH_SYSTEM'; // error code not action

export const GET_HEALTH_SYSTEM_INFO = 'GET_HEALTH_SYSTEM_INFO';
export const GET_HEALTH_SYSTEM_INFO_SUCCESS = 'GET_HEALTH_SYSTEM_INFO_SUCCESS';
export const GET_HEALTH_SYSTEM_INFO_FAILED = 'GET_HEALTH_SYSTEM_INFO_FAILED';

export const UPDATE_SELECTED_HEALTH_SYSTEM_INFO = 'UPDATE_SELECTED_HEALTH_SYSTEM_INFO';
export const UPDATE_SELECTED_HEALTH_SYSTEM_INFO_FOR_ONBOARDING = 'UPDATE_SELECTED_HEALTH_SYSTEM_INFO_FOR_ONBOARDING';
export const UPDATE_SELECTED_HEALTH_SYSTEM_ONBOARDING_ROLES = 'UPDATE_SELECTED_HEALTH_SYSTEM_ONBOARDING_ROLES';

export const OPEN_ADD_USER_MODAL = 'OPEN_ADD_USER_MODAL';
export const CLOSE_ADD_USER_MODAL = 'CLOSE_ADD_USER_MODAL';

export const OPEN_ASSIGN_CE_MODAL = 'OPEN_ASSIGN_CE_MODAL';
export const CLOSE_ASSIGN_CE_MODAL = 'CLOSE_ASSIGN_CE_MODAL';

export const OPEN_EDIT_TEAM_MODAL = 'OPEN_EDIT_TEAM_MODAL';
export const CLOSE_EDIT_TEAM_MODAL = 'CLOSE_EDIT_TEAM_MODAL';

export const OPEN_CANCEL_INVITE_MODAL = 'OPEN_CANCEL_INVITE_MODAL';
export const CLOSE_CANCEL_INVITE_MODAL = 'CLOSE_CANCEL_INVITE_MODAL';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

export const ASSIGN_USER_CE = 'ASSIGN_USER_CE';
export const ASSIGN_USER_CE_SUCCESS = 'ASSIGN_USER_CE_SUCCESS';
export const ASSIGN_USER_CE_FAILURE = 'ASSIGN_USER_CE_FAILURE';

export const ASSIGN_CE_USERS = 'ASSIGN_CE_USERS';
export const ASSIGN_CE_USERS_SUCCESS = 'ASSIGN_CE_USERS_SUCCESS';
export const ASSIGN_CE_USERS_FAILURE = 'ASSIGN_CE_USERS_FAILURE';

export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS';
export const UPDATE_USER_ROLE_ERROR = 'UPDATE_USER_ROLE_ERROR';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const GET_CE_LIST_FOR_HS_MODAL = 'GET_CE_LIST_FOR_HS_MODAL';
export const GET_CE_LIST_FOR_HS_MODAL_ERROR = 'GET_CE_LIST_FOR_HS_MODAL_ERROR';
export const GET_CE_LIST_FOR_HS_MODAL_SUCCESS = 'GET_CE_LIST_FOR_HS_MODAL_SUCCESS';

export const CANCEL_INVITE = 'CANCEL_INVITE';
export const CANCEL_INVITE_SUCCESS = 'CANCEL_INVITE_SUCCESS';
export const CANCEL_INVITE_FAILURE = 'CANCEL_INVITE_FAILURE';

export const OPEN_CHANGE_HEALTH_SYSTEM_MODAL = 'OPEN_CHANGE_HEALTH_SYSTEM_MODAL';
export const CLOSE_CHANGE_HEALTH_SYSTEM_MODAL = 'CLOSE_CHANGE_HEALTH_SYSTEM_MODAL';

export const OPEN_SEARCH_HEALTH_SYSTEMS_MODAL = 'OPEN_SEARCH_HEALTH_SYSTEMS_MODAL';
export const CLOSE_SEARCH_HEALTH_SYSTEMS_MODAL = 'CLOSE_SEARCH_HEALTH_SYSTEMS_MODAL';

export const SEARCH_HEALTH_SYSTEMS = 'SEARCH_HEALTH_SYSTEMS';
export const SEARCH_HEALTH_SYSTEMS_SUCCESS = 'SEARCH_HEALTH_SYSTEMS_SUCCESS';
export const SEARCH_HEALTH_SYSTEMS_FAILURE = 'SEARCH_HEALTH_SYSTEMS_FAILURE';

export const OPEN_ONBOARDING_INVITE_MODAL = 'OPEN_ONBOARDING_INVITE_MODAL';
export const CLOSE_ONBOARDING_INVITE_MODAL = 'CLOSE_ONBOARDING_INVITE_MODAL';

export const SEND_ONBOARDING_INVITE = 'SEND_ONBOARDING_INVITE';
export const SEND_ONBOARDING_INVITE_SUCCESS = 'SEND_ONBOARDING_INVITE_SUCCESS';
export const SEND_ONBOARDING_INVITE_FAILURE = 'SEND_ONBOARDING_INVITE_FAILURE';

export const UPDATE_CE_LEAD_CONTACT = 'UPDATE_CE_LEAD_CONTACT';
export const UPDATE_CE_LEAD_CONTACT_SUCCESS = 'UPDATE_CE_LEAD_CONTACT_SUCCESS';
export const UPDATE_CE_LEAD_CONTACT_FAILURE = 'UPDATE_CE_LEAD_CONTACT_FAILURE';

export const DISABLE_USER = 'DISABLE_USER';
export const DISABLE_USER_SUCCESS = 'DISABLE_USER_SUCCESS';
export const DISABLE_USER_FAILURE = 'DISABLE_USER_FAILURE';

export const ENABLE_USER = 'ENABLE_USER';
export const ENABLE_USER_SUCCESS = 'ENABLE_USER_SUCCESS';
export const ENABLE_USER_FAILURE = 'ENABLE_USER_FAILURE';

export const SET_PRESELECTED_HEALTH_SYSTEM_ID = 'SET_PRESELECTED_HEALTH_SYSTEM_ID';
export const SET_MOCK_NEW_AO = 'SET_MOCK_NEW_AO';

export enum RECIPIENT_TEAM_MANAGEMENT_USER_ROLES {
  ADMIN = 'Admin',
  SUBMITTER = 'Submitter',
  PAYMENT = 'Payment',
  VIEWER = 'Viewer',
}

export const RECIPIENT_USER_ROLE_OPTIONS = [
  { key: RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN, text: RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN, value: RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN },
  { key: RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.PAYMENT, text: RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.PAYMENT, value: RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.PAYMENT },
  { key: RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.SUBMITTER, text: RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.SUBMITTER, value: RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.SUBMITTER },
  { key: RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.VIEWER, text: RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.VIEWER, value: RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.VIEWER },
];

interface GetHealthSystemsAction {
  type: typeof GET_HEALTH_SYSTEMS
}

interface GetHealthSystemsSuccessAction {
  type: typeof GET_HEALTH_SYSTEMS_SUCCESS,
  payload: HealthSystemsResponse
}

interface GetHealthSystemsFailedAction {
  type: typeof GET_HEALTH_SYSTEMS_FAILED,
  payload: ErrorModel
}

interface ClearHealthSystemsErrorAction {
  type: typeof CLEAR_HEALTH_SYSTEMS_ERROR,
}


interface GetHealthSystemInfoAction {
  type: typeof GET_HEALTH_SYSTEM_INFO,
  payload: {
    healthSystemId: number,
    switchingHS: boolean | undefined
  }
}

interface GetHealthSystemInfoSuccessAction {
  type: typeof GET_HEALTH_SYSTEM_INFO_SUCCESS,
  payload: HealthSystemInfo
}

interface GetHealthSystemInfoFailedAction {
  type: typeof GET_HEALTH_SYSTEM_INFO_FAILED
  payload: ErrorModel
}

interface UpdateSelectedHealthSystemInfoAction {
  type: typeof UPDATE_SELECTED_HEALTH_SYSTEM_INFO,
  payload: HealthSystem
}

interface UpdateSelectedHealthSystemInfoForOnboardingAction {
  type: typeof UPDATE_SELECTED_HEALTH_SYSTEM_INFO_FOR_ONBOARDING,
  payload: HealthSystem
}

interface UpdateSelectedHealthSystemOnboardingRolesAction {
  type: typeof UPDATE_SELECTED_HEALTH_SYSTEM_ONBOARDING_ROLES,
  payload: string[]
}

interface OpenAddUserModalAction {
  type: typeof OPEN_ADD_USER_MODAL
}

interface CloseAddUserModalAction {
  type: typeof CLOSE_ADD_USER_MODAL
}

interface CloseAssignCEModalAction {
  type: typeof CLOSE_ASSIGN_CE_MODAL
  payload: boolean,
}

interface OpenAssignCEModalAction {
  type: typeof OPEN_ASSIGN_CE_MODAL,
  payload: {
    userId: number,
    ceIds: number[],
  }
}

interface CloseAssignCEModalAction {
  type: typeof CLOSE_ASSIGN_CE_MODAL
}

interface OpenEditTeamModalAction {
  type: typeof OPEN_EDIT_TEAM_MODAL,
  payload: CoveredEntity
}

interface CloseEditTeamModalAction {
  type: typeof CLOSE_EDIT_TEAM_MODAL
}

interface OpenCancelInviteModalAction {
  type: typeof OPEN_CANCEL_INVITE_MODAL,
  payload: TeamMember
}

interface CloseCancelInviteModalAction {
  type: typeof CLOSE_CANCEL_INVITE_MODAL
}

interface AddUserAction {
  type: typeof ADD_USER,
  payload: {
    email: string,
    role: string
  }
}

interface AddUserSuccessAction {
  type: typeof ADD_USER_SUCCESS,
  payload: PendingUser,
}

interface AddUserFailureAction {
  type: typeof ADD_USER_FAILURE,
  payload: ErrorModel
}

interface AssignUserCEAction {
  type: typeof ASSIGN_USER_CE
  payload: {
    userId: number,
    ceIds: number[]
  }
}

interface AssignUserCESuccessAction {
  type: typeof ASSIGN_USER_CE_SUCCESS,
  payload: string
}

interface AssignUserCEFailureAction {
  type: typeof ASSIGN_USER_CE_FAILURE,
  payload: ErrorModel
}

interface AssignCeUsersAction {
  type: typeof ASSIGN_CE_USERS
  payload: {
    ceId: number,
    userIds: number[]
  }
}

interface AssignCeUsersSuccessAction {
  type: typeof ASSIGN_CE_USERS_SUCCESS,
  payload: string
}

interface AssignCeUsersFailureAction {
  type: typeof ASSIGN_CE_USERS_FAILURE,
  payload: ErrorModel
}

interface UpdateLeadContactAction {
  type: typeof UPDATE_CE_LEAD_CONTACT,
  payload: {
    userId: number,
    ceId: number,
    id340B: string
  }
}

interface UpdateLeadContactSuccessAction {
  type: typeof UPDATE_CE_LEAD_CONTACT_SUCCESS,
  payload: {
    userId: number,
    ceId: number,
    id340B: string
  }
}

interface UpdateUserRoleAction {
  type: typeof UPDATE_USER_ROLE,
  payload: EditRoleModel
}

interface UpdateUserRoleSuccessAction {
  type: typeof UPDATE_USER_ROLE_SUCCESS
}

interface UpdateUserRoleFailedAction {
  type: typeof UPDATE_USER_ROLE_ERROR,
  payload: ErrorModel
}

interface UpdateUserAction {
  type: typeof UPDATE_USER,
  payload: EditUserModel
}

interface UpdateUserSuccessAction {
  type: typeof UPDATE_USER_SUCCESS,
  payload: any
}

interface UpdateUserFailedAction {
  type: typeof UPDATE_USER_ERROR
  payload: ErrorModel
}

interface CancelInviteAction {
  type: typeof CANCEL_INVITE,
  payload: {
    userId: number,
    email: string
  }
}

interface CancelInviteSuccessAction {
  type: typeof CANCEL_INVITE_SUCCESS,
}

interface CancelInviteFailureAction {
  type: typeof CANCEL_INVITE_FAILURE,
  payload: ErrorModel
}

interface GetCEListForHSModalAction {
  type: typeof GET_CE_LIST_FOR_HS_MODAL,
  payload: number
}

interface GetCEListForHSModalError {
  type: typeof GET_CE_LIST_FOR_HS_MODAL_ERROR,
  payload: ErrorModel
}

interface GetCEListForHSModalSuccessAction {
  type: typeof GET_CE_LIST_FOR_HS_MODAL_SUCCESS,
  payload: {
    coveredEntities: CoveredEntity[]
  }
}

interface OpenChangeHealthSystemsModalAction {
  type: typeof OPEN_CHANGE_HEALTH_SYSTEM_MODAL,
  payload: boolean
}

interface CloseChangeHealthSystemsModalAction {
  type: typeof CLOSE_CHANGE_HEALTH_SYSTEM_MODAL
}

interface OpenSearchHealthSystemsModalAction {
  type: typeof OPEN_SEARCH_HEALTH_SYSTEMS_MODAL,
  payload: boolean
}

interface CloseSearchHealthSystemsModalAction {
  type: typeof CLOSE_SEARCH_HEALTH_SYSTEMS_MODAL
}

interface SearchHealthSystemsAction {
  type: typeof SEARCH_HEALTH_SYSTEMS,
  payload: HealthSystemSearchInput
}

interface SearchHealthSystemsSuccessAction {
  type: typeof SEARCH_HEALTH_SYSTEMS_SUCCESS,
  payload: HealthSystem[]
}

interface SearchHealthSystemsFailureAction {
  type: typeof SEARCH_HEALTH_SYSTEMS_FAILURE,
  payload: ErrorModel
}

interface OpenOnboardingInviteModal {
  type: typeof OPEN_ONBOARDING_INVITE_MODAL,
  payload: string
}

interface CloseOnboardingInviteModal {
  type: typeof CLOSE_ONBOARDING_INVITE_MODAL
}

interface SendOnboardingInviteAction {
  type: typeof SEND_ONBOARDING_INVITE,
  payload: {
    user: OnboardingUserInviteModel,
    role: string
  }
}

interface SendOnboardingInviteSuccessAction {
  type: typeof SEND_ONBOARDING_INVITE_SUCCESS,
}

interface SendOnboardingInviteFailureAction {
  type: typeof SEND_ONBOARDING_INVITE_FAILURE,
  payload: ErrorModel
}

interface DisableUser {
  type: typeof DISABLE_USER,
  payload: {
    teamId: number,
    userId: number
  }
}

interface DisableUserSuccess {
  type: typeof DISABLE_USER_SUCCESS,
  payload: number
}

interface DisableUserFailure {
  type: typeof DISABLE_USER_FAILURE,
  payload: string
}


interface EnableUser {
  type: typeof ENABLE_USER,
  payload: {
    teamId: number,
    userId: number
  }
}

interface EnableUserSuccess {
  type: typeof ENABLE_USER_SUCCESS,
  payload: number
}

interface EnableUserFailure {
  type: typeof ENABLE_USER_FAILURE,
  payload: string
}

interface SetPreSelectedHealthSystemId {
  type: typeof SET_PRESELECTED_HEALTH_SYSTEM_ID,
  payload: number
}
interface SetMockNewAOAction {
  type: typeof SET_MOCK_NEW_AO
}

export type RecipientTeamManagementActionTypes =
  GetHealthSystemsAction
  | GetHealthSystemsSuccessAction
  | GetHealthSystemsFailedAction
  | GetHealthSystemInfoAction
  | GetHealthSystemInfoSuccessAction
  | GetHealthSystemInfoFailedAction
  | ClearHealthSystemsErrorAction
  | UpdateSelectedHealthSystemInfoAction
  | UpdateSelectedHealthSystemInfoForOnboardingAction
  | UpdateSelectedHealthSystemOnboardingRolesAction
  | OpenAddUserModalAction
  | CloseAddUserModalAction
  | OpenAssignCEModalAction
  | CloseAssignCEModalAction
  | OpenEditTeamModalAction
  | CloseEditTeamModalAction
  | OpenCancelInviteModalAction
  | CloseCancelInviteModalAction
  | AddUserAction
  | AddUserSuccessAction
  | AddUserFailureAction
  | AssignUserCEAction
  | AssignUserCESuccessAction
  | AssignUserCEFailureAction
  | AssignCeUsersAction
  | AssignCeUsersSuccessAction
  | AssignCeUsersFailureAction
  | UpdateLeadContactAction
  | UpdateLeadContactSuccessAction
  | UpdateUserRoleAction
  | UpdateUserRoleSuccessAction
  | UpdateUserRoleFailedAction
  | UpdateUserAction
  | UpdateUserSuccessAction
  | UpdateUserFailedAction
  | CancelInviteAction
  | CancelInviteSuccessAction
  | CancelInviteFailureAction
  | GetCEListForHSModalAction
  | GetCEListForHSModalError
  | GetCEListForHSModalSuccessAction
  | OpenChangeHealthSystemsModalAction
  | CloseChangeHealthSystemsModalAction
  | OpenSearchHealthSystemsModalAction
  | CloseSearchHealthSystemsModalAction
  | SearchHealthSystemsAction
  | SearchHealthSystemsSuccessAction
  | SearchHealthSystemsFailureAction  
  | OpenOnboardingInviteModal
  | CloseOnboardingInviteModal
  | SendOnboardingInviteAction
  | SendOnboardingInviteSuccessAction
  | SendOnboardingInviteFailureAction
  | DisableUser
  | DisableUserSuccess
  | DisableUserFailure
  | EnableUser
  | EnableUserSuccess
  | EnableUserFailure
  | SetPreSelectedHealthSystemId
  | SetMockNewAOAction;