import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Form, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { ChangePasswordModel } from '../../store/password/types';
import { createToast, TOAST_TYPE, Toast } from '../../store/toast';
import { changePassword, resetChangePasswordStatus } from '../../store/password/actionCreators'
import { RootState } from '../../store/RootState';
import PasswordValidation from '../common/PasswordValidation';
import CloseButton from '../common/CloseButton';

interface ChangePasswordState {
  showOldPassword: boolean;
  showNewPassword: boolean;
  showConfirmedPassword: boolean;
  showPasswordValidation: boolean;
  isPasswordValid: boolean;
}

interface ChangePasswordProps {
  changePasswordErrorText: string;
  changePasswordSuccess: boolean;
  isLoading: boolean;
  changePassword: (ChangePasswordModel: ChangePasswordModel) => void;
  createToast: (toast: Toast) => void;
  closeModal: () => void;
  resetChangePasswordStatus: () => void;
}

interface ChangePasswordInput {
  oldPassword: string,
  newPassword: string,
  confirmPassword: string,
}

const initialValues: ChangePasswordInput = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
}

const schema = yup.object().shape({
  oldPassword: yup
    .string()
    .required()
    .max(100),
  newPassword: yup
    .string()
    .required()
    .max(100),
  confirmPassword: yup
    .string()
    .required()
    .max(100)
})

export class ChangePassword extends React.Component<ChangePasswordProps, ChangePasswordState>{
  constructor (props: Readonly<ChangePasswordProps>) {
    super(props)
    this.state = {
      showOldPassword: false,
      showNewPassword: false,
      showConfirmedPassword: false,
      showPasswordValidation: false,
      isPasswordValid: false,
    }
  }

  componentDidUpdate () {
    if (this.props.changePasswordSuccess) {
      this.props.createToast({
        text: 'Password successfully changed.',
        type: TOAST_TYPE.SUCCESS
      })
      this.props.closeModal();
    }
  }

  handlePasswordValidation = (isValid: boolean) => {
    this.setState({ isPasswordValid: isValid });
  }

  handleChangePasswordSubmit = (values: ChangePasswordInput) => {
    if (this.state.isPasswordValid && values.oldPassword && values.confirmPassword && values.newPassword === values.confirmPassword && values.newPassword !== values.oldPassword) {
      const changePasswordModel: ChangePasswordModel = {
        currentPassword: values.oldPassword,
        newPassword: values.confirmPassword
      }
      //call API
      this.props.changePassword(changePasswordModel)
    }
  }

  resetErrorState = () => {
    if (this.props.changePasswordErrorText) {
      this.props.resetChangePasswordStatus();
    }
  }

  render () {
    const { showOldPassword, showNewPassword, showConfirmedPassword, showPasswordValidation, isPasswordValid } = this.state
    const { closeModal, changePasswordErrorText, isLoading } = this.props;

    return (
      <div className="change-password-modal-wrapper">
        <div className="change-password-modal">
          <div className='login-form-container'>
            {isLoading && <Dimmer className="dimmer" active inverted><Loader inverted content='Loading' /></Dimmer>}

            <Formik
              initialValues={initialValues}
              validateOnMount={true}
              validationSchema={schema}
              onSubmit={(values) => {
                this.handleChangePasswordSubmit(values);
              }}>
              {props => (

                <Form className="login-form" onSubmit={props.handleSubmit}>

                  <div className="login-form-header">
                    <div className="flex-grow">Change Password</div>
                    <CloseButton onClick={closeModal} />
                  </div>

                  <div className="login-form-body">
                    <Form.Input
                      data-private
                      name="oldPassword"
                      label={
                        <div className="password-label">
                          <label>Old Password</label>
                          <button className={cx('app-button link-text', { 'hidden': !props.values.oldPassword })} type="button" onClick={() => this.setState({ showOldPassword: !showOldPassword })}>
                            {showOldPassword ? 'Hide' : 'Show'}
                          </button>
                        </div>
                      }
                      onChange={props.handleChange}
                      className={cx({ 'input-error-field': changePasswordErrorText !== '' })}
                      type={showOldPassword ? 'text' : 'password'}
                      value={props.values.oldPassword}
                      onFocus={this.resetErrorState}
                    />

                    {changePasswordErrorText &&
                      <div className="input-error-text">Incorrect old password</div>
                    }

                    <Form.Input
                      data-private
                      name="newPassword"
                      label={
                        <div className="password-label">
                          <label>New Password</label>
                          <button className={cx('app-button link-text', { 'hidden': !props.values.newPassword })} type="button" onClick={() => this.setState({ showNewPassword: !showNewPassword })}>
                            {showNewPassword ? 'Hide' : 'Show'}
                          </button>
                        </div>
                      }
                      onChange={props.handleChange}
                      onFocus={() => {
                        this.setState({ showPasswordValidation: true })
                      }}
                      className={cx({ 'input-error-field': props.values.oldPassword && props.values.newPassword === props.values.oldPassword })}
                      type={showNewPassword ? 'text' : 'password'}
                      value={props.values.newPassword}
                    />

                    {props.values.oldPassword && props.values.newPassword === props.values.oldPassword &&
                      <div className="input-error-text">You cannot use your previous password</div>
                    }

                    {showPasswordValidation && <PasswordValidation password={props.values.newPassword} onValidation={this.handlePasswordValidation} />}

                    <Form.Input
                      data-private
                      name="confirmPassword"
                      label={
                        <div className="password-label">
                          <label>Confirm Password</label>
                          <button className={cx('app-button link-text', { 'hidden': !props.values.confirmPassword })} type="button" onClick={() => this.setState({ showConfirmedPassword: !showConfirmedPassword })}>
                            {showConfirmedPassword ? 'Hide' : 'Show'}
                          </button>
                        </div>
                      }
                      onChange={props.handleChange}
                      type={showConfirmedPassword ? 'text' : 'password'}
                      value={props.values.confirmPassword}
                    />

                    {props.values.confirmPassword && props.values.confirmPassword !== props.values.newPassword &&
                      <div className='input-error-text'>Password does not match</div>
                    }


                  </div>
                  <div className="login-form-footer">
                    <button className="app-button button-white cancel-button" type="button" onClick={closeModal} >Cancel</button>
                    <button className="app-button button-dark confirm-password-button" type="submit" disabled={!isPasswordValid || props.values.newPassword !== props.values.confirmPassword || props.values.oldPassword === props.values.newPassword}>Change Password</button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state: RootState) => ({
  changePasswordErrorText: state.PasswordState.changePasswordErrorText,
  changePasswordSuccess: state.PasswordState.changePasswordSuccess,
  isLoading: state.PasswordState.isLoading
})

const mapDispatchToProps = (dispatch: any) => ({
  createToast: (toast: Toast) => dispatch(createToast(toast)),
  changePassword: (ChangePasswordModel: ChangePasswordModel) => dispatch(changePassword(ChangePasswordModel)),
  resetChangePasswordStatus: () => dispatch(resetChangePasswordStatus())
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
