import {
  GET_REVERSALS,
  GET_REVERSALS_FAILURE,
  GET_REVERSALS_SUCCESS,
  RESET_REVERSALS,
  RESET_REVERSALS_TRANSACTIONS,
  ReversalActionTypes,
  REVERSALS_TAB,
  UPDATE_REVERSALS_FILTER,
  UPDATE_REVERSALS_PAGE,
  UPDATE_REVERSALS_SORT
} from './constants';
import { SortingFilter } from '../appcommon/types';
import { ErrorModel } from '../applicationTypes';
import { DiscountRequestResponseModel, TransactionsFilterModel } from '../transactionscommon/types';

export function getReversals (tab: REVERSALS_TAB): ReversalActionTypes {
  return {
    type: GET_REVERSALS,
    payload: tab,
  }
}

export function getReversalsSuccess (response: DiscountRequestResponseModel): ReversalActionTypes {
  return {
    type: GET_REVERSALS_SUCCESS,
    payload: response
  }
}

export function getReversalsFailure (error: ErrorModel): ReversalActionTypes {
  return {
    type: GET_REVERSALS_FAILURE,
    payload: error
  }
}

export function updateReversalsPage (page: number): ReversalActionTypes {
  return {
    type: UPDATE_REVERSALS_PAGE,
    payload: page
  }
}

export function updateReversalsSort (sortFilter: SortingFilter): ReversalActionTypes {
  return {
    type: UPDATE_REVERSALS_SORT,
    payload: sortFilter
  }
}

export function updateReversalsFilter (tab: REVERSALS_TAB, filter: TransactionsFilterModel): ReversalActionTypes {
  return {
    type: UPDATE_REVERSALS_FILTER,
    payload: {
      tab,
      filter
    }
  }
}

export function resetReversalsTransactions (): ReversalActionTypes {
  return {
    type: RESET_REVERSALS_TRANSACTIONS
  }
}

export function resetReversals (): ReversalActionTypes {
  return {
    type: RESET_REVERSALS
  }
}
