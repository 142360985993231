import { all } from 'redux-saga/effects';
import watchAllRequestCenter from './requestcenter/sagas';
import watchAllAppCommon from './appcommon/sagas';
import watchAllToasts from './toast/sagas';
import watchAllLogins from './login/sagas';
import watchAllPasswords from './password/sagas';
import watchAllAccountInformation from './account/sagas';
import watchAllPayments from './payments/sagas';
import watchAllRemittance from './remittance/sagas';
import watchAllOnboarding from './onboarding/sagas';
import watchAllReversals from './reversals/sagas';
import watchAllTeamManagement from './teammanagement/sagas';
import watchAllPharmacyManagement from './coveredentityinfo/pharmacymanagement/sagas';
import watchAllMDRP from './mdrp/sagas';
import watchAllTransactionsCommon from './transactionscommon/sagas';

function* rootSaga () {
  yield all([
    watchAllRequestCenter(),
    watchAllAppCommon(),
    watchAllToasts(),
    watchAllLogins(),
    watchAllPasswords(),
    watchAllAccountInformation(),
    watchAllPayments(),
    watchAllRemittance(),
    watchAllOnboarding(),
    watchAllReversals(),
    watchAllTeamManagement(),
    watchAllPharmacyManagement(),
    watchAllMDRP(),
    watchAllTransactionsCommon(),
  ])
}

export default rootSaga;
