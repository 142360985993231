import React from 'react';
import { Link } from 'react-router-dom';
import { AlertTriangle } from 'react-feather';

const ResetPasswordError = (props: { message: string }) => {
  return (
    <div className="login-page reset-password-error">
      <div className="login-page-layout">
        <div className="login-page-column-right">
          <div className="login-form-container">
            <div className="login-form-header">Create your password</div>
            <div className="login-form-body">
              <div className="message-box error-box">
                <AlertTriangle size={20} />
                <div className="message-box-text">{props.message}</div>
              </div>
              <div>Looks like there was an error with this link. You&apos;ll have to request a new link to reset your password.</div>
            </div>
            <div className="login-form-footer">
              <Link to="/forgotpassword">
                <button className="app-button cta" type="submit">
                  Request a New Link
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordError;
