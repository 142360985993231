import React from 'react'

export interface SummaryHeaderProps {
  beforeText: string;
  amountText: string;
  afterText: string;
}

const SummaryHeader: React.FunctionComponent<SummaryHeaderProps> = (props: SummaryHeaderProps) => {
  return (
    <div className="summary-header">
      {props.beforeText}&nbsp;
      <span className="summary-header-total">
        {props.amountText}
      </span>&nbsp;
      {props.afterText}
    </div>
  )
}

export default SummaryHeader;