import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import moment from 'moment';
import { Dimmer, Loader } from 'semantic-ui-react';
import PageHeader from '../../common/navigation/PageHeader';
import { InfoBanner } from '../../common/errors/InfoBanner';
import { RootState } from '../../../store/RootState';
import { SUPPORT_EMAIL_LINK, SUPPORT_EMAIL } from '../../../store/applicationConstants';
import { getPaymentAlertInfo } from '../../../store/payments/common/actionCreators';
import { PaymentAlertModel } from '../../../store/payments/common/types';
import { getRemittanceTransactionsCounts, getRemittancePeriodDetails, getRemittanceTransactions, updateRemittancePeriodDetailsFilter, resetRemittanceDetails, resetRemittanceTransactions } from '../../../store/remittance/actionCreators';
import { RemittanceDetailsCountsModel, RemittanceDetailsHeaderFilterModel, RemittancePeriodSummary } from '../../../store/remittance/types';
import { REMITTANCE_DETAILS_TAB } from '../../../store/remittance/constants';
import RemittanceTransactionsTabs, { RemittanceDetailsTabMap } from '../Transactions/RemittanceTransactionsTabs';
import RemittanceCoveredEntityFilter from './RemittanceCoveredEntityFilter';
import RemittanceManufacturerFilter from './RemittanceManufacturerFilter';
import { isRemittanceTransactionsLoading } from '../../../store/remittance/selectors';

export interface RemittancePeriodDetailsProps extends RouteComponentProps {
  activeIndex: REMITTANCE_DETAILS_TAB,
  year: number;
  month: number;
  period: number;
  summary?: RemittancePeriodSummary;
  filter: RemittanceDetailsHeaderFilterModel;
  counts: RemittanceDetailsCountsModel;
  isLoading: boolean;
  errorText: string;
  paymentAlert: PaymentAlertModel;
  getRemittancePeriodDetails: (year: number, month: number, period: number) => void;
  getRemittanceTransactionsCounts: () => void;
  getRemittanceTransactions: (tab: REMITTANCE_DETAILS_TAB) => void;
  updateRemittancePeriodDetailsFilter: (filter: RemittanceDetailsHeaderFilterModel) => void;
  resetRemittanceDetails: () => void;
  resetRemittanceTransactions: () => void;
  getPaymentAlertInfo: () => void;
}

export class RemittancePeriodDetails extends React.Component<RemittancePeriodDetailsProps> {

  componentDidMount () {
    this.parseFilterFromQueryString();

    this.props.getPaymentAlertInfo();
    this.props.getRemittancePeriodDetails(this.props.year, this.props.month, this.props.period);
  }

  componentDidUpdate (prevProps: RemittancePeriodDetailsProps) {
    if (this.props.summary && !prevProps.summary) {
      this.props.getRemittanceTransactionsCounts();
      this.props.getRemittanceTransactions(this.props.activeIndex);
    }
  }

  componentWillUnmount () {
    this.props.resetRemittanceDetails();
  }

  parseFilterFromQueryString = () => {
    const queryString = new URLSearchParams(this.props.location.search);
    if (queryString.has('coveredentity') || queryString.has('remittanceid')) {
      const filter = { ...this.props.filter };

      if (queryString.has('coveredentity')) {
        filter.coveredEntity = queryString.get('coveredentity') as string;
      }

      if (queryString.has('remittanceid')) {
        filter.remittanceId = parseInt(queryString.get('remittanceid') as string);
      }

      this.props.updateRemittancePeriodDetailsFilter(filter);
    }
  }


  handleFilterChange = (filter: RemittanceDetailsHeaderFilterModel) => {
    this.props.resetRemittanceTransactions();
    this.props.updateRemittancePeriodDetailsFilter(filter);
    this.props.getRemittanceTransactionsCounts();
    this.props.getRemittanceTransactions(this.props.activeIndex);
  }

  handleTabChange = (tab: REMITTANCE_DETAILS_TAB) => {
    const path = Object.keys(RemittanceDetailsTabMap).find(k => RemittanceDetailsTabMap[k] === tab);
    this.props.history.push(`/recipients/remittance/period/${this.props.year}/${this.props.month}/${this.props.period}/${path}`);
  }

  getHeaderDateRange = () => {
    if (this.props.summary) {
      return `${moment(this.props.summary.startDate).format('M/D')} - ${moment(this.props.summary.endDate).format('M/D')}/${moment(this.props.summary.endDate).year()}`
    }
    return '';
  }

  render () {
    const { activeIndex, isLoading, paymentAlert, summary, filter, counts } = this.props;

    return (

      <div className="remittance-advice-home">
        <div className="remittance-advice-home-page-header">
          <PageHeader text={`Remittance Advice ${this.getHeaderDateRange()}`} defaultBack />
        </div>
        {paymentAlert?.description?.length > 0 &&
          <div className="info-banner-content">
            <InfoBanner
              title={'You have one or more payments waiting for you.'}
              messageWithNode={<span>Go to <Link className="link" to='/recipients/payments/settings'>Payment Settings</Link> or contact kalderos support at <a href={SUPPORT_EMAIL_LINK}>{SUPPORT_EMAIL}</a> to resolve.</span>}
            />
          </div>
        }
        <div className="remittance-advice-home-main-container">

          {isLoading &&
            <Dimmer active inverted>
              <Loader className="remittance-advice-loader" inverted content='Loading' />
            </Dimmer>
          }

          {summary &&
            <div className='remittance-request-center'>
              <div className='remittance-filter-row'>

                <RemittanceCoveredEntityFilter
                  period={summary}
                  selectedCoveredEntity={filter.coveredEntity}
                  onFilterChange={(coveredEntity) =>
                    this.handleFilterChange({ coveredEntity: coveredEntity, remittanceId: 0 })
                  } />
                <RemittanceManufacturerFilter
                  period={summary}
                  selectedRemittanceId={filter.remittanceId}
                  selectedCoveredEntity={filter.coveredEntity}
                  onFilterChange={(remittanceId) =>
                    this.handleFilterChange({ ...filter, remittanceId: remittanceId })
                  } />
              </div>

              <RemittanceTransactionsTabs
                activeTab={activeIndex}
                isLoading={isLoading}
                counts={counts}
                handleTabChange={this.handleTabChange}
              />

            </div>
          }
        </div>
      </div>

    )
  }
}

const mapStateToProps = (state: RootState) => ({
  summary: state.RemittanceState.periodDetails.summary,
  filter: state.RemittanceState.periodDetails.filter,
  counts: state.RemittanceState.counts,
  isLoading: state.RemittanceState.periodDetails.isLoading || isRemittanceTransactionsLoading(state),
  errorText: state.RemittanceState.periodDetails.errorText,
  paymentAlert: state.PaymentsState.common.paymentAlert,

});

const mapDispatchToProps = (dispatch: any) => ({
  getRemittancePeriodDetails: (year: number, month: number, period: number) => dispatch(getRemittancePeriodDetails(year, month, period)),
  getRemittanceTransactionsCounts: () => dispatch(getRemittanceTransactionsCounts()),
  getRemittanceTransactions: (tab: REMITTANCE_DETAILS_TAB) => dispatch(getRemittanceTransactions(tab)),
  updateRemittancePeriodDetailsFilter: (filter: RemittanceDetailsHeaderFilterModel) => dispatch(updateRemittancePeriodDetailsFilter(filter)),
  resetRemittanceDetails: () => dispatch(resetRemittanceDetails()),
  resetRemittanceTransactions: () => dispatch(resetRemittanceTransactions()),
  getPaymentAlertInfo: () => dispatch(getPaymentAlertInfo())
})

export default connect(mapStateToProps, mapDispatchToProps)(RemittancePeriodDetails)
