import { 
  CLEAR_MANUFACTURER_DISPUTES,
  DisputesCenterMdrpActionTypes,
  GET_DISPUTES_CENTER_SUMMARY,
  GET_DISPUTES_CENTER_SUMMARY_FAILURE,
  GET_DISPUTES_CENTER_SUMMARY_SUCCESS,
  GET_MANUFACTURER_DISPUTES,
  GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS,
  GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS_FAILURE,
  GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS_SUCCESS,
  GET_MANUFACTURER_DISPUTES_FAILURE,
  GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS,
  GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS_FAILURE,
  GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS_SUCCESS,
  GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS,
  GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS_FAILURE,
  GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS_SUCCESS,
  GET_MANUFACTURER_DISPUTES_SUCCESS,
  GET_MANUFACTURER_SUMMARIES,
  GET_MANUFACTURER_SUMMARIES_FAILURE,
  GET_MANUFACTURER_SUMMARIES_SUCCESS,
  GET_MANUFACTURER_SUMMARY,
  GET_MANUFACTURER_SUMMARY_FAILURE,
  GET_MANUFACTURER_SUMMARY_SUCCESS,
  UPDATE_FILTERS,
  UPDATE_MANUFACTURER_DISPUTES_FILTERS,
  UPDATE_MANUFACTURER_DISPUTES_PAGE_NUMBER,
  UPDATE_MANUFACTURER_DISPUTES_SELECTED_CLAIM,
  UPDATE_MANUFACTURER_DISPUTES_SORT,
  UPDATE_SINGLE_CLAIM_RESPONSE,
  UPDATE_SINGLE_CLAIM_RESPONSE_FAILURE,
  UPDATE_SINGLE_CLAIM_RESPONSE_SUCCESS
} from './constants'
import { AdvancedFilterOptionsModel, ClaimModel, DisputesSummaryModel, ManufacturerDisputesFilterModel, ManufacturerDisputesModel, ManufacturerSummaryModel, PrimaryFilterOptionsModel, ResponseTotalsModel } from './types'
import { ErrorModel } from '../../applicationTypes'

export function getDisputesCenterSummary (stateCode: string) : DisputesCenterMdrpActionTypes {
  return {
    type: GET_DISPUTES_CENTER_SUMMARY,
    payload: stateCode
  }
}

export function getDisputesCenterSummarySuccess (summary: DisputesSummaryModel): DisputesCenterMdrpActionTypes {
  return {
    type: GET_DISPUTES_CENTER_SUMMARY_SUCCESS,
    payload: summary
  }
}

export function getDisputesCenterSummaryFailure (errorModel: ErrorModel ): DisputesCenterMdrpActionTypes {
  return {
    type: GET_DISPUTES_CENTER_SUMMARY_FAILURE,
    payload: errorModel
  }
}

export function getManufacturerSummaries (stateCode: string): DisputesCenterMdrpActionTypes {
  return {
    type: GET_MANUFACTURER_SUMMARIES,
    payload: stateCode
  }
}

export function getManufacturerSummariesSuccess (summaries: ManufacturerSummaryModel[]): DisputesCenterMdrpActionTypes {
  return {
    type: GET_MANUFACTURER_SUMMARIES_SUCCESS,
    payload: summaries
  }
}


export function getManufacturerSummariesFailure (errorModel: ErrorModel): DisputesCenterMdrpActionTypes {
  return {
    type: GET_MANUFACTURER_SUMMARIES_FAILURE,
    payload: errorModel
  }
}

export function getManufacturerSummary (stateCode: string, manufacturerId: number): DisputesCenterMdrpActionTypes {
  return {
    type: GET_MANUFACTURER_SUMMARY,
    payload: { stateCode, manufacturerId }
  }
}

export function getManufacturerSummarySuccess (summary: ManufacturerSummaryModel): DisputesCenterMdrpActionTypes {
  return {
    type: GET_MANUFACTURER_SUMMARY_SUCCESS,
    payload: summary
  }
}

export function getManufacturerSummaryFailure (errorModel: ErrorModel): DisputesCenterMdrpActionTypes {
  return {
    type: GET_MANUFACTURER_SUMMARY_FAILURE,
    payload: errorModel
  }
}

export function getManufacturerDisputes (stateCode: string, manufacturerId: number, disputeStatus: string, activeTabIndex: number): DisputesCenterMdrpActionTypes {
  return {
    type: GET_MANUFACTURER_DISPUTES,
    payload: { stateCode, manufacturerId, disputeStatus, activeTabIndex }
  }
}

export function getManufacturerDisputesSuccess (claims: ManufacturerDisputesModel): DisputesCenterMdrpActionTypes {
  return {
    type: GET_MANUFACTURER_DISPUTES_SUCCESS,
    payload: claims
  }
}

export function getManufacturerDisputesFailure (errorModel: ErrorModel): DisputesCenterMdrpActionTypes {
  return {
    type: GET_MANUFACTURER_DISPUTES_FAILURE,
    payload: errorModel
  }
}

export function getManufacturerDisputesAdvancedFilterOptions (stateCode: string, manufacturerId: number, disputeStatus: string, filters: ManufacturerDisputesFilterModel): DisputesCenterMdrpActionTypes {
  return {
    type: GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS,
    payload: { stateCode, manufacturerId, disputeStatus, filters }
  }
}

export function getManufacturerDisputesAdvancedFilterOptionsSuccess (filterOptions: AdvancedFilterOptionsModel): DisputesCenterMdrpActionTypes {
  return {
    type: GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS_SUCCESS,
    payload: filterOptions
  }
}

export function getManufacturerDisputesAdvancedFilterOptionsFailure (errorModel: ErrorModel): DisputesCenterMdrpActionTypes {
  return {
    type: GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS_FAILURE,
    payload: errorModel
  }
}

export function getManufacturerDisputesPrimaryFilterOptions (stateCode: string, manufacturerId: number, disputeStatus: string): DisputesCenterMdrpActionTypes {
  return {
    type: GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS,
    payload: { stateCode, manufacturerId, disputeStatus }
  }
}

export function getManufacturerDisputesPrimaryFilterOptionsSuccess (filterOptions: PrimaryFilterOptionsModel): DisputesCenterMdrpActionTypes {
  return {
    type: GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS_SUCCESS,
    payload: filterOptions
  }
}

export function getManufacturerDisputesPrimaryFilterOptionsFailure (errorModel: ErrorModel): DisputesCenterMdrpActionTypes {
  return {
    type: GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS_FAILURE,
    payload: errorModel
  }
}

export function updateFilters (activeTab: string, filters: ManufacturerDisputesFilterModel): DisputesCenterMdrpActionTypes {
  return {
    type: UPDATE_FILTERS,
    payload: { activeTab, filters }
  }
}

export function updateManufacturerDisputesFilters (stateCode: string, manufacturerId: number, disputeStatus: string, activeTab: string, filters: ManufacturerDisputesFilterModel): DisputesCenterMdrpActionTypes {
  return {
    type: UPDATE_MANUFACTURER_DISPUTES_FILTERS,
    payload: { stateCode, manufacturerId, disputeStatus, activeTab, filters }
  }
}

export function updateManufacturerDisputesPageNumber (pageNumber: number): DisputesCenterMdrpActionTypes {
  return {
    type: UPDATE_MANUFACTURER_DISPUTES_PAGE_NUMBER,
    payload: pageNumber
  }
}

export function updateManufacturerDisputesSort (stateCode: string, manufacturerId: number, disputeStatus: string, isSortAsc: boolean, sortBy: string): DisputesCenterMdrpActionTypes {
  return {
    type: UPDATE_MANUFACTURER_DISPUTES_SORT,
    payload: { stateCode, manufacturerId, disputeStatus, isSortAsc, sortBy }
  }
}

export function updateManufacturerDisputesSelectedClaim (index: number): DisputesCenterMdrpActionTypes {
  return {
    type: UPDATE_MANUFACTURER_DISPUTES_SELECTED_CLAIM,
    payload: index
  }
}

export function updateSingleClaimResponse (stateCode: string, manufacturerId: number, claimId: number, stateResponse: string, index: number) : DisputesCenterMdrpActionTypes {
  return {
    type: UPDATE_SINGLE_CLAIM_RESPONSE,
    payload: { stateCode, manufacturerId, claimId, stateResponse, index }
  }
}

export function updateSingleClaimResponseSuccess (index: number, claim: ClaimModel) : DisputesCenterMdrpActionTypes {
  return {
    type: UPDATE_SINGLE_CLAIM_RESPONSE_SUCCESS,
    payload: { index, claim }
  }
}

export function updateSingleClaimResponseFailure (index: number, errorModel : ErrorModel) : DisputesCenterMdrpActionTypes {
  return {
    type: UPDATE_SINGLE_CLAIM_RESPONSE_FAILURE,
    payload: { index, errorModel }
  }
}

export function getManufacturerDisputesResponseTotals (stateCode: string, manufacturerId: number): DisputesCenterMdrpActionTypes {
  return {
    type: GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS,
    payload: { stateCode, manufacturerId }
  }
}

export function getManufacturerDisputesResponseTotalsSuccess (responseTotals: ResponseTotalsModel): DisputesCenterMdrpActionTypes {
  return {
    type: GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS_SUCCESS,
    payload: responseTotals
  }
}

export function getManufacturerDisputesResponseTotalsFailure (errorModel: ErrorModel): DisputesCenterMdrpActionTypes {
  return {
    type: GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS_FAILURE,
    payload: errorModel
  }
}

export function clearManufacturerDisputes (): DisputesCenterMdrpActionTypes {
  return {
    type: CLEAR_MANUFACTURER_DISPUTES
  }
}
