import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import cx from 'classnames';
import { Modal, Button, Form, Dimmer, Loader } from 'semantic-ui-react';
import { RootState } from '../../../store/RootState';
import { sendOnboardingInvite, closeOnboardingInviteModal } from '../../../store/teammanagement/actionCreators';
import { OnboardingUserInviteModel } from '../../../store/teammanagement/types';
import { ROLES } from '../../../store/applicationConstants';

export interface OnboardingInviteModalProps {
  isOpen: boolean,
  role: string,
  isLoading: boolean,
  errorText: string,
  sendOnboardingInvite: (user: OnboardingUserInviteModel, role: string) => void;
  closeOnboardingInviteModal: () => void;
}

const initialValues: OnboardingUserInviteModel = {
  firstName: '',
  lastName: '',
  email: '',
}

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .max(100),
  lastName: yup
    .string()
    .required()
    .max(100),
  email: yup
    .string()
    .email()
    .required()
    .max(100),
});

export const OnboardingInviteModal: React.FunctionComponent<OnboardingInviteModalProps> = ({ isOpen, role, isLoading, errorText, sendOnboardingInvite, closeOnboardingInviteModal }: OnboardingInviteModalProps) => {

  const [apiErrorText, setApiErrorText] = useState(errorText);

  useEffect(() => {
    setApiErrorText(errorText)
  }, [errorText]);

  function handleCancelClick () {
    closeOnboardingInviteModal();
  }

  function handleOnSubmit (input: OnboardingUserInviteModel) {
    sendOnboardingInvite(input, role);
  }

  const roleText = role === ROLES.RECIPIENT_ACCOUNT_ADMIN ? 'Account Admin' : role === ROLES.RECIPIENT_CONTROLLER ? 'Controller' : '';

  return (
    <Modal className="team-management-modal" open={isOpen}>
      <Formik
        initialValues={initialValues}
        validateOnMount={true}
        validationSchema={schema}
        onSubmit={(values) => {
          handleOnSubmit(values);
        }}>
        {props => {

          const formErrorText = (props.errors.email && props.touched.email && props.values.email.length > 0) ? 'That is not a valid email address' : ''

          return (
            <Form className="flex-column flex-grow" onSubmit={props.handleSubmit}>
              <div className="team-management-modal-header">Invite {roleText}</div>
              {isLoading && <Dimmer className="dimmer" active inverted><Loader inverted /></Dimmer>}
              <div className="team-management-modal-body">
                <Form.Input width={11} fluid
                  name="firstName"
                  label="First Name"
                  defaultValue={props.values.firstName}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                <Form.Input width={11} fluid
                  name="lastName"
                  label="Last Name"
                  defaultValue={props.values.lastName}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                <Form.Input width={16} fluid
                  name="email"
                  label="Email Address"
                  defaultValue={props.values.email}
                  onChange={(e) => { setApiErrorText(''); props.handleChange(e) }}
                  onBlur={props.handleBlur}
                  className={cx({ 'team-management-modal-input-error': !!formErrorText || !!apiErrorText })}
                />
                {(formErrorText || apiErrorText) && <div className="team-management-modal-error-text no-margin">{formErrorText || apiErrorText}</div>}
              </div>
              <div className="team-management-modal-footer">
                <Button className="app-button button-white" type="button" onClick={handleCancelClick}>Cancel</Button>
                <Button className="app-button button-dark" type="submit" disabled={!props.isValid || !props.dirty}>Submit</Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

const mapStateToProps = (state: RootState) => ({
  isOpen: state.TeamManagementState.onboardingInviteModal.isOpen,
  role: state.TeamManagementState.onboardingInviteModal.role,
  isLoading: state.TeamManagementState.onboardingInviteModal.isLoading,
  errorText: state.TeamManagementState.onboardingInviteModal.errorText,
});

const mapDispatchToProps = (dispatch: any) => ({
  sendOnboardingInvite: (user: OnboardingUserInviteModel, role: string) => dispatch(sendOnboardingInvite(user, role)),
  closeOnboardingInviteModal: () => dispatch(closeOnboardingInviteModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingInviteModal)