import React from 'react';
import { connect } from 'react-redux';
import { CoveredEntity, HealthSystemInfo, TeamMember } from '../../store/teammanagement/types';
import { Table, Dimmer, Loader, Dropdown, DropdownItemProps, Button } from 'semantic-ui-react';
import { RootState } from '../../store/RootState';
import EditTeamModal from './Modals/EditTeamModal';
import { openEditTeamModal, updateLeadContact } from '../../store/teammanagement/actionCreators';
import RestrictedByRole from './RestrictedByRole';
import { RECIPIENT_TEAM_MANAGEMENT_USER_ROLES } from '../../store/teammanagement/constants';
import { getActiveHealthSystemRole } from '../../store/teammanagement/selectors';
import TableCellPopup from './common/TableCellPopup';

export interface CoveredEntitiesProps {
  healthSystem: HealthSystemInfo;
  isLoading: boolean;
  activeRole: string;
  openEditTeamModal: (coveredEntity: CoveredEntity) => void,
  updateLeadContact: (userId: number, ceId: number, id340B: string) => void
}

interface CoveredEntitiesState {
  memberOptions: DropdownItemProps[];
  isPopupOpen: boolean;
}

export class CoveredEntities extends React.Component<CoveredEntitiesProps, CoveredEntitiesState> {
  constructor (props: CoveredEntitiesProps) {
    super(props);
    this.state = {
      memberOptions: [],
      isPopupOpen: false,
    };
  }

  componentDidMount () {
    this.buildMemberOptions();
  }

  componentDidUpdate (prevProps: CoveredEntitiesProps) {
    if (this.props.healthSystem.activeMembers !== prevProps.healthSystem.activeMembers) {
      this.buildMemberOptions();
    }
  }

  handleEditTeam = (coveredEntity: CoveredEntity) => {
    this.props.openEditTeamModal(coveredEntity);
  }

  buildMemberOptions = () => {
    const options = this.props.healthSystem.activeMembers.map((m) => {
      return ({ key: m.id, value: m.id, text: m.firstName ? `${m.firstName} ${m.lastName}` : `${m.email}` });
    });
    this.setState({ memberOptions: options });
  }

  handleLeadContactChange = (userId: any, ceId: number, id340B: any) => {
    const parsedId = parseInt(userId[0].value);
    if (this.props.updateLeadContact) {
      this.props.updateLeadContact(parsedId, ceId, id340B);
    }
  };

  renderCoveredEntityRows = () => {
    const users: JSX.Element[] = [];
    const { coveredEntities } = this.props.healthSystem;
    const { activeRole, healthSystem } = this.props;
    coveredEntities.forEach((ce: CoveredEntity, index) => {
      const lc = healthSystem.activeMembers.find(u => u.id === ce.ddmLeadContactId) as TeamMember;
      let leadContactText = '';
      if (lc) {
        if (lc.firstName) {
          leadContactText = healthSystem.activeMembers.find(u => u.id === ce.ddmLeadContactId)?.firstName + ' ' + healthSystem.activeMembers.find(u => u.id === ce.ddmLeadContactId)?.lastName
        } else {
          leadContactText = healthSystem.activeMembers.find(u => u.id === ce.ddmLeadContactId)?.email as string;
        }
      }

      users.push(
        <React.Fragment key={index}>
          <Table.Row>
            <Table.Cell width={3} className="no-wrap">{ce.id340B}</Table.Cell>
            <Table.Cell width={4}>
              <TableCellPopup
                content={ce.entityName}
                id={`tm-entity-name${index}`}
              />
            </Table.Cell>
            <Table.Cell width={2} className="no-wrap">
              {ce.userIdsAssigned.length} {ce.userIdsAssigned.length === 1 ? 'user' : 'users'}
            </Table.Cell>
            <Table.Cell width={3}>
              <div className="tm-edit-cell">
                <RestrictedByRole allowedRoles={[RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN]}>
                  <Button className="app-button button-white edit-cell-button viewAndEdit_Pendo" onClick={() => this.handleEditTeam(ce)}>View &amp; Edit</Button>
                </RestrictedByRole>
              </div>
            </Table.Cell>
            <Table.Cell width={4}>
              <Dropdown
                className="changeLeadContact_Pendo"
                disabled={activeRole !== RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN}
                selection
                text={leadContactText}
                options={this.state.memberOptions}
                value={ce.ddmLeadContactId}
                selectOnBlur={false}
                onChange={(event: any, data: any) => {
                  this.handleLeadContactChange(data.options.filter((o: any) => o.value === data.value), ce.coveredEntityId, ce.id340B)
                }} />
            </Table.Cell>
          </Table.Row>
        </React.Fragment>
      );
    })

    return users
  }

  render () {
    const { isLoading } = this.props;
    return (
      <div className="team-management-page no-header">
        {isLoading && <Dimmer className="dimmer" active inverted><Loader inverted content='Loading' className="tm-busy-loader" /></Dimmer>}
        <div className="basic-table-wrapper tm-table-container">
          <Table unstackable className="basic-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={3}>340B ID</Table.HeaderCell>
                <Table.HeaderCell width={4}>Entity Name</Table.HeaderCell>
                <Table.HeaderCell width={2}>Team</Table.HeaderCell>
                <Table.HeaderCell width={3} />
                <Table.HeaderCell width={4}>Lead Contact</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.renderCoveredEntityRows()}
            </Table.Body>
          </Table>
        </div>

        <EditTeamModal />
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  healthSystem: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo,
  isLoading: state.TeamManagementState.teamManagementModel.isLoading,
  activeRole: getActiveHealthSystemRole(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  openEditTeamModal: (coveredEntity: CoveredEntity) => dispatch(openEditTeamModal(coveredEntity)),
  updateLeadContact: (userId: number, ceId: number, id340B: string) => dispatch(updateLeadContact(userId, ceId, id340B))
});

export default connect(mapStateToProps, mapDispatchToProps)(CoveredEntities);
