import React from 'react'
import { connect } from 'react-redux';
import cx from 'classnames';
import { Icon, TransitionablePortal, Button, Progress } from 'semantic-ui-react';
import { RootState } from '../../../store/RootState';
import Dropzone from './Dropzone';
import { LEFT_NAV_WIDTH } from '../../../store/applicationConstants';
import CloseButton from '../../common/CloseButton';
import { RequestCenterFileUploadResponseModel } from '../../../store/requestcenter/types';
import { uploadDiscountRequests } from '../../../api/discountrequests/DiscountRequests';
import FormStepper, { FormStepperStep, STEP_STATUS } from '../../common/formStepper/FormStepper';
import { getActiveHealthSystemId } from '../../../store/teammanagement/selectors';

interface FileUploadProps {
  isOpen: boolean;
  isLeftNavigationExpanded?: boolean;
  activeHealthSystemId: number;
  onClose: (isSuccess: boolean) => void;
}

interface FileUploadState {
  file: any;
  steps: Record<string, FormStepperStep>;
  uploadProgress: number;
  isLoading: boolean;
  isSuccess: boolean;
  hasUploadError: boolean;
  hasSizeError: boolean;
  hasFileTypeError: boolean;
  hadSuccess: boolean;
  result?: RequestCenterFileUploadResponseModel;
}

const initialState: FileUploadState = {
  file: null,
  steps: {
    upload: { text: 'Upload', status: STEP_STATUS.ACTIVE, isComplete: false },
    submit: { text: 'Submit', status: STEP_STATUS.PENDING, isComplete: false }
  },
  uploadProgress: 0,
  isLoading: false,
  isSuccess: false,
  hasUploadError: false,
  hasSizeError: false,
  hasFileTypeError: false,
  hadSuccess: false,
  result: undefined,
};

class FileUpload extends React.Component<FileUploadProps, FileUploadState>{
  constructor (props: Readonly<FileUploadProps>) {
    super(props);
    this.state = initialState;
  }

  componentDidUpdate (prevProps: FileUploadProps) {
    if (this.props.activeHealthSystemId !== prevProps.activeHealthSystemId) {
      this.props.onClose(false);
      this.reset(false);
    }
  }

  reset = (saveSuccess: boolean) => {
    this.setState({ ...initialState, hadSuccess: saveSuccess, });
  }

  handleClose = () => {
    this.props.onClose(this.state.isSuccess || this.state.hadSuccess);
    this.reset(false);
  }

  activateStep = (stepName: string) => {
    //going back to upload is the only clickable step and it should clear the selected file
    if (stepName === 'upload') {
      this.setFile(null);
    }
  }

  setFile = (file: any) => {
    if (file) {
      const isValidSize = file.size / 1048576 <= 100;
      const isValidType = file.name.split('.')[1].toUpperCase() === 'CSV';
      if (isValidSize && isValidType) {
        const updateSteps = {
          upload: { ...this.state.steps.upload, status: STEP_STATUS.COMPLETE },
          submit: { ...this.state.steps.submit, status: STEP_STATUS.ACTIVE }
        };
        this.setState({ file: file, hasSizeError: false, hasFileTypeError: false, steps: updateSteps });
      } else {
        this.setState({ file: file, hasSizeError: !isValidSize, hasFileTypeError: !isValidType });
      }
    } else {
      const updateSteps = {
        upload: { ...this.state.steps.upload, status: STEP_STATUS.ACTIVE },
        submit: { ...this.state.steps.submit, status: STEP_STATUS.PENDING }
      };
      this.setState({ file: file, hasSizeError: false, hasFileTypeError: false, hasUploadError: false, steps: updateSteps });
    }
  }

  onSubmit = async () => {

    this.setState({
      result: undefined,
      steps: { ...this.state.steps, submit: { ...this.state.steps.submit, status: STEP_STATUS.PROCESSING } },
      uploadProgress: 0,
      isLoading: true,
      isSuccess: false,
      hasUploadError: false
    });

    try {
      const response = await uploadDiscountRequests(this.state.file, (percent) => { this.setState({ uploadProgress: percent }); });

      this.setState({
        result: response,
        steps: { ...this.state.steps, submit: { ...this.state.steps.submit, status: STEP_STATUS.COMPLETE } },
        uploadProgress: 100,
        isLoading: false,
        isSuccess: true,
      });

    } catch (ex) {
      this.setState({ hasUploadError: true, isLoading: false });
    }
  }


  render () {
    const { isLeftNavigationExpanded } = this.props;
    const { steps, file, hasSizeError, hasFileTypeError, hasUploadError, result } = this.state;

    const leftNavWidth = isLeftNavigationExpanded ? LEFT_NAV_WIDTH.EXPANDED : LEFT_NAV_WIDTH.COLLAPSED;
    const  validationResults  = result?.validationResults || [];

    return (
      <TransitionablePortal open={this.props.isOpen} transition={{ animation: 'slide up', duration: 400 }} closeOnDocumentClick={false} closeOnEscape={false}>
        <div className='file-upload' style={{ width: `calc(100% - ${leftNavWidth}px)`, left: leftNavWidth }}>
          <div className='file-upload-content flex-column flex-grow'>
            <div className='file-upload-header flex-column justify-center'>
              <div className='flex-row justify-space-between'>
                <div className='beta'>Upload a file</div>
                <CloseButton onClick={this.handleClose} />
              </div>
            </div>
            <div className='file-upload-body flex-row flex-grow'>
              <div className='file-upload-step flex-column'>
                <FormStepper steps={steps} activateStep={this.activateStep} />
              </div>
              <div className='file-upload-details flex-column'>
                { // file upload step
                  (steps.upload.status === STEP_STATUS.ACTIVE || steps.submit.status === STEP_STATUS.ACTIVE) &&
                  <Dropzone file={file} setFile={this.setFile} hasSizeError={hasSizeError} hasFileTypeError={hasFileTypeError} />
                }
                { // processing step
                  (steps.submit.status === STEP_STATUS.PROCESSING) &&
                  <React.Fragment>
                    {
                      hasUploadError &&
                      <div className='processing-error-notification flex-row justify-space-between'>
                        <div className='flex-column'>
                          <div>
                            <Icon name='warning sign' /> &nbsp;&nbsp;
                            {'Uh oh! Something interrupted the upload.'}
                          </div>
                        </div>
                        <div className='flex-column'>
                          <span className='epsilon cursor-pointer' onClick={this.onSubmit}>Try Again</span>
                        </div>
                      </div>
                    }
                    <div className={cx('file-upload-processing flex-column', { 'hasError': hasUploadError })}>
                      <div className='eta-regular'>
                        <span className='file-name'>{this.state.file.name}</span>
                        <span className='file-size'>{
                          this.state.file.size <= 1048576
                            ? `${Math.ceil(this.state.file.size / 1024)} KB`
                            : `${Math.ceil(this.state.file.size / 1048576)} MB`
                        }</span>
                      </div>
                      <Progress size='small' className="file-upload-progress" percent={this.state.uploadProgress} />
                      {!hasUploadError ?
                        <div className='status epsilon'>{this.state.uploadProgress < 80 ? 'Uploading file...' : 'Processing file...'}</div>
                        :
                        <div className='status epsilon'>{this.state.uploadProgress < 80 ? 'Error uploading file' : 'Error processing file'}</div>
                      }
                    </div>
                  </React.Fragment>
                }
                { // file upload complete
                  (steps.submit.status === STEP_STATUS.COMPLETE && validationResults.length === 0) &&
                  <div className='file-upload-success flex-column'>
                    <div className='flex-column'>
                      <div className='checkicon-outline'><Icon name='check circle outline' size='big' className='checkicon' /></div>
                    </div>
                    <div className='beta'>Request(s) successfully added!</div>
                    <Progress size='small' className="file-upload-progress" percent={100} />
                    <div>
                      <Button className='go-to-link epsilon fileUploadGoToSubmitted_Pendo' onClick={this.handleClose}>Go to Submitted Discount Requests <Icon name='arrow right' size='large' /></Button>
                    </div>
                    <div className='note eta-regular'>Larger files need a few minutes to process and may not be <br />immediately visible in the Request Center.</div>
                  </div>
                }
                {//file upload complete with some errors
                  (steps.submit.status === STEP_STATUS.COMPLETE && validationResults.length > 0) &&
                  <div className="file-upload-some-errors flex-column">
                    <div className="flex-column">
                      <div className="flex-row file-name-errors-row justify-center">
                        <span className='file-name-errors eta'>{this.state.file.name}</span>
                        <span className='file-size-errors eta'>{
                          this.state.file.size <= 1048576
                            ? `${Math.ceil(this.state.file.size / 1024)} KB`
                            : `${Math.ceil(this.state.file.size / 1048576)} MB`
                        }</span>
                      </div>
                      <Progress size='small' className="file-upload-progress" percent={100} />
                      <div className="epsilon error-message">Error uploading file</div>
                      <div className="error-msgs-container">
                        <div className="flex-row message-header">
                          <Icon name="warning sign" />
                          {validationResults[0].invalidLineNumber === 0 ? <div className="delta">Some column header(s) are missing or misspelled. Please correct the information and try uploading the file again.</div> : <div className="delta">{`We couldn't accept the file because the following data was missing or invalid. Please correct the information and try uploading the corrected file again.`}</div>}
                        </div>
                        <div className="flex-column validation-container">
                          {validationResults.map((d: any, i: any) => {
                            return (
                              <div className={validationResults[0].invalidLineNumber === 0 ? 'validation-msg-container invalid' : 'validation-msg-container row-error'} key={i}>
                                <div className="delta row-data">{validationResults[0].invalidLineNumber === 0 ? null : `Row ${d.invalidLineNumber}`}</div>
                                <div className='flex-column'>
                                  {Object.values(d.validationResults).map((d: any, i: any) => {
                                    return (
                                      <div className="delta validation-results" key={i}>{d}</div>
                                    )
                                  })}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className='file-upload-footer flex-column justify-center'>
              <div className='flex-row justify-flex-end'>
                <div className='actions'>
                  {
                    (steps.submit.status === STEP_STATUS.COMPLETE) ?
                      <React.Fragment>
                        <Button basic className='upload-another disReqUploadAnother_Pendo' onClick={() => this.reset(true)}>Upload Another File</Button>
                        <Button secondary onClick={this.handleClose}>Close</Button>
                      </React.Fragment>
                      :
                      <Button secondary className="submit-upload disReqSubmitUpload_Pendo" disabled={!file || hasFileTypeError || hasSizeError || steps.submit.status === STEP_STATUS.PROCESSING} onClick={this.onSubmit}>Submit</Button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </TransitionablePortal>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  isLeftNavigationExpanded: state.AppCommonState.navigationState.isLeftNavExpanded,
  activeHealthSystemId: getActiveHealthSystemId(state),
});

export default connect(mapStateToProps)(FileUpload);
