import React from 'react'
import { Button, Icon } from 'semantic-ui-react';
import { DiscountProgramFieldsModel, DiscountRequestAdditionalFieldsModel, DiscountRequestRequiredFieldsModel } from '../../../store/requestcenter/types';
import FieldInfo from './FieldInfo';
import { orderDiscountProgramFields } from './RequestFormHelper';

export interface ReviewProps {
  contractFields: DiscountProgramFieldsModel[];
  requiredFieldValues: DiscountRequestRequiredFieldsModel;
  additionalFieldValues: DiscountRequestAdditionalFieldsModel;
  submitErrors: string[];
  onSubmit: () => void;
}

export const Review: React.FunctionComponent<ReviewProps> = (props) => {

  const { contractFields, requiredFieldValues, additionalFieldValues, onSubmit, submitErrors } = props;
  const sortedContractFields = orderDiscountProgramFields(contractFields, requiredFieldValues.claimType);

  return (
    <React.Fragment>
      <div className='request-form-body flex-row flex-grow scroll-hidden'>
        {props.children}
        <div className='request-form-details flex-column v-scroll-auto'>

          <div className='form-container flex-column flex-grow'>
            <div className='form-title gamma'>Review and Submit</div>
            {submitErrors && submitErrors.length > 0 &&
              <div className='error-notification'>
                <Icon name='warning sign' />
                <div className="error-notification-message">
                  {'There were errors with your submission: '}
                  {submitErrors.map((error, i) => {
                    return (
                      <div className="error-notification-row" key={i}>{error}</div>
                    )
                  })}
                </div>
              </div>}
            <div className='field-info-row flex-row'>
              {/* <FieldInfo value={claimType} text='RETAIL/MEDICAL REQUEST' /> */}
              {/* <FieldInfo name="discountProgram" value={requiredFieldValues.discountProgram} label='DISCOUNT PROGRAM' /> */}
              <FieldInfo name="idCode" value={requiredFieldValues.idCode} label='340B ID' />
              <FieldInfo name="ndc11" value={requiredFieldValues.ndc11} label='NDC11' />
              <FieldInfo name="fillDate" value={requiredFieldValues.fillDate} label='FILL DATE' />
              {
                sortedContractFields.map(fld => {
                  const fieldKey = fld.name as keyof DiscountRequestAdditionalFieldsModel;
                  return <FieldInfo name={fld.name} value={additionalFieldValues[fieldKey]} label={fld.display} key={fld.name} />
                })
              }
            </div>
          </div>
        </div>
      </div>
      <div className='request-form-footer flex-column justify-center'>
        <div className='flex-row justify-flex-end'>
          <div className='actions'>
            <Button type="submit" className="disReqSubmitFormSubmit_Pendo" secondary onClick={onSubmit}>Submit</Button>
          </div>
        </div>
      </div>
    </React.Fragment >
  )

}


export default Review;