import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as yup from 'yup';

import { RootState } from '../../../store/RootState';
import { UserInfoInputModel } from '../../../store/onboarding/types';
import { completeControllerInfoStep } from '../../../store/onboarding/actionCreators';
import { makeSelectIsLoading } from '../../../store/onboarding/selectors';

import { UserInfoModel } from '../../../store/applicationTypes';
import Layout from '../Layout';
import AddressFields from '../forms/AddressFields';
import TitleAndPhoneFields from '../forms/TitleAndPhoneFields';
import { addressSchema, userSchema } from '../forms/schemas';

interface ControllerProps {
  controllerInfo: UserInfoModel;
  isLoading: boolean;
  errorText: string;
  completeControllerInfoStep: (input: UserInfoInputModel) => void;
}

export class Controller extends React.Component<ControllerProps>{

  onNextClick = (input: any) => {
    this.props.completeControllerInfoStep({
      jobTitle: input.jobTitle,
      phone: input.phone,
      phoneExtension: input.phoneExtension,
      address: input.address
    });
  }

  render () {
    const name = `${this.props.controllerInfo?.firstName} ${this.props.controllerInfo?.lastName}`;
    const email = this.props.controllerInfo?.email;

    const initialValues = {
      jobTitle: this.props.controllerInfo?.jobTitle,
      phone: this.props.controllerInfo?.phone,
      phoneExtension: this.props.controllerInfo?.phoneExtension,
      address: this.props.controllerInfo?.address ? this.props.controllerInfo?.address : {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: ''
      }
    };

    const schema = yup.object().shape({
      ...userSchema,
      address: addressSchema
    });

    return (
      <Layout
        isLoading={this.props.isLoading}
        errorText={this.props.errorText}
        stepNumber={1}>

        {!this.props.isLoading && // don't show  until finished loading
          <Formik
            initialValues={initialValues}
            validateOnMount={true}
            validationSchema={schema}
            onSubmit={(values) => {
              this.onNextClick(values);
            }}
          >
            {props => {

              const validateForm = props.validateForm;
              useEffect(() => {
                validateForm();
              }, [validateForm]);

              return (
                <Form className="input-form full" onSubmit={props.handleSubmit}>
                  <div className="onboarding-layout-header">
                    <div className="title">1. Your contact information</div>
                    <div className="sub-title">
                      We need to collect your information to finish setting up your company’s account. Please provide the remaining contact information.
                    </div>
                  </div>
                  <div className="onboarding-layout-children">
                    <div className="onboarding-layout-body dr-controller-info">
                      <div className="section-header">Contact Information</div>
                    </div>
                    <div className="detail-field">
                      <div className="value" data-private>{name}</div>
                      <div className="label">NAME</div>
                    </div>
                    <div className="detail-field">
                      <div className="value" data-private>{email}</div>
                      <div className="label">EMAIL</div>
                    </div>
                    <TitleAndPhoneFields values={props.values} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched} />
                    <div className="section-header margin-top">Employee Address</div>
                    <AddressFields values={props.values.address} setFieldValue={props.setFieldValue} handleChange={props.handleChange} handleBlur={props.handleBlur} errors={props.errors} touched={props.touched} />
                  </div>
                  <div className="onboarding-layout-footer">
                    <div className="onboarding-layout-cta">
                      <button className="app-button button-dark" type="submit" disabled={!props.isValid || this.props.isLoading}>Next</button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        }
      </Layout>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  controllerInfo: state.AccountInformationState.userInfo,
  isLoading: makeSelectIsLoading(state),
  errorText: state.OnboardingState.errorText,
});

const mapDispatchToProps = (dispatch: any) => ({
  completeControllerInfoStep: (input: UserInfoInputModel) => dispatch(completeControllerInfoStep(input)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Controller);