import React from 'react';

interface EmptyTransactionsProps {
  emptyTransactionsHeader: string,
  emptyTransactionsMessage: string
}

const EmptyTransactions: React.FunctionComponent<EmptyTransactionsProps> = ({ emptyTransactionsHeader, emptyTransactionsMessage }) => {

  return (
    <div className="empty-transactions-no-records">
      <div className="beta empty-transactions-header-msg">
        {emptyTransactionsHeader}
      </div>
      <div className="delta empty-transactions-sub-msg">
        {emptyTransactionsMessage}
      </div>
    </div>
  );
}

export default EmptyTransactions
