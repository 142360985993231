import React, { useState } from 'react';
import { Popup } from 'semantic-ui-react';

export interface TableCellPopupProps {
  content: string;
  id: string;
}

const TableCellPopup = (props: TableCellPopupProps) => {
  const { content, id } = props;

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const checkColumnWidth = (id: string) => {
    const element = document.getElementById(id);

    if (element) {
      if (element.scrollWidth > element.clientWidth) {
        setIsPopupOpen(true);
      }
    }
  };

  return (
    <Popup
      className="team-management-popup popup-container"
      content={content}
      hoverable
      inverted
      onOpen={() => checkColumnWidth(id)}
      onClose={() => setIsPopupOpen(false)}
      open={isPopupOpen}
      position='top left'
      size='tiny'
      trigger={
        <div className="team-management-popup content" id={id}>
          {content}
        </div>
      }
    />
  );
};

export default TableCellPopup;