
export const formatPhone = (phone?: string) => {
  if (!phone) {
    return '';
  }
  if (phone?.length !== 10) {
    return phone;
  }
  const areaCode = phone.substr(0, 3);
  const exchangeCode = phone.substr(3, 3);
  const lineNumber = phone.substr(6, 4);
  
  return `(${areaCode}) ${exchangeCode}-${lineNumber}`; 
};

export const unFormatPhone = (phone?: string) => {
  if (!phone) return '';
  return phone.replace(/\D/g, '');
};