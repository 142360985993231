import React from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import { Moment } from 'moment-timezone';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import { Form } from 'semantic-ui-react';

import { TRANSACTION_FILTER_LABELS } from '../../../store/transactionscommon/constants';
import { FilterTextbox } from '../filter/FilterTextbox';
import { FilterDropdown } from '../filter/FilterDropdown';
import { AdjustReasonsModel } from '../../../store/appcommon/types';
import { TransactionsFilterModel } from '../../../store/transactionscommon/types';
// import { CLAIM_TYPE_FILTER_OPTIONS } from '../../store/transactions/constants'
import { ArrowRight } from '../../../themes/kalderos/Common/Icons';
import { formatYYYYMMDD } from '../../../utils/dateHelper';

type focusedInputType = 'startDate' | 'endDate' | null;

export interface TransactionFiltersProps {
  adjustReasons: AdjustReasonsModel;
  filters: TransactionsFilterModel;
  focusedInput: focusedInputType;
  isAdjustmentFilterVisible?: boolean;
  isFailureFilterVisible?: boolean;
  isRemittance?: boolean;
  onApplyFilter: (values: any) => void;
  onFocusChange: (input: any) => void;
}

const TransactionFilters = (props: TransactionFiltersProps) => {
  const { adjustReasons, filters, focusedInput, isAdjustmentFilterVisible, isFailureFilterVisible, isRemittance, onApplyFilter, onFocusChange } = props;

  return (
    <Formik
      initialValues={filters}
      onSubmit={(values, helpers) => {
        helpers.setSubmitting(false);
        helpers.resetForm({ values: values })
        onApplyFilter(values);
      }}
    >
      {props => {
        const handleDateChange = async (fillStartDate: Moment | null, fillEndDate: Moment | null) => {
          if (fillStartDate !== null) {
            await props.setFieldValue('fillStartDate', formatYYYYMMDD(fillStartDate));
            props.setFieldTouched('fillStartDate');
          }
          if (fillEndDate !== null) {
            await props.setFieldValue('fillEndDate', formatYYYYMMDD(fillEndDate));
            props.setFieldTouched('fillEndDate');
          }
          props.handleSubmit();
        };

        const handleDropdownChange = (label: string, value: any) => {
          if (value.length > 0) {
            props.setFieldValue(label, value);
          } else {
            props.setFieldValue(label, null);
          } 

          props.handleSubmit();
        };

        const handleOnBlur = () => {
          if (props.dirty && props.isValid && !props.isSubmitting) {
            props.submitForm();
          }
        };

        return (
          <div className="table-header-filter">
            <Form className="filter-form" onSubmit={props.handleSubmit}>
              <div className="filter-left-container">
                <div className="filter-header">Fill Date</div>
                <Form.Group widths='equal' className="filter-date-picker-group">
                  <Form.Field className="filter-date-picker">
                    <DateRangePicker
                      noBorder
                      isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                      startDate={props.values.fillStartDate ? moment(props.values.fillStartDate) : null}
                      startDateId='fillStartDate'
                      endDate={props.values.fillEndDate ? moment(props.values.fillEndDate) : null}
                      endDateId='fillEndDate'
                      focusedInput={focusedInput}
                      onFocusChange={focusedInput => onFocusChange(focusedInput)}
                      onDatesChange={({startDate, endDate}) => handleDateChange(startDate, endDate)}
                      customArrowIcon={<div className="react-dates-arrow"><ArrowRight /></div>}
                      minimumNights={0}
                    />
                  </Form.Field>
                </Form.Group>
              </div>
      
              <div className="filter-right-container no-header">
                {/* <Form.Field>
                  <FilterDropdown
                    search
                    label={TRANSACTION_FILTER_LABELS.claimTypeText}
                    options={CLAIM_TYPE_FILTER_OPTIONS}
                    values={props.values.claimTypeText || undefined}
                    onChange={(value) => handleDropdownChange('claimTypeText', value)}
                  />
                </Form.Field> */}
                { !isRemittance && (
                  <Form.Field>
                    <FilterTextbox
                      label={TRANSACTION_FILTER_LABELS.idCode}
                      name="idCode"
                      onChange={props.handleChange}
                      onBlur={handleOnBlur}
                      value={props.values.idCode}
                    />
                    {props.errors?.idCode && <div className="filter-error-text">Invalid 340B ID</div>}
                  </Form.Field>
                )}
                <Form.Field>
                  <FilterTextbox
                    label={TRANSACTION_FILTER_LABELS.ndc11}
                    name="ndc11"
                    onChange={props.handleChange}
                    onBlur={handleOnBlur}
                    value={props.values.ndc11}
                  />
                  {props.errors?.ndc11 && <div className="filter-error-text">Invalid NDC11</div>}
                </Form.Field>
    
                <Form.Field>
                  <FilterTextbox
                    label={TRANSACTION_FILTER_LABELS.rxNumber}
                    name="rxNumber"
                    onChange={props.handleChange}
                    onBlur={handleOnBlur}
                    value={props.values.rxNumber}
                  />
                  {props.errors?.rxNumber && <div className="filter-error-text">Invalid Rx Number</div>}
                </Form.Field>

                <Form.Field>
                  <FilterTextbox
                    label={TRANSACTION_FILTER_LABELS.pharmacyNpi}
                    name="pharmacyNpi"
                    onChange={props.handleChange}
                    onBlur={handleOnBlur}
                    value={props.values.pharmacyNpi}
                  />
                  {props.errors?.pharmacyNpi && <div className="filter-error-text">Invalid NPI</div>}
                </Form.Field>
    
                {isAdjustmentFilterVisible && (
                  <Form.Field>
                    <FilterDropdown
                      search
                      label={TRANSACTION_FILTER_LABELS.adjustmentReasons}
                      options={adjustReasons.adjustedReasonsFilter}
                      values={props.values.adjustmentReasons || undefined}
                      onChange={(value) => handleDropdownChange('adjustmentReasons', value)}
                    />
                  </Form.Field>
                )}
    
                {isFailureFilterVisible && (
                  <Form.Field>
                    <FilterDropdown
                      search
                      label={TRANSACTION_FILTER_LABELS.failureReasons}
                      options={adjustReasons.failureReasonsFilter}
                      values={props.values.failureReasons || undefined}
                      onChange={(value) => handleDropdownChange('failureReasons', value)}
                    />
                  </Form.Field>
                )}
              </div>
            </Form>
          </div>
        )
      }}
    </Formik>
  );
};

export default TransactionFilters;