import RequestConfig from '../../../app.config';
import { API_PAGE_SIZE } from '../../../store/applicationConstants';
import { createUrlParametersString } from '../../../utils/requestUtil';
import { commonResponseHandler, RESPONSE_TYPE } from '../../../utils/responseUtils';
import fetchWrapper from '../../FetchWrapper';

export const fetchPharmacyManagement = async (sortBy: string, isSortDesc: boolean, pageToFetch: number, id340B: string, healthSystemId: number, filters: string) => {
  const urlParams = createUrlParametersString({ SortField: sortBy, SortDesc: isSortDesc, pageSize: API_PAGE_SIZE, page: pageToFetch, id340B: id340B, healthSystemId: healthSystemId });
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/PharmacyManagement/associatedPharmacies?${urlParams}&${filters}`, true);
  return commonResponseHandler(response, RESPONSE_TYPE.JSON);
}

export const fetchEditHistoryInfo = async () => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/PharmacyManagement/editHistory`, true);
  return commonResponseHandler(response);
}

export const fetchDownloadEditHistory = async (id340B: string, coveredEntityName: string) => {
  const urlParams = createUrlParametersString({ id340B: id340B, pharmacyNpi: coveredEntityName })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/PharmacyManagement/editHistory/download?${urlParams}`, true);
  return commonResponseHandler(response, RESPONSE_TYPE.BLOB);
}

export const fetchRemovePharmacyReasons = async () => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/PharmacyManagement/removalReasons`, true);
  return commonResponseHandler(response);
}
