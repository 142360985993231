import React, { Fragment } from 'react';
import { RootState } from '../../store/RootState';
import { getActiveHealthSystemRole } from '../../store/teammanagement/selectors';
import { connect } from 'react-redux';

export interface RestrictedByRoleProps {
  children: React.ReactNode;
  allowedRoles: string[];
  checkUserRole?: boolean;

  // state
  activeRole: string;
  userRoles: string[] | null;
}

export class RestrictedByRole extends React.Component<RestrictedByRoleProps> {
  render () {
    const { activeRole, allowedRoles, children, checkUserRole, userRoles } = this.props;

    if (!checkUserRole && !allowedRoles.includes(activeRole)) {
      return null;
    } else if (checkUserRole && !allowedRoles.some(role => userRoles?.includes(role) || userRoles === null)) {
      return null;
    }

    return (
      <Fragment>
        {children}
      </Fragment>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  activeRole: getActiveHealthSystemRole(state),
  userRoles: state.LoginState.user.roles
});

export default connect(mapStateToProps)(RestrictedByRole);
