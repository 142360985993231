import { takeEvery, all, call, put, select } from 'redux-saga/effects';
import {
  GET_RECIPIENT_PAYMENT_HISTORY,
  UPDATE_RECIPIENT_PAYMENTS_HISTORY_SORT_FILTERS,
  RETRY_PAYMENTS,
  GET_CREDITS_PER_MANUFACTURER,
  UPDATE_CREDITS_PER_MANUFACTURER_SORT_FILTERS,
  UPDATE_CREDITS_PER_MANUFACTURER_PAGE,
  GET_ASSOCIATED_COVERED_ENTITIES,
  UPDATE_ASSOCIATED_COVERED_ENTITIES_SORT,
  GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO
} from './constants';
import { fetchAssociatedEntities } from '../../../api/discountrequests/DiscountRequests';
import { fetchRecipientPaymentHistory, retryPayments, fetchRecipientCreditsPerManufacturer } from '../../../api/payments/RecipientPayments';
import { fetchControllerInfoRequest, fetchAccountAdminInfoRequest } from '../../../api/payments/CommonPayments';
import {
  getRecipientPaymentsHistoryError,
  updateRecipientPaymentsHistory,
  retryPaymentsSuccess,
  retryPaymentsFailure,
  creditsPerManufacturerSuccess,
  creditsPerManufacturerFailure,
  getAssociatedCoveredEntitiesSuccess,
  getAssociatedCoveredEntitiesFailure,
  getCoveredEntityHealthSystemInfoFailure,
  getCoveredEntityHealthSystemInfoSuccess
} from './actionCreators';
import { RootState } from '../../RootState';
import { UI_PAGE_SIZE, API_PAGE_SIZE } from '../../applicationConstants'
import { TOAST_TYPE } from '../../toast';
import { createToast } from '../../toast/actionCreators';
import { Toast } from '../../toast/types';
import { canUseCachedRecords, getApiPageToFetch } from '../../../utils/datatableHelper';
import { getActiveHealthSystemId } from '../../teammanagement/selectors';
import { getPaymentSummary, getPaymentAlertInfo } from '../common/actionCreators';
import logRocketUtil from '../../../utils/logRocketUtil';
import { formatPhone } from '../../../utils/phoneFormatHelper';

const getPaymentHistoryState = (state: RootState) => state.PaymentsState.recipient.paymentHistory;
const getPaymentBillingInfoState = (state: RootState) => state.PaymentsState.recipient.coveredEntityBillingInfo;
export const getCreditsPerManufacturerState = (state: RootState) => state.PaymentsState.recipient.creditsPerManufacturerModel;

export function* getRecipientPaymentsHistorySaga () {
  try {
    const { sortBy, isSortDesc, currentPage } = yield select(getPaymentHistoryState);
    const healthSystemId = yield select(getActiveHealthSystemId);
    const pageToFetch = getApiPageToFetch(currentPage, UI_PAGE_SIZE, API_PAGE_SIZE);
    const { remittancePayments, totalCount, page } = yield call(fetchRecipientPaymentHistory, sortBy, isSortDesc, pageToFetch, healthSystemId);
    yield put(updateRecipientPaymentsHistory(remittancePayments, totalCount, page));
  }
  catch (e) {
    yield put(getRecipientPaymentsHistoryError(logRocketUtil(e)));
  }
}

export function* getCreditsPerManufacturerSaga () {
  try {
    const { sortBy, isSortDesc, currentPage } = yield select(getCreditsPerManufacturerState);
    const healthSystemId = yield select(getActiveHealthSystemId);
    const pageToFetch = getApiPageToFetch(currentPage, UI_PAGE_SIZE, API_PAGE_SIZE);
    const creditsPerManufacturerResponse = yield call(fetchRecipientCreditsPerManufacturer, sortBy, isSortDesc, pageToFetch, healthSystemId);
    yield put(creditsPerManufacturerSuccess(creditsPerManufacturerResponse.creditsPerManufacturer, creditsPerManufacturerResponse.page, creditsPerManufacturerResponse.totalCount));
  }
  catch (e) {
    yield put(creditsPerManufacturerFailure());
  }
}

export function* checkPageFetchCreditsPerManufacturerSaga () {
  const { currentPage, currentApiPage } = yield select(getCreditsPerManufacturerState);

  if (!canUseCachedRecords(currentPage, currentApiPage, UI_PAGE_SIZE, API_PAGE_SIZE)) {
    yield (getCreditsPerManufacturerSaga());
  }
}

export function* retryPaymentsSaga () {
  try {
    const healthSystemId = yield select(getActiveHealthSystemId);
    const response = yield call(retryPayments, healthSystemId);
    yield put(retryPaymentsSuccess(response));
    const toast: Toast = {
      text: 'Payment retry initiated. Allow 1-4 business days for transfer of funds.',
      type: TOAST_TYPE.SUCCESS
    }
    yield put(createToast(toast));
    // refresh data after successful retry
    yield put(getPaymentAlertInfo());
    yield put(getPaymentSummary());
  } catch (e) {
    const toast: Toast = {
      text: e.message,
      type: TOAST_TYPE.ERROR
    }
    yield put(createToast(toast));
    yield put(retryPaymentsFailure(logRocketUtil(e)));
  }
}

export function* getAssociatedCoveredEntitiesSaga () {
  try {
    const { sortBy, isSortDesc } = yield select(getPaymentBillingInfoState);
    const healthSystemId = yield select(getActiveHealthSystemId);
    const response = yield call(fetchAssociatedEntities, sortBy, isSortDesc, healthSystemId)
    yield put(getAssociatedCoveredEntitiesSuccess(response));
  }
  catch (e) {
    yield put(getAssociatedCoveredEntitiesFailure(logRocketUtil(e)));
  }
}

export function* getCoveredEntityHealthSystemInfoSaga () {
  try {
    const { accountAdminResponse, controllerInfoResponse } = yield all({
      accountAdminResponse: call(getAccountAdminInfo),
      controllerInfoResponse: call(getControllerInfo)
    });
    yield put(getCoveredEntityHealthSystemInfoSuccess(accountAdminResponse.payload, controllerInfoResponse.payload));
  }
  catch (e) {
    yield put(getCoveredEntityHealthSystemInfoFailure(logRocketUtil(e)));
  }
}

//private functions that the getCoveredEntityHealthSystemInfoSaga bundles
function* getControllerInfo () {
  try {
    const healthSystemId = yield select(getActiveHealthSystemId);
    const response = yield call(fetchControllerInfoRequest, healthSystemId);
    response.phone = formatPhone(response.phone);
    return { payload: response }
  }
  catch (e) {
    logRocketUtil(e)
    return { error: e }
  }
}

function* getAccountAdminInfo () {
  try {
    const healthSystemId = yield select(getActiveHealthSystemId);
    const response = yield call(fetchAccountAdminInfoRequest, healthSystemId);
    response.phone = formatPhone(response.phone);
    return { payload: response }
  }
  catch (e) {
    logRocketUtil(e)
    return { error: e }
  }
}

function* watchGetRecipientPaymentsHistory () {
  yield takeEvery([GET_RECIPIENT_PAYMENT_HISTORY, UPDATE_RECIPIENT_PAYMENTS_HISTORY_SORT_FILTERS], getRecipientPaymentsHistorySaga);
}

function* watchRetryPaymentsSaga () {
  yield takeEvery(RETRY_PAYMENTS, retryPaymentsSaga);
}

function* watchGetCreditsPerManufacturer () {
  yield takeEvery([GET_CREDITS_PER_MANUFACTURER, UPDATE_CREDITS_PER_MANUFACTURER_SORT_FILTERS], getCreditsPerManufacturerSaga)
}

function* watchCreditsPerManufacturerChange () {
  yield takeEvery(UPDATE_CREDITS_PER_MANUFACTURER_PAGE, checkPageFetchCreditsPerManufacturerSaga);
}

function* watchGetAssociatedCoveredEntities () {
  yield takeEvery([GET_ASSOCIATED_COVERED_ENTITIES, UPDATE_ASSOCIATED_COVERED_ENTITIES_SORT], getAssociatedCoveredEntitiesSaga);
}

function* watchGetCoveredEntityHealthSystemInfoSaga () {
  yield takeEvery(GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO, getCoveredEntityHealthSystemInfoSaga);
}

export default function* watchAllRecipientPayments () {
  yield all([
    watchGetRecipientPaymentsHistory(),
    watchRetryPaymentsSaga(),
    watchGetCreditsPerManufacturer(),
    watchCreditsPerManufacturerChange(),
    watchGetAssociatedCoveredEntities(),
    watchGetCoveredEntityHealthSystemInfoSaga()
  ]);
}
