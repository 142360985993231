import {
  RecipientActionTypes,
  GET_REQUEST_CENTER_TRANSACTION_COUNTS_FAILURE,
  GET_REQUEST_CENTER_TRANSACTION_COUNTS_SUCCESS,
  GET_REQUEST_CENTER_TRANSACTION_COUNTS,
  GET_REQUEST_CENTER_TRANSACTIONS_FAILURE,
  GET_REQUEST_CENTER_TRANSACTIONS_SUCCESS,
  GET_REQUEST_CENTER_TRANSACTIONS,
  REQUEST_CENTER_TAB,
  REQUEST_CENTER_BUCKET,
  RESET_REQUEST_CENTER_TRANSACTION_COUNTS,
  RESET_REQUEST_CENTER_TRANSACTIONS,
  UPDATE_REQUEST_CENTER_TRANSACTIONS_BUCKET,
  UPDATE_REQUEST_CENTER_TRANSACTIONS_FILTER,
  UPDATE_REQUEST_CENTER_TRANSACTIONS_PAGE,
  UPDATE_REQUEST_CENTER_TRANSACTIONS_SORT,
  SUBMIT_DISCOUNT_REQUEST,
  SUBMIT_DISCOUNT_REQUEST_SUCCESS,
  SUBMIT_DISCOUNT_REQUEST_FAILURE,
  GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES,
  GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES_SUCCESS,
  GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES_FAILURE,
  GET_DISCOUNT_REQUEST_CONTRACT_FIELDS,
  GET_DISCOUNT_REQUEST_CONTRACT_FIELDS_SUCCESS,
  GET_DISCOUNT_REQUEST_CONTRACT_FIELDS_FAILURE,
  RESET_DISCOUNT_REQUEST_FORM,
  CORRECT_DISCOUNT_REQUEST,
  CORRECT_DISCOUNT_REQUEST_SUCCESS,
  CORRECT_DISCOUNT_REQUEST_FAILURE,
} from './constants';
import { RequestCenterState } from './types';
import { getStartOfTheDayNonUTC, getEndOfTheDayNonUTC, get30DaysAgo } from '../../utils/dateHelper'
import { TransactionsFilterModel } from '../transactionscommon/types';

const filterInitialState: TransactionsFilterModel = {
  startDate: getStartOfTheDayNonUTC(get30DaysAgo(new Date())),
  endDate: getEndOfTheDayNonUTC(new Date()),
  claimTypeText: null,
  ndc11: '',
  fillStartDate: '',
  fillEndDate: '',
  pharmacyNpi: '',
  rxNumber: '',
  adjustmentReasons: null,
  idCode: '',
  failureReasons: null
};

const initialState: RequestCenterState = {
  counts: {
    inMfrReviewCount: 0,
    submittedCount: 0,
    rejectedCount: 0,
    approvedCount: 0,
    approvedAndAdjustedCount: 0,
    processingCount: 0,
    isLoading: false,
    errorText: '',
  },
  transactions: {
    transactions: [],
    bucket: REQUEST_CENTER_BUCKET.NONE,
    currentPage: 1,
    currentApiPage: 1,
    totalCount: 0,
    sortBy: 'submittedDate',
    isSortAsc: false,
    isLoading: false,
    errorText: '',
  },
  filters: {
    [REQUEST_CENTER_TAB.ALL]: filterInitialState,
    [REQUEST_CENTER_TAB.AWAITING]: filterInitialState,
    [REQUEST_CENTER_TAB.PROCESSING]: filterInitialState,
    [REQUEST_CENTER_TAB.PROCESSED]: filterInitialState
  },
  requestForm: {
    verify: {
      discountPrograms: [],
      coveredEntities: [],
      isLoading: false,
      isSuccess: false,
      errorText: '',
    },
    submit: {
      contractFields: [],
      isLoading: false,
      isSuccess: false,
      errors: [],
    },
    correct: {
      result: [],
      isLoading: false,
      isSuccess: false,
      errors: [],
    }
  }
};

export function recipientsReducer (
  state = initialState,
  action: RecipientActionTypes
): RequestCenterState {
  switch (action.type) {

    case GET_REQUEST_CENTER_TRANSACTION_COUNTS:
      return {
        ...state,
        counts: {
          ...state.counts,
          isLoading: true,
          errorText: ''
        }
      }

    case GET_REQUEST_CENTER_TRANSACTION_COUNTS_SUCCESS:
      return {
        ...state,
        counts: {
          ...state.counts,
          ...action.payload,
          isLoading: false,
        },
      }

    case GET_REQUEST_CENTER_TRANSACTION_COUNTS_FAILURE:
      return {
        ...state,
        counts: {
          ...state.counts,
          isLoading: false,
          errorText: action.payload.message
        }
      }

    case GET_REQUEST_CENTER_TRANSACTIONS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          isLoading: true,
          errorText: '',
        }
      }

    case GET_REQUEST_CENTER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          transactions: action.payload.discountRequests,
          currentApiPage: action.payload.page,
          totalCount: action.payload.totalCount,
          isLoading: false,
        }
      }

    case GET_REQUEST_CENTER_TRANSACTIONS_FAILURE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          isLoading: false,
          errorText: action.payload.message
        }
      }

    case UPDATE_REQUEST_CENTER_TRANSACTIONS_BUCKET:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          bucket: action.payload,
        }
      }

    case UPDATE_REQUEST_CENTER_TRANSACTIONS_PAGE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          currentPage: action.payload,
        }
      }

    case UPDATE_REQUEST_CENTER_TRANSACTIONS_SORT:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          currentPage: 1,
          sortBy: action.payload.sortBy,
          isSortAsc: action.payload.isSortAsc,
        }
      }

    case UPDATE_REQUEST_CENTER_TRANSACTIONS_FILTER:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          currentPage: 1,
          sortBy: 'submittedDate',
          isSortAsc: false,
        },
        filters: {
          ...state.filters,
          [action.payload.tab]: action.payload.filter
        }
      }

    case RESET_REQUEST_CENTER_TRANSACTION_COUNTS:
      return {
        ...state,
        counts: {
          ...state.counts,
          inMfrReviewCount: 0,
          submittedCount: 0,
          rejectedCount: 0,
          approvedCount: 0,
          approvedAndAdjustedCount: 0,
          processingCount: 0,
        }
      }

    case RESET_REQUEST_CENTER_TRANSACTIONS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          bucket: REQUEST_CENTER_BUCKET.NONE,
          transactions: [],
          currentPage: 1,
          currentApiPage: 1,
          totalCount: 0,
          sortBy: 'submittedDate',
          isSortAsc: false,
        },
      }

    case GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES:
      return {
        ...state,
        requestForm: {
          ...state.requestForm,
          verify: {
            ...state.requestForm.verify,
            isLoading: true,
            errorText: '',
          }
        }
      }

    case GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES_SUCCESS:
      return {
        ...state,
        requestForm: {
          ...state.requestForm,
          verify: {
            ...state.requestForm.verify,
            discountPrograms: action.payload.discountPrograms,
            coveredEntities: action.payload.coveredEntities,
            isLoading: false,
          }
        }
      }

    case GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES_FAILURE:
      return {
        ...state,
        requestForm: {
          ...state.requestForm,
          verify: {
            ...state.requestForm.verify,
            isLoading: false,
            errorText: action.payload.message
          }
        }
      }

    case GET_DISCOUNT_REQUEST_CONTRACT_FIELDS:
      return {
        ...state,
        requestForm: {
          ...state.requestForm,
          verify: {
            ...state.requestForm.verify,
            isLoading: true,
            isSuccess: false,
            errorText: '',
          }
        }
      }

    case GET_DISCOUNT_REQUEST_CONTRACT_FIELDS_SUCCESS:
      return {
        ...state,
        requestForm: {
          ...state.requestForm,
          verify: {
            ...state.requestForm.verify,
            isLoading: false,
            isSuccess: true,
          },
          submit: {
            ...state.requestForm.submit,
            contractFields: action.payload,
          }
        }
      }

    case GET_DISCOUNT_REQUEST_CONTRACT_FIELDS_FAILURE:
      return {
        ...state,
        requestForm: {
          ...state.requestForm,
          verify: {
            ...state.requestForm.verify,
            isLoading: false,
            errorText: action.payload.message,
          }
        }
      }

    case SUBMIT_DISCOUNT_REQUEST:
      return {
        ...state,
        requestForm: {
          ...state.requestForm,
          submit: {
            ...state.requestForm.submit,
            isLoading: true,
            isSuccess: false,
            errors: []
          }
        }
      }

    case SUBMIT_DISCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        requestForm: {
          ...state.requestForm,
          submit: {
            ...state.requestForm.submit,
            isLoading: false,
            isSuccess: true,
          }
        }
      }

    case SUBMIT_DISCOUNT_REQUEST_FAILURE:
      return {
        ...state,
        requestForm: {
          ...state.requestForm,
          submit: {
            ...state.requestForm.submit,
            isLoading: false,
            errors: action.payload,
          }
        }
      }


    case CORRECT_DISCOUNT_REQUEST:
      return {
        ...state,
        requestForm: {
          ...state.requestForm,
          correct: {
            ...state.requestForm.correct,
            result: [],
            isLoading: true,
            isSuccess: false,
            errors: []
          }
        }
      }

    case CORRECT_DISCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        requestForm: {
          ...state.requestForm,
          correct: {
            ...state.requestForm.correct,
            result: action.payload,
            isLoading: false,
            isSuccess: true,
          }
        }
      }

    case CORRECT_DISCOUNT_REQUEST_FAILURE:
      return {
        ...state,
        requestForm: {
          ...state.requestForm,
          correct: {
            ...state.requestForm.correct,
            isLoading: false,
            errors: action.payload,
          }
        }
      }

    case RESET_DISCOUNT_REQUEST_FORM:
      return {
        ...state,
        requestForm: {
          ...state.requestForm,
          verify: {
            ...state.requestForm.verify,
            //preserve Programs and Covered Entities
            isLoading: false,
            isSuccess: false,
            errorText: '',
          },
          submit: {
            contractFields: [],
            isLoading: false,
            isSuccess: false,
            errors: [],
          },
          correct: {
            result: [],
            isLoading: false,
            isSuccess: false,
            errors: [],
          }
        }
      }

    default:
      return state
  }
}
