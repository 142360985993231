import React, { Fragment } from 'react';
import { Popup } from 'semantic-ui-react';
import cx from 'classnames';

import { DisputeCode } from '../../store/applicationTypes';
import '../../themes/kalderos/Common/DisputeCodeList.scss';

export interface DisputeCodeListProps {
  disputeCodes: DisputeCode[];
  disableTooltip?: boolean;
  isTableList?: boolean;
  placeholder?: string;
}

const DisputeCodeList = (props: DisputeCodeListProps) => {
  const { disputeCodes, disableTooltip, isTableList, placeholder } = props;

  if (disputeCodes.length === 0) {
    return <Fragment>{placeholder || '- -'}</Fragment>;
  }

  return (
    <div className='dispute-code-list'>
      {disputeCodes.map((dc, i) => (
        <Popup
          key={i}
          hoverable
          inverted
          popperModifiers={{ preventOverflow: { boundariesElement: 'window' } }}
          disabled={disableTooltip}
          position='top center'
          size='tiny'
          trigger={<div className={cx('dispute-code', { 'padding-top': isTableList })}>{dc.code}</div>}
          content={<div className='dispute-description'>{dc.description}</div>}
        />
      ))}
    </div>
  );
};

export default DisputeCodeList;
