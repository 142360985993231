import React from 'react';
import cx from 'classnames';
import '../../../themes/kalderos/Common/Modals/FakeModal.scss';

export interface FakeModalProps {
  children: React.ReactNode;
  className?: string;
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen'
}

const FakeModal: React.FunctionComponent<FakeModalProps> = (props: FakeModalProps) => {

  return (
    <div className={cx('fake-modal', props.className, props.size)}>
      <div className="ui modal">
        {props.children}
      </div>
    </div>
  )
}


export default FakeModal
