import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';

import ManufacturerSummaryCard from './common/ManufacturerSummaryCard';
import PageHeader from '../../common/navigation/PageHeader';
import PageSummary from '../../common/pageSummary/PageSummary';
import SummaryHeader from '../../common/pageSummary/SummaryHeader';
import SummaryItem from '../../common/pageSummary/SummaryItem';
import { getDisputesCenterSummary, getManufacturerSummaries, updateFilters } from '../../../store/mdrp/disputescenter/actionCreators';
import { DisputesCenterStatusMap, DISPUTES_CENTER_STATUS_TYPES } from '../../../store/mdrp/disputescenter/constants';
import { ManufacturerSummaryModel, DisputesSummaryModel, ManufacturerDisputesFilterModel } from '../../../store/mdrp/disputescenter/types';
import { RootState } from '../../../store/RootState';
import { DisputesCenterBannerIcon, DisputesCenterHeaderIcon } from '../../../themes/kalderos/Common/Icons';
import { formatDollar } from '../../../utils/numberFormatHelper';

export interface DisputesCenterProps extends RouteComponentProps {
  activeIndex: number;
  getDisputesCenterSummary: (stateCode: string) => void;
  getManufacturerSummaries: (stateCode: string) => void;
  isLoading: boolean;
  isSummaryLoading: boolean;
  manufacturers: ManufacturerSummaryModel[];
  newDisputesFilters: ManufacturerDisputesFilterModel;
  summary: DisputesSummaryModel;
  updateFilters: (activeTab: string, filters: ManufacturerDisputesFilterModel) => void;
}

export const DisputesCenter = (props: DisputesCenterProps) => {
  const { activeIndex, getDisputesCenterSummary, getManufacturerSummaries, history, isLoading, isSummaryLoading, manufacturers, newDisputesFilters, summary, updateFilters } = props;

  const [headerBeforeText, setHeaderBeforeText] = useState<string>('');
  const [headerAmountText, setHeaderAmountText] = useState<string>('');
  const [headerAfterText, setHeaderAfterText] = useState<string>('');
  
  const pageHeader = DisputesCenterStatusMap[activeIndex] === DISPUTES_CENTER_STATUS_TYPES.OPEN ? 'Open Disputes' : 'Closed Disputes';
  const summaryError = summary.errorText !== '';
  const isOpenDisputesTab = DisputesCenterStatusMap[activeIndex] === DISPUTES_CENTER_STATUS_TYPES.OPEN;
  const sortedManufacturers = manufacturers.sort((a, b) => a.name.localeCompare(b.name))

  const setInvoiceSummaryProps = () => {
    if (isOpenDisputesTab) {
      const openDisputesTotal = summary.totalImpasseAmount + summary.totalNewDisputeAmount + summary.totalSentToManufacturerAmount;

      setHeaderBeforeText('You have')
      setHeaderAmountText(summaryError ? '- -' : `${formatDollar(openDisputesTotal)}`)
      setHeaderAfterText('in open disputes')
    } else {
      // closed disputes text to be implemented in later story
      setHeaderBeforeText('')
      setHeaderAmountText('')
      setHeaderAfterText('')
    }
  };

  const summaryItemValue = (summaryValue: number, format: boolean = true) => {
    const value = format ? formatDollar(summaryValue) : summaryValue;
    return summaryError ? '- -' : value;
  };

  const routeToManufacturerReview = (manufacturerId: number, labelerCode?: string) => {
    if (isOpenDisputesTab) {
      if (labelerCode) {
        const updatedFilters = {
          ...newDisputesFilters,
          labeler: [labelerCode]
        };
        updateFilters('newDisputes', updatedFilters)
      }

      history.push(`/states/disputes/open/manufacturer/${manufacturerId}/newdisputes`)
    }
  };

  useEffect(() => {
    getDisputesCenterSummary('AK')
  }, [])

  useEffect(() => {
    setInvoiceSummaryProps()
    getManufacturerSummaries('AK')
  }, [activeIndex])

  useEffect(() => {
    setInvoiceSummaryProps()
  }, [summary])

  return (
    <div className="disputes-center scrollable-page">
      {(isSummaryLoading || isLoading) ? 
        <Dimmer active inverted><Loader inverted content='Loading' /></Dimmer> :
        <Fragment>
          <div className="disputes-center-header">
            <div className="banner-icon">
              <DisputesCenterBannerIcon />
            </div>
            <PageHeader text={pageHeader} icon={<DisputesCenterHeaderIcon />} />
            <PageSummary
              summaryHeader={
                <SummaryHeader
                  beforeText={headerBeforeText}
                  amountText={headerAmountText}
                  afterText={headerAfterText}
                />
              }
              summaryTotals={
                activeIndex === 0 && (
                  <Fragment>
                    <SummaryItem title='OPEN DISPUTED CLAIMS' value={summaryItemValue(summary.openDisputedClaims, false)} />
                    <SummaryItem title='TOTAL NEW DISPUTE AMOUNT' value={summaryItemValue(summary.totalNewDisputeAmount)} />
                    <SummaryItem title='TOTAL SENT TO MFR. AMOUNT' value={summaryItemValue(summary.totalSentToManufacturerAmount)} />
                    <SummaryItem title='TOTAL IMPASSE AMOUNT' value={summaryItemValue(summary.totalImpasseAmount)} />
                  </Fragment>
                )
              }
            />
          </div>
          <div className="disputes-center-body">
            {manufacturers && 
              <div className="disputes-center-cards-container">
                {activeIndex === 0 && sortedManufacturers.map((mfr: ManufacturerSummaryModel, index) => {
                  return <ManufacturerSummaryCard key={index} manufacturer={mfr} onViewClick={routeToManufacturerReview} />
                })}
              </div>
            }
          </div>
        </Fragment>}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: state.MdrpState.disputesCenter.disputesCenterHome.manufacturerSummaries.isLoading,
  isSummaryLoading: state.MdrpState.disputesCenter.disputesCenterHome.disputesSummary.isLoading,
  manufacturers: state.MdrpState.disputesCenter.disputesCenterHome.manufacturerSummaries.manufacturers,
  newDisputesFilters: state.MdrpState.disputesCenter.disputesReview.manufacturerDisputesFilters.newDisputes,
  summary: state.MdrpState.disputesCenter.disputesCenterHome.disputesSummary
});

const mapDispatchToProps = (dispatch: any) => ({
  getDisputesCenterSummary: (stateCode: string) => dispatch(getDisputesCenterSummary(stateCode)),
  getManufacturerSummaries: (stateCode: string) => dispatch(getManufacturerSummaries(stateCode)),
  updateFilters: (activeTab: string, filters: ManufacturerDisputesFilterModel) => dispatch(updateFilters(activeTab, filters))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DisputesCenter));
