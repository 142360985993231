import React from 'react'
import { Icon, TransitionablePortal, Button, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { RootState } from '../../../store/RootState';
import Verify from './Verify';
import DataEntry from './DataEntry';
import Review from './Review';
import FormStepper, { FormStepperStep, STEP_STATUS } from '../../common/formStepper/FormStepper';
import { getActiveHealthSystemId } from '../../../store/teammanagement/selectors';
import { LEFT_NAV_WIDTH } from '../../../store/applicationConstants';
import CloseButton from '../../common/CloseButton';
import { DiscountRequestAdditionalFieldsModel, DiscountRequestFieldSubmitModel, DiscountRequestRequiredFieldsModel, RequestCenterRequestSubmitStepModel, RequestCenterRequestVerifyStepModel } from '../../../store/requestcenter/types';
import { getDiscountRequestProgramsAndCoveredEntities, getDiscountRequestContractFields, resetDiscountRequestForm, submitDiscountRequest } from '../../../store/requestcenter/actionCreators';

export interface RequestFormProps {
  isOpen: boolean;
  verify: RequestCenterRequestVerifyStepModel,
  submit: RequestCenterRequestSubmitStepModel,
  isLeftNavExpanded?: boolean;
  activeHealthSystemId: number;
  getDiscountRequestProgramsAndCoveredEntities: () => void;
  getDiscountRequestContractFields: (values: DiscountRequestRequiredFieldsModel) => void;
  submitDiscountRequest: (fields: DiscountRequestFieldSubmitModel) => void;
  resetDiscountRequestForm: () => void;
  onClose: (isSuccess: boolean) => void;
}

interface RequestFormState {
  steps: Record<string, FormStepperStep>;
  requiredFieldValues: DiscountRequestRequiredFieldsModel,
  additionalFieldValues: DiscountRequestAdditionalFieldsModel;
  hadSuccess: boolean;
}

const initialState: RequestFormState = {
  steps: {
    verify: { text: 'Verify Eligibility', status: STEP_STATUS.ACTIVE, isComplete: false },
    dataEntry: { text: 'Enter Data', status: STEP_STATUS.PENDING, isComplete: false, prevStep: 'verify' },
    review: { text: 'Review and Submit ', status: STEP_STATUS.PENDING, isComplete: false, prevStep: 'dataEntry' }
  },
  requiredFieldValues: {
    discountProgram: '',
    ndc11: '',
    fillDate: null,
    idCode: '',
    claimType: 'Retail'
  },
  additionalFieldValues: {},
  hadSuccess: false,
};

export class RequestForm extends React.Component<RequestFormProps, RequestFormState>{
  constructor (props: Readonly<RequestFormProps>) {
    super(props);
    this.state = initialState;
  }

  componentDidUpdate (prevProps: RequestFormProps) {
    if (this.props.activeHealthSystemId !== prevProps.activeHealthSystemId) {
      this.props.onClose(false);
      this.reset(false);
    }

    if (this.props.isOpen && !prevProps.isOpen) {
      if (this.props.verify.coveredEntities.length === 0) { //this.props.verify.discountPrograms.length === 0
        this.props.getDiscountRequestProgramsAndCoveredEntities();
      }
    }

    if (this.props.verify.isSuccess && !prevProps.verify.isSuccess) {
      this.completeVerifyStep();
    }

    if (this.props.submit.isSuccess && !prevProps.submit.isSuccess) {
      this.completeReviewStep();
    }

    if (this.props.submit.errors.length > 0 && prevProps.submit.errors.length === 0) {
      this.onReviewSubmitError();
    }
  }

  componentWillUnmount () {
    this.props.resetDiscountRequestForm();
  }

  reset = (saveSuccess: boolean) => {
    this.setState({ ...initialState, hadSuccess: saveSuccess, });
    this.props.resetDiscountRequestForm();
  }

  handleClose = () => {
    this.props.onClose(this.props.submit.isSuccess || this.state.hadSuccess);
    this.reset(false);
  }

  activateStep = (stepName: string) => {
    const activeStep = Object.keys(this.state.steps).find(key => this.state.steps[key].status === STEP_STATUS.ACTIVE) || '';
    this.setState({
      steps: this.getStepObjToUpdate([
        { stepName: stepName, newStatus: STEP_STATUS.ACTIVE },
        // if active step was complete, then set the status to complete, else set to pending
        { stepName: activeStep, newStatus: this.state.steps[activeStep].isComplete ? STEP_STATUS.COMPLETE : STEP_STATUS.PENDING }
      ])
    });
  }

  getStepObjToUpdate = (stepUpdateInfo: { stepName: string, newStatus: STEP_STATUS }[]) => {
    let updatedSteps = { ...this.state.steps };
    stepUpdateInfo.forEach(step => {
      updatedSteps = {
        ...updatedSteps,
        [step.stepName]: { ...updatedSteps[step.stepName], status: step.newStatus, isComplete: step.newStatus === STEP_STATUS.COMPLETE ? true : updatedSteps[step.stepName].isComplete }
      }
    })
    return updatedSteps;
  }

  onVerifySubmit = (requiredFieldValues: DiscountRequestRequiredFieldsModel) => {
    this.setState({ requiredFieldValues: requiredFieldValues });
    this.props.getDiscountRequestContractFields(requiredFieldValues);
  }

  completeVerifyStep = () => {
    this.setState({
      steps: this.getStepObjToUpdate([
        { stepName: 'verify', newStatus: STEP_STATUS.COMPLETE },
        { stepName: 'dataEntry', newStatus: STEP_STATUS.ACTIVE }])
    });
  }

  onDataEntrySubmit = (values: DiscountRequestAdditionalFieldsModel) => {
    this.setState({
      steps: this.getStepObjToUpdate([
        { stepName: 'dataEntry', newStatus: STEP_STATUS.COMPLETE },
        { stepName: 'review', newStatus: STEP_STATUS.ACTIVE }]),
      additionalFieldValues: values
    });
  }

  onReviewSubmit = async () => {
    this.setState({ steps: this.getStepObjToUpdate([{ stepName: 'review', newStatus: STEP_STATUS.PROCESSING }]) });
    this.props.submitDiscountRequest({ ...this.state.requiredFieldValues, ...this.state.additionalFieldValues });
  }

  onReviewSubmitError = () => {
    this.setState({ steps: this.getStepObjToUpdate([{ stepName: 'review', newStatus: STEP_STATUS.ACTIVE }]) });
  }

  completeReviewStep = () => {
    this.setState({ steps: this.getStepObjToUpdate([{ stepName: 'review', newStatus: STEP_STATUS.COMPLETE }]) });
  }

  render () {
    const { steps } = this.state;

    const isLoading = this.props.verify.isLoading || this.props.submit.isLoading;
    const loadingContent = this.props.submit.isLoading ? 'Submitting' : 'Loading';

    const leftNavWidth = this.props.isLeftNavExpanded ? LEFT_NAV_WIDTH.EXPANDED : LEFT_NAV_WIDTH.COLLAPSED;

    return (
      <TransitionablePortal open={this.props.isOpen} transition={{ animation: 'slide up', duration: 400 }} closeOnDocumentClick={false} closeOnEscape={false}>
        <div className='request-form' style={{ width: `calc(100% - ${leftNavWidth}px)`, left: leftNavWidth }}>
          <div className='request-form-content flex-column flex-grow scroll-hidden'>
            <div className='request-form-header flex-column justify-center'>
              <div className='flex-row justify-space-between'>
                <div className='beta'>Enter Discount Request</div>
                <CloseButton onClick={this.handleClose} />
              </div>
            </div>
            {isLoading && <Dimmer active inverted><Loader inverted content={loadingContent} /></Dimmer>}
            {
              steps.verify.status === STEP_STATUS.ACTIVE &&
              <Verify
                requiredFieldValues={this.state.requiredFieldValues}
                // discountPrograms={this.props.verify.discountPrograms}
                coveredEntities={this.props.verify.coveredEntities}
                submitError={this.props.verify.errorText}
                onSubmit={this.onVerifySubmit}
              >
                <div className="multi-step-form-left">
                  <FormStepper steps={steps} activateStep={this.activateStep} />
                </div>
              </Verify>
            }
            {
              steps.dataEntry.status === STEP_STATUS.ACTIVE &&
              <DataEntry
                requiredFieldValues={this.state.requiredFieldValues}
                contractFields={this.props.submit.contractFields}
                additionalFieldValues={this.state.additionalFieldValues}
                onSubmit={this.onDataEntrySubmit}
              >
                <div className="multi-step-form-left">
                  <FormStepper steps={steps} activateStep={this.activateStep} />
                </div>
              </DataEntry>
            }
            {
              (steps.review.status === STEP_STATUS.ACTIVE || steps.review.status === STEP_STATUS.PROCESSING) &&
              <Review
                contractFields={this.props.submit.contractFields}
                requiredFieldValues={this.state.requiredFieldValues}
                additionalFieldValues={this.state.additionalFieldValues}
                onSubmit={this.onReviewSubmit}
                submitErrors={this.props.submit.errors}
              >
                <div className="multi-step-form-left">
                  <FormStepper steps={steps} activateStep={this.activateStep} />
                </div>
              </Review>
            }
            {
              steps.review.status === STEP_STATUS.COMPLETE &&
              <React.Fragment>
                <div className='request-form-body flex-row flex-grow scroll-hidden'>
                  <div className="multi-step-form-left">
                    <FormStepper steps={steps} activateStep={this.activateStep} isDisabled />
                  </div>
                  <div className='request-form-details request-form-success flex-column justify-center'>
                    <div className='flex-column'>
                      <div className='checkicon-outline'><Icon name='check circle outline' size='big' className='checkicon' /></div>
                    </div>
                    <div className='beta'>Request successfully added!</div>
                    <Button className='go-to-link epsilon disReqGoToSubmitted_Pendo' onClick={this.handleClose}>
                      Go to Submitted Discount Requests <Icon name='arrow right' size='large' />
                    </Button>
                  </div>
                </div>
                <div className='request-form-footer flex-column justify-center'>
                  <div className='flex-row justify-flex-end'>
                    <div className='actions'>
                      <Button basic className='upload-another disReqEnterAnother_Pendo' onClick={() => this.reset(true)}>Enter Another Request</Button>
                      <Button secondary onClick={this.handleClose}>Close</Button>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }

          </div>
        </div>
      </TransitionablePortal >
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  verify: state.RequestCenterState.requestForm.verify,
  submit: state.RequestCenterState.requestForm.submit,
  isLeftNavExpanded: state.AppCommonState.navigationState.isLeftNavExpanded,
  user: state.LoginState.user,
  activeHealthSystemId: getActiveHealthSystemId(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getDiscountRequestProgramsAndCoveredEntities: () => dispatch(getDiscountRequestProgramsAndCoveredEntities()),
  getDiscountRequestContractFields: (values: DiscountRequestRequiredFieldsModel) => dispatch(getDiscountRequestContractFields(values)),
  submitDiscountRequest: (fields: DiscountRequestFieldSubmitModel) => dispatch(submitDiscountRequest(fields)),
  resetDiscountRequestForm: () => dispatch(resetDiscountRequestForm())
});


export default connect(mapStateToProps, mapDispatchToProps)(RequestForm);
