import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store/RootState';
import classNames from 'classnames';
import { makeSelectListOfSteps } from '../../store/onboarding/selectors'

interface OnboardingStepsProps {
  stepNumber?: number,
  steps: string[]
}

export class OnboardingSteps extends React.Component<OnboardingStepsProps> {

  render () {
    const { steps, stepNumber } = this.props;

    return (
      <ol>
        {steps.map((step, index) => {

          const isCurrentStep = (stepNumber ? stepNumber - 1 : 0) === index;

          return (
            <li className={classNames('step', { 'active': isCurrentStep })} key={index}>
              <div className="step-name">{step}</div>
              <div className="step-divider"></div>
            </li>
          )
        })}
      </ol>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  steps: makeSelectListOfSteps(state)
})

export default connect(mapStateToProps)(OnboardingSteps);
