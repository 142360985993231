import React from 'react';

import Layout from '../Layout';
import PaymentSuccess from './PaymentSuccess';
import GoToRequestFooter from './GoToRequestFooter';

class PaymentSuccessFullScreen extends React.Component {
  render () {
    return (
      <Layout stepNumber={7}      >
        <PaymentSuccess />
        <GoToRequestFooter />
      </Layout>
    )
  }
}

export default PaymentSuccessFullScreen;
