import React from 'react';
import logo from '../../../themes/kalderos/static/media/Payment_Connected.svg'

const PaymentSuccess = () => {
  return (
    <div className="onboarding-layout-children">
      <div className="onboarding-layout-body payment-success">
        <img src={logo} alt="success" />
        <div className="details">
          We received all the information we need to begin verifying your bank account. You will soon be all set up to receive money through Kalderos Request.
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
