import RequestConfig from '../../app.config';
import fetchWrapper from '../FetchWrapper';
import { createUrlParametersString } from '../../utils/requestUtil';
import { API_PAGE_SIZE } from '../../store/requestcenter/constants';
import { commonResponseHandler } from '../../utils/responseUtils';

export const fetchReversalsTransactions = async (pageToFetch: number, sortBy: string, isSortAsc: boolean, queryFilter: any, healthSystemId: number) => {
  if (sortBy === 'statusTextSort') {
    sortBy = 'statusText';
  }

  const urlParams = createUrlParametersString({ page: pageToFetch, pageSize: API_PAGE_SIZE, SortField: sortBy, SortDesc: !isSortAsc })
  const apiUrl = `${await RequestConfig()}/request/v1/discountrequests?${urlParams}&${queryFilter}&healthSystemId=${healthSystemId}`
  const response = await fetchWrapper(apiUrl, true);
  return commonResponseHandler(response);
};
