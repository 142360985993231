import React from 'react';
import { Link } from 'react-router-dom';
import { Tab, Table, Popup } from 'semantic-ui-react';
import PageHeader from '../../common/navigation/PageHeader';
import PaymentSettings from './PaymentSettings';
import PaymentHistory from './PaymentHistory';
import SortIconSet from '../../common/SortIconSet';
import { SortingFilter } from '../../../store/appcommon/types';
import { formatMDYYYY } from '../../../utils/dateHelper';
import { formatCredits, formatDiscountAmount } from '../../../utils/numberFormatHelper';
import RemoveFundingSourceModal from '../common/RemoveFundingSourceModal';
import RestrictedByRole from '../../teammanagement/RestrictedByRole';
import { RECIPIENT_TEAM_MANAGEMENT_USER_ROLES } from '../../../store/teammanagement/constants';
import RetryPayments from './RetryPayments';
import VerifyMicroDepositsModal from '../common/VerifyMicroDepositsModal';
import { NotificationDot } from '../../../themes/kalderos/Common/Icons';
import InitiateMicroDeposits  from './InitiateMicroDeposits';
import CreditsPerManufacturer from './CreditsPerManufacturer';
import { connect } from 'react-redux';
import { RootState } from '../../../store/RootState';
import { isAdminAllAccess } from '../../../utils/roleUtil';

const RecipientPaymentsCenterTabMap: { [key: string]: number } = {
  'history': 0,
  'settings': 1,
  'credits': 2,
}

export function getRecipientPaymentsCenterTabIndex (path: string): number {
  return RecipientPaymentsCenterTabMap[path.substr(path.lastIndexOf('/') + 1)];
}

interface RecipientPaymentsCenterProps {
  activeIndex: number;
  userRole: string[] | null;
}

interface RecipientBasicTableProps {
  headers: any[];
  data: any;  
  title?: string;
  table: string;
  handleSortClick: (sort: SortingFilter) => void;
  showRetry?: boolean
}

//functional component . (basic table for recipient). . pass in headers needed and data / then map body
export const RecipientBasicTable: React.FunctionComponent<RecipientBasicTableProps> = ({ headers, data, title, handleSortClick, table, showRetry=false }) =>
  <div className="basic-table-wrapper recipient-payments">
    <div className="flex-row title-row">
      <div className="gamma">{title ? title : null}</div>
    </div>
    {data.length > 0 &&
      <Table unstackable className="basic-table">
        <Table.Header>
          <Table.Row>
            {headers && headers.map((idr: any, index: any) =>
              <Table.HeaderCell key={index} className={idr.header === 'Amount' ? 'amount-header' : ''}>
                {idr.header}
                {(idr.header !== 'Amount' && table === 'paymentHistory') && <SortIconSet field={idr.sortValue} handleCellSorting={handleSortClick}/>}
                {table === 'creditsPerManufacturer' && <SortIconSet field={idr.sortValue} handleCellSorting={handleSortClick}/>}
              </Table.HeaderCell>)}
            {/* Blank header cell for formatting (payment settings table) */}
            {table === 'paymentSettings' && <Table.HeaderCell></Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {table === 'paymentSettings' && data.map((i: any) => {
            return (
              <Table.Row key={i.id}>
                {i.status === 'unverified' ? <Table.Cell className='body-content'><div className='md-verification-pending'>Microdeposit verification pending</div><div data-private>{i.name}{<div>{i.eligibleToInitiateMicroDeposits && <InitiateMicroDeposits sourceId={i.fundingSourceId}/>}</div>}</div></Table.Cell>  :
                  <Table.Cell data-private className="body-content">{i.name}{<div>{i.eligibleToInitiateMicroDeposits && <InitiateMicroDeposits sourceId={i.fundingSourceId}/>}</div>}</Table.Cell>}
                <Table.Cell data-private className="body-content">{i.bankName}</Table.Cell>
                <Table.Cell className='body-content action-btn'>
                  <RestrictedByRole allowedRoles={[RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.PAYMENT, RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN]}>
                    {i.status === 'unverified' && <VerifyMicroDepositsModal sourceId={i.id} accountName={i.name} bankName={i.bankName} />}
                    <RemoveFundingSourceModal sourceId={i.id} name={i.name} bankName={i.bankName} />
                    {(showRetry && i.status === 'verified') &&  <RetryPayments />}
                  </RestrictedByRole>
                </Table.Cell>
              </Table.Row>
            )
          })}
          {table === 'paymentHistory' && data.map((i: any, index: number) => {
            return (
              <Table.Row key={index} className={i.status === 'Failed' ? 'failed-transactions' : 'payment-history-body'}>
                <Table.Cell data-private width={2} className="failed-content">{i.status === 'Failed' ? 'Transfer failed' : formatMDYYYY(i.datePaid)}</Table.Cell>
                <Table.Cell data-private width={3} className="body-content"><Link id='remittanceLink_Pendo' to={`/recipients/remittance/invoice/${i.remittanceNumber}/${i.idCode}`} className="app-link blue-link remittance-link">{`Remittance ${i.remittanceNumber}`}</Link></Table.Cell>
                <Table.Cell data-private width={3} className="body-content">{i.idCode}</Table.Cell>
                <Table.Cell data-private width={3} className="failed-content"> {i.status === 'Failed' ? <div>
                  <Popup className="failed-content tooltip" inverted trigger={<div className="text-content"><NotificationDot /><span className="notification">{i.accountName}</span></div>}
                    position='bottom center'
                    content="There is a problem with your Linked Account. Go to Payment Settings to resolve."
                    size='tiny' />
                </div>
                  : i.accountName}</Table.Cell>
                <Table.Cell data-private width={3} className="body-content">{i.manufacturerName}</Table.Cell>
                <Table.Cell data-private width={2} className="body-content semi-bold" textAlign='right'>{formatDiscountAmount(i.amountPaid)}</Table.Cell>
              </Table.Row>
            )
          })}
          {table === 'creditsPerManufacturer' && data.map((i: any, index: number) => {
            return (
              <Table.Row key={index}>
                <Table.Cell data-private width={4} className="body-content">{i.id340b}</Table.Cell>
                <Table.Cell data-private width={4} className="body-content">{i.name340b}</Table.Cell>
                <Table.Cell data-private width={4} className="body-content">{i.manufacturerName}</Table.Cell>
                <Table.Cell data-private width={4} className="body-content">{formatCredits(i.credits)}</Table.Cell>
              </Table.Row>
            )
          })}

        </Table.Body>
      </Table>
    }
  </div>

export class RecipientPaymentsCenter extends React.Component<RecipientPaymentsCenterProps>{

  render () {

    const disablePaymentSettings = isAdminAllAccess(this.props.userRole || []);
    const panes = [
      {
        menuItem: {
          as: Link,
          key: 'history',
          content: 'Payment History',
          to: '/recipients/payments/history',
        },
        render: () => <Tab.Pane><PaymentHistory /></Tab.Pane>
      },
      {
        menuItem: {
          as: Link,
          key: 'settings',
          content: 'Payment Settings',
          to: '/recipients/payments/settings',
          disabled: disablePaymentSettings
        },
        render: () => <Tab.Pane><PaymentSettings /></Tab.Pane>
      },      
      {
        menuItem: {
          as: Link,
          key: 'credits',
          content: 'Credits Per Manufacturer',
          to: '/recipients/payments/credits',
        },
        render: () => <Tab.Pane><CreditsPerManufacturer /></Tab.Pane>
      }
    ]

    return (
      <div className="recipient-payments-center v-scroll-auto">
        <PageHeader className="recipient-payments-center-page-header" text="Payments" />
        <Tab activeIndex={this.props.activeIndex}
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          className="tab-controller fit-height divided" />
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  userRole: state.LoginState.user.roles
});

export default connect(mapStateToProps)(RecipientPaymentsCenter);
