import React, { Fragment } from 'react';
import { Icon } from 'semantic-ui-react';
import classnames from 'classnames';
import { X } from 'react-feather';

import { FilterModel } from '../../../store/appcommon/types';

interface FilterDropdownProps {
  label: string;
  options: FilterModel[];
  values?: string[];
  search?: boolean;
  onChange?: (selection: any[]) => void;
  onDropDownClose?: (selection: any[]) => void;
  isPrimaryFilterType?: boolean;
  isDisabled?: boolean;
  onMultipleItemsChange?: (selection: any[]) => void;
}

interface FilterDropdownState {
  isOpen: boolean;
  selectedOptions: FilterModel[],
  searchText: string,
  keyIndex: number,
  optionRefs: { [key: string]: React.RefObject<HTMLDivElement> },
}

export class FilterDropdown extends React.Component<FilterDropdownProps, FilterDropdownState> {

  dropDownRef: React.RefObject<HTMLDivElement>;
  textboxRef: React.RefObject<HTMLInputElement>;

  constructor (props: FilterDropdownProps) {
    super(props);
    this.state = {
      isOpen: false,
      selectedOptions: [],
      searchText: '',
      keyIndex: -1,
      optionRefs: {},
    }
    this.dropDownRef = React.createRef<HTMLDivElement>();
    this.textboxRef = React.createRef<HTMLInputElement>();
  }

  componentDidMount () {
    document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
    this.setOptionRefs();
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', (e) => this.handleClickOutside(e));
  }

  componentDidUpdate (prevProps: FilterDropdownProps, prevState: FilterDropdownState) {
    if (prevState.isOpen && !this.state.isOpen) {
      if (this.props.onDropDownClose) {
        this.props.onDropDownClose(this.state.selectedOptions);
      }
    }

    if (prevProps.options !== this.props.options) {
      this.setOptionRefs();
    }
  }

  static getDerivedStateFromProps (props: FilterDropdownProps, state: FilterDropdownState) {
    if (props.values) {
      const selected = props.options.filter(option => props.values?.includes(option.value))
      return { ...state, selectedOptions: selected };
    } else {
      return null;
    }
  }

  setOptionRefs = () => {
    const refList = this.props.options.reduce((list: { [key: string]: React.RefObject<HTMLDivElement> }, val) => {
      list[val.value] = React.createRef<HTMLDivElement>();
      return list;
    }, {});
    this.setState({ optionRefs: refList });
  }

  toggleIsOpen = () => {
    if (this.props.isDisabled) {
      return this.setState({ isOpen: false });
    }

    const isOpen = !this.state.isOpen;
    this.setState({ isOpen: isOpen, searchText: '', keyIndex: -1 });

    if (isOpen) {
      this.textboxRef.current?.focus();
    }
  }

  selectItem = (option: FilterModel) => {
    let items = [...this.state.selectedOptions];
    items.push(option);

    //make sure selected list is in same order as source
    items = this.props.options.filter((x) => items.indexOf(x) >= 0).map((x) => x);

    this.setState({ selectedOptions: items });
    const value = items.map(item => item.value);

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  removeItem = (option: FilterModel) => {
    const items = [...this.state.selectedOptions];
    items.splice(items.indexOf(option), 1);

    this.setState({ selectedOptions: items });
    const value = items.map(item => item.value);

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  removeMultipleItems = () => {
    this.setState({ selectedOptions: [] });

    if (this.props.onMultipleItemsChange) {
      this.props.onMultipleItemsChange([]);
    }
  }

  onBlur = () => {
    if (!this.props.search && this.state.isOpen) {
      this.toggleIsOpen();
    }
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, opts: FilterModel[]) => {

    try {
      if (event.key === 'ArrowDown') {
        if (this.state.isOpen) {
          if (opts.length > 0) {
            let i = this.state.keyIndex;
            if (this.state.keyIndex === opts.length - 1) {
              //at the end, jump to beginning
              i = 0;
            } else {
              i = this.state.keyIndex + 1;
            }
            this.setState({ keyIndex: i });
            this.state.optionRefs[opts[i].value].current?.scrollIntoView({ block: 'nearest' })
          }
        } else {
          this.toggleIsOpen();
        }
        event.preventDefault();
      }

      if (event.key === 'ArrowUp') {
        if (this.state.isOpen) {
          if (opts.length > 0) {
            let i = this.state.keyIndex;
            if (this.state.keyIndex <= 0) {
              //at the beginning, jump to end
              i = opts.length - 1;
            } else {
              i = this.state.keyIndex - 1;
            }
            this.setState({ keyIndex: i });
            this.state.optionRefs[opts[i].value].current?.scrollIntoView({ block: 'nearest' });
          }
        }
        event.preventDefault();
      }

      if (event.key === 'Enter') {
        if (this.state.isOpen) {
          if (opts.length > 0 && this.state.keyIndex >= 0) {
            if (opts[this.state.keyIndex]) {

              this.selectItem(opts[this.state.keyIndex]);

              //selectItem will remove item from list
              const newLength = opts.length - 1;

              if (newLength === 0) {
                //last item removed, select nothing
                this.setState({ keyIndex: -1 });
              } else {
                const i = this.state.keyIndex;
                if (i > 0 && i >= newLength) {
                  //end of list, move down one item
                  this.setState({ keyIndex: newLength - 1 });
                  this.state.optionRefs[opts[i - 1].value].current?.scrollIntoView({ block: 'nearest' })
                } else {
                  //list shifts down one so keep index the same, but scroll to next item
                  this.setState({ keyIndex: i });
                  this.state.optionRefs[opts[i + 1].value].current?.scrollIntoView({ block: 'nearest' })
                }
              }
            }
          }

        } else {
          this.toggleIsOpen();
        }
        event.preventDefault();
      }

      if (event.key === 'Escape') {
        if (this.state.isOpen) {
          this.toggleIsOpen();
          event.preventDefault();
        }
      }
    } catch {
      //don't let keyboard nav crash app. Just flush selection instead.
      this.setState({ keyIndex: -1 });
    }
  }

  onItemAddClick = (e: React.MouseEvent, item: FilterModel) => {
    e.stopPropagation();
    this.setState({ keyIndex: -1 });
    this.selectItem(item);
  }

  onItemRemoveClick = (e: React.MouseEvent, value: FilterModel) => {
    e.stopPropagation();
    this.removeItem(value);
  }

  onMultipleItemsRemoveClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.removeMultipleItems();
  }

  onSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: event.target.value, keyIndex: -1 })
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.state.isOpen) {
      if (this.dropDownRef && !this.dropDownRef.current?.contains(event.target as Node)) {
        this.setState({ isOpen: false })
      }
    }
  }

  render () {
    const summary = `${this.props.label} (${this.state.selectedOptions.length > 0 ? this.state.selectedOptions.length : 'All'})`;
    const isApplied = this.state.selectedOptions.length > 0 && !this.state.isOpen;

    const opts = this.props.options.filter(o => this.state.selectedOptions.indexOf(o) < 0 &&
            ((this.props.search && this.state.searchText) ? o.text.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0 : true)
    );

    let value = ''
    let placeholder = ''

    if (this.props.isPrimaryFilterType) {
      placeholder = this.state.isOpen ? summary : isApplied ? '' : summary;
      value = this.state.isOpen ? this.state.searchText : '';
    } else {
      placeholder = summary;
      value = this.state.isOpen ? this.state.searchText : this.state.selectedOptions.length > 0 ? summary : '';
    }

    return (
      <div className="filter-dropdown" ref={this.dropDownRef} title={this.props.isPrimaryFilterType ? '' : this.props.values?.join('\n')}>
        <div
          className={classnames('ui fluid multiple selection dropdown', { 'active': this.state.isOpen }, { 'search': this.props.search }, { 'applied': isApplied }, { 'primary-filter': this.props.isPrimaryFilterType }, { disabled: this.props.isDisabled })}
          onClick={this.toggleIsOpen}
          onKeyDown={(e) => this.onKeyDown(e, opts)}
          onBlur={this.onBlur}
          tabIndex={0} >
          {this.props.search ?
            <Fragment>
              {(this.props.isPrimaryFilterType && isApplied) && 
                <div>
                  <div className='default text ui label'>
                    <div>{summary}</div>
                    <div className="clear-icon-container" onClick={(e) => this.onMultipleItemsRemoveClick(e)}><X size={16} /></div>
                  </div>
                </div>
              }
              <input
                ref={this.textboxRef}
                type="text"
                className="search"
                placeholder={placeholder}
                value={value}
                onClick={(e) => { if (this.state.isOpen) { e.stopPropagation() } }}
                onChange={this.onSearchTextChange}
                disabled={this.props.isDisabled}
              />
            </Fragment> :
            <div className="default text">{summary}</div>
          }
          <Icon name="dropdown"></Icon>
          <div className={classnames({ 'visible': this.state.isOpen }, 'menu transition')}>
            {this.state.selectedOptions.map((o, i) =>
              <div key={i} className='selected item' onClick={(e) => { e.stopPropagation() }}>
                <div className="flex-row">
                  <div className="flex-grow">{o.text}</div>
                  <div onClick={(e) => this.onItemRemoveClick(e, o)}><X size={this.props.isPrimaryFilterType ? 18 : 14} /></div>
                </div>
              </div>
            )}
            {opts.map((o, i) =>
              <div key={o.key} ref={this.state.optionRefs[o.value]} className={classnames('item', { 'active': this.state.keyIndex === i })}
                onClick={(e) => this.onItemAddClick(e, o)}>{o.text}</div>
            )}
            {this.props.search && opts.length === 0 && this.state.selectedOptions.length < this.props.options.length &&
              <div className="message">No results found.</div>
            }
          </div>
        </div>
      </div>
    )
  }
}
