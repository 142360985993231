import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Button, Popup, Tab } from 'semantic-ui-react';
import PageHeader from '../common/navigation/PageHeader';
import Users from './Users'
import  CoveredEntities  from './CoveredEntities';
import Footer from '../../pages/common/drawer/components/Footer';
import RestrictedByRole from './RestrictedByRole';
import { RECIPIENT_TEAM_MANAGEMENT_USER_ROLES } from '../../store/teammanagement/constants';
import { ROLES, SUPPORT_EMAIL, SUPPORT_EMAIL_LINK, SUPPORT_PHONE } from '../../store/applicationConstants';
import { HealthSystemInfo, TeamMember } from '../../store/teammanagement/types';
import { RootState } from '../../store/RootState';
import { connect } from 'react-redux';
import { getActiveHealthSystemRole } from '../../store/teammanagement/selectors';
import { makeSelectIsAccountAdmin, makeSelectIsAuthorizingOfficial } from '../../store/onboarding/selectors';
import { openAddUserModal, openCancelInviteModal, openOnboardingInviteModal } from '../../store/teammanagement/actionCreators';
import { AddUserPlusIcon } from '../../themes/kalderos/Common/Icons';

const TeamManagementTabMap: { [key: string]: number } = {
  'users': 0,
  'coveredentities': 1,
}

export function getRecipientTeamManagementTabIndex (path: string): number {
  return TeamManagementTabMap[path.substr(path.lastIndexOf('/') + 1)];
}

export interface TeamManagementProps {
  activeIndex: number;
  healthSystem: HealthSystemInfo;
  isAuthorizingOfficial: boolean,
  isAccountAdmin: boolean,
  openAddUserModal: () => void,
  openOnboardingInviteModal: (role: string) => void,
}

interface TeamManagementState {
  viewDisabledUsers: boolean;
  disableUserModalOpen: boolean;
  isPopupOpen: boolean;
}
export class TeamManagement extends React.Component<TeamManagementProps, TeamManagementState> {

  constructor (props: TeamManagementProps) {
    super(props);
    this.state = {
      viewDisabledUsers: false,
      disableUserModalOpen: false,
      isPopupOpen: false
    }
  }

  handleAddUserClick = () => {
    this.props.openAddUserModal();
  }

  handleInviteAccountAdminClick = () => {
    this.props.openOnboardingInviteModal(ROLES.RECIPIENT_ACCOUNT_ADMIN);
  }

  handleInviteControllerClick = () => {
    this.props.openOnboardingInviteModal(ROLES.RECIPIENT_CONTROLLER);
  }

  setViewDisabledUsers = (view: boolean) => {
    this.setState({ viewDisabledUsers: view })
  };

  render () {
    const { activeIndex, healthSystem, isAccountAdmin, isAuthorizingOfficial } = this.props;
    const { viewDisabledUsers } = this.state;
    const panes = [
      {
        menuItem: {
          as: Link,
          key: 'users',
          content: 'Users',
          to: '/recipients/teammanagement/users',
        },
        render: () => <Tab.Pane><Users viewDisabledUsers={viewDisabledUsers} /></Tab.Pane>
      },
      {
        menuItem: {
          as: Link,
          key: 'coveredentites',
          content: 'Covered Entities',
          to: '/recipients/teammanagement/coveredentities',
        },
        render: () => <Tab.Pane><CoveredEntities /></Tab.Pane>
      },
    ];

    return (
      <Fragment>
        <div className="team-management-container">
          <PageHeader className='team-mgmt-header' text='Team Management' noBorder />
          <div className="tab-container">
            <Tab menu={{ secondary: true, pointing: true }} panes={panes} activeIndex={this.props.activeIndex} className={cx('tab-controller fit-height divided')} />
          </div>
        </div>
        <div>
          {(activeIndex === 0) && <Footer>
            <div className="teamManagement-footer flex-row justify-flex-end">
              <RestrictedByRole allowedRoles={[RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN]}>

                {
                  !viewDisabledUsers ?
                    <Button className="app-button ghost btn-text footer-button usersViewDisabled_Pendo" onClick={() => this.setViewDisabledUsers(true)}>View Disabled Users</Button>
                    :
                    <Button className="app-button ghost btn-text footer-button usersViewActive_Pendo" onClick={() => this.setViewDisabledUsers(false)}>View Active Users</Button>
                }
                <Button id='addNewUser_Pendo' className="app-button button-dark footer-button" onClick={this.handleAddUserClick}>
                  <div className="plusIcon"><AddUserPlusIcon /></div>
                  Add New User
                </Button>
                {!healthSystem.accountAdminId && isAuthorizingOfficial &&
                  <Button className="app-button button-dark footer-button" onClick={this.handleInviteAccountAdminClick}>
                    Invite Account Admin
                  </Button>
                }
                {!healthSystem.controllerId && isAccountAdmin &&
                  <Button className="app-button button-dark footer-button" onClick={this.handleInviteControllerClick}>
                    Invite Controller
                  </Button>
                }
              </RestrictedByRole>
            </div>
          </Footer>
          }
          {(activeIndex === 1) && <Footer>
            <div className="teamManagement-footer flex-row justify-flex-end">
              <div className="team-management-help-tooltip">
                <Popup
                  content={<div>
                    <div>To add covered entities to this Health System contact Kalderos Support at <a href={SUPPORT_EMAIL_LINK}>{SUPPORT_EMAIL}</a> or {SUPPORT_PHONE}.</div>
                    <div className="close"><button type="button" onClick={() => { this.setState({ isPopupOpen: false }) }}>Close</button></div>
                  </div>}
                  eventsEnabled={true}
                  position={'bottom right'}
                  on='click'
                  onClose={() => { this.setState({ isPopupOpen: false }) }}
                  onOpen={() => { this.setState({ isPopupOpen: true }) }}
                  open={this.state.isPopupOpen}
                  trigger={<button type="button" className="missingCoveredEntities_Pendo">Missing covered entities?</button>}
                  className="team-management-help-tooltip content"
                />
              </div>
            </div>
          </Footer>}
        </div>
      </Fragment>
    )
  }
}


const mapStateToProps = (state: RootState) => ({
  healthSystem: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo,
  isError: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.isError,
  viewDisabledUsers: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.viewDisabledUsers,
  activeRole: getActiveHealthSystemRole(state),
  isAuthorizingOfficial: makeSelectIsAuthorizingOfficial(state),
  isAccountAdmin: makeSelectIsAccountAdmin(state),

});

const mapDispatchToProps = (dispatch: any) => ({
  openAddUserModal: () => dispatch(openAddUserModal()),
  openCancelInviteModal: (user: TeamMember) => dispatch(openCancelInviteModal(user)),
  openOnboardingInviteModal: (role: string) => dispatch(openOnboardingInviteModal(role)),
});


export default connect(mapStateToProps, mapDispatchToProps)(TeamManagement);