import React, { useState } from 'react';
import {
  Checkbox,
  Popup,
  Table,
  Button,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { X, Edit3 } from 'react-feather';
import { AlertCircle } from 'react-feather';
import SortIconSet from '../../common/SortIconSet';
import { RootState } from '../../../store/RootState';
import { PharmacyManagementDetails } from '../../../store/coveredentityinfo/pharmacymanagement/types';
import { SortingFilter } from '../../../store/appcommon/types';
import { formatEmpty } from '../../../utils/dataUtil';
import { formatMMDDYYYY } from '../../../utils/dateHelper';
import PharmacyToggleButton from '../../common/toggle/PharmacyToggleButton';
import {
  GPOWAC_DIALOG_HEADER,
  GPOWAC_DIALOG_MESSAGE,
  GPOWAC_TOOLTIP_MESSAGE,
  PAY340B_DIALOG_HEADER,
  PAY340B_DIALOG_MESSAGE,
  PAY340B_TOOLTIP_MESSAGE,
} from '../../../store/coveredentityinfo/pharmacymanagement/constants';
import RemovePharmacyDialog from './RemovePharmacyDialog';
import { createToast, TOAST_TYPE, Toast } from '../../../store/toast';
import StandardModal from '../../common/modals/StandardModal';
import ModalHeaderContent from '../../common/modals/ModalContent/ModalHeaderContent';
import ModalBodyContent from '../../common/modals/ModalContent/ModalBodyContent';
import ModalFooterContent from '../../common/modals/ModalContent/ModalFooterContent';
import { update340BPayIndex, updateGPOWACIndex } from '../../../store/coveredentityinfo/pharmacymanagement/actioncreators';
import { getRemovePharmacyReasons } from '../../../store/coveredentityinfo/pharmacymanagement/actioncreators';
import EditDateOverrideModal from './EditDateOverrideModal';

interface AssociatedPharmaciesTableProps {
  associatedPharmacies: PharmacyManagementDetails[];
  isEditMode: boolean;
  isRemoveColumnVisible: boolean;
  removePharmacyReasons: string[];
  handleCellSorting: (sort: SortingFilter) => void;
  createToast: (toast: Toast) => void;
  updateGPOWACIndex: (index: number, pharmacy: PharmacyManagementDetails) => void;
  update340BPayIndex: (index: number, pharmacy: PharmacyManagementDetails) => void;
  getRemovePharmacyReasons: () => void;
}

export const AssociatedPharmaciesTable: React.FunctionComponent<AssociatedPharmaciesTableProps> = ({
  handleCellSorting,
  associatedPharmacies,
  isEditMode,
  isRemoveColumnVisible,
  createToast,
  removePharmacyReasons,
  getRemovePharmacyReasons,
  updateGPOWACIndex,
  update340BPayIndex
}) => {
  const [isRemovePharmacyDialogOpen, setIsRemovePharmacyDialogOpen] = useState(false);
  const [isEditDateOverrideDialogOpen, setIsDateOverrideDialogOpen] = useState(false);
  const [selectedToggleIndex, setSelectedToggleIndex] = useState(0);
  const [selectedCheckboxIndex, setSelectedCheckboxIndex] = useState(0);
  const [isGPOWAColumn, setIsGPOWACColumn] = useState(false);
  const [is340BPaycolumn, setIs340BPayColumn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPharmacy, setSelectedPharmacy] = useState<PharmacyManagementDetails>({} as PharmacyManagementDetails);

  const handleRemovePharmacyDialog = async (pharmacy: PharmacyManagementDetails) => {
    if (removePharmacyReasons.length === 0) {
      await getRemovePharmacyReasons();
    }

    await setIsRemovePharmacyDialogOpen(true);
    setSelectedPharmacy(pharmacy);
  };

  const closeRemovePharmacyDialog = () => {
    setIsRemovePharmacyDialogOpen(false);
  };

  const handleRemoveClick = () => {
    setIsRemovePharmacyDialogOpen(false);

    // temporarily creating the toast here until the redux files for remove pharmacy are set up
    const toast: Toast = {
      text: `${selectedPharmacy.pharmacyName} was removed.`,
      type: TOAST_TYPE.SUCCESS
    };

    createToast(toast);
  };

  const handleEditDateDialog = (pharmacy: PharmacyManagementDetails) => {
    setIsDateOverrideDialogOpen(true);
    setSelectedPharmacy(pharmacy);
  };

  const handleYesChangeClick = () => {
    setIsDateOverrideDialogOpen(false);

    // doing the same as above for the toast message
    const toast: Toast = {
      text: `The Start and/or Term Date Override was changed for ${selectedPharmacy.pharmacyName}`,
      type: TOAST_TYPE.SUCCESS
    }

    createToast(toast)
  }

  const closeEditDateOverrideOpen = () => {
    setIsDateOverrideDialogOpen(false);
  };

  const handleToggleChange = (pharmacy: PharmacyManagementDetails, index: number) => {
    setIsGPOWACColumn(true);
    setIs340BPayColumn(false);
    if (isEditMode) {
      setSelectedToggleIndex(index);
      setIsModalOpen(true);
      setSelectedPharmacy(pharmacy);
    }
  };

  const handleCheckBoxChange = (pharmacy: PharmacyManagementDetails, index: number) => {
    setIs340BPayColumn(true);
    setIsGPOWACColumn(false);
    if (isEditMode) {
      setSelectedCheckboxIndex(index)
      setIsModalOpen(true);
      setSelectedPharmacy(pharmacy);
    }
  };

  const handleGPOWACConfirmChanges = () => {
    updateGPOWACIndex(selectedToggleIndex, selectedPharmacy);
    if (isEditMode) {
      // temporarily creating the toast here until the redux files for toggle changes are set up
      const toast: Toast = {
        text: `The GPO/WAC setting was switched for ${selectedPharmacy.pharmacyName}.`,
        type: TOAST_TYPE.SUCCESS
      }
      createToast(toast);
      setIsModalOpen(false);
    }
  }
  const handle340BPayConfirmChanges = () => {
    update340BPayIndex(selectedCheckboxIndex, selectedPharmacy);
    if (isEditMode) {
      // temporarily creating the toast here until the redux files for checkbox changes are set up
      const toast: Toast = {
        text: `The 340B Pay election was switched for ${selectedPharmacy.pharmacyName}.`,
        type: TOAST_TYPE.SUCCESS
      }
      createToast(toast);
      setIsModalOpen(false);
    }
  }

  const onModalClose = () => {
    setIsModalOpen(false);
    setSelectedToggleIndex(0);
    setSelectedCheckboxIndex(0);
  }
  return (
    <div className="associated-pharmacies-table-container">
      <div className="data-table-wrapper">
        <Table unstackable className="data-table associated-pharmacies-table">
          <Table.Header>
            <Table.Row className="selectable-row">
              {isEditMode &&
                (isRemoveColumnVisible ? (
                  <Table.HeaderCell
                    className="eta header-cell"
                    id="remove-header-cell"
                  >
                    REMOVE
                  </Table.HeaderCell>
                ) : (
                  <Table.HeaderCell
                    className="eta header-cell"
                    id="edit-header-cell"
                  >
                      EDIT DATE
                  </Table.HeaderCell>
                ))}
              <Table.HeaderCell className="eta header-cell">
                NPI
                <SortIconSet
                  field="npi"
                  handleCellSorting={handleCellSorting}
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="eta header-cell">
                Pharmacy Type
                <SortIconSet
                  field="pharmacyType"
                  handleCellSorting={handleCellSorting}
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="eta header-cell">
                Pharmacy Name
                <SortIconSet
                  field="pharmacyName"
                  handleCellSorting={handleCellSorting}
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="eta header-cell">
                Address
                <SortIconSet
                  field="address"
                  handleCellSorting={handleCellSorting}
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="eta header-cell">
                City
                <SortIconSet
                  field="city"
                  handleCellSorting={handleCellSorting}
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="eta header-cell">
                State
                <SortIconSet
                  field="state"
                  handleCellSorting={handleCellSorting}
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="eta header-cell">
                Zip Code
                <SortIconSet
                  field="zipCode"
                  handleCellSorting={handleCellSorting}
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="eta header-cell">
                Start Date
                <SortIconSet
                  field="startDate"
                  handleCellSorting={handleCellSorting}
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="eta header-cell">
                Term Date
                <SortIconSet
                  field="termDate"
                  handleCellSorting={handleCellSorting}
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="eta header-cell">
                Start Date Override
                <SortIconSet
                  field="startDateOverride"
                  handleCellSorting={handleCellSorting}
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="eta header-cell">
                Term Date Override
                <SortIconSet
                  field="termDateOverride"
                  handleCellSorting={handleCellSorting}
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="eta header-cell">
                HIN
                <SortIconSet
                  field="hin"
                  handleCellSorting={handleCellSorting}
                />
              </Table.HeaderCell>
              <Table.HeaderCell className="eta header-cell">
                DEA
                <SortIconSet
                  field="dea"
                  handleCellSorting={handleCellSorting}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {associatedPharmacies.map((i: any, index: number) => {
              return (
                <Table.Row
                  key={index}
                  className={
                    isEditMode && !isRemoveColumnVisible
                      ? 'pharmacy-row-edit-mode'
                      : 'pharmacy-row'
                  }
                >
                  {isEditMode &&
                    (isRemoveColumnVisible ? (
                      <Table.Cell id='removeIconClick_Pendo'
                        className="remove-cell"
                        onClick={() => handleRemovePharmacyDialog(i)}
                      >
                        <X size={24} />
                      </Table.Cell>
                    ) : (
                      <Table.Cell className="edit-cell">
                        <Button
                          id='editDateIcon_Pendo'
                          className="app-button edit-cell-button"
                          type="button"
                          onClick={() => handleEditDateDialog(i)}
                        >
                            Edit
                          <Edit3 size={14} />
                        </Button>
                      </Table.Cell>
                    ))}
                  <Table.Cell>{i.npi}</Table.Cell>
                  <Table.Cell>{i.pharmacyType}</Table.Cell>
                  <Table.Cell>{i.pharmacyName}</Table.Cell>
                  <Table.Cell>{formatEmpty(i.address)}</Table.Cell>
                  <Table.Cell>{formatEmpty(i.city)}</Table.Cell>
                  <Table.Cell>{formatEmpty(i.state)}</Table.Cell>
                  <Table.Cell>{formatEmpty(i.zipCode)}</Table.Cell>
                  <Table.Cell>{formatMMDDYYYY(i.startDate)}</Table.Cell>
                  <Table.Cell>{formatMMDDYYYY(i.termDate)}</Table.Cell>
                  <Table.Cell>{formatMMDDYYYY(i.startDateOverride)}</Table.Cell>
                  <Table.Cell>{formatMMDDYYYY(i.termDateOverride)}</Table.Cell>
                  <Table.Cell>{formatEmpty(i.hin)}</Table.Cell>
                  <Table.Cell>{formatEmpty(i.dea)}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
      <div className="associated-pharmacies-action-table">
        <div className="action-table-wrapper">
          <Table unstackable className="action-table absolute-right">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="eta header-cell">
                  GPO/WAC
                  <Popup
                    inverted
                    trigger={<AlertCircle size={10} className="tooltip" />}
                    position="left center"
                    wide="very"
                    content={GPOWAC_TOOLTIP_MESSAGE}
                    size="mini"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell className="eta header-cell">
                  340B Pay
                  <Popup
                    inverted
                    trigger={<AlertCircle size={10} className="tooltip" />}
                    position="left center"
                    wide="very"
                    content={PAY340B_TOOLTIP_MESSAGE}
                    size="mini"
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {associatedPharmacies.map((i: any, index: number) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell>
                      <PharmacyToggleButton
                        toggle
                        checked={i.gpoOrWac === 'WAC'}
                        onClick={() => { handleToggleChange(i, index) }}
                        readOnly={!isEditMode}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        className={isEditMode && i.pharmacyType !== 'Contract' ? 'enabled' : 'disabled'}
                        checked={i.pay340B}
                        onClick={() => { handleCheckBoxChange(i, index) }}
                        readOnly={!isEditMode}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {isEditMode && isModalOpen && <StandardModal handleModalClose={() => false}>
            <ModalHeaderContent header={isGPOWAColumn ? GPOWAC_DIALOG_HEADER : (is340BPaycolumn ? PAY340B_DIALOG_HEADER : '')} hasCloseButton handleModalClose={onModalClose} />
            <ModalBodyContent bodyText={isGPOWAColumn ? GPOWAC_DIALOG_MESSAGE : (is340BPaycolumn ? PAY340B_DIALOG_MESSAGE : '')} />
            <ModalFooterContent cancelButtonText='No, do not change' buttonText='Yes, change' handleModalAction={() => isGPOWAColumn ? handleGPOWACConfirmChanges() : (is340BPaycolumn ? handle340BPayConfirmChanges() : '')} handleModalClose={onModalClose} />
          </StandardModal>}
          {isRemovePharmacyDialogOpen && <RemovePharmacyDialog isOpen={true} closeModal={closeRemovePharmacyDialog} onRemoveClick={handleRemoveClick} />}
          {isRemovePharmacyDialogOpen && (
            <RemovePharmacyDialog
              isOpen={true}
              closeModal={closeRemovePharmacyDialog}
              onRemoveClick={handleRemoveClick}
            />
          )}
          {isEditDateOverrideDialogOpen && (
            <EditDateOverrideModal
              startDate={selectedPharmacy.startDate}
              startDateOverride={selectedPharmacy.startDateOverride}
              termDate={selectedPharmacy.termDate}
              termDateOverride={selectedPharmacy.termDateOverride}
              isOpen={true}
              closeModal={closeEditDateOverrideOpen}
              affirmativeClick={handleYesChangeClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  removePharmacyReasons: state.PharmacyManagementState.removePharmacy.reasons.reasons
});

const mapDispatchToProps = (dispatch: any) => ({
  createToast: (toast: Toast) => dispatch(createToast(toast)),
  updateGPOWACIndex: (index: number, selectedPharmacy: PharmacyManagementDetails) => dispatch(updateGPOWACIndex(index, selectedPharmacy)),
  update340BPayIndex: (index: number, selectedPharmacy: PharmacyManagementDetails) => dispatch(update340BPayIndex(index, selectedPharmacy)),
  getRemovePharmacyReasons: () => dispatch(getRemovePharmacyReasons())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssociatedPharmaciesTable);
