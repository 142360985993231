import React, { Fragment } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { RootState } from '../../store/RootState';
import { validateInvite, acceptInvite } from '../../store/password/actionCreators';
import { AcceptInviteInput, ValidateInviteInput } from '../../store/password/types';
import ResetPasswordError from './ResetPasswordError';
import { UserModel } from '../../store/login/types';
import SignUp, { SignUpFormInput } from './SignUp';

interface AcceptInviteProps extends RouteComponentProps {
  token: string;
  tokenError: string;
  isLoading: boolean;
  acceptInviteSuccess: boolean;
  acceptInviteErrorText: string;
  user?: UserModel,
  isSignedUp: boolean,
  validateInvite: (input: ValidateInviteInput) => void;
  acceptInvite: (input: AcceptInviteInput) => void;
}

interface AcceptInviteState {
  healthSystemId?: number;
  sentAcceptInvite: boolean;
}

class AcceptInvite extends React.Component<AcceptInviteProps, AcceptInviteState> {
  constructor (props: Readonly<AcceptInviteProps>) {
    super(props);
    this.state = {
      sentAcceptInvite: false
    }
  }

  componentDidMount () {
    const token = this.props.token;
    if (token.includes('&healthSystemId=')) {
      const split = token.split('&healthSystemId=');
      const tokenString = split[0];
      const healthSystemId = split[1];
      this.setState({healthSystemId: Number(healthSystemId) });
      this.props.validateInvite({ token: tokenString, healthSystemId: Number(healthSystemId) });
    }
  }

  componentDidUpdate () {
    if (this.props.user && this.props.user.isAuthenticated && this.props.isSignedUp && !this.props.acceptInviteSuccess && !this.state.sentAcceptInvite) {
      this.setState({ sentAcceptInvite: true });
      this.props.acceptInvite({ healthSystemId: this.state.healthSystemId });
    } else if (this.state.sentAcceptInvite && this.props.acceptInviteSuccess) {
      //go to login where they will be routed into app
      this.props.history.push('/')
    }
  }

  handleSignUp = (values: SignUpFormInput) => {

    const input: AcceptInviteInput = {
      healthSystemId: this.state.healthSystemId,
      firstName: values.firstName,
      lastName: values.lastName,
      jobTitle: values.jobTitle,
      phone: values.phone,
      password: values.password
    }

    this.setState({ sentAcceptInvite: true });
    this.props.acceptInvite(input);
  }

  render () {
    const { isLoading, tokenError, acceptInviteErrorText } = this.props;

    if (tokenError || acceptInviteErrorText) {
      return (
        <ResetPasswordError message={tokenError || acceptInviteErrorText} />
      )
    }

    return (
      <Fragment>
        {isLoading && <Dimmer className="dimmer" active inverted><Loader inverted /></Dimmer>}
        {this.props.user && this.props.user.isAuthenticated && !this.props.isSignedUp &&
          <SignUp user={this.props.user} onSubmit={this.handleSignUp} />

        }
      </Fragment>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  tokenError: state.PasswordState.resetPasswordErrorText,
  isLoading: state.PasswordState.isLoading,
  acceptInviteSuccess: state.PasswordState.acceptInviteSuccess,
  acceptInviteErrorText: state.PasswordState.acceptInviteErrorText,
  user: state.PasswordState.user,
  isSignedUp: state.PasswordState.isSignedUp
});

const mapDispatchToProps = (dispatch: any) => ({
  validateInvite: (input: ValidateInviteInput) => dispatch(validateInvite(input)),
  acceptInvite: (input: AcceptInviteInput) => dispatch(acceptInvite(input)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AcceptInvite);