import React from 'react';
import cx from 'classnames';

interface DetailsHeaderProps {
  hasMarginTop?: boolean;
  header: string;
}

const DetailsHeader = (props: DetailsHeaderProps) => {
  const { hasMarginTop, header } = props;

  return (
    <div className={cx('drawer-details-header', {'margin-top': hasMarginTop})}>{header}</div>
  );
};

export default DetailsHeader;
