import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import {
  API_PAGE_SIZE,
  GET_REVERSALS,
  REVERSALS_TAB,
  UI_PAGE_SIZE,
} from './constants';
import { fetchReversalsTransactions } from '../../api/reversals/ReversalsTransactions';
import { RootState } from '../RootState';
import { getApiPageToFetch } from '../../utils/datatableHelper';
import { getActiveHealthSystemId } from '../teammanagement/selectors';
import logRocketUtil from '../../utils/logRocketUtil';
import { getReversalsFailure, getReversalsSuccess } from './actionCreators';
import { ReversalTransactionsFilters } from './types';
import { DiscountRequestResponseModel, TransactionsFilterModel } from '../transactionscommon/types';
import { addDiscountRequestAdditionalFields } from '../transactionscommon/sagas';

const getReversalsState = (state: RootState) => state.ReversalsState.reversals;
const getReversalsFilters = (state: RootState) => state.ReversalsState.filters;

const buildReversalFilters = (tab: REVERSALS_TAB, filter: TransactionsFilterModel) => {
  const filtersToSend: any = [];

  Object.keys(filter).forEach(key => {
    const val = (filter as { [key: string]: any })[key];
    if (Array.isArray(val)) {
      filtersToSend.push({ 'field': key, 'operator': 'in', 'value': val.join() })
    } else if (val) {
      if (key !== 'startDate' && key !== 'endDate') {
        if (key === 'fillStartDate' || key === 'fillEndDate') {
          const dateUTCString = new Date(val).toUTCString();
          const dateUTC = new Date(dateUTCString);
          const operator = key === 'fillStartDate' ? 'gte' : 'lte';

          filtersToSend.push({ 'field': 'fillDate', 'operator': operator, 'value': dateUTC })
        } else {
          filtersToSend.push({ 'field': key, 'operator': 'like', 'value': val })
        }
      }
    }

  })

  if (tab === REVERSALS_TAB.PENDING) {
    filtersToSend.push({ 'field': 'statusId', 'operator': 'eq', 'value': 17 });
  } else if (tab === REVERSALS_TAB.PROCESSED) {
    const startDateUTCString = new Date(filter.startDate).toUTCString();
    const startDateUTC = new Date(startDateUTCString);

    const endDateUTCString = new Date(filter.endDate).toUTCString();
    const endDateUTC = new Date(endDateUTCString);

    filtersToSend.push(
      { 'field': 'statusId', 'operator': 'in', 'value': '18,19' },
      { 'field': 'originalDiscountRequestId', 'operator': 'ne', 'value': null },
      { 'field': 'submittedDate', 'operator': 'gte', 'value': startDateUTC },
      { 'field': 'submittedDate', 'operator': 'lte', 'value': endDateUTC }
    );
  }

  const filterQueryString = filtersToSend.map((obj: any) => `Filters=${JSON.stringify(obj)}`).join('&');

  return filterQueryString;
}

export function* fetchReversalsSaga (action: any) {
  try {
    const tab = action.payload as REVERSALS_TAB;
    const { sortBy, isSortAsc, currentPage } = yield select(getReversalsState);
    const pageToFetch = getApiPageToFetch(currentPage, UI_PAGE_SIZE, API_PAGE_SIZE);
    const activeHealthSystemId: number = yield select(getActiveHealthSystemId);
    const filtersState: ReversalTransactionsFilters = yield select(getReversalsFilters);

    const reversalFilters: string = buildReversalFilters(tab, filtersState[tab]);

    const response: DiscountRequestResponseModel = yield call(fetchReversalsTransactions, pageToFetch, sortBy, isSortAsc, reversalFilters, activeHealthSystemId);
    response.discountRequests = yield call(addDiscountRequestAdditionalFields, response.discountRequests);

    yield put(getReversalsSuccess(response))
  } catch (e) {
    yield put(getReversalsFailure(logRocketUtil(e)));
  }
}

function* watchGetReversalsSaga () {
  yield takeEvery(GET_REVERSALS, fetchReversalsSaga);
}

export default function* watchAllReversals () {
  yield all([
    watchGetReversalsSaga()
  ])
}
