import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Table, Dropdown, Button, Dimmer, Loader, Message, Popup } from 'semantic-ui-react';
import { ChevronUp, ChevronDown, AlertCircle } from 'react-feather';
import { HealthSystemInfo, TeamMember, EditRoleModel, ErrorModel } from '../../store/teammanagement/types';
import { RECIPIENT_USER_ROLE_OPTIONS, RECIPIENT_TEAM_MANAGEMENT_USER_ROLES } from '../../store/teammanagement/constants';
import { updateUserRole, openAssignCEModal, openAddUserModal, openCancelInviteModal, openOnboardingInviteModal, disableUser, enableUser } from '../../store/teammanagement/actionCreators';
import { RootState } from '../../store/RootState';
import AddUserModal from './Modals/AddUserModal';
import AssignCEModal from './Modals/AssignCEModal';
import CancelInviteModal from './Modals/CancelInviteModal';
import OnboardingInviteModal from './Modals/OnboardingInviteModal';
import RestrictedByRole from './RestrictedByRole';
import { getActiveHealthSystemRole } from '../../store/teammanagement/selectors';
import { makeSelectIsAuthorizingOfficial, makeSelectIsAccountAdmin } from '../../store/onboarding/selectors';
import { ROLES, SUPPORT_EMAIL } from '../../store/applicationConstants';
import DisabledUsers from './DisabledUsers';
import EditUserModal from './Modals/EditUserModal';
import DisableUserModal from './Modals/DisableUserModal';
import { formatPhone } from '../../utils/phoneFormatHelper';
import { UserAddress } from './common/UserAddress';
import TableCellPopup from './common/TableCellPopup';


export interface UsersProps {
  healthSystem: HealthSystemInfo;
  isLoading: boolean;
  errorText: string;
  activeUser: string | null,
  activeRole: string;
  isAuthorizingOfficial: boolean,
  isAccountAdmin: boolean,
  disableUserWaiting: boolean,
  viewDisabledUsers: boolean;
  isError: ErrorModel,
  updateUserRole: (editUserModel: EditRoleModel) => void;
  openAddUserModal: () => void,
  openAssignCEModal: (userId: number, ceIds: number[]) => void,
  openCancelInviteModal: (user: TeamMember) => void,
  openOnboardingInviteModal: (role: string) => void,
  disableUser?: (data: object) => void;
  enableUser: (data: object) => void;
}

interface UsersState {
  expandedRows: number[];
  editOpen: boolean;
  userInfo: TeamMember;
  disableUserModalOpen: boolean;
}

export class Users extends React.Component<UsersProps, UsersState> {
  constructor (props: UsersProps) {
    super(props);
    this.state = {
      expandedRows: [],
      editOpen: false,
      disableUserModalOpen: false,
      userInfo: {} as TeamMember
    };
  }

  setUserRole = (data: any, userId: number) => {
    const editUserModel: EditRoleModel = {
      healthSystemId: this.props.healthSystem.healthSystemId,
      userId: userId,
      role: data.value
    }
    this.props.updateUserRole(editUserModel)
  }


  handleRowClick = (rowId: number) => {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);
    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter(id => id !== rowId)
      : currentExpandedRows.concat(rowId);
    this.setState({ expandedRows: newExpandedRows });
  }

  handleEditCesClick = (tm: TeamMember, e: React.MouseEvent) => {
    e.stopPropagation();
    this.props.openAssignCEModal(tm.id, tm.assignedCoveredEntities);
  }

  handleAddUserClick = () => {
    this.props.openAddUserModal();
  }

  handleCancelInviteClick = (tm: TeamMember) => {
    this.props.openCancelInviteModal(tm);
  }

  handleInviteAccountAdminClick = () => {
    this.props.openOnboardingInviteModal(ROLES.RECIPIENT_ACCOUNT_ADMIN);
  }

  handleInviteControllerClick = () => {
    this.props.openOnboardingInviteModal(ROLES.RECIPIENT_CONTROLLER);
  }

  openEditModal = (item: TeamMember) => {
    this.setState({ userInfo: item }, () => {
      this.setState({ editOpen: true })
    });
  };

  closeEditModal = () => {
    this.setState({ editOpen: false })
  };

  openDisableModal = () => {
    this.setState({ editOpen: false, disableUserModalOpen: true })
  };

  closeDisableModal = () => {
    this.setState({ disableUserModalOpen: false });
  };

  callDisableUser = (id: number) => {
    const data = {
      teamId: this.props.healthSystem.healthSystemId,
      userId: id
    };
    if (this.props.disableUser) {
      this.props.disableUser(data)
    }
  };

  isProtectedRole = (tm: TeamMember) => {
    return tm.id === this.props.healthSystem.authorizingOfficialId ||
      tm.id === this.props.healthSystem.accountAdminId ||
      tm.id === this.props.healthSystem.controllerId;
  }

  isRoleDropdownDisabled = (tm: TeamMember) => {
    return this.props.activeUser === tm.email ||
      this.props.activeRole !== RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN ||
      this.isProtectedRole(tm);
  }

  getRoleDropdownTooltip = (tm: TeamMember) => {
    let role = '';
    if (tm.id === this.props.healthSystem.authorizingOfficialId) {
      role = 'Authorizing Official';
    } else if (tm.id === this.props.healthSystem.accountAdminId) {
      role = 'Account Admin';
    } else if (tm.id === this.props.healthSystem.controllerId) {
      role = 'Controller';
    }

    if (role) {
      return `If you need to make changes to the ${role} role, please contact Kalderos support at ${SUPPORT_EMAIL}.`;
    }
  }

  renderItemCaret = (rowId: number) => {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    if (isRowCurrentlyExpanded) {
      return <ChevronUp size={18} />;
    } else {
      return <ChevronDown size={18} />;
    }
  }

  renderPendingUserRows = () => {
    const { healthSystem, viewDisabledUsers } = this.props;
    const { expandedRows } = this.state;
    const users: JSX.Element[] = [];
    const members: TeamMember[] = viewDisabledUsers ? healthSystem.disabledMembers : healthSystem.activeMembers;
    if (healthSystem) {
      members.forEach((tm: TeamMember, index) => {
        if (!tm.isActive) {
          users.push(
            <React.Fragment key={index}>
              <Table.Row className="tm-users tm-pending selectable-row" onClick={() => this.handleRowClick(index)}>
                <Table.Cell width={3} className="no-wrap">
                  <div className="tm-name-cell">
                    <div className="icon-container">
                      {this.renderItemCaret(index)}
                    </div>
                    Invite Pending
                  </div>
                </Table.Cell>
                <Table.Cell width={4}>
                  <TableCellPopup
                    content={tm.email}
                    id={`tm-inactive-email-${index}`}
                  />
                </Table.Cell>
                <Table.Cell width={2} textAlign="center">
                  {tm.assignedCoveredEntities.length}
                </Table.Cell>
                <Table.Cell width={3}>
                  <div className="tm-edit-cell">
                    {(tm.role !== RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN && tm.role !== RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.PAYMENT) &&
                      <RestrictedByRole allowedRoles={[RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN]}>
                        <Button type="button" className="app-button button-white edit-cell-button" onClick={(e) => this.handleEditCesClick(tm, e)}>
                          Edit CEs
                        </Button>
                      </RestrictedByRole>
                    }
                  </div>
                </Table.Cell>
                <Table.Cell width={4} className="tm-role-cell">
                  {this.isProtectedRole(tm) &&
                    <Popup
                      className="team-management-role-popup"
                      content={this.getRoleDropdownTooltip(tm)}
                      hoverable
                      inverted
                      position="top center"
                      size="tiny"
                      trigger={<AlertCircle size={16} className="tooltip" />}
                    />
                  }
                  <Dropdown
                    className="changeRole_Pendo"
                    selection
                    disabled={this.isRoleDropdownDisabled(tm)}
                    placeholder='Choose a role...'
                    value={tm.role}
                    options={RECIPIENT_USER_ROLE_OPTIONS}
                    onChange={(e: any, data: any) => this.setUserRole(data, tm.id)}
                  />
                </Table.Cell>
              </Table.Row>
              {expandedRows.includes(index) &&
                <Table.Row className="tm-sub-row inactive">
                  <Table.Cell colSpan={3} />
                  <Table.Cell>
                    <div className="sub-row-button-cell">
                      <RestrictedByRole allowedRoles={[RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN]}>
                        <Button type="button" className="app-button button-white usersCancelInvite_Pendo" onClick={() => this.handleCancelInviteClick(tm)}>
                          Cancel Invite
                        </Button>
                      </RestrictedByRole>
                    </div>
                  </Table.Cell>
                </Table.Row>
              }
            </React.Fragment>
          );
        }
      })
    }
    return users;
  }

  renderActiveUserRows = () => {
    const { healthSystem, activeUser, activeRole, viewDisabledUsers } = this.props;
    const { expandedRows } = this.state;
    const users: JSX.Element[] = [];
    const members: TeamMember[] = viewDisabledUsers ? healthSystem.disabledMembers : healthSystem.activeMembers;
    if (healthSystem) {
      members.forEach((tm: TeamMember, index) => {
        if (tm.isActive) {
          users.push(
            <React.Fragment key={index}>
              <Table.Row onClick={() => this.handleRowClick(index)} className="selectable-row tm-users">
                <Table.Cell width={3}>
                  <div className="tm-name-cell">
                    <div className="icon-container">
                      {this.renderItemCaret(index)}
                    </div>
                    <TableCellPopup
                      content={tm.firstName + ' ' + tm.lastName}
                      id={`tm-active-name-${index}`}
                    />
                  </div>
                </Table.Cell>
                <Table.Cell width={4}>
                  <TableCellPopup
                    content={tm.email}
                    id={`tm-active-email-${index}`}
                  />
                </Table.Cell>
                <Table.Cell width={2} textAlign="center">
                  {tm.assignedCoveredEntities.length}
                </Table.Cell>
                <Table.Cell width={3}>
                  <div className="tm-edit-cell">
                    {(tm.role !== RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN && tm.role !== RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.PAYMENT) &&
                      <RestrictedByRole allowedRoles={[RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN]}>
                        <Button className="app-button button-white edit-cell-button userEdit_Pendo" onClick={(e) => this.handleEditCesClick(tm, e)}>
                          Edit CEs
                        </Button>
                      </RestrictedByRole>
                    }
                  </div>
                </Table.Cell>
                <Table.Cell width={4} className="tm-role-cell">
                  {this.isProtectedRole(tm) &&
                    <Popup
                      className="team-management-role-popup"
                      content={this.getRoleDropdownTooltip(tm)}
                      hoverable
                      inverted
                      position="top center"
                      size="tiny"
                      trigger={<AlertCircle size={16} className="tooltip" />}
                    />
                  }
                  <Dropdown
                    selection
                    disabled={this.isRoleDropdownDisabled(tm)}
                    placeholder='Choose a role...'
                    options={RECIPIENT_USER_ROLE_OPTIONS}
                    value={tm.role}
                    onChange={(e: any, data: any) => this.setUserRole(data, tm.id)}
                  />
                </Table.Cell>
              </Table.Row>
              {expandedRows.includes(index) &&
                <Table.Row className="tm-sub-row" verticalAlign="top">
                  <Table.Cell width={3}>
                    <div className="header">Job Title</div>
                    <TableCellPopup
                      content={tm.jobTitle}
                      id={`tm-job-title-${index}`}
                    />
                  </Table.Cell>
                  <Table.Cell width={9}>
                    <div className="detail-group">
                      <div>
                        <div className="header">Primary Phone</div>
                        <div>{formatPhone(tm.phone)} {tm.phoneExtension && 'ext.' + tm.phoneExtension}</div>
                      </div>
                      <div className="team-management-address container">
                        <div className="header">Office Information</div>
                        {tm.address &&
                          <UserAddress
                            address={tm.address}
                            index={index}
                          />
                        }
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell width={4}>
                    <div className="sub-row-button-cell">
                      {(activeUser === tm.email || activeRole === RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN) &&
                        <Button className="app-button button-white editUserProfile_Pendo" disabled={false} onClick={() => { this.openEditModal(tm); }}>
                          Edit Information
                        </Button>
                      }
                    </div>
                  </Table.Cell>
                </Table.Row>
              }
            </React.Fragment>
          );
        }
      })
    }
    return users;
  }

  render () {
    const { isLoading, errorText, healthSystem, viewDisabledUsers } = this.props;
    return (
      <Fragment>
        <div className="team-management-page">
          {
            this.state.editOpen &&
            <EditUserModal open={this.state.editOpen} close={this.closeEditModal} activeUser={this.props.activeUser}
              userInfo={this.state.userInfo} openDisable={this.openDisableModal} userId={this.state.userInfo.userId} />
          }
          {isLoading && <Dimmer className="dimmer" active inverted><Loader inverted content='Loading' className="tm-busy-loader" /></Dimmer>}
          <div className="basic-table-wrapper tm-table-container">
            <Table unstackable className="basic-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3} className="tm-name-header">Name</Table.HeaderCell>
                  <Table.HeaderCell width={4}>Email</Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign="center">Assigned CEs</Table.HeaderCell>
                  <Table.HeaderCell width={3} />
                  <Table.HeaderCell width={4}>Role</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {!errorText && !viewDisabledUsers && this.renderPendingUserRows()}
                {!errorText && !viewDisabledUsers && this.renderActiveUserRows()}
                {viewDisabledUsers && <DisabledUsers healthSystem={healthSystem} isLoading={false} errorText={errorText} enableUser={this.props.enableUser} />}
              </Table.Body>
            </Table>
          </div>
          {!isLoading && errorText &&
            <div className="tm-page-no-data" data-testid='tm-no-data'>
              <Message>
                <Message.Header>Error Loading Users</Message.Header>
                <Message.Content>Please try refreshing the page to reload.</Message.Content>
              </Message>
            </div>
          }
          <AddUserModal />
          <AssignCEModal />
          <CancelInviteModal />
          <OnboardingInviteModal />
          {this.state.userInfo &&
            <DisableUserModal open={this.state.disableUserModalOpen} userId={this.state.userInfo.id}
              closeModal={this.closeDisableModal}
              disableUser={this.callDisableUser}
              waiting={this.props.disableUserWaiting} />}
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  healthSystem: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo,
  activeUser: state.LoginState.user.userName,
  isLoading: state.TeamManagementState.teamManagementModel.isLoading,
  errorText: state.TeamManagementState.teamManagementModel.errorText,
  disableUserWaiting: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.disableUserWaiting,
  isError: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.isError,
  activeRole: getActiveHealthSystemRole(state),
  isAuthorizingOfficial: makeSelectIsAuthorizingOfficial(state),
  isAccountAdmin: makeSelectIsAccountAdmin(state),

});

const mapDispatchToProps = (dispatch: any) => ({
  updateUserRole: (editRoleModel: EditRoleModel) => dispatch(updateUserRole(editRoleModel)),
  openAssignCEModal: (userId: number, ceIds: number[]) => dispatch(openAssignCEModal(userId, ceIds)),
  openAddUserModal: () => dispatch(openAddUserModal()),
  openCancelInviteModal: (user: TeamMember) => dispatch(openCancelInviteModal(user)),
  openOnboardingInviteModal: (role: string) => dispatch(openOnboardingInviteModal(role)),
  disableUser: (data: any) => dispatch(disableUser(data)),
  enableUser: (data: any) => dispatch(enableUser(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(Users);
