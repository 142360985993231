import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store/RootState';
import { selectUserIsNewAO, selectUserRequiresOnboarding } from '../../store/onboarding/selectors';

export interface RestrictedByOnboardingProps {
  isAuthenticated?: boolean;
  userIsNewAO: boolean;
  userRequiresOnboarding: boolean;
  children: React.ReactNode
}

export class RestrictedByOnboarding extends React.Component<RestrictedByOnboardingProps> {

  render () {
    const { isAuthenticated, userIsNewAO, userRequiresOnboarding, children } = this.props;

    if (!isAuthenticated || userIsNewAO || userRequiresOnboarding) {
      return null;
    }

    return (
      <Fragment>
        {children}
      </Fragment>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.LoginState.user.isAuthenticated,
  userIsNewAO: selectUserIsNewAO(state),
  userRequiresOnboarding: selectUserRequiresOnboarding(state)
});

export default connect(mapStateToProps)(RestrictedByOnboarding);