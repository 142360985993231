import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store/RootState';
import { NO_FILTER_RESULTS_HEADER, NO_FILTER_RESULTS_MESSAGE, NO_TRANSACTIONS_HEADER, NO_TRANSACTIONS_MESSAGE } from '../../../store/applicationConstants';
import { AdjustReasonsModel, SortingFilter } from '../../../store/appcommon/types';
import { DiscountRequestModel, TransactionsFilterModel } from '../../../store/transactionscommon/types';
import { getPagedRemittanceTransactions } from '../../../store/remittance/selectors';
import { API_PAGE_SIZE, REMITTANCE_DETAILS_TAB, UI_PAGE_SIZE } from '../../../store/remittance/constants';
import { getRemittanceTransactions, resetRemittanceTransactions, updateRemittanceTransactionsFilter, updateRemittanceTransactionsPage, updateRemittanceTransactionsSort } from '../../../store/remittance/actionCreators';
import EmptyTransactions from '../../common/transactions/EmptyTransactions';
import RemittanceTransactionsTableHeader from './RemittanceTransactionsTableHeader';
import RemittanceTransactionsTable from './RemittanceTransactionsTable';
import RemittanceTransactionsDrawer from './RemittanceTransactionsDrawer';
import CorrectRequestForm from '../../requestcenter/RequestForm/CorrectRequestForm';
import ReversalModal from '../../common/transactions/ReversalModal';
import { canUseCachedRecords } from '../../../utils/datatableHelper';
import { getActiveHealthSystemRole, hasVerifiedFundingSource } from '../../../store/teammanagement/selectors';
import { RECIPIENT_TEAM_MANAGEMENT_USER_ROLES } from '../../../store/teammanagement/constants';
import { isAdminAllAccess } from '../../../utils/roleUtil';
import { reverseTransactions } from '../../../store/transactionscommon/actionCreators';

const MODIFIER_ROLES: string[] = [RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN, RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.SUBMITTER];

export interface RemittanceTransactionsProps {
  tab: REMITTANCE_DETAILS_TAB,
  transactions: DiscountRequestModel[],
  currentApiPage: number,
  currentPage: number,
  totalCount: number,
  isLoading: boolean,
  reversalSuccess: boolean;
  filters: TransactionsFilterModel,
  adjustReasons: AdjustReasonsModel;
  activeHealthSystemRole: string;
  hasVerifiedFundingSource: boolean;
  userRoles: string[];
  getRemittanceTransactions: (tab: REMITTANCE_DETAILS_TAB) => void;
  updateRemittanceTransactionsPage: (pageNumber: number) => void;
  updateRemittanceTransactionsSort: (sort: SortingFilter) => void;
  updateRemittanceTransactionsFilter: (filters: TransactionsFilterModel) => void;
  resetRemittanceTransactions: () => void;
  reverseTransactions: (transactionIds: number[]) => void;
}

export interface RemittanceTransactionsState {
  activeTransaction?: DiscountRequestModel,
  selectedTransactions: DiscountRequestModel[],
  isDrawerOpen: boolean;
  isReversalModalOpen: boolean;
  isCorrectFormOpen: boolean;
  isDrawerReversal: boolean;
}

const initialState: RemittanceTransactionsState = {
  activeTransaction: undefined,
  selectedTransactions: [],
  isDrawerOpen: false,
  isReversalModalOpen: false,
  isCorrectFormOpen: false,
  isDrawerReversal: false
}

export class RemittanceTransactions extends React.Component<RemittanceTransactionsProps, RemittanceTransactionsState> {
  constructor (props: Readonly<RemittanceTransactionsProps>) {
    super(props)
    this.state = initialState;
  }

  // It seems switching tabs doesn't mount/unmount just updates props
  componentDidUpdate (prevProps: RemittanceTransactionsProps) {
    if ((this.props.tab !== prevProps.tab) || (this.props.reversalSuccess && !prevProps.reversalSuccess)) {
      this.setState({ ...initialState });
      this.props.resetRemittanceTransactions();
      this.props.getRemittanceTransactions(this.props.tab);
    }
  }

  handleRowClick = (transactionId: number) => {
    const transaction = this.props.transactions.find((d) => d.id === transactionId);
    if (transaction) {
      this.setState({ activeTransaction: transaction, isDrawerOpen: true });
    }
  }

  handlePageChange = (page: number) => {
    this.props.updateRemittanceTransactionsPage(page);
    if (!canUseCachedRecords(page, this.props.currentApiPage, UI_PAGE_SIZE, API_PAGE_SIZE)) {
      this.props.getRemittanceTransactions(this.props.tab)
    }
  }

  handleSort = (sort: SortingFilter) => {
    this.setState({ selectedTransactions: [], activeTransaction: undefined });
    this.props.updateRemittanceTransactionsSort(sort);
    this.props.getRemittanceTransactions(this.props.tab);
  }

  handleApplyFilter = (filter: TransactionsFilterModel) => {
    this.setState({ selectedTransactions: [], activeTransaction: undefined });
    this.props.updateRemittanceTransactionsFilter(filter);
    this.props.getRemittanceTransactions(this.props.tab);
  }

  handleDrawerNext = () => {
    const index = this.props.transactions.indexOf(this.state.activeTransaction!);
    if (index !== -1 && index + 1 < this.props.transactions.length) {
      this.setState({ activeTransaction: this.props.transactions[index + 1] });
    }
  }

  handleDrawerPrevious = () => {
    const index = this.props.transactions.indexOf(this.state.activeTransaction!);
    if (index > 0) {
      this.setState({ activeTransaction: this.props.transactions[index - 1] });
    }
  }

  handleDrawerClose = () => {
    this.setState({ isDrawerOpen: false });
  }

  handleDrawerCorrect = () => {
    this.setState({ isCorrectFormOpen: true, isDrawerOpen: false });
  }

  handleDrawerReverse = () => {
    this.setState({ isReversalModalOpen: true, isDrawerReversal: true })
  }

  handleReverseSelection = () => {
    this.setState({ isReversalModalOpen: true, isDrawerReversal: false })
  }

  handleReverseModalSubmit = (transactionIds: number[]) => {
    this.props.reverseTransactions(transactionIds);
    this.setState({ isReversalModalOpen: false, isDrawerReversal: false, isDrawerOpen: false });
  }

  handleReverseModalClose = () => {
    this.setState({ isReversalModalOpen: false, isDrawerReversal: false });
  }

  handleCorrectSubmissionClose = (isSuccess: boolean) => {
    this.setState({ isCorrectFormOpen: false });
    if (isSuccess) {
      this.props.getRemittanceTransactions(this.props.tab);
    }
  }

  handleCheckboxClick = (transactionId: number, isChecked: boolean) => {
    const transaction = this.props.transactions.find(t => t.id === transactionId);
    if (transaction) {
      if (isChecked && !this.state.selectedTransactions.includes(transaction)) {
        this.setState({ selectedTransactions: [...this.state.selectedTransactions, transaction] })
      }
      if (!isChecked && this.state.selectedTransactions.includes(transaction)) {
        this.setState({ selectedTransactions: this.state.selectedTransactions.filter(t => t.id !== transactionId) });
      }
    }
  }

  handleCheckboxAllClick = (isChecked: boolean) => {
    if (isChecked) {
      this.setState({ selectedTransactions: [...this.props.transactions] })
    } else {
      this.setState({ selectedTransactions: [] })
    }
  }

  handleClearSelection = () => {
    this.setState({ selectedTransactions: [] })
  }

  render () {
    const { tab, transactions, filters, isLoading, currentPage, totalCount, adjustReasons, activeHealthSystemRole, hasVerifiedFundingSource, userRoles } = this.props;
    const { isDrawerOpen, isReversalModalOpen, isDrawerReversal, isCorrectFormOpen, activeTransaction, selectedTransactions } = this.state;

    const isReadOnly = !MODIFIER_ROLES.includes(activeHealthSystemRole);

    const areFiltersApplied = Object.values(this.props.filters).some(filter => (filter !== null && filter !== ''));

    const index = activeTransaction ? transactions.indexOf(activeTransaction) : -1;
    const nextEnabled = index !== -1 && index + 1 < transactions.length;
    const prevEnabled = index > 0;

    return (
      <div className="remittance-details-tab">

        {/* Dimmer/Loader handled by parent page */}

        <RemittanceTransactionsTableHeader
          tab={tab}
          filter={filters}
          currentPage={currentPage}
          totalCount={totalCount}
          selectedTransactions={selectedTransactions}
          isLoading={isLoading}
          adjustReasons={adjustReasons}
          hasVerifiedFundingSource={hasVerifiedFundingSource}
          handleApplyFilter={this.handleApplyFilter}
          handlePageChange={this.handlePageChange}
          handleReverseSelection={this.handleReverseSelection}
          handleClearSelection={this.handleClearSelection}
        />

        <RemittanceTransactionsTable
          tab={tab}
          transactions={transactions}
          activeTransactionId={activeTransaction?.id}
          selectedTransactionIds={selectedTransactions.map(t => t.id)}
          isReadOnly={isReadOnly}
          isAdminAllAccess={isAdminAllAccess(userRoles)}
          handleCellSorting={this.handleSort}
          handleRowClick={this.handleRowClick}
          handleCheckboxClick={this.handleCheckboxClick}
          handleCheckboxAllClick={this.handleCheckboxAllClick}
        />

        {isDrawerOpen &&
          <RemittanceTransactionsDrawer
            selectedTransaction={activeTransaction!}
            nextEnabled={nextEnabled}
            prevEnabled={prevEnabled}
            hasVerifiedFundingSource={hasVerifiedFundingSource}
            handleClose={this.handleDrawerClose}
            handleNext={this.handleDrawerNext}
            handlePrevious={this.handleDrawerPrevious}
            handleCorrect={this.handleDrawerCorrect}
            handleReverse={this.handleDrawerReverse}
          />
        }

        {isCorrectFormOpen &&
          <CorrectRequestForm
            transaction={activeTransaction!}
            onClose={this.handleCorrectSubmissionClose}
          />
        }

        {isReversalModalOpen &&
          <ReversalModal
            transactions={isDrawerReversal ? [activeTransaction!] : selectedTransactions}
            hasVerifiedFundingSource={hasVerifiedFundingSource}
            onReverseClick={this.handleReverseModalSubmit}
            onCloseClick={this.handleReverseModalClose}
          />
        }
        {!isLoading && transactions.length === 0 &&
          <EmptyTransactions
            emptyTransactionsHeader={areFiltersApplied ? NO_FILTER_RESULTS_HEADER : NO_TRANSACTIONS_HEADER}
            emptyTransactionsMessage={areFiltersApplied ? NO_FILTER_RESULTS_MESSAGE : NO_TRANSACTIONS_MESSAGE}
          />
        }

      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  transactions: getPagedRemittanceTransactions(state),
  currentApiPage: state.RemittanceState.transactions.currentApiPage,
  currentPage: state.RemittanceState.transactions.currentPage,
  totalCount: state.RemittanceState.transactions.totalCount,
  isLoading: state.RemittanceState.transactions.isLoading || state.TransactionsCommon.reversalsModal.isLoading,
  reversalSuccess: state.TransactionsCommon.reversalsModal.isSuccess,
  filters: state.RemittanceState.transactions.filter,
  adjustReasons: state.AppCommonState.adjustReasons,
  activeHealthSystemRole: getActiveHealthSystemRole(state),
  hasVerifiedFundingSource: hasVerifiedFundingSource(state),
  userRoles: state.LoginState.user.roles || [],
})

const mapDispatchToProps = (dispatch: any) => ({
  getRemittanceTransactions: (tab: REMITTANCE_DETAILS_TAB) => dispatch(getRemittanceTransactions(tab)),
  updateRemittanceTransactionsPage: (pageNumber: number) => dispatch(updateRemittanceTransactionsPage(pageNumber)),
  updateRemittanceTransactionsSort: (sort: SortingFilter) => dispatch(updateRemittanceTransactionsSort(sort)),
  updateRemittanceTransactionsFilter: (filters: TransactionsFilterModel) => dispatch(updateRemittanceTransactionsFilter(filters)),
  resetRemittanceTransactions: () => dispatch(resetRemittanceTransactions()),
  reverseTransactions: (transactionIds: number[]) => dispatch(reverseTransactions(transactionIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemittanceTransactions);