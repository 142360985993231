import React, { useState } from 'react';
import cx from 'classnames';
import { Icon } from 'semantic-ui-react';

import '../../themes/kalderos/Common/PasswordValidation.scss'

interface PasswordValidationProps {
  password: string;
  onValidation: (isValid: boolean) => void;
}

const PasswordValidation: React.FunctionComponent<PasswordValidationProps> = ({ password, onValidation }: PasswordValidationProps) => {

  const [validLength, setValidLength] = useState<boolean>(false);
  const [validCase, setValidCase] = useState<boolean>(false);
  const [validChar, setValidChar] = useState<boolean>(false);
  const [validNumber, setValidNumber] = useState<boolean>(false);

  React.useEffect(() => {
    validatePasswordRequirements(password)
  }, [password]);

  React.useEffect(() => {
    onValidation(validLength && validCase && validChar && validNumber);
  }, [onValidation, validLength, validCase, validChar, validNumber]);

  const validatePasswordRequirements = (password: string) => {
    setValidLength(password.length >= 12);
    setValidCase(/(?=.*[a-z])(?=.*[A-Z])/.test(password));
    setValidChar(/[!@#$%^&*(),.?":{}|<>]/.test(password));
    setValidNumber(/\d/.test(password));
  }

  return (
    <div className="password-validation-container">
      <div className="password-validation"><Icon className={cx({'green-icon': validLength})} name={validLength ? 'check circle outline' :'circle outline'} /><div>At least 12 characters long</div></div>
      <div className="password-validation"><Icon className={cx({'green-icon': validCase})}   name={validCase ? 'check circle outline' :'circle outline'} /><div>Contains at least one uppercase and lower case letter</div></div>
      <div className="password-validation"><Icon className={cx({'green-icon': validChar})}   name={validChar ? 'check circle outline' :'circle outline'} /><div>Contains at least one special character (e.g. !@#$%&amp;)</div></div>
      <div className="password-validation"><Icon className={cx({'green-icon': validNumber})} name={validNumber ? 'check circle outline' :'circle outline'} /><div>Contains at least one number</div></div>
    </div>
  )
}

export default PasswordValidation
