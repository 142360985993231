import {
  PasswordTypes,
  VALIDATE_RESET_PASSWORD_TOKEN,
  VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  RESET_CHANGE_PASSWORD_STATUS,
  SEND_FORGOT_PASSWORD,
  SEND_FORGOT_PASSWORD_SUCCESS,
  SEND_FORGOT_PASSWORD_ERROR,
  ACCEPT_INVITE,
  ACCEPT_INVITE_SUCCESS,
  ACCEPT_INVITE_FAILURE,
  RESET_FORGOT_PASSWORD,
  VALIDATE_INVITE,
  VALIDATE_INVITE_SUCCESS,
  VALIDATE_INVITE_FAILURE,
} from './constants';
import { PasswordState } from './types'

const initalState: PasswordState = {
  token: '',
  resetPasswordErrorText: '',
  resetPasswordSuccess: false,
  changePasswordErrorText: '',
  changePasswordSuccess: false,
  isLoading: false,
  acceptInviteInput: {
    healthSystemId: -1,
    firstName: '',
    lastName: '',
    phone: '',
    jobTitle: '',
    password: '',
  },
  acceptInviteSuccess: false,
  acceptInviteErrorText: '',
  forgotPasswordSuccess: false,
  forgotPasswordErrorText: '',
  isSignedUp: false,
}

export function passwordReducer (
  state = initalState,
  action: PasswordTypes
): any {
  switch (action.type) {
    case VALIDATE_RESET_PASSWORD_TOKEN:
      return {
        ...state,
        isLoading: true
      }
    case VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLoading: false,
      }
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        resetPasswordErrorText: action.payload.message
      }
    case RESET_PASSWORD:
      return {
        ...state,
        resetPasswordErrorText: '',
        resetPasswordSuccess: false,
        isLoading: true,
      }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: true,
        isLoading: false,
      }
    case CHANGE_PASSWORD:
      return {
        ...state,
        isLoading: true,
      }
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordErrorText: action.payload.message,
        isLoading: false,
      }
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordSuccess: true,
        isLoading: false,
      }
    case RESET_CHANGE_PASSWORD_STATUS:
      return {
        ...state,
        changePasswordErrorText: '',
        changePasswordSuccess: false
      }
    case SEND_FORGOT_PASSWORD:
      return {
        ...state,
        isLoading: true,
        forgotPasswordSuccess: false,
        forgotPasswordErrorText: '',

      }
    case SEND_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        forgotPasswordSuccess: true,
      }
    case SEND_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        forgotPasswordErrorText: action.payload.message,
      }
    case RESET_FORGOT_PASSWORD:
      return {
        ...state,
        isLoading: false,
        forgotPasswordSuccess: false,
        forgotPasswordErrorText: '',
      }
    case VALIDATE_INVITE:
      return {
        ...state,
        isLoading: true,
        isSignedUp: false,
        resetPasswordErrorText: '',
      }
    case VALIDATE_INVITE_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        isSignedUp: action.payload.isSignedUp,
        isLoading: false,
      }
    case VALIDATE_INVITE_FAILURE:
      return {
        ...state,
        resetPasswordErrorText: action.payload.message,
        isLoading: false,
      }
    case ACCEPT_INVITE:
      return {
        ...state,
        isLoading: true,
        acceptInviteInput: action.payload,
        acceptInviteSuccess: false,
        acceptInviteErrorText: '',
      }
    case ACCEPT_INVITE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        acceptInviteSuccess: true,
      }
    case ACCEPT_INVITE_FAILURE:
      return {
        ...state,
        isLoading: false,
        acceptInviteErrorText: action.payload.message,
      }
    default:
      return state
  }

}
