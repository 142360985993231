import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../../store/RootState';
import { AuthorizingOfficialInfoModel, CandidateHealthSystem, CandidateCoveredEntity } from '../../../../store/onboarding/types';
import { getCandidateCeList, completeTermsAndConditionsStep } from '../../../../store/onboarding/actionCreators';
import AssociateHealthSystems from './AssociateHealthSystems';
import AssociateCoveredEntities from './AssociateCoveredEntities';
import TermsAndConditions from './TermsAndConditions';

import '../../../../themes/kalderos/Onboarding/Recipient/AssociateCoveredEntities.scss';
import Layout from '../../Layout';
import { getCandidateHealthSystems, getOrphanedCes } from '../../../../store/onboarding/selectors';
import { ROLES } from '../../../../store/applicationConstants';
import { updateSelectedHealthSystemOnboardingRoles } from '../../../../store/teammanagement/actionCreators';

interface AssociateCesAndHealthSystemsProps {
  info: AuthorizingOfficialInfoModel,
  isLoading: boolean,
  errorText: string,
  candidateHealthSystems: CandidateHealthSystem[],
  orphanedCes: CandidateCoveredEntity[],
  selected: CandidateHealthSystem,
  getCandidateCeList: () => void,
  completeTermsAndConditionsStep: () => void,
  updateSelectedHealthSystemOnboardingRoles: (roles: string[]) => void
}

interface AssociateCesAndHealthSystemsState {
  selectedOption: number;
  currentStep: 'loading' | 'associate-ce' | 'associate-hs' | 'terms-and-conditions';
  isInitialLoadDone: boolean;
}

export class AssociateCesAndHealthSystems extends React.Component<AssociateCesAndHealthSystemsProps, AssociateCesAndHealthSystemsState>{
  constructor (props: Readonly<AssociateCesAndHealthSystemsProps>) {
    super(props);
    this.state = {
      selectedOption: 0,
      currentStep: 'loading',
      isInitialLoadDone: false,
    };
  }

  componentDidMount () {
    //make sure AO's other potential roles from a previous setup do not get used. 
    this.props.updateSelectedHealthSystemOnboardingRoles([ROLES.RECIPIENT_AUTHORIZING_OFFICIAL]);
    this.props.getCandidateCeList();
  }

  onConfirm = () => {
    this.props.completeTermsAndConditionsStep();
  }

  componentDidUpdate (prevProps: AssociateCesAndHealthSystemsProps) {
    if (prevProps !== this.props) {
      const { isInitialLoadDone } = this.state;
      const { isLoading, candidateHealthSystems, orphanedCes, selected } = this.props;

      if (!isLoading && !isInitialLoadDone) {

        if (candidateHealthSystems && candidateHealthSystems.length === 0
          && orphanedCes && orphanedCes.length > 0
          && selected.orphanCeList && selected.orphanCeList.length === 0) {
          this.setState({ currentStep: 'associate-ce', isInitialLoadDone: true });
        } else {
          this.setState({ currentStep: 'associate-hs', isInitialLoadDone: true });
        }
      }

    }
  }

  goToCeStep = () => {
    this.setState({ currentStep: 'associate-ce' })
  }

  goToHsStep = () => {
    this.setState({ currentStep: 'associate-hs' })
  }

  goToTermsAndConditionsStep = () => {
    this.setState({ currentStep: 'terms-and-conditions' })
  }

  render () {
    return (
      <Fragment>
        {this.state.currentStep === 'loading' &&
          <Layout
            stepNumber={1}
            isLoading={this.props.isLoading}
          >
            <div className="onboarding-layout-header">
              <div className="title">1. Associate Covered Entities &amp; Health Systems</div>
            </div>
          </Layout>}
        {this.state.currentStep === 'associate-hs' &&
          <AssociateHealthSystems onNext={this.goToCeStep} />
        }
        {this.state.currentStep === 'associate-ce' &&
          <AssociateCoveredEntities onBack={this.goToHsStep} onNext={this.goToTermsAndConditionsStep} />
        }
        {this.state.currentStep === 'terms-and-conditions' &&
          <TermsAndConditions onBack={this.goToCeStep} onConfirm={this.onConfirm} />}
      </Fragment>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  info: state.OnboardingState.authorizingOfficialInfoStepModel.info,
  isLoading: state.OnboardingState.isLoading,
  errorText: state.OnboardingState.errorText,
  candidateHealthSystems: getCandidateHealthSystems(state),
  orphanedCes: getOrphanedCes(state),
  selected: state.OnboardingState.associateCesAndHsStepModel.selectedHealthSystem
});

const mapDispatchToProps = (dispatch: any) => ({
  getCandidateCeList: () => dispatch(getCandidateCeList()),
  completeTermsAndConditionsStep: () => dispatch(completeTermsAndConditionsStep()),
  updateSelectedHealthSystemOnboardingRoles: (roles: string[]) => dispatch(updateSelectedHealthSystemOnboardingRoles(roles))

});

export default connect(mapStateToProps, mapDispatchToProps)(AssociateCesAndHealthSystems);
