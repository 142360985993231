import {
  RecipientOnboardingActionTypes,
  RESET_ONBOARDING_STATUS,
  CHECK_ONBOARDING_STATUS,
  CHECK_ONBOARDING_STATUS_SUCCESS,
  CHECK_ONBOARDING_STATUS_FAILED,
  COMPLETE_TERMS_AND_CONDITIONS_STEP,
  COMPLETE_TERMS_AND_CONDITIONS_STEP_SUCCESS,
  COMPLETE_INVITE_ACCOUNT_ADMIN_STEP,
  COMPLETE_INVITE_ACCOUNT_ADMIN_STEP_SUCCESS,
  COMPLETE_ACCOUNT_ADMIN_INFO_STEP,
  COMPLETE_ACCOUNT_ADMIN_INFO_STEP_SUCCESS,
  GET_COVERED_ENTITY_INFO,
  GET_COVERED_ENTITY_INFO_SUCCESS,
  GET_COVERED_ENTITY_INFO_FAILURE,
  COMPLETE_ACCOUNT_ADMIN_CONFIRM_INFO_STEP,
  COMPLETE_ACCOUNT_ADMIN_CONFIRM_INFO_STEP_SUCCESS,
  COMPLETE_INVITE_CONTROLLER_STEP,
  COMPLETE_INVITE_CONTROLLER_STEP_SUCCESS,
  COMPLETE_CONTROLLER_INFO_STEP,
  COMPLETE_CONTROLLER_INFO_STEP_SUCCESS,
  COMPLETE_CONTROLLER_CONFIRM_INFO_STEP,
  COMPLETE_CONTROLLER_CONFIRM_INFO_STEP_SUCCESS,
  COMPLETE_CONTROLLER_BANK_INFO_STEP,
  COMPLETE_CONTROLLER_BANK_INFO_STEP_SUCCESS,
  COMPLETE_RECIPIENT_STEP_FAILURE,
  GET_TERMS_AND_CONDITIONS,
  GET_TERMS_AND_CONDITIONS_SUCCESS,
  GET_TERMS_AND_CONDITIONS_FAILURE,
  GET_ACCOUNT_ADMIN_SUMMARY,
  GET_ACCOUNT_ADMIN_SUMMARY_SUCCESS,
  GET_ACCOUNT_ADMIN_SUMMARY_FAILURE,
  SEND_AUTHORIZING_OFFICIAL_SIGN_UP,
  SEND_AUTHORIZING_OFFICIAL_SIGN_UP_SUCCESS,
  SEND_AUTHORIZING_OFFICIAL_SIGN_UP_ERROR,
  RESET_AUTHORIZING_OFFICIAL_SIGN_UP,
  GET_CANDIDATE_CE_LIST,
  GET_CANDIDATE_CE_LIST_SUCCESS,
  GET_CANDIDATE_CE_LIST_FAILURE,
  SET_SELECTED_HEALTH_SYSTEM,
  SET_SELECTED_ORPHAN_CES,
  SET_HEALTH_SYSTEM_NAME,
  CHECK_UNIQUE_HEALTH_SYSTEM_NAME,
  CHECK_UNIQUE_HEALTH_SYSTEM_NAME_SUCCESS,
  CHECK_UNIQUE_HEALTH_SYSTEM_NAME_FAILURE,
  GET_HEALTH_SYSTEM_ACCOUNT_ADMIN,
  GET_HEALTH_SYSTEM_ACCOUNT_ADMIN_SUCCESS,
  GET_HEALTH_SYSTEM_ACCOUNT_ADMIN_FAILURE,
  RESET_ONBOARDING_STATE,
  LOAD_DATA_ERROR_MESSAGE,
  GET_CONTROLLER_STATUS,
  GET_CONTROLLER_STATUS_SUCCESS,
  GET_CONTROLLER_STATUS_FAILURE,
  UPDATE_ONBOARDING_STEP,
  UPDATE_ONBOARDING_DISMISSED_WELCOME,
  COMPLETE_ONBOARDING,
} from './constants';
import { OnboardingState } from './types';

const initialState: OnboardingState = {
  onboardingStatusModel: {
    onboardingCompleted: false,
    currentStep: 1,
    dismissedWelcome: false,
    isError: false,
    isLoading: false,
    isLoaded: false,
  },
  authorizingOfficialInfoStepModel: {
    info: {
      firstName: '',
      lastName: '',
      jobTitle: '',
      email: '',
      idCode: '',
      entityName: '',
    },
  },
  termsAndConditionsStepModel: {
    termsText: '',
  },
  inviteAccountAdminStepModel: {
    input: {
      firstName: '',
      lastName: '',
      email: '',
      addRoleToCurrentUser: false,
    },
    isEmailSent: false,
    hasAccountAdmin: false,
  },
  authorizingOfficialHealthSystemsModel: {
    healthSystems: [],
  },
  accountAdminInfoStepModel: {
    adminInfo: {
      jobTitle: '',
      phone: '',
      phoneExtension: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      },
    },
  },
  coveredEntityInfoStepModel: {
    coveredEntities: [],
  },
  accountAdminConfirmInfoStepModel: {
    associatedEntities: [],
  },
  inviteControllerStepModel: {
    input: {
      firstName: '',
      lastName: '',
      email: '',
      addRoleToCurrentUser: false,
    },
    isEmailSent: false,
  },
  controllerInfoStepModel: {
    controllerInfo: {
      jobTitle: '',
      phone: '',
      phoneExtension: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      },
    },
  },
  authorizingOfficialSignUpStepModel: {
    authorizingOfficialSignUp: {
      authorizingOfficialSignUpSuccess: false,
      authorizingOfficialSignUpErrorText: ''
    }
  },
  associateCesAndHsStepModel: {
    aoInfo: {
      aoFirstName: '',
      aoLastName: '',
    },
    candidateCeListModel: {
      candidateHealthSystems: [],
      orphanedCes: []
    },
    selectedHealthSystem: {
      ceList: [],
      orphanCeList: [],
      healthSystemName: '',
      isExistingHealthSystem: false,
      onboardState: 0,
    },
    checkUniqueHealthSystemName: {
      name: '',
      unique: false,
      isLoading: false,
      isLoaded: false,
    },
    isLoading: false,
  },
  controllerStatusModel: {
    invited: false,
    completed: false
  },
  isLoading: false,
  errorText: '',
}

export function onboardingReducer (
  state = initialState,
  action: RecipientOnboardingActionTypes
): OnboardingState {
  switch (action.type) {
    case RESET_ONBOARDING_STATE: {
      return {
        ...initialState
      }
    }
    case RESET_ONBOARDING_STATUS:
      return {
        ...state,
        onboardingStatusModel: {
          ...initialState.onboardingStatusModel
        }
      }
    case CHECK_ONBOARDING_STATUS:
      return {
        ...state,
        onboardingStatusModel: {
          ...state.onboardingStatusModel,
          isLoading: true
        }
      }
    case CHECK_ONBOARDING_STATUS_SUCCESS:
      return {
        ...state,
        onboardingStatusModel: {
          ...state.onboardingStatusModel,
          onboardingCompleted: action.payload.onboardingCompleted,
          currentStep: action.payload.onboardingStatusValue,
          isLoading: false,
          isError: false,
          isLoaded: true,
        }
      }
    case CHECK_ONBOARDING_STATUS_FAILED:
      return {
        ...state,
        onboardingStatusModel: {
          ...state.onboardingStatusModel,
          isLoading: false,
          isError: true,
          isLoaded: true,
        },
      }
    case UPDATE_ONBOARDING_STEP:
      return {
        ...state,
        onboardingStatusModel: {
          ...state.onboardingStatusModel,
          currentStep: action.payload,
        }
      }
    case UPDATE_ONBOARDING_DISMISSED_WELCOME:
      return {
        ...state,
        onboardingStatusModel: {
          ...state.onboardingStatusModel,
          dismissedWelcome: action.payload
        }
      }
    case GET_TERMS_AND_CONDITIONS:
      return {
        ...state,
        errorText: '',
        isLoading: true,
      }
    case GET_TERMS_AND_CONDITIONS_SUCCESS:
      return {
        ...state,
        termsAndConditionsStepModel: {
          ...state.termsAndConditionsStepModel,
          termsText: action.payload,
        },
        isLoading: false
      }
    case COMPLETE_TERMS_AND_CONDITIONS_STEP_SUCCESS:
      return {
        ...state,
        termsAndConditionsStepModel: {
          ...state.termsAndConditionsStepModel,
          confirmation: action.payload,
        },
        isLoading: false
      }
    case COMPLETE_INVITE_ACCOUNT_ADMIN_STEP:
      return {
        ...state,
        inviteAccountAdminStepModel: {
          ...state.inviteAccountAdminStepModel,
          input: {
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            email: action.payload.email,
            addRoleToCurrentUser: action.payload.addRoleToCurrentUser,
            removeRoleFromCurrentUser: action.payload.removeRoleFromCurrentUser
          },
        },
        isLoading: true,
        errorText: ''
      }
    case COMPLETE_INVITE_ACCOUNT_ADMIN_STEP_SUCCESS:
      return {
        ...state,
        inviteAccountAdminStepModel: {
          ...state.inviteAccountAdminStepModel,
          isEmailSent: !state.inviteAccountAdminStepModel.input.addRoleToCurrentUser,
        },
        isLoading: false,
      }
    case COMPLETE_ACCOUNT_ADMIN_INFO_STEP:
      return {
        ...state,
        accountAdminInfoStepModel: {
          ...state.accountAdminInfoStepModel,
          adminInfo: action.payload,
        },
        isLoading: true,
        errorText: ''
      }
    case COMPLETE_ACCOUNT_ADMIN_INFO_STEP_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case GET_COVERED_ENTITY_INFO_SUCCESS:
      return {
        ...state,
        coveredEntityInfoStepModel: {
          coveredEntities: action.payload
        },
        isLoading: false,
      }
    case GET_ACCOUNT_ADMIN_SUMMARY_SUCCESS:
      return {
        ...state,
        accountAdminConfirmInfoStepModel: {
          ...state.accountAdminConfirmInfoStepModel,
          accountAdminInfo: action.payload.accountAdminInfo,
          associatedEntities: action.payload.associatedEntities
        },
        isLoading: false,
      }
    case COMPLETE_ACCOUNT_ADMIN_CONFIRM_INFO_STEP_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case COMPLETE_INVITE_CONTROLLER_STEP:
      return {
        ...state,
        inviteControllerStepModel: {
          ...state.inviteControllerStepModel,
          input: {
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            email: action.payload.email,
            addRoleToCurrentUser: action.payload.addRoleToCurrentUser,
            removeRoleFromCurrentUser: action.payload.removeRoleFromCurrentUser,
          },
        },
        isLoading: true,
        errorText: ''
      }
    case COMPLETE_INVITE_CONTROLLER_STEP_SUCCESS:
      return {
        ...state,
        inviteControllerStepModel: {
          ...state.inviteControllerStepModel,
          isEmailSent: !state.inviteControllerStepModel.input.addRoleToCurrentUser,
        },
        isLoading: false,
      }
    case COMPLETE_CONTROLLER_INFO_STEP:
      return {
        ...state,
        controllerInfoStepModel: {
          ...state.controllerInfoStepModel,
          controllerInfo: action.payload,
        },
        isLoading: true,
        errorText: ''
      }
    case COMPLETE_CONTROLLER_INFO_STEP_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case COMPLETE_CONTROLLER_CONFIRM_INFO_STEP_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case COMPLETE_CONTROLLER_BANK_INFO_STEP:
    case COMPLETE_CONTROLLER_BANK_INFO_STEP_SUCCESS:
      return {
        ...state,
      }
    case GET_CANDIDATE_CE_LIST: 
      return {
        ...state,
        isLoading: true,
        errorText: '',
        associateCesAndHsStepModel: {
          ...state.associateCesAndHsStepModel,
          isLoading: true
        }
      }
    case GET_CANDIDATE_CE_LIST_FAILURE: 
      return {
        ...state,
        isLoading: false,
        errorText: LOAD_DATA_ERROR_MESSAGE,
        associateCesAndHsStepModel: {
          ...state.associateCesAndHsStepModel,
          isLoading: false
        }
      }
    case COMPLETE_TERMS_AND_CONDITIONS_STEP:
    case GET_COVERED_ENTITY_INFO:
    case GET_ACCOUNT_ADMIN_SUMMARY:
    case COMPLETE_ACCOUNT_ADMIN_CONFIRM_INFO_STEP:
    case COMPLETE_CONTROLLER_CONFIRM_INFO_STEP:
    case GET_HEALTH_SYSTEM_ACCOUNT_ADMIN:
      return {
        ...state,
        isLoading: true,
        errorText: ''
      }
    case GET_TERMS_AND_CONDITIONS_FAILURE:
    case GET_COVERED_ENTITY_INFO_FAILURE:
    case GET_ACCOUNT_ADMIN_SUMMARY_FAILURE:
    case GET_HEALTH_SYSTEM_ACCOUNT_ADMIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorText: LOAD_DATA_ERROR_MESSAGE
      }
    case COMPLETE_RECIPIENT_STEP_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorText: action.payload.message
      }
    case SEND_AUTHORIZING_OFFICIAL_SIGN_UP:
      return {
        ...state,
        isLoading: true,
        authorizingOfficialSignUpStepModel: {
          authorizingOfficialSignUp: {
            authorizingOfficialSignUpSuccess: false,
            authorizingOfficialSignUpErrorText: ''
          }
        },
      }
    case SEND_AUTHORIZING_OFFICIAL_SIGN_UP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        authorizingOfficialSignUpStepModel: {
          authorizingOfficialSignUp: {
            authorizingOfficialSignUpSuccess: true,
            authorizingOfficialSignUpErrorText: ''
          }
        },
      }
    case SEND_AUTHORIZING_OFFICIAL_SIGN_UP_ERROR:
      return {
        ...state,
        isLoading: false,
        authorizingOfficialSignUpStepModel: {
          authorizingOfficialSignUp: {
            authorizingOfficialSignUpSuccess: false,
            authorizingOfficialSignUpErrorText: action.payload.message
          }
        },
      }
    case RESET_AUTHORIZING_OFFICIAL_SIGN_UP:
      return {
        ...state,
        isLoading: false,
        authorizingOfficialSignUpStepModel: {
          authorizingOfficialSignUp: {
            authorizingOfficialSignUpSuccess: false,
            authorizingOfficialSignUpErrorText: ''
          }
        },
      }
    case GET_CANDIDATE_CE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        associateCesAndHsStepModel: {
          ...state.associateCesAndHsStepModel,
          aoInfo: action.payload.aoInfo,
          candidateCeListModel: {
            candidateHealthSystems: action.payload.candidateHealthSystems,
            orphanedCes: action.payload.orphanedCes
          },
          isLoading: false
        }
      }
    case SET_SELECTED_HEALTH_SYSTEM:
      return {
        ...state,
        associateCesAndHsStepModel: {
          ...state.associateCesAndHsStepModel,
          selectedHealthSystem: {
            ...action.payload,
            ceList: action.payload.ceList.filter((ce) => ce.allowedToOnboard),
            isExistingHealthSystem: action.payload.healthSystemId ? true : false,
          }
        }
      }
    case SET_SELECTED_ORPHAN_CES:
      return {
        ...state,
        associateCesAndHsStepModel: {
          ...state.associateCesAndHsStepModel,
          selectedHealthSystem: {
            ...state.associateCesAndHsStepModel.selectedHealthSystem,
            orphanCeList: action.payload
          }
        }
      }
    case SET_HEALTH_SYSTEM_NAME:
      return {
        ...state,
        associateCesAndHsStepModel: {
          ...state.associateCesAndHsStepModel,
          selectedHealthSystem: {
            ...state.associateCesAndHsStepModel.selectedHealthSystem,
            healthSystemName: action.payload
          }
        }
      }
    case CHECK_UNIQUE_HEALTH_SYSTEM_NAME:
      return {
        ...state,
        associateCesAndHsStepModel: {
          ...state.associateCesAndHsStepModel,
          checkUniqueHealthSystemName: {
            name: action.payload,
            unique: false,
            isLoading: true,
            isLoaded: false,
          }
        }
      }
    case CHECK_UNIQUE_HEALTH_SYSTEM_NAME_SUCCESS:
      return {
        ...state,
        associateCesAndHsStepModel: {
          ...state.associateCesAndHsStepModel,
          checkUniqueHealthSystemName: {
            ...state.associateCesAndHsStepModel.checkUniqueHealthSystemName,
            unique: action.payload,
            isLoading: false,
            isLoaded: true,
          }
        }
      }
    case CHECK_UNIQUE_HEALTH_SYSTEM_NAME_FAILURE:
      return {
        ...state,
        errorText: LOAD_DATA_ERROR_MESSAGE,
        associateCesAndHsStepModel: {
          ...state.associateCesAndHsStepModel,
          checkUniqueHealthSystemName: {
            ...state.associateCesAndHsStepModel.checkUniqueHealthSystemName,
            isLoading: false,
            isLoaded: true,
          }
        }
      }
    case GET_HEALTH_SYSTEM_ACCOUNT_ADMIN_SUCCESS:
      return {
        ...state,
        inviteAccountAdminStepModel: {
          ...state.inviteAccountAdminStepModel,
          hasAccountAdmin: action.payload.hasAccountAdmin,
          accountAdminInfo: action.payload.accountAdminInfo
        },
        isLoading: false,
      }
    case GET_CONTROLLER_STATUS:
      return {
        ...state,
        isLoading: true
      }
    case GET_CONTROLLER_STATUS_SUCCESS:
      return {
        ...state,
        controllerStatusModel: action.payload,
        isLoading: false
      }
    case GET_CONTROLLER_STATUS_FAILURE:
      return {
        ...state,
        controllerStatusModel: {
          ...state.controllerStatusModel,
          completed: true
        },
        isLoading: false,
        errorText: LOAD_DATA_ERROR_MESSAGE
      }
    case COMPLETE_ONBOARDING:
      return {
        ...state,
        onboardingStatusModel: {
          ...state.onboardingStatusModel,
          onboardingCompleted: true
        }
      }
    default:
      return state
  }
}
