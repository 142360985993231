import React, { useEffect, useState } from 'react'
import { Button, Icon, Form } from 'semantic-ui-react';
import { Formik, ErrorMessage } from 'formik';
import classnames from 'classnames';
import * as yup from 'yup';
import moment, { Moment } from 'moment-timezone';
import { SingleDatePicker, isInclusivelyBeforeDay } from 'react-dates';

import { MIN_DATE, MAX_DATE } from './RequestFormHelper';
import { getStartOfTheDayNonUTC } from '../../../utils/dateHelper';
import { CoveredEntityModel, DiscountRequestRequiredFieldsModel } from '../../../store/requestcenter/types';
// import { DiscountProgramModel } from '../../../store/appcommon/types';

export interface VerifyProps {
  requiredFieldValues: DiscountRequestRequiredFieldsModel;
  // discountPrograms: DiscountProgramModel[];
  coveredEntities: CoveredEntityModel[];
  submitError: string;
  onSubmit: (requiredFieldValues: DiscountRequestRequiredFieldsModel) => void;
}

export const Verify: React.FunctionComponent<VerifyProps> = (props) => {

  const { coveredEntities, requiredFieldValues, submitError, onSubmit } = props;

  const [focused, setFocused] = useState<boolean>(false);

  // const discountProgramsOptions = discountPrograms.map((option) => ({ key: option.name, value: option.name, text: option.name }));
  const threeFoutyBOptions = coveredEntities.map((option) => ({ key: option.idCode, value: option.idCode, text: option.idCode }));

  const initialValues: DiscountRequestRequiredFieldsModel = {
    ...requiredFieldValues,
    // discountProgram: discountProgramsOptions.length === 1 ? discountProgramsOptions[0].value : requiredFieldValues.discountProgram,
    discountProgram: '340B',
    idCode: threeFoutyBOptions.length === 1 ? threeFoutyBOptions[0].value : requiredFieldValues.idCode,
  };

  const schema = yup.object().shape({
    // discountProgram: yup
    //   .string()
    //   .label('Discount Program')
    //   .required(),
    idCode: yup
      .string()
      .label('340B ID')
      .required(),
    ndc11: yup
      .string()
      .label('NDC11')
      .required()
      .test('regex', '${path} must be a number',
        function (value: any) {
          if (value !== undefined && value !== null) {
            return new RegExp(/^\d{0,11}$/).test(value);
          }
          return true;
        }),
    fillDate: yup
      .date()
      .label('Fill Date')
      .nullable()
      .required('${path} must be a valid non-future date')
      .min(MIN_DATE)
      .max(MAX_DATE)
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
      validateOnMount={true}
      enableReinitialize={true}
    >
      {({ handleSubmit, handleChange, handleBlur, errors, touched, setFieldValue, setFieldTouched, values, isValid, validateForm }) => {

        const handleDate = async (date: Moment | null) => {
          const fillDate = date ? new Date(date.toDate()) : null;
          await setFieldValue('fillDate', getStartOfTheDayNonUTC(fillDate)); // need to wait for this to finish before setting touched
          setFieldTouched('fillDate');
        }

        const handleDateClose = async () => {
          if (values.fillDate === null) {
            await setFieldTouched('fillDate');
          }
        };

        //Temporarily remove onClick function from ToggleGroup and disable toggle
        // const handleClaimType = () => {
        //   const claimTypeValue = values.claimType === 'Retail' ? 'Medical' : 'Retail';
        //   setFieldValue('claimType', claimTypeValue)
        // };

        useEffect(() => {
          validateForm();
        }, [validateForm]);

        return (
          <React.Fragment>
            <div className='request-form-body flex-row flex-grow'>
              {props.children}
              <div className='request-form-details flex-column'>
                <div className='form-container flex-column flex-grow'>
                  <div className='form-title gamma'>Verify Eligibility</div>
                  {submitError &&
                    <div className='error-notification'>
                      <Icon name='warning sign' />
                      <div className="error-notification-message">{submitError}</div>
                    </div>
                  }
                  <form className='ui form'>
                    {/* <div className='claim-type-field'>
                        <ToggleGroup leftLabel={CLAIM_TYPE.leftLabel} rightLabel={CLAIM_TYPE.rightLabel} checked={values.claimType === 'Medical'} disabled />
                      </div> */}
                    <div className='verify-row form-row'>
                      {/*for now program dropdown is no longer needed and should default to 340B */}
                      {/* <div className='form-column'>
                          <Form.Select
                            name={'discountProgram'}
                            value={values.discountProgram}
                            label={'Discount Program'}
                            placeholder={'Select Discount Program'}
                            options={discountProgramsOptions}
                            onChange={(e, data) => setFieldValue('discountProgram', data.value)}
                            onBlur={() => setFieldTouched('discountProgram')} />
                          <ErrorMessage name={'discountProgram'}></ErrorMessage>
                        </div> */}
                      <div className='form-column'>
                        <Form.Select
                          name={'idCode'}
                          value={values.idCode}
                          label={'340B ID'}
                          placeholder={'340B ID'}
                          options={threeFoutyBOptions}
                          onChange={(e, data) => setFieldValue('idCode', data.value)}
                          onBlur={() => setFieldTouched('idCode')}
                          error={!!(errors.idCode && touched.idCode)} />
                        <ErrorMessage name={'idCode'}></ErrorMessage>
                      </div>
                      <div className='form-column'>
                        <Form.Input
                          type='text'
                          name={'ndc11'}
                          value={values.ndc11}
                          placeholder={'NDC11'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!(errors.ndc11 && touched.ndc11)}
                          label={'NDC11'} />
                        <ErrorMessage name={'ndc11'}></ErrorMessage>
                      </div>
                    </div>
                    <div className='verify-row  form-row'>
                      <div className={classnames('form-column request-form-date-field', { 'has-error': !!(errors.fillDate && touched.fillDate) })}>
                        <div className='field'>
                          <label>Fill Date</label>
                          <SingleDatePicker
                            isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                            numberOfMonths={1}
                            noBorder
                            required
                            date={values.fillDate ? moment(values.fillDate) : null}
                            id='singleDatePicker'
                            focused={focused}
                            onFocusChange={({ focused }) => setFocused(focused)}
                            onDateChange={(date) => handleDate(date)}
                            onClose={handleDateClose}
                          />
                        </div>
                        <ErrorMessage name={'fillDate'}></ErrorMessage>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='request-form-footer flex-column justify-center'>
              <div className='flex-row justify-flex-end'>
                <div className='actions'>
                  <Button onClick={() => handleSubmit()} secondary disabled={!isValid} className="disReqSubmitFormVerifyAndNext_Pendo" type="submit">Verify and Next</Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      }
      }
    </Formik>

  )
}




export default Verify

