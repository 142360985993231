import React, { Fragment } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { RootState } from '../../store/RootState';
import EmptyTransactions from '../common/transactions/EmptyTransactions';
import { getReversals, updateReversalsFilter, updateReversalsPage, updateReversalsSort, resetReversalsTransactions } from '../../store/reversals/actionCreators';
import { NO_FILTER_RESULTS_HEADER, NO_FILTER_RESULTS_MESSAGE, NO_PENDING_REVERSALS_HEADER, NO_PENDING_REVERSALS_MESSAGE, NO_PROCESSED_REVERSALS_HEADER, NO_PROCESSED_REVERSALS_MESSAGE } from '../../store/applicationConstants';
import { DiscountRequestModel, TransactionsFilterModel } from '../../store/transactionscommon/types';
import { ReversalTransactionsFilters } from '../../store/reversals/types';
import { get30DaysAgo, getEndOfTheDayNonUTC, getStartOfTheDayNonUTC } from '../../utils/dateHelper';
import { REVERSALS_TAB, UI_PAGE_SIZE, API_PAGE_SIZE } from '../../store/reversals/constants';
import { AdjustReasonsModel, SortingFilter } from '../../store/appcommon/types';
import { canUseCachedRecords } from '../../utils/datatableHelper';
import ReversalsTableHeader from './ReversalsTableHeader';
import ReversalsTable from './ReversalsTable';
import { getPagedReversalTransactions } from '../../store/reversals/selectors';
import ReversalsDrawer from './ReversalsDrawer';

export interface ReversalsTabProps {
  tab: REVERSALS_TAB;
  transactions: DiscountRequestModel[],
  currentApiPage: number,
  currentPage: number,
  totalCount: number,
  isLoading: boolean,
  filters: ReversalTransactionsFilters,
  adjustReasons: AdjustReasonsModel;
  getReversals: (tab: REVERSALS_TAB) => void;
  updateReversalsPage: (pageNumber: number) => void;
  updateReversalsSort: (sort: SortingFilter) => void;
  updateReversalFilters: (tab: REVERSALS_TAB, filters: TransactionsFilterModel) => void;
  resetReversalsTransactions: () => void;
}

export interface ReversalsTabState {
  activeTransaction?: DiscountRequestModel,
  isDrawerOpen: boolean;
}

export class ReversalsTab extends React.Component<ReversalsTabProps, ReversalsTabState> {
  constructor (props: Readonly<ReversalsTabProps>) {
    super(props)
    this.state = {
      isDrawerOpen: false,
    }
  }

  componentDidMount () {
    this.props.getReversals(this.props.tab);
  }

  // It seems switching tabs doesn't mount/unmount just updates props
  componentDidUpdate (prevProps: ReversalsTabProps) {
    if (this.props.tab !== prevProps.tab) {
      this.setState({ isDrawerOpen: false });
      this.props.resetReversalsTransactions();
      this.props.getReversals(this.props.tab);
    }
  }

  handleRowClick = (transactionId: number) => {
    const transaction = this.props.transactions.find((d) => d.id === transactionId);
    if (transaction) {
      this.setState({ activeTransaction: transaction, isDrawerOpen: true });
    }
  }

  handlePageChange = (page: number) => {
    this.props.updateReversalsPage(page);
    if (!canUseCachedRecords(page, this.props.currentApiPage, UI_PAGE_SIZE, API_PAGE_SIZE)) {
      this.props.getReversals(this.props.tab)
    }
  }

  handleSort = (sort: SortingFilter) => {
    this.props.updateReversalsSort(sort);
    this.props.getReversals(this.props.tab);
  }

  handleApplyFilter = (filter: TransactionsFilterModel) => {
    this.props.updateReversalFilters(this.props.tab, filter);
    this.props.getReversals(this.props.tab);
  }

  handleDrawerNext = () => {
    const index = this.props.transactions.indexOf(this.state.activeTransaction!);
    if (index !== -1 && index + 1 < this.props.transactions.length) {
      this.setState({ activeTransaction: this.props.transactions[index + 1] });
    }
  }

  handleDrawerPrevious = () => {
    const index = this.props.transactions.indexOf(this.state.activeTransaction!);
    if (index > 0) {
      this.setState({ activeTransaction: this.props.transactions[index - 1] });
    }
  }

  handleDrawerClose = () => {
    this.setState({ isDrawerOpen: false });
  }

  render () {
    const { tab, transactions, filters, isLoading, currentPage, totalCount, adjustReasons } = this.props;
    const { isDrawerOpen, activeTransaction } = this.state;

    const filter = filters[tab];
    const areFiltersApplied = Object.values({ ...filter, 'startDate': '', 'endDate': '' }).some(filter => (filter !== null && filter !== ''));
    const areDatesApplied = filter.startDate !== getStartOfTheDayNonUTC(get30DaysAgo(new Date())) || filter.endDate !== getEndOfTheDayNonUTC(new Date());

    const index = activeTransaction ? transactions.indexOf(activeTransaction) : -1;
    const nextEnabled = index !== -1 && index + 1 < transactions.length;
    const prevEnabled = index > 0;

    return (
      <div className="recipient-transactions-tab">

        {isLoading &&
          <Dimmer active inverted>
            <Loader className="recipient-request-center-loader" inverted content="Loading..." />
          </Dimmer>
        }

        {tab === REVERSALS_TAB.PENDING && (
          <div className="recipient-transactions-banner">
            <div className="recipient-transactions-banner-text">
              These transactions are pending processing. If in manufacturer review, reversals will be processed after this period.
            </div>
          </div>
        )}

        <div className="padded-tab">

          <ReversalsTableHeader
            filter={filter}
            currentPage={currentPage}
            totalCount={totalCount}
            showDateFilter={tab === REVERSALS_TAB.PROCESSED}
            isLoading={isLoading}
            adjustReasons={adjustReasons}
            handleApplyFilter={this.handleApplyFilter}
            handlePageChange={this.handlePageChange}
          />

          <ReversalsTable
            transactions={transactions}
            activeTransactionId={activeTransaction?.id}
            canSortStatus={tab === REVERSALS_TAB.PROCESSED}
            handleCellSorting={this.handleSort}
            handleRowClick={this.handleRowClick}
          />

          {isDrawerOpen &&
            <ReversalsDrawer
              selectedTransaction={activeTransaction!}
              nextEnabled={nextEnabled}
              prevEnabled={prevEnabled}
              handleClose={this.handleDrawerClose}
              handleNext={this.handleDrawerNext}
              handlePrevious={this.handleDrawerPrevious}
            />
          }

          {!isLoading && transactions.length === 0 &&
            <Fragment>
              {tab === REVERSALS_TAB.PROCESSED &&
                <EmptyTransactions
                  emptyTransactionsHeader={areFiltersApplied || areDatesApplied ? NO_FILTER_RESULTS_HEADER : NO_PROCESSED_REVERSALS_HEADER}
                  emptyTransactionsMessage={areFiltersApplied || areDatesApplied ? NO_FILTER_RESULTS_MESSAGE : NO_PROCESSED_REVERSALS_MESSAGE}
                />
              }
              {tab === REVERSALS_TAB.PENDING &&
                <EmptyTransactions
                  emptyTransactionsHeader={areFiltersApplied ? NO_FILTER_RESULTS_HEADER : NO_PENDING_REVERSALS_HEADER}
                  emptyTransactionsMessage={areFiltersApplied ? NO_FILTER_RESULTS_MESSAGE : NO_PENDING_REVERSALS_MESSAGE}
                />
              }
            </Fragment>
          }

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  transactions: getPagedReversalTransactions(state),
  currentApiPage: state.ReversalsState.reversals.currentApiPage,
  currentPage: state.ReversalsState.reversals.currentPage,
  totalCount: state.ReversalsState.reversals.totalCount,
  isLoading: state.ReversalsState.reversals.isLoading,
  filters: state.ReversalsState.filters,
  adjustReasons: state.AppCommonState.adjustReasons,
})

const mapDispatchToProps = (dispatch: any) => ({
  getReversals: (tab: REVERSALS_TAB) => dispatch(getReversals(tab)),
  updateReversalsPage: (pageNumber: number) => dispatch(updateReversalsPage(pageNumber)),
  updateReversalsSort: (sort: SortingFilter) => dispatch(updateReversalsSort(sort)),
  updateReversalFilters: (tab: REVERSALS_TAB, filters: TransactionsFilterModel) => dispatch(updateReversalsFilter(tab, filters)),
  resetReversalsTransactions: () => dispatch(resetReversalsTransactions())
});

export default connect(mapStateToProps, mapDispatchToProps)(ReversalsTab);
