import { call, put, all, takeEvery, select } from 'redux-saga/effects';
import { getHealthSystems, openSearchHealthSystemsModal, searchHealthSystemsFailure, searchHealthSystemsSuccess } from '../teammanagement/actionCreators';
import Logrocket from 'logrocket';
import {
  getHealthSystemsSuccess,
  getHealthSystemInfo,
  getHealthSystemInfoSuccess,
  getHealthSystemsFailed,
  getHealthSystemInfoFailed,
  addUserFailure,
  addUserSuccess,
  assignUserCEsSuccess,
  assignUserCEsFailure,
  assignCeUsersSuccess,
  assignCeUsersFailure,
  updateUserRoleError,
  updateUserRoleSuccess,
  updateUserSuccess,
  updateUserError,
  closeEditTeamModal,
  closeAssignCEModal,
  openAssignCEModal,
  closeAddUserModal,
  cancelInviteSuccess,
  cancelInviteFailure,
  closeCancelInviteModal,
  getCEListForHSModalError,
  getCEListForHSModalSuccess,
  openChangeHealthSystemsModal,
  updateSelectedHealthSystemInfo,
  sendOnboardingInviteSuccess,
  sendOnboardingInviteFailure,
  closeOnboardingInviteModal,
  updateLeadContactSuccess,
  disableUserSuccess,
  disableUserFailure,
  enableUserSuccess,
  enableUserFailure
} from './actionCreators';
import {
  GET_HEALTH_SYSTEMS,
  GET_HEALTH_SYSTEM_INFO,
  ADD_USER,
  ASSIGN_USER_CE,
  ASSIGN_CE_USERS,
  RECIPIENT_TEAM_MANAGEMENT_USER_ROLES,
  UPDATE_USER_ROLE,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  CLOSE_ASSIGN_CE_MODAL,
  CANCEL_INVITE,
  GET_CE_LIST_FOR_HS_MODAL,
  USER_NOT_APART_OF_A_HEALTH_SYSTEM,
  SEND_ONBOARDING_INVITE,
  UPDATE_CE_LEAD_CONTACT,
  DISABLE_USER,
  ENABLE_USER,
  SEARCH_HEALTH_SYSTEMS
} from './constants';
import { healthSystems, healthSystemInfo, healthSystemBasicInfo, healthSystemSearch } from '../../api/teammanagement/HealthSystem';
import { addUserRequest, assignCERequest, updateUserRole, updateUser, cancelInviteRequest, inviteAccountAdminRequest, inviteControllerRequest, disableUserCall, enableUserCall } from '../../api/teammanagement/Users';
import { assignUserRequest, updateLeadContact } from '../../api/teammanagement/CoveredEntities';
import { getActiveHealthSystemId, getActiveHealthSystemCoveredEntities, getAddUserModalNewUserAdded, getTeamManagementUserId, getEntitledHealthSystems, getPreSelectedHealthSystemID as getPreSelectedHealthSystemId } from './selectors';
import { createToast, TOAST_TYPE } from '../toast';
import { checkOnboardingStatus, resetOnboardingStatus } from '../onboarding/actionCreators';
import { getUserHealthSystemId, storeUserHealthSystemId } from '../../localstore/LocalStore';
import { HealthSystem } from './types';
import { REMOVE_FUNDING_SOURCE_SUCCESS, VERIFY_MICRO_DEPOSITS_MODAL_CLOSE } from '../payments/common/constants';
import { ROLES } from '../applicationConstants';
import { ErrorModel } from '../applicationTypes';
import { getUserInfo } from '../account/actionCreators';
import logRocketUtil from '../../utils/logRocketUtil';
import { selectRoles } from '../login/loginSelector';
import { isAdminAllAccess, isAdminSupport } from '../../utils/roleUtil';

export function* getHealthSystemsSaga () {
  try {
    const getHealthSystemsResponse = yield call(healthSystems);
    if (getHealthSystemsResponse.DetailCodes && getHealthSystemsResponse.DetailCodes.length > 0 && getHealthSystemsResponse.DetailCodes.includes(1)) {
      Logrocket.track('error')
      const errorModel: ErrorModel = {
        message: USER_NOT_APART_OF_A_HEALTH_SYSTEM,
        traceId: 'get health systems response - detail codes issue'
      }
      yield put(getHealthSystemsFailed(errorModel));
    } else {
      const healthSystemList = getHealthSystemsResponse.healthSystems;

      //if health systems, make sure the user as in internal admin
      if (healthSystemList.length === 0) {
        const roles: string[] = yield (select(selectRoles));
        const preselectedHealthSystemId = yield (select(getPreSelectedHealthSystemId));
        if (isAdminAllAccess(roles) && preselectedHealthSystemId) {
          storeUserHealthSystemId(getHealthSystemsResponse.userId, preselectedHealthSystemId);
          const healthSystem = yield call(healthSystemBasicInfo, preselectedHealthSystemId);
          yield put(updateSelectedHealthSystemInfo(healthSystem));
          yield put(getHealthSystemInfo(healthSystem.healthSystemId));
          yield put(checkOnboardingStatus(healthSystem.healthSystemId));
        } else if (isAdminSupport(roles)) {
          yield put(openSearchHealthSystemsModal(true))
        }
      }

      if (healthSystemList.length > 0) {
        if (healthSystemList.length > 1) {
          const healthSystemId = getUserHealthSystemId(getHealthSystemsResponse.userId)
          const selectedHS = healthSystemList.find((hs: HealthSystem) => hs.healthSystemId === healthSystemId);
          if (selectedHS) {
            yield put(updateSelectedHealthSystemInfo(selectedHS))
            yield put(getHealthSystemInfo(selectedHS.healthSystemId));
            yield put(checkOnboardingStatus(selectedHS.healthSystemId));
          } else {
            yield put(openChangeHealthSystemsModal(true))
          }
        } else {
          yield put(updateSelectedHealthSystemInfo(healthSystemList[0]))
          yield put(getHealthSystemInfo(healthSystemList[0].healthSystemId));
          yield put(checkOnboardingStatus(healthSystemList[0].healthSystemId));
        }
      }
      yield put(getHealthSystemsSuccess(getHealthSystemsResponse));
    }
  } catch (e) {
    yield put(getHealthSystemsFailed(logRocketUtil(e)));
  }
}

export function* getHealthSystemInfoSaga (action: any) {
  try {
    if (action.payload.switchingHS) {
      yield put(resetOnboardingStatus());
    }
    const healthSystemInfoResponse = yield call(healthSystemInfo, action.payload.healthSystemId);
    if (action.payload.switchingHS) {
      const userId = yield select(getTeamManagementUserId);
      const roles: string[] = yield (select(selectRoles));
      const entitledHealthSystems: HealthSystem[] = yield select(getEntitledHealthSystems);
      let selectedHS: HealthSystem | undefined;

      if (entitledHealthSystems.length > 0) {
        selectedHS = entitledHealthSystems.find(hs => hs.healthSystemId === action.payload.healthSystemId);
      } else if (isAdminAllAccess(roles) || isAdminSupport(roles)) {
        const healthSystemBasicInfoResponse = yield call(healthSystemBasicInfo, action.payload.healthSystemId);
        selectedHS = healthSystemBasicInfoResponse;
      }
      storeUserHealthSystemId(userId, action.payload.healthSystemId);
      if (selectedHS) {
        yield put(updateSelectedHealthSystemInfo(selectedHS));
      }
      yield put(checkOnboardingStatus(selectedHS!.healthSystemId));
      yield put(createToast({ text: 'You have switched health systems', type: TOAST_TYPE.SUCCESS }))
    }
    yield put(getHealthSystemInfoSuccess(healthSystemInfoResponse));
    yield put(getUserInfo());

  } catch (e) {
    if (action.payload.switchingHS) {
      yield put(createToast({ text: 'Error in switching health systems', type: TOAST_TYPE.ERROR }))
    }
    yield put(getHealthSystemInfoFailed(logRocketUtil(e)));
  }
}

export function* updateUserRoleSaga (action: any) {
  try {
    const response = yield call(updateUserRole, action.payload)
    yield put(updateUserRoleSuccess())
    yield put(createToast({ text: 'User has been updated.', type: TOAST_TYPE.SUCCESS }))
    if (action.payload.role === RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN && response.coveredEntityCount !== 0)
      yield put(createToast({ text: `User has been assigned to ${response.coveredEntityCount} covered ${response.coveredEntityCount === 1 ? 'entity' : 'entities'}`, type: TOAST_TYPE.SUCCESS }));
  }
  catch (e) {
    yield put(updateUserRoleError(logRocketUtil(e)))
    yield put(createToast({ text: e.message, type: TOAST_TYPE.ERROR }))
  }
}

export function* updateUserSaga (action: any) {
  try {
    const response = yield call(updateUser, action.payload)
    yield put(updateUserSuccess(response));
    yield put(createToast({ text: 'User has been updated.', type: TOAST_TYPE.SUCCESS }))
  }
  catch (e) {
    yield put(updateUserError(logRocketUtil(e)))
    yield put(createToast({ text: e.message, type: TOAST_TYPE.ERROR }))
  }
}
export function* disableUserSaga (action: any) {
  try {
    const response = yield call(disableUserCall, action.payload.teamId, action.payload.userId);
    yield put(disableUserSuccess(response))
    yield put(getHealthSystems());
    yield put(createToast({ text: 'User has been disabled.', type: TOAST_TYPE.SUCCESS }))
  }
  catch (e) {
    yield put(disableUserFailure(e.message))
    yield put(createToast({ text: 'Unable to disable user.', type: TOAST_TYPE.ERROR }))
    yield put(updateUserError(logRocketUtil(e)))
  }
}

export function* enableUserSaga (action: any) {
  try {
    const response = yield call(enableUserCall, action.payload.teamId, action.payload.userId);
    yield put(enableUserSuccess(response))
    yield put(getHealthSystems());
    yield put(createToast({ text: 'User has been enabled.', type: TOAST_TYPE.SUCCESS }))
    if (response)
      yield put(enableUserFailure(response.message))
  }
  catch (e) {
    yield put(createToast({ text: 'Unable to enable a user.', type: TOAST_TYPE.ERROR }))
    yield put(updateUserError(logRocketUtil(e)))
  }
}

export function* addUserSaga (action: any) {
  try {
    const healthSystemId = yield select(getActiveHealthSystemId);
    const response = yield call(addUserRequest, healthSystemId, action.payload.email, action.payload.role);
    yield put(addUserSuccess(response));
    yield put(closeAddUserModal());
    yield put(createToast({ text: 'You have successfully invited a new user.', type: TOAST_TYPE.SUCCESS }))

    if (action.payload.role === RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN || action.payload.role === RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.PAYMENT) {
      const coveredEntities = yield select(getActiveHealthSystemCoveredEntities);
      yield put(assignUserCEsSuccess(''));
      yield put(createToast({ text: `User has been assigned to ${coveredEntities.length} covered ${coveredEntities.length === 1 ? 'entity' : 'entities'}`, type: TOAST_TYPE.SUCCESS }));
      yield put(getHealthSystemInfo(healthSystemId));
    } else {
      yield put(openAssignCEModal(response.id, []));
    }

  } catch (e) {
    yield put(addUserFailure(logRocketUtil(e)))
  }
}

export function* assignUserCEsSaga (action: any) {
  try {
    const healthSystemId = yield select(getActiveHealthSystemId);
    const response = yield call(assignCERequest, healthSystemId, action.payload.userId, action.payload.ceIds);
    yield put(assignUserCEsSuccess(response));
    yield put(closeAssignCEModal(false));
    yield put(createToast({ text: `User has been assigned to ${action.payload.ceIds.length} covered ${action.payload.ceIds.length === 1 ? 'entity' : 'entities'}`, type: TOAST_TYPE.SUCCESS }));
    yield put(getHealthSystemInfo(healthSystemId));
    if (response) {
      yield put(createToast({ text: response, type: TOAST_TYPE.ERROR }));
    }

  } catch (e) {
    yield put(assignUserCEsFailure(logRocketUtil(e)));
  }
}

export function* closeAssignCEModalSaga (action: any) {
  if (action.payload === true) {
    const newUserAdded = yield select(getAddUserModalNewUserAdded);
    if (newUserAdded) {
      const healthSystemId = yield select(getActiveHealthSystemId);
      yield put(getHealthSystemInfo(healthSystemId));
    }
  }
}

export function* assignCeUsersSaga (action: any) {
  try {
    const healthSystemId = yield select(getActiveHealthSystemId);
    const response = yield call(assignUserRequest, healthSystemId, action.payload.ceId, action.payload.userIds);

    yield put(assignCeUsersSuccess(response));
    yield put(closeEditTeamModal());
    yield put(getHealthSystemInfo(healthSystemId));
    if (response) {
      yield put(createToast({ text: response, type: TOAST_TYPE.ERROR }));
    } else {
      yield put(createToast({ text: `${action.payload.userIds.length} ${action.payload.userIds.length === 1 ? 'user' : 'users'} assigned to covered entity`, type: TOAST_TYPE.SUCCESS }));
    }

  } catch (e) {
    yield put(assignCeUsersFailure(logRocketUtil(e)));
  }
}

export function* cancelInviteSaga (action: any) {
  try {
    const healthSystemId = yield select(getActiveHealthSystemId);
    yield call(cancelInviteRequest, healthSystemId, action.payload.userId);
    yield put(cancelInviteSuccess());
    yield put(closeCancelInviteModal());
    yield put(createToast({ text: `Invite has been cancelled for ${action.payload.email}`, type: TOAST_TYPE.SUCCESS }));
    yield put(getHealthSystemInfo(healthSystemId));
  } catch (e) {
    yield put(cancelInviteFailure(logRocketUtil(e)));
  }
}

export function* getCEListForHSModalSaga (action: any) {
  try {
    const { coveredEntities } = yield call(healthSystemInfo, action.payload);
    yield put(getCEListForHSModalSuccess(coveredEntities))
  }
  catch (e) {
    yield put(getCEListForHSModalError(logRocketUtil(e)))
  }
}

export function* searchHealthSystemsSaga (action: any) {
  try {
    const healthSystems: HealthSystem[] = yield call(healthSystemSearch, action.payload);
    yield put(searchHealthSystemsSuccess(healthSystems))
  }
  catch (e) {
    yield put(searchHealthSystemsFailure(logRocketUtil(e)))
  }
}

export function* sendOnboardingInviteSaga (action: any) {
  try {
    const healthSystemId = yield select(getActiveHealthSystemId);
    const role = action.payload.role;
    let roleText = '';

    if (role === ROLES.RECIPIENT_ACCOUNT_ADMIN) {
      roleText = 'Account Admin';
      yield call(inviteAccountAdminRequest, action.payload.user, healthSystemId);
    } else if (role === ROLES.RECIPIENT_CONTROLLER) {
      roleText = 'Controller';
      yield call(inviteControllerRequest, action.payload.user, healthSystemId);
    } else {
      throw new Error('Invalid role');
    }

    yield put(sendOnboardingInviteSuccess());
    yield put(closeOnboardingInviteModal());
    yield put(createToast({ text: `You have successfully invited a new ${roleText}.`, type: TOAST_TYPE.SUCCESS }))
    yield put(getHealthSystemInfo(healthSystemId));
  }
  catch (e) {
    yield put(sendOnboardingInviteFailure(logRocketUtil(e)))
  }
}

export function* updateLeadContactSaga (action: any) {
  try {
    const response = yield call(updateLeadContact, action.payload.ceId, action.payload.userId);

    yield put(updateLeadContactSuccess(response.userID, response.ceID, action.payload.id340B));
    yield put(createToast({ text: 'Lead Contact Successfully updated.', type: TOAST_TYPE.SUCCESS }))
  } catch (e) {
    // Do nothing for now even though we shouldn't
  }
}

function* watchGetHealthSystems () {
  yield takeEvery([GET_HEALTH_SYSTEMS, UPDATE_USER_ROLE_SUCCESS, UPDATE_USER_SUCCESS, REMOVE_FUNDING_SOURCE_SUCCESS, VERIFY_MICRO_DEPOSITS_MODAL_CLOSE], getHealthSystemsSaga);
}

function* watchUpdateLeadContact () {
  yield takeEvery(UPDATE_CE_LEAD_CONTACT, updateLeadContactSaga);
}

function* watchGetHealthSystemInfo () {
  yield takeEvery(GET_HEALTH_SYSTEM_INFO, getHealthSystemInfoSaga);
}

function* watchAddUser () {
  yield takeEvery(ADD_USER, addUserSaga);
}

function* watchAssignUserCEsSaga () {
  yield takeEvery(ASSIGN_USER_CE, assignUserCEsSaga);
  yield takeEvery(CLOSE_ASSIGN_CE_MODAL, closeAssignCEModalSaga)
}

function* watchAssignCeUsersSaga () {
  yield takeEvery(ASSIGN_CE_USERS, assignCeUsersSaga);
}

function* watchUpdateUserRole () {
  yield takeEvery(UPDATE_USER_ROLE, updateUserRoleSaga)
}

function* watchUpdateUser () {
  yield takeEvery(UPDATE_USER, updateUserSaga)
}

function* watchCancelInviteSaga () {
  yield takeEvery(CANCEL_INVITE, cancelInviteSaga);
}

function* watchGetCEListForHSModalSaga () {
  yield takeEvery(GET_CE_LIST_FOR_HS_MODAL, getCEListForHSModalSaga)
}

function* watchSearchHealthSystemsSaga () {
  yield takeEvery(SEARCH_HEALTH_SYSTEMS, searchHealthSystemsSaga)
}

function* watchSendOnboardingInviteSaga () {
  yield takeEvery(SEND_ONBOARDING_INVITE, sendOnboardingInviteSaga)
}

function* watchDisableUser () {
  yield takeEvery(DISABLE_USER, disableUserSaga)
}

function* watchEnableUser () {
  yield takeEvery(ENABLE_USER, enableUserSaga)
}

export default function* watchAllTeamManagement () {
  yield all([
    watchGetHealthSystems(),
    watchGetHealthSystemInfo(),
    watchAddUser(),
    watchAssignUserCEsSaga(),
    watchAssignCeUsersSaga(),
    watchUpdateUserRole(),
    watchUpdateUser(),
    watchCancelInviteSaga(),
    watchGetCEListForHSModalSaga(),
    watchSearchHealthSystemsSaga(),
    watchSendOnboardingInviteSaga(),
    watchUpdateLeadContact(),
    watchDisableUser(),
    watchEnableUser()
  ])
}
