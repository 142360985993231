import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';

import { FilterDropdown } from '../../../common/filter/FilterDropdown';
import { FilterModel } from '../../../../store/appcommon/types';
import { getLabelerOptions, getNdcOptions, getRebateQtrOptions } from '../../../../store/mdrp/disputescenter/selectors';
import { ManufacturerDisputesFilterModel, PrimaryFilterOptionsModel } from '../../../../store/mdrp/disputescenter/types';
import { RootState } from '../../../../store/RootState';

export interface ManufacturerReviewPrimaryFiltersProps {
  filters: ManufacturerDisputesFilterModel;
  isLoading: boolean;
  labelerFilterOptions: FilterModel[];
  ndcFilterOptions: FilterModel[];
  onApplyFilters: (values: ManufacturerDisputesFilterModel, isPrimaryFilter: boolean) => void;
  options: PrimaryFilterOptionsModel;
  rebateQtrFilterOptions: FilterModel[];
}

export const ManufacturerReviewPrimaryFilters = (props: ManufacturerReviewPrimaryFiltersProps) => {
  const { filters, isLoading, labelerFilterOptions, ndcFilterOptions, onApplyFilters, rebateQtrFilterOptions, options } = props;

  const [ndcDropdownOptions, setNdcDropdownOptions] = useState<FilterModel[]>([]);

  const updateNdcFilterOptions = (selectedLabelerOptions: string[]) => {
    if (selectedLabelerOptions.length > 0) {
      const filteredOptions = options.labelers.filter(labeler => selectedLabelerOptions.includes(labeler.labelerCode));
      const ndcOptions = filteredOptions.map(exist => exist.ndcs).flat();
      const ndcFilterDropdownOptions = ndcFilterOptions.filter(option => ndcOptions.includes(option.value));
      setNdcDropdownOptions(ndcFilterDropdownOptions);
    } else {
      setNdcDropdownOptions(ndcFilterOptions);
    }
  };

  const handleApplyFilters = (values: ManufacturerDisputesFilterModel) => {
    onApplyFilters(values, true);
  };

  useEffect(() => {
    if (filters.labeler && filters.labeler.length > 0) {
      updateNdcFilterOptions(filters.labeler);
    } else {
      setNdcDropdownOptions(ndcFilterOptions);
    }
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={filters}
      onSubmit={(values, helpers) => {
        helpers.setSubmitting(false);
        helpers.resetForm({ values: values })
        handleApplyFilters(values);
      }}
    >
      {({ dirty, handleSubmit, setFieldValue, values }) => {
        const handleOnChange = (label: string, value: any) => {
          if (label === 'labeler') {
            updateNdcFilterOptions(value)
          }

          if (value.length > 0){
            setFieldValue(label, value);
          } else {
            setFieldValue(label, null);
          }
        };

        const clearSelectedOptions = (label: string, value: any) => {
          handleOnChange(label, value);
          handleSubmit();
        };

        return (
          <div className="mfr-review-filters">
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Field>
                  <label>Labeler</label>
                  <FilterDropdown
                    isDisabled={isLoading || !values.labeler && (!!values.ndc && values.ndc.length > 0)}
                    isPrimaryFilterType
                    label="Labeler"
                    onChange={(value) => handleOnChange('labeler', value)}
                    onMultipleItemsChange={(value) => clearSelectedOptions('labeler', value)}
                    options={labelerFilterOptions}
                    search
                    values={values.labeler || undefined}
                  />
                </Form.Field>
                <Form.Field>
                  <label>NDC</label>
                  <FilterDropdown
                    isDisabled={isLoading}
                    isPrimaryFilterType
                    label="NDC"
                    onChange={(value) => handleOnChange('ndc', value)}
                    onMultipleItemsChange={(value) => clearSelectedOptions('ndc', value)}
                    options={ndcDropdownOptions}
                    search
                    values={values.ndc || undefined}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Quarter</label>
                  <FilterDropdown
                    isDisabled={isLoading}
                    isPrimaryFilterType
                    label="Quarter"
                    onChange={(value) => handleOnChange('rebateQtr', value)}
                    onMultipleItemsChange={(value) => clearSelectedOptions('rebateQtr', value)}
                    options={rebateQtrFilterOptions}
                    search
                    values={values.rebateQtr || undefined}
                  />
                </Form.Field>

                <div className="mfr-review-filters-button-container">
                  <button
                    className="app-button button-dark"
                    disabled={!dirty || isLoading}
                    type="submit">
                    Apply Filters
                  </button>
                </div>
              </Form.Group>
            </Form>
          </div>
        )
      }}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  labelerFilterOptions: getLabelerOptions(state),
  ndcFilterOptions: getNdcOptions(state),
  rebateQtrFilterOptions: getRebateQtrOptions(state),
})

export default (connect(mapStateToProps))(ManufacturerReviewPrimaryFilters);
