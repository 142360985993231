import { createSelector, Selector } from 'reselect';

export const canUseCachedRecords = (activePage: number, currentApiPage: number, uiPageSize: number, apiPageSize: number) => {
  const recordToSee = activePage * uiPageSize;
  const recordsFromApi_LowerBound = (currentApiPage - 1) * apiPageSize;
  const recordsFromApi_UpperBound = currentApiPage * apiPageSize;
  return recordToSee > recordsFromApi_LowerBound && recordToSee <= recordsFromApi_UpperBound;
}

export const getApiPageToFetch = (currentPage: number, uiPageSize: number, apiPageSize: number) => Math.ceil((currentPage * uiPageSize) / apiPageSize);

export const getPagedData = (data: any[], currentPage: number, uiPageSize: number, apiPageSize: number) => {
  const currentPageForSlice = currentPage % (apiPageSize / uiPageSize) || (apiPageSize / uiPageSize);
  const pagedData = data ? data.slice((currentPageForSlice - 1) * uiPageSize, currentPageForSlice * uiPageSize) : data;
  return pagedData;
}

export const getPagedDataSelector = (dataSelector: Selector<any, any[]>, currentPageSelector: Selector<any, number>, uiPageSize: number, apiPageSize: number) => createSelector(
  [dataSelector, currentPageSelector],
  (data, currentPage) => {
    return getPagedData(data, currentPage, uiPageSize, apiPageSize);
  }
);

