import RequestConfig from '../../app.config';
import fetchWrapper from '../FetchWrapper';
import { createUrlParametersString } from '../../utils/requestUtil';
import { API_PAGE_SIZE } from '../../store/requestcenter/constants';
import { commonResponseHandler, RESPONSE_TYPE } from '../../utils/responseUtils';

export const fetchRecipientTransactionCounts = async (filters: string, healthSystemId: number) => {
  const urlParams = createUrlParametersString({ healthSystemId: healthSystemId })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/discountrequests/counts?${filters}&${urlParams}`, true);
  return commonResponseHandler(response);
}

export const fetchRecipientTransactions = async (filters: string, sortBy: string, isSortAsc: boolean, pageToFetch: number, healthSystemId: number) => {
  let multiSort = ''
  if (sortBy === 'statusTextSort') {
    sortBy = ''
    multiSort = isSortAsc ? 'statusText asc, isAdjusted' : 'statusText desc, isAdjusted desc'
  }

  const urlParams = createUrlParametersString({ page: pageToFetch, pageSize: API_PAGE_SIZE, SortField: sortBy, SortDesc: !isSortAsc, multiSortField: multiSort, healthSystemId: healthSystemId })
  const apiUrl = `${await RequestConfig()}/request/v1/discountrequests?${urlParams}&${filters}`
  const response = await fetchWrapper(apiUrl, true);
  return commonResponseHandler(response);
};

export const postReverseTransaction = async (transactionIdList: number[], healthSystemId: number) => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/discountrequests/reverse?healthSystemId=${healthSystemId}`, true, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(transactionIdList)
  });
  return commonResponseHandler(response, RESPONSE_TYPE.JSON)
}