import React, { useState } from 'react';
import { Popup } from 'semantic-ui-react';

import { AddressModel } from '../../../store/applicationTypes';

export interface UserAddressProps {
  address: AddressModel;
  index: number;
}

export const UserAddress = (props: UserAddressProps) => {
  const { address, index } = props;

  const [isAddress1PopupOpen, setIsAddress1PopupOpen] = useState<boolean>(false);
  const [isAddress2PopupOpen, setIsAddress2PopupOpen] = useState<boolean>(false);
  const [isAddress3PopupOpen, setIsAddress3PopupOpen] = useState<boolean>(false);

  const checkWidth = (addressLine: number) => {
    const addressElement = document.getElementById(`address-${addressLine}-${index}`);

    if (addressElement) {
      if (addressElement.scrollWidth > addressElement.clientWidth) {
        if (addressLine === 1) {
          setIsAddress1PopupOpen(true);
        } else if (addressLine === 2) {
          setIsAddress2PopupOpen(true);
        } else {
          setIsAddress3PopupOpen(true);
        }
      }
    }
  };
  
  return (
    <>
      <Popup
        className="team-management-address popup-container"
        content={address.address1}
        hoverable
        inverted
        onOpen={() => checkWidth(1)}
        onClose={() => setIsAddress1PopupOpen(false)}
        open={isAddress1PopupOpen}
        position='top left'
        size='tiny'
        trigger= {
          <div className="team-management-address content" id={`address-1-${index}`}>
            {address.address1}
          </div>
        }
      />

      <Popup
        className="team-management-address popup-container"
        content={address.address2}
        hoverable
        inverted
        onOpen={() => checkWidth(2)}
        onClose={() => setIsAddress2PopupOpen(false)}
        open={isAddress2PopupOpen}
        position='top left'
        size='tiny'
        trigger= {
          <div className="team-management-address content" id={`address-2-${index}`}>
            {address.address2}
          </div>
        }
      />

      <Popup
        className="team-management-address popup-container"
        content={`${address.city} ${address.state} ${address.zip}`}
        hoverable
        inverted
        onOpen={() => checkWidth(3)}
        onClose={() => setIsAddress3PopupOpen(false)}
        open={isAddress3PopupOpen}
        position='top left'
        size='tiny'
        trigger= {
          <div className="team-management-address content" id={`address-3-${index}`}>
            {address.city} {address.state} {address.zip}
          </div>
        }
      />
    </>
  );
};

export default UserAddress;