import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import cx from 'classnames';
import CloseButton from '../../CloseButton';

import '../../../../themes/kalderos/Common/Drawer/Components/Header.scss';

export interface HeaderProps {
  header: string;
  subHeader: string;
  headerLabel?: React.ReactNode
  isPrevDisabled: boolean;
  isNextDisabled: boolean;
  onPrevTransactionClick: () => void;
  onNextTransactionClick: () => void;
  onClose: () => void;
}

export const Header: React.FunctionComponent<HeaderProps> = (props: HeaderProps) => {
  return (
    <div className="drawer-header">
      <div className="navigation-buttons-container">
        <div>
          <button data-testid='prevButton' disabled={props.isPrevDisabled} className={cx('navigation-button', {'disabled': props.isPrevDisabled})} type="button" onClick={props.onPrevTransactionClick}>
            <ChevronLeft size={24}/>
          </button>
          <button data-testid='nextButton' disabled={props.isNextDisabled} className={cx('navigation-button', {'disabled': props.isNextDisabled})} type="button" onClick={props.onNextTransactionClick}>
            <ChevronRight size={24}/>
          </button>
        </div>
        <CloseButton data-testid='closeButton' size={24} onClick={props.onClose} />
      </div>
      <div className="header-text">
        <div  className="value">{props.header}</div>
        <div className='value-header'>{props.headerLabel}</div>
      </div>
      <div className="attribute-label">{props.subHeader}</div>
    </div>
  )
}

export default Header;
