import React from 'react';
import { Mail } from 'react-feather';

const ForgotPasswordSuccess = (props: { email: string, onReset: () => void, onClose: () => void }) => {
  return (
    <div className="login-page forgot-password-success">
      <div className="login-page-layout">
        <div className="login-page-column-right">
          <div className="login-form-container">
            <div className="login-form-header">Check your email inbox</div>
            <div className="login-form-body">
              <div className="message-box success-box">
                <Mail size={20} />
                <div className="message-box-text">If your email is in our system, you’ll receive an email from us. </div>
              </div>
              <div>If you cannot find the email, check your spam folder. Please allow up to five minutes for the email to arrive.</div>
              <div className="request-another-link">Still don’t see an email? <button className="link-button" type="button" onClick={props.onReset}>Request another link.</button></div>
            </div>
            <div className="login-form-footer">
              <button type="submit" className="app-button cta" onClick={props.onClose}>Return to Sign-In</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordSuccess
