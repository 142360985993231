import LogRocket from 'logrocket';
import { ErrorModel } from '../store/applicationTypes';

export default function logRocketUtil (err: any) {
  const errorModel: ErrorModel = {
    message: err.message || 'generic error - error message missing',
    traceId: err.traceId,
    statusCode: err.statusCode
  }
  LogRocket.track('error')
  LogRocket.captureException(err, {
    extra: {
      traceId: err.traceId,
      errorMsg: err.message
    }
  })
  return errorModel
}
