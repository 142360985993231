import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store/RootState';
import { Modal, Button, Checkbox, Dimmer, Loader, Input } from 'semantic-ui-react';
import cx from 'classnames';
import { TeamMember, CoveredEntity } from '../../../store/teammanagement/types';
import { RECIPIENT_TEAM_MANAGEMENT_USER_ROLES } from '../../../store/teammanagement/constants';
import { assignCeUsers, closeEditTeamModal } from '../../../store/teammanagement/actionCreators';
import { ErrorBanner } from '../../common/errors/ErrorBanner';

import '../../../themes/kalderos/TeamManagement/EditTeamModal.scss';

export interface EditTeamModalProps {
  members: TeamMember[],
  coveredEntity: CoveredEntity,
  isOpen: boolean,
  isLoading: boolean,
  errorText: string,
  assignCeUsers: (ceId: number, userIds: number[]) => void;
  closeEditTeamModal: () => void;
}

interface MemberModel {
  id: number,
  label: string,
  active: boolean,
  checked: boolean,
  disabled: boolean,
}

export const EditTeamModal: React.FunctionComponent<EditTeamModalProps> = (props: EditTeamModalProps) => {

  const [selectedIds, setSelectedIds] = useState<number[]>(props.coveredEntity.userIdsAssigned);
  const [searchText, setSearchText] = useState<string>('');
  const [isDirty, setIsDirty] = useState<boolean>(false);

  React.useEffect(() => {
    setSelectedIds(props.coveredEntity.userIdsAssigned)
  }, [props.coveredEntity]);

  React.useEffect(() => {
    setSearchText('')
    setIsDirty(false);
  }, [props.isOpen]);

  function handleMemberChange (member: MemberModel) {

    if (!member.disabled) {
      const i = selectedIds.indexOf(member.id);
      const newList = [...selectedIds];
      if (i >= 0) {
        newList.splice(i, 1);
      } else {
        newList.push(member.id);
      }
      setSelectedIds(newList);
      setIsDirty(true);
    }
  }

  function handleSearchChange (text: string) {
    setSearchText(text);
  }

  function handleCancelClick () {
    props.closeEditTeamModal();
  }

  function handleSubmitClick () {
    props.assignCeUsers(props.coveredEntity.coveredEntityId, selectedIds);
  }

  function mapMembers (members: TeamMember[]): MemberModel[] {
    return members.map(m => ({
      id: m.id,
      active: m.isActive,
      label: m.isActive ? `${m.firstName} ${m.lastName}` : `${m.email} [pending]`,
      checked: selectedIds.indexOf(m.id) >= 0,
      disabled: m.role === RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN || m.role === RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.PAYMENT
    }));
  }

  function getMembersList () {
    let mappedMembers: MemberModel[] = [];

    const selected = props.members.filter(m => props.coveredEntity!.userIdsAssigned.indexOf(m.id) >= 0);
    mappedMembers.push(...mapMembers(selected.filter(m => m.isActive)));
    mappedMembers.push(...mapMembers(selected.filter(m => !m.isActive)));

    const notSelected = props.members.filter(m => props.coveredEntity!.userIdsAssigned.indexOf(m.id) < 0);
    mappedMembers.push(...mapMembers(notSelected.filter(m => m.isActive)));
    mappedMembers.push(...mapMembers(notSelected.filter(m => !m.isActive)));

    if (searchText) {
      mappedMembers = mappedMembers.filter(m => m.label.toLowerCase().includes(searchText.toLowerCase()));
    }

    return mappedMembers;
  }

  return (
    <Modal className="team-management-modal edit-team-modal" open={props.isOpen}>
      <div className="team-management-modal-header">Edit Team</div>
      {props.isLoading && <Dimmer className="dimmer" active inverted><Loader inverted /></Dimmer>}
      {props.errorText &&
        <ErrorBanner
          errorText={'An error occurred while updating team members'}
        />}
      <div className="team-management-modal-body">
        <div className="edit-team-name">{props.coveredEntity.entityName}</div>
        <Input type="text"
          className="edit-team-search"
          placeholder="Search..."
          onChange={(e, d) => handleSearchChange(d.value)}
        />
        <div className="edit-team-list">
          {getMembersList().map(m => {
            return (
              <div key={m.id} className={cx('edit-member-row', { 'disabled': m.disabled }, { 'pending': !m.active })} onClick={() => handleMemberChange(m)}>
                <Checkbox
                  label={m.label}
                  checked={m.checked}
                  disabled={m.disabled}
                />
              </div>
            )
          })}
        </div>
        <div className="edit-team-count">{selectedIds.length} {selectedIds.length === 1 ? 'user' : 'users'} selected</div>
      </div>
      <div className="team-management-modal-footer">
        <Button type="button" className="app-button button-white" onClick={handleCancelClick}>Cancel</Button>
        <Button type="submit" className="app-button button-dark" onClick={handleSubmitClick} disabled={!isDirty}>Submit</Button>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state: RootState) => ({
  members: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.activeMembers,
  coveredEntity: state.TeamManagementState.editTeamModal.coveredEntity,
  isOpen: state.TeamManagementState.editTeamModal.isOpen,
  isLoading: state.TeamManagementState.editTeamModal.isLoading,
  errorText: state.TeamManagementState.editTeamModal.errorText,
});

const mapDispatchToProps = (dispatch: any) => ({
  assignCeUsers: (ceId: number, userIds: number[]) => dispatch(assignCeUsers(ceId, userIds)),
  closeEditTeamModal: () => dispatch(closeEditTeamModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTeamModal);
