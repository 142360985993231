import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Loader, Modal } from 'semantic-ui-react';
import { RootState } from '../../../store/RootState';
import { ErrorModel } from '../../../store/teammanagement/types';
export interface DisableUserModalProps {
  open: boolean;
  userId: number;
  waiting: boolean;
  isError: ErrorModel;
  closeModal: () => void;
  disableUser: (data: any) => void;
}

export const DisableUserModal: React.FunctionComponent<DisableUserModalProps> = ({ open, closeModal, userId, disableUser, waiting, isError }) => {
  return (
    <Modal className="app-modal medium-modal"
      open={open}>
      {
        isError?.isError ?
          <div className="app-modal-header">Error</div>
          :
          <div className="app-modal-header">Disable User</div>
      }
      {
        waiting ?
          <Loader className="modal-loader" active />
          :
          isError?.isError ?
            <>
              <div className="app-modal-content disable-user-modal">
                <p className="cancel-text">Unable to disable user. This user is a lead contact for a covered entity.</p>
              </div>
              <div className="app-modal-btn-container">
                <Button id="disableUserCancBtn_Pendo" onClick={closeModal} className="app-button button-white app-modal-cancel-button">
                  Ok
                </Button>
              </div>
            </>
            :
            <>
              <div className="app-modal-content disable-user-modal">
                <p className="cancel-text">You are about to disable a user. You can reactivate this user later.</p>
              </div>
              <div className="app-modal-btn-container">
                <Button onClick={closeModal} className="app-button button-white app-modal-cancel-button">
                  Cancel
                </Button>
                <Button onClick={() => disableUser(userId)} className="app-button button-dark app-modal-submit-button">
                  Disable User
                </Button>
              </div>
            </>
      }
    </Modal>
  )
};

const mapStateToProps = (state: RootState) => ({
  isError: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.isError,
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(DisableUserModal);