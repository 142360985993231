import { AcceptInviteInput, ValidateInviteInput } from './types';
import { UserModel, LoginModel, ValidateInviteResponse } from '../login/types';
import { ErrorModel } from '../applicationTypes';

export const VALIDATE_RESET_PASSWORD_TOKEN = 'VALIDATE_RESET_PASSWORD_TOKEN';
export const VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS = 'VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const RESET_CHANGE_PASSWORD_STATUS = 'RESET_CHANGE_PASSWORD_STATUS';

export const SEND_FORGOT_PASSWORD = 'SEND_FORGOT_PASSWORD';
export const SEND_FORGOT_PASSWORD_SUCCESS = 'SEND_FORGOT_PASSWORD_SUCCESS';
export const SEND_FORGOT_PASSWORD_ERROR = 'SEND_FORGOT_PASSWORD_ERROR';
export const RESET_FORGOT_PASSWORD = 'RESET_FORGOT_PASSWORD';

export const VALIDATE_INVITE = 'VALIDATE_INVITE';
export const VALIDATE_INVITE_SUCCESS = 'VALIDATE_INVITE_SUCCESS';
export const VALIDATE_INVITE_FAILURE = 'VALIDATE_INVITE_FAILURE';

export const ACCEPT_INVITE = 'ACCEPT_INVITE';
export const ACCEPT_INVITE_SUCCESS = 'ACCEPT_INVITE_SUCCESS';
export const ACCEPT_INVITE_FAILURE = 'ACCEPT_INVITE_FAILURE';

export const LINK_NOT_VALID_ERROR_MESSAGE = 'This link is not valid';
export const PLEASE_TRY_AGAIN_ERROR_MESSAGE = 'Error - Please try again';
export const CREATING_ACCOUNT_ERROR_MESSAGE = 'Error creating account. Please try again';

interface ValidateResetPasswordToken {
  type: typeof VALIDATE_RESET_PASSWORD_TOKEN,
  payload: {
    token: string,
    healthSystemId: number | null
  }
}

interface ValidateResetPasswordTokenSuccess {
  type: typeof VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS,
  payload: UserModel
}

interface ResetPassword {
  type: typeof RESET_PASSWORD,
  payload: LoginModel
}

interface ResetPasswordSuccess {
  type: typeof RESET_PASSWORD_SUCCESS,
}

interface ResetPasswordError {
  type: typeof RESET_PASSWORD_ERROR,
  payload: ErrorModel
}

interface ChangePassword {
  type: typeof CHANGE_PASSWORD,
  payload: any
}

interface ChangePasswordError {
  type: typeof CHANGE_PASSWORD_ERROR,
  payload: ErrorModel
}

interface ChangePasswordSuccess {
  type: typeof CHANGE_PASSWORD_SUCCESS,
}

interface ResetChangePasswordStatus {
  type: typeof RESET_CHANGE_PASSWORD_STATUS,
}

interface SendForgotPassword {
  type: typeof SEND_FORGOT_PASSWORD,
  payload: string
}

interface SendForgotPasswordSuccess {
  type: typeof SEND_FORGOT_PASSWORD_SUCCESS,
}

interface SendForgotPasswordError {
  type: typeof SEND_FORGOT_PASSWORD_ERROR
  payload: ErrorModel
}

interface ResetForgotPassword {
  type: typeof RESET_FORGOT_PASSWORD
}

interface ValidateInviteAction {
  type: typeof VALIDATE_INVITE,
  payload: ValidateInviteInput
}

interface ValidateInviteSuccessAction {
  type: typeof VALIDATE_INVITE_SUCCESS,
  payload: ValidateInviteResponse
}

interface ValidateInviteFailureAction {
  type: typeof VALIDATE_INVITE_FAILURE,
  payload: ErrorModel
}

interface AcceptInviteAction {
  type: typeof ACCEPT_INVITE,
  payload: AcceptInviteInput
}

interface AcceptInviteSuccessAction {
  type: typeof ACCEPT_INVITE_SUCCESS,
}

interface AcceptInviteFailureAction {
  type: typeof ACCEPT_INVITE_FAILURE,
  payload: ErrorModel
}

export type PasswordTypes = ValidateResetPasswordToken | ValidateResetPasswordTokenSuccess
| ResetPassword | ResetPasswordSuccess | ResetPasswordError
| ChangePassword | ChangePasswordError | ChangePasswordSuccess | ResetChangePasswordStatus
| SendForgotPassword | SendForgotPasswordSuccess | SendForgotPasswordError | ResetForgotPassword
| ValidateInviteAction | ValidateInviteSuccessAction | ValidateInviteFailureAction
| AcceptInviteAction | AcceptInviteSuccessAction | AcceptInviteFailureAction;
