import React from 'react';
import { connect } from 'react-redux'
import { CloseIconAlt } from '../../../themes/kalderos/Common/Icons';
import { Modal, ModalContent, Button, Dimmer, Loader } from 'semantic-ui-react';
import { RootState } from '../../../store/RootState';
import { initiateMicroDeposits } from '../../../store/payments/common/actionCreators';

interface InitiateMicroDepositsProps {
  sourceId: number;
  isMicroDepositLoading: boolean;
  initiateMicroDeposits: (sourceId: number, isRetry: boolean) => void;
}
interface InitiateMicroDepositsState {
  isModalOpen: boolean;
}

export class InitiateMicroDeposits extends React.Component<InitiateMicroDepositsProps, InitiateMicroDepositsState>{
  constructor (props: Readonly<InitiateMicroDepositsProps>) {
    super(props);
    this.state = {
      isModalOpen: false
    };
  }

  handleModalClose = () => {
    this.setState({ isModalOpen: false });
  }

  initiateMicroDeposits = () => {
    this.props.initiateMicroDeposits(this.props.sourceId, true)
    this.setState({ isModalOpen: false });
  }

  render () {
    const isMicroDepositLoading = this.props;
    return (
      <div>
        <Button className="app-link blue-link underline link-button" onClick={() => this.setState({ isModalOpen: true })}>I didn&apos;t receive microdeposits.</Button>
        <Modal id="mynav" open={this.state.isModalOpen} size={'large'} >
          <ModalContent>
            <div className="flex-column microdeposit-modal-container" >
              <button data-testid='modalClose' className='modal-close-x-icon cursor-pointer' type="button" onClick={this.handleModalClose} >
                <CloseIconAlt />
              </button>
              <div className='beta microdeposit-modal-header'>I didn&apos;t receive microdeposits</div>
              <div className='delta microdeposit-modal-message'>If it has been 3 days after adding your linked financial account and you have not received two deposits of 10¢ or less, you may retry below.</div>
              <div className='delta microdeposit-modal-message'>Please allow 3 days after retry for new microdeposits to reach your account. Note the amount and return in order to finish verifying your account.</div>
              {isMicroDepositLoading && <Dimmer active inverted><Loader inverted content='Loading' /></Dimmer> && <button className='app-button button-dark microdeposit-btn' onClick={this.initiateMicroDeposits} type="button">Retry Microdeposits</button>}
            </div></ModalContent>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  isMicroDepositLoading: state.PaymentsState.common.initiateMicroDeposit.isLoading
});

const mapDispatchToProps = (dispatch: any) => ({
  initiateMicroDeposits: (sourceId: number, isRetry: boolean) => dispatch(initiateMicroDeposits(sourceId, isRetry))
});

export default connect(mapStateToProps, mapDispatchToProps)(InitiateMicroDeposits);
