import React from 'react';
import { Dimmer, Loader, Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { RootState } from '../../../store/RootState';
import { RECIPIENT_PAYMENT_SETTINGS_TABLE_HEADERS } from '../../../store/payments/recipient/constants'
import { getPaymentSummary, updatePaymentsSummaryTableSortFilters, updatePaymentsSummaryError } from '../../../store/payments/common/actionCreators';
import { SortingFilter } from '../../../store/appcommon/types';
import { FundingSourcesModel } from '../../../store/payments/common/types';
import { RecipientBasicTable } from './index';
import EmptyTransactions from '../../common/transactions/EmptyTransactions';
import { DR_NO_LINKED_ACCOUNTS_HEADER, DR_NO_LINKED_ACCOUNTS_MESSAGE } from '../../../store/appcommon/constants';
import RestrictedByRole from '../../teammanagement/RestrictedByRole';
import { RECIPIENT_TEAM_MANAGEMENT_USER_ROLES } from '../../../store/teammanagement/constants';
import AddFundingSourceModal from '../../common/dwolla/AddFundingSourceModal';
import { PaymentAlertModel } from '../../../store/payments/common/types';
import { getPaymentAlertInfo} from '../../../store/payments/common/actionCreators';
import { InfoBanner } from '../../common/errors/InfoBanner';
import { ErrorBanner } from '../../common/errors/ErrorBanner';
import { SUPPORT_EMAIL, SUPPORT_EMAIL_LINK } from '../../../store/applicationConstants';
import { InformationalModal } from '../../common/modals/InformationalModal';

export interface PaymentSettingsProps {
  isPaymentSummaryLoading: boolean;
  errorStatus: number | null;
  fundingSources: FundingSourcesModel[];
  paymentAlert: PaymentAlertModel;
  removalErrorStatus: number | null;
  removalErrorAccount: FundingSourcesModel;
  onboardingCompleted: boolean;
  updateRecipientPaymentsSummarySortFilters: (sortFilters: SortingFilter) => void;
  getPaymentSummary: () => void;
  getPaymentAlertInfo:() => void;
  updatePaymentsSummaryError: (errorStatus: number | null, errorAccount: any) => void;

}

export class PaymentSettings extends React.Component<PaymentSettingsProps>{

  componentDidMount () {
    this.props.getPaymentSummary();
    this.props.getPaymentAlertInfo();
  }

  componentWillUnmount () {
    this.props.updatePaymentsSummaryError(null, {});
  }

  handleSortColumn = (sort: SortingFilter) => {
    this.props.updateRecipientPaymentsSummarySortFilters(sort)
  }

  onModalClose = () => {
    this.props.getPaymentSummary();
  }

  closePendingTransferErrorModal = () => {
    this.props.updatePaymentsSummaryError(null, {});
  }

  render () {
    const { fundingSources, isPaymentSummaryLoading, paymentAlert, removalErrorStatus, removalErrorAccount, onboardingCompleted } = this.props;

    const showError = !isPaymentSummaryLoading && fundingSources.length === 0 && paymentAlert?.description?.length > 0;
    const hasAchError = paymentAlert?.achReturnCode?.length > 0;
    const showInfoBanner = !isPaymentSummaryLoading && paymentAlert?.description?.length > 0 && fundingSources.length > 0;
    const addAccountEnabled = fundingSources.length === 0 && onboardingCompleted;
    let popupContent = '';
    if (!onboardingCompleted) {
      popupContent = 'Linked accounts cannot be added until your business has completed onboarding.';
    } else if (fundingSources.length > 0) {
      popupContent = 'The maximum amount (1) of linked financial accounts has been reached. Remove an existing linked financial account before adding another.';
    }

    return (
      <div className="recipient-payment-settings">

        {showInfoBanner &&
        <div className="banner">
          <InfoBanner
            title={'You have one or more payments waiting for you.'}
            message={'Initiate payment retry below. Allow 1-4 business days for transfer of funds.'}
          />
        </div>
        }

        {showError &&
        (hasAchError ?
          <div className="banner">
            <ErrorBanner
              data-testid='achError'
              title={'There is a problem with your linked financial account.'}
              errorContent={<span>We can no longer connect to {paymentAlert.fundingSourceName}. Click &quot;+ Add Account&quot; to link account or contact Kalderos support at <a href={SUPPORT_EMAIL_LINK}>{SUPPORT_EMAIL}</a>.</span>}
            />
          </div> :
          <div className="banner">
            <ErrorBanner
              data-testid='genericError'
              title={'There is a problem with your linked financial account.'}
              errorContent={<span>Your financial account is no longer connected.  Add a new account below or contact Kalderos support at <a href={SUPPORT_EMAIL_LINK}>{SUPPORT_EMAIL}</a>.</span>}
            />
          </div>)
        }

        {isPaymentSummaryLoading && <Dimmer active inverted><Loader inverted content='Loading' /></Dimmer>}

        <div className="recipient-payment-body">
          <div className="flex-row linked-accounts-header">
            <div className="gamma">Linked Accounts</div>
            <RestrictedByRole allowedRoles={[RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.PAYMENT, RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN]}>
              {addAccountEnabled ? <AddFundingSourceModal
                modalTriggerText={'+ Add Account'}
                modalTriggerStyles={'ghost'}
                onClose={this.onModalClose}
              /> :
                <Popup
                  inverted
                  position='top right'
                  size='tiny'
                  wide='very'
                  trigger={ <div className="add-account-tooltip">+ Add Account</div> }
                  content={popupContent}
                />
              }
            </RestrictedByRole>
          </div>

          <RecipientBasicTable
            data={fundingSources}
            headers={RECIPIENT_PAYMENT_SETTINGS_TABLE_HEADERS}
            table="paymentSettings"
            handleSortClick={this.handleSortColumn}
            showRetry={paymentAlert?.description.length > 0}
          />
          {fundingSources.length === 0 && !isPaymentSummaryLoading && <EmptyTransactions emptyTransactionsHeader={DR_NO_LINKED_ACCOUNTS_HEADER} emptyTransactionsMessage={DR_NO_LINKED_ACCOUNTS_MESSAGE} />}
          {removalErrorStatus === 500 && removalErrorAccount && <InformationalModal header='Pending Transfer from Funding Source' message={`Funding source ${removalErrorAccount.name} ${removalErrorAccount.bankName} cannot be removed due to a pending transfer. Try removing the funding source after all transfers have processed`} handleModalClose={this.closePendingTransferErrorModal} />}
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state: RootState) => ({
  fundingSources: state.PaymentsState.common.paymentSettings.fundingSources,
  isPaymentSummaryLoading: state.PaymentsState.common.paymentSettings.isLoading,
  errorStatus: state.PaymentsState.common.paymentSettings.errorStatus,
  paymentAlert: state.PaymentsState.common.paymentAlert,
  removalErrorStatus: state.PaymentsState.common.paymentSettings.errorStatus,
  removalErrorAccount: state.PaymentsState.common.paymentSettings.errorAccount,
  onboardingCompleted: state.OnboardingState.onboardingStatusModel.onboardingCompleted,
});



const mapDispatchToProps = (dispatch: any) => ({
  updateRecipientPaymentsSummarySortFilters: (sortFilters: SortingFilter) => dispatch(updatePaymentsSummaryTableSortFilters(sortFilters)),
  getPaymentSummary: () => dispatch(getPaymentSummary()),
  getPaymentAlertInfo: () => dispatch(getPaymentAlertInfo()),
  updatePaymentsSummaryError: (errorStatus: number | null, errorAccount: FundingSourcesModel) => dispatch(updatePaymentsSummaryError(errorStatus, errorAccount)),
});


export default connect(mapStateToProps, mapDispatchToProps)(PaymentSettings)
