import React from 'react';
import cx from 'classnames';

import '../../themes/kalderos/Common/Drawer/Components/StatusLabel.scss';

interface StatusLabelProps {
  color: string;
  text: string;
}

const StatusLabel: React.FunctionComponent<StatusLabelProps> = (props: StatusLabelProps) => {
  return (
    <span className={cx('status-label', props.color)}>{props.text}</span>
  )
}

export default StatusLabel;