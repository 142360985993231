import React from 'react';

interface TransactionLabelProps {
  statusText: string,
  isAdjusted: boolean
}

const TransactionLabel: React.FunctionComponent<TransactionLabelProps> = ({ statusText, isAdjusted }) => {
  if (statusText === 'Paid' && isAdjusted) {
    return <span className='labels'><span className='recipient-transaction-label paid-adjust'>Paid</span><span className='recipient-transaction-label adjust-paid'>Adjusted</span></span>
  }

  let displayStatusText = statusText;
  const lowerCaseStatusText = statusText?.toLowerCase()?.replace(' ', '-');

  if (lowerCaseStatusText === 'in-mfr review' || lowerCaseStatusText === 'review') {
    displayStatusText = 'In Mfr. Review';
  }

  return <span className={`recipient-transaction-label ${lowerCaseStatusText} labels`}>{displayStatusText}</span>
}

export default TransactionLabel;