import React from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';

import { RootState } from '../../store/RootState';
import '../../themes/kalderos/Onboarding/Welcome.scss';
import KalderosLogo from '../../themes/kalderos/static/media/Kalderos_Wordmark_GreyDarker.svg';

import { makeSelectOnboardingRole } from '../../store/onboarding/selectors';
import { allSteps } from './constants';

interface WelcomeProps {
  onboardingRole: string,
  handleNextClick: () => void;
}

interface Steps {
  roleName: string,
  steps: [],
  inviteMessage: string
}

export const Steps = ({ steps, handleClick }: { steps: string[], handleClick: () => void }) => {
  const ListItems = steps.map((step, index) =>
    <li key={index}>{step}</li>);
  return (
    <div className="steps">
      <div className="steps-heading">
        Let&apos;s set up your account.
      </div>
      <div className="steps-list">
        <ol>
          {ListItems}
        </ol>
      </div>
      <button type="button" className="app-button button-dark steps-button" onClick={handleClick}>
        Set Up My Account
      </button>
    </div>
  )
}

export class Welcome extends React.Component<WelcomeProps>{

  render () {
    const { onboardingRole, handleNextClick } = this.props;
    if (!onboardingRole) {
      return <div>error</div>
    }
    const roleSteps = find(allSteps, { 'roleName': onboardingRole }) as Steps;
    const steps = roleSteps ? roleSteps.steps : [];
    const inviteMessage = roleSteps ? roleSteps.inviteMessage : '';
    return (
      <div className="welcome-container">
        <div className="welcome-message-container">
          <img src={KalderosLogo} alt="Kalderos" className="logo" />
          Welcome to <br /> Kalderos Request
          <p>{inviteMessage}</p>
        </div>
        <div className="steps-container">
          <Steps steps={steps} handleClick={handleNextClick} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  onboardingRole: makeSelectOnboardingRole(state),
});


export default connect(mapStateToProps)(Welcome);
