import RequestConfig from '../../app.config';
import fetchWrapper from '../FetchWrapper';
import { VerifyMicroDepositsModel } from '../../store/payments/common/types';
import { commonResponseHandler, RESPONSE_TYPE } from '../../utils/responseUtils';
import { createUrlParametersString } from '../../utils/requestUtil';

export const fundingSourceAmountUpdate = async (amount: string, sourceId: number) => {
  const amountInt = parseFloat(amount)
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/customer/depositFunds`, true,
    {
      method: 'POST',
      body: JSON.stringify({
        amount: amountInt,
        selectedFundingSourceId: sourceId
      }),
      headers: {
        'Content-Type' : 'application/json'
      }
    }
  );

  return commonResponseHandler(response);

}

export const withdrawFundsUpdate = async (amount: string, sourceId: number) => {
  const amountInt = parseFloat(amount)
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/customer/withdrawFunds `, true,
    {
      method: 'POST',
      body: JSON.stringify({
        amount: amountInt,
        selectedFundingSourceId: sourceId
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  return commonResponseHandler(response);
}


export const verifyMicroDeposits = async (verifyMicroDepositsModel: VerifyMicroDepositsModel, healthSystemId?: number) => {
  const urlParams = healthSystemId ? createUrlParametersString({ healthSystemId: healthSystemId }) : '';
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/customer/verifyMicroDeposits?${urlParams}`, true,
    {
      method: 'POST',
      body: JSON.stringify(verifyMicroDepositsModel),
      headers: {
        'Content-Type' : 'application/json'
      }
    }
  )
  return response
}

export const removeFundingSource = async (fundingSourceId: number, healthSystemId?: number) => {
  const urlParams = healthSystemId ? createUrlParametersString({ fundingSourceId: fundingSourceId, healthSystemId: healthSystemId }) : createUrlParametersString({ fundingSourceId: fundingSourceId });
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/customer/removeFundingSource?${urlParams}`, true,
    {
      method: 'POST',
    }
  )
  return response;
}

export const fetchFundingSourceToken = async (healthSystemId?: number) => {
  const urlParams = healthSystemId ? createUrlParametersString({ healthSystemId: healthSystemId }) : '';
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/customer/fundingSourceToken?${urlParams}`, true);
  return commonResponseHandler(response, RESPONSE_TYPE.TEXT);
}

export const fetchDwollaBalance = async () => {
  try {
    const response = await fetchWrapper(`${await RequestConfig()}/request/v1/customer/balance`, true)
    const data = await response.json();
    return ({
      dwollaBalance: data
    });
  }
  catch (e) {
    throw new Error('Error in fetching Payment Summary')
  }
}

export const addFundingSourceRequest = async (fundingSourceId: string, healthSystemId?: number) => {
  const urlParams = healthSystemId ? createUrlParametersString({ fundingSourceId: fundingSourceId, healthSystemId: healthSystemId }) : createUrlParametersString({ fundingSourceId: fundingSourceId });
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/customer/addFundingSource?${urlParams}`, true,
    {
      method: 'POST',
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.NONE);
}

export const fetchDwollaEnvironment = async () => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/config/paymentprovider/environment`, true);
  return commonResponseHandler(response, RESPONSE_TYPE.TEXT);
}

export const fetchControllerInfoRequest = async (healthSystemId?: number) => {
  const urlParams = healthSystemId ? createUrlParametersString({ healthSystemId: healthSystemId }) : '';
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/customer/controller?${urlParams}`, true,
    {
      method: 'GET',
    }
  );
  return commonResponseHandler(response);
}

export const fetchAccountAdminInfoRequest = async (healthSystemId?: number) => {
  const urlParams = healthSystemId ? createUrlParametersString({ healthSystemId: healthSystemId }) : '';
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/customer/accountAdmin?${urlParams}`, true,
    {
      method: 'GET',
    }
  );
  return commonResponseHandler(response);
}

export const fetchFundingSources = async (sortBy: string, isSortDesc: boolean, healthSystemId?: number) => {
  const urlParams = healthSystemId ? createUrlParametersString({ SortField: sortBy, SortDesc: isSortDesc, healthSystemId: healthSystemId }) : createUrlParametersString({ SortField: sortBy, SortDesc: isSortDesc })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/customer/fundingSources?${urlParams}`, true, {
    method: 'GET'
  });
  return commonResponseHandler(response)
}

export const fetchPaymentAlert = async (healthSystemId?: number) => {
  const urlParams = healthSystemId ? createUrlParametersString({ healthSystemId: healthSystemId }) : '';
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/customer/paymentAlert?${urlParams}`, true)
  return commonResponseHandler(response);
}

export const initiateMicroDepositsRequest = async (sourceId: number, healthSystemId?: number) => {
  const urlParams = healthSystemId ? createUrlParametersString({ paymentProviderFundingSourceId: sourceId, healthSystemId: healthSystemId }) :  createUrlParametersString({ paymentProviderFundingSourceId: sourceId });
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/customer/initiateMicroDeposits?${urlParams}`, true,
    {
      method: 'POST',
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.NONE);
}

