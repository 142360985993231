import React from 'react';
import { AlertCircle } from 'react-feather';

import '../../../themes/kalderos/Common/InfoBanner.scss';

export interface InfoBannerProps{
  title?: string;
  message?: string
  messageWithNode?: React.ReactNode
}

export const InfoBanner: React.FunctionComponent<InfoBannerProps> = ({title, message, messageWithNode}) => {
  return (
    <div className="info-banner">
      <div className="banner-contents">
        <div className="icon-wrapper">
          <AlertCircle />
        </div>
        <div>
          {title && <div className="title"><b>{title}</b></div>}
          <div className="contents">{message || messageWithNode}</div>
        </div>
      </div>
    </div>
  )
}
