import * as React from 'react';

export const KalderosIcon = () => <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M32 0H18.2435L10.9968 10.2176V0H7.5487V21.0398L20.0227 3.45246H25.3247L10.3636 24.5508H3.44805V0H0V28H12.1429L15.1948 23.6991L18.2435 28H32L22.0714 14.0016L32 0ZM25.3247 24.5508H20.0227L17.3052 20.718L19.9545 16.9795L25.3247 24.5508Z" fill="#DCDFE6" />
</svg>

export const RequestIcon = () => <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M30 0H10C4.47715 0 0 4.47715 0 10V30C0 35.5228 4.47715 40 10 40H30C35.5228 40 40 35.5228 40 30V10C40 4.47715 35.5228 0 30 0Z" fill="#1BDFC7" />
  <path d="M22.9 20.42L29.2 14.43L22.9 8.45001V12.23H9.48999V16.63H22.9V20.42Z" fill="url(#paint0_linear)" />
  <path d="M17.1 19.58L10.8 25.57L17.1 31.55V27.77H30.51V23.37H17.1V19.58Z" fill="url(#paint1_linear)" />
  <defs>
    <linearGradient id="paint0_linear" x1="9.48999" y1="14.43" x2="29.2" y2="14.43" gradientUnits="userSpaceOnUse">
      <stop stopColor="#1BDFC7" />
      <stop offset="0.86" stopColor="white" />
    </linearGradient>
    <linearGradient id="paint1_linear" x1="10.8" y1="25.57" x2="30.51" y2="25.57" gradientUnits="userSpaceOnUse">
      <stop offset="0.18" stopColor="white" />
      <stop offset="1" stopColor="#1BDFC7" />
    </linearGradient>
  </defs>
</svg>

export const RequestLogoIcon = () => <svg xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" width="122.96" height="40" viewBox="0 0 122.96 40">
  <defs>
    <linearGradient id="a" x1="9.39" y1="14.43" x2="29.1" y2="14.43" gradientUnits="userSpaceOnUse">
      <stop offset="0" stopColor="#1bdfc7" />
      <stop offset="0.86" stopColor="#fff" />
    </linearGradient>
    <linearGradient id="b" x1="10.7" y1="25.57" x2="30.41" y2="25.57" gradientUnits="userSpaceOnUse">
      <stop offset="0.18" stopColor="#fff" />
      <stop offset="1" stopColor="#1bdfc7" />
    </linearGradient>
  </defs>
  <title>Request_Lockup_Left_REV</title>
  <g>
    <g>
      <path d="M49.9,12.9h5.38c3.42,0,4.94,2,4.94,4.72a4.11,4.11,0,0,1-3,4.28l3.56,5.2H57.48L54.3,22.24H52.68V27.1H49.9Zm4.82,6.86c1.72,0,2.6-.88,2.6-2.18s-.86-2.18-2.48-2.18H52.68v4.36Z" fill="#fff" />
      <path d="M71.6,23.06H64.74a2.49,2.49,0,0,0,2.58,1.88,2.92,2.92,0,0,0,2.38-1.1l1.7,1.58a5,5,0,0,1-4.26,1.86A4.85,4.85,0,0,1,62,22.2a4.77,4.77,0,0,1,5-5,4.63,4.63,0,0,1,4.68,4.94C71.66,22.44,71.62,22.9,71.6,23.06Zm-6.84-1.88h4.3A2.06,2.06,0,0,0,67,19.46,2.18,2.18,0,0,0,64.76,21.18Z" fill="#fff" />
      <path d="M80.76,26h0a3.62,3.62,0,0,1-2.92,1.28c-2.68,0-4.66-2-4.66-5s2-5.06,4.6-5.06a3.8,3.8,0,0,1,3,1.28h0V17.32h2.62V31.06H80.76Zm.1-3.74a2.55,2.55,0,0,0-2.54-2.74,2.52,2.52,0,0,0-2.5,2.74,2.47,2.47,0,0,0,2.5,2.66A2.51,2.51,0,0,0,80.86,22.26Z" fill="#fff" />
      <path d="M85.74,23.22v-5.9h2.6v5.22c0,1.78.54,2.38,1.76,2.38s2.16-1,2.16-2.84V17.32H94.9V27.1H92.26V25.94l0,0a3.68,3.68,0,0,1-2.88,1.36C87.22,27.28,85.74,26.2,85.74,23.22Z" fill="#fff" />
      <path d="M106.46,23.06H99.6a2.49,2.49,0,0,0,2.58,1.88,2.92,2.92,0,0,0,2.38-1.1l1.7,1.58A5,5,0,0,1,102,27.28a4.85,4.85,0,0,1-5.12-5.08,4.77,4.77,0,0,1,5-5,4.63,4.63,0,0,1,4.68,4.94C106.52,22.44,106.48,22.9,106.46,23.06Zm-6.84-1.88h4.3a2.06,2.06,0,0,0-2.1-1.72A2.18,2.18,0,0,0,99.62,21.18Z" fill="#fff" />
      <path d="M107.76,25.84,109,24a3.8,3.8,0,0,0,2.72,1.12c.76,0,1.18-.32,1.18-.8s-.5-.64-1.76-1.06c-1.62-.54-3-1.3-3-3.2s1.52-2.9,3.54-2.9a5.5,5.5,0,0,1,3.56,1.22L114,20.34a3.46,3.46,0,0,0-2.34-1c-.4,0-.92.2-.92.72s.48.68,1.44,1c2.12.78,3.36,1.24,3.36,3.18,0,1.72-1.22,3-3.78,3A5.91,5.91,0,0,1,107.76,25.84Z" fill="#fff" />
      <path d="M117.7,24V19.5h-1.16V17.32h1.16V14.14l2.62-.28v3.46H123V19.5h-2.64v4.14c0,.86.22,1.26.84,1.26a3.06,3.06,0,0,0,1.24-.34l.66,2.1a4.71,4.71,0,0,1-2.58.62C118.8,27.28,117.7,26.22,117.7,24Z" fill="#fff" />
    </g>
    <g>
      <rect x="-0.1" width="40" height="40" rx="10" fill="#1bdfc7" />
      <g>
        <polygon points="22.8 20.42 29.1 14.43 22.8 8.45 22.8 12.23 9.39 12.23 9.39 16.63 22.8 16.63 22.8 20.42" fill="url(#a)" />
        <polygon points="17 19.58 10.71 25.57 17 31.55 17 27.77 30.41 27.77 30.41 23.37 17 23.37 17 19.58" fill="url(#b)" />
      </g>
    </g>
  </g>
</svg>

export const RequestLogoIconLogin = () => <svg width="175" height="57" viewBox="0 0 175 57" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0)">
    <path d="M71.0021 18.3824H78.6572C83.5235 18.3824 85.6863 21.2324 85.6863 25.1084C85.7917 26.4631 85.4235 27.8124 84.6447 28.925C83.8659 30.0377 82.7251 30.8446 81.4176 31.2074L86.4831 38.6174H81.7876L77.2628 31.6919H74.9577V38.6174H71.0021V18.3824ZM77.8604 28.1579C80.3078 28.1579 81.5599 26.9039 81.5599 25.0514C81.5599 23.1989 80.3362 21.9449 78.0311 21.9449H74.9577V28.1579H77.8604Z" fill="#1E2533" />
    <path d="M101.879 32.8604H92.1178C92.3194 33.6669 92.7973 34.377 93.4683 34.8666C94.1392 35.3562 94.9605 35.5943 95.7888 35.5394C96.4363 35.5606 97.0796 35.4299 97.6676 35.1577C98.2556 34.8855 98.7719 34.4795 99.1753 33.9719L101.594 36.2234C100.874 37.1237 99.9456 37.8353 98.8897 38.297C97.8339 38.7587 96.6819 38.9566 95.5327 38.8739C94.5642 38.9332 93.594 38.7872 92.6856 38.4456C91.7771 38.1039 90.9509 37.5743 90.2607 36.8911C89.5705 36.208 89.0319 35.3868 88.6802 34.4811C88.3284 33.5754 88.1713 32.6055 88.2191 31.6349C88.1722 30.6868 88.3242 29.7394 88.6651 28.8537C89.0061 27.968 89.5285 27.1637 90.1987 26.4925C90.8689 25.8212 91.6721 25.2981 92.5565 24.9566C93.4409 24.6151 94.3868 24.463 95.3335 24.5099C96.243 24.498 97.1451 24.6749 97.983 25.0293C98.8209 25.3838 99.5765 25.9082 100.202 26.5695C100.828 27.2308 101.31 28.0147 101.618 28.8718C101.926 29.7288 102.054 30.6405 101.993 31.5494C101.964 31.9769 101.907 32.6324 101.879 32.8604ZM92.1463 30.1814H98.2647C98.1491 29.4895 97.79 28.8619 97.2523 28.4123C96.7146 27.9627 96.0339 27.7208 95.3335 27.7304C94.5982 27.6937 93.8739 27.9201 93.29 28.3691C92.7061 28.8182 92.3007 29.4605 92.1463 30.1814V30.1814Z" fill="#1E2533" />
    <path d="M114.912 37.05C114.405 37.6514 113.767 38.1285 113.046 38.4447C112.326 38.7609 111.543 38.9077 110.758 38.874C106.944 38.874 104.127 36.024 104.127 31.749C104.127 27.474 106.973 24.5385 110.672 24.5385C111.474 24.5061 112.274 24.653 113.012 24.9685C113.75 25.284 114.409 25.7601 114.941 26.3625V26.3625V24.681H118.669V44.2605H114.912V37.05ZM115.055 31.7205C115.092 31.2222 115.026 30.7216 114.861 30.25C114.697 29.7783 114.437 29.3458 114.097 28.9794C113.758 28.6129 113.347 28.3205 112.89 28.1203C112.433 27.9201 111.94 27.8165 111.441 27.816C110.945 27.818 110.454 27.9231 110.001 28.1246C109.548 28.3261 109.141 28.6196 108.807 28.9865C108.472 29.3535 108.218 29.7859 108.059 30.2565C107.9 30.7271 107.84 31.2256 107.883 31.7205C107.846 32.2085 107.91 32.6991 108.073 33.1609C108.235 33.6226 108.492 34.0454 108.826 34.4021C109.161 34.7588 109.567 35.0417 110.017 35.2326C110.467 35.4236 110.952 35.5184 111.441 35.511C111.932 35.5177 112.419 35.423 112.872 35.2326C113.325 35.0423 113.733 34.7604 114.073 34.4047C114.412 34.0489 114.674 33.6269 114.843 33.1651C115.012 32.7032 115.084 32.2114 115.055 31.7205Z" fill="#1E2533" />
    <path d="M121.998 33.0884V24.6809H125.698V32.1194C125.698 34.6559 126.466 35.5109 128.202 35.5109C129.938 35.5109 131.276 34.0859 131.276 31.4639V24.6809H135.032V38.6174H131.276V36.9644C130.781 37.5742 130.156 38.065 129.447 38.4004C128.738 38.7358 127.962 38.9074 127.178 38.9024C124.104 38.8739 121.998 37.3349 121.998 33.0884Z" fill="#1E2533" />
    <path d="M151.481 32.8604H141.72C141.921 33.6669 142.399 34.377 143.07 34.8666C143.741 35.3562 144.562 35.5943 145.391 35.5394C146.038 35.5606 146.681 35.4299 147.269 35.1577C147.857 34.8855 148.374 34.4795 148.777 33.9719L151.196 36.2234C150.476 37.1237 149.547 37.8353 148.492 38.297C147.436 38.7587 146.284 38.9566 145.135 38.8739C144.168 38.929 143.201 38.78 142.296 38.4365C141.391 38.093 140.569 37.5626 139.882 36.88C139.194 36.1973 138.659 35.3775 138.308 34.4739C137.958 33.5703 137.802 32.603 137.849 31.6349C137.803 30.6868 137.954 29.7394 138.295 28.8537C138.636 27.968 139.159 27.1637 139.829 26.4925C140.499 25.8212 141.302 25.2981 142.187 24.9566C143.071 24.6151 144.017 24.463 144.964 24.5099C145.873 24.498 146.775 24.6749 147.613 25.0293C148.451 25.3838 149.207 25.9082 149.832 26.5695C150.458 27.2308 150.94 28.0147 151.248 28.8718C151.556 29.7288 151.684 30.6405 151.623 31.5494C151.566 31.9769 151.509 32.6324 151.481 32.8604ZM141.748 30.1814H147.867C147.75 29.4796 147.382 28.8442 146.833 28.3933C146.283 27.9425 145.589 27.7069 144.878 27.7304C144.153 27.7074 143.442 27.9402 142.87 28.388C142.298 28.8359 141.901 29.4705 141.748 30.1814V30.1814Z" fill="#1E2533" />
    <path d="M153.33 36.822L155.095 34.2C155.602 34.7102 156.205 35.1142 156.869 35.3882C157.534 35.6623 158.246 35.8009 158.965 35.796C160.046 35.796 160.644 35.34 160.644 34.656C160.644 33.972 159.933 33.744 158.14 33.1455C155.835 32.376 153.871 31.293 153.871 28.5855C153.871 25.878 156.034 24.453 158.908 24.453C160.748 24.4179 162.542 25.0335 163.974 26.1915L162.209 28.9845C161.32 28.1031 160.13 27.5936 158.88 27.5595C158.31 27.5595 157.571 27.8445 157.571 28.5855C157.571 29.3265 158.254 29.5545 159.619 30.0105C162.636 31.122 164.4 31.7775 164.4 34.542C164.4 36.993 162.664 38.817 159.022 38.817C156.944 38.8795 154.916 38.1687 153.33 36.822V36.822Z" fill="#1E2533" />
    <path d="M167.474 34.2V27.7875H165.823V24.681H167.474V20.1495L171.202 19.7505V24.681H175.015V27.7875H171.259V33.687C171.259 34.9125 171.572 35.4825 172.454 35.4825C173.07 35.448 173.671 35.2828 174.218 34.998L175.158 37.9905C174.044 38.6312 172.769 38.9379 171.486 38.874C169.039 38.874 167.474 37.3635 167.474 34.2Z" fill="#1E2533" />
    <path d="M42.5443 0H14.0865C6.22815 0 -0.142334 6.37994 -0.142334 14.25V42.75C-0.142334 50.6201 6.22815 57 14.0865 57H42.5443C50.4027 57 56.7732 50.6201 56.7732 42.75V14.25C56.7732 6.37994 50.4027 0 42.5443 0Z" fill="#1BDFC7" />
    <path d="M32.4418 29.0985L41.406 20.5628L32.4418 12.0413V17.4278H13.3608V23.6978H32.4418V29.0985Z" fill="url(#paint0_linear)" />
    <path d="M24.189 27.9015L15.239 36.4372L24.189 44.9587V39.5722H43.2699V33.3022H24.189V27.9015Z" fill="url(#paint1_linear)" />
  </g>
  <defs>
    <linearGradient id="paint0_linear" x1="13.3608" y1="20.5628" x2="41.406" y2="20.5628" gradientUnits="userSpaceOnUse">
      <stop stopColor="#1BDFC7" />
      <stop offset="0.86" stopColor="white" />
    </linearGradient>
    <linearGradient id="paint1_linear" x1="15.2248" y1="36.4372" x2="43.2699" y2="36.4372" gradientUnits="userSpaceOnUse">
      <stop offset="0.18" stopColor="white" />
      <stop offset="1" stopColor="#1BDFC7" />
    </linearGradient>
    <clipPath id="clip0">
      <rect width="174.958" height="57" fill="white" />
    </clipPath>
  </defs>
</svg>

export const RequestCenterIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M20.3077 7.38464H22.1539V22.1539H1.84619V7.38464H3.69235" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M7.38464 12V1.84619H16.6154V12" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M1.84619 15.6923H22.1539" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M5.53845 12H18.4615" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>

export const ReportsIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.01843 1.84619V21.3693C3.02086 21.5782 3.10557 21.7778 3.25418 21.9246C3.4028 22.0715 3.60333 22.1539 3.81228 22.1539H9.29536V2.40927C9.29658 2.33576 9.28316 2.26275 9.25588 2.19448C9.22859 2.12622 9.18799 2.06407 9.13644 2.01166C9.08489 1.95925 9.02342 1.91762 8.95561 1.88922C8.88781 1.86081 8.81502 1.84618 8.74151 1.84619H6.46151" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M12.6462 8.6123H15.0185C15.1671 8.61468 15.3089 8.67477 15.414 8.77986C15.5191 8.88494 15.5792 9.02679 15.5816 9.17538V22.1538H9.29541" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M18.7938 15.3877H20.4184C20.5662 15.3877 20.708 15.4457 20.8133 15.5493C20.9187 15.6529 20.9791 15.7938 20.9815 15.9415V21.3692C20.9791 21.5782 20.8944 21.7777 20.7457 21.9246C20.5971 22.0715 20.3966 22.1539 20.1876 22.1538H15.5723" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>


export const DiscountMgmtIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M21.2308 6.46158V1.84619H15.6924V2.76927C15.6924 3.74853 15.3034 4.68768 14.6109 5.38012C13.9185 6.07257 12.9793 6.46158 12.0001 6.46158C11.0208 6.46158 10.0816 6.07257 9.3892 5.38012C8.69676 4.68768 8.30775 3.74853 8.30775 2.76927V1.84619H2.76929V12" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M2.76929 22.1538H21.2308V12H15.6924V12.9231C15.6924 13.9023 15.3034 14.8415 14.6109 15.5339C13.9185 16.2264 12.9793 16.6154 12.0001 16.6154C11.0208 16.6154 10.0816 16.2264 9.3892 15.5339C8.69676 14.8415 8.30775 13.9023 8.30775 12.9231V12H2.76929V16.6154" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M5.53845 12H19.3846" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>

export const BellIcon = () => <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M12 14H4V8C4 5.334 6 4 7.999 4H8.001C10 4 12 5.334 12 8V14ZM9 17C9 17.552 8.552 18 8 18C7.448 18 7 17.552 7 17V16H9V17ZM14 14V8C14 4.447 11.632 2.475 9 2.079V0H7V2.079C4.368 2.475 2 4.447 2 8V14H0V16H5V17C5 18.657 6.343 20 8 20C9.657 20 11 18.657 11 17V16H16V14H14Z" fill="#1E2533" />
</svg>

export const MessageIcon = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M12.0373 0H8.49625C3.81225 0 0.186254 3.171 0.0072543 7.319C-0.164746 11.323 2.74225 14.676 6.55525 15.225C7.09125 15.302 7.55325 15.641 7.81725 16.113L10.0003 20.001L12.1823 16.114C12.4473 15.642 12.9103 15.302 13.4453 15.225C17.2593 14.675 20.1653 11.323 19.9933 7.319C19.8143 3.171 16.1883 0 12.0373 0ZM11.9393 1.996C15.0963 1.996 17.8173 4.422 17.9463 7.402C18.0713 10.317 16.0173 12.829 13.1363 13.244C11.2163 13.521 10.5473 14.915 9.99425 15.915C9.43625 14.915 8.76325 13.523 6.83925 13.245C3.95725 12.83 1.87925 10.319 2.00525 7.405C2.13725 4.324 4.92825 2 8.49625 2H12.0003" fill="#1E2533" />
</svg>

export const TimesIcon = () => <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15 0C6.71371 0 0 6.71371 0 15C0 23.2863 6.71371 30 15 30C23.2863 30 30 23.2863 30 15C30 6.71371 23.2863 0 15 0ZM15 27.0968C8.31653 27.0968 2.90323 21.6835 2.90323 15C2.90323 8.31653 8.31653 2.90323 15 2.90323C21.6835 2.90323 27.0968 8.31653 27.0968 15C27.0968 21.6835 21.6835 27.0968 15 27.0968ZM21.1573 11.2379L17.3952 15L21.1573 18.7621C21.4415 19.0464 21.4415 19.506 21.1573 19.7903L19.7903 21.1573C19.506 21.4415 19.0464 21.4415 18.7621 21.1573L15 17.3952L11.2379 21.1573C10.9536 21.4415 10.494 21.4415 10.2097 21.1573L8.84274 19.7903C8.55847 19.506 8.55847 19.0464 8.84274 18.7621L12.6048 15L8.84274 11.2379C8.55847 10.9536 8.55847 10.494 8.84274 10.2097L10.2097 8.84274C10.494 8.55847 10.9536 8.55847 11.2379 8.84274L15 12.6048L18.7621 8.84274C19.0464 8.55847 19.506 8.55847 19.7903 8.84274L21.1573 10.2097C21.4415 10.494 21.4415 10.9536 21.1573 11.2379Z" fill="#1E2533" />
</svg>

export const RecipientIcon = () => <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect y="9" width="10" height="1.5" fill="#1E2533" />
  <rect y="5.25" width="10" height="1.5" fill="#1E2533" />
  <rect width="10" height="3" fill="#1E2533" />
  <rect y="12.75" width="10" height="1.5" fill="#1E2533" />
  <rect x="12.1426" y="12.75" width="10.5" height="1.42857" transform="rotate(-90 12.1426 12.75)" fill="#1E2533" />
  <path d="M12.8577 0L14.7135 3.375H11.0019L12.8577 0Z" fill="#1E2533" />
  <path d="M12.8571 15L11.0014 11.625H14.7129L12.8571 15Z" fill="#1E2533" />
</svg>

export const PlusIcon = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M20 9V11H11V20H9V11H0V9H9V0H11V9H20Z" fill="white" />
</svg>

export const AddUserPlusIcon = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8 3.33325V12.6666" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M3.33333 8H12.6667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>

export const CloseIconAlt = () => <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M8.99599 7.46609L13.7579 2.42763C13.9777 2.19686 13.9777 1.85071 13.7579 1.61994L13.0253 0.812246C12.8055 0.581477 12.4758 0.581477 12.2561 0.812246L7.45753 5.85071C7.31101 6.00455 7.09123 6.00455 6.94471 5.85071L2.14618 0.773785C1.9264 0.543016 1.59673 0.543016 1.37695 0.773785L0.607715 1.58148C0.387935 1.81225 0.387935 2.1584 0.607715 2.38917L5.40625 7.42763C5.55277 7.58148 5.55277 7.81225 5.40625 7.96609L0.571085 13.043C0.351305 13.2738 0.351305 13.6199 0.571085 13.8507L1.34032 14.6584C1.5601 14.8892 1.88977 14.8892 2.10955 14.6584L6.90808 9.61994C7.0546 9.46609 7.27438 9.46609 7.4209 9.61994L12.2194 14.6584C12.4392 14.8892 12.7689 14.8892 12.9887 14.6584L13.7579 13.8507C13.9777 13.6199 13.9777 13.2738 13.7579 13.043L8.99599 8.00455C8.84947 7.85071 8.84947 7.61994 8.99599 7.46609Z" fill="black" />
</svg>

export const ArrowRight = () => <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M0.430829 6.92287L10.6462 6.92287C10.9231 6.92287 11.0462 7.26133 10.8616 7.44594L7.90775 10.3998C7.72314 10.5844 7.72314 10.8613 7.90775 11.0459L8.58468 11.7229C8.76929 11.9075 9.04621 11.9075 9.23083 11.7229L14.6154 6.30748C14.8001 6.12287 14.8001 5.84594 14.6154 5.66133L9.23083 0.276713C9.04621 0.0920978 8.76929 0.0920978 8.58467 0.276713L7.93852 0.922867C7.75391 1.10748 7.75391 1.38441 7.93852 1.56902L10.8924 4.52287C11.077 4.73825 10.9539 5.07671 10.677 5.07671L0.461598 5.07671C0.215444 5.07671 5.95045e-05 5.26133 5.9526e-05 5.50748L5.96067e-05 6.43056C5.96282e-05 6.67671 0.184675 6.92287 0.430829 6.92287Z" fill="#BDBDBD" />
</svg>

export const ReverseIcon = () => <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.51376 1.21938e-05C9.51786 0.00361098 11.3375 0.793348 12.6809 2.07718L13.761 0.997088C14.2182 0.539861 15 0.863691 15 1.51032V5.56452C15 5.96538 14.675 6.29033 14.2742 6.29033H10.22C9.57336 6.29033 9.24953 5.50855 9.70675 5.05129L10.9694 3.78869C10.036 2.91473 8.82819 2.43061 7.54506 2.41955C4.75077 2.39541 2.39537 4.65675 2.41954 7.5438C2.44246 10.2825 4.66282 12.5806 7.5 12.5806C8.74376 12.5806 9.91926 12.1368 10.8456 11.3239C10.9891 11.1981 11.2057 11.2057 11.3406 11.3406L12.5401 12.5401C12.6874 12.6874 12.6801 12.9276 12.5255 13.0673C11.1956 14.2685 9.43325 15 7.5 15C3.35788 15 3.02421e-05 11.6421 2.03152e-10 7.50007C-3.02417e-05 3.3627 3.37639 -0.00739707 7.51376 1.21938e-05Z" fill="white" />
</svg>

export const TeamManagementIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26"
  viewBox="0 0 26 26" fill="none">
  <path d="M5.5 9C7.433 9 9 7.433 9 5.5C9 3.567 7.433 2 5.5 2C3.567 2 2 3.567 2 5.5C2 7.433 3.567 9 5.5 9Z"
    stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M9 5.5H13.13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path
    d="M5.5 24C7.433 24 9 22.433 9 20.5C9 18.567 7.433 17 5.5 17C3.567 17 2 18.567 2 20.5C2 22.433 3.567 24 5.5 24Z"
    stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M5.5 17.0001V12.8701" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path
    d="M20.5 24C22.433 24 24 22.433 24 20.5C24 18.567 22.433 17 20.5 17C18.567 17 17 18.567 17 20.5C17 22.433 18.567 24 20.5 24Z"
    stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M17 20.5H12.87" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path
    d="M20.5 9C22.433 9 24 7.433 24 5.5C24 3.567 22.433 2 20.5 2C18.567 2 17 3.567 17 5.5C17 7.433 18.567 9 20.5 9Z"
    stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M20.5 9V13.13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>;

export const TabDoubleDrawer = () => <svg width="34" height="135" viewBox="0 0 34 135" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0)">
    <g filter="url(#filter0_d)">
      <path d="M25.855 38.2898C33.1922 28.2168 33.8367 17.2503 34 11L34 116C33.8367 109.75 33.5349 99.2536 25.855 88.7102C21.6973 83.0022 18.9954 79.1555 17.3615 75.2366C16.1421 72.3118 15.5292 69.3801 15.2915 65.6555L15.1699 65.6555C15.1699 64.9071 15.1784 64.1899 15.1966 63.5C15.1784 62.8101 15.1699 62.0929 15.1699 61.3445L15.2915 61.3445C15.5292 57.6199 16.1421 54.6882 17.3615 51.7634C18.9954 47.8446 21.6972 43.9978 25.855 38.2898Z" fill="#FFFFFF" />
    </g>
  </g>
  <defs>
    <filter id="filter0_d" x="0.169861" y="0" width="48.8301" height="135" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="7.5" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
    </filter>
    <clipPath id="clip0">
      <rect width="34" height="135" fill="white" />
    </clipPath>
  </defs>
</svg>


export const ChevronClosed = () => <svg width="9" height="28" viewBox="0 0 9 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0)">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.48087 0.0443924C0.328638 0.270655 -0.278557 1.3481 0.124666 2.45094L4.25471 13.7468C4.26316 13.77 4.27198 13.7929 4.28118 13.8156C4.27198 13.8384 4.26315 13.8613 4.2547 13.8845L0.124664 25.1804C-0.278559 26.2832 0.328636 27.3606 1.48087 27.5869C2.6331 27.8132 3.89405 27.1026 4.29727 25.9997L8.42731 14.7038C8.53837 14.4001 8.57278 14.0983 8.54027 13.8157C8.57278 13.533 8.53837 13.2312 8.42732 12.9275L4.29728 1.63157C3.89405 0.528735 2.63311 -0.18187 1.48087 0.0443924Z" fill="#C1C3C7" />
  </g>
  <defs>
    <clipPath id="clip0">
      <rect y="0.00239563" width="8.55198" height="27.6265" fill="white" />
    </clipPath>
  </defs>
</svg>


export const CheveronOpen = () => <svg width="9" height="28" viewBox="0 0 9 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0)">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.07113 27.5869C8.22336 27.3606 8.83056 26.2832 8.42734 25.1804L4.29729 13.8845C4.28884 13.8614 4.28002 13.8384 4.27083 13.8157C4.28002 13.7929 4.28885 13.77 4.2973 13.7468L8.42734 2.45094C8.83056 1.34811 8.22336 0.270659 7.07113 0.0443955C5.9189 -0.181866 4.65795 0.528738 4.25473 1.63157L0.124686 12.9275C0.0136359 13.2312 -0.0207755 13.533 0.0117314 13.8156C-0.0207812 14.0983 0.0136284 14.4001 0.124683 14.7038L4.25472 25.9997C4.65795 27.1026 5.9189 27.8132 7.07113 27.5869Z" fill="#C1C3C7" />
  </g>
  <defs>
    <clipPath id="clip0">
      <rect y="0.00239563" width="8.55198" height="27.6265" fill="white" />
    </clipPath>
  </defs>
</svg>

export const FilterIcon = () =>
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 2.5714C8 2.26883 8.0576 1.71426 8.1472 1.71426H0V3.42855H8.1472C8.0576 3.42855 8 2.87397 8 2.5714ZM14.2528 1.71429C13.9224 0.857143 13.0432 0 12 0C10.6744 0 9.6 1.15114 9.6 2.57143C9.6 3.99171 10.6744 5.14286 12 5.14286C13.0432 5.14286 13.9224 4.28571 14.2528 3.42857H16V1.71429H14.2528ZM8.00004 9.42857C8.00004 9.73114 7.94244 10.0166 7.85284 10.2857H16V8.57143H7.85284C7.94244 8.57143 8.00004 9.126 8.00004 9.42857ZM6.4 9.4286C6.4 10.8489 5.3256 12 4 12C2.9568 12 2.0776 11.1429 1.7472 10.2857H0V8.57146H1.7472C2.0776 7.71431 2.9568 6.85717 4 6.85717C5.3256 6.85717 6.4 8.00831 6.4 9.4286Z" fill="black" />
  </svg>

export const KalderosWordmark = () => <svg width="108" height="16" viewBox="0 0 108 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.62985 10.644H2.36686V15.0357H0V0H2.36686V8.585H5.56828L7.93514 4.21383H10.7945L7.61363 9.52901L11.1776 15.0426H8.2977L5.62985 10.644Z" fill="#DCDFE6" />
  <path d="M13.0859 9.60446C13.0859 6.27306 15.4049 3.97461 18.3943 3.97461C20.0292 3.97461 21.1647 4.57659 21.9172 5.78054H21.9377V4.21403H24.3251V15.0428H21.9377V13.2779H21.8967C21.1237 14.5434 20.0702 15.2548 18.3327 15.2548C15.4528 15.2548 13.0859 12.9153 13.0859 9.60446ZM15.4938 9.60446C15.4938 11.7319 16.9099 13.2163 18.8047 13.2163C20.7406 13.2163 21.9651 11.7524 21.9651 9.69339V9.52237C21.9446 7.43597 20.6996 6.01996 18.7842 6.01996C16.9099 6.01312 15.4938 7.49754 15.4938 9.60446Z" fill="#DCDFE6" />
  <path d="M39.1763 12.9972V15.0357H27.8276V12.9972H32.2741V2.03851H28.1902V0H34.6341V12.9972H39.1763Z" fill="#DCDFE6" />
  <path d="M41.4541 9.60426C41.4541 6.27287 43.6431 3.99493 46.6119 3.99493C48.2469 3.99493 49.4713 4.57639 50.2854 5.78034H50.3059V0H52.6933V15.0426H50.3059V13.2777H50.2648C49.4303 14.5432 48.2879 15.2546 46.5504 15.2546C43.6636 15.2546 41.4541 12.9151 41.4541 9.60426ZM43.862 9.60426C43.862 11.7317 45.278 13.2161 47.1729 13.2161C49.1088 13.2161 50.3333 11.7522 50.3333 9.69319V9.52217C50.3127 7.43578 49.0677 6.04029 47.1524 6.04029C45.278 6.04029 43.862 7.49734 43.862 9.60426Z" fill="#DCDFE6" />
  <path d="M56.1548 9.66576C56.1548 6.40278 58.2822 3.99487 61.6957 3.99487C65.0887 3.99487 66.8946 6.40278 66.8946 9.75469V10.1857H58.6037V10.2062C58.7337 12.0121 59.7666 13.2571 61.6547 13.2571C63.0502 13.2571 63.871 12.6346 64.4046 11.5811H66.833C66.19 13.6881 64.5551 15.2546 61.7436 15.2546C58.1728 15.2546 56.1548 12.8672 56.1548 9.66576ZM64.4456 8.5097C64.1652 6.96371 63.3306 5.93077 61.6752 5.93077C60.0403 5.93077 59.0347 6.96371 58.6858 8.5097H64.4456Z" fill="#DCDFE6" />
  <path d="M80.8018 4.03589V6.48484C75.6029 6.14281 75.2335 7.77088 75.2335 11.2528V12.9971H79.4679V15.0356H70.103V12.9971H72.8735V6.25226H70.103V4.21375H75.2403V6.40275C76.0749 4.78836 77.7509 4.03589 80.8018 4.03589Z" fill="#DCDFE6" />
  <path d="M94.9001 9.6042C94.9001 12.9151 92.4922 15.2546 89.2292 15.2546C85.9663 15.2546 83.5583 12.9151 83.5583 9.6042C83.5583 6.34121 85.9663 3.99487 89.2292 3.99487C92.4922 3.99487 94.9001 6.34121 94.9001 9.6042ZM85.9594 9.6042C85.9594 11.7111 87.3139 13.2161 89.2224 13.2161C91.1104 13.2161 92.4649 11.7111 92.4649 9.6042C92.4649 7.53832 91.1104 6.04022 89.2224 6.04022C87.3139 6.04022 85.9594 7.54517 85.9594 9.6042Z" fill="#DCDFE6" />
  <path d="M104.778 8.79016L102.22 8.40708C101.234 8.25659 100.653 7.93508 100.653 7.24417C100.653 6.38225 101.556 5.8692 102.972 5.8692C104.258 5.8692 105.14 6.30016 105.455 7.16209H107.877C107.466 5.17146 105.654 4.00171 102.883 4.00171C100.003 4.00171 98.2656 5.26723 98.2656 7.31258C98.2656 9.28952 99.88 10.0625 101.556 10.3019L104.094 10.6645C105.277 10.8355 105.619 11.3964 105.619 11.93C105.619 12.7919 104.757 13.346 103.232 13.346C101.665 13.346 100.646 12.7304 100.379 11.6017H97.9236C98.2451 13.9206 100.174 15.2546 103.211 15.2546C106.201 15.2546 108 13.9412 108 11.7932C108 9.96675 106.645 9.06378 104.778 8.79016Z" fill="#DCDFE6" />
</svg>

export const Nubbin = () => <svg width="14" height="42" viewBox="0 0 14 42" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M6.41325 41.9873L6.82661 42C6.82674 41.9958 6.82687 41.9916 6.82699 41.9873C6.83079 41.8583 6.8342 41.7236 6.83775 41.5836C6.89995 39.1302 7.13433 34.8495 9.92945 30.8956C11.5134 28.6551 12.5427 27.1451 13.1651 25.6069C13.6296 24.4589 13.8631 23.3081 13.9537 21.8461H14C14 21.5523 13.9968 21.2708 13.9898 21C13.9968 20.7292 14 20.4477 14 20.1539H13.9537C13.8631 18.6919 13.6296 17.5411 13.1651 16.3931C12.5427 14.8549 11.5134 13.3449 9.92945 11.1044C7.0038 6.96587 6.89995 2.86991 6.83775 0.416515C6.8342 0.276476 6.83079 0.141754 6.82699 0.0127166V0H6V41.9873H6.41325Z" fill="#1E2533" />
  <line x1="4.75" y1="15.75" x2="4.75" y2="24.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
  <line x1="0.75" y1="15.75" x2="0.75" y2="24.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
</svg>

export const PaymentCheckMarkIcon = () =>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0C4.47714 0 0 4.47714 0 10C0 15.5229 4.47714 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47714 15.5229 0 10 0ZM10 1.93548C14.4569 1.93548 18.0645 5.54238 18.0645 10C18.0645 14.4569 14.4576 18.0645 10 18.0645C5.54306 18.0645 1.93548 14.4576 1.93548 10C1.93548 5.54306 5.54238 1.93548 10 1.93548ZM15.6534 7.18819L14.7447 6.27214C14.5565 6.08242 14.2501 6.08117 14.0604 6.26939L8.36073 11.9233L5.94976 9.49274C5.76157 9.30302 5.4552 9.30177 5.26548 9.48996L4.34939 10.3987C4.15968 10.5869 4.15843 10.8932 4.34665 11.083L8.00718 14.7731C8.19536 14.9629 8.50173 14.9641 8.69145 14.7759L15.6507 7.8725C15.8404 7.68427 15.8416 7.3779 15.6534 7.18819Z" fill="#1E2533" />
  </svg>

export const AppSwitcher = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M20.9446 1.84619H10.4399C9.7721 1.84619 9.23071 2.38758 9.23071 3.05542V13.56C9.23071 14.2279 9.7721 14.7693 10.4399 14.7693H20.9446C21.6124 14.7693 22.1538 14.2279 22.1538 13.56V3.05542C22.1538 2.38758 21.6124 1.84619 20.9446 1.84619Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M14.7693 11.0769V10.4399C14.7693 10.1192 14.6419 9.81166 14.4151 9.58489C14.1883 9.35811 13.8807 9.23071 13.56 9.23071H3.05542C2.73471 9.23071 2.42714 9.35811 2.20037 9.58489C1.97359 9.81166 1.84619 10.1192 1.84619 10.4399V20.9446C1.84619 21.2653 1.97359 21.5728 2.20037 21.7996C2.42714 22.0264 2.73471 22.1538 3.05542 22.1538H13.56C13.8807 22.1538 14.1883 22.0264 14.4151 21.7996C14.6419 21.5728 14.7693 21.2653 14.7693 20.9446V18.4615" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>

export const NotificationDot = () => <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="4.36621" cy="4" r="4" fill="#AE403D" />
</svg>

export const HelpIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="icon/communication/custom//Help/24px">
    <path id="Vector" d="M3.20311 6.92308C4.43339 4.78884 6.39761 3.17465 8.72979 2.38125C11.062 1.58785 13.6031 1.66936 15.8796 2.61059C18.1562 3.55182 20.0129 5.28858 21.1039 7.49728C22.1948 9.70597 22.4456 12.236 21.8096 14.6159C21.1735 16.9958 19.6939 19.0633 17.6464 20.4332C15.599 21.8031 13.1234 22.3819 10.6808 22.0618C8.23825 21.7418 5.99534 20.5447 4.36997 18.6935C2.7446 16.8424 1.84763 14.4635 1.84619 12" stroke="#1E2533" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
    <path id="Vector_2" d="M8.99072 9.01846V8.63077C8.99072 7.93305 9.26789 7.2639 9.76126 6.77053C10.2546 6.27717 10.9238 6 11.6215 6H12.203C12.9008 6 13.5699 6.27717 14.0633 6.77053C14.5566 7.2639 14.8338 7.93305 14.8338 8.63077V8.63077C14.8303 9.32279 14.5551 9.98574 14.0676 10.4769L12.8492 11.6862C12.3608 12.1775 12.0827 12.8396 12.0738 13.5323V14.4554" stroke="#1E2533" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
    <path id="Vector_3" d="M12.12 18.72C12.6706 18.72 13.1169 18.2737 13.1169 17.7231C13.1169 17.1725 12.6706 16.7262 12.12 16.7262C11.5694 16.7262 11.123 17.1725 11.123 17.7231C11.123 18.2737 11.5694 18.72 12.12 18.72Z" fill="#1E2533"/>
  </g>
</svg>

export const DisputesCenterIcon = () => <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#DisputesCenterIcon)">
    <path d="M20 16L25 19.9955L20 24" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M23.4199 20H4.41992" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.41992 14L4.41992 10.0045L9.41992 6" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 10L25 10" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </g>
  <defs>
    <clipPath id="DisputesCenterIcon">
      <rect width="22.85" height="21.85" fill="white" transform="translate(3 4)" />
    </clipPath>
  </defs>
</svg>

export const DisputesCenterHeaderIcon = () => <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.5801 11L21.5801 14.9955L16.5801 19" stroke="#1E2533" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M20 15H1" stroke="#1E2533" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M6 9L1 5.00452L6 1" stroke="#1E2533" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  <path d="M2.58008 5L21.5801 5" stroke="#1E2533" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
</svg>

export const DisputesCenterBannerIcon = () => <svg width="549" height="306" viewBox="0 0 549 306" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0)">
    <path opacity="0.5" d="M43.4998 23.6935C35.7378 22.5691 28.2184 27.3077 25.8736 34.777C25.1459 37.1061 24.9842 39.4353 25.2267 41.6841C25.5501 44.3345 26.6012 46.8242 28.0566 48.9124C31.2099 54.8557 32.4228 61.9235 30.8057 68.9912C30.4014 70.5975 29.9163 72.2038 29.3504 73.6494C28.6227 75.2557 28.0566 76.9423 27.6523 78.7896C27.0864 81.2794 26.8439 83.8494 27.0056 86.3392C27.6524 99.2699 37.1123 110.594 50.6958 112.923C65.1687 115.413 79.3991 106.338 83.1184 92.2022C85.2206 84.3313 83.7651 76.4605 79.8033 70.0353C77.2968 66.0195 73.9009 62.6463 69.6965 60.2368C62.5005 55.177 57.9727 47.1455 57.3259 38.3109C57.3259 38.2305 57.3259 38.1502 57.3259 38.0699C57.1642 36.062 56.5982 34.1345 55.7896 32.4479C53.5257 28.0305 49.1596 24.4967 43.4998 23.6935Z" fill="#B8F5F2"/>
    <path opacity="0.5" d="M317.272 216.611C309.51 215.487 301.991 220.225 299.646 227.695C298.918 230.024 298.757 232.353 298.999 234.602C299.322 237.252 300.374 239.742 301.829 241.83C304.982 247.773 306.195 254.841 304.578 261.909C304.174 263.515 303.689 265.121 303.123 266.567C302.395 268.173 301.829 269.86 301.425 271.707C300.859 274.197 300.616 276.767 300.778 279.257C301.425 292.188 310.885 303.512 324.468 305.841C338.941 308.331 353.171 299.255 356.891 285.12C358.993 277.249 357.538 269.378 353.576 262.953C351.069 258.937 347.673 255.564 343.469 253.154C336.273 248.095 331.745 240.063 331.098 231.228C331.098 231.148 331.098 231.068 331.098 230.988C330.936 228.98 330.37 227.052 329.562 225.365C327.298 220.868 322.851 217.414 317.272 216.611Z" fill="#B8F5F2"/>
    <path opacity="0.5" d="M391.658 229.059C411.063 231.79 429.983 219.984 435.804 201.35C437.664 195.568 438.068 189.705 437.341 184.083C436.451 177.497 433.945 171.232 430.145 165.932V165.851C422.14 150.993 419.31 133.324 423.272 115.574C424.161 111.478 425.455 107.623 426.991 103.848C428.77 99.752 430.225 95.4953 431.196 91.078C432.651 84.7331 433.136 78.4685 432.813 72.2843C432.813 72.204 432.813 72.204 432.813 72.204C431.277 39.9174 407.505 11.4055 373.466 5.54254C337.243 -0.641712 301.748 22.0071 292.288 57.3457C287.033 77.0229 290.671 96.7803 300.535 112.763C306.761 122.722 315.412 131.235 325.923 137.179C343.873 149.869 355.354 170.028 356.81 192.114C356.81 192.275 356.891 192.516 356.891 192.676C357.295 197.656 358.669 202.395 360.853 206.812C366.512 218.297 377.589 227.051 391.658 229.059Z" fill="#B8F5F2"/>
    <path opacity="0.25" d="M442.596 60.478C443.489 60.478 444.213 59.7588 444.213 58.8717C444.213 57.9845 443.489 57.2654 442.596 57.2654C441.703 57.2654 440.979 57.9845 440.979 58.8717C440.979 59.7588 441.703 60.478 442.596 60.478Z" fill="#0A2142"/>
    <path opacity="0.25" d="M275.471 208.338C276.364 208.338 277.088 207.618 277.088 206.731C277.088 205.844 276.364 205.125 275.471 205.125C274.578 205.125 273.854 205.844 273.854 206.731C273.854 207.618 274.578 208.338 275.471 208.338Z" fill="#0A2142"/>
    <path opacity="0.25" d="M132.682 84.6534C133.575 84.6534 134.299 83.9342 134.299 83.0471C134.299 82.16 133.575 81.4408 132.682 81.4408C131.789 81.4408 131.065 82.16 131.065 83.0471C131.065 83.9342 131.789 84.6534 132.682 84.6534Z" fill="#0A2142"/>
    <path opacity="0.25" d="M278.705 60.478C279.598 60.478 280.322 59.7588 280.322 58.8717C280.322 57.9845 279.598 57.2654 278.705 57.2654C277.812 57.2654 277.088 57.9845 277.088 58.8717C277.088 59.7588 277.812 60.478 278.705 60.478Z" fill="#0A2142"/>
    <path opacity="0.25" d="M440.979 153.082C441.872 153.082 442.596 152.363 442.596 151.475C442.596 150.588 441.872 149.869 440.979 149.869C440.086 149.869 439.362 150.588 439.362 151.475C439.362 152.363 440.086 153.082 440.979 153.082Z" fill="#0A2142"/>
    <path opacity="0.25" d="M234.235 116.779C235.128 116.779 235.852 116.06 235.852 115.173C235.852 114.286 235.128 113.567 234.235 113.567C233.342 113.567 232.618 114.286 232.618 115.173C232.618 116.06 233.342 116.779 234.235 116.779Z" fill="#0A2142"/>
    <path opacity="0.25" d="M68.5646 83.0468C69.4577 83.0468 70.1817 82.3277 70.1817 81.4405C70.1817 80.5534 69.4577 79.8342 68.5646 79.8342C67.6715 79.8342 66.9475 80.5534 66.9475 81.4405C66.9475 82.3277 67.6715 83.0468 68.5646 83.0468Z" fill="#0A2142"/>
    <path opacity="0.25" d="M395.216 201.913C396.109 201.913 396.833 201.193 396.833 200.306C396.833 199.419 396.109 198.7 395.216 198.7C394.322 198.7 393.599 199.419 393.599 200.306C393.599 201.193 394.322 201.913 395.216 201.913Z" fill="#0A2142"/>
    <path opacity="0.25" d="M373.547 68.5907L328.349 44.4159L373.547 20.0001L418.744 44.4159L373.547 68.5907Z" stroke="#0A2142" strokeLinecap="round" strokeLinejoin="round"/>
    <path opacity="0.25" d="M373.547 92.7639L328.349 68.5891L373.547 44.2537L418.744 68.5891L373.547 92.7639Z" stroke="#0A2142" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="6 6"/>
    <path opacity="0.25" d="M373.547 116.939L328.349 92.7646L373.547 68.4292L418.744 92.7646L373.547 116.939Z" stroke="#0A2142" strokeLinecap="round" strokeLinejoin="round"/>
    <path opacity="0.25" d="M373.547 141.114L328.349 116.939L373.547 92.6038L418.744 116.939L373.547 141.114Z" stroke="#0A2142" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="6 6"/>
    <path opacity="0.25" d="M373.547 165.289L328.349 141.114L373.547 116.779L418.744 141.114L373.547 165.289Z" stroke="#0A2142" strokeLinecap="round" strokeLinejoin="round"/>
    <path opacity="0.25" d="M373.547 269.216L328.349 245.041L373.547 220.626L418.744 245.041L373.547 269.216Z" stroke="#0A2142" strokeLinecap="round" strokeLinejoin="round"/>
    <path opacity="0.25" d="M373.547 293.391L328.349 269.216L373.547 244.801L418.744 269.216L373.547 293.391Z" stroke="#0A2142" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="6 6"/>
    <path opacity="0.25" d="M373.547 189.464L328.349 165.289L373.547 140.954L418.744 165.289L373.547 189.464Z" stroke="#0A2142" strokeLinecap="round" strokeLinejoin="round"/>
    <path opacity="0.25" d="M321.962 161.916L254.853 126.015" stroke="#0A2142" strokeLinecap="round" strokeLinejoin="round"/>
    <path opacity="0.25" d="M548.434 161.916L429.74 98.3871" stroke="#0A2142" strokeLinecap="round" strokeLinejoin="round"/>
    <path opacity="0.25" d="M468.874 238.055L423.676 213.88L468.874 189.464L514.071 213.88L468.874 238.055Z" stroke="#0A2142" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="6 6"/>
    <path opacity="0.25" d="M214.911 48.9929L169.713 24.8181L214.911 0.402344L260.108 24.8181L214.911 48.9929Z" stroke="#0A2142" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="6 6"/>
    <path opacity="0.25" d="M214.911 73.1675L169.713 48.9927L214.911 24.5769L260.108 48.9927L214.911 73.1675Z" stroke="#0A2142" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="6 6"/>
    <path opacity="0.25" d="M214.911 97.3429L169.713 73.1681L214.911 48.7523L260.108 73.1681L214.911 97.3429Z" stroke="#0A2142" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="6 6"/>
  </g>
  <defs>
    <clipPath id="clip0">
      <rect width="549" height="306" fill="white"/>
    </clipPath>
  </defs>
</svg>

export const ManufacturerReviewBannerIcon = () => <svg width="497" height="135" viewBox="0 0 497 135" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0)">
    <path opacity="0.5" d="M109.108 18.5715C103.149 17.6928 97.3772 21.3957 95.5773 27.2325C95.0187 29.0526 94.8945 30.8726 95.0807 32.6299C95.329 34.701 96.1358 36.6466 97.253 38.2784C99.6736 42.9227 100.605 48.4457 99.3632 53.9686C99.0529 55.2239 98.6806 56.4791 98.2461 57.6088C97.6875 58.864 97.253 60.182 96.9427 61.6255C96.5082 63.571 96.3221 65.5794 96.4462 67.525C96.9427 77.6295 104.204 86.4788 114.631 88.2988C125.741 90.2444 136.665 83.1524 139.52 72.1065C141.134 65.956 140.016 59.8054 136.975 54.7845C135.051 51.6465 132.444 49.0105 129.217 47.1277C123.693 43.1738 120.217 36.8977 119.721 29.994C119.721 29.9312 119.721 29.8685 119.721 29.8057C119.597 28.2367 119.162 26.7304 118.542 25.4124C116.804 21.9606 113.452 19.1991 109.108 18.5715Z" fill="#B8F5F2"/>
    <path opacity="0.5" d="M376.362 179.051C391.258 181.185 405.782 171.959 410.25 157.399C411.678 152.88 411.988 148.298 411.43 143.905C410.747 138.759 408.823 133.863 405.906 129.721V129.658C399.761 118.047 397.589 104.24 400.63 90.3699C401.313 87.1691 402.306 84.1566 403.485 81.2068C404.851 78.006 405.968 74.6797 406.713 71.2278C407.83 66.2697 408.202 61.3743 407.954 56.5418C407.954 56.479 407.954 56.479 407.954 56.479C406.775 31.2491 388.527 8.96899 362.398 4.38744C334.592 -0.445148 307.345 17.2534 300.084 44.8682C296.049 60.2447 298.842 75.6838 306.414 88.1732C311.193 95.9556 317.835 102.608 325.903 107.253C339.682 117.169 348.495 132.922 349.612 150.181C349.612 150.307 349.674 150.495 349.674 150.62C349.985 154.512 351.04 158.214 352.715 161.666C357.06 170.641 365.563 177.482 376.362 179.051Z" fill="#B8F5F2"/>
    <path opacity="0.25" d="M415.464 47.3146C416.15 47.3146 416.705 46.7526 416.705 46.0594C416.705 45.3662 416.15 44.8042 415.464 44.8042C414.778 44.8042 414.223 45.3662 414.223 46.0594C414.223 46.7526 414.778 47.3146 415.464 47.3146Z" fill="#1E2533"/>
    <path opacity="0.25" d="M177.566 66.2062C178.252 66.2062 178.807 65.6442 178.807 64.951C178.807 64.2578 178.252 63.6958 177.566 63.6958C176.881 63.6958 176.325 64.2578 176.325 64.951C176.325 65.6442 176.881 66.2062 177.566 66.2062Z" fill="#1E2533"/>
    <path opacity="0.25" d="M289.657 47.3146C290.342 47.3146 290.898 46.7526 290.898 46.0594C290.898 45.3662 290.342 44.8042 289.657 44.8042C288.971 44.8042 288.415 45.3662 288.415 46.0594C288.415 46.7526 288.971 47.3146 289.657 47.3146Z" fill="#1E2533"/>
    <path opacity="0.25" d="M414.223 119.678C414.908 119.678 415.464 119.116 415.464 118.423C415.464 117.73 414.908 117.168 414.223 117.168C413.537 117.168 412.981 117.73 412.981 118.423C412.981 119.116 413.537 119.678 414.223 119.678Z" fill="#1E2533"/>
    <path opacity="0.25" d="M255.52 91.3112C256.206 91.3112 256.762 90.7492 256.762 90.056C256.762 89.3628 256.206 88.8008 255.52 88.8008C254.835 88.8008 254.279 89.3628 254.279 90.056C254.279 90.7492 254.835 91.3112 255.52 91.3112Z" fill="#1E2533"/>
    <path opacity="0.25" d="M128.348 64.9513C129.034 64.9513 129.589 64.3894 129.589 63.6961C129.589 63.0029 129.034 62.4409 128.348 62.4409C127.662 62.4409 127.107 63.0029 127.107 63.6961C127.107 64.3894 127.662 64.9513 128.348 64.9513Z" fill="#1E2533"/>
    <path opacity="0.25" d="M362.46 53.6539L327.765 34.7629L362.46 15.6836L397.154 34.7629L362.46 53.6539Z" stroke="#1E2533" strokeLinecap="round" strokeLinejoin="round"/>
    <path opacity="0.25" d="M362.46 72.5458L327.765 53.6547L362.46 34.6382L397.154 53.6547L362.46 72.5458Z" stroke="#1E2533" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="6 6"/>
    <path opacity="0.25" d="M362.46 91.4369L327.765 72.5459L362.46 53.5293L397.154 72.5459L362.46 91.4369Z" stroke="#1E2533" strokeLinecap="round" strokeLinejoin="round"/>
    <path opacity="0.25" d="M362.46 110.327L327.765 91.4365L362.46 72.4199L397.154 91.4365L362.46 110.327Z" stroke="#1E2533" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="6 6"/>
    <path opacity="0.25" d="M362.46 129.219L327.765 110.328L362.46 91.311L397.154 110.328L362.46 129.219Z" stroke="#1E2533" strokeLinecap="round" strokeLinejoin="round"/>
    <path opacity="0.25" d="M362.46 148.109L327.765 129.218L362.46 110.202L397.154 129.218L362.46 148.109Z" stroke="#1E2533" strokeLinecap="round" strokeLinejoin="round"/>
    <path opacity="0.25" d="M322.862 126.582L271.347 98.5283" stroke="#1E2533" strokeLinecap="round" strokeLinejoin="round"/>
    <path opacity="0.25" d="M496.708 126.583L405.596 76.939" stroke="#1E2533" strokeLinecap="round" strokeLinejoin="round"/>
    <path opacity="0.25" d="M240.687 38.3405L205.992 19.4494L240.687 0.370117L275.382 19.4494L240.687 38.3405Z" stroke="#1E2533" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="6 6"/>
    <path opacity="0.25" d="M240.687 57.2311L205.992 38.3401L240.687 19.2607L275.382 38.3401L240.687 57.2311Z" stroke="#1E2533" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="6 6"/>
    <path opacity="0.25" d="M240.687 76.1227L205.992 57.2317L240.687 38.1523L275.382 57.2317L240.687 76.1227Z" stroke="#1E2533" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="6 6"/>
  </g>
  <defs>
    <clipPath id="clip0">
      <rect width="497" height="135" fill="white"/>
    </clipPath>
  </defs>
</svg>
