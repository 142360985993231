import React, { Fragment } from 'react'
import { Formik } from 'formik';
import * as yup from 'yup';
import { Modal, Form, Dimmer, Loader } from 'semantic-ui-react'
import cx from 'classnames';
import { connect } from 'react-redux';

import AddFundingSource from './AddFundingSource';
import { DwollaBankAccountFormModel } from '../../../store/appcommon/types';

import { RootState } from '../../../store/RootState';

import '../../../themes/kalderos/Common/dwolla/AddFundingSourceModal.scss';
import { dwollaAddFundingSourceSuccess, getFundingSourceToken, getPaymentSummary, resetAddFundingSource } from '../../../store/payments/common/actionCreators';
import { DwollaErrorModel } from '../../../store/payments/common/types';
import { ErrorBanner } from '../errors/ErrorBanner';
import { DwollaError } from './DwollaError';
import CloseButton from '../CloseButton';

export const BankAccountSuccess = ({name, close}: {name: string, close: () => void}) => {
  return (
    <div className="success-message">
      <div className="header-text">{name} information has been successfully attached!</div>
      <div className="success-body">
      In 1-3 days, we&apos;ll make two deposits of 10&cent; or less into the account you provided. Please note the amount and return in order to finish verifying your account.
      </div>
      <button type="button" className="app-button button-dark bottom-right-close-button" onClick={close}>Close</button>
    </div>
  )
}

interface AddFundingSourceModalState {
  modalOpen: boolean
  input: DwollaBankAccountFormModel
  formSubmitted: boolean
  formError: boolean
  routingNumberError: boolean
  accountNumberError: boolean
  duplicateError: boolean
  genericError: boolean
  formSuccess: boolean
}

export interface AddFundingSourceModalProps {
  modalTriggerText: string
  modalTriggerStyles: string
  fundingSourceToken: string
  initiateMicroDepositLoading: boolean
  initiateMicroDepositSuccess: boolean
  initiateMicroDepositError: string
  fundingSourceTokenIsLoading: boolean
  fundingSourceTokenError: String
  dwollaEnvironment: string
  addFundingSourceLoading: boolean
  onSuccess?: ()=> void
  onClose?: () => void
  getPaymentSummary: () => void
  getFundingSourceToken: () => void
  dwollaAddFundingSourceSuccess: (fundingSourceId: string) => void
  resetAddFundingSource: () => void
}

const initialState = {
  modalOpen: false,
  input: {
    routingNumber: '',
    accountNumber: '',
    name: '',
    type: 'checking'
  },
  formSubmitted: false,
  formError: false,
  routingNumberError: false,
  accountNumberError: false,
  duplicateError: false,
  genericError: false,
  formSuccess: false,
};

export class AddFundingSourceModal extends React.Component<AddFundingSourceModalProps, AddFundingSourceModalState> {
  constructor (props: AddFundingSourceModalProps) {
    super(props);
    this.state = initialState;
  }

  onOpen = () => {
    this.props.getFundingSourceToken();
    this.setState({ modalOpen: true })
  };

  onClose = () => {
    this.props.resetAddFundingSource();
    this.setState(initialState);
    if (this.props.onClose) {
      this.props.onClose();
    }

  }


  onSubmit = (valuesFromForm: DwollaBankAccountFormModel) => {
    this.setState({
      input: valuesFromForm,
      formError: false,
      routingNumberError: false,
      accountNumberError: false,
      duplicateError: false,
      genericError: false,
      formSubmitted: true,
    });
  }

  onError = (error: DwollaErrorModel) => {
    if (error.code === 'ValidationError') {
      const { fieldErrors } = error;
      for (let i = 0; i < fieldErrors.length; i++ ) {
        const lowerError = fieldErrors[i].toLowerCase();
        if (lowerError.indexOf('routing') >= 0) {
          this.setState({ routingNumberError: true })
        }
        if (lowerError.indexOf('account') >= 0) {
          this.setState({ accountNumberError: true })
        }
      }
      // if for whatever reason dwolla returns a validation error that is not related
      // to routing or account, then set the generic error state to true
      if ( !this.state.routingNumberError && !this.state.accountNumberError ) {
        this.setState({ genericError: true })
      }
    } else if (error.code === 'DuplicateResource') {
      this.setState({ duplicateError: true });
    } else if (error.code !== '') {
      this.setState({ genericError: true })
    }

    this.setState({ formError: true, formSubmitted: false });
    this.props.getFundingSourceToken();
  }

  onSuccess = (fundingSourceId: string) => {
    if (!this.state.formSuccess) { // ensure we are not submitting twice
      this.setState({ formSuccess: true, formSubmitted: false });
      this.props.dwollaAddFundingSourceSuccess(fundingSourceId);
      if (this.props.onSuccess){
        this.props.onSuccess();
      }
    }
  }

  render () {

    const initialValues = this.state.input;
    const isFundingSourceInfoLoading = (this.state.formSubmitted || this.props.initiateMicroDepositLoading || this.props.fundingSourceTokenIsLoading || this.props.addFundingSourceLoading);
    const schema = yup.object().shape({
      accountNumber: yup
        .string()
        .required()
        .test(
          'account',
          'enter between 4 and 17 digits',
          function (item) {
            if (item) {
              const isCorrectLength = item.length >= 4 && item.length <= 17;
              const isAllNumbers = /^[0-9]*$/.test(item);
              return isCorrectLength && isAllNumbers;
            }
            return true;
          }
        ),
      routingNumber: yup
        .string()
        .required()
        .max(9)
        .test(
          'routing',
          'enter 9 digits',
          function (item) {
            if (item && !(/^\d{9}$/.test(item))){
              return false;
            }
            return true;
          }
        ),
      type: yup
        .string()
        .required(),
      name: yup
        .string()
        .required()
        .max(100)
        .trim(),
    });
    return (
      <Modal
        trigger={
          <button
            data-testid="modalTrigger"
            className={cx('app-button', this.props.modalTriggerStyles)}
            onClick={this.onOpen}
            type="button"
            id='addAccount_Pendo'
          >
            {this.props.modalTriggerText}
          </button>}
        open={this.state.modalOpen}
        onClose={this.onClose}
        className="add-funding-source-modal"
        closeOnDimmerClick={false}
      >
        <div className="modal-close-button">
          <CloseButton isDisabled={isFundingSourceInfoLoading} data-testid="modalClose" onClick={this.onClose} />
        </div>
        {this.props.fundingSourceTokenError ?
          <div className="full-error">
            <DwollaError isModal/>
            <div>
              <button type="button" className="app-button button-dark bottom-right-close-button" onClick={this.onClose}>Close</button>
            </div>
          </div>:<Fragment>
            {(this.state.formSuccess && (this.props.initiateMicroDepositSuccess || (!this.props.initiateMicroDepositLoading && this.props.initiateMicroDepositError))) ? <BankAccountSuccess name={this.state.input.name} close={this.onClose}/>
              : <Fragment>
                <div className="header-text">
              Please provide your bank or credit union account details.
                </div>
                <div className="error-wrapper">

                  {// wait to show error until new funding source token
                    // has been returned
                    !this.props.fundingSourceTokenIsLoading &&
                <Fragment>
                  {this.state.duplicateError &&
                    <ErrorBanner
                      title={'Duplicate Funding Source'}
                      errorText={'The account entered is currently an active Funding Source. Try adding a different account.'}
                    />
                  }
                  {this.state.genericError &&
                    <ErrorBanner
                      errorText={'An error occurred. Please try again.'}
                    />
                  }
                </Fragment>
                  }
                </div>

                <Formik
                  initialValues={initialValues}
                  validationSchema={schema}
                  onSubmit={ (values) => this.onSubmit(values) }
                >
                  {props => (
                    <Form className="input-form bank-account-form" onSubmit={props.handleSubmit}>
                      <Form.Field data-private>
                        <label>Account type</label>
                        <div className="radio-buttons">
                          <Form.Radio
                            label="Checking"
                            value="checking"
                            checked={props.values.type === 'checking'}
                            onChange={(e, d) => props.setFieldValue(`type`, d.value as string)}
                          />
                          <Form.Radio
                            label="Savings"
                            value="savings"
                            checked={props.values.type === 'savings'}
                            onChange={(e, d) => props.setFieldValue(`type`, d.value as string)}
                          />
                        </div>
                      </Form.Field>
                      <Form.Input
                        name="accountNumber"
                        label="Account number"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        width={5}
                        className={cx({'input-error': (this.state.accountNumberError && !this.props.fundingSourceTokenIsLoading) || (props.errors?.accountNumber && props.touched?.accountNumber) })}
                        value={props.values.accountNumber}
                        data-private
                      />
                      {((this.state.accountNumberError && !this.props.fundingSourceTokenIsLoading) || (props.errors?.accountNumber && props.touched?.accountNumber)) &&
                    <div className="error-text">
                      Account number invalid
                    </div>
                      }
                      <Form.Input
                        name="routingNumber"
                        label="Routing number"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        width={5}
                        className={cx({'input-error': (this.state.routingNumberError && !this.props.fundingSourceTokenIsLoading) || (props.errors?.routingNumber && props.touched?.routingNumber) })}
                        value={props.values.routingNumber}
                        data-private
                      />
                      { ((this.state.routingNumberError && !this.props.fundingSourceTokenIsLoading) || (props.errors?.routingNumber && props.touched?.routingNumber)) &&
                    <div className="error-text">
                      Routing number invalid
                    </div>
                      }
                      <Form.Input
                        name="name"
                        label="Account name"
                        placeholder={'Example: "Bank Name - Checking"'}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        width={12}
                        value={props.values.name}
                        className={cx({'input-error': props.errors?.name && props.touched?.name })}
                        data-private
                      />
                      {props.errors?.name && props.touched?.name && <div className="error-text">Account name invalid</div>}
                      <button
                        className="app-button button-dark bottom-right-button"
                        type="submit"
                        disabled={!props.isValid || !props.dirty || this.state.formSubmitted || this.props.initiateMicroDepositLoading || this.props.fundingSourceTokenIsLoading}>
                      Add Bank
                        {isFundingSourceInfoLoading &&
                      <Dimmer active inverted><Loader inverted /></Dimmer>
                        }
                      </button>
                    </Form>
                  )}
                </Formik>

                {this.state.formSubmitted &&
          <AddFundingSource
            onSuccess={this.onSuccess}
            onError={this.onError}
            //@ts-ignore
            environment={this.props.dwollaEnvironment}
            fundingSourceToken={ this.props.fundingSourceToken}
            bankInfo={this.state.input}
          />
                }
              </Fragment>
            }
          </Fragment>}
      </Modal>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  initiateMicroDepositLoading: state.PaymentsState.common.initiateMicroDeposit.isLoading,
  initiateMicroDepositSuccess: state.PaymentsState.common.initiateMicroDeposit.isSuccess,
  initiateMicroDepositError: state.PaymentsState.common.initiateMicroDeposit.errorText,
  fundingSourceToken: state.PaymentsState.common.fundingSourceToken.token,
  fundingSourceTokenIsLoading: state.PaymentsState.common.fundingSourceToken.isLoading,
  fundingSourceTokenError: state.PaymentsState.common.fundingSourceToken.errorText,
  dwollaEnvironment: state.PaymentsState.common.dwollaEnvironment.environment,
  addFundingSourceLoading: state.PaymentsState.common.addFundingSource.isLoading
})

const mapDispatchToProps = (dispatch: any) => ({
  dwollaAddFundingSourceSuccess: (fundingSourceId: string) => dispatch(dwollaAddFundingSourceSuccess(fundingSourceId)),
  getFundingSourceToken: () => dispatch(getFundingSourceToken()),
  getPaymentSummary: () => dispatch(getPaymentSummary()),
  resetAddFundingSource: () => dispatch(resetAddFundingSource())
});

export default connect(mapStateToProps, mapDispatchToProps)(AddFundingSourceModal);
