import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { UserInfoModel } from '../../store/applicationTypes';
import { getUserInfo } from '../../store/account/actionCreators';
import { RootState } from '../../store/RootState';
import { connect } from 'react-redux';
import ChangePassword from '../password/ChangePassword';
import { resetChangePasswordStatus } from '../../store/password/actionCreators'
import { formatEmpty } from '../../utils/dataUtil';

interface ProfileSettingsProps {
  userInfo: UserInfoModel,
  error: string, //todo: show this somewhere
  isLoading: boolean;
  getUserInfo: () => void;
  resetChangePasswordStatus: () => void;
}

export class ProfileSettings extends React.Component<ProfileSettingsProps, { changePasswordOpen: boolean }>{
  constructor (props: Readonly<ProfileSettingsProps>) {
    super(props);
    this.state = {
      changePasswordOpen: false
    }
  }

  componentDidMount () {
    if (!this.props.userInfo.id) {
      this.props.getUserInfo();
    }
  }

  closeChangePasswordModal = () => {
    this.setState({ changePasswordOpen: false })
    this.props.resetChangePasswordStatus();
  }

  changePassword = () => {
    this.setState({ changePasswordOpen: true })
  }

  render () {
    const { email, firstName, lastName, jobTitle, phone, address } = this.props.userInfo;

    return (
      <div className="profile-settings">
        {this.props.isLoading && <Dimmer active inverted><Loader inverted content='Loading' /></Dimmer>}

        <div className="profile-settings-container">
          <div className="profile-settings-header">
            <span className="beta">Profile Settings</span>
          </div>
          <div className="profile-settings-body">
            <div className="account-info-section">
              <div className="account-info-section-header">Information</div>
              <div className="account-info-row">
                <div className="account-info-label">Name</div>
                <div data-private className="account-info-value">{(firstName || lastName) ? `${firstName ? firstName : ''} ${lastName ? lastName : ''}` : '- -'}</div>
              </div>
              <div className="account-info-row">
                <div className="account-info-label">Job Title</div>
                <div data-private className="account-info-value">{formatEmpty(jobTitle)}</div>
              </div>
              <div className="account-info-row">
                <div className="account-info-label">Email Address</div>
                <div data-private className="account-info-value">{formatEmpty(email)}</div>
              </div>
              <div className="account-info-row">
                <div className="account-info-label">Primary Phone</div>
                <div data-private className="account-info-value">{formatEmpty(phone)}</div>
              </div>
              <div className="account-info-row">
                <div className="account-info-label">Office Address</div>
                <div data-private className="account-info-value">{(address?.address1 || address?.address2) ? `${address.address1 ? address.address1 : ''} ${(address.address2) ? address.address2 : ''}` : '- -'}</div>
              </div>
              <div className="account-info-row">
                <div className="account-info-label">City</div>
                <div data-private className="account-info-value">{formatEmpty(address?.city)}</div>
              </div>
              <div className="account-info-row">
                <div className="account-info-label">State</div>
                <div data-private className="account-info-value">{formatEmpty(address?.state)}</div>
              </div>
              <div className="account-info-row">
                <div className="account-info-label">Zip Code</div>
                <div data-private className="account-info-value">{formatEmpty(address?.zip)}</div>
              </div>
            </div>
            <div className="account-info-section">
              <div className="account-info-section-header">Security</div>
              <div className="account-info-row">
                <div className="account-info-label">Password</div>
                <div className="account-info-value">
                  <button className="app-button change-password-link profileSettingsChangePassword_Pendo" type='button' onClick={this.changePassword}>Change Password</button>
                </div>
              </div>
              {/* Disabled until functional */}
              {/* <div className="account-info-row">
                <div className="account-info-label">Two Factor Authentication</div>
                <div className="account-info-value">
                  <button className="app-button change-password-link" type='button'>Turn On</button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {this.state.changePasswordOpen && <ChangePassword closeModal={this.closeChangePasswordModal} />}
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  userInfo: state.AccountInformationState.userInfo,
  error: state.AccountInformationState.error,
  isLoading: state.AccountInformationState.isLoading
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    getUserInfo: () => dispatch(getUserInfo()),
    resetChangePasswordStatus: () => dispatch(resetChangePasswordStatus())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings)
