import React, { Fragment } from 'react';
import { Button, Loader } from 'semantic-ui-react';
import {  HealthSystem } from '../../../store/teammanagement/types';
import cx from 'classnames';

export interface TeamListComponentProps {
  healthSystems: HealthSystem[];
  showId: number | null;
  waiting: boolean;
  setShowId: (id: number | null) => void;
  showCeList: (id: number, name: any) => void;
}

export const TeamListComponent: React.FunctionComponent<TeamListComponentProps> = ({  setShowId, showCeList, healthSystems, showId, waiting }) => {
  if (!healthSystems) return null;
  if (waiting) return <Loader className="modal-loader" active />;
  return (
    <Fragment>
      <div className="team-list-top flex-row">
        <span>Health System</span>
        <span>Covered Entities</span>
      </div>
      <div className="team-list-wrapper">
        <div className="team-list-row">
          {
            healthSystems && healthSystems.map((healthSystem: HealthSystem) => {
              return (
                <span key={healthSystem.healthSystemId}>
                  <div onClick={() => setShowId(healthSystem.healthSystemId)} className={cx('content', {
                    'light-blue-background': showId === healthSystem.healthSystemId
                  })}>
                    <div className="team-name">
                      <div className="overflow-ellipsis">{healthSystem.healthSystemName}</div>
                      {healthSystem.onboardingInProgress && <span className="team-onboarding">(onboarding)</span>}
                    </div>
                    <Button onClick={() => showCeList(healthSystem.healthSystemId, healthSystem.healthSystemName)}>View</Button>
                  </div>
                </span>
              )
            })
          }

        </div>
      </div>
    </Fragment>
  )
};

export default TeamListComponent;
