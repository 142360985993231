import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Table, Icon, Divider, Button } from 'semantic-ui-react';
import { FEATURE_FLAGS, ROLES } from '../../store/applicationConstants';
import { AddressModel } from '../../store/applicationTypes';
import { CoveredEntityModel } from '../../store/payments/recipient/types';
import FeatureFlagContainer from '../common/FeatureFlagContainer';
import RestrictedByRole from '../teammanagement/RestrictedByRole';

export const Address = ({ addressTitle, address }: { addressTitle: string, address: AddressModel }) => {
  return (
    <div data-private className='flex-column'>
      <label className='addressTitle'>{addressTitle}</label>
      <label className='formlabel addressLabel'>{address.address1}</label>
      <label className='formlabel addressLabel'>{address.address2}</label>
      <label className='formlabel addressLabel'>{address.city + ', ' + address.state + '  ' + address.zip}</label>
    </div>
  );
}

interface CoveredEntityBillingInfoRowProps {
  coveredEntity: CoveredEntityModel;
  featureFlags: string[];
}

export class CoveredEntityBillingInfoRow extends React.Component<CoveredEntityBillingInfoRowProps> {
  state = {
    isOpen: false
  }

  renderCoveredEntityAddresses () {
    const { coveredEntity } = this.props;
    const { isOpen } = this.state;

    if (isOpen) {
      return (
        <Table.Row>
          <Table.Cell className='addressCell' colSpan={5}>
            <div className='ce-info'>
              <Divider style={{ margin: '0' }} />
              <div className='flex-row ce-info-address'>
                <div className='flex-column ce-info-street-address'>
                  <Address addressTitle={'Street Address'} address={coveredEntity.streetAddress} />
                </div>
                <div className='flex-column ce-info-billing-address'>
                  <Address addressTitle={'Billing Address'} address={coveredEntity.streetAddress} />
                </div>
              </div>
            </div>
          </Table.Cell>
        </Table.Row>
      );
    }

    return null;
  }

  render () {
    const { isOpen } = this.state;
    const { coveredEntity, featureFlags } = this.props;

    return (
      <Fragment>
        <Table.Row onClick={() => this.setState({ isOpen: !isOpen })} className="ce-summary">
          <Table.Cell className='accordionIcon'><Icon name={isOpen ? 'angle up' : 'angle down'} /></Table.Cell>
          <Table.Cell data-private className='idCode' width={3}>{coveredEntity.idCode}</Table.Cell>
          <Table.Cell data-private className='name340B' width={9}>{coveredEntity.name340B}</Table.Cell>
          <Table.Cell className='edit-cell' width={4}>
            <RestrictedByRole allowedRoles={[ROLES.ADMIN_ALL_ACCESS]} checkUserRole>
              <FeatureFlagContainer entitledFeatureFlags={featureFlags} featureFlag={FEATURE_FLAGS.PHARMACY_MANAGEMENT}>
                <Link to={`/recipients/coveredentityinfo/${coveredEntity.idCode}/associatedpharmacies`}>
                  <Button id='viewPharmacies_Pendo' className='app-button button-white edit-cell-button'>
                  View Pharmacies
                  </Button>
                </Link>
              </FeatureFlagContainer>
            </RestrictedByRole>
          </Table.Cell>
        </Table.Row>
        {this.renderCoveredEntityAddresses()}
      </Fragment>
    );
  }
}

export default CoveredEntityBillingInfoRow
