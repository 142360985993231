import React from 'react'
import { Form, Dimmer, Loader } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as yup from 'yup';
import cx from 'classnames';
import { validateToken, resetPassword } from '../../store/password/actionCreators';
import { connect } from 'react-redux';
import { RootState } from '../../store/RootState';
import { UserModel, LoginModel } from '../../store/login/types';
import ResetPasswordError from './ResetPasswordError';
import PasswordValidation from '../common/PasswordValidation';
import { Redirect } from 'react-router-dom';

interface ResetPasswordState {
  showPasswordValidation: boolean;
  showPassword: boolean;
  showConfirmPassword: boolean;
  isPasswordValid: boolean;
}

interface ResetPasswordProps {
  token: string;
  resetPasswordErrorText: string;
  resetPasswordSuccess: boolean;
  tempUser?: UserModel;
  user: UserModel;
  isLoading: boolean;
  validateToken: (token: string) => void;
  resetPassword: (loginModel: LoginModel) => void;
}

const initialValues = {
  password: '',
  confirmPassword: '',
}

const schema = yup.object().shape({
  password: yup
    .string()
    .required()
    .max(100),
  confirmPassword: yup
    .string()
    .required()
    .max(100)
})

export class ResetPassword extends React.Component<ResetPasswordProps, ResetPasswordState>{
  constructor (props: Readonly<ResetPasswordProps>) {
    super(props)
    this.state = {
      showPasswordValidation: false,
      showPassword: false,
      showConfirmPassword: false,
      isPasswordValid: false,
    }
  }

  componentDidMount () {
    this.props.validateToken(this.props.token)
  }

  handlePasswordValidation = (isValid: boolean) => {
    this.setState({ isPasswordValid: isValid });
  }

  handleResetPasswordSubmit = (password: string) => {
    const loginModel: LoginModel = {
      userName: this.props.tempUser?.userName || '',
      password: password,
    }
    this.props.resetPassword(loginModel);
  }

  render () {
    const { resetPasswordSuccess, resetPasswordErrorText, user, isLoading } = this.props
    const { showPasswordValidation, showConfirmPassword: showSecondPassword, showPassword, isPasswordValid } = this.state

    if (resetPasswordErrorText) {
      return (
        <ResetPasswordError message={resetPasswordErrorText} />
      )
    }

    if (resetPasswordSuccess && user && user.isAuthenticated && user.userName && user.roles && user.roles.length > 0) {
      return <Redirect to="/" />
    }

    return (
      <div className="login-page reset-password">
        <div className="login-page-layout">
          <div className="login-page-column-right">
            <div className="login-form-container">
              {isLoading && <Dimmer className="dimmer" active inverted><Loader inverted content='Loading' /></Dimmer>}

              <Formik
                initialValues={initialValues}
                validateOnMount={true}
                validationSchema={schema}
                onSubmit={(values) => {
                  this.handleResetPasswordSubmit(values.confirmPassword);
                }}>
                {props => (

                  <Form className="login-form" onSubmit={props.handleSubmit}>
                    <div className="login-form-header">Create your password</div>
                    <div className="login-form-body">
                      <Form.Input
                        data-private
                        name="password"
                        label={
                          <div className="password-label">
                            <label>New Password</label>
                            <button className={cx('app-button link-text', { 'hidden': !props.values.password })} type="button" onClick={() => this.setState({ showPassword: !showPassword })}>
                              {showPassword ? 'Hide' : 'Show'}
                            </button>
                          </div>
                        }
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Selection"
                        onChange={props.handleChange}
                        onFocus={() => { this.setState({ showPasswordValidation: true }) }}
                        value={props.values.password}
                        required={true}
                      >
                      </Form.Input>

                      {showPasswordValidation && <PasswordValidation password={props.values.password} onValidation={this.handlePasswordValidation} />}

                      <Form.Input
                        data-private
                        name="confirmPassword"
                        label={
                          <div className="password-label">
                            <label>Confirm Password</label>
                            <button className={cx('app-button link-text', { 'hidden': !props.values.confirmPassword })} type="button" onClick={() => this.setState({ showConfirmPassword: !showSecondPassword })}>
                              {showSecondPassword ? 'Hide' : 'Show'}
                            </button>
                          </div>
                        }
                        type={showSecondPassword ? 'text' : 'password'}
                        placeholder="Selection"
                        onChange={props.handleChange}
                        value={props.values.confirmPassword}
                        required={true}
                        className="confirm-password"
                      >
                      </Form.Input>

                      {props.values.confirmPassword && props.values.confirmPassword !== props.values.password &&
                        <div className='input-error-text'>Password does not match</div>
                      }
                    </div>

                    <div className="login-form-footer">
                      <button className="app-button cta" type="submit" disabled={!props.isValid || !isPasswordValid || props.values.confirmPassword !== props.values.password}>Reset and Sign In</button>
                    </div>

                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  resetPasswordErrorText: state.PasswordState.resetPasswordErrorText,
  resetPasswordSuccess: state.PasswordState.resetPasswordSuccess,
  tempUser: state.PasswordState.user,
  user: state.LoginState.user,
  isLoading: state.PasswordState.isLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    validateToken: (token: string) => { dispatch(validateToken(token, null)) },
    resetPassword: (loginModel: LoginModel) => { dispatch(resetPassword(loginModel)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)

