import { SortingFilter } from '../appcommon/types';
import { ErrorModel } from '../applicationTypes';
import { DiscountRequestResponseModel, TransactionsFilterModel } from '../transactionscommon/types';

export const GET_REVERSALS = 'GET_REVERSALS';
export const GET_REVERSALS_SUCCESS = 'GET_REVERSALS_SUCCESS';
export const GET_REVERSALS_FAILURE = 'GET_REVERSALS_FAILURE';

export const UPDATE_REVERSALS_PAGE = 'UPDATE_REVERSALS_PAGE';
export const UPDATE_REVERSALS_SORT = 'UPDATE_REVERSALS_SORT';
export const UPDATE_REVERSALS_DATE_RANGE = 'UPDATE_REVERSALS_DATE_RANGE';
export const UPDATE_REVERSALS_FILTER = 'UPDATE_REVERSALS_FILTER';

export const RESET_REVERSALS_TRANSACTIONS = 'RESET_REVERSALS_TRANSACTIONS';
export const RESET_REVERSALS = 'RESET_REVERSALS';

export enum REVERSALS_TAB {
  PENDING = 0,
  PROCESSED = 1
}

export const UI_PAGE_SIZE = 25;
export const API_PAGE_SIZE = 100;

interface GetReversalsAction {
  type: typeof GET_REVERSALS,
  payload: REVERSALS_TAB
}

interface GetReversalsSuccessAction {
  type: typeof GET_REVERSALS_SUCCESS,
  payload: DiscountRequestResponseModel
}

interface GetReversalsFailureAction {
  type: typeof GET_REVERSALS_FAILURE,
  payload: ErrorModel
}

interface UpdateReversalsPageAction {
  type: typeof UPDATE_REVERSALS_PAGE,
  payload: number
}

interface UpdateReversalsSortAction {
  type: typeof UPDATE_REVERSALS_SORT,
  payload: SortingFilter
}

interface UpdateReversalsFilterAction {
  type: typeof UPDATE_REVERSALS_FILTER,
  payload: {
    tab: REVERSALS_TAB,
    filter: TransactionsFilterModel
  }
}

interface ResetReversalsTransactionsAction {
  type: typeof RESET_REVERSALS_TRANSACTIONS
}

interface ResetReversalsAction {
  type: typeof RESET_REVERSALS
}


export type ReversalActionTypes =
  GetReversalsAction |
  GetReversalsSuccessAction |
  GetReversalsFailureAction |
  UpdateReversalsPageAction |
  UpdateReversalsSortAction |
  UpdateReversalsFilterAction |
  ResetReversalsTransactionsAction |
  ResetReversalsAction;