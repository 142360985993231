import React from 'react';
import { DropdownItemProps, Modal, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Formik } from 'formik';

import CloseButton from '../../common/CloseButton';
import { getRemovePharmacyReasonOptions } from '../../../store/coveredentityinfo/pharmacymanagement/selectors';
import { RootState } from '../../../store/RootState';

export interface RemovePharmacyDialogProps {
  isOpen: boolean;
  closeModal: () => void;
  onRemoveClick: () => void;
  removePharmacyReasons: DropdownItemProps[];
}

export const RemovePharmacyDialog: React.FunctionComponent<RemovePharmacyDialogProps> = ({ isOpen, closeModal, onRemoveClick, removePharmacyReasons }) => {
  const initialValues = {
    removalReason: ''
  };

  const schema = yup.object().shape({
    removalReason: yup.string().required()
  });

  return (
    <Modal className='remove-pharmacy-modal' 
      closeOnDimmerClick={false}  
      open={isOpen}>
      <div className='flex-column remove-pharmacy-modal-container'>
        <div className='flex-row remove-pharmacy-header-container'>
          <div className='beta remove-pharmacy-header'>Remove Pharmacy</div>
          <CloseButton onClick={closeModal} />
        </div>
        <div data-private className='delta remove-pharmacy-modal-message'>{`Please let us know why you want to remove and unassociate this pharmacy from this covered entity.`}</div>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={() => {}}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <div className='remove-pharmacy-modal-dropdown-container'>
                <Form.Select
                  name='reason'
                  onChange={(e, d) => props.setFieldValue('removalReason', d.value)}
                  options={removePharmacyReasons}
                  placeholder='Removal Reason'
                  value={props.values.removalReason}
                />
              </div>
              <div className='flex-row remove-pharmacy-btn-container'>
                <button id='doNotRemoveBtn_Pendo' type='button' className='app-button button-white' onClick={closeModal}>No, do not remove</button>
                <button
                  id='yesRemoveBtn_Pendo'
                  className='app-button button-red'
                  disabled={!props.isValid || !props.dirty}
                  onClick={onRemoveClick}
                  type='button'>
                  Yes, remove
                </button>
              </div>
            </Form>
          )}

        </Formik>
      </div>
    </Modal>
  )  
};

const mapStateToProps = (state: RootState) => ({
  removePharmacyReasons: getRemovePharmacyReasonOptions(state)
});

export default connect(mapStateToProps)(RemovePharmacyDialog);