import { call, put, takeEvery, all, select } from 'redux-saga/effects'
import { GET_BUSINESS_INFORMATION_REQUEST, GET_USER_INFORMATION_REQUEST } from './constants';
import { updateBusinessInfo, updateUserInfo,updateUserInfoErrors, updateBusinessInfoErrors } from './actionCreators';
import { fetchBusinessInfo, fetchUserInfo } from '../../api/account/AccountInformation';
import { formatPhone } from '../../utils/phoneFormatHelper';
import { getActiveHealthSystemId } from '../teammanagement/selectors';
import logRocketUtil from '../../utils/logRocketUtil';

function* fetchBusinessInfoSaga () {
  try {
    const activeHealthSystemId = yield select(getActiveHealthSystemId);
    const response = yield call(fetchBusinessInfo, activeHealthSystemId);
    yield put(updateBusinessInfo(response))
  } catch (e) {
    yield put(updateBusinessInfoErrors(logRocketUtil(e)))
  }
}

function* fetchUserInfoSaga () {
  try {
    const activeHealthSystemId = yield select(getActiveHealthSystemId);
    const response = yield call(fetchUserInfo, activeHealthSystemId);
    response.phone = formatPhone(response.phone);
    yield put(updateUserInfo(response))
  } catch (e) {
    yield put(updateUserInfoErrors(logRocketUtil(e)));
  }
}

function* watchGetBusinessInfo () {
  yield takeEvery(GET_BUSINESS_INFORMATION_REQUEST, fetchBusinessInfoSaga)
}

function* watchGetUserInfo () {
  yield takeEvery(GET_USER_INFORMATION_REQUEST, fetchUserInfoSaga)
}


export default function* watchAllAccountInformation () {
  yield all([
    watchGetBusinessInfo(),
    watchGetUserInfo()
  ])
}
