import { createStore, applyMiddleware, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import LogRocket from 'logrocket';
import rootSaga from './store/rootSagas';
import { RootState } from './store/RootState';
import { recipientsReducer } from './store/requestcenter/reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { appCommonReducer } from './store/appcommon/reducers';
import { toastReducer } from './store/toast/reducers';
import { loginReducer } from './store/login/reducers';
import { passwordReducer } from './store/password/reducers';
import { accountInfoReducer } from './store/account/reducers';
import { paymentsReducer } from './store/payments/reducers';
import { remittanceReducer } from './store/remittance/reducers';
import { CLEAR_USER_DETAILS } from './store/login/constants';
import { onboardingReducer } from './store/onboarding/reducers';
import { reversalsReducer } from './store/reversals/reducers';
import { teamManagementReducer } from './store/teammanagement/reducers';
import { pharmacyManagementReducer } from './store/coveredentityinfo/pharmacymanagement/reducers';
import { mdrpReducer } from './store/mdrp/reducers';
import { transactionsCommonReducer } from './store/transactionscommon/reducers';

const appReducer = combineReducers<RootState>({
  RequestCenterState: recipientsReducer,
  AppCommonState: appCommonReducer,
  ToastState: toastReducer,
  LoginState: loginReducer,
  PasswordState: passwordReducer,
  AccountInformationState: accountInfoReducer,
  PaymentsState: paymentsReducer,
  RemittanceState: remittanceReducer,
  OnboardingState: onboardingReducer,
  ReversalsState: reversalsReducer,
  TeamManagementState: teamManagementReducer,
  PharmacyManagementState: pharmacyManagementReducer,
  MdrpState: mdrpReducer,
  TransactionsCommon: transactionsCommonReducer
});

const rootReducer = (state: any, action: any) => {
  if (action.type === CLEAR_USER_DETAILS) {
    state = undefined
  }
  return appReducer(state, action)
}

const sagaMiddleware = createSagaMiddleware()

const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(sagaMiddleware, LogRocket.reduxMiddleware({
    //not logging any state or actions until users are fully using manage and we determine what state/actions we do and do not want to log
    stateSanitizer: () => {
      return {};
    },
    actionSanitizer: (action) => {
      if (action.type.includes('ERROR') || action.type.includes('FAILURE') || action.type.includes('FAILED')){
        return {
          errorAction: action.type,
          errorMessage: action.payload.message,
          traceId: action.payload.traceId
        }
      }
      return null
    }
  }
  ))),
);

sagaMiddleware.run(rootSaga);

export default store;
