import { put, all, call, takeEvery, select} from 'redux-saga/effects';

import { getDisputesCenterSummaryFailure, getDisputesCenterSummarySuccess, getManufacturerDisputesAdvancedFilterOptionsFailure, getManufacturerDisputesAdvancedFilterOptionsSuccess, getManufacturerDisputesFailure, getManufacturerDisputesPrimaryFilterOptionsFailure, getManufacturerDisputesPrimaryFilterOptionsSuccess, getManufacturerDisputesResponseTotals, getManufacturerDisputesResponseTotalsFailure, getManufacturerDisputesResponseTotalsSuccess, getManufacturerDisputesSuccess, getManufacturerSummariesFailure, getManufacturerSummariesSuccess, getManufacturerSummaryFailure, getManufacturerSummarySuccess, updateSingleClaimResponseFailure, updateSingleClaimResponseSuccess } from './actionCreators';
import { GET_DISPUTES_CENTER_SUMMARY, GET_MANUFACTURER_DISPUTES, GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS, GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS, GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS, GET_MANUFACTURER_SUMMARIES, GET_MANUFACTURER_SUMMARY, UPDATE_MANUFACTURER_DISPUTES_FILTERS, UPDATE_MANUFACTURER_DISPUTES_SORT, UPDATE_SINGLE_CLAIM_RESPONSE } from './constants';
import { getManufacturerClaims, selectManufacturerReviewActiveTabFilters } from './selectors';
import { ClaimModel, ManufacturerDisputesFilterModel } from './types';
import { API_PAGE_SIZE, UI_PAGE_SIZE } from '../../applicationConstants';
import { RootState } from '../../RootState';
import { fetchMdrpDisputeResponseTotals, fetchMdrpDisputesSummary, fetchMdrpManufacturerDisputes, fetchMdrpManufacturerDisputesFilterOptions, fetchMdrpManufacturerSummaries, updateMdrpSingleClaimResponse } from '../../../api/mdrp/disputescenter/DisputesCenter';
import { getApiPageToFetch } from '../../../utils/datatableHelper';
import logRocketUtil from '../../../utils/logRocketUtil';

const getManfacturerDisputesState = (state: RootState) => state.MdrpState.disputesCenter.disputesReview.manufacturerDisputes;
const getManfacturerDisputesFiltersState = (state: RootState) => selectManufacturerReviewActiveTabFilters(state);

const formatFilters = (filters: ManufacturerDisputesFilterModel) => {
  let formattedFilters: any = '';

  Object.keys(filters).forEach(key => {
    const value = (filters as { [key: string]: any })[key];
    const formattedKey = key.charAt(0).toUpperCase() + key.slice(1);

    if (Array.isArray(value)) {
      value.forEach(val => {
        formattedFilters = formattedFilters.concat(`&${formattedKey}=${val}`)
      })
    } else if (value) {
      if (key === 'maxDateOfService' || key === 'minDateOfService') {
        const dateUTCString = new Date(value).toUTCString();
        const dateUTC = new Date(dateUTCString).toJSON();
        formattedFilters = formattedFilters.concat(`&${formattedKey}=${dateUTC}`)
      } else {
        formattedFilters = formattedFilters.concat(`&${formattedKey}Search=${value}%25`)
      }
    }
  })

  return formattedFilters;
}

const addIndexToClaims = (claims: ClaimModel[]) => {
  const claimsWithIndex: ClaimModel[] = [];
  claims.forEach((claim: ClaimModel, index: number) => {
    claimsWithIndex.push({ ...claim, index: index })
  })

  return claimsWithIndex;
}

export function* getDisputesCenterSummarySaga (action: any) {
  try {
    const response = yield call(fetchMdrpDisputesSummary, action.payload)
    yield put(getDisputesCenterSummarySuccess(response))
  }
  catch (e){
    yield put(getDisputesCenterSummaryFailure(logRocketUtil(e)))
  }
}

export function* getManufacturerSummariesSaga (action: any) {
  try {
    const response = yield call(fetchMdrpManufacturerSummaries, action.payload)
    yield put(getManufacturerSummariesSuccess(response))
  } catch (e){
    yield put(getManufacturerSummariesFailure(logRocketUtil(e)))
  }
}

export function* getManufacturerSummarySaga (action: any) {
  try {
    const response = yield call(fetchMdrpManufacturerSummaries, action.payload.stateCode, action.payload.manufacturerId);
    const formattedResponse = response[0]
    yield put(getManufacturerSummarySuccess(formattedResponse))
  } catch (e){
    yield put(getManufacturerSummaryFailure(logRocketUtil(e)))
  }
}

export function* getManufacturerDisputesSaga (action: any) {
  try {
    const { stateCode, manufacturerId, disputeStatus } = action.payload;
    const filters: ManufacturerDisputesFilterModel = action.payload.filters ? action.payload.filters : yield select(getManfacturerDisputesFiltersState);
    const { currentPage, isSortAsc, sortBy } = yield select(getManfacturerDisputesState);
    const pageToFetch = getApiPageToFetch(currentPage, UI_PAGE_SIZE, API_PAGE_SIZE);
    const filterQuery = formatFilters(filters);
    const response = yield call(fetchMdrpManufacturerDisputes, stateCode, manufacturerId, disputeStatus, pageToFetch, sortBy, isSortAsc, filterQuery);
    const formattedReponse = { ...response, claims: addIndexToClaims(response.claims) }
    yield put(getManufacturerDisputesSuccess(formattedReponse))
  } catch (e){
    yield put(getManufacturerDisputesFailure(logRocketUtil(e)))
  }
}

export function* getManufacturerDisputesAdvancedFilterOptionsSaga (action: any) {
  try {
    const { stateCode, manufacturerId, disputeStatus, filters } = action.payload;
    const filterQuery = formatFilters(filters);
    const response = yield call(fetchMdrpManufacturerDisputesFilterOptions, stateCode, manufacturerId, disputeStatus, filterQuery);
    yield put(getManufacturerDisputesAdvancedFilterOptionsSuccess(response))
  } catch (e){
    yield put(getManufacturerDisputesAdvancedFilterOptionsFailure(logRocketUtil(e)))
  }
}

export function* getManufacturerDisputesPrimaryFilterOptionsSaga (action: any) {
  try {
    const { stateCode, manufacturerId, disputeStatus } = action.payload;
    const response = yield call(fetchMdrpManufacturerDisputesFilterOptions, stateCode, manufacturerId, disputeStatus);
    yield put(getManufacturerDisputesPrimaryFilterOptionsSuccess(response))
  } catch (e){
    yield put(getManufacturerDisputesPrimaryFilterOptionsFailure(logRocketUtil(e)))
  }
}

export function* getManufacturerDisputesResponseTotalsSaga (action: any) {
  try {
    const response = yield call(fetchMdrpDisputeResponseTotals, action.payload.stateCode, action.payload.manufacturerId);
    yield put(getManufacturerDisputesResponseTotalsSuccess(response))
  } catch (e){
    yield put(getManufacturerDisputesResponseTotalsFailure(logRocketUtil(e)))
  }
}

export function* updateSingleClaimResponseSaga (action: any) {
  try {
    const { stateCode, manufacturerId, claimId, stateResponse, index } = action.payload;
    yield call(updateMdrpSingleClaimResponse, stateCode, manufacturerId, claimId, stateResponse);
    const claims: ClaimModel[] = yield select(getManufacturerClaims);
    const claimToUpdate = claims[index];
    const updatedClaim = {
      ...claimToUpdate,
      isLoading: false,
      stateResponse: stateResponse
    };
    yield put(updateSingleClaimResponseSuccess(index, updatedClaim))
    yield put(getManufacturerDisputesResponseTotals(stateCode, manufacturerId))
  } catch (e){
    yield put(updateSingleClaimResponseFailure(action.payload.index, logRocketUtil(e)))
  }
}

function* watchGetDisputesCenterSummarySaga () {
  yield takeEvery(GET_DISPUTES_CENTER_SUMMARY, getDisputesCenterSummarySaga)
}

function* watchGetManufacturerSummariesSaga () {
  yield takeEvery(GET_MANUFACTURER_SUMMARIES, getManufacturerSummariesSaga)
}

function* watchGetManufacturerSummarySaga () {
  yield takeEvery(GET_MANUFACTURER_SUMMARY, getManufacturerSummarySaga)
}

function* watchGetManufacturerDisputesSaga () {
  yield takeEvery([GET_MANUFACTURER_DISPUTES, UPDATE_MANUFACTURER_DISPUTES_FILTERS, UPDATE_MANUFACTURER_DISPUTES_SORT], getManufacturerDisputesSaga)
}

function* watchGetManufacturerDisputesAdvancedFilterOptionsSaga () {
  yield takeEvery(GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS, getManufacturerDisputesAdvancedFilterOptionsSaga)
}

function* watchGetManufacturerDisputesPrimaryFilterOptionsSaga () {
  yield takeEvery(GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS, getManufacturerDisputesPrimaryFilterOptionsSaga)
}

function* watchGetManufacturerDisputesResponseTotalsSaga () {
  yield takeEvery(GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS, getManufacturerDisputesResponseTotalsSaga)
}

function* watchUpdateSingleClaimResponseSaga () {
  yield takeEvery(UPDATE_SINGLE_CLAIM_RESPONSE, updateSingleClaimResponseSaga)
}

export default function* watchAllDisputesCenterSaga (){
  yield all([
    watchGetDisputesCenterSummarySaga(),
    watchGetManufacturerSummariesSaga(),
    watchGetManufacturerSummarySaga(),
    watchGetManufacturerDisputesSaga(),
    watchGetManufacturerDisputesAdvancedFilterOptionsSaga(),
    watchGetManufacturerDisputesPrimaryFilterOptionsSaga(),
    watchGetManufacturerDisputesResponseTotalsSaga(),
    watchUpdateSingleClaimResponseSaga(),
  ])
}
