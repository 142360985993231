import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { RootState } from '../../store/RootState';
import { selectUserIsNewAO } from '../../store/onboarding/selectors';
import { getEntitledHealthSystems } from '../../store/teammanagement/selectors';
import { HealthSystem } from '../../store/teammanagement/types';
import { isAdminAllAccess } from '../../utils/roleUtil';

export interface RestrictedByLoginProps {
  isAuthenticated?: boolean;
  startUpComplete: boolean;
  healthSystemId: number;
  healthSystemError: string;
  userIsNewAO: boolean;
  onboardingStatusCallFinished: boolean;
  children: React.ReactNode
  entitledHealthSystems: HealthSystem[];
  roles: string[] | null;
}

export class RestrictedByLogin extends React.Component<RestrictedByLoginProps> {

  render () {
    const { isAuthenticated, startUpComplete, healthSystemId, healthSystemError, userIsNewAO, onboardingStatusCallFinished, children, entitledHealthSystems, roles } = this.props;
    if (!isAuthenticated) {
      return null;
    }

    // wait for initial calls to complete
    if (!startUpComplete) {
      return <Dimmer active inverted><Loader inverted content='Loading' /></Dimmer>;
    }

    // wait for health system
    if (healthSystemId === 0 && !healthSystemError && (entitledHealthSystems.length > 0 || roles && isAdminAllAccess(roles))) {
      return <Dimmer active inverted><Loader inverted content='Loading' /></Dimmer>;
    }

    // wait for calls that happen after health system info is loaded
    if (!userIsNewAO && !onboardingStatusCallFinished && healthSystemId !== 0) {
      return <Dimmer active inverted><Loader inverted content='Loading' /></Dimmer>;
    }

    return (
      <Fragment>
        {children}
      </Fragment>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.LoginState.user.isAuthenticated,
  startUpComplete: state.LoginState.startUpComplete,
  healthSystemId: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.healthSystemId,
  healthSystemError: state.TeamManagementState.teamManagementModel.errorText,
  userIsNewAO: selectUserIsNewAO(state),
  onboardingStatusCallFinished: state.OnboardingState.onboardingStatusModel.isLoaded,
  entitledHealthSystems: getEntitledHealthSystems(state),
  roles: state.LoginState.user.roles
});

export default connect(mapStateToProps)(RestrictedByLogin);