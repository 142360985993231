import React, { Fragment } from 'react';
import classnames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ChevronUp, ChevronDown } from 'react-feather';
import { NavLink } from 'react-router-dom';

import { KalderosIcon, RequestIcon, RequestLogoIcon, DisputesCenterIcon, Nubbin, KalderosWordmark } from '../../../themes/kalderos/Common/Icons';
import ScreenReaderMessage from '../ScreenReaderMessage';

interface SubNavigationProps {
  text: React.ReactNode;
  link: string;
  id: string;
  NavIcon: any;
  childLinks: any[];
  pathname: string;
}

const SubNavigation: React.FunctionComponent<SubNavigationProps> = ({ text, NavIcon, link, id, pathname, childLinks }) => {
  const isActive = pathname === link || !!childLinks.find(item => pathname.includes(item.link));
  const isRootActive = pathname === link && !childLinks.find(item => item.link === link);
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  React.useEffect(() => {
    if (!isActive && !isRootActive) {
      setIsCollapsed(true)
    }
  }, [pathname, isActive, isRootActive]);

  return (
    <div className="sub-navigation">
      <div className={classnames('sub-navigation-link sub-navigation-parent', { 'root-active': isRootActive, 'active': isActive })}>
        <NavLink className='navigation-item' exact={true} to={link} id={id} onClick={() => setIsCollapsed(false)}>
          <div className='navigation-icon'><NavIcon /></div>
          <div className='navigation-text'>{text}</div>
        </NavLink>
        <div className='sub-navigation-icon' onClick={() => setIsCollapsed(!isCollapsed)}>
          {isCollapsed ? <ChevronDown strokeWidth='1.5' /> : <ChevronUp strokeWidth='1.5' />}
        </div>
      </div>
      <div className={classnames('sub-navigation-link sub-navigation-children', { 'collapsed': isCollapsed }, { 'active': isActive })}>
        <div className='flex-column flex-grow'>
          {childLinks.map(child =>
            <NavLink key={child.link} exact={true} to={child.link}
              className={classnames('sub-navigation-item', { 'active': pathname.includes(child.link) })}>{child.text}
            </NavLink>)}
        </div>
      </div>
    </div>
  )
}

const Header = ({ isExpanded, hasToggle, toggleNavigation }: { isExpanded: boolean, hasToggle: boolean, toggleNavigation: () => void }) => {
  return (
    <div className='left-navigation-header'>
      <div className="left-navigation-header-logo">
        {isExpanded ? <RequestLogoIcon /> : <RequestIcon />}   
      </div>
      {hasToggle &&
        <div className='nubbin' onClick={toggleNavigation}>
          <Nubbin />
        </div>
      }
    </div>
  )
};

const Body = ({ isVisible, pathname }: { isVisible: boolean, pathname: string }) => {
  return (
    <div className="left-navigation-body">
      {isVisible &&
        <Fragment>
          <div className='navigation-section flex-column flex-grow scroll-hidden v-scroll-auto'>
            <RecipientNavLinks pathname={pathname} />
          </div>
        </Fragment>
      }
    </div>
  )
}

const Footer = ({ isExpanded, showExpandedContent, privacyPolicy, termsAndConditions }: { isExpanded: boolean, showExpandedContent: boolean, privacyPolicy: string, termsAndConditions: string }) => {
  return (
    <div className='left-navigation-footer'>
      {isExpanded &&
        <div className={classnames('transition', { 'visible': showExpandedContent })}>
          <div className={classnames('left-navigation-footer-logo', { 'margin-bottom': !termsAndConditions && !privacyPolicy })}>
            <KalderosWordmark />
          </div>
          {(termsAndConditions || privacyPolicy) && 
            <div className="left-navigation-footer-links">
              {termsAndConditions &&
                <a href={termsAndConditions} className="app-link gray-link underline leftNavTermsAndConditions_Pendo" target='_blank' rel='noopener noreferrer'>
                  Terms &amp; Conditions
                  <ScreenReaderMessage>(opens in a new tab)</ScreenReaderMessage>
                </a>
              }
              {privacyPolicy &&
                <a href={privacyPolicy} className="app-link gray-link underline leftNavPrivacy_Pendo" target='_blank' rel='noopener noreferrer'>
                  Privacy Policy
                  <ScreenReaderMessage>(opens in a new tab)</ScreenReaderMessage>
                </a>
              }
            </div>
          }
        </div>
      }
      {!isExpanded &&
        <div className={classnames('transition', { 'visible': !showExpandedContent })}>
          <div className='left-navigation-footer-icon'>
            <KalderosIcon />
          </div>
        </div>
      }
    </div>
  )
}

export interface MdrpLeftNavigationProps extends RouteComponentProps {
  isExpanded: boolean;
  isMinimal: boolean;
  showExpandedContent: boolean;
  toggleNavigation: () => void;
}

export class MdrpLeftNavigation extends React.Component<MdrpLeftNavigationProps>{
  render () {
    const { isExpanded, isMinimal, showExpandedContent, toggleNavigation } = this.props;

    return (
      <div className={classnames('left-navigation', { 'expanded': isExpanded, 'collapsed': !isExpanded })}>
        <Header isExpanded={isExpanded} hasToggle={!isMinimal} toggleNavigation={toggleNavigation} />
        <Body isVisible={!isMinimal} pathname={this.props.location.pathname} />
        <Footer isExpanded={isExpanded} showExpandedContent={showExpandedContent} privacyPolicy='' termsAndConditions='' />
      </div>
    );
  }
}

const RecipientNavLinks = ({ pathname }: { pathname: string }) => (
  <Fragment>
    <SubNavigation link="/states/disputes/open" text="Disputes Center" id="disputesCenter" NavIcon={DisputesCenterIcon} pathname={pathname}
      childLinks={[
        { text: 'Open Disputes', link: '/states/disputes/open' },
        { text: 'Closed Disputes', link: '/states/disputes/closed' }
      ]} />
  </Fragment>
);

export default withRouter((MdrpLeftNavigation));
