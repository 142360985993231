import { createSelector } from 'reselect';
import { RootState } from '../RootState';
import { selectRoles } from '../login/loginSelector';
import { isRecipient } from '../../utils/roleUtil';
import { ROLES } from '../applicationConstants';
import { allSteps } from './constants';

import { getHealthSystemError, selectHealthSystemOnboardingRoles } from '../teammanagement/selectors';
import { USER_NOT_APART_OF_A_HEALTH_SYSTEM } from '../teammanagement/constants';

export const makeSelectCurrentOnboardingStep = (state: RootState) => state.OnboardingState.onboardingStatusModel.currentStep;
export const makeSelectOnboardingComplete = (state: RootState) => state.OnboardingState.onboardingStatusModel.onboardingCompleted;

export const getInviteAccountAdminStepInput = (state: RootState) => state.OnboardingState.inviteAccountAdminStepModel.input;
export const getAccountAdminInfoStepInput = (state: RootState) => state.OnboardingState.accountAdminInfoStepModel.adminInfo;
export const getInviteControllerStepInput = (state: RootState) => state.OnboardingState.inviteControllerStepModel.input;
export const getControllerInfoStepInput = (state: RootState) => state.OnboardingState.controllerInfoStepModel.controllerInfo;
export const selectControllerStatus = (state:RootState) => state.OnboardingState.controllerStatusModel;

const selectActiveRoles = createSelector(
  [selectRoles, selectHealthSystemOnboardingRoles], (roles, onboardingRoles) => {
    if (roles === null || !roles) {
      return [];
    }

    if (isRecipient(roles)) {
      if (onboardingRoles && onboardingRoles.length > 0) {
        return onboardingRoles;
      } else if (roles.includes(ROLES.RECIPIENT_AUTHORIZING_OFFICIAL)) {
        //AO can exist before health system is created, make sure their other roles do not get used in this case
        return [ROLES.RECIPIENT_AUTHORIZING_OFFICIAL];
      } else {
        // return roles without onboarding roles as their onboarding roles do not apply to this hs (or they would have been in the onboardingRoles list)
        const newRoles = roles.filter((r: string) => r !== ROLES.RECIPIENT_ACCOUNT_ADMIN && r !== ROLES.RECIPIENT_CONTROLLER)
        return newRoles;
      }
    }
    return [];
  }
)

/*
 * since role order is hierarchical this picks the role that would start
 * earliest in the onboarding flow, we will need additional checks throughout
 * the flow as well
*/
export const makeSelectOnboardingRole = createSelector(
  [selectActiveRoles], (roles) => {
    if (roles === null || !roles) {
      return '';
    }

    if (roles.includes(ROLES.RECIPIENT_AUTHORIZING_OFFICIAL)) {
      return ROLES.RECIPIENT_AUTHORIZING_OFFICIAL;
    } else if (roles.includes(ROLES.RECIPIENT_ACCOUNT_ADMIN)) {
      return ROLES.RECIPIENT_ACCOUNT_ADMIN;
    } else if (roles.includes(ROLES.RECIPIENT_CONTROLLER)) {
      return ROLES.RECIPIENT_CONTROLLER;
    }

    return '';
  }
)

export const makeSelectShowOnboardingWelcome = createSelector(
  [makeSelectOnboardingRole, makeSelectCurrentOnboardingStep], (role, step) => {
    let showWelcome = false;
    if ((role === ROLES.RECIPIENT_AUTHORIZING_OFFICIAL && step === 1) ||
      (role === ROLES.RECIPIENT_ACCOUNT_ADMIN && step <= 3) ||
      (role === ROLES.RECIPIENT_CONTROLLER && step <= 6)) {
      showWelcome = true;
    }
    return showWelcome;
  }
)

export const makeSelectListOfSteps = createSelector(
  [selectActiveRoles], (roles) => {
    const stepList = [] as string[];
    roles?.forEach(role => {

      //AA and Controller steps overlap - do not combine if both
      if (role === ROLES.RECIPIENT_CONTROLLER && roles.indexOf(ROLES.RECIPIENT_ACCOUNT_ADMIN) >= 0) {
        return;
      }

      const steps = allSteps.get(role);
      if (steps && steps.length > 0) {
        stepList.push(...steps);
      }
    })
    return stepList;
  }
);

export const selectUserIsNewAO = createSelector(
  [makeSelectOnboardingRole, getHealthSystemError], (onboardingRole, healthSystemError) => {
    if (healthSystemError && healthSystemError === USER_NOT_APART_OF_A_HEALTH_SYSTEM && onboardingRole === ROLES.RECIPIENT_AUTHORIZING_OFFICIAL) {
      return true;
    }
    return false;
  }
);

export const selectUserRequiresOnboarding = createSelector(
  [selectActiveRoles, makeSelectCurrentOnboardingStep, makeSelectOnboardingComplete, makeSelectOnboardingRole], (roles, step, onboardingCompleted, onboardingRole) => {
    if (!onboardingCompleted) {
      if (onboardingRole !== '') {

        if (((roles.includes(ROLES.RECIPIENT_AUTHORIZING_OFFICIAL) && !(roles.includes(ROLES.RECIPIENT_ACCOUNT_ADMIN)) && step >= 3) ||
          (roles.includes(ROLES.RECIPIENT_ACCOUNT_ADMIN) && !(roles.includes(ROLES.RECIPIENT_CONTROLLER)) && step >= 6))) {
          return false;
        }

        return true;
      }
    }
    return false;
  }
);

export const makeSelectIsAuthorizingOfficial = createSelector(
  [selectHealthSystemOnboardingRoles], (roles) => {
    if (roles && roles.length > 0)
      return roles.includes(ROLES.RECIPIENT_AUTHORIZING_OFFICIAL);
    return false;
  }
);
export const makeSelectIsAccountAdmin = createSelector(
  [selectHealthSystemOnboardingRoles], (roles) => {
    if (roles && roles.length > 0)
      return roles.includes(ROLES.RECIPIENT_ACCOUNT_ADMIN);
    return false;
  }
);
export const makeSelectIsController = createSelector(
  [selectHealthSystemOnboardingRoles], (roles) => {
    if (roles && roles.length > 0)
      return (roles.includes(ROLES.RECIPIENT_CONTROLLER))
    return false;
  }
);

export const makeSelectIsLoading = (state: RootState) => state.OnboardingState.isLoading || state.AccountInformationState.isLoading;
export const getSelectedHealthSystem = (state: RootState) => state.OnboardingState.associateCesAndHsStepModel.selectedHealthSystem;
export const getHealthSystemNameForCheck = (state: RootState) => state.OnboardingState.associateCesAndHsStepModel.checkUniqueHealthSystemName.name;

export const getCandidateHealthSystems = (state: RootState) => state.OnboardingState.associateCesAndHsStepModel.candidateCeListModel.candidateHealthSystems;
export const getOrphanedCes = (state: RootState) => state.OnboardingState.associateCesAndHsStepModel.candidateCeListModel.orphanedCes;

export const hasAdditionalCesToOnboard = createSelector(
  [getCandidateHealthSystems, getOrphanedCes], (candidates, orphans) => {
    const notYetOnboardedHealthSystems = candidates.filter(hs => hs.healthSystemId !== undefined && hs.healthSystemId !== null && hs.onboardState === 0);
    let hasAdditional = false;
    notYetOnboardedHealthSystems.forEach((healthSystem) => {
      if (healthSystem.ceList) {
        healthSystem.ceList.forEach((ce) => {
          if (ce.allowedToOnboard && ce.onboardState === 0) {
            hasAdditional = true;
          }
        })
      }
    })

    if (orphans) {
      orphans.forEach((orphan) => {
        if (orphan.allowedToOnboard) {
          hasAdditional = true;
        }
      });
    }
    return hasAdditional;
  });