import React from 'react';
import { Label, Menu, Tab } from 'semantic-ui-react';
import { RemittanceDetailsCountsModel } from '../../../store/remittance/types';
import { REMITTANCE_DETAILS_TAB } from '../../../store/remittance/constants';
import RemittanceTransactions from './RemittanceTransactions';

export interface RemittanceTransactionsTabsProps {
  activeTab: REMITTANCE_DETAILS_TAB,
  counts: RemittanceDetailsCountsModel,
  isLoading: boolean;
  handleTabChange: (tab: REMITTANCE_DETAILS_TAB) => void;
}

export const RemittanceDetailsTabMap: { [key: string]: number } = {
  'all': REMITTANCE_DETAILS_TAB.ALL,
  'failed': REMITTANCE_DETAILS_TAB.FAILED,
  'adjusted': REMITTANCE_DETAILS_TAB.ADJUSTED,
  'approved': REMITTANCE_DETAILS_TAB.APPROVED,
  'processed': REMITTANCE_DETAILS_TAB.PROCESSED,
}

export function getRemittanceDetailsTabIndex (path: string): number {
  return RemittanceDetailsTabMap[path.substr(path.lastIndexOf('/') + 1)];
}

export const RemittanceTransactionsTabs: React.FunctionComponent<RemittanceTransactionsTabsProps> = (props) => {
  const { activeTab, isLoading, counts, handleTabChange } = props;

  const panes = [
    {
      menuItem: (
        <Menu.Item key='all' className="remittanceDetailAllTab_Pendo" disabled={isLoading}>
          {'All'}<Label className="remittance-tab-count">{counts.all}</Label>
        </Menu.Item>
      ), render: () => <Tab.Pane attached={false}><RemittanceTransactions tab={REMITTANCE_DETAILS_TAB.ALL} /></Tab.Pane>
    },
    {
      menuItem: (
        <Menu.Item key='failed' className="remittanceDetailFailedTab_Pendo" disabled={isLoading}>
          {'Failed'}<Label className="remittance-tab-count">{counts.failed}</Label>
        </Menu.Item>
      ), render: () => <Tab.Pane attached={false}><RemittanceTransactions tab={REMITTANCE_DETAILS_TAB.FAILED} /></Tab.Pane>

    },
    {
      menuItem: (
        <Menu.Item key='adjustedPaid' className="remittanceDetailAdjustedPaidTab_Pendo" disabled={isLoading}>
          {'Adjusted & Paid'}<Label className="remittance-tab-count">{counts.adjustedPaid}</Label>
        </Menu.Item>
      ), render: () => <Tab.Pane attached={false}><RemittanceTransactions tab={REMITTANCE_DETAILS_TAB.ADJUSTED} /></Tab.Pane>

    },
    {
      menuItem: (
        <Menu.Item key='approvedPaid' className="remittanceDetailApprovedPaidTab_Pendo" disabled={isLoading}>
          {'Approved & Paid'}<Label className="remittance-tab-count">{counts.paid}</Label>
        </Menu.Item>
      ), render: () => <Tab.Pane attached={false}><RemittanceTransactions tab={REMITTANCE_DETAILS_TAB.APPROVED} /></Tab.Pane>
    },
    {
      menuItem: (
        <Menu.Item key='reversal' className="remittanceDetailProcessedReversalsTab_Pendo" disabled={isLoading}>
          {'Processed Reversals'}<Label className="remittance-tab-count">{counts.reversed}</Label>
        </Menu.Item>
      ), render: () => <Tab.Pane attached={false}><RemittanceTransactions tab={REMITTANCE_DETAILS_TAB.PROCESSED} /></Tab.Pane>
    }
  ];

  return (
    <Tab className="tab-controller remittance-request-center-tabs fit-height"
      onTabChange={(e, d) => handleTabChange(d.activeIndex as number)}
      activeIndex={activeTab}
      menu={{ secondary: true, pointing: true }}
      panes={panes} />
  )
}

export default RemittanceTransactionsTabs
