import {
  RemittanceActionTypes,
  GET_REMITTANCE_INVOICE_DETAILS_FAILURE,
  GET_REMITTANCE_INVOICE_DETAILS_SUCCESS,
  GET_REMITTANCE_INVOICE_DETAILS,
  GET_REMITTANCE_MONTH_SUMMARY_FAILURE,
  GET_REMITTANCE_MONTH_SUMMARY_SUCCESS,
  GET_REMITTANCE_MONTH_SUMMARY,
  GET_REMITTANCE_PERIOD_DETAILS_FAILURE,
  GET_REMITTANCE_PERIOD_DETAILS_SUCCESS,
  GET_REMITTANCE_PERIOD_DETAILS,
  GET_REMITTANCE_SUMMARY_PERIODS_FAILURE,
  GET_REMITTANCE_SUMMARY_PERIODS_SUCCESS,
  GET_REMITTANCE_SUMMARY_PERIODS,
  GET_REMITTANCE_TRANSACTION_COUNTS_FAILURE,
  GET_REMITTANCE_TRANSACTION_COUNTS_SUCCESS,
  GET_REMITTANCE_TRANSACTION_COUNTS,
  GET_REMITTANCE_TRANSACTIONS_FAILURE,
  GET_REMITTANCE_TRANSACTIONS_SUCCESS,
  GET_REMITTANCE_TRANSACTIONS,
  RESET_REMITTANCE_DETAILS,
  RESET_REMITTANCE_TRANSACTIONS,
  UPDATE_REMITTANCE_PERIOD_DETAILS_FILTER,
  UPDATE_REMITTANCE_TRANSACTIONS_FILTER,
  UPDATE_REMITTANCE_TRANSACTIONS_PAGE,
  UPDATE_REMITTANCE_TRANSACTIONS_SORT,
} from './constants';

import { RemittanceDetailsHeaderFilterModel, RemittanceDetailsParamModel, RemittancePeriodSummary, RemittanceState, } from './types';

const initialState: RemittanceState = {
  summary: {
    remittancePeriods: [],
    monthSummaries: [],
    isMonthLoading: false,
    isLoading: false,
    errorText: ''
  },
  periodDetails: {
    year: 0,
    month: 0,
    filter: {
      coveredEntity: 'ALL',
      remittanceId: 0,
    },
    isLoading: false,
    errorText: ''
  },
  invoiceDetails: {
    remittanceId: 0,
    participantId: '',
    isInvalid: false,
    isLoading: false,
    errorText: ''
  },
  counts: {
    all: 0,
    failed: 0,
    adjustedPaid: 0,
    paid: 0,
    reversed: 0,
    isLoading: false,
    errorText: '',
  },
  transactions: {
    transactions: [],
    params: {
      remittanceIds: []
    },
    filter: {
      startDate: '',
      endDate: '',
      claimTypeText: null,
      ndc11: '',
      fillStartDate: '',
      fillEndDate: '',
      pharmacyNpi: '',
      rxNumber: '',
      adjustmentReasons: null,
      failureReasons: null,
      idCode: '',
    },
    currentPage: 1,
    currentApiPage: 1,
    totalCount: 0,
    sortBy: '',
    isSortAsc: false,
    errorText: '',
    isLoading: false,
  }
}

const getRemittancePeriodDetailsParamModel = (summary: RemittancePeriodSummary | undefined, filter: RemittanceDetailsHeaderFilterModel): RemittanceDetailsParamModel => {
  const params: RemittanceDetailsParamModel = {
    startDate: summary?.startDate,
    endDate: summary?.endDate,
    remittanceIds: [],
  }

  if (filter.coveredEntity && filter.coveredEntity !== 'ALL') {
    params.participantId = filter.coveredEntity;
  }

  if (filter.remittanceId != 0) {
    params.remittanceIds = [filter.remittanceId];
  } else if (summary) {
    if (filter.coveredEntity && filter.coveredEntity !== 'ALL') {
      params.remittanceIds = summary.remittanceCoveredEntitySummaryDetails.find(d => d.coveredEntityId === filter.coveredEntity)?.remittanceIds || [];
    } else {
      params.remittanceIds = summary.remittanceIds || [];
    }
  }

  return params;
}

export function remittanceReducer (state = initialState, action: RemittanceActionTypes): RemittanceState {
  switch (action.type) {

    case GET_REMITTANCE_SUMMARY_PERIODS:
      return {
        ...state,
        summary: {
          ...state.summary,
          isLoading: true,
          errorText: '',
        }
      }

    case GET_REMITTANCE_SUMMARY_PERIODS_SUCCESS:
      return {
        ...state,
        summary: {
          ...state.summary,
          remittancePeriods: action.payload,
          isLoading: false,
        }
      }

    case GET_REMITTANCE_SUMMARY_PERIODS_FAILURE:
      return {
        ...state,
        summary: {
          ...state.summary,
          isLoading: false,
          errorText: action.payload.message
        }
      }

    case GET_REMITTANCE_MONTH_SUMMARY:
      return {
        ...state,
        summary: {
          ...state.summary,
          isMonthLoading: true
        }
      }

    case GET_REMITTANCE_MONTH_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: {
          ...state.summary,
          monthSummaries: [...state.summary.monthSummaries, action.payload],
          isMonthLoading: false
        }
      }

    case GET_REMITTANCE_MONTH_SUMMARY_FAILURE:
      return {
        ...state,
        summary: {
          ...state.summary,
          isMonthLoading: false
          //todo
        }
      }

    case GET_REMITTANCE_PERIOD_DETAILS:
      return {
        ...state,
        periodDetails: {
          ...state.periodDetails,
          year: action.payload.year,
          month: action.payload.month,
          isLoading: true,
          errorText: '',
        },
      }

    case GET_REMITTANCE_PERIOD_DETAILS_SUCCESS: {
      const params = getRemittancePeriodDetailsParamModel(action.payload, state.periodDetails.filter);
      return {
        ...state,
        periodDetails: {
          ...state.periodDetails,
          summary: action.payload,
          isLoading: false,
        },
        transactions: {
          ...state.transactions,
          params: params,
        }
      }
    }

    case GET_REMITTANCE_PERIOD_DETAILS_FAILURE:
      return {
        ...state,
        periodDetails: {
          ...state.periodDetails,
          isLoading: false,
          errorText: action.payload.message
        }
      }

    case UPDATE_REMITTANCE_PERIOD_DETAILS_FILTER: {
      const params = getRemittancePeriodDetailsParamModel(state.periodDetails.summary, action.payload);
      return {
        ...state,
        periodDetails: {
          ...state.periodDetails,
          filter: action.payload
        },
        transactions: {
          ...state.transactions,
          params: params
        }
      }
    }

    case GET_REMITTANCE_INVOICE_DETAILS:
      return {
        ...state,
        invoiceDetails: {
          ...state.invoiceDetails,
          remittanceId: action.payload.remittanceId,
          participantId: action.payload.participantId,
          isLoading: true,
          errorText: '',
        },
      }

    case GET_REMITTANCE_INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        invoiceDetails: {
          ...state.invoiceDetails,
          summary: action.payload,
          isLoading: false,
        },
        transactions: {
          ...state.transactions,
          params: {
            ...state.transactions.params,
            participantId: state.invoiceDetails.participantId,
            remittanceIds: [state.invoiceDetails.remittanceId]
          }
        }
      }

    case GET_REMITTANCE_INVOICE_DETAILS_FAILURE:
      return {
        ...state,
        invoiceDetails: {
          ...state.invoiceDetails,
          isLoading: false,
          errorText: action.payload.message,
          isInvalid: action.payload.statusCode === 400 || action.payload.statusCode === 401 || action.payload.statusCode === 404
        }
      }

    case GET_REMITTANCE_TRANSACTION_COUNTS:
      return {
        ...state,
        counts: {
          ...state.counts,
          isLoading: true,
          errorText: '',
        }
      }

    case GET_REMITTANCE_TRANSACTION_COUNTS_SUCCESS:
      return {
        ...state,
        counts: {
          ...state.counts,
          ...action.payload,
          isLoading: false
        }
      }

    case GET_REMITTANCE_TRANSACTION_COUNTS_FAILURE:
      return {
        ...state,
        counts: {
          ...state.counts,
          isLoading: false,
          errorText: action.payload.message
        }
      }

    case GET_REMITTANCE_TRANSACTIONS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          isLoading: true,
          errorText: '',
        }
      }

    case GET_REMITTANCE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          transactions: action.payload.discountRequests,
          currentApiPage: action.payload.page,
          totalCount: action.payload.totalCount,
          isLoading: false,
        }

      }

    case GET_REMITTANCE_TRANSACTIONS_FAILURE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          isLoading: false,
          errorText: action.payload.message
        }
      }

    case UPDATE_REMITTANCE_TRANSACTIONS_PAGE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          currentPage: action.payload,
        }
      }

    case UPDATE_REMITTANCE_TRANSACTIONS_SORT:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          currentPage: 1,
          sortBy: action.payload.sortBy,
          isSortAsc: action.payload.isSortAsc,
        }
      }

    case UPDATE_REMITTANCE_TRANSACTIONS_FILTER:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          filter: action.payload,
          currentPage: 1,
          sortBy: '',
          isSortAsc: false,
        }
      }

    case RESET_REMITTANCE_DETAILS:
      return {
        ...state,
        periodDetails: {
          ...initialState.periodDetails
        },
        invoiceDetails: {
          ...initialState.invoiceDetails
        },
        transactions: {
          ...initialState.transactions
        }
      }

    case RESET_REMITTANCE_TRANSACTIONS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          transactions: [],
          currentPage: 1,
          sortBy: '',
          isSortAsc: false,
        }
      }

    default:
      return state
  }
}
