import { AccountInformationState } from './types';
import { AccountInfoActionTypes, GET_BUSINESS_INFORMATION_ERROR, GET_BUSINESS_INFORMATION_REQUEST, GET_BUSINESS_INFORMATION_SUCCESS, GET_USER_INFORMATION_REQUEST, GET_USER_INFORMATION_SUCCESS, GET_USER_INFORMATION_ERROR } from './constants';


const initialState: AccountInformationState = {
  userInfo: {
    id: 0,
    userName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    phoneExtension: '',
    mobilePhone: '',
    jobTitle: '',
    userRole: '',
    status: '',
    address: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      zip: ''
    },
  },
  businessInfo: {
    name: '',
    phone: '',
    address: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
    }
  },
  error: '',
  isLoading: false
}

export function accountInfoReducer (
  state = initialState,
  action: AccountInfoActionTypes
): AccountInformationState {
  switch (action.type) {
    case GET_BUSINESS_INFORMATION_REQUEST:
    case GET_USER_INFORMATION_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case GET_BUSINESS_INFORMATION_SUCCESS:
      return {
        ...state,
        businessInfo: action.payload,
        isLoading: false
      }
    case GET_BUSINESS_INFORMATION_ERROR:
      return {
        ...state,
        error: action.payload.message,
        isLoading: false
      }
    case GET_USER_INFORMATION_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
        isLoading: false
      }
    case GET_USER_INFORMATION_ERROR:
      return {
        ...state,
        error: action.payload.message,
        isLoading: false
      }
    default:
      return state
  }
}