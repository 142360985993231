import React, { useState } from 'react';
import cx from 'classnames';
import { Formik } from 'formik';
import moment from 'moment';
import { Moment } from 'moment-timezone';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';

import { FilterDropdown } from '../../../common/filter/FilterDropdown';
import { FilterTextbox } from '../../../common/filter/FilterTextbox';
import { FilterModel } from '../../../../store/appcommon/types';
import { ManufacturerReviewFilterLabels } from '../../../../store/mdrp/disputescenter/constants';
import { ManufacturerDisputesFilterModel } from '../../../../store/mdrp/disputescenter/types';
import { getDisputeCodeOptions, getPharmacyNpiOptions, getProductNameOptions, getProgramNameOptions } from '../../../../store/mdrp/disputescenter/selectors';
import { RootState } from '../../../../store/RootState';
import { formatYYYYMMDD } from '../../../../utils/dateHelper';

type focusedDateInputType = 'startDate' | 'endDate' | null;

export interface ManufacturerReviewAdvancedFiltersProps {
  disputeCodeOptions: FilterModel[];
  filters: ManufacturerDisputesFilterModel;
  isLoading: boolean;
  onApplyFilter: (values: ManufacturerDisputesFilterModel) => void;
  pharmacyNpiOptions: FilterModel[];
  programNameOptions: FilterModel[];
  productNameOptions: FilterModel[];
}

export const ManufacturerReviewAdvancedFilters = (props: ManufacturerReviewAdvancedFiltersProps) => {
  const { filters, isLoading, onApplyFilter, pharmacyNpiOptions, programNameOptions, productNameOptions } = props;

  const [focusedDateInput, setFocusedDateInput] = useState<focusedDateInputType>(null)

  const handleDateFocusChange = (input: focusedDateInputType) => {
    if (isLoading && input === null) {
      setFocusedDateInput('endDate')
    } else {
      setFocusedDateInput(input)
    }
  };

  return (
    <Formik
      initialValues={filters}
      onSubmit={(values, helpers) => {
        helpers.setSubmitting(false);
        helpers.resetForm({ values: values })
        onApplyFilter(values);
      }}
    >
      {({ dirty, handleChange, handleSubmit, isSubmitting, isValid, setFieldTouched, setFieldValue, submitForm, values }) => {
        const handleDateChange = async (startDate: Moment | null, endDate: Moment | null) => {
          if (startDate !== null) {
            await setFieldValue('minDateOfService', formatYYYYMMDD(startDate));
            setFieldTouched('minDateOfService');
          }
          if (endDate !== null) {
            await setFieldValue('maxDateOfService', formatYYYYMMDD(endDate));
            setFieldTouched('maxDateOfService');
          }
          handleSubmit();
        };

        const handleDropdownChange = (label: string, value: any) => {
          if (value.length > 0) {
            setFieldValue(label, value);
          } else {
            setFieldValue(label, null);
          } 
        
          handleSubmit();
        };

        const handleOnBlur = () => {
          if (dirty && isValid && !isSubmitting) {
            submitForm();
          }
        };

        return (
          <div className="table-header-filter mfr-review-advanced-filters">
            <Form className="filter-form" onSubmit={handleSubmit}>
              <div className="filter-left-container small">
                <div className="filter-header">Date of Service</div>
                <Form.Group widths='equal' className="filter-date-picker-group">
                  <Form.Field className={cx('filter-date-picker', { disabled: isLoading })}>
                    <DateRangePicker
                      customArrowIcon={<div></div>}
                      disabled={isLoading}
                      endDate={values.maxDateOfService ? moment(values.maxDateOfService) : null}
                      endDateId='maxDateOfService'
                      focusedInput={focusedDateInput}
                      isOutsideRange={isLoading ? () => true : day => !isInclusivelyBeforeDay(day, moment())}
                      minimumNights={0}
                      noBorder
                      onDatesChange={({startDate, endDate}) => handleDateChange(startDate, endDate)}
                      onFocusChange={focusedInput => handleDateFocusChange(focusedInput)}
                      startDate={values.minDateOfService ? moment(values.minDateOfService) : null}
                      startDateId='minDateOfService'
                    />
                  </Form.Field>
                </Form.Group>
              </div>
              <div className="filter-right-container small no-header">
                <Form.Field>
                  <FilterTextbox
                    isDisabled={isLoading}
                    label={ManufacturerReviewFilterLabels.icn}
                    name="icn"
                    onChange={handleChange}
                    onBlur={handleOnBlur}
                    value={values.icn}
                  />
                </Form.Field>
                <Form.Field>
                  <FilterDropdown
                    isDisabled={isLoading}
                    label={ManufacturerReviewFilterLabels.programName}
                    options={programNameOptions}
                    values={values.programName || undefined}
                    onChange={(value) => handleDropdownChange('programName', value)}
                    search
                  />
                </Form.Field>
                <Form.Field>
                  <FilterDropdown
                    isDisabled={isLoading}
                    label={ManufacturerReviewFilterLabels.productName}
                    options={productNameOptions}
                    values={values.productName || undefined}
                    onChange={(value) => handleDropdownChange('productName', value)}
                    search
                  />
                </Form.Field>
                {/* <Form.Field>
                  <FilterDropdown
                    isDisabled={isLoading}
                    label={ManufacturerReviewFilterLabels.manualDisputeCode}
                    options={disputeCodeOptions}
                    values={values.manualDisputeCode || undefined}
                    onChange={(value) => handleDropdownChange('manualDisputeCode', value)}
                    search
                  />
                </Form.Field> */}
                <Form.Field>
                  <FilterDropdown
                    isDisabled={isLoading}
                    label={ManufacturerReviewFilterLabels.pharmacyNpi}
                    options={pharmacyNpiOptions}
                    values={values.pharmacyNpi || undefined}
                    onChange={(value) => handleDropdownChange('pharmacyNpi', value)}
                    search
                  />
                </Form.Field>
                <Form.Field>
                  <FilterTextbox
                    isDisabled={isLoading}
                    label={ManufacturerReviewFilterLabels.rxNumber}
                    name="rxNumber"
                    onChange={handleChange}
                    onBlur={handleOnBlur}
                    value={values.rxNumber}
                  />
                </Form.Field>
              </div>
            </Form>
          </div>
        )
      }}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  disputeCodeOptions: getDisputeCodeOptions(state),
  pharmacyNpiOptions: getPharmacyNpiOptions(state),
  programNameOptions: getProgramNameOptions(state),
  productNameOptions: getProductNameOptions(state)
})

export default (connect(mapStateToProps))(ManufacturerReviewAdvancedFilters);
