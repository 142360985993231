import React, { useState } from 'react';
import cx from 'classnames';
import { Accordion, Table, Button } from 'semantic-ui-react';
import { formatCredits, formatDollar, formatPositiveDollarToNegative } from '../../../utils/numberFormatHelper';
import { formatMMDDYYYY } from '../../../utils/dateHelper';
import { ChevronUp, ChevronDown } from 'react-feather';
import { RemittancePeriodSummary, RemittancePeriodCoveredEntitySummary, RemittancePeriodSummaryDetail } from '../../../store/remittance/types';
import { ArrowRight } from '../../../themes/kalderos/Common/Icons';

interface RemittanceSummaryPeriodDetailsProps {
  coveredEntityId: string,
  numberPayments: number,
  initialIsOpen: boolean,
  remittanceSummaryDetails: RemittancePeriodSummaryDetail[],
  onDetailsClick: (invoiceId: number, coveredEntity: string) => void
}
const RemittanceSummaryPeriodDetails: React.FunctionComponent<RemittanceSummaryPeriodDetailsProps> = (props) => {
  const { coveredEntityId, numberPayments, remittanceSummaryDetails, initialIsOpen, onDetailsClick } = props;
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  return (
    <React.Fragment>
      <Accordion>
        <Accordion.Title className='ce-row'>
          <div className={cx('flex-row clickable remittanceSummaryCERow_Pendo', { 'open': isOpen })} onClick={() => setIsOpen(!isOpen)} >
            <div className='flex-row'>
              {isOpen ? <ChevronUp strokeWidth='1.5' /> : <ChevronDown strokeWidth='1.5' />}
              <div className='epsilon entity-id'>{coveredEntityId}</div>
              <div className='delta numb-mfrs'>{`(${numberPayments} ${numberPayments <= 1 ? 'Payment' : 'Payments'})`}</div>
            </div>
            <div className='flex-column'>
              <div className='flex-row'>
                <Button onClick={() => onDetailsClick(0, coveredEntityId)} className='remittance-detail-link remittanceSummaryDetails_Pendo' type='button'>
                  Go to Remittance Details <span className='hover-arrow'><ArrowRight /></span>
                </Button>
              </div>
            </div>
          </div>
        </Accordion.Title>
        <Accordion.Content active={isOpen}>
          <Table className='remittance-summary-details-table'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className='border-spacing-cell'></Table.HeaderCell>
                <Table.HeaderCell>
                  <div className='header-cell'>Manufacturer</div>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <div className='header-cell'>Remittance ID</div>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <div className='header-cell'>Processed Date</div>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <div className='header-cell'>Amount Failed</div>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <div className='header-cell'>Amount Paid</div>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <div className='header-cell'>Amount Reversed</div>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <div className='header-cell'>Credits</div>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <div className='header-cell'>Payment Received</div>
                </Table.HeaderCell>
                <Table.HeaderCell className='border-spacing-cell'></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {remittanceSummaryDetails && remittanceSummaryDetails.map((d: RemittancePeriodSummaryDetail) => {
                return (
                  <Table.Row key={d.remittanceNumber} onClick={() => onDetailsClick(d.remittanceId, coveredEntityId)} className='remittanceSummaryDetailRow_Pendo'>
                    <Table.Cell className='border-spacing-cell'></Table.Cell>
                    <Table.Cell>
                      <div className='body-cell'>{d.manufacturerName}</div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className='body-cell'>{d.remittanceNumber}</div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className='body-cell'>{formatMMDDYYYY(d.dateProcessed)}</div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className='body-cell'>{formatDollar(d.amountFailed)}</div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className='body-cell'>{formatDollar(d.amountPaid)}</div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className='body-cell'>{formatPositiveDollarToNegative(d.amountReversed)}</div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className='body-cell'>{formatCredits(d.creditsGenerated - d.creditsUsed)}</div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className='body-cell with-arrow'>
                        {formatMMDDYYYY(d.datePaid)}
                        <div className='hover-arrow'><ArrowRight /></div></div>
                    </Table.Cell>
                    <Table.Cell className='border-spacing-cell'></Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </Accordion.Content>
      </Accordion>
    </React.Fragment>
  );
}

interface RemittanceSummaryPeriodProps {
  remittanceSummary: RemittancePeriodSummary,
  initialIsOpen: boolean,
  onDetailsClick: (invoiceId: number, coveredEntity: string, periodId: number) => void;
}

const RemittanceSummaryPeriod: React.FunctionComponent<RemittanceSummaryPeriodProps> = (props) => {
  const { remittanceSummary, initialIsOpen, onDetailsClick } = props;
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  if (!remittanceSummary) {
    return null;
  }

  return (
    <div className={cx('remittance-summary-details-card', { 'open': isOpen })}>
      <Accordion>
        <Accordion.Title className='remittance-row'>
          <div className='flex-row main-container clickable remittanceSummaryRow_Pendo'  onClick={() => setIsOpen(!isOpen)} >
            <div className='flex-column date-column'>
              <div className='flex-row'>
                <div className='epsilon remittance-date'>{formatMMDDYYYY(remittanceSummary.startDate)}</div>
                <div className='epsilon remittance-dash'>-</div>
                <div className='epsilon remittance-date'>{formatMMDDYYYY(remittanceSummary.endDate)}</div>
              </div>
            </div>
            <div className='flex-column information-column'>
              <div className='flex-row information-row'>
                <div className='flex-column'>
                  <div className='beta data'>{formatDollar(remittanceSummary.netFailed)}</div>
                  <div className='eta label'>NET AMOUNT FAILED</div>
                </div>
                <div className='flex-column'>
                  <div className='beta data'>{formatDollar(remittanceSummary.netPaid)}</div>
                  <div className='eta label'>NET AMOUNT PAID</div>
                </div>
                <div className='flex-column'>
                  <div className='beta data'>{formatPositiveDollarToNegative(remittanceSummary.netReversed)}</div>
                  <div className='eta label'>NET AMOUNT REVERSED</div>
                </div>
              </div>
            </div>
          </div>
        </Accordion.Title>
        <Accordion.Content active={isOpen}>
          {remittanceSummary.remittanceCoveredEntitySummaryDetails?.map((ce: RemittancePeriodCoveredEntitySummary, index: number) => {
            return (
              <RemittanceSummaryPeriodDetails
                key={index}
                coveredEntityId={ce.coveredEntityId}
                initialIsOpen={index === 0}
                numberPayments={ce.numberPayments}
                remittanceSummaryDetails={ce.remittanceSummaryDetails}
                onDetailsClick={(i, c) => onDetailsClick(i,c, remittanceSummary.id)}
              />
            )
          })}
        </Accordion.Content>
      </Accordion>
    </div>
  )

}

export default RemittanceSummaryPeriod;
