import React, { useState } from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import { Moment } from 'moment-timezone';
import { DateRangePicker } from 'react-dates';
import { Form } from 'semantic-ui-react';

import { filterLabels } from './AssociatedPharmacies'
import { FilterTextbox } from '../../common/filter/FilterTextbox';
import { FilterDropdown } from '../../common/filter/FilterDropdown';
import { PHARMACY_TYPE_FILTER_OPTIONS } from '../../../store/coveredentityinfo/pharmacymanagement/constants';
import { AssociatedPharmaciesFiltersModel } from '../../../store/coveredentityinfo/pharmacymanagement/types';
import { STATES } from '../../../store/teammanagement/constants';
import { ArrowRight } from '../../../themes/kalderos/Common/Icons';
import { formatYYYYMMDD } from '../../../utils/dateHelper';

type focusedInputType = 'startDate' | 'endDate' | null;

export interface AssociatedPharmaciesFiltersProps {
  filters: AssociatedPharmaciesFiltersModel;
  onApplyFilter: (values: any) => void;
}

const AssociatedPharmaciesFilters = (props: AssociatedPharmaciesFiltersProps) => {
  const { filters, onApplyFilter } = props;

  const [focusedStartInput, setFocusedStartInput] = useState<focusedInputType>(null);
  const [focusedTermInput, setFocusedTermInput] = useState<focusedInputType>(null);

  return (
    <Formik
      initialValues={filters}
      onSubmit={(values, helpers) => {
        helpers.setSubmitting(false);
        helpers.resetForm({ values: values })
        onApplyFilter(values);
      }}
    >
      {({ dirty, handleChange, handleSubmit, isSubmitting, isValid, setFieldTouched, setFieldValue, submitForm, values }) => {

        const handleDateChange = async (startDate: Moment | null, endDate: Moment | null, dateField: string) => {
          if (startDate !== null) {
            const field = dateField === 'term' ? 'termStartDate' : 'startDate';
            await setFieldValue(field, formatYYYYMMDD(startDate));
            setFieldTouched(field);
          }
          if (endDate !== null) {
            const field = dateField === 'term' ? 'termEndDate' : 'endDate';
            await setFieldValue(field, formatYYYYMMDD(endDate));
            setFieldTouched(field);
          }

          handleSubmit();
        };

        const handleDropdownChange = (label: string, value: any) => {
          if (value.length > 0){
            setFieldValue(label, value);
          } else {
            setFieldValue(label, null);
          }
          handleSubmit();
        };

        const handleOnBlur = () => {
          if (dirty && isValid && !isSubmitting) {
            submitForm();
          }
        };

        return (
          <div className="table-header-filter">
            <Form className="filter-form" onSubmit={handleSubmit}>
              <div className="filter-left-container">
                <div className="filter-header">Start Date</div>
                <Form.Group className="filter-date-picker-group" widths='equal'>
                  <Form.Field className="filter-date-picker">
                    <DateRangePicker
                      customArrowIcon={<div className="react-dates-arrow"><ArrowRight /></div>}
                      endDate={values.endDate ? moment(values.endDate) : null}
                      endDateId='endDate'
                      focusedInput={focusedStartInput}
                      isOutsideRange={() => false}
                      minimumNights={0}
                      noBorder
                      onDatesChange={({ startDate, endDate }) => handleDateChange(startDate, endDate, 'start')}
                      onFocusChange={focusedInput => setFocusedStartInput(focusedInput)}
                      startDate={values.startDate ? moment(values.startDate) : null}
                      startDateId='startDate'
                    />
                  </Form.Field>
                </Form.Group>
              </div>

              <div className="filter-left-container">
                <div className="filter-header">Term Date</div>
                <Form.Group className="filter-date-picker-group" widths='equal'>
                  <Form.Field className="filter-date-picker datePickerFilter_Pendo">
                    <DateRangePicker
                      customArrowIcon={<div className="react-dates-arrow"><ArrowRight /></div>}
                      endDate={values.termEndDate ? moment(values.termEndDate) : null}
                      endDateId='termEndDate'
                      focusedInput={focusedTermInput}
                      isOutsideRange={() => false}
                      minimumNights={0}
                      noBorder
                      onDatesChange={({ startDate, endDate }) => handleDateChange(startDate, endDate, 'term')}
                      onFocusChange={focusedInput => setFocusedTermInput(focusedInput)}
                      startDate={values.termStartDate ? moment(values.termStartDate) : null}
                      startDateId='termStartDate'
                    />
                  </Form.Field>
                </Form.Group>
              </div>

              <div className="filter-right-container small no-header">
                <Form.Field className='pharmacyTypeFilter_Pendo'>
                  <FilterDropdown
                    label={filterLabels.pharmacyType}
                    onChange={(value) => handleDropdownChange('pharmacyType', value)}
                    options={PHARMACY_TYPE_FILTER_OPTIONS}
                    search
                    values={values.pharmacyType || undefined}
                  />
                </Form.Field>

                <Form.Field className='pharmacyTextboxFilter_Pendo'>
                  <FilterTextbox
                    label={filterLabels.pharmacyName}
                    name="pharmacyName"
                    onBlur={handleOnBlur}
                    onChange={handleChange}
                    value={values.pharmacyName}
                  />
                </Form.Field>

                <Form.Field className='pharmacyCityFilter_Pendo'>
                  <FilterTextbox
                    label={filterLabels.city}
                    name="city"
                    onBlur={handleOnBlur}
                    onChange={handleChange}
                    value={values.city}
                  />
                </Form.Field>

                <Form.Field className='pharmacyStateFilter_Pendo'>
                  <FilterDropdown
                    label={filterLabels.state}
                    onChange={(value) => handleDropdownChange('state', value)}
                    options={STATES}
                    search
                    values={values.state || undefined}
                  />
                </Form.Field>

                <Form.Field className='npiFilter_Pendo'>
                  <FilterTextbox
                    label={filterLabels.npi}
                    name="npi"
                    onBlur={handleOnBlur}
                    onChange={handleChange}
                    value={values.npi}
                  />
                </Form.Field>

                <Form.Field className='hinFilter_Pendo'>
                  <FilterTextbox
                    label={filterLabels.hin}
                    name="hin"
                    onBlur={handleOnBlur}
                    onChange={handleChange}
                    value={values.hin}
                  />
                </Form.Field>

                <Form.Field className='deaFilter_Pendo'>
                  <FilterTextbox
                    label={filterLabels.dea}
                    name="dea"
                    onBlur={handleOnBlur}
                    onChange={handleChange}
                    value={values.dea}
                  />
                </Form.Field>
              </div>
            </Form>
          </div>
        )
      }}
    </Formik>
  );
};

export default AssociatedPharmaciesFilters;