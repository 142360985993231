import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store/RootState';
import { Dimmer, Loader } from 'semantic-ui-react';
import cx from 'classnames';

import { API_PAGE_SIZE, NO_FILTER_RESULTS_HEADER, NO_FILTER_RESULTS_MESSAGE, SUPPORT_PHONE } from '../../../store/applicationConstants';
import { NO_ASSOCIATED_PHARMACIES_HEADER, NO_ASSOCIATED_PHARMACIES_MESSAGE, UI_PAGE_SIZE } from '../../../store/coveredentityinfo/pharmacymanagement/constants';
import AssociatedPharmaciesTable from './AssociatedPharmaciesTable';
import { SortingFilter } from '../../../store/appcommon/types';
import { AssociatedPharmaciesFiltersModel, PharmacyManagementDetails } from '../../../store/coveredentityinfo/pharmacymanagement/types';
import { getPharmacyManagement, updatePharmacyManagementFilters, updatePharmacyManagementPage, updatePharmacyManagementSortFilters } from '../../../store/coveredentityinfo/pharmacymanagement/actioncreators';
import { getPagedPharmacyManagementList } from '../../../store/coveredentityinfo/pharmacymanagement/selectors';

import ErrorImage from '../../common/errors/ErrorImage';
import NeutralImage from '../../../themes/kalderos/static/media/neutral-green.svg';
import { canUseCachedRecords } from '../../../utils/datatableHelper';
import TransactionsTablePagingMenu from '../../common/transactions/TransactionsTablePagingMenu';
import AssociatedPharmaciesFilters from './AssociatedPharmaciesFilters';
import { FilterValueSummary } from '../../common/filter/FilterToggleButton';
import { formatMMDDYYYY } from '../../../utils/dateHelper';
import EmptyTransactions from '../../common/transactions/EmptyTransactions';

export interface AssociatedPharmaciesProps {
  isLoading: boolean;
  totalCount: number;
  currentApiPage: number;
  currentPage: number;
  idCode: string;
  associatedPharmacies: PharmacyManagementDetails[];
  isEditMode: boolean;
  isRemoveColumnVisible: boolean;
  filters: AssociatedPharmaciesFiltersModel;
  getPharmacyDetails: (idCode: string) => void;
  updatePharmacyManagementSortFilters: (sortFilters: SortingFilter) => void;
  updatePharmacyManagementPage:  (page: number) => void;
  updatePharmacyManagementFilters: (filters: AssociatedPharmaciesFiltersModel) => void;
}

interface AssociatedPharmaciesState {
  isFilterOpen: boolean;
}

export const filterLabels: { [key: string]: string} = {
  city: 'City',
  dea: 'DEA',
  endDate: 'End Date',
  hin: 'HIN',
  npi: 'NPI',
  pharmacyName: 'Pharmacy Name',
  pharmacyType: 'Pharmacy Type',
  startDate: 'Start Date',
  state: 'State',
  termEndDate: 'Term End Date',
  termStartDate: 'Term Start Date'
}

export class AssociatedPharmacies extends React.Component<AssociatedPharmaciesProps, AssociatedPharmaciesState> {
  tableHeaderRef: React.RefObject<HTMLDivElement>;

  constructor (props: Readonly<AssociatedPharmaciesProps>) {
    super(props)
    this.state = {
      isFilterOpen: false,
    }

    this.tableHeaderRef = React.createRef<HTMLDivElement>();
  }

  componentDidMount () {
    document.addEventListener('mouseup', (e) => this.handleMouseUp(e));
  }

  componentWillUnmount () {
    document.removeEventListener('mouseup', (e) => this.handleMouseUp(e));
  }

  handlePageChange = (e: any, d: any) => {
    const activePage = d.value === 'page-left' ? this.props.currentPage - 1 : this.props.currentPage + 1;
    this.props.updatePharmacyManagementPage(activePage);
    if (!canUseCachedRecords(activePage, this.props.currentApiPage, UI_PAGE_SIZE, API_PAGE_SIZE)) {
      this.props.getPharmacyDetails(this.props.idCode);
    }
  }

  handleSortColumn = (sort: SortingFilter) => {
    this.props.updatePharmacyManagementSortFilters(sort)
  }

  handleFilterToggle = (isOpen: boolean) => {
    this.setState({ isFilterOpen: isOpen });
  }

  handleMouseUp = (event: MouseEvent) => {
    if (this.state.isFilterOpen) {
      if (this.tableHeaderRef && !this.tableHeaderRef.current?.contains(event.target as Node) && !this.props.isLoading) {
        this.setState({ isFilterOpen: false })
      }
    }
  }

  getFilterSummary = () => {
    const filters = this.props.filters ? this.props.filters : {};
    const summary: FilterValueSummary[] = [];

    Object.keys(filters).forEach((key) => {
      const val = (filters as { [key: string]: any })[key]

      if (Array.isArray(val)) {
        summary.push({ name: filterLabels[key], values: val })
      } else if (val) {
        if (key === 'startDate' || key === 'termStartDate' || key === 'endDate' || key === 'termEndDate') {
          summary.push({ name: filterLabels[key], values: [formatMMDDYYYY(val)] })
        } else {
          summary.push({ name: filterLabels[key], values: [val] })
        }
      }
    });
    
    return summary;
  }

  applyFilter = (values: AssociatedPharmaciesFiltersModel) => {
    this.props.updatePharmacyManagementFilters(values);
  }

  clearFilter = () => {
    const emptyFilters: AssociatedPharmaciesFiltersModel = {
      city: '',
      dea: '',
      endDate: '',
      hin: '',
      npi: '',
      pharmacyName: '',
      pharmacyType: null,
      startDate: '',
      state: null,
      termEndDate: '',
      termStartDate: ''
    };

    this.applyFilter(emptyFilters);
    this.setState({ isFilterOpen: false });
  }

  render () {
    const { currentPage, totalCount, associatedPharmacies, isLoading, isEditMode, isRemoveColumnVisible, filters } = this.props;
    const { isFilterOpen } = this.state

    const bannerText = !isEditMode ? `If any of this information is incorrect, contact customer support at ${SUPPORT_PHONE}.` :
      `Please designate whether the pharmacy purchases non-340B drugs on your covered entity’s GPO contract(s) or on a standard WAC/retail account. If some drugs are purchased at GPO and some at WAC, you should select GPO. If any of this information is incorrect, contact customer support at ${SUPPORT_PHONE}.`
    const areFiltersApplied = Object.values(this.props.filters).some(filter => (filter !== null && filter !== ''));
    const filterSummary = this.getFilterSummary();
    const filterCount = filterSummary.length;

    return (
      <div className={cx('associated-pharmacies-tab', { 'gray': isEditMode }, { 'no-content': associatedPharmacies.length === 0 })}>
        {isLoading && <Dimmer active inverted><Loader className="associated-pharmacies-tab-loader" inverted content="Details Loading..." /></Dimmer>}

        {(!isLoading && associatedPharmacies.length === 0 && !areFiltersApplied) ?
          <ErrorImage
            header={NO_ASSOCIATED_PHARMACIES_HEADER}
            image={NeutralImage}
            message={NO_ASSOCIATED_PHARMACIES_MESSAGE}
          /> :
          <Fragment>
            <div className='credits-info-banner'>
              {bannerText}
            </div>
            <div className="padded-tab">
              <div className="table-header basic" ref={this.tableHeaderRef}>
                <TransactionsTablePagingMenu
                  className={cx('associated-pharmacies-tab-paging-menu', {'gray': isFilterOpen})}
                  currentPage={currentPage}
                  filterCount={filterCount}
                  filterSummary={filterSummary}
                  handlePageChange={this.handlePageChange}
                  isFilterOpen={isFilterOpen}
                  onFilterClear={this.clearFilter}
                  onFilterToggle={this.handleFilterToggle}
                  pageSize={UI_PAGE_SIZE}
                  showFilterToggleButton
                  totalCount={totalCount}
                />
                {isFilterOpen && (
                  <AssociatedPharmaciesFilters
                    filters={filters}
                    onApplyFilter={this.applyFilter}
                  />
                )}
              </div>
              <AssociatedPharmaciesTable 
                handleCellSorting={this.handleSortColumn} 
                associatedPharmacies={associatedPharmacies} 
                isEditMode={isEditMode} 
                isRemoveColumnVisible={isRemoveColumnVisible} 
              />
            </div>
          </Fragment>
        }


        {(!isLoading && associatedPharmacies.length === 0 && areFiltersApplied) && (
          <EmptyTransactions
            emptyTransactionsHeader={NO_FILTER_RESULTS_HEADER}
            emptyTransactionsMessage={NO_FILTER_RESULTS_MESSAGE}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  totalCount: state.PharmacyManagementState.associatedPharmaciesModel.totalCount,
  currentApiPage: state.PharmacyManagementState.associatedPharmaciesModel.currentApiPage,
  currentPage: state.PharmacyManagementState.associatedPharmaciesModel.currentPage,
  isLoading: state.PharmacyManagementState.associatedPharmaciesModel.isLoading,
  associatedPharmacies: getPagedPharmacyManagementList(state),
  filters: state.PharmacyManagementState.associatedPharmaciesModel.filters
});


const mapDispatchToProps = (dispatch: any) => ({
  getPharmacyDetails: (idCode: string) => dispatch(getPharmacyManagement(idCode)),
  updatePharmacyManagementSortFilters: (sortFilters: SortingFilter) => dispatch(updatePharmacyManagementSortFilters(sortFilters)),
  updatePharmacyManagementPage: (pageNumber: number) => dispatch(updatePharmacyManagementPage(pageNumber)),
  updatePharmacyManagementFilters: (filters: AssociatedPharmaciesFiltersModel) => dispatch(updatePharmacyManagementFilters(filters)) 
});

export default connect(mapStateToProps, mapDispatchToProps)(AssociatedPharmacies);
