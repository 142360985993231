import React, { ChangeEvent, Fragment } from 'react';
import { Form } from 'semantic-ui-react';
import cx from 'classnames';

interface NameAndEmailFieldsProps {
  values: any,
  handleChange?: {(e: ChangeEvent<any>): void; <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any> ? void : (e: string | ChangeEvent<any>) => void}; 
  handleBlur?: {(e: ChangeEvent<any>): void; <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any> ? void : (e: string | ChangeEvent<any>) => void}; 
  includeEmail?: boolean,
  errors: any
  touched: any
}

const NameAndEmailFields: React.FunctionComponent<NameAndEmailFieldsProps> = ({ values, handleChange, handleBlur, includeEmail, errors, touched }) => {
  return (
    <Fragment>
      <Form.Input data-private width={11} name="firstName" label="First name" defaultValue={values.firstName} onChange={handleChange} onBlur={handleBlur} className={cx({'input-error': errors?.firstName && touched?.firstName})}/>
      {errors?.firstName && touched?.firstName && <div className="error-text">First name invalid</div>}
      <Form.Input data-private width={11} name="lastName" label="Last name" defaultValue={values.lastName} onChange={handleChange} onBlur={handleBlur} className={cx({'input-error': errors?.lastName && touched?.lastName})}/>
      {errors?.lastName && touched?.lastName && <div className="error-text">Last name invalid</div>}
      {includeEmail && 
      <Fragment>
        <Form.Input data-private width={16} name="email" label="Email" defaultValue={values.email} onChange={handleChange} onBlur={handleBlur} className={cx({'input-error': errors?.email && touched?.email})}/>
        {errors?.email && touched?.email && <div className="error-text">Email invalid</div>}
      </Fragment>}
    </Fragment>
  )
}

export default NameAndEmailFields;