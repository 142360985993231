import {
  TransactionsCommonActionTypes,
  REVERSE_TRANSACTIONS,
  REVERSE_TRANSACTIONS_SUCCESS,
  REVERSE_TRANSACTIONS_FAILURE,
  GET_CONTRACT_ELEMENTS,
  GET_CONTRACT_ELEMENTS_SUCCESS,
  GET_CONTRACT_ELEMENTS_FAILURE,
} from './constants';

import { TransactionsCommonState, } from './types';

const initialState: TransactionsCommonState = {
  reversalsModal: {
    isSuccess: false,
    isLoading: false,
    errorText: ''
  },
  contractElements: {
    retailContractElements: [],
    medicalContractElements: [],
    isLoading: false,
    errorText: '',
  }
}

export function transactionsCommonReducer (state = initialState, action: TransactionsCommonActionTypes): TransactionsCommonState {
  switch (action.type) {

    case REVERSE_TRANSACTIONS:
      return {
        ...state,
        reversalsModal: {
          ...state.reversalsModal,
          isLoading: true,
          isSuccess: false,
          errorText: '',
        }
      }

    case REVERSE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        reversalsModal: {
          ...state.reversalsModal,
          isLoading: false,
          isSuccess: true
        }
      }

    case REVERSE_TRANSACTIONS_FAILURE:
      return {
        ...state,
        reversalsModal: {
          ...state.reversalsModal,
          isLoading: false,
          errorText: action.payload.message
        }
      }

    case GET_CONTRACT_ELEMENTS:
      return {
        ...state,
        contractElements: {
          ...state.contractElements,
          isLoading: true,
          errorText: ''
        }
      }

    case GET_CONTRACT_ELEMENTS_SUCCESS:
      return {
        ...state,
        contractElements: {
          ...state.contractElements,
          retailContractElements: action.payload.retailContractElements,
          medicalContractElements: action.payload.medicalContractElements,
          isLoading: false
        }
      }

    case GET_CONTRACT_ELEMENTS_FAILURE:
      return {
        ...state,
        contractElements: {
          ...state.contractElements,
          isLoading: false,
          errorText: action.payload.message
        }
      }

    default:
      return state
  }
}
