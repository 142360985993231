import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { RemittancePeriodSummary } from '../../../store/remittance/types';

const CoveredEntityFilterOption: React.FunctionComponent<{ coveredEntity: string, numberPayments: number }> = ({ coveredEntity, numberPayments }) => {
  return (
    <div className="flex-column covered-entity-item-first covered-entity-name">
      <div className="beta data">{coveredEntity === 'ALL' ? 'All Covered Entities' : coveredEntity}</div>
      <div className="covered-entity-sub-row">
        <div className="covered-entity-item-label eta data covered-entity-sub-item-first">{numberPayments > 1 ? (numberPayments + ' Payments') : (numberPayments + ' Payment')}</div>
      </div>
    </div>
  )
}

export interface RemittanceCoveredEntityFilterProps {
  period: RemittancePeriodSummary;
  selectedCoveredEntity: string;
  onFilterChange: (coveredEntity: string) => void;
}

const RemittanceCoveredEntityFilter: React.FunctionComponent<RemittanceCoveredEntityFilterProps> = (props) => {
  const { period, selectedCoveredEntity, onFilterChange } = props;

  const ALL_MANUFACTURER_OPTION = {
    key: 'ALL',
    value: 'ALL',
    text: <CoveredEntityFilterOption coveredEntity={'ALL'} numberPayments={period.numberPayments} />
  };

  const options = [ALL_MANUFACTURER_OPTION, ...period.remittanceCoveredEntitySummaryDetails.map((detail) => ({
    key: detail.coveredEntityId,
    value: detail.coveredEntityId,
    text: <CoveredEntityFilterOption coveredEntity={detail.coveredEntityId} numberPayments={detail.numberPayments} />
  }))];

  return (
    <div className='covered-entity-filter'>
      <div className='zeta title'>Covered Entity Filter</div>
      <Dropdown
        scrolling
        selection
        className='covered-entity-filter-dropdown remittanceDetailCeFilter_Pendo'
        icon={'chevron down'}
        onChange={(e, d) => onFilterChange(d.value as string)}
        options={options}
        value={selectedCoveredEntity}
      />
    </div>
  )
}

export default RemittanceCoveredEntityFilter;
