import React from 'react'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../../store/RootState';
import { Modal } from 'semantic-ui-react';
import RequestConfig from '../../app.config';

import '../../themes/kalderos/Login/IdleTimer.scss'

let seconds = 900
async function getSeconds () {
  seconds = await RequestConfig('idleTimeoutSeconds')
}

interface IdleTimerProps extends RouteComponentProps {
  isAuthenticated?: boolean;
}

interface IdleTimerState {
  open: boolean;
}

export class IdleTimer extends React.Component<IdleTimerProps, IdleTimerState>{

  constructor (props: Readonly<IdleTimerProps>) {
    super(props);

    this.state = {
      open: false
    };
  }

  logOutTimer = setTimeout(()=>{}, 60000);
  timer = setTimeout(()=>{}, 900000);

  componentDidMount () {
    getSeconds()
    this.idleTimer()
  }

  idleTimer = () => {
    window.onload = this.resetTimer;
    window.onmousemove = this.resetTimer;
    window.onmousedown = this.resetTimer;
    window.ontouchstart = this.resetTimer;
    window.onwheel = this.resetTimer;
    window.onscroll = this.resetTimer;
    window.onclick = this.resetTimer;
    window.onkeydown = this.resetTimer;
    window.addEventListener('scroll', this.resetTimer, true);
  }

  resetTimer = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(this.onIdle, seconds * 1000)
  }

  onIdle = () => {
    if (!this.props.isAuthenticated) {
      return
    } else {
      if (this.state.open === false) {
        this.setState({open: true})
        this.logOutTimer = setTimeout(this.logOut, 60000)
      }
    }
  }
  logOut = () => {
    clearTimeout(this.logOutTimer)
    this.setState({open: false})
    this.props.history.push('/logout')
  }

  stayAvtice = () => {
    this.setState({open: false})
    clearTimeout(this.logOutTimer)
  }

  render () {
    return (
      <Modal
        open={this.state.open}
        className="idle-timer-modal"
      >
        <div className="idle-timer-modal-container">
          <div className='beta idle-timer-modal-header'>Still here?</div>
          <div className="idle-timer-modal-body">We noticed you have been inactive for a bit. You will be automatically logged out soon.</div>
          <div className="idle-timer-modal-button-container">
            <Link to='/logout'  onClick={() => this.setState({open: false})}>
              <button type="button" className='idle-timer-modal-signout-button app-button button-white'>Sign out</button>
            </Link>
            <button type='button' className='app-button button-dark' onClick={this.stayAvtice}>Stay active</button>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    isAuthenticated: state.LoginState.user.isAuthenticated
  }
}

export default withRouter(connect(mapStateToProps, null)(IdleTimer))
