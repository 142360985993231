import { ROLES } from '../store/applicationConstants';
import { USER_TYPE } from '../store/login/constants';

const isUserType = (roleType: string, roles: string[]) => roles.findIndex(role => role.toUpperCase().startsWith(roleType.toUpperCase())) >= 0;

export const isRecipient = (roles: string[]) => isUserType(USER_TYPE.RECIPIENT, roles);
export const isPayer = (roles: string[]) => isUserType(USER_TYPE.PAYER, roles);
export const isStakeHolder = (roles: string[]) => isUserType(USER_TYPE.STAKEHOLDER, roles);
export const isReviewUser = (roles: string[]) => isUserType(USER_TYPE.REVIEW, roles);

export const isAdminAllAccess = (roles: string[]) => roles?.includes(ROLES.ADMIN_ALL_ACCESS) || false;
export const isAdminSupport = (roles: string[]) =>  roles?.includes(ROLES.ADMIN_SUPPORT) || false;