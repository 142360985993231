import fetchWrapper from '../../FetchWrapper';
import RequestConfig from '../../../app.config';
import { API_PAGE_SIZE } from '../../../store/applicationConstants';
import { createUrlParametersString } from '../../../utils/requestUtil';
import { commonResponseHandler, RESPONSE_TYPE } from '../../../utils/responseUtils';

export const fetchMdrpDisputesSummary = async (stateCode: string) => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/mdrp/state/disputes/summary?stateCode=${stateCode}`, true);
  return commonResponseHandler(response, RESPONSE_TYPE.JSON)
}

export const fetchMdrpManufacturerSummaries = async (stateCode: string, manufacturerId?: number) => {
  let apiUrl = `${await RequestConfig()}/request/v1/mdrp/state/disputes/manufacturerSummaries?stateCode=${stateCode}`;
  if (manufacturerId) {
    apiUrl += `&businessId=${manufacturerId}`
  }
  const response = await fetchWrapper(apiUrl, true);
  return commonResponseHandler(response, RESPONSE_TYPE.JSON)
}

export const fetchMdrpManufacturerDisputes = async (stateCode: string, manufacturerId: number, disputeStatus: string, pageToFetch: number, sortBy: string, isSortAsc: boolean, filters: string) => {
  const urlParams = createUrlParametersString({ businessMasterId: manufacturerId, DisputeStatus: disputeStatus, page: pageToFetch, pageSize: API_PAGE_SIZE, sortField: sortBy, sortDesc: !isSortAsc });
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/mdrp/state/disputes/${stateCode}/claims?${urlParams}${filters}`, true); 
  return commonResponseHandler(response, RESPONSE_TYPE.JSON)
}

export const fetchMdrpManufacturerDisputesFilterOptions = async (stateCode: string, manufacturerId: number, disputeStatus: string, filters?: string) => {
  const urlParams = createUrlParametersString({ businessMasterId: manufacturerId, DisputeStatus: disputeStatus });
  let apiUrl = `${await RequestConfig()}/request/v1/mdrp/state/disputes/${stateCode}/claims/filterOptions?${urlParams}`;
  if (filters) {
    apiUrl += `${filters}`
  }
  const response = await fetchWrapper(apiUrl, true);
  return commonResponseHandler(response, RESPONSE_TYPE.JSON)
}

export const fetchMdrpDisputeResponseTotals = async (stateCode: string, manufacturerId: number) => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/mdrp/state/disputes/${stateCode}/claims/responseTotals?businessMasterId=${manufacturerId}`, true); 
  return commonResponseHandler(response, RESPONSE_TYPE.JSON)
}

export const updateMdrpSingleClaimResponse = async (stateCode: string, manufacturerId: number, claimId: number, stateResponse: string) => {
  const urlParams = createUrlParametersString({ businessMasterId: manufacturerId});
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/mdrp/state/disputes/${stateCode}/claims/${claimId}?${urlParams}`, true,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ StateResponse: stateResponse }),
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.NONE);
}
