import RequestConfig from '../../app.config';
import fetchWrapper from '../FetchWrapper';
import { commonResponseHandler, RESPONSE_TYPE } from '../../utils/responseUtils';
import { EditRoleModel, EditUserModel, OnboardingUserInviteModel } from '../../store/teammanagement/types';
import { createUrlParametersString, trimInputFields } from '../../utils/requestUtil';

export const addUserRequest = async (healthSystemId: number, email: string, role: string) => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/healthSystem/inviteNewUser`, true, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      healthSystemId: healthSystemId,
      email: email,
      role: role,
    })
  });
  return commonResponseHandler(response);
};

export const assignCERequest = async (healthSystemId: number, userId: number, coveredEntityIds: number[]) => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/healthSystem/assignCoveredEntities`, true, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      healthSystemId: healthSystemId,
      userId: userId,
      coveredEntityIds: coveredEntityIds,
    })
  });
  return commonResponseHandler(response, RESPONSE_TYPE.TEXT);
};

export const updateUserRole = async (editRoleModel: EditRoleModel) => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/healthSystem/editRole`, true,
    {
      method: 'POST',
      body: JSON.stringify(editRoleModel),
      headers: {
        'Content-Type' : 'application/json'
      }
    }
  );
  return commonResponseHandler(response)
}

export const updateUser = async (editUserModel: EditUserModel) => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/healthSystem/editUser`, true,
    {
      method: 'PUT',
      body: JSON.stringify(editUserModel),
      headers: {
        'Content-Type' : 'application/json'
      }
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.TEXT)
}

export const cancelInviteRequest = async (healthSystemId: number, userId: number) => {
  const urlParams = createUrlParametersString({ healthSystemId: healthSystemId, userId: userId});
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/healthSystem/cancelInvite?${urlParams}`, true,
    {
      method: 'DELETE',
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.TEXT)
}


export const inviteAccountAdminRequest = async (input: OnboardingUserInviteModel, healthSystemId: number) => {
  const urlParams = createUrlParametersString({ healthSystemId: healthSystemId })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/onboarding/inviteaccountadmin?${urlParams}`, true,
    {
      method: 'POST',
      body: trimInputFields(JSON.stringify(input)),
      headers: {
        'Content-Type': 'application/json',
      }
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.NONE);
}

export const inviteControllerRequest = async (input: OnboardingUserInviteModel, healthSystemId: number) => {
  const urlParams = createUrlParametersString({ healthSystemId: healthSystemId })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/onboarding/invitecontroller?${urlParams}`, true,
    {
      method: 'POST',
      body: trimInputFields(JSON.stringify(input)),
      headers: {
        'Content-Type': 'application/json',
      }
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.NONE);
}

export const disableUserCall = async (teamId: number, userId: number) => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/healthSystem/user/disable?teamId=${teamId}&userId=${userId}`, true, {
    method: 'PUT'
  });
  return commonResponseHandler(response, RESPONSE_TYPE.NONE);
}

export const enableUserCall = async (teamId: number, userId: number) => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/healthSystem/user/enable?teamId=${teamId}&userId=${userId}`, true, {
    method: 'PUT'
  });
  return commonResponseHandler(response, RESPONSE_TYPE.NONE);
}