import React, { Fragment } from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import RequestCenter, { getRecipientRequestCenterTabIndex } from './pages/requestcenter/RequestCenter';
import Reversals, { getReversalsCenterTabIndex } from './pages/reversals/Reversals';
import Toasts from './pages/common/toasts/Toast';
import Login from './pages/login/Login';
import Logout from './pages/login/Logout';
import IdleTimer from './pages/login/IdleTimer';
import ProtectedRoute from './ProtectedRoute';
import ForgotPassword from './pages/password/ForgotPassword'
import ResetPassword from './pages/password/ResetPassword';
import AuthorizingOfficialSignUp from './pages/onboarding/Recipient/AuthorizingOfficialSignUp/AuthorizingOfficialSignUp';
import LeftNavigation from './pages/common/navigation/LeftNavigation';
import RightNavigation from './pages/common/navigation/RightNavigation';
import RecipientPaymentsCenter, { getRecipientPaymentsCenterTabIndex } from './pages/payments/recipient'
import AuthLoader from './pages/login/AuthLoader';
import { ROLES } from './store/applicationConstants';
import Zendesk from 'react-zendesk';
import CoveredEntityInfo, { getCoveredEntityInfoCenterTabIndex } from './pages/coveredentityinfo/CoveredEntityInfoCenter';
import AssociatedPharmacies, { getPharmacyManagementCenterTabIndex } from './pages/coveredentityinfo/PharamacyManagement/PharmacyManagementCenter';
import { default as RecipientTeamManagement, getRecipientTeamManagementTabIndex } from './pages/teammanagement/TeamManagement';
import HealthSystemModal from './pages/teammanagement/Modals/HealthSystemsModal';
import HealthSystemSearchModal from './pages/teammanagement/Modals/HealthSystemsSearchModal';
// import SingularRemittance from './pages/remittance/SingularRemittance';
import AcceptInvite from './pages/password/AcceptInvite';
import { ZENDESK_KEY } from './store/applicationConstants';
import { settings, setSuggestions } from './integrations/ZenDeskInit';
import RestrictedByLogin from './pages/login/RestrictedByLogin';
import RestrictedByOnboarding from './pages/login/RestrictedByOnboarding';
import RestrictedByRole from './pages/teammanagement/RestrictedByRole';
import DisputesCenter from './pages/mdrp/disputescenter/DisputesCenter';
import { getDisputesTabMap, getOpenDisputesTabMap } from './store/mdrp/disputescenter/util';
import ManufacturerReview from './pages/mdrp/disputescenter/ManufacturerReview';
import { convertStringOrUndefinedToNumber, convertStringOrUndefinedToString } from './utils/dataUtil';
import OnboardingManager from './pages/onboarding/OnboardingManager';
import SignUpError from './pages/login/SignUpError';
import RemittanceSummary from './pages/remittance/Summary/RemittanceSummary';
import RemittancePeriodDetails from './pages/remittance/Details/RemittancePeriodDetails';
import RemittanceInvoiceDetails from './pages/remittance/Details/RemittanceInvoiceDetails';
import { getRemittanceDetailsTabIndex } from './pages/remittance/Transactions/RemittanceTransactionsTabs';

const App = () => {

  return (
    <Fragment>
      <Switch>
        <Fragment>
          <AuthLoader />
          <div className='app flex-row flex-grow scroll-hidden'>
            <LeftNavigation />
            <IdleTimer />
            <div className='app-content flex-column flex-grow scroll-hidden'>
              {/* <Switch> */}

              <Route exact path="/" component={Login} />
              <HealthSystemModal />
              <HealthSystemSearchModal />

              {/* protected routes */}
              <RestrictedByLogin>

                <RestrictedByOnboarding>

                  <RestrictedByRole allowedRoles={[ROLES.RECIPIENT]} checkUserRole>
                    <ProtectedRoute exact path="/recipients/requestcenter" render={() => <Redirect to="/recipients/requestcenter/all" />} />
                    <ProtectedRoute exact path="/recipients/requestcenter/(all|awaiting|processing|processed)" render={(props) => <RequestCenter {...props} activeIndex={getRecipientRequestCenterTabIndex(props.location.pathname)} />} />

                    <ProtectedRoute exact path="/recipients/reversals" render={() => <Redirect to="/recipients/reversals/pending" />} />
                    <ProtectedRoute exact path="/recipients/reversals/(pending|processed)" render={(props) => <Reversals {...props} activeTab={getReversalsCenterTabIndex(props.location.pathname)} />} />

                    <ProtectedRoute exact path="/recipients/payments" render={() => <Redirect to="/recipients/payments/history" />} />
                    <ProtectedRoute exact path="/recipients/payments/(history|settings|credits)" render={(props) => <RecipientPaymentsCenter {...props} activeIndex={getRecipientPaymentsCenterTabIndex(props.location.pathname)} />} />

                    <ProtectedRoute exact path="/recipients/coveredentityinfo" render={() => <Redirect to="/recipients/coveredentityinfo/ceinfo" />} />
                    <ProtectedRoute exact path="/recipients/coveredentityinfo/(ceinfo|healthsystem)" render={(props) => <CoveredEntityInfo {...props} activeIndex={getCoveredEntityInfoCenterTabIndex(props.location.pathname)} />} />
                    <RestrictedByRole allowedRoles={[ROLES.ADMIN_ALL_ACCESS]} checkUserRole>
                      <ProtectedRoute exact path="/recipients/coveredentityinfo/:id/(associatedpharmacies|edithistory)" render={(props) => <AssociatedPharmacies {...props} idCode={props.match.params.id} activeIndex={getPharmacyManagementCenterTabIndex(props.location.pathname)} />} />
                    </RestrictedByRole>

                    <ProtectedRoute exact path="/recipients/remittance" component={RemittanceSummary} />
                    <ProtectedRoute exact path="/recipients/remittance/period/:year/:month/:period" render={(props) => <Redirect to={{ pathname: `/recipients/remittance/period/${props.match.params.year}/${props.match.params.month}/${props.match.params.period}/all`, search: props.location.search }} />} />
                    <ProtectedRoute exact path="/recipients/remittance/period/:year/:month/:period/(all|failed|adjusted|approved|processed)" render={(props) => <RemittancePeriodDetails {...props} year={convertStringOrUndefinedToNumber(props.match.params.year)} month={convertStringOrUndefinedToNumber(props.match.params.month)} period={convertStringOrUndefinedToNumber(props.match.params.period)} activeIndex={getRemittanceDetailsTabIndex(props.location.pathname)} />} />
                    <ProtectedRoute exact path="/recipients/remittance/invoice/:id/:participantId" render={(props) => <Redirect to={`/recipients/remittance/invoice/${props.match.params.id}/${props.match.params.participantId}/all`} />} />
                    <ProtectedRoute exact path="/recipients/remittance/invoice/:id/:participantId/(all|failed|adjusted|approved|processed)" render={(props) => <RemittanceInvoiceDetails {...props} remittanceId={convertStringOrUndefinedToNumber(props.match.params.id)} participantId={convertStringOrUndefinedToString(props.match.params.participantId)} activeIndex={getRemittanceDetailsTabIndex(props.location.pathname)} />} />

                    <ProtectedRoute exact path="/recipients/teammanagement" render={() => <Redirect to="recipients/teammanagement/users" />} />
                    <ProtectedRoute exact path="/recipients/teammanagement/(users|coveredentities)" render={(props) => <RecipientTeamManagement {...props} activeIndex={getRecipientTeamManagementTabIndex(props.location.pathname)} />} />
                  </RestrictedByRole>

                </RestrictedByOnboarding>

                {/* onboarding */}
                <RestrictedByRole allowedRoles={[ROLES.RECIPIENT]} checkUserRole>
                  <ProtectedRoute exact path='/onboarding' component={OnboardingManager} />
                </RestrictedByRole>

                {/* State MDRP routes */}
                <RestrictedByOnboarding>

                  <RestrictedByRole allowedRoles={[ROLES.RECIPIENT]} checkUserRole>
                    <ProtectedRoute exact path={'/states/disputes'} render={() => <Redirect to="/states/disputes/open" />} />
                    <ProtectedRoute exact path={'/states/disputes/(open|closed)'} render={(props) => <DisputesCenter {...props} activeIndex={getDisputesTabMap(props.location.pathname)} />} />
                    <ProtectedRoute exact path={'/states/disputes/open/manufacturer/:id/(newdisputes|senttomfr|impasse)'} render={(props) => <ManufacturerReview {...props} activeIndex={getOpenDisputesTabMap(props.location.pathname)} manufacturerId={convertStringOrUndefinedToNumber(props.match.params.id)} />} />
                  </RestrictedByRole>

                </RestrictedByOnboarding>

              </RestrictedByLogin>

              {/* unprotected routes*/}
              <Route exact path="/logout" render={(props) => <Logout {...props} clearToken={true} />} />
              <Route exact path="/forgotpassword" render={(props) => <ForgotPassword  {...props} expired={false} />} />
              <Route exact path="/authorizingofficialsignup" render={(props) => <AuthorizingOfficialSignUp  {...props} />} />
              <Route exact path="/expiredpassword" render={(props) => <ForgotPassword {...props} expired={true} />} />
              <Route exact path="/initialLogin/:id" render={(props) => <ResetPassword {...props} token={props.match.params.id} />} />
              <Route exact path="/acceptInvite/:id" render={(props) => <AcceptInvite {...props} token={props.match.params.id} />} />
              <Route exact path="/signuperror" render={(props) => <SignUpError {...props} />} />
            </div>
            <div className='right-navigation-container flex-column'>
              <RightNavigation />
            </div>
          </div>
        </Fragment>
      </Switch>
      <Toasts />
      <Zendesk zendeskKey={ZENDESK_KEY} {...settings} onLoaded={() => setSuggestions()} />
    </Fragment>
  );
}

export default withRouter(App);
