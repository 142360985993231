import React from 'react';
import { connect } from 'react-redux';
import { Form, Checkbox, Icon } from 'semantic-ui-react';

import { RootState } from '../../../../store/RootState';
import Layout from '../../Layout';
import { formatMMDDYYYY } from '../../../../utils/dateHelper';
import { getTermsAndConditions } from '../../../../store/onboarding/actionCreators'
import { TermsAndConditionsConfirmation, AoInfo } from '../../../../store/onboarding/types';
import { LegalDocumentsModel } from '../../../../store/appcommon/types';
import ScreenReaderMessage from '../../../common/ScreenReaderMessage';

import '../../../../themes/kalderos/Onboarding/Recipient/TermsAndConditions.scss';

const DWOLLA_TERMS_URL = 'https://www.dwolla.com/legal/tos';
const DOWLLA_PRIVACY_POLICY_URL = 'https://www.dwolla.com/legal/privacy';

export interface TermsAndConditionsProps {
  termsText: string,
  confirmation?: TermsAndConditionsConfirmation
  aoInfo: AoInfo,
  isLoading: boolean,
  errorText: string,
  legalDocuments: LegalDocumentsModel,
  getTermsAndConditions: () => void;
  onBack: () => void;
  onConfirm: () => void;
}

interface TermsAndConditionsState {
  termsRead: boolean,
  confirmChecked: boolean,
  dateInput: string,
}

export class TermsAndConditions extends React.Component<TermsAndConditionsProps, TermsAndConditionsState>{
  constructor (props: Readonly<TermsAndConditionsProps>) {
    super(props);
    this.state = {
      termsRead: false,
      confirmChecked: false,
      dateInput: formatMMDDYYYY(new Date()),
    };
  }

  componentDidMount () {
    this.props.getTermsAndConditions();
  }

  handleScroll = (e: any) => {
    if (!this.state.termsRead) {
      const bottom = (e.target.scrollHeight - e.target.scrollTop) < (e.target.clientHeight + 5); //add a small buffer to the calculation due to pixel scaling discrepencies

      if (bottom) {
        this.setState({ termsRead: true })
      }
    }
  }

  onConfirmCheck = () => {
    this.setState({ confirmChecked: !this.state.confirmChecked });
  }

  onCheckBoxLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation(); //prevents toggling checkbox when clicking the link
  }

  onNextClick = () => {
    this.props.onConfirm();
  }

  render () {
    const { confirmation, aoInfo, termsText, isLoading, errorText, legalDocuments } = this.props;
    const name = confirmation?.name ?? `${aoInfo.aoFirstName ?? ''} ${aoInfo.aoLastName ?? ''}`
    const date = formatMMDDYYYY(confirmation?.date ?? new Date());

    const kalderosTerms = <a href={legalDocuments.termsAndConditions} target='_blank' rel="noopener noreferrer" onClick={this.onCheckBoxLinkClick}>Terms &amp; Conditions<ScreenReaderMessage>(opens in a new tab)</ScreenReaderMessage></a>;
    const kalderosPrivacy = <a href={legalDocuments.privacyPolicy} target='_blank' rel="noopener noreferrer" onClick={this.onCheckBoxLinkClick}>Privacy Policy<ScreenReaderMessage>(opens in a new tab)</ScreenReaderMessage></a>

    const dwollaTerms = <a href={DWOLLA_TERMS_URL} target='_blank' rel="noopener noreferrer" onClick={this.onCheckBoxLinkClick}>Dwolla&apos;s Terms &amp; Conditions<ScreenReaderMessage>(opens in a new tab)</ScreenReaderMessage></a>;
    const dwollaPrivacy = <a href={DOWLLA_PRIVACY_POLICY_URL} target='_blank' rel="noopener noreferrer" onClick={this.onCheckBoxLinkClick}>Privacy Policy<ScreenReaderMessage>(opens in a new tab)</ScreenReaderMessage></a>;

    return (
      <Layout
        isLoading={isLoading}
        errorText={errorText}
        stepNumber={2}
      >
        <div className="onboarding-layout-header">
          <div className="title">2. e-Sign Terms &amp; Conditions and Privacy Policy</div>
          <div className="sub-title">
            Below is our Terms &amp; Conditions. There are links to download the Terms &amp; Conditions and Privacy Policy from Kalderos, and our payment partner, Dwolla. Please review and agree before preceeding.
          </div>
        </div>
        <div className="onboarding-layout-children">
          <div className="onboarding-layout-body terms-and-conditions">
            <div className="terms-body">
              <textarea onScroll={this.handleScroll} readOnly value={termsText} />
            </div>
            <div className="terms-input">
              <div className="onboarding-checkbox">
                <Checkbox
                  data-private
                  checked={this.state.confirmChecked}
                  onChange={this.onConfirmCheck}
                  label={
                    <label>
                      By checking this box you agree to our {kalderosTerms} and {kalderosPrivacy}, as well as our payment partner {dwollaTerms} and {dwollaPrivacy}.
                    </label>
                  } />
              </div>
              <Form className="input-form">
                <Form.Group >
                  <Form.Input data-private readOnly fluid width={12} label='Full name' value={name} />
                  <Form.Input data-private readOnly fluid width={4} label='Today&apos;s date' value={date} />
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
        <div className="onboarding-layout-footer">
          <div className="onboarding-layout-navigation">
            <button className="app-button" onClick={()=>this.props.onBack()} type="button">
              <Icon name="chevron left" size="large" />Back
            </button>
          </div>
          <div className="onboarding-layout-cta">
            <button className="app-button button-dark" onClick={this.onNextClick} disabled={isLoading || !this.state.termsRead || !this.state.confirmChecked} type="button">Confirm and Next</button>
          </div>
        </div>
      </Layout>

    )
  }
}

const mapStateToProps = (state: RootState) => ({
  termsText: state.OnboardingState.termsAndConditionsStepModel.termsText,
  confirmation: state.OnboardingState.termsAndConditionsStepModel.confirmation,
  isLoading: state.OnboardingState.isLoading,
  errorText: state.OnboardingState.errorText,
  aoInfo: state.OnboardingState.associateCesAndHsStepModel.aoInfo,
  legalDocuments: state.AppCommonState.legalDocuments,
})

const mapDispatchToProps = (dispatch: any) => ({
  getTermsAndConditions: () => dispatch(getTermsAndConditions())
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions)
