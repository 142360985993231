import React from 'react';
import classnames from 'classnames';

interface FilterTextboxProps {
  name?: string;
  label?: string;
  value?: string;
  isDisabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

interface FilterTextboxState {
  isFocused: boolean;
}

export class FilterTextbox extends React.Component<FilterTextboxProps, FilterTextboxState> {

  textboxRef: React.RefObject<HTMLInputElement>;

  constructor (props: FilterTextboxProps) {
    super(props);
    this.state = {
      isFocused: false,
    }

    this.textboxRef = React.createRef<HTMLInputElement>();

  }

  onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    this.setState({ isFocused: true });

    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  }

  onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    this.setState({ isFocused: false })

    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      this.textboxRef.current?.blur();
      event.preventDefault();
    }
  }

  render () {
    const showOverlay = this.props.value ? !this.state.isFocused : false;

    return (

      <div className="filter-textbox">
        <div className={classnames({ 'active': this.props.value }, {'disabled': this.props.isDisabled }, 'ui fluid input')}>
          {showOverlay &&
                        <div className="overlay">
                          <div className="summary">
                            {this.props.label} ({this.props.value})
                          </div>
                        </div>
          }
          <input
            ref={this.textboxRef}
            type="text"
            name={this.props.name}
            placeholder={this.props.label}
            value={this.props.value}
            onChange={this.props.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onKeyDown={this.onKeyDown}
            disabled={this.props.isDisabled}
          />
        </div>
      </div>
    )
  }
}
