import React, { useState } from 'react';
import { Popup } from 'semantic-ui-react';
import cx from 'classnames';

import '../../themes/kalderos/Common/DataPopup.scss';

export interface DataPopupProps {
  content: string;
  id: string;
  small?: boolean;
}

const DataPopup = (props: DataPopupProps) => {
  const { content, id, small } = props;

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const checkColumnWidth = (id: string) => {
    const element = document.getElementById(id);

    if (element) {
      if (element.scrollWidth > element.clientWidth) {
        setIsPopupOpen(true);
      }
    }
  };

  return (
    <Popup
      className={cx('data-popup popup-container', { 'small': small })}
      content={content}
      hoverable
      inverted
      onOpen={() => checkColumnWidth(id)}
      onClose={() => setIsPopupOpen(false)}
      open={isPopupOpen}
      position='top left'
      size='tiny'
      trigger={
        <div className="data-popup content" id={id}>
          {content}
        </div>
      }
    />
  );
};

export default DataPopup;