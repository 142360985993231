import * as React from 'react'
import cx from 'classnames';
import { Radio, RadioProps } from 'semantic-ui-react';
import '../../../themes/kalderos/Common/Toggle/PharmacyToggleButton.scss';

const PharmacyToggleButton = (props: RadioProps) =>
  <div className={cx('pharmacy-toggle-button', { 'read-only': props.readOnly })}>
    <span className={cx('left', {'selected': !props.checked })}>GPO</span>
    <span className={cx('right', {'selected': props.checked })}>WAC</span>
    <Radio toggle {...props} />
  </div>

export default PharmacyToggleButton