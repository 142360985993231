import React from 'react'
import { Button, Icon } from 'semantic-ui-react';
import { Formik } from 'formik';

import { DiscountProgramFieldsModel, DiscountRequestAdditionalFieldsModel } from '../../../store/requestcenter/types';
import { getRequestFormSchema, orderDiscountProgramFields } from './RequestFormHelper';
import RequestFormFieldField from './RequestFormField';
import { DiscountRequestModel } from '../../../store/transactionscommon/types';

export interface CorrectDataEntryProps {
  contractFields: DiscountProgramFieldsModel[];
  transaction: DiscountRequestModel;
  submitErrors: string[];
  onSubmit: (formValues: DiscountRequestAdditionalFieldsModel) => void;
}

export const CorrectDataEntry: React.FunctionComponent<CorrectDataEntryProps> = (props) => {

  const { contractFields, transaction, submitErrors, onSubmit } = props;
  const initialValues: DiscountRequestAdditionalFieldsModel = {};

  contractFields.forEach((fld) => {
    if (fld.name === 'units') {
      initialValues.units = transaction.currentUnits;
    } else if (fld.name === 'discountPerUnit') {
      initialValues.discountPerUnit = transaction.currentDiscountPerUnit;
    } else {
      //todo: a better way than this?
      const transactionValue = transaction[fld.name as keyof DiscountRequestModel] as string | number | boolean | Date;
      if (transactionValue) {
        const key = fld.name as keyof DiscountRequestAdditionalFieldsModel;
        (initialValues[key] as string | number | boolean | Date) = transactionValue;
      }
    }
  });

  const sortedDiscountProgramFields = orderDiscountProgramFields(contractFields, transaction.claimTypeId);

  return (
    <Formik initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={getRequestFormSchema(contractFields)}
      validateOnMount={true}
      enableReinitialize={true}
    >
      {formikProps => (
        <React.Fragment>
          <div className='request-form-body flex-row flex-grow scroll-hidden'>
            {props.children}
            <div className='request-form-details flex-column v-scroll-auto'>
              <div className='form-container flex-column flex-grow'>
                <div className='form-title correct gamma'>
                  Make changes to your request (Transaction ID #{transaction.id}) below.  When you resubmit we&apos;ll
                  reverse the original request (if applicable) and submit a new request with your provided corrections.
                </div>
                <form onSubmit={formikProps.handleSubmit} className='ui form'>
                  <div className='field-info-group'>
                    <div className='form-row'>
                      {
                        sortedDiscountProgramFields.map(fld => {
                          return <RequestFormFieldField key={fld.name} field={fld} formProps={formikProps} />
                        })
                      }
                    </div>
                  </div>
                  {submitErrors && submitErrors.length > 0 &&
                    <div className='error-notification'>
                      <Icon name='warning sign' />
                      <div className="error-notification-message">
                        {'There were errors with your submission: '}
                        {submitErrors.map((error, i) => {
                          return (
                            <div className="error-notification-row" key={i}>{error}</div>
                          )
                        })}
                      </div>
                    </div>}
                </form>
              </div>
            </div>
          </div>
          <div className='request-form-footer flex-column justify-center'>
            <div className='flex-row justify-flex-end'>
              <div className='actions'>
                <Button onClick={() => formikProps.handleSubmit()} secondary disabled={!formikProps.isValid || !formikProps.dirty} type="submit">Submit</Button>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </Formik>
  )
}

export default CorrectDataEntry;