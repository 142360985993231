import { InviteUserStepInputModel, UserInfoInputModel, TermsAndConditionsConfirmation, AccountAdminConfirmInfoStepModel, CandidateHealthSystem, CandidateCoveredEntity, ControllerStatusModel, OnboardingStatusResponseModel } from './types';
import { UserInfoModel, ErrorModel } from '../applicationTypes';
import { ROLES } from '../applicationConstants';

export const RESET_ONBOARDING_STATE = 'RESET_ONBOARDING_STATE';

export const RESET_ONBOARDING_STATUS = 'RESET_ONBOARDING_STATUS';
export const CHECK_ONBOARDING_STATUS_SUCCESS = 'CHECK_ONBOARDING_STATUS_SUCCESS';
export const CHECK_ONBOARDING_STATUS_FAILED = 'CHECK_ONBOARDING_STATUS_FAILED';
export const CHECK_ONBOARDING_STATUS = 'CHECK_ONBOARDING_STATUS';

export const UPDATE_ONBOARDING_STEP = 'UPDATE_ONBOARDING_STEP';
export const UPDATE_ONBOARDING_DISMISSED_WELCOME = 'UPDATE_ONBOARDING_DISMISSED_WELCOME';

export const GET_TERMS_AND_CONDITIONS = 'RECIPIENT_GET_TERMS_AND_CONDITIONS';
export const GET_TERMS_AND_CONDITIONS_SUCCESS = 'RECIPIENT_GET_TERMS_AND_CONDITIONS_SUCCESS';
export const GET_TERMS_AND_CONDITIONS_FAILURE = 'RECIPIENT_GET_TERMS_AND_CONDITIONS_FAILURE';

export const COMPLETE_TERMS_AND_CONDITIONS_STEP = 'RECIPIENT_COMPLETE_TERMS_AND_CONDITIONS_STEP';
export const COMPLETE_TERMS_AND_CONDITIONS_STEP_SUCCESS = 'RECIPIENT_COMPLETE_TERMS_AND_CONDITIONS_STEP_SUCCESS';

export const COMPLETE_INVITE_ACCOUNT_ADMIN_STEP = 'RECIPIENT_COMPLETE_INVITE_ACCOUNT_ADMIN_STEP';
export const COMPLETE_INVITE_ACCOUNT_ADMIN_STEP_SUCCESS = 'RECIPIENT_COMPLETE_INVITE_ACCOUNT_ADMIN_STEP_SUCCESS';

export const COMPLETE_ACCOUNT_ADMIN_INFO_STEP = 'RECIPIENT_COMPLETE_ACCOUNT_ADMIN_INFO_STEP';
export const COMPLETE_ACCOUNT_ADMIN_INFO_STEP_SUCCESS = 'RECIPIENT_COMPLETE_ACCOUNT_ADMIN_INFO_STEP_SUCCESS';

export const GET_COVERED_ENTITY_INFO = 'RECIPIENT_GET_COVERED_ENTITY_INFO'
export const GET_COVERED_ENTITY_INFO_SUCCESS = 'RECIPIENT_GET_COVERED_ENTITY_INFO_SUCCESS'
export const GET_COVERED_ENTITY_INFO_FAILURE = 'RECIPIENT_GET_COVERED_ENTITY_INFO_FAILURE'

export const GET_ACCOUNT_ADMIN_SUMMARY = 'RECIPIENT_GET_ACCOUNT_ADMIN_SUMMARY'
export const GET_ACCOUNT_ADMIN_SUMMARY_SUCCESS = 'RECIPIENT_GET_ACCOUNT_ADMIN_SUMMARY_SUCCESS'
export const GET_ACCOUNT_ADMIN_SUMMARY_FAILURE = 'RECIPIENT_GET_ACCOUNT_ADMIN_SUMMARY_FAILURE'

export const COMPLETE_ACCOUNT_ADMIN_CONFIRM_INFO_STEP = 'RECIPIENT_COMPLETE_ACCOUNT_ADMIN_CONFIRM_INFO_STEP';
export const COMPLETE_ACCOUNT_ADMIN_CONFIRM_INFO_STEP_SUCCESS = 'RECIPIENT_COMPLETE_ACCOUNT_ADMIN_CONFIRM_INFO_STEP_SUCCESS';

export const COMPLETE_INVITE_CONTROLLER_STEP = 'RECIPIENT_COMPLETE_INVITE_CONTROLLER_STEP';
export const COMPLETE_INVITE_CONTROLLER_STEP_SUCCESS = 'RECIPIENT_COMPLETE_INVITE_CONTROLLER_STEP_SUCCESS';

export const COMPLETE_CONTROLLER_INFO_STEP = 'RECIPIENT_COMPLETE_CONTROLLER_INFO_STEP';
export const COMPLETE_CONTROLLER_INFO_STEP_SUCCESS = 'RECIPIENT_COMPLETE_CONTROLLER_INFO_STEP_SUCCESS';

export const COMPLETE_CONTROLLER_CONFIRM_INFO_STEP = 'RECIPIENT_COMPLETE_CONTROLLER_CONFIRM_INFO_STEP';
export const COMPLETE_CONTROLLER_CONFIRM_INFO_STEP_SUCCESS = 'RECIPIENT_COMPLETE_CONTROLLER_CONFIRM_INFO_STEP_SUCCESS';

export const COMPLETE_CONTROLLER_BANK_INFO_STEP = 'RECIPIENT_COMPLETE_CONTROLLER_BANK_INFO_STEP';
export const COMPLETE_CONTROLLER_BANK_INFO_STEP_SUCCESS = 'RECIPIENT_COMPLETE_CONTROLLER_BANK_INFO_STEP_SUCCESS';
export const COMPLETE_ONBOARDING = 'COMPLETE_ONBOARDING';

export const COMPLETE_RECIPIENT_STEP_FAILURE = 'RECIPIENT_COMPLETE_RECIPIENT_STEP_FAILURE'; //going to try using a single handler for all step progression failures until that doesn't work

export const SEND_AUTHORIZING_OFFICIAL_SIGN_UP = 'SEND_AUTHORIZING_OFFICIAL_SIGN_UP';
export const SEND_AUTHORIZING_OFFICIAL_SIGN_UP_SUCCESS = 'SEND_AUTHORIZING_OFFICIAL_SIGN_UP_SUCCESS';
export const SEND_AUTHORIZING_OFFICIAL_SIGN_UP_ERROR = 'SEND_AUTHORIZING_OFFICIAL_SIGN_UP_ERROR';
export const RESET_AUTHORIZING_OFFICIAL_SIGN_UP = 'RESET_AUTHORIZING_OFFICIAL_SIGN_UP';

export const REFRESH_DATA_AFTER_ONBOARDING = 'RECIPIENT_REFRESH_DATA_AFTER_ONBOARDING';

export const GET_CANDIDATE_CE_LIST = 'GET_CANDIDATE_CE_LIST'
export const GET_CANDIDATE_CE_LIST_SUCCESS = 'GET_CANDIDATE_CE_LIST_SUCCESS'
export const GET_CANDIDATE_CE_LIST_FAILURE = 'GET_CANDIDATE_CE_LIST_FAILURE'

export const SET_SELECTED_HEALTH_SYSTEM = 'SET_SELECTED_HEALTH_SYSTEM'
export const SET_SELECTED_ORPHAN_CES = 'SET_SELECTED_ORPHAN_CES'
export const SET_HEALTH_SYSTEM_NAME = 'SET_HEALTH_SYSTEM_NAME'
export const SET_ONBOARDING_HEALTH_SYSTEM_ID = 'SET_ONBOARDING_HEALTH_SYSTEM_ID'

export const CHECK_UNIQUE_HEALTH_SYSTEM_NAME = 'CHECK_UNIQUE_HEALTH_SYSTEM_NAME'
export const CHECK_UNIQUE_HEALTH_SYSTEM_NAME_SUCCESS = 'CHECK_UNIQUE_HEALTH_SYSTEM_NAME_SUCCESS'
export const CHECK_UNIQUE_HEALTH_SYSTEM_NAME_FAILURE = 'CHECK_UNIQUE_HEALTH_SYSTEM_NAME_FAILURE'

export const GET_HEALTH_SYSTEM_ACCOUNT_ADMIN = 'GET_HEALTH_SYSTEM_ACCOUNT_ADMIN';
export const GET_HEALTH_SYSTEM_ACCOUNT_ADMIN_SUCCESS = 'GET_HEALTH_SYSTEM_ACCOUNT_ADMIN_SUCCESS';
export const GET_HEALTH_SYSTEM_ACCOUNT_ADMIN_FAILURE = 'GET_HEALTH_SYSTEM_ACCOUNT_ADMIN_FAILURE';

export const GET_CONTROLLER_STATUS = 'GET_CONTROLLER_STATUS';
export const GET_CONTROLLER_STATUS_SUCCESS = 'GET_CONTROLLER_STATUS_SUCCESS';
export const GET_CONTROLLER_STATUS_FAILURE = 'GET_CONTROLLER_STATUS_FAILURE';

export const COMPLETE_STEP_ERROR_MESSAGE = 'Oops. An error occurred while loading the next step. Please try again.';
export const LOAD_DATA_ERROR_MESSAGE = 'Oops. An error occurred while loading the information. Please try again.';

export enum ONBOARDING_STEPS {
  AUTHORIZING_OFFICIAL_ASSOCIATE_HS = 1,
  AUTHORIZING_OFFICIAL_INVITE_ACCOUNT_ADMIN = 2,
  ACCOUNT_ADMIN_INFO = 3,
  ACCOUNT_ADMIN_CONFIRM_INFO = 4,
  ACCOUNT_ADMIN_INVITE_CONTROLLER = 5,
  CONTROLLER_INFO = 6,
  CONTROLLER_CONFIRM_INFO = 7,
  CONTROLLER_BANK_INFO = 8,
  ONBOARDING_COMPLETE = 10,
}

export const allSteps = new Map<string, string[]>([
  [
    ROLES.RECIPIENT_AUTHORIZING_OFFICIAL, // Covered Entity Authorizing Official
    [
      'Associate Covered Entities & Health Systems',
      'e-Sign Terms and Conditions',
      'Invite Account Admin'
    ]
  ],
  [
    ROLES.RECIPIENT_ACCOUNT_ADMIN, // Covered Entity Account Admin
    [
      'Account Admin information',
      'Confirm your information',
      'Add bank account'
    ]
  ],
  [
    ROLES.RECIPIENT_CONTROLLER, // Covered Entity Financial Setup
    [
      'Your contact information',
      'Confirm your information',
      'Add bank info'
    ]
  ]
]);

interface ResetOnboardingStateAction {
  type: typeof RESET_ONBOARDING_STATE
}

interface ResetOnboardingStatusAction {
  type: typeof RESET_ONBOARDING_STATUS
}

interface CheckOnboardingStatusAction {
  type: typeof CHECK_ONBOARDING_STATUS,
  payload: number | undefined
}

interface CheckOnboardingStatusSuccessAction {
  type: typeof CHECK_ONBOARDING_STATUS_SUCCESS
  payload: OnboardingStatusResponseModel
}

interface CheckOnboardingStatusFailedAction {
  type: typeof CHECK_ONBOARDING_STATUS_FAILED
  payload: ErrorModel
}

interface UpdateOnboardingStepAction {
  type: typeof UPDATE_ONBOARDING_STEP
  payload: number
}

interface UpdateOnboardingDismissedWelcomeAction {
  type: typeof UPDATE_ONBOARDING_DISMISSED_WELCOME
  payload: boolean
}

interface GetTermsAndConditionsAction {
  type: typeof GET_TERMS_AND_CONDITIONS
}

interface GetTermsAndConditionsSuccessAction {
  type: typeof GET_TERMS_AND_CONDITIONS_SUCCESS
  payload: string
}

interface GetTermsAndConditionsFailureAction {
  type: typeof GET_TERMS_AND_CONDITIONS_FAILURE,
  payload: ErrorModel
}

interface CompleteTermsAndConditionsStepAction {
  type: typeof COMPLETE_TERMS_AND_CONDITIONS_STEP,
}

interface CompleteTermsAndConditionsStepSuccessAction {
  type: typeof COMPLETE_TERMS_AND_CONDITIONS_STEP_SUCCESS,
  payload: TermsAndConditionsConfirmation
}

interface CompleteInviteAccountAdminStepAction {
  type: typeof COMPLETE_INVITE_ACCOUNT_ADMIN_STEP
  payload: InviteUserStepInputModel
}

interface CompleteInviteAccountAdminStepSuccessAction {
  type: typeof COMPLETE_INVITE_ACCOUNT_ADMIN_STEP_SUCCESS,
}

interface CompleteAccountAdminInfoStepAction {
  type: typeof COMPLETE_ACCOUNT_ADMIN_INFO_STEP,
  payload: UserInfoInputModel
}

interface CompleteAccountAdminInfoStepSuccessAction {
  type: typeof COMPLETE_ACCOUNT_ADMIN_INFO_STEP_SUCCESS,
}

interface GetCoveredEntityInfoAction {
  type: typeof GET_COVERED_ENTITY_INFO
}

interface GetCoveredEntityInfoSuccessAction {
  type: typeof GET_COVERED_ENTITY_INFO_SUCCESS,
  payload: any
}

interface GetCoveredEntityInfoFailureAction {
  type: typeof GET_COVERED_ENTITY_INFO_FAILURE,
  payload: ErrorModel
}

interface GetAccountAdminSummaryAction {
  type: typeof GET_ACCOUNT_ADMIN_SUMMARY
}

interface GetAccountAdminSummarySuccessAction {
  type: typeof GET_ACCOUNT_ADMIN_SUMMARY_SUCCESS,
  payload: AccountAdminConfirmInfoStepModel
}

interface GetAccountAdminSummaryFailureAction {
  type: typeof GET_ACCOUNT_ADMIN_SUMMARY_FAILURE,
  payload: ErrorModel
}

interface CompleteAccountAdminConfirmInfoStepAction {
  type: typeof COMPLETE_ACCOUNT_ADMIN_CONFIRM_INFO_STEP
}

interface CompleteAccountAdminConfirmInfoStepSuccessAction {
  type: typeof COMPLETE_ACCOUNT_ADMIN_CONFIRM_INFO_STEP_SUCCESS,
}

interface CompleteInviteControllerStepAction {
  type: typeof COMPLETE_INVITE_CONTROLLER_STEP,
  payload: InviteUserStepInputModel
}

interface CompleteInviteControllerStepSuccessAction {
  type: typeof COMPLETE_INVITE_CONTROLLER_STEP_SUCCESS,
}

interface CompleteControllerInfoStepAction {
  type: typeof COMPLETE_CONTROLLER_INFO_STEP,
  payload: UserInfoInputModel
}

interface CompleteControllerInfoStepSuccessAction {
  type: typeof COMPLETE_CONTROLLER_INFO_STEP_SUCCESS,
}

interface CompleteControllerConfirmInfoStepAction {
  type: typeof COMPLETE_CONTROLLER_CONFIRM_INFO_STEP
}

interface CompleteControllerConfirmInfoStepSuccessAction {
  type: typeof COMPLETE_CONTROLLER_CONFIRM_INFO_STEP_SUCCESS,
}

interface CompleteControllerBankInfoStepAction {
  type: typeof COMPLETE_CONTROLLER_BANK_INFO_STEP
}

interface CompleteControllerBankInfoStepSuccessAction {
  type: typeof COMPLETE_CONTROLLER_BANK_INFO_STEP_SUCCESS,
}

interface CompleteRecipientStepFailureAction {
  type: typeof COMPLETE_RECIPIENT_STEP_FAILURE,
  payload: ErrorModel
}

interface SendAuthorizingOfficialSignUpAction {
  type: typeof SEND_AUTHORIZING_OFFICIAL_SIGN_UP,
  payload: string
}

interface SendAuthorizingOfficialSignUpSuccessAction {
  type: typeof SEND_AUTHORIZING_OFFICIAL_SIGN_UP_SUCCESS,
}

interface SendAuthorizingOfficialSignUpErrorAction {
  type: typeof SEND_AUTHORIZING_OFFICIAL_SIGN_UP_ERROR,
  payload: ErrorModel
}

interface ResetAuthorizingOfficialSignUpAction {
  type: typeof RESET_AUTHORIZING_OFFICIAL_SIGN_UP
}

interface RefreshDataAfterOnboardingAction {
  type: typeof REFRESH_DATA_AFTER_ONBOARDING,
}

interface GetCandidateCeListAction {
  type: typeof GET_CANDIDATE_CE_LIST
}

interface GetCandidateCeListSuccessAction {
  type: typeof GET_CANDIDATE_CE_LIST_SUCCESS,
  payload: any
}

interface GetCandidateCeListFailureAction {
  type: typeof GET_CANDIDATE_CE_LIST_FAILURE,
  payload: ErrorModel
}

interface SetSelectedHealthSystemAction {
  type: typeof SET_SELECTED_HEALTH_SYSTEM
  payload: CandidateHealthSystem
}

interface SetSelectedOrphanCesAction {
  type: typeof SET_SELECTED_ORPHAN_CES
  payload: CandidateCoveredEntity[]
}

interface SetHealthSystemName {
  type: typeof SET_HEALTH_SYSTEM_NAME
  payload: string
}

interface CheckUniqueHealthSystemNameAction {
  type: typeof CHECK_UNIQUE_HEALTH_SYSTEM_NAME
  payload: string
}

interface CheckUniqueHealthSystemNameSuccessAction {
  type: typeof CHECK_UNIQUE_HEALTH_SYSTEM_NAME_SUCCESS,
  payload: any
}

interface CheckUniqueHealthSystemNameFailureAction {
  type: typeof CHECK_UNIQUE_HEALTH_SYSTEM_NAME_FAILURE,
  payload: ErrorModel
}

interface GetHealthSystemAccountAdminAction {
  type: typeof GET_HEALTH_SYSTEM_ACCOUNT_ADMIN
}

interface GetHealthSystemAccountAdminSuccessAction {
  type: typeof GET_HEALTH_SYSTEM_ACCOUNT_ADMIN_SUCCESS,
  payload: {
    hasAccountAdmin: boolean,
    accountAdminInfo: UserInfoModel
  }
}

interface GetHealthSystemAccountAdminFailureAction {
  type: typeof GET_HEALTH_SYSTEM_ACCOUNT_ADMIN_FAILURE,
  payload: ErrorModel
}

interface GetControllerStatusAction {
  type: typeof GET_CONTROLLER_STATUS
}

interface GetControllerStatusSuccessAction {
  type: typeof GET_CONTROLLER_STATUS_SUCCESS,
  payload: ControllerStatusModel
}

interface GetControllerStatusFailureAction {
  type: typeof GET_CONTROLLER_STATUS_FAILURE,
  payload: ErrorModel
}

interface CompleteOnboardingAction {
  type: typeof COMPLETE_ONBOARDING,
}

export type RecipientOnboardingActionTypes =
  ResetOnboardingStateAction |
  ResetOnboardingStatusAction |
  CheckOnboardingStatusAction |
  CheckOnboardingStatusSuccessAction |
  CheckOnboardingStatusFailedAction |
  UpdateOnboardingStepAction |
  UpdateOnboardingDismissedWelcomeAction |
  GetTermsAndConditionsAction |
  GetTermsAndConditionsSuccessAction |
  GetTermsAndConditionsFailureAction |
  CompleteTermsAndConditionsStepAction |
  CompleteTermsAndConditionsStepSuccessAction |
  CompleteInviteAccountAdminStepAction |
  CompleteInviteAccountAdminStepSuccessAction |
  CompleteAccountAdminInfoStepAction |
  CompleteAccountAdminInfoStepSuccessAction |
  GetCoveredEntityInfoAction |
  GetCoveredEntityInfoSuccessAction |
  GetCoveredEntityInfoFailureAction |
  GetAccountAdminSummaryAction |
  GetAccountAdminSummarySuccessAction |
  GetAccountAdminSummaryFailureAction |
  CompleteAccountAdminConfirmInfoStepAction |
  CompleteAccountAdminConfirmInfoStepSuccessAction |
  CompleteInviteControllerStepAction |
  CompleteInviteControllerStepSuccessAction |
  CompleteControllerInfoStepAction |
  CompleteControllerInfoStepSuccessAction |
  CompleteControllerConfirmInfoStepAction |
  CompleteControllerConfirmInfoStepSuccessAction |
  CompleteControllerBankInfoStepAction |
  CompleteControllerBankInfoStepSuccessAction |
  CompleteRecipientStepFailureAction |
  SendAuthorizingOfficialSignUpAction |
  SendAuthorizingOfficialSignUpSuccessAction |
  SendAuthorizingOfficialSignUpErrorAction |
  ResetAuthorizingOfficialSignUpAction |
  RefreshDataAfterOnboardingAction |
  GetCandidateCeListAction |
  GetCandidateCeListSuccessAction |
  GetCandidateCeListFailureAction |
  SetSelectedHealthSystemAction |
  SetSelectedOrphanCesAction |
  SetHealthSystemName |
  CheckUniqueHealthSystemNameAction |
  CheckUniqueHealthSystemNameSuccessAction |
  CheckUniqueHealthSystemNameFailureAction |
  GetHealthSystemAccountAdminAction |
  GetHealthSystemAccountAdminSuccessAction |
  GetHealthSystemAccountAdminFailureAction |
  GetControllerStatusAction |
  GetControllerStatusSuccessAction |
  GetControllerStatusFailureAction |
  CompleteOnboardingAction;
