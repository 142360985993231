import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../common/navigation/PageHeader';
import { Tab, Menu, TabProps } from 'semantic-ui-react';
import ReversalsTab from './ReversalsTab';
import { connect } from 'react-redux';
import { resetReversals } from '../../store/reversals/actionCreators';
import { RootState } from '../../store/RootState';
import { REVERSALS_TAB } from '../../store/reversals/constants';

export interface ReversalsProps extends RouteComponentProps {
  activeTab: REVERSALS_TAB;
  isLoading: boolean;
  resetReversals: () => void;
  activeHealthSystemId: number;
}

const ReversalsCenterTabMap: { [key: string]: number } = {
  'pending': REVERSALS_TAB.PENDING,
  'processed': REVERSALS_TAB.PROCESSED
}

export function getReversalsCenterTabIndex (path: string): number {
  return ReversalsCenterTabMap[path.substr(path.lastIndexOf('/') + 1)];
}

export class Reversals extends React.Component<ReversalsProps>{
  componentDidUpdate (prevProps: ReversalsProps){
    if (prevProps.activeHealthSystemId !== this.props.activeHealthSystemId){
      this.props.resetReversals();
    }
  }

  componentWillUnmount () {
    this.props.resetReversals();
  }

  handleTabChange = (event: any, data: TabProps) => {
    const path = Object.keys(ReversalsCenterTabMap).find(k => ReversalsCenterTabMap[k] === data.activeIndex);
    this.props.history.push(`/recipients/reversals/${path}`);
  }

  render () {
    const { activeTab, isLoading } = this.props;

    const panes = [
      {
        menuItem: (
          <Menu.Item key='pending' className='reversalsPendingTab_Pendo' disabled={isLoading}>
            {'Pending'}
          </Menu.Item>
        ), render: () => <Tab.Pane attached={false}><ReversalsTab tab={REVERSALS_TAB.PENDING} /></Tab.Pane>
      },
      {
        menuItem: (
          <Menu.Item key='processed' className='reversalProcessedTab_Pendo' disabled={isLoading}>
            {'Processed'}
          </Menu.Item>
        ), render: () => <Tab.Pane attached={false}><ReversalsTab tab={REVERSALS_TAB.PROCESSED} /></Tab.Pane> // canSortStatus={true}
      }
    ];

    return (
      <div className='recipient-request-center'>
        <div className="recipient-request-center-circles-container">
          <div className="recipient-request-center-header-div">
            <PageHeader noBorder text='Reversals' />
          </div>
          <div className="recipient-request-center-info-circle3"></div>
          <div className="recipient-request-center-info-circle4"></div>
          <Tab renderActiveOnly={true} onTabChange={this.handleTabChange} className="tab-controller recipient-request-center-tabs"
            activeIndex={activeTab} menu={{ secondary: true, pointing: true }} panes={panes} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.ReversalsState.reversals.isLoading,
  activeHealthSystemId: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.healthSystemId
});

const mapDispatchToProps = (dispatch: any) => ({
  resetReversals: () => dispatch(resetReversals()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reversals);
