import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Dimmer, Loader, Button, Dropdown } from 'semantic-ui-react';
import { RootState } from '../../../store/RootState';
import { assignUserCEs, closeAssignCEModal } from '../../../store/teammanagement/actionCreators';
import { CoveredEntity } from '../../../store/teammanagement/types';
import { ErrorBanner } from '../../common/errors/ErrorBanner';

import '../../../themes/kalderos/TeamManagement/AssignCEModal.scss';

export interface AssignCEModalProps {
  coveredEntities: CoveredEntity[],
  userId: number,
  ceIds: number[],
  isOpen: boolean,
  isLoading: boolean,
  errorText: string,
  assignUserCEs: (userId: number, ceIds: number[]) => void,
  closeAssignCEModal: (isCancel: boolean) => void;
}

export const AssignCEModal: React.FunctionComponent<AssignCEModalProps> = (props: AssignCEModalProps) => {

  const [selectedCeIds, setSelectedCeIds] = useState<number[]>(props.ceIds);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  React.useEffect(() => {
    setSelectedCeIds(props.ceIds)
  }, [props.ceIds]);

  React.useEffect(() => {
    setIsDirty(false);
  }, [props.isOpen]);

  function getCeOptions () {
    return props.coveredEntities.map(ce => {
      return (
        {
          key: ce.id340B,
          text: ce.id340B + ' ' + ce.entityName,
          content: (
            <div className="assign-ce-option">
              <div className="ce-option-id">{ce.id340B}</div>
              <div className="ce-option-name">{ce.entityName}</div>
            </div>
          ),
          value: ce.coveredEntityId
        }
      )
    });
  }

  function handleCancelClick () {
    props.closeAssignCEModal(true);
  }

  function handleSubmitClick () {
    props.assignUserCEs(props.userId, selectedCeIds);
  }

  function onSelectionChange (values: any) {
    setSelectedCeIds(values);
    setIsDirty(true);
  }

  function clearAll () {
    setSelectedCeIds([]);
    setIsDirty(true);
  }

  function selectAll () {
    setSelectedCeIds(props.coveredEntities.map(ce => ce.coveredEntityId))
    setIsDirty(true);
  }

  return (
    <Modal className="team-management-modal assign-ce-modal" open={props.isOpen}>
      <div className="team-management-modal-header">Assign CEs</div>
      {props.isLoading && <Dimmer className="dimmer" active inverted><Loader inverted /></Dimmer>}
      {props.errorText &&
        <ErrorBanner
          errorText={'An error occurred while assigning CEs'}
        />}
      <div className="team-management-modal-body">
        <div className="assign-ce-actions">
          <Button type="button" onClick={clearAll} disabled={selectedCeIds.length === 0} className="assignCEClear_Pendo">Clear</Button>
          <Button type="button" onClick={selectAll} className="assignCEAssignAll_Pendo">Assign All</Button>
        </div>
        <Dropdown fluid search multiple selection
          className="assign-ce-dropdown"
          placeholder="Search 340B ID" value={selectedCeIds}
          onChange={(e, data) => onSelectionChange(data.value)}
          renderLabel={i => i.key}
          options={getCeOptions()}
        />
      </div>
      <div className="team-management-modal-footer">
        <Button type="button" className="app-button button-white" onClick={handleCancelClick}>Cancel</Button>
        <Button type="submit" className="app-button button-dark assignCESubmit_Pendo" onClick={handleSubmitClick} disabled={!isDirty}>Submit</Button>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state: RootState) => ({
  coveredEntities: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.coveredEntities,
  userId: state.TeamManagementState.assignCEModal.userId,
  ceIds: state.TeamManagementState.assignCEModal.ceIds,
  isOpen: state.TeamManagementState.assignCEModal.isOpen,
  isLoading: state.TeamManagementState.assignCEModal.isLoading,
  errorText: state.TeamManagementState.assignCEModal.errorText,
});

const mapDispatchToProps = (dispatch: any) => ({
  assignUserCEs: (userId: number, ceIds: number[]) => dispatch(assignUserCEs(userId, ceIds)),
  closeAssignCEModal: (isCancel: boolean) => dispatch(closeAssignCEModal(isCancel)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignCEModal)
