import {
  PaymentsRecipientActionTypes,
  GET_RECIPIENT_PAYMENT_HISTORY,
  UPDATE_RECIPIENT_PAYMENT_HISTORY,
  GET_RECIPIENT_PAYMENT_HISTORY_ERROR,
  UPDATE_RECIPIENT_PAYMENTS_HISTORY_SORT_FILTERS,
  UPDATE_RECIPIENT_PAYMENT_HISTORY_PAGE,
  RESET_RECIPIENT_PAYMENT_STATE,
  RETRY_PAYMENTS,
  RETRY_PAYMENTS_SUCCESS,
  RETRY_PAYMENTS_FAILURE,
  GET_CREDITS_PER_MANUFACTURER,
  GET_CREDITS_PER_MANUFACTURER_SUCCESS,
  GET_CREDITS_PER_MANUFACTURER_FAILURE,
  UPDATE_CREDITS_PER_MANUFACTURER_SORT_FILTERS,
  UPDATE_CREDITS_PER_MANUFACTURER_PAGE,
  GET_ASSOCIATED_COVERED_ENTITIES,
  GET_ASSOCIATED_COVERED_ENTITIES_SUCCESS,
  GET_ASSOCIATED_COVERED_ENTITIES_FAILURE,
  UPDATE_ASSOCIATED_COVERED_ENTITIES_SORT,
  GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO,
  GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO_SUCCESS,
  GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO_FAILURE
} from './constants';
import { UserInfoModel, ErrorModel } from '../../applicationTypes';
import { PaymentHistoryModel, CoveredEntityModel, CreditsPerManufacturerModel } from './types';
import { SortingFilter } from '../../appcommon/types';

export function getRecipientPaymentsHistory (): PaymentsRecipientActionTypes {
  return {
    type: GET_RECIPIENT_PAYMENT_HISTORY
  }
}

export function updateRecipientPaymentsHistory (summaryModel: PaymentHistoryModel[], totalCount: number, page: number): PaymentsRecipientActionTypes {
  return {
    type: UPDATE_RECIPIENT_PAYMENT_HISTORY,
    payload: {
      paymentTransactions: summaryModel,
      totalCount: totalCount,
      page: page
    }
  }
}

export function getRecipientPaymentsHistoryError (errorModel: ErrorModel): PaymentsRecipientActionTypes {
  return {
    type: GET_RECIPIENT_PAYMENT_HISTORY_ERROR,
    payload: errorModel
  }
}

export function updateRecipientPaymentsHistorySortFilters (sortFilters: SortingFilter): PaymentsRecipientActionTypes {
  return {
    type: UPDATE_RECIPIENT_PAYMENTS_HISTORY_SORT_FILTERS,
    payload: sortFilters
  }
}

export function updateRecipientPaymentsHistoryPage (page: number): PaymentsRecipientActionTypes {
  return {
    type: UPDATE_RECIPIENT_PAYMENT_HISTORY_PAGE,
    payload: page
  }
}

export function resetRecipientPaymentsState (): PaymentsRecipientActionTypes {
  return {
    type: RESET_RECIPIENT_PAYMENT_STATE
  }
}

export function retryPayments (): PaymentsRecipientActionTypes {
  return {
    type: RETRY_PAYMENTS
  }
}

export function retryPaymentsSuccess (response: any): PaymentsRecipientActionTypes {
  return {
    type: RETRY_PAYMENTS_SUCCESS,
    payload: response
  }
}

export function retryPaymentsFailure (errorModel: ErrorModel): PaymentsRecipientActionTypes {
  return {
    type: RETRY_PAYMENTS_FAILURE,
    payload: errorModel
  }
}

export function getCreditsPerManufacturer (): PaymentsRecipientActionTypes {
  return {
    type: GET_CREDITS_PER_MANUFACTURER,
  }
}

export function creditsPerManufacturerSuccess (creditsPerManufacturer: CreditsPerManufacturerModel[], page: number, totalCount: number): PaymentsRecipientActionTypes {
  return {
    type: GET_CREDITS_PER_MANUFACTURER_SUCCESS,
    payload: {
      creditsPerManufacturer: creditsPerManufacturer,
      currentApiPage: page,
      totalCount: totalCount
    }
  }
}

export function creditsPerManufacturerFailure (): PaymentsRecipientActionTypes {
  return {
    type: GET_CREDITS_PER_MANUFACTURER_FAILURE
  }
}

export function updateCreditsPerManufacturerSortFilters (sortFilters: SortingFilter): PaymentsRecipientActionTypes {
  return {
    type: UPDATE_CREDITS_PER_MANUFACTURER_SORT_FILTERS,
    payload: sortFilters
  }
}

export function updateCreditsPerManufacturerPage (pageNumber: number): PaymentsRecipientActionTypes {
  return {
    type: UPDATE_CREDITS_PER_MANUFACTURER_PAGE,
    payload: pageNumber
  }
}


export function getAssociatedCoveredEntities (): PaymentsRecipientActionTypes {
  return {
    type: GET_ASSOCIATED_COVERED_ENTITIES
  }
}

export function getAssociatedCoveredEntitiesSuccess (coveredEntities: CoveredEntityModel[]): PaymentsRecipientActionTypes {
  return {
    type: GET_ASSOCIATED_COVERED_ENTITIES_SUCCESS,
    payload: {
      coveredEntities: coveredEntities,
    }
  }
}

export function getAssociatedCoveredEntitiesFailure (errorModel: ErrorModel): PaymentsRecipientActionTypes {
  return {
    type: GET_ASSOCIATED_COVERED_ENTITIES_FAILURE,
    payload: errorModel
  }
}


export function updateAssociatedCoveredEntitiesSort (sort: SortingFilter): PaymentsRecipientActionTypes {
  return {
    type: UPDATE_ASSOCIATED_COVERED_ENTITIES_SORT,
    payload: sort
  }
}

export function getCoveredEntityHealthSystemInfo (): PaymentsRecipientActionTypes {
  return {
    type: GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO
  }
}

export function getCoveredEntityHealthSystemInfoSuccess (accountAdmin: UserInfoModel, controller: UserInfoModel): PaymentsRecipientActionTypes {
  return {
    type: GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO_SUCCESS,
    payload: {
      accountAdmin: accountAdmin,
      controller: controller
    }
  }
}

export function getCoveredEntityHealthSystemInfoFailure (errorModel: ErrorModel): PaymentsRecipientActionTypes {
  return {
    type: GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO_FAILURE,
    payload: errorModel
  }
}