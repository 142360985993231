import RequestConfig from '../../app.config';
import fetchWrapper from '../FetchWrapper';
import { createUrlMultiParameterString, createUrlParametersString } from '../../utils/requestUtil';
import { commonResponseHandler } from '../../utils/responseUtils';

export const fetchMultiContractElements = async (ids: number[], healthSystemId: number, isMedical: boolean) => {
  const idParams = createUrlMultiParameterString('contractIds', ids);
  const otherParams = createUrlParametersString({ healthSystemId: healthSystemId, isMedical: isMedical });
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/contracts/elements/multi?${idParams}&${otherParams}`, true);
  return commonResponseHandler(response);
}