import React from 'react';
import DwollaIcon from '../../themes/kalderos/static/media/DwollaIcon.svg'
import { PaymentCheckMarkIcon } from '../../themes/kalderos/Common/Icons';

import '../../themes/kalderos/Onboarding/Payment.scss';

interface AddBankPaymentProps {
  partOneComplete: boolean;
}
export class AddBankPayment extends React.Component<AddBankPaymentProps>{

  render () {
    const { partOneComplete } = this.props;
    return (

      <div className="onboarding-layout-subbody">
        <img src={DwollaIcon} alt='dwolla-icon' />
        {!partOneComplete ?
          <div className="payment-body-text">
            <div className="add-bank-payment-account-text">Kalderos uses Dwolla to link your financial account </div>
            <div className="flex-row bank-account-column">
              <div className="flex-column bank-account-icon">
                <PaymentCheckMarkIcon />
              </div>
              <div className="flex-column">
                <div className="add-bank-payment-text1">Secure</div>
                <div className="add-bank-payment-text2">Transfer of your information is encrypted end to end. </div>
              </div>
            </div>
            <div className="flex-row bank-account-column">
              <div className="flex-column bank-account-icon">
                <PaymentCheckMarkIcon />
              </div>
              <div className="flex-column ">
                <div className="add-bank-payment-text1">Private</div>
                <div className="add-bank-payment-text2">Your credentials are never seen or stored by Kalderos. </div>
              </div>
            </div>
          </div>
          : <div className="payment-body-text">
            <div className="add-bank-payment-account-text">Your account will be verified using micro deposits</div>
            <div className="flex-row bank-account-column">
              <div className="flex-column bank-account-icon">
                <PaymentCheckMarkIcon />
              </div>

              <div className="flex-column">
                <div className="add-bank-payment-text1">Provide Bank Info</div>
                <div className="add-bank-payment-text2">Requires your account and routing number. </div>
              </div>
            </div>
            <div className="flex-row bank-account-column">
              <div className="flex-column bank-account-icon">
                <PaymentCheckMarkIcon />
              </div>

              <div className="flex-column">
                <div className="add-bank-payment-text1">Receive micro deposits</div>
                <div className="add-bank-payment-text2">Takes 1-3 business days for two small deposits to <br /> appear in your account. </div>
              </div>
            </div>
            <div className="flex-row bank-account-column">
              <div className="flex-column bank-account-icon">
                <PaymentCheckMarkIcon />
              </div>
              <div className="flex-column ">
                <div className="add-bank-payment-text1">Verify Account</div>
                <div className="add-bank-payment-text2">You will be ready to transfer money! </div>
              </div>
            </div>
          </div>}
      </div>
    )
  }
}


export default AddBankPayment;
