import React from 'react';
import { SUPPORT_PHONE, SUPPORT_EMAIL_LINK, SUPPORT_EMAIL } from '../../../../store/applicationConstants';
import { Mail } from 'react-feather';

const AuthorizingOfficialSignUpError = (props: { email: string, onReset: () => void, message: string, onClose: () => void }) => {
  return (
    <div className="login-page ao-sign-up-error">
      <div className="login-page-layout">
        <div className="login-page-column-right">
          <div className="login-form-container">
            <div className="login-form-header">Sign Up Error</div>
            <div className="login-form-body">
              <div className="message-box error-box">
                <Mail size={20} />
                <div className="message-box-text">{props.message}</div>
              </div>
              <div>Still don’t see an email?  <button className="link-button" type="button" onClick={props.onReset}>Request another link.</button></div>
            </div>
            <div className="login-form-footer">
              <button type="submit" className="app-button cta" onClick={props.onClose}>Return to Sign-In</button>
            </div>
          </div>
          <div className="login-page-column-right-footer">
            If you have any questions, please contact Kalderos Support at <a href={SUPPORT_EMAIL_LINK}>{SUPPORT_EMAIL}</a> or {SUPPORT_PHONE} for assistance.
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthorizingOfficialSignUpError
