import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { RootState } from '../../../../store/RootState';
import { formatDollar } from '../../../../utils/numberFormatHelper';

export interface ManufacturerReviewFooterProps {
  totalAccepted: number;
  totalPendingManufacturerReview: number
}

export const ManufacturerReviewFooter = (props: ManufacturerReviewFooterProps) => {
  const { totalAccepted, totalPendingManufacturerReview } = props;

  return (
    <div className="mfr-review-footer">
      <div className="mfr-review-footer-left-container">
        <div className="header">Total Responses</div>
        <div className="total-container">
          <div className="total-value">{formatDollar(totalAccepted)}</div>
          <div className="total-label">TOTAL ACCEPTED</div>
        </div>
        <div className="total-container">
          <div className="total-value">{formatDollar(totalPendingManufacturerReview)}</div>
          <div className="total-label">TOTAL PENDING MANUF. RESPONSE</div>
        </div>
      </div>
      <div className="mfr-review-footer-right-container">
        <button className="app-button button-dark" disabled type="button">
          Submit Reviewed Disputes
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  totalAccepted: state.MdrpState.disputesCenter.disputesReview.manufacturerDisputes.responseTotals.totalAccepted,
  totalPendingManufacturerReview: state.MdrpState.disputesCenter.disputesReview.manufacturerDisputes.responseTotals.totalPendingManufacturerReview,
})

export default withRouter(connect(mapStateToProps)(ManufacturerReviewFooter));
