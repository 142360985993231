import React from 'react';
import { Modal, Button, Form, Dropdown } from 'semantic-ui-react';
import { TeamMember, HealthSystemInfo } from '../../../store/teammanagement/types';
import { connect } from 'react-redux';
import { RootState } from '../../../store/RootState';
import _ from 'lodash';
import { updateUser } from '../../../store/teammanagement/actionCreators';
import { UPDATE_INFO, USER_INFO, STATES } from '../../../store/teammanagement/constants';
import Cleave from 'cleave.js/react';
import { RECIPIENT_TEAM_MANAGEMENT_USER_ROLES } from '../../../store/teammanagement/constants';
import { Formik } from 'formik';
export interface EditUserModalProps {
  userInfo: TeamMember;
  team: HealthSystemInfo;
  open: boolean;
  userId: number;
  activeUser: string | null;
  updateUser: (data: any) => void;
  openDisable: () => void;
  close: () => void;
}

interface EditUserModalState {
  closeOnEscape: boolean;
  closeOnDimmerClick: boolean;
  updateInfo: any;
  userInfo: TeamMember;
  errors: errorModel;
}

interface errorModel {
  phone: string,
  address: {
    address1: string,
    address2: string,
    zip: string
  }
}

export class EditUserModal extends React.Component<EditUserModalProps, EditUserModalState> {
  private appContent: React.RefObject<HTMLDivElement>;
  constructor (props: any) {
    super(props);
    this.appContent = React.createRef();
    this.state = {
      closeOnEscape: true,
      closeOnDimmerClick: true,
      userInfo: USER_INFO,
      updateInfo: UPDATE_INFO,
      errors: { phone: '', address: { address1: '', address2: '', zip: '' } } as errorModel,
    }
  }

  componentDidMount (): void {
    const teamId = this.props.team.healthSystemId;
    this.setState({ userInfo: this.props.userInfo, updateInfo: { ...this.state.updateInfo, teamID: teamId } })
  }

  close = () => {
    const teamId = this.props.team.healthSystemId;
    this.setState({ userInfo: this.props.userInfo, updateInfo: { ...UPDATE_INFO, teamID: teamId } })
  };

  updateUserInfo = (e: any, key: any) => {
    let cleaned;
    if (key === ('phone' || 'phoneExtension')) {
      cleaned = ('' + e.target.value).replace(/\D/g, '')
    } else {
      cleaned = e.target.value.trim();
    }
    this.setState({
      userInfo: { ...this.state.userInfo, [key]: cleaned },
      updateInfo: { ...this.state.updateInfo, [key]: cleaned, userID: this.state.userInfo.id },
    })
  };

  updateUserAddress = (e: any, key: any) => {
    const newValue = e.target.value.trim();
    this.setState({
      userInfo: {
        ...this.state.userInfo,
        address: { ...this.state.userInfo.address, [key]: newValue }
      },
      updateInfo: { ...this.state.updateInfo, [key]: newValue, userID: this.state.userInfo.id }
    })
  };


  handleStateChange = (data: any) => {
    let stateId: number = 0;
    STATES.forEach(s => {
      if (s.value === data) {
        stateId = s.key;
      }
    });
    this.setState({
      userInfo: {
        ...this.state.userInfo,
        address: {
          ...this.state.userInfo.address,
          state: data
        }
      },
      updateInfo: { ...this.state.updateInfo, stateId: stateId, userID: this.state.userInfo.id }
    })
  };

  submitUpdatedInfo = () => {
    this.props.updateUser(this.state.updateInfo);
    this.props.close();
    this.close();
  };

  handleScroll = () => {
    setTimeout(() => {
      if (this.appContent.current) {
        this.appContent.current.scrollTo({ top: 800, behavior: 'smooth' });
      }
    })
  }


  render () {
    const { closeOnEscape, userInfo } = this.state;
    const { open, activeUser } = this.props;
    const user = this.props.userInfo;
    const isSaveButtonDisabled = (_.isEqual(user, this.state.userInfo) || this.state.errors.address.zip !== '' || this.state.errors.phone !== '' || this.state.errors.address.address1 !== ''|| this.state.errors.address.address2 !== '');

    return (
      <Modal className="edit-user-modal app-modal medium-modal"
        open={open}
        closeOnEscape={closeOnEscape}
        closeOnDimmerClick={false}
        onClose={this.close}>
        <div className="app-modal-header">Edit User Profile</div>
        <div className="app-modal-content" ref={this.appContent}>
          <div className="app-form">
            <Formik
              initialValues={{ ...user }}
              validate={values => {
                const errors = { phone: '', address: { address1: '', address2: '', zip: '' } } as errorModel;
                if (values.phone) {
                  if (values.phone.replace(/[^\d+]+/g, '').length < 10) {
                    errors.phone = 'Phone number must be 10 digits long.'
                  }
                }
                if (values.address.zip) {
                  if (values.address.zip.length < 5) {
                    errors.address.zip = 'Zip code must be 5 digits long.'
                  }
                }
                if (values.address.address1) {
                  if (values.address.address1.length > 100) {
                    errors.address.address1 = 'Office address cannot exceed 100 characters.'
                  }
                }
                if (values.address.address2) {
                  if (values.address.address2.length > 100) {
                    errors.address.address2 = 'Office address cannot exceed 100 characters.'
                  }
                }
                this.setState({ errors: errors });
                return errors;
              }}
              onSubmit={() => { }}>
              {({ handleChange, values, errors }) => (
                <Form>
                  <Form.Field>
                    <label>First Name</label>
                    <input
                      name="first_name"
                      onChange={(e: any) => this.updateUserInfo(e, 'firstName')}
                      defaultValue={values.firstName} />
                  </Form.Field>
                  <Form.Field>
                    <label>Last Name</label>
                    <input onChange={(e: any) => this.updateUserInfo(e, 'lastName')}
                      defaultValue={user.lastName} />
                  </Form.Field>
                  <Form.Field>
                    <label>Job Title</label>
                    <input onChange={(e: any) => this.updateUserInfo(e, 'jobTitle')}
                      defaultValue={user.jobTitle} />
                  </Form.Field>
                  <Form.Field>
                    <label>Email Address</label>
                    <input disabled onChange={(e: any) => this.updateUserInfo(e, 'email')}
                      defaultValue={user.email} />
                  </Form.Field>
                  <div className="flex-row">
                    <Form.Field className="flex-grow">
                      <label>Primary Phone</label>
                      <Cleave
                        name="phone"
                        value={user.phone}
                        onChange={(e: any) => { this.updateUserInfo(e, 'phone'); handleChange(e) }}
                        options={{ delimiters: ['(', ')', ' ', '-'], blocks: [0, 3, 0, 3, 4], numericOnly: true }}
                      />
                      {errors.phone && <div className="app-form-error">{errors.phone}</div>}
                    </Form.Field>
                    <Form.Field className="extension-field">
                      <label>Ext.</label>
                      <Cleave
                        name="phoneExtension"
                        maxLength={4}
                        value={user.phoneExtension}
                        onChange={(event: any) => { this.updateUserInfo(event, 'phoneExtension') }}
                        options={{ numericOnly: true }}
                      />
                    </Form.Field>
                  </div>
                  <Form.Field>
                    <label>Office Address (Line 1)</label>
                    <input name="address.address1" onChange={(e: any) => {this.updateUserAddress(e, 'address1'); handleChange(e)}}
                      defaultValue={user.address?.address1} />
                    {errors.address?.address1 && <div className="app-form-error">{errors.address?.address1}</div>}
                  </Form.Field>
                  <Form.Field>
                    <label>Office Address (Line 2)</label>
                    <input name="address.address2" onChange={(e: any) => {this.updateUserAddress(e, 'address2'); handleChange(e)}}
                      defaultValue={user.address?.address2} />
                    {errors.address?.address2 && <div className="app-form-error">{errors.address?.address2}</div>}
                  </Form.Field>
                  <Form.Field>
                    <label>City</label>
                    <input onChange={(e: any) => this.updateUserAddress(e, 'city')}
                      defaultValue={user.address?.city} />
                  </Form.Field>
                  <Form.Field>
                    <label>State</label>
                    <Dropdown selection
                      onClick={() => this.handleScroll()}
                      search
                      options={STATES}
                      onChange={(e: any, data: any) => this.handleStateChange(data.value)}
                      value={userInfo.address?.state} />
                  </Form.Field>
                  <Form.Field>
                    <label>Zip Code</label>
                    <Cleave
                      name="address.zip"
                      value={user.address?.zip}
                      onChange={(e: any) => { this.updateUserAddress(e, 'zip'); handleChange(e) }}
                      options={{ blocks: [5], numericOnly: true }}
                    />
                    {errors.address?.zip && <div className="app-form-error">{errors.address?.zip}</div>}
                  </Form.Field>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="app-modal-btn-container">
          {
            user.email !== activeUser &&
            <Button disabled={ user.role !== RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN && user.email === activeUser } onClick={this.props.openDisable} 
              className="disable-user-button" id="disUserBTN_Pendo">Disable User</Button>
          }
          <Button onClick={() => { this.close(); this.props.close() }} className="app-button button-white app-modal-cancel-button" >Cancel</Button>
          <Button onClick={() => this.submitUpdatedInfo()}
            disabled={isSaveButtonDisabled}
            type="submit"
            className="app-button button-dark app-modal-submit-button">Save</Button>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  team: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo
});

const mapDispatchToProps = (dispatch: any) => ({
  updateUser: (data: any) => dispatch(updateUser(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUserModal);

