import { AdvancedFilterOptionsModel, ClaimModel, DisputesSummaryModel, ManufacturerDisputesFilterModel, ManufacturerDisputesModel, ManufacturerSummaryModel, PrimaryFilterOptionsModel, ResponseTotalsModel } from './types';
import { ErrorModel } from '../../applicationTypes';

export const GET_DISPUTES_CENTER_SUMMARY = 'GET_DISPUTES_CENTER_SUMMARY';
export const GET_DISPUTES_CENTER_SUMMARY_SUCCESS = 'GET_DISPUTES_CENTER_SUMMARY_SUCCESS';
export const GET_DISPUTES_CENTER_SUMMARY_FAILURE = 'GET_DISPUTES_CENTER_SUMMARY_FAILURE';

export const GET_MANUFACTURER_SUMMARIES = 'GET_MANUFACTURER_SUMMARIES';
export const GET_MANUFACTURER_SUMMARIES_SUCCESS = 'GET_MANUFACTURER_SUMMARIES_SUCCESS';
export const GET_MANUFACTURER_SUMMARIES_FAILURE = 'GET_MANUFACTURER_SUMMARIES_FAILURE';

export const GET_MANUFACTURER_SUMMARY = 'GET_MANUFACTURER_SUMMARY';
export const GET_MANUFACTURER_SUMMARY_SUCCESS = 'GET_MANUFACTURER_SUMMARY_SUCCESS';
export const GET_MANUFACTURER_SUMMARY_FAILURE = 'GET_MANUFACTURER_SUMMARY_FAILURE';

export const GET_MANUFACTURER_DISPUTES = 'GET_MANUFACTURER_DISPUTES';
export const GET_MANUFACTURER_DISPUTES_SUCCESS = 'GET_MANUFACTURER_DISPUTES_SUCCESS';
export const GET_MANUFACTURER_DISPUTES_FAILURE = 'GET_MANUFACTURER_DISPUTES_FAILURE';

export const GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS = 'GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS';
export const GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS_SUCCESS = 'GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS_SUCCESS';
export const GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS_FAILURE = 'GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS_FAILURE';
export const GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS = 'GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS';
export const GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS_SUCCESS = 'GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS_SUCCESS';
export const GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS_FAILURE = 'GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS_FAILURE';

export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const UPDATE_MANUFACTURER_DISPUTES_FILTERS = 'UPDATE_MANUFACTURER_DISPUTES_FILTERS';
export const UPDATE_MANUFACTURER_DISPUTES_PAGE_NUMBER = 'UPDATE_MANUFACTURER_DISPUTES_PAGE_NUMBER';
export const UPDATE_MANUFACTURER_DISPUTES_SORT = 'UPDATE_MANUFACTURER_DISPUTES_SORT';
export const UPDATE_MANUFACTURER_DISPUTES_SELECTED_CLAIM = 'UPDATE_MANUFACTURER_DISPUTES_SELECTED_CLAIM';

export const UPDATE_SINGLE_CLAIM_RESPONSE = 'UPDATE_SINGLE_CLAIM_RESPONSE';
export const UPDATE_SINGLE_CLAIM_RESPONSE_SUCCESS = 'UPDATE_SINGLE_CLAIM_RESPONSE_SUCCESS';
export const UPDATE_SINGLE_CLAIM_RESPONSE_FAILURE = 'UPDATE_SINGLE_CLAIM_RESPONSE_FAILURE';

export const GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS = 'GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS';
export const GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS_SUCCESS = 'GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS_SUCCESS';
export const GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS_FAILURE = 'GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS_FAILURE';

export const CLEAR_MANUFACTURER_DISPUTES = 'CLEAR_MANUFACTURER_DISPUTES';

export const NO_NEW_DISPUTES_HEADER = 'No New Disputes';
export const NO_NEW_DISPUTES_MESSAGE = 'You don’t have any new disputes for this manufacturer at the moment.';

export const NO_DISPUTE_FILTER_RESULTS_HEADER = 'No Filter Results';
export const NO_DISPUTE_FILTER_RESULTS_MESSAGE = 'Try adjusting your filter criteria to see more disputes.';

export const DisputesCenterTabMap: { [key: string]: number } = {
  'open': 0,
  'closed': 1,
}

export const OpenDisputesTabMap: { [key: string]: number } = {
  'newdisputes': 0,
  'senttomfr': 1,
  'impasse': 2
}

export const DISPUTES_CENTER_STATUS_TYPES = {
  OPEN: 'open',
  CLOSED: 'closed'
}

export const DISPUTE_STATUS_TYPES = {
  SENT_TO_STATE: 'DIS-STA-STS',
}

export const DisputesCenterStatusMap: { [key: number]: string } = {
  0: DISPUTES_CENTER_STATUS_TYPES.OPEN,
  1: DISPUTES_CENTER_STATUS_TYPES.CLOSED
}

export const OpenDisputesStatusMap: { [key: number]: string } = {
  0: 'newdisputes',
  1: 'senttomfr',
  2: 'impasse'
}

export const OpenDisputesStatusTypeMap: { [key: number]: string } = {
  0: DISPUTE_STATUS_TYPES.SENT_TO_STATE,
}

export enum OPEN_DISPUTES_TAB {
  NEW = 0,
  SENT_TO_MFR = 1,
  IMPASSE = 2
}

export const ManufacturerReviewFilterLabels: {[key: string]: string} = {
  icn: 'State ICN',
  manualDisputeCode: 'CMS Dispute Codes',
  maxDateOfService: 'End Date',
  minDateOfService: 'Start Date',
  pharmacyNpi: 'Provider NPI',
  productName: 'Product Name',
  programName: 'Program Name',
  rxNumber: 'Rx Number'
}

export const STATE_RESPONSE = {
  ACCEPT: 'Accept',
  CHALLENGE: 'Challenge',
  NONE: 'None'
}

interface GetDisputesCenterSummaryAction {
  type: typeof GET_DISPUTES_CENTER_SUMMARY,
  payload: string
}

interface GetDisputesCenterSummarySuccessAction {
  type: typeof GET_DISPUTES_CENTER_SUMMARY_SUCCESS,
  payload: DisputesSummaryModel
}

interface GetDisputesCenterSummaryFailureAction {
  type: typeof GET_DISPUTES_CENTER_SUMMARY_FAILURE,
  payload: ErrorModel
}

interface GetManufacturerSummariesAction {
  type: typeof GET_MANUFACTURER_SUMMARIES,
  payload: string
}

interface GetManufacturerSummariesSuccessAction {
  type: typeof GET_MANUFACTURER_SUMMARIES_SUCCESS,
  payload: ManufacturerSummaryModel[]
}

interface GetManufacturerSummariesFailureAction {
  type: typeof GET_MANUFACTURER_SUMMARIES_FAILURE,
  payload: ErrorModel
}

interface GetManufacturerSummaryAction {
  type: typeof GET_MANUFACTURER_SUMMARY,
  payload: {
    stateCode: string,
    manufacturerId: number
  }
}

interface GetManufacturerSummarySuccessAction {
  type: typeof GET_MANUFACTURER_SUMMARY_SUCCESS,
  payload: ManufacturerSummaryModel
}

interface GetManufacturerSummaryFailureAction {
  type: typeof GET_MANUFACTURER_SUMMARY_FAILURE,
  payload: ErrorModel
}

interface GetManufacturerDisputesAction {
  type: typeof GET_MANUFACTURER_DISPUTES,
  payload: {
    stateCode: string,
    manufacturerId: number,
    disputeStatus: string,
    activeTabIndex: number,
  }
}

interface GetManufacturerDisputesSuccessAction {
  type: typeof GET_MANUFACTURER_DISPUTES_SUCCESS,
  payload: ManufacturerDisputesModel
}

interface GetManufacturerDisputesFailureAction {
  type: typeof GET_MANUFACTURER_DISPUTES_FAILURE,
  payload: ErrorModel
}

interface GetManufacturerDisputesAdvancedFilterOptionsAction {
  type: typeof GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS,
  payload: {
    stateCode: string,
    manufacturerId: number,
    disputeStatus: string,
    filters: ManufacturerDisputesFilterModel
  }
}

interface GetManufacturerDisputesAdvancedFilterOptionsSuccessAction {
  type: typeof GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS_SUCCESS,
  payload: AdvancedFilterOptionsModel
}

interface GetManufacturerDisputesAdvancedFilterOptionsFailureAction {
  type: typeof GET_MANUFACTURER_DISPUTES_ADVANCED_FILTER_OPTIONS_FAILURE,
  payload: ErrorModel
}

interface GetManufacturerDisputesPrimaryFilterOptionsAction {
  type: typeof GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS,
  payload: {
    stateCode: string,
    manufacturerId: number,
    disputeStatus: string,
  }
}

interface GetManufacturerDisputesPrimaryFilterOptionsSuccessAction {
  type: typeof GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS_SUCCESS,
  payload: PrimaryFilterOptionsModel
}

interface GetManufacturerDisputesPrimaryFilterOptionsFailureAction {
  type: typeof GET_MANUFACTURER_DISPUTES_PRIMARY_FILTER_OPTIONS_FAILURE,
  payload: ErrorModel
}

interface UpdateFiltersAction {
  type: typeof UPDATE_FILTERS,
  payload: {
    activeTab: string,
    filters: ManufacturerDisputesFilterModel
  }
}

interface UpdateManufacturerDisputesFiltersAction {
  type: typeof UPDATE_MANUFACTURER_DISPUTES_FILTERS,
  payload: {
    stateCode: string,
    manufacturerId: number,
    disputeStatus: string,
    activeTab: string,
    filters: ManufacturerDisputesFilterModel
  }
}

interface UpdateManufacturerDisputesPageNumberAction {
  type: typeof UPDATE_MANUFACTURER_DISPUTES_PAGE_NUMBER,
  payload: number
}

interface UpdateManufacturerDisputesSortAction {
  type: typeof UPDATE_MANUFACTURER_DISPUTES_SORT,
  payload: {
    stateCode: string,
    manufacturerId: number,
    disputeStatus: string,
    isSortAsc: boolean,
    sortBy: string
  }
}

interface UpdateManufacturerDisputesSelectedClaimAction {
  type: typeof UPDATE_MANUFACTURER_DISPUTES_SELECTED_CLAIM,
  payload: number
}

interface GetManufacturerDisputesResponseTotalsAction {
  type: typeof GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS,
  payload: {
    stateCode: string,
    manufacturerId: number,
  }
}

interface GetManufacturerDisputesResponseTotalsSuccessAction {
  type: typeof GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS_SUCCESS,
  payload: ResponseTotalsModel
}

interface GetManufacturerDisputesResponseTotalsFailureAction {
  type: typeof GET_MANUFACTURER_DISPUTES_RESPONSE_TOTALS_FAILURE,
  payload: ErrorModel
}

interface UpdateSingleClaimResponseAction {
  type: typeof UPDATE_SINGLE_CLAIM_RESPONSE,
  payload: {
    stateCode: string,
    manufacturerId: number,
    claimId: number,
    stateResponse: string,
    index: number,
  }
}

interface UpdateSingleClaimResponseSuccessAction {
  type: typeof UPDATE_SINGLE_CLAIM_RESPONSE_SUCCESS,
  payload: {
    index: number,
    claim: ClaimModel
  }
}

interface UpdateSingleClaimResponseFailureAction {
  type: typeof UPDATE_SINGLE_CLAIM_RESPONSE_FAILURE,
  payload: {
    index: number,
    errorModel: ErrorModel
  }
}

interface ClearManufacturerDisputesAction {
  type: typeof CLEAR_MANUFACTURER_DISPUTES
}

export type DisputesCenterMdrpActionTypes =
  GetDisputesCenterSummaryAction |
  GetDisputesCenterSummarySuccessAction |
  GetDisputesCenterSummaryFailureAction |
  GetManufacturerSummariesAction |
  GetManufacturerSummariesSuccessAction |
  GetManufacturerSummariesFailureAction |
  GetManufacturerSummaryAction |
  GetManufacturerSummarySuccessAction |
  GetManufacturerSummaryFailureAction |
  GetManufacturerDisputesAction |
  GetManufacturerDisputesSuccessAction |
  GetManufacturerDisputesFailureAction |
  GetManufacturerDisputesPrimaryFilterOptionsAction |
  GetManufacturerDisputesPrimaryFilterOptionsSuccessAction|
  GetManufacturerDisputesPrimaryFilterOptionsFailureAction |
  GetManufacturerDisputesAdvancedFilterOptionsAction |
  GetManufacturerDisputesAdvancedFilterOptionsSuccessAction|
  GetManufacturerDisputesAdvancedFilterOptionsFailureAction |
  UpdateFiltersAction |
  UpdateManufacturerDisputesFiltersAction |
  UpdateManufacturerDisputesPageNumberAction |
  UpdateManufacturerDisputesSortAction |
  UpdateManufacturerDisputesSelectedClaimAction |
  UpdateSingleClaimResponseAction |
  UpdateSingleClaimResponseSuccessAction |
  UpdateSingleClaimResponseFailureAction |
  GetManufacturerDisputesResponseTotalsAction |
  GetManufacturerDisputesResponseTotalsSuccessAction |
  GetManufacturerDisputesResponseTotalsFailureAction |
  ClearManufacturerDisputesAction
