import React, { Fragment } from 'react';
import classnames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ChevronUp, ChevronDown } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { KalderosIcon, RequestIcon, RequestLogoIcon, ReportsIcon, DiscountMgmtIcon, RequestCenterIcon, Nubbin, KalderosWordmark, TeamManagementIcon } from '../../../themes/kalderos/Common/Icons';
import { RootState } from '../../../store/RootState';
import { UserModel } from '../../../store/login/types';
import { reportToggleLeftNavigation } from '../../../store/appcommon/actionCreators';
import { LegalDocumentsModel } from '../../../store/appcommon/types';
import HealthSystemSwitcher from '../../teammanagement/HealthSystemSwitcher';
import { HealthSystem, HealthSystemInfo } from '../../../store/teammanagement/types';
import ScreenReaderMessage from '../ScreenReaderMessage';
import RestrictedByRole from '../../teammanagement/RestrictedByRole';
import { ROLES } from '../../../store/applicationConstants';
import MdrpLeftNavigation from './MdrpLeftNavigation';
import { isAdminSupport } from '../../../utils/roleUtil';
import RequestConfig from '../../../app.config';

const NO_LEFT_NAV_PATHS = [
  '/login/',
  '/welcome/',
  '/acceptInvite',
  '/forgotpassword',
  '/expiredpassword',
  '/initialLogin',
  '/authorizingofficialsignup',
  '/signuperror'
];

interface NavigationLinkProps {
  link: string;
  text: string;
  id: string;
  NavIcon: any;
  pathname: string;
}

const NavigationLink = ({ link, id, text, NavIcon, pathname }: NavigationLinkProps) => {
  const isActive = pathname === link || pathname.includes(link);

  return (
    <div className={classnames('root-navigation', { 'active': isActive })}>
      <NavLink className='navigation-item' exact={true} to={link} id={id}>
        <div className='navigation-icon'><NavIcon /></div>
        <div className='navigation-text'>{text}</div>
      </NavLink>
    </div>
  );
}
interface SubNavigationProps {
  text: React.ReactNode;
  link: string;
  id: string;
  NavIcon: any;
  childLinks: any[];
  pathname: string;
}

const SubNavigation: React.FunctionComponent<SubNavigationProps> = ({ text, NavIcon, link, id, pathname, childLinks }) => {
  const isActive = pathname === link || !!childLinks.find(item => pathname.includes(item.link));
  const isRootActive = pathname === link && !childLinks.find(item => item.link === link);
  const [isCollapsed, setIsCollapsed] = React.useState(true);

  React.useEffect(() => {
    if (!isActive && !isRootActive) {
      setIsCollapsed(true)
    }
  }, [pathname, isActive, isRootActive]);

  return (
    <div className="sub-navigation">
      <div className={classnames('sub-navigation-link sub-navigation-parent', { 'root-active': isRootActive, 'active': isActive })}>
        <NavLink className='navigation-item' exact={true} to={link} id={id} onClick={() => setIsCollapsed(false)}>
          <div className='navigation-icon'><NavIcon /></div>
          <div className='navigation-text'>{text}</div>
        </NavLink>
        <div className='sub-navigation-icon' onClick={() => setIsCollapsed(!isCollapsed)}>
          {isCollapsed ? <ChevronDown strokeWidth='1.5' /> : <ChevronUp strokeWidth='1.5' />}
        </div>
      </div>
      <div className={classnames('sub-navigation-link sub-navigation-children', { 'collapsed': isCollapsed }, { 'active': isActive })}>
        <div className='flex-column flex-grow'>
          {childLinks.map(child =>
            <NavLink key={child.link} exact={true} to={child.link}
              className={classnames('sub-navigation-item', { 'active': pathname.includes(child.link) })}>{child.text}
            </NavLink>)}
        </div>
      </div>
    </div>
  )
}

const Header = ({ isExpanded, hasToggle, toggleNavigation }: { isExpanded: boolean, hasToggle: boolean, toggleNavigation: () => void }) => {
  return (
    <div className='left-navigation-header'>
      <div className="left-navigation-header-logo">
        {isExpanded ? <RequestLogoIcon /> : <RequestIcon />}
      </div>
      {hasToggle &&
        <div className='nubbin' onClick={toggleNavigation}>
          <Nubbin />
        </div>
      }
    </div>
  )
};

const Body = ({ isVisible, pathname }: { isVisible: boolean, pathname: string }) => {
  return (
    <div className="left-navigation-body">
      {isVisible &&
        <Fragment>
          <div className='navigation-section flex-column flex-grow scroll-hidden v-scroll-auto'>
            <RecipientNavLinks pathname={pathname} />
          </div>
        </Fragment>
      }
    </div>
  )
}

const Footer = ({ isExpanded, showExpandedContent, privacyPolicy, termsAndConditions, reviewAppUrl }: { isExpanded: boolean, showExpandedContent: boolean, privacyPolicy: string, termsAndConditions: string, reviewAppUrl: string }) => {
  return (
    <div className='left-navigation-footer'>
      {isExpanded &&
        <div className={classnames('transition', { 'visible': showExpandedContent })}>
          <RestrictedByRole allowedRoles={[ROLES.ADMIN_ALL_ACCESS]} checkUserRole>
            <a className="app-button left-navigation-footer-admin-button" href={reviewAppUrl}>
              Admin
            </a>
          </RestrictedByRole>
          <RestrictedByRole allowedRoles={[ROLES.ADMIN_SUPPORT]} checkUserRole>
            <div className="left-navigation-footer-admin-banner">
              Admin
            </div>
          </RestrictedByRole>
          <div className="left-navigation-footer-logo">
            <KalderosWordmark />
          </div>
          <div className="left-navigation-footer-links">
            {termsAndConditions &&
              <a href={termsAndConditions} className="app-link gray-link underline leftNavTermsAndConditions_Pendo" target='_blank' rel='noopener noreferrer'>
                Terms &amp; Conditions
                <ScreenReaderMessage>(opens in a new tab)</ScreenReaderMessage>
              </a>
            }
            {privacyPolicy &&
              <a href={privacyPolicy} className="app-link gray-link underline leftNavPrivacy_Pendo" target='_blank' rel='noopener noreferrer'>
                Privacy Policy
                <ScreenReaderMessage>(opens in a new tab)</ScreenReaderMessage>
              </a>
            }
          </div>
        </div>
      }
      {!isExpanded &&
        <div className={classnames('transition', { 'visible': !showExpandedContent })}>
          <div className='left-navigation-footer-icon'>
            <KalderosIcon />
          </div>
        </div>
      }
    </div>
  )
}

interface LeftNavigationState {
  isExpanded: boolean;
  isMinimal: boolean;
  isHidden: boolean;
  showExpandedContent: boolean,
  reviewAppUrl: string,
}

export interface LeftNavigationProps extends RouteComponentProps {
  user: UserModel;
  healthSystems: HealthSystem[];
  activeHealthSystemInfo: HealthSystemInfo
  legalDocuments: LegalDocumentsModel;
  reportToggleLeftNavigationForView: (isExpanded: boolean) => void;
}

export class LeftNavigation extends React.Component<LeftNavigationProps, LeftNavigationState>{
  constructor (props: Readonly<LeftNavigationProps>) {
    super(props);
    this.state = {
      isExpanded: true,
      isMinimal: false,
      isHidden: true,
      showExpandedContent: true,
      reviewAppUrl: '',
    };
  }

  componentDidMount () {
    const currentPath = this.props.location.pathname;
    if (currentPath) {
      this.configureNavigationByRoute(currentPath);
    }
    RequestConfig('reviewAppUrl')
      .then(url => this.setState({ reviewAppUrl: url }));
  }

  componentDidUpdate (prevProps: LeftNavigationProps, prevState: LeftNavigationState) {
    const currentPath = this.props.location.pathname;
    if (currentPath && currentPath !== prevProps.location.pathname) {
      this.configureNavigationByRoute(currentPath)
    }
    if (prevState.isMinimal === true && this.state.isMinimal === false) {
      this.setIsExpanded(true);
    }
  }

  configureNavigationByRoute = (currentPath: string) => {

    if (currentPath === ('/') || NO_LEFT_NAV_PATHS.some(p => this.props.location.pathname.includes(p))) {
      this.setState({ isHidden: true });
    } else if (currentPath.includes('/onboarding')) {
      this.setState({ isHidden: false });
      this.setState({ isMinimal: true });
      this.setIsExpanded(false);
    } else {
      this.setState({ isHidden: false });
      this.setState({ isMinimal: false });
    }
  }

  setIsExpanded = (isExpanded: boolean) => {
    this.setState({ isExpanded: isExpanded });
    setTimeout(() => {
      this.setState({ showExpandedContent: isExpanded })
    }, 200);
    this.props.reportToggleLeftNavigationForView(isExpanded);
  }

  toggleNavigation = () => {
    this.setIsExpanded(!this.state.isExpanded);
  };

  render () {
    const { isExpanded, isMinimal, isHidden, showExpandedContent, reviewAppUrl } = this.state;
    const { healthSystems, legalDocuments } = this.props;
    const isMdrpPath = this.props.location.pathname.includes('/states/');
    const isHealthSystemLoaded = this.props.activeHealthSystemInfo.healthSystemId > 0;

    if (isHidden || !this.props.user.isAuthenticated) return null;
    return (
      <div className={classnames('left-navigation', { 'expanded': isExpanded, 'collapsed': !isExpanded })}>
        {isMdrpPath ?
          <MdrpLeftNavigation
            isExpanded={isExpanded}
            isMinimal={isMinimal}
            showExpandedContent={showExpandedContent}
            toggleNavigation={this.toggleNavigation}
          /> :
          <Fragment>
            <Header isExpanded={isExpanded} hasToggle={!isMinimal} toggleNavigation={this.toggleNavigation} />
            {((healthSystems && healthSystems.length > 1) || isAdminSupport(this.props.user.roles!)) && isHealthSystemLoaded &&
              <HealthSystemSwitcher expanded={isExpanded} showExpandedContent={showExpandedContent} />
            }
            <Body isVisible={!isMinimal && isHealthSystemLoaded} pathname={this.props.location.pathname} />
            <Footer isExpanded={isExpanded} showExpandedContent={showExpandedContent} privacyPolicy={legalDocuments.privacyPolicy} termsAndConditions={legalDocuments.termsAndConditions} reviewAppUrl={reviewAppUrl} />
          </Fragment>
        }
      </div>
    );
  }
}

const RecipientNavLinks = ({ pathname }: { pathname: string }) => (
  <Fragment>
    <SubNavigation link="/recipients/requestcenter" text="Request Center" id="requestCenter" NavIcon={RequestCenterIcon} pathname={pathname}
      childLinks={[
        { text: 'Discount Requests', link: '/recipients/requestcenter' },
        { text: 'Reversals', link: '/recipients/reversals' }
      ]} />
    <SubNavigation link='/recipients/remittance' text='Reports' NavIcon={ReportsIcon} id='reportsHome' pathname={pathname}
      childLinks={[
        { text: 'Remittance Advice ', link: '/recipients/remittance' }
      ]} />
    <SubNavigation link="/recipients/payments" text={<Fragment>Covered Entity<br />Management</Fragment>} NavIcon={DiscountMgmtIcon} id='coveredEntityManagement' pathname={pathname}
      childLinks={[
        { text: 'Payments', link: '/recipients/payments' },
        { text: 'Covered Entity\'s Info', link: '/recipients/coveredentityinfo' }
      ]} />
    <NavigationLink link='/recipients/teammanagement/users' text='Team Management' NavIcon={TeamManagementIcon} id='teamManagement' pathname={pathname} />
  </Fragment>
);

const mapStateToProps = (state: RootState) => ({
  user: state.LoginState.user,
  healthSystems: state.TeamManagementState.entitledHealthSystems,
  activeHealthSystemInfo: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo,
  legalDocuments: state.AppCommonState.legalDocuments,
});

const mapDispatchToProps = (dispatch: any) => ({
  reportToggleLeftNavigationForView: (isExpanded: boolean) => dispatch(reportToggleLeftNavigation(isExpanded))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftNavigation));
