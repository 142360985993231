import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import Logrocket from 'logrocket';
import { verifyMicroDeposits, removeFundingSource, fetchDwollaEnvironment, fetchFundingSources, fetchDwollaBalance } from '../../../api/payments/CommonPayments';
import { Toast } from '../../toast/types';
import {
  UPDATE_FUNDING_SOURCE_AMOUNT, VERIFY_MICRO_DEPOSITS, REMOVE_FUNDING_SOURCE,
  FETCH_DWOLLA_ENVIRONMENT,
  GET_PAYMENT_SUMMARY,
  REMOVE_FUNDING_SOURCE_SUCCESS,
  UPDATE_PAYMENTS_SUMMARY_SORT_FILTERS,
  UPDATE_WITHDRAWN_AMOUNT,
  GET_PAYMENT_ALERT,
  INITIATE_MICRO_DEPOSITS,
  GET_FUNDING_SOURCE_TOKEN,
  DWOLLA_ADD_FUNDING_SOURCE_SUCCESS,
} from './constants';
import {
  updateMicroDepositStatus, removeFundingSourceSuccess,
  fetchDwollaEnvironmentSuccess, updatePaymentsSummaryError,
  fetchDwollaEnvironmentFailure, updatePaymentsSummary , updateWithdrawnAmountSuccess, updateFundingSourceAmountSuccess,
  getPaymentAlertSuccess, getPaymentAlertFailure,
  initiateMicroDeposits, initiateMicroDepositsSuccess, initiateMicroDepositsFailure,
  getFundingSourceTokenSuccess, getFundingSourceTokenFailure, removeFundingSourceFailure, updateFundingSourceAmountError
} from './actionCreators';
import { fundingSourceAmountUpdate, withdrawFundsUpdate, fetchFundingSourceToken, addFundingSourceRequest, initiateMicroDepositsRequest, fetchPaymentAlert } from '../../../api/payments/CommonPayments';
import { createToast } from '../../toast/actionCreators';
import { TOAST_TYPE } from '../../toast/constants';
import { formatDollar } from '../../../utils/numberFormatHelper';
import { getActiveHealthSystemId } from '../../teammanagement/selectors';
import { addFundingSource, addFundingSourceSuccess, addFundingSourceFailure } from '../common/actionCreators';
import { getFundingSourceId, getPaymentSettingsState, getFundingSourceIdForMicroDeposit } from './selectors';
import { isPayer } from '../../../utils/roleUtil';
import { selectRoles } from '../../login/loginSelector';
import logRocketUtil from '../../../utils/logRocketUtil';

export function* verifyMicroDepositsSaga (action: any) {
  try {
    const healthSystemId = yield select(getActiveHealthSystemId);
    const response = yield call(verifyMicroDeposits, action.payload, healthSystemId)
    yield put(updateMicroDepositStatus(response.status))
  }
  catch (e) {
    logRocketUtil(e)
    throw new Error('Error in verifying micro deposits')
  }
}

export function* removeFundingSourceSaga (action: any) {
  try {

    const { fundingSources } = yield select(getPaymentSettingsState);
    const selectedRemovedFundingSource = fundingSources.find((fundingSource: any) => fundingSource.id === action.payload.sourceId)
    const healthSystemId = yield select(getActiveHealthSystemId);
    const response = yield call(removeFundingSource, action.payload.sourceId, healthSystemId);

    if (response.status !== 200) {
      Logrocket.track('error')
      yield put(updatePaymentsSummaryError(response.status, selectedRemovedFundingSource))
    } else {
      yield put(removeFundingSourceSuccess())
      const toast: Toast = {
        text: `${action.payload.name} ${action.payload.bankName} removed.`,
        type: TOAST_TYPE.SUCCESS
      }
      yield put(createToast(toast))
    }

  }
  catch (e) {
    yield put(removeFundingSourceFailure(logRocketUtil(e)))
  }
}


export function* updateFundingSourceAmountSaga (action: any) {
  try {
    const { amount, sourceId } = action.payload;
    const response = yield call(fundingSourceAmountUpdate, amount, sourceId);
    yield put(createToast({ text: `${formatDollar(amount)} added to Balance. Funds available in 3-4 business days.`, type: TOAST_TYPE.SUCCESS }))
    yield put(updateFundingSourceAmountSuccess(response))
  }
  catch (e) {
    yield put(updateFundingSourceAmountError(logRocketUtil(e)))
    yield put(createToast({ text: `Error in updating balance`, type: TOAST_TYPE.ERROR }))
  }
}

export function* updateWithdrawnAmountSaga (action: any) {
  try {
    const { amount, sourceId } = action.payload;
    const response = yield call(withdrawFundsUpdate, amount, sourceId);
    yield put(createToast({ text: `${formatDollar(amount)} withdrawn from Balance.`, type: TOAST_TYPE.SUCCESS }));
    yield put((updateWithdrawnAmountSuccess(response)));
  } catch (e) {
    yield put(updateFundingSourceAmountError(logRocketUtil(e)))
    yield put(createToast({ text: `Error in withdrawing amount`, type: TOAST_TYPE.ERROR }))
  }
}

export function* fetchDwollaEnvironmentSaga () {
  try {
    const response = yield call(fetchDwollaEnvironment);
    yield put(fetchDwollaEnvironmentSuccess(response));
  } catch (e) {
    yield put(fetchDwollaEnvironmentFailure(logRocketUtil(e)));
  }
}


export function* getPaymentsSummarySaga () {
  try {
    const { sortBy, isSortDesc } = yield select(getPaymentSettingsState);
    const healthSystemId = yield select(getActiveHealthSystemId);
    const roles = yield select(selectRoles);

    const response = yield call(fetchFundingSources, sortBy, isSortDesc, healthSystemId);
    const verifiedAccts = response.filter((accounts: any) => accounts.status === 'verified')
    let balance = 0;
    if (isPayer(roles)) {
      const { dwollaBalance } = yield call(fetchDwollaBalance);
      balance = dwollaBalance;
    }
    yield put(updatePaymentsSummary(balance, response, verifiedAccts))
  }
  catch (e) {
    logRocketUtil(e)
    yield put(updatePaymentsSummaryError(500, e.message));
  }
}

export function* getPaymentAlertInfoSaga () {
  try {
    const healthSystemId = yield select(getActiveHealthSystemId);
    const response = yield call(fetchPaymentAlert, healthSystemId);
    yield put(getPaymentAlertSuccess(response));
  }
  catch (e) {
    yield put(getPaymentAlertFailure(logRocketUtil(e)));
  }
}

export function* initiateMicroDepositsSaga (action: any) {
  try {
    const healthSystemId = yield select(getActiveHealthSystemId);
    const sourceId = yield select(getFundingSourceIdForMicroDeposit);
    const retryMicroDeposit = action.payload.isRetry;
    const response = yield call(initiateMicroDepositsRequest, sourceId, healthSystemId);
    if (retryMicroDeposit) {
      yield put(initiateMicroDepositsSuccess(response));
      yield put(createToast({ text: `Retry initiated. Allow 1-3 days for microdeposits to reach account.`, type: TOAST_TYPE.SUCCESS }));
    }
    else {
      yield put(initiateMicroDepositsSuccess(response));
    }
  } catch (e) {
    yield put(initiateMicroDepositsFailure(logRocketUtil(e)));
  }
}

export function* getFundingSourceTokenSaga () {
  try {
    const healthSystemId = yield select(getActiveHealthSystemId);
    const response = yield call(fetchFundingSourceToken, healthSystemId);
    yield put(getFundingSourceTokenSuccess(response));
  } catch (e) {
    yield put(getFundingSourceTokenFailure(logRocketUtil(e)));
  }
}

export function* addFundingSourceSaga () {
  try {
    const fundingSourceId = yield select(getFundingSourceId);
    const healthSystemId = yield select(getActiveHealthSystemId);
    yield put (addFundingSource());
    yield call(addFundingSourceRequest, fundingSourceId, healthSystemId);
    yield put(addFundingSourceSuccess());
    yield put(initiateMicroDeposits(fundingSourceId))
  } catch (e) {
    let errorMessage = e.message;
    yield put(addFundingSourceFailure(logRocketUtil(e)));
    if (errorMessage && (errorMessage.indexOf('already exists') >= 0)) {
      errorMessage = 'Funding source already exists';
    }
    const toast: Toast = {
      text: errorMessage,
      type: TOAST_TYPE.ERROR
    }
    yield put(createToast(toast));
  }
}


function* watchVerifyMicroDeposits () {
  yield takeEvery(VERIFY_MICRO_DEPOSITS, verifyMicroDepositsSaga)
}

function* watchRemoveFundingSource () {
  yield takeEvery(REMOVE_FUNDING_SOURCE, removeFundingSourceSaga)

}

function* watchUpdateFundingSourceAmount () {
  yield takeEvery(UPDATE_FUNDING_SOURCE_AMOUNT, updateFundingSourceAmountSaga)
}

function* watchUpdateWithdrawnAmountSaga () {
  yield takeEvery(UPDATE_WITHDRAWN_AMOUNT, updateWithdrawnAmountSaga)
}

function* watchFetchDwollaEnvironmentSaga () {
  yield takeEvery(FETCH_DWOLLA_ENVIRONMENT, fetchDwollaEnvironmentSaga);
}

function* watchGetPaymentsSummary () {
  yield takeEvery([GET_PAYMENT_SUMMARY, UPDATE_PAYMENTS_SUMMARY_SORT_FILTERS, UPDATE_FUNDING_SOURCE_AMOUNT,
    REMOVE_FUNDING_SOURCE_SUCCESS], getPaymentsSummarySaga)
}


function* watchInitiateMicroDepositsSaga () {
  yield takeEvery(INITIATE_MICRO_DEPOSITS, initiateMicroDepositsSaga);
}

function* watchGetFundingSourceTokenSaga () {
  yield takeEvery(GET_FUNDING_SOURCE_TOKEN, getFundingSourceTokenSaga);
}

function* watchAddFundingSourceSaga () {
  yield takeEvery(DWOLLA_ADD_FUNDING_SOURCE_SUCCESS, addFundingSourceSaga);
}

function* watchFetchPaymentAlertSaga () {
  yield takeEvery(GET_PAYMENT_ALERT, getPaymentAlertInfoSaga);
}

export default function* watchAllCommonPayments () {
  yield all([
    watchVerifyMicroDeposits(),
    watchRemoveFundingSource(),
    watchUpdateFundingSourceAmount(),
    watchFetchDwollaEnvironmentSaga(),
    watchUpdateWithdrawnAmountSaga(),
    watchGetPaymentsSummary(),
    watchFetchPaymentAlertSaga(),
    watchInitiateMicroDepositsSaga(),
    watchGetFundingSourceTokenSaga(),
    watchAddFundingSourceSaga()
  ])
}

