import React, { Fragment } from 'react'
import cx from 'classnames';
import { Formik } from 'formik';
import * as yup from 'yup';
import Cleave from 'cleave.js/react';
import { Form } from 'semantic-ui-react';
import PasswordValidation from '../common/PasswordValidation';
import { UserModel } from '../../store/login/types';

interface SignUpProps {
  user?: UserModel;
  onSubmit: (values: SignUpFormInput) => void;
}

interface SignUpState {
  passwordStepComplete: boolean,
  showPasswordValidation: boolean,
  showPassword: boolean,
  showConfirmPassword: boolean,
  isPasswordValid: boolean,
}

export interface SignUpFormInput {
  firstName: string,
  lastName: string,
  phone: string,
  jobTitle: string,
  password: string,
  confirmPassword: string,
}

export class SignUp extends React.Component<SignUpProps, SignUpState> {
  constructor (props: Readonly<SignUpProps>) {
    super(props);
    this.state = {
      passwordStepComplete: false,
      showPasswordValidation: false,
      showPassword: false,
      showConfirmPassword: false,
      isPasswordValid: false,
    }
  }

  handlePasswordValidation = (isValid: boolean) => {
    this.setState({ isPasswordValid: isValid });
  }

  onContinueClick = () => {
    this.setState({ passwordStepComplete: true });
  }

  render () {

    const schema = yup.object().shape({
      firstName: yup
        .string()
        .required()
        .max(100)
        .trim(),
      lastName: yup
        .string()
        .required()
        .max(100)
        .trim(),
      phone: yup
        .string()
        .required()
        .test(
          'phone',
          'should be 10 digits',
          function (item) {
            if (item) {
              return /^(\(\d{3}\) \d{3}-\d{4})$/.test(item);
            }
            return true;
          }),
      jobTitle: yup
        .string()
        .required()
        .max(100)
        .trim(),
      password: yup
        .string()
        .required()
        .max(100),
      confirmPassword: yup
        .string()
        .required()
        .max(100)
    });

    const { passwordStepComplete, showPasswordValidation, showPassword, showConfirmPassword, isPasswordValid } = this.state;

    const initialValues: SignUpFormInput = {
      firstName: this.props.user?.given_name || '',
      lastName: this.props.user?.family_name || '',
      phone: '',
      jobTitle: '',
      password: '',
      confirmPassword: '',
    }

    return (
      <div className="login-page sign-up">
        <div className="login-page-layout">
          <div className="login-page-column-right">
            <div className="login-form-container">
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validateOnMount={true}
                validationSchema={schema}
                onSubmit={(values) => {
                  this.props.onSubmit(values);
                }}>
                {props => {

                  return (
                    <Form className="login-form" onSubmit={props.handleSubmit}>
                      <div className="login-form-header">{!passwordStepComplete ? 'Create your password' : 'Let\'s update your information'}</div>
                      <div className="login-form-body">

                        {!passwordStepComplete &&
                          <Fragment>
                            <Form.Input
                              data-private
                              name="password"
                              label={
                                <div className="password-label">
                                  <label>New Password</label>
                                  <button className={cx('app-button link-text', { 'hidden': !props.values.password })} type="button" onClick={() => this.setState({ showPassword: !showPassword })}>
                                    {showPassword ? 'Hide' : 'Show'}
                                  </button>
                                </div>
                              }
                              type={showPassword ? 'text' : 'password'}
                              defaultValue={props.values.password}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              onFocus={() => this.setState({ showPasswordValidation: true })}
                              className={cx({ 'input-error-field': props.values.password && props.errors.password && props.touched.password })}
                              placeholder="Selection" />

                            {props.values.password && props.errors.password && props.touched.password &&
                              <div className="input-error-text">Password invalid</div>
                            }

                            {showPasswordValidation &&
                              <PasswordValidation password={props.values.password} onValidation={this.handlePasswordValidation} />
                            }

                            <Form.Input
                              data-private
                              name="confirmPassword"
                              label={
                                <div className="password-label">
                                  <label>Confirm Password</label>
                                  <button className={cx('app-button link-text', { 'hidden': !props.values.confirmPassword })} type="button" onClick={() => this.setState({ showConfirmPassword: !showConfirmPassword })}>
                                    {showConfirmPassword ? 'Hide' : 'Show'}
                                  </button>
                                </div>
                              }
                              type={showConfirmPassword ? 'text' : 'password'}
                              defaultValue={props.values.confirmPassword}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              placeholder="Selection"
                              className="confirm-password" />

                            {props.values.confirmPassword && props.values.confirmPassword !== props.values.password &&
                              <div className="input-error-text">Password does not match</div>
                            }
                          </Fragment>
                        }

                        {passwordStepComplete &&
                          <Fragment>
                            <div className="login-form-description">
                              Before signing in, we’ll need you to update your profile information.
                            </div>
                            <Form.Input
                              data-private
                              name="firstName"
                              label="First Name"
                              defaultValue={props.values.firstName}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              className={cx({ 'input-error-field': props.errors.firstName && props.touched.firstName })} />

                            {props.errors.firstName && props.touched.firstName &&
                              <div className="input-error-text">First Name invalid</div>
                            }

                            <Form.Input
                              data-private
                              name="lastName"
                              label="Last Name"
                              defaultValue={props.values.lastName}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              className={cx({ 'input-error-field': props.errors.lastName && props.touched.lastName })} />

                            {props.errors.lastName && props.touched.lastName &&
                              <div className="input-error-text">Last Name invalid</div>
                            }

                            <Form.Input
                              data-private
                              name="jobTitle"
                              label="Job Title"
                              autoComplete="organization-title"
                              defaultValue={props.values.jobTitle}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              className={cx({ 'input-error-field': props.errors.jobTitle && props.touched.jobTitle })} />

                            {props.errors.jobTitle && props.touched.jobTitle &&
                              <div className="input-error-text">Job Title invalid</div>
                            }

                            <div className={cx('field', { 'input-error-field': props.errors.phone && props.touched.phone })}>
                              <label htmlFor="phone">Phone Number</label>
                              <div className="ui input">
                                <Cleave
                                  data-private
                                  name="phone"
                                  value={props.values.phone}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  options={{ delimiters: ['(', ')', ' ', '-'], blocks: [0, 3, 0, 3, 4] }}
                                />
                              </div>
                            </div>

                            {props.errors.phone && props.touched.phone &&
                              <div className="input-error-text">Phone Number invalid</div>
                            }

                          </Fragment>
                        }

                      </div>
                      <div className="login-form-footer">
                        {!passwordStepComplete && <button className="app-button cta" type="button" disabled={!props.values.password || !props.values.confirmPassword || !isPasswordValid || props.values.confirmPassword !== props.values.password || !!props.errors.password} onClick={this.onContinueClick}>Continue</button>}
                        {passwordStepComplete && <button className="app-button cta" type="submit" disabled={!props.isValid || !isPasswordValid || props.values.confirmPassword !== props.values.password}>Finish Setup</button>}
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SignUp
