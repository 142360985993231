import React from 'react'
import cx from 'classnames';
import { TransitionablePortal, Transition } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { TimesIcon } from '../../themes/kalderos/Common/Icons';
import { connect } from 'react-redux';
import { RootState } from '../../store/RootState';
import { UserModel } from '../../store/login/types';
import ProfileSettings from './ProfileSettings';

interface AccountDrawerProps {
  user: UserModel;
}

export const AccountDrawer: React.FunctionComponent<AccountDrawerProps> = ({ user }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isAccountInfoVisible, setIsAccountInfoVisible] = React.useState(false);

  const handleAccountProfileClick = () => {
    if (isAccountInfoVisible) {
      setIsAccountInfoVisible(false);
    } else {
      setIsAccountInfoVisible(true);
    }
  }

  return (
    <React.Fragment>
      {isOpen && <div className='account-dimmer' />}
      <div className="right-navigation-icon profile flex-center flex-column justify-center" onClick={() => setIsOpen(true)}>
        <span>
          {user?.given_name?.charAt(0) || '?'}
        </span>
      </div>
      <TransitionablePortal open={isOpen} onClose={() => { setIsOpen(false); setIsAccountInfoVisible(false); }} transition={{ animation: 'fade left', duration: 400 }}>
        <div className='account-drawer'>
          <div className='account-panel flex-column flex-grow scroll-hidden'>
            <div className='account-panel-header flex-row flex-center flex-noshrink'>
              <span className='beta'>Account</span>
              <div className='account-panel-header-icon' onClick={() => setIsOpen(false)}>
                <TimesIcon />
              </div>
            </div>
            <div className='account-panel-body flex-column flex-grow'>
              <button className={cx('app-button account-panel-link accountDrawerProfileSettings_Pendo', { 'selected': isAccountInfoVisible })} type='button' onClick={handleAccountProfileClick}>Profile Settings</button>
            </div>
            <div className='account-panel-footer flex-column'>
              <Link to='/logout' className='ui button basic accountDrawerSignOut_Pendo'>Sign out</Link>
            </div>
          </div>
          <Transition visible={isAccountInfoVisible} duration={200} animation="fade left">
            <div className='profile-settings-drawer'>
              <ProfileSettings />
            </div>
          </Transition>
        </div>
      </TransitionablePortal>
    </React.Fragment>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.LoginState.user
});

export default connect(mapStateToProps)(AccountDrawer)
