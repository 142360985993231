import { PharmacyManagementActionTypes, 
  GET_PHARMACY_MANAGEMENT,
  GET_PHARMACY_MANAGEMENT_SUCCESS, 
  GET_PHARMACY_MANAGEMENT_ERROR, 
  GET_EDIT_HISTORY_INFO_FAILURE,
  UPDATE_PHARMACY_MANAGEMENT_SORT_FILTERS, 
  UPDATE_PHARMACY_MANAGEMENT_PAGE, 
  GET_EDIT_HISTORY_INFO_SUCCESS, 
  GET_EDIT_HISTORY_INFO, 
  CLEAR_PHARMACY_MANAGEMENT,
  GET_REMOVE_PHARMACY_REASONS,
  GET_REMOVE_PHARMACY_REASONS_SUCCESS,
  UPDATE_GPOWAC_INDEX,
  GET_REMOVE_PHARMACY_REASONS_ERROR,
  UPDATE_340BPAY_INDEX,
  UPDATE_PHARMACY_MANAGEMENT_FILTERS
} from './constants';
import { PharmacyManagementModel, ErrorModel, PharmacyManagementState, PharmacyEditHistoryModel, PharmacyRemoveModel, RemoveReasonsModel, AssociatedPharmaciesFiltersModel } from './types';
 
 
const errorModel: ErrorModel = {
  isError: false,
  message: 'Error'
};

const associatedPharmaciesFilters: AssociatedPharmaciesFiltersModel = {
  city: '',
  dea: '',
  endDate: '',
  hin: '',
  npi: '',
  pharmacyName: '',
  pharmacyType: null,
  startDate: '',
  state: null,
  termEndDate: '',
  termStartDate: ''
}

const AssociatedPharmaciesDefaultState: PharmacyManagementModel = {
  associatedPharmacies: [],
  currentPage: 1,
  currentApiPage: 1,
  totalCount: 0,
  sortBy: 'npi',
  isSortDesc: false,
  isLoading: false,
  isError: errorModel,
  id340B: '',
  coveredEntityName: '',
  filters: associatedPharmaciesFilters
}

const PharmacyEditHistoryModelState: PharmacyEditHistoryModel = {
  pharmacyEditViews: [],
  isLoading: false
}

const removeReasonsModel: RemoveReasonsModel = {
  reasons: [],
  isLoading: false,
  errorText: ''
};

const RemovePharmacyDefaultState: PharmacyRemoveModel = {
  reasons: removeReasonsModel
};

const initialState: PharmacyManagementState = {
  associatedPharmaciesModel: AssociatedPharmaciesDefaultState,
  pharmacyEditHistoryModel: PharmacyEditHistoryModelState,
  removePharmacy: RemovePharmacyDefaultState
}

export function pharmacyManagementReducer (
  state = initialState,
  action: PharmacyManagementActionTypes
): PharmacyManagementState {
  switch (action.type) {

    case GET_PHARMACY_MANAGEMENT:
      return {
        ...state,
        associatedPharmaciesModel: {
          ...state.associatedPharmaciesModel,
          isLoading: true,
          id340B: action.payload
        }
      }

    case GET_PHARMACY_MANAGEMENT_SUCCESS:
      return {
        ...state,
        associatedPharmaciesModel: {
          ...state.associatedPharmaciesModel,
          associatedPharmacies: action.payload.associatedPharmacies,
          currentApiPage: action.payload.page,
          totalCount: action.payload.totalCount,
          isLoading: false,
          coveredEntityName: action.payload.coveredEntityName
        }
      }

    case GET_PHARMACY_MANAGEMENT_ERROR:
      return {
        ...state,
        associatedPharmaciesModel: {
          ...state.associatedPharmaciesModel,
          isLoading: false,
          isError: {
            isError: true,
            message: action.payload.message
          }
        }
      }
    case GET_EDIT_HISTORY_INFO:
      return {
        ...state,
        pharmacyEditHistoryModel: {
          ...state.pharmacyEditHistoryModel,
          isLoading: true
        }
      }
    case GET_EDIT_HISTORY_INFO_SUCCESS:
      return {
        ...state,
        pharmacyEditHistoryModel: {
          ...state.pharmacyEditHistoryModel,
          pharmacyEditViews: action.payload.pharmacyEditViews,
          isLoading: false
        }
      }
    case GET_EDIT_HISTORY_INFO_FAILURE:
      return {
        ...state,
        pharmacyEditHistoryModel: {
          ...state.pharmacyEditHistoryModel,
          isLoading: false
        }
      }

    case UPDATE_PHARMACY_MANAGEMENT_SORT_FILTERS:
      return {
        ...state,
        associatedPharmaciesModel: {
          ...state.associatedPharmaciesModel,
          currentPage: 1,
          isLoading: true,
          isSortDesc: !action.payload.isSortAsc,
          sortBy: action.payload.sortBy
        }
      }

    case UPDATE_PHARMACY_MANAGEMENT_PAGE:
      return {
        ...state,
        associatedPharmaciesModel: {
          ...state.associatedPharmaciesModel,
          currentPage: action.payload
        }
      }
    case CLEAR_PHARMACY_MANAGEMENT:
      return {
        ...state,
        associatedPharmaciesModel: AssociatedPharmaciesDefaultState
      }
  
    case UPDATE_GPOWAC_INDEX:
    {
      const selectedIndex = action.payload.index;
      const pharmacy = action.payload.pharmacy;
      pharmacy.gpoOrWac = action.payload.pharmacy.gpoOrWac === 'WAC' ? 'GPO' : 'WAC';
      const tempPharmacy = [...state.associatedPharmaciesModel.associatedPharmacies];
      tempPharmacy.splice(selectedIndex, 1, pharmacy)
      return {
        ...state,
        associatedPharmaciesModel: {
          ...state.associatedPharmaciesModel,
          associatedPharmacies: tempPharmacy
        }
      }
    }
    case UPDATE_340BPAY_INDEX:
    {
      const selectedIndex = action.payload.index;
      const pharmacy = action.payload.pharmacy;
      pharmacy.pay340B = !action.payload.pharmacy.pay340B;
      const tempPharmacy = [...state.associatedPharmaciesModel.associatedPharmacies];
      tempPharmacy.splice(selectedIndex, 1, pharmacy)
      return {
        ...state,
        associatedPharmaciesModel: {
          ...state.associatedPharmaciesModel,
          associatedPharmacies: tempPharmacy
        }
      }
    }
    case GET_REMOVE_PHARMACY_REASONS:
      return {
        ...state,
        removePharmacy: {
          reasons: {
            ...state.removePharmacy.reasons,
            isLoading: true
          }
        }
      }
    case GET_REMOVE_PHARMACY_REASONS_SUCCESS:
      return {
        ...state,
        removePharmacy: {
          reasons: {
            ...state.removePharmacy.reasons,
            reasons: action.payload,
            isLoading: false
          }
        }
      }
    case GET_REMOVE_PHARMACY_REASONS_ERROR:
      return {
        ...state,
        removePharmacy: {
          reasons: {
            ...state.removePharmacy.reasons,
            errorText: action.payload,
            isLoading: false
          }
        }
      }
    case UPDATE_PHARMACY_MANAGEMENT_FILTERS:
      return {
        ...state,
        associatedPharmaciesModel: {
          ...state.associatedPharmaciesModel,
          filters: action.payload,
          currentPage: 1,
          isLoading: true,
          isSortDesc: false,
          sortBy: 'npi'
        },
      }

    default:
      return state
  }
}
