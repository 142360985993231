
export type ConfigsAvailable =
  | 'apiUrl'
  | 'logRocketEnabled'
  | 'idleTimeoutSeconds'
  | 'reviewAppUrl'
  | 'discountRequestRetailTemplate'
  //| 'discountRequestMedicalTemplate'
  | '';

const RequestConfig = async (config?: ConfigsAvailable) => {
  try {
    // default to most used config
    const configToLookup = config ? config : 'apiUrl';
    // dev environment check
    if (process.env.NODE_ENV === 'development') {
      if (configToLookup === 'apiUrl') {
        // return 'https://dev-request-api.kalderos.com';
        return 'https://test-request-api.kalderos.com';
        // return 'http://localhost:5001';
      }
      if (configToLookup === 'idleTimeoutSeconds') {
        return 900;
      }
      if (configToLookup === 'reviewAppUrl') {
        return 'https://test2-grappa.kalderos.com/';
      }
      if (configToLookup === 'discountRequestRetailTemplate') {
        return 'https://kalderosappstoragedev.blob.core.windows.net/public/templates/discount_request_retail_template.csv';
      }
      // if (configToLookup === 'discountRequestMedicalTemplate') {
      //   return 'https://kalderosappstoragedev.blob.core.windows.net/public/templates/discount_request_medical_template.csv';
      // }
    }

    // check if retrieved and set previously
    if ((window as any)[configToLookup] != null) {
      return (window as any)[configToLookup];
    }

    // if not set previously get from node server
    const response = await fetch(`/config`, {
      method: 'GET',
      headers: {
      },
    });
    const data = await response.json();
    (window as any)[configToLookup] = data[configToLookup];
    return data[configToLookup];

  } catch (e) {
    //return error response model

  }
};

export default RequestConfig;