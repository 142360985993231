import React, { Fragment } from 'react';
import { Modal, Button, Dropdown, DropdownItemProps, Input, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import TeamListComponent from './TeamListComponent';
import CeList from './CeList';
import { RootState } from '../../../store/RootState';
import { CoveredEntity, HealthSystemInfo, HealthSystem, HealthSystemSearchInput } from '../../../store/teammanagement/types';
import { getHealthSystemInfo, getCEListForHSModal, closeSearchHealthSystemsModal, searchHealthSystems } from '../../../store/teammanagement/actionCreators';

export interface HealthSystemSearchModalProps extends RouteComponentProps {
  coveredEntities: CoveredEntity[];
  healthSystems: HealthSystem[];
  activeHealthSystemInfo: HealthSystemInfo;
  isOpen: boolean;
  isRequired: boolean;
  isLoading: boolean;
  ceIsLoading: boolean;
  ceListError: string;
  getHealthSystemInfo: (healthSystemId: number, switchingHs: boolean) => void;
  getCEListForHSModal: (healthSystemId: number) => void;
  searchHealthSystems: (input: HealthSystemSearchInput) => void;
  closeHealthSystemSearchModal: () => void;
}

interface HealthSystemSearchModalState {
  showTeamList: boolean;
  name: string;
  showId: number | null;
  searchType: string;
  searchText: string;
  isDirty: boolean;
}

const searchOption: DropdownItemProps[] = [
  { key: 'name', text: 'Health System', value: 'name' },
  { key: 'email', text: 'Email Address', value: 'email' },
  { key: 'id340b', text: '340B ID', value: 'id340b' }
]

export class HealthSystemSearchModal extends React.Component<HealthSystemSearchModalProps, HealthSystemSearchModalState> {
  constructor (props: Readonly<HealthSystemSearchModalProps>) {
    super(props);
    this.state = {
      showTeamList: true,
      name: '',
      showId: null,
      searchType: '',
      searchText: '',
      isDirty: false,
    };
  }

  close = () => {
    this.props.closeHealthSystemSearchModal();
    this.reset();
  };

  search = () => {
    this.setState({ isDirty: true });
    const input: HealthSystemSearchInput = { [this.state.searchType]: this.state.searchText };
    this.props.searchHealthSystems(input);
  }

  showCeList = (id: number, name: string) => {
    this.props.getCEListForHSModal(id)
    this.setState({ showTeamList: false, name: name, showId: id });
  };

  handleBack = () => {
    this.setState({ showTeamList: true });
  };

  switchTeam = () => {
    this.props.closeHealthSystemSearchModal();
    this.props.getHealthSystemInfo(this.state.showId!, true);
    this.props.history.push('/')
    this.reset();
  };

  setShowId = (id: number | null) => {
    this.setState({ showId: id });
  };

  reset = () => {
    this.setState({
      showId: null,
      searchType: '',
      searchText: '',
      isDirty: false
    })
  }

  render () {
    const { showTeamList, name, showId, searchType, searchText, isDirty } = this.state;
    const { isOpen, isRequired, coveredEntities, isLoading, healthSystems, activeHealthSystemInfo, ceIsLoading, ceListError } = this.props;
    return (
      <Modal
        open={isOpen}
        closeOnDimmerClick={false}
        closeOnEscape={!isRequired}
        onClose={this.close}
        className="switch-hs-modal app-modal">
        {isLoading && <Dimmer className="dimmer" active inverted><Loader inverted /></Dimmer>}
        <div className="app-modal-header">
          {'Select Your Health System'}
          {showTeamList ? null : <p className="sub-header overflow-ellipsis">Covered Entities with {name}</p>}
        </div>
        {showTeamList &&
          <div className="hs-search-input">
            <Dropdown
              name="searchType"
              placeholder="Select search category"
              selection
              options={searchOption}
              value={searchType}
              onChange={(e, d) => this.setState({ searchType: d.value as string })}
            />
            <div className="hs-search-text">
              <Input
                type="text"
                name="searchText"
                placeholder='Search by selection'
                icon='search'
                iconPosition='left'
                value={searchText}
                disabled={!searchType}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter' && searchText) {
                    this.search();
                  }
                }}
                onChange={(e, d) => this.setState({ searchText: d.value })}
              />
              <button type="submit" className='app-button button-dark' disabled={!searchType || !searchText} onClick={() => this.search()}>Search</button>
            </div>
          </div>
        }
        <div className="app-modal-content">
          {showTeamList &&
            <Fragment>
              {!isLoading && isDirty && healthSystems.length === 0 ?
                <Fragment>
                  <div className="team-list-top flex-row">
                    <span>Health System</span>
                    <span>Covered Entities</span>
                  </div>
                  <div className="no-search-results">No Health Systems Found</div>
                </Fragment>
                :
                <TeamListComponent
                  setShowId={this.setShowId}
                  waiting={false}
                  showId={showId !== null ? showId : activeHealthSystemInfo.healthSystemId}
                  healthSystems={healthSystems}
                  showCeList={this.showCeList}
                />
              }
            </Fragment>
          }
          {!showTeamList &&
            <CeList ceListError={ceListError} waiting={ceIsLoading} ceList={coveredEntities} />
          }
        </div>
        <div className="app-modal-btn-container">
          {showTeamList && !isRequired && <Button onClick={() => this.close()} className="app-button button-white app-modal-cancel-button">Cancel</Button>}
          {showTeamList ?
            <Button
              className='app-button button-dark'
              disabled={showId === null || activeHealthSystemInfo.healthSystemId === showId}
              onClick={() => this.switchTeam()}>
              {'Switch'}
            </Button> :
            <Button
              disabled={showTeamList}
              className="app-button button-dark app-modal-submit-button"
              onClick={() => this.handleBack()}>
              Back
            </Button>}
        </div>
      </Modal>
    )
  }
}



const mapStateToProps = (state: RootState) => ({
  activeHealthSystemInfo: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo,
  isOpen: state.TeamManagementState.searchHealthSystemsModal.isOpen,
  isRequired: state.TeamManagementState.searchHealthSystemsModal.isRequired,
  coveredEntities: state.TeamManagementState.changeHealthSystemModal.coveredEntities, //todo: probably shouldn't share this?
  healthSystems: state.TeamManagementState.searchHealthSystemsModal.healthSystems,
  isLoading: state.TeamManagementState.searchHealthSystemsModal.isLoading,
  ceIsLoading: state.TeamManagementState.changeHealthSystemModal.modalWaiting,
  ceListError: state.TeamManagementState.searchHealthSystemsModal.errorText,
});

const mapDispatchToProps = (dispatch: any) => ({
  getHealthSystemInfo: (healthSystemId: number, switchingHS: boolean) => dispatch(getHealthSystemInfo(healthSystemId, switchingHS)),
  getCEListForHSModal: (healthSystemId: number) => dispatch(getCEListForHSModal(healthSystemId)),
  searchHealthSystems: (input: HealthSystemSearchInput) => dispatch(searchHealthSystems(input)),
  closeHealthSystemSearchModal: () => dispatch(closeSearchHealthSystemsModal()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HealthSystemSearchModal))
