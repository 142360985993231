import React from 'react'
import cx from 'classnames';
import { Circle, CheckCircle } from 'react-feather';

import '../../../themes/kalderos/Common/FormStepper.scss';

export enum STEP_STATUS {
  PENDING = 'pending',
  ACTIVE = 'active',
  PROCESSING = 'processing',
  COMPLETE = 'complete'
}

export interface FormStepperStep {
  text: string,
  status: STEP_STATUS,
  isComplete: boolean,
  prevStep?: string,
}

const StepIcon = ({ step }: { step: any }) => {
  if (step.status === STEP_STATUS.ACTIVE || step.status === STEP_STATUS.PROCESSING)
    return <Circle fill="#1e2533" size={16} />
  if ((step.status === STEP_STATUS.COMPLETE) || step.isComplete) 
    return <CheckCircle size={16} />
  
  return <Circle size={16} />;
}

const FormStepper = ({ steps, activateStep, isDisabled=false }: { steps: Record<string, FormStepperStep>, activateStep: (stepName: string) => void, isDisabled?: boolean }) => {
  return (
    <div className='formStepper-container flex-column flex-grow'>
      {
        Object.keys(steps).map((stepName) => {
          const step = steps[stepName];
          const prevStepName = step.prevStep || '';
          const isPrevStepComplete = steps[prevStepName] && steps[prevStepName].isComplete;

          const showLink = !isDisabled && (step.status !== STEP_STATUS.ACTIVE) && (step.status === STEP_STATUS.COMPLETE || isPrevStepComplete);

          return (
            <div className={cx('stepper flex-column', { 'active': step.status === STEP_STATUS.ACTIVE })} key={stepName}>
              <div className='flex-row'>
                <StepIcon step={step}/>
                {
                  // show link only when if step is complete or if the previous step is complete and current step is not active
                  showLink ? <button className='stepper-text eta stepLink' type="button" onClick={(e: any) => {
                    e.preventDefault();
                    activateStep(stepName);
                  }}>
                    {step.text}
                  </button>
                    : <div className='stepper-text eta'> {step.text}</div>
                }
              </div>
              <div className='step-divider' />
            </div>
          )
        })
      }
    </div>
  )
}

export default FormStepper;