import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store/RootState';
import { Modal, Button, Dimmer, Loader } from 'semantic-ui-react';
import { TeamMember } from '../../../store/teammanagement/types';
import { cancelInvite, closeCancelInviteModal } from '../../../store/teammanagement/actionCreators';
import { ErrorBanner } from '../../common/errors/ErrorBanner';

export interface CancelInviteModalProps {
  user?: TeamMember,
  isOpen: boolean,
  isLoading: boolean,
  errorText: string,
  cancelInvite: (userId: number, email: string) => void,
  closeCancelInviteModal: () => void;
}

export const CancelInviteModal: React.FunctionComponent<CancelInviteModalProps> = (props: CancelInviteModalProps) => {

  function handleCancelClick () {
    props.closeCancelInviteModal();
  }

  function handleConfirmClick () {
    if (props.user) {
      props.cancelInvite(props.user.id, props.user.email);
    }
  }

  return (
    <Modal className="team-management-modal edit-team-modal" open={props.isOpen}>
      <div className="team-management-modal-header">Cancel Invite</div>
      {props.isLoading && <Dimmer className="dimmer" active inverted><Loader inverted /></Dimmer>}
      {props.errorText &&
        <ErrorBanner
          errorText={'An error occurred while canceling the invite'}
        />}
      <div className="team-management-modal-body">
        You are about to cancel the invite for this user
      </div>
      <div className="team-management-modal-footer">
        <Button type="button" className="app-button button-white" onClick={handleCancelClick}>Cancel</Button>
        <Button type="submit" className="app-button button-dark" onClick={handleConfirmClick}>Confirm</Button>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.TeamManagementState.cancelInviteModal.user,
  isOpen: state.TeamManagementState.cancelInviteModal.isOpen,
  isLoading: state.TeamManagementState.cancelInviteModal.isLoading,
  errorText: state.TeamManagementState.cancelInviteModal.errorText,
});

const mapDispatchToProps = (dispatch: any) => ({
  cancelInvite: (userId: number, email: string) => dispatch(cancelInvite(userId, email)),
  closeCancelInviteModal: () => dispatch(closeCancelInviteModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelInviteModal);
