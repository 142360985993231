import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import PageHeader from '../../common/navigation/PageHeader';
import ErrorImage from '../../common/errors/ErrorImage';
import { RootState } from '../../../store/RootState';
import { SERVER_ERROR_HEADER, SERVER_ERROR_MESSAGE } from '../../../store/applicationConstants';
import { getRemittanceTransactionsCounts, getRemittanceTransactions, getRemittanceInvoiceDetails, resetRemittanceDetails } from '../../../store/remittance/actionCreators';
import { RemittanceDetailsCountsModel, RemittancePeriodSummaryDetail } from '../../../store/remittance/types';
import { REMITTANCE_CLIENT_ERROR_HEADER, REMITTANCE_CLIENT_ERROR_MESSAGE, REMITTANCE_DETAILS_TAB } from '../../../store/remittance/constants';
import RemittanceTransactionsTabs, { RemittanceDetailsTabMap } from '../Transactions/RemittanceTransactionsTabs';
import { RemittanceInvoiceDetailRow } from './RemittanceManufacturerFilter';

import ClientErrorImage from '../../../themes/kalderos/static/media/errors/ClientError.svg';
import ServerErrorImage from '../../../themes/kalderos/static/media/errors/ServerError.svg';
import { isRemittanceTransactionsLoading } from '../../../store/remittance/selectors';

export interface RemittanceInvoiceDetailsProps extends RouteComponentProps {
  activeIndex: REMITTANCE_DETAILS_TAB,
  remittanceId: number,
  participantId: string,
  summary?: RemittancePeriodSummaryDetail
  counts: RemittanceDetailsCountsModel,
  isLoading: boolean;
  errorText: string;
  isInvalid: boolean;
  getRemittanceInvoiceDetails: (remittanceId: number, participantId: string) => void;
  getRemittanceTransactionsCounts: () => void;
  getRemittanceTransactions: (tab: REMITTANCE_DETAILS_TAB) => void;
  resetRemittanceDetails: () => void;
}

export class RemittanceInvoiceDetails extends React.Component<RemittanceInvoiceDetailsProps> {

  componentDidMount () {
    this.props.getRemittanceInvoiceDetails(this.props.remittanceId, this.props.participantId);
  }

  componentDidUpdate (prevProps: RemittanceInvoiceDetailsProps) {
    if (this.props.summary && !prevProps.summary) {
      this.props.getRemittanceTransactionsCounts();
      this.props.getRemittanceTransactions(this.props.activeIndex);
    }
  }

  componentWillUnmount () {
    this.props.resetRemittanceDetails();
  }

  handleTabChange = (tab: REMITTANCE_DETAILS_TAB) => {
    const path = Object.keys(RemittanceDetailsTabMap).find(k => RemittanceDetailsTabMap[k] === tab);
    this.props.history.push(`/recipients/remittance/invoice/${this.props.remittanceId}/${this.props.participantId}/${path}`);
  }

  render () {
    const { activeIndex, isLoading, summary, counts, errorText, isInvalid } = this.props;

    return (

      <div className="remittance-advice-home">
        <div className="remittance-advice-home-page-header">
          <PageHeader text={`Remittance Advice #${this.props.remittanceId}`} defaultBack />
        </div>

        <div className="remittance-advice-home-main-container">

          {isLoading &&
            <Dimmer active inverted>
              <Loader className="remittance-advice-loader" inverted content='Loading' />
            </Dimmer>
          }

          {errorText &&
            <ErrorImage
              header={isInvalid ? REMITTANCE_CLIENT_ERROR_HEADER : SERVER_ERROR_HEADER}
              image={isInvalid ? ClientErrorImage : ServerErrorImage}
              link={isInvalid ? '/recipients/remittance' : undefined}
              linkText={isInvalid ? 'Go to Remittance Center' : undefined}
              message={isInvalid ? REMITTANCE_CLIENT_ERROR_MESSAGE : SERVER_ERROR_MESSAGE}
            />
          }

          {summary &&
            <div className='remittance-request-center'>
              <div className="remittance-filter-row">
                <div className="manufacturer-filter remittance-summary-without-filter">
                  <RemittanceInvoiceDetailRow detail={summary} showRemittanceNumber />
                </div>
              </div>

              <RemittanceTransactionsTabs
                activeTab={activeIndex}
                isLoading={isLoading}
                counts={counts}
                handleTabChange={this.handleTabChange}
              />

            </div>
          }
        </div>
      </div>

    )
  }
}

const mapStateToProps = (state: RootState) => ({
  summary: state.RemittanceState.invoiceDetails.summary,
  counts: state.RemittanceState.counts,
  isLoading: state.RemittanceState.invoiceDetails.isLoading || isRemittanceTransactionsLoading(state),
  errorText: state.RemittanceState.invoiceDetails.errorText,
  isInvalid: state.RemittanceState.invoiceDetails.isInvalid,
});

const mapDispatchToProps = (dispatch: any) => ({
  getRemittanceInvoiceDetails: (remittanceId: number, participantId: string) => dispatch(getRemittanceInvoiceDetails(remittanceId, participantId)),
  getRemittanceTransactionsCounts: () => dispatch(getRemittanceTransactionsCounts()),
  getRemittanceTransactions: (tab: REMITTANCE_DETAILS_TAB) => dispatch(getRemittanceTransactions(tab)),
  resetRemittanceDetails: () => dispatch(resetRemittanceDetails()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RemittanceInvoiceDetails)
