import {
  PaymentsActionTypes,
  SHOW_ADD_FUNDS_MODAL,
  SHOW_WITHDRAW_FUNDS_MODAL,
  HIDE_ADD_WITHDRAW_FUNDS_MODAL,
  VERIFY_MICRO_DEPOSITS,
  UPDATE_MICRO_DEPOSIT_STATUS,
  RESET_MICRO_DEPOSIT_STATUS,
  REMOVE_FUNDING_SOURCE,
  UPDATE_FUNDING_SOURCE_AMOUNT,
  UPDATE_FUNDING_SOURCE_AMOUNT_SUCCESS,
  UPDATE_FUNDING_SOURCE_AMOUNT_ERROR,
  VERIFY_MICRO_DEPOSITS_MODAL_CLOSE,
  REMOVE_FUNDING_SOURCE_SUCCESS,
  UPDATE_WITHDRAWN_AMOUNT,
  UPDATE_WITHDRAWN_AMOUNT_SUCCESS,
  UPDATE_WITHDRAWN_AMOUNT_FAILURE,
  ADD_FUNDING_SOURCE,
  ADD_FUNDING_SOURCE_FAILURE,
  ADD_FUNDING_SOURCE_SUCCESS,
  FETCH_DWOLLA_ENVIRONMENT,
  FETCH_DWOLLA_ENVIRONMENT_SUCCESS,
  FETCH_DWOLLA_ENVIRONMENT_FAILURE,
  GET_PAYMENT_SUMMARY,
  UPDATE_PAYMENT_SUMMARY,
  UPDATE_PAYMENT_SUMMARY_ERROR,
  UPDATE_PAYMENTS_SUMMARY_SORT_FILTERS,
  GET_PAYMENT_ALERT,
  GET_PAYMENT_ALERT_FAILURE,
  GET_PAYMENT_ALERT_SUCCESS,

  INITIATE_MICRO_DEPOSITS,
  INITIATE_MICRO_DEPOSITS_SUCCESS, INITIATE_MICRO_DEPOSITS_FAILURE, GET_FUNDING_SOURCE_TOKEN,
  GET_FUNDING_SOURCE_TOKEN_SUCCESS, GET_FUNDING_SOURCE_TOKEN_FAILURE,
  DWOLLA_ADD_FUNDING_SOURCE_SUCCESS, DWOLLA_ADD_FUNDING_SOURCE_FAILURE, REMOVE_FUNDING_SOURCE_FAILURE, RESET_ADD_FUNDING_SOURCE
} from './constants';

import { VerifyMicroDepositsModel, FundingSourcesModel } from './types';
import { SortingFilter } from '../../appcommon/types';
import { ErrorModel } from '../../applicationTypes';


export function getPaymentSummary (): PaymentsActionTypes {
  return {
    type: GET_PAYMENT_SUMMARY
  }
}

export function updatePaymentsSummary (dwollaBalance: number, fundingSources: FundingSourcesModel[], approvedFundingSources: FundingSourcesModel[]): PaymentsActionTypes {
  return {
    type: UPDATE_PAYMENT_SUMMARY,
    payload: {
      dwollaBalance,
      fundingSources,
      approvedFundingSources
    }
  }
}

export function updatePaymentsSummaryError (errorStatus: number | null, errorAccount: any): PaymentsActionTypes {
  return {
    type: UPDATE_PAYMENT_SUMMARY_ERROR,
    payload: {
      errorStatus,
      errorAccount
    }
  }
}

export function updatePaymentsSummaryTableSortFilters (sortFilters: SortingFilter): PaymentsActionTypes {
  return {
    type: UPDATE_PAYMENTS_SUMMARY_SORT_FILTERS,
    payload: sortFilters
  }
}


export function showAddFundsModal (): PaymentsActionTypes {
  return {
    type: SHOW_ADD_FUNDS_MODAL
  }
}

export function showWithdrawFundsModal (): PaymentsActionTypes {
  return {
    type: SHOW_WITHDRAW_FUNDS_MODAL
  }
}

export function hideAddWithdrawFundsModal (): PaymentsActionTypes {
  return {
    type: HIDE_ADD_WITHDRAW_FUNDS_MODAL
  }
}

export function verifyMicroDeposits (verifyMicroDepositsModel: VerifyMicroDepositsModel): PaymentsActionTypes {
  return {
    type: VERIFY_MICRO_DEPOSITS,
    payload: verifyMicroDepositsModel
  }
}

export function verifyMicroDepositsModalClose (): PaymentsActionTypes {
  return {
    type: VERIFY_MICRO_DEPOSITS_MODAL_CLOSE
  }
}

export function updateMicroDepositStatus (status: number): PaymentsActionTypes {
  return {
    type: UPDATE_MICRO_DEPOSIT_STATUS,
    payload: {
      status
    }
  }
}

export function resetMicroDepositStatus ():PaymentsActionTypes{
  return {
    type: RESET_MICRO_DEPOSIT_STATUS
  }
}

export function removeFundingSource (sourceId: number, bankName: string, name: string) : PaymentsActionTypes {
  return {
    type: REMOVE_FUNDING_SOURCE,
    payload: {
      sourceId,
      bankName,
      name
    }

  }
}

export function removeFundingSourceSuccess (): PaymentsActionTypes {
  return {
    type: REMOVE_FUNDING_SOURCE_SUCCESS
  }
}

export function removeFundingSourceFailure (errorModel: ErrorModel): PaymentsActionTypes {
  return {
    type: REMOVE_FUNDING_SOURCE_FAILURE,
    payload: errorModel
  }
}


export function updateFundingSourceAmount (amount: number | undefined, sourceId: number | undefined): PaymentsActionTypes{
  return {
    type: UPDATE_FUNDING_SOURCE_AMOUNT,
    payload: {
      amount,
      sourceId
    }
  }
}

export function updateFundingSourceAmountSuccess (amount: number):PaymentsActionTypes{
  return {
    type: UPDATE_FUNDING_SOURCE_AMOUNT_SUCCESS,
    payload: {
      amount
    }
  }
}

export function updateFundingSourceAmountError (errorModel: ErrorModel): PaymentsActionTypes {
  return {
    type: UPDATE_FUNDING_SOURCE_AMOUNT_ERROR,
    payload: errorModel
  }
}

export function updateWithdrawnAmount (amount: number | undefined, sourceId: number | undefined): PaymentsActionTypes{
  return {
    type: UPDATE_WITHDRAWN_AMOUNT,
    payload: {
      amount,
      sourceId
    }
  }
}

export function updateWithdrawnAmountSuccess (amount: number): PaymentsActionTypes{
  return {
    type: UPDATE_WITHDRAWN_AMOUNT_SUCCESS,
    payload: {
      amount
    }
  }
}

export function updateWithdrawnAmountFailure (errorModel: ErrorModel): PaymentsActionTypes {
  return {
    type: UPDATE_WITHDRAWN_AMOUNT_FAILURE,
    payload: errorModel
  }
}
export function addFundingSource (): PaymentsActionTypes {
  return {
    type: ADD_FUNDING_SOURCE
  }
}

export function addFundingSourceSuccess (): PaymentsActionTypes {
  return {
    type: ADD_FUNDING_SOURCE_SUCCESS
  }
}

export function addFundingSourceFailure (errorModel: ErrorModel): PaymentsActionTypes {
  return {
    type: ADD_FUNDING_SOURCE_FAILURE,
    payload: errorModel
  }
}


export function fetchDwollaEnvironment (): PaymentsActionTypes {
  return {
    type: FETCH_DWOLLA_ENVIRONMENT
  }
}

export function fetchDwollaEnvironmentSuccess (response: string): PaymentsActionTypes {
  return {
    type: FETCH_DWOLLA_ENVIRONMENT_SUCCESS,
    payload: response
  }
}

export function fetchDwollaEnvironmentFailure (errorModel: ErrorModel): PaymentsActionTypes {
  return {
    type: FETCH_DWOLLA_ENVIRONMENT_FAILURE,
    payload: errorModel
  }
}

export function getPaymentAlertInfo () : PaymentsActionTypes {
  return {
    type: GET_PAYMENT_ALERT
  }
}
export function getPaymentAlertSuccess (response: any): PaymentsActionTypes {
  return {
    type: GET_PAYMENT_ALERT_SUCCESS,
    payload:response
  }
}
export function getPaymentAlertFailure (errorModel: ErrorModel): PaymentsActionTypes {
  return {
    type: GET_PAYMENT_ALERT_FAILURE,
    payload: errorModel
  }
}

export function initiateMicroDeposits (sourceId: number, isRetry?: boolean): PaymentsActionTypes {
  return {
    type: INITIATE_MICRO_DEPOSITS,
    payload: { sourceId, isRetry}
  }
}

export function initiateMicroDepositsSuccess (response: any): PaymentsActionTypes {
  return {
    type: INITIATE_MICRO_DEPOSITS_SUCCESS,
    payload: response
  }
}

export function initiateMicroDepositsFailure (errorModel: ErrorModel): PaymentsActionTypes {
  return {
    type: INITIATE_MICRO_DEPOSITS_FAILURE,
    payload: errorModel
  }
}

export function getFundingSourceToken (): PaymentsActionTypes {
  return {
    type: GET_FUNDING_SOURCE_TOKEN
  }
}

export function getFundingSourceTokenSuccess (token: string): PaymentsActionTypes {
  return {
    type: GET_FUNDING_SOURCE_TOKEN_SUCCESS,
    payload: token
  }
}

export function getFundingSourceTokenFailure (errorModel: ErrorModel): PaymentsActionTypes {
  return {
    type: GET_FUNDING_SOURCE_TOKEN_FAILURE,
    payload: errorModel
  }
}

export function resetAddFundingSource (): PaymentsActionTypes {
  return {
    type: RESET_ADD_FUNDING_SOURCE
  }
}

export function dwollaAddFundingSourceSuccess (response: any): PaymentsActionTypes {
  return {
    type: DWOLLA_ADD_FUNDING_SOURCE_SUCCESS,
    payload: response
  }
}

export function dwollaAddFundingSourceFailure (errorModel: ErrorModel): PaymentsActionTypes {
  return {
    type: DWOLLA_ADD_FUNDING_SOURCE_FAILURE,
    payload: errorModel
  }
}
