import React, { useState } from 'react'
import { Modal } from 'semantic-ui-react';
import { SUPPORT_EMAIL, SUPPORT_PHONE, SUPPORT_EMAIL_LINK } from '../../../../store/applicationConstants';

import '../../../../themes/kalderos/Onboarding/Recipient/AuthorizingOfficial/HealthSystemInformationModal.scss';

const HealthSystemInformationModal: React.FunctionComponent = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  return (
    <Modal
      trigger={
        <button type="button" onClick={() => setOpen(true)} className="app-link health-system-info-modal-trigger">Tell me more about Health Systems.</button>
      }
      open={isOpen}
      onClose={() => setOpen(false)}
      className="app-modal"
    >
      <div className="health-system-info-modal">
        <div className="heading">
        Tell me more about Health Systems
        </div>
        <div className="description">
          Health Systems are a group of associated covered entities. 
          If your organization is a current Kalderos Review user, we&apos;ve imported your Health Systems for you.
        </div>
        <div className="description bolder">
          In Kalderos Request, Health Systems each have their own unique Account Admin and banking information.
        </div>
        <div className="description">
          If you&apos;d like to update or merge Health Systems after setup, please contact Kalderos support at <a href={SUPPORT_EMAIL_LINK}>{SUPPORT_EMAIL}</a> or {SUPPORT_PHONE}, anytime and we will work with you on how to proceed.
        </div>
        <div className="footer"><button className="app-button button-dark" type="button" onClick={() => setOpen(false)}>Close</button></div>
      </div>
    </Modal>
  )
}

export default HealthSystemInformationModal