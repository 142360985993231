import React, { Fragment } from 'react';
import cx from 'classnames';
import { REQUEST_CENTER_TAB, REQUEST_CENTER_BUCKET } from '../../store/requestcenter/constants';
import { RequestCenterTransactionCountsModel } from '../../store/requestcenter/types';

export interface RequestCenterBucketProps {
  bucket: REQUEST_CENTER_BUCKET,
  isActive: boolean,
  label: string,
  count: number,
  onClick: () => void;
}

export const RequestCenterBucket: React.FunctionComponent<RequestCenterBucketProps> = (props) => {
  const { bucket, isActive, label, count, onClick } = props;
  return (
    <button type='button' id='disReqRecipientBucket_Pendo' className={cx('bucket flex-column', { 'active': isActive }, { 'all': bucket === REQUEST_CENTER_BUCKET.ALL })} onClick={onClick}>
      <div className='bucket-title primary'>{label}</div>
      <div className='bucket-transaction-count primary'>{count}</div>
      <div className='bucket-text primary'>{count === 1 ? 'Transaction' : 'Transactions'}</div>
    </button>
  )
}

export interface RequestCenterBucketsProps {
  tab: REQUEST_CENTER_TAB,
  counts: RequestCenterTransactionCountsModel,
  selectedBucket: REQUEST_CENTER_BUCKET,
  onBucketClick: (bucket: REQUEST_CENTER_BUCKET) => void;
}

export const RequestCenterBuckets: React.FunctionComponent<RequestCenterBucketsProps> = (props) => {
  const { tab, counts, selectedBucket, onBucketClick } = props;

  return (
    <Fragment>
      {tab === REQUEST_CENTER_TAB.AWAITING &&
        <div className='buckets-container'>
          <RequestCenterBucket
            bucket={REQUEST_CENTER_BUCKET.ALL}
            isActive={selectedBucket === REQUEST_CENTER_BUCKET.ALL}
            label={'All'}
            count={counts.inMfrReviewCount + counts.submittedCount}
            onClick={() => onBucketClick(REQUEST_CENTER_BUCKET.ALL)}
          />
          <RequestCenterBucket
            bucket={REQUEST_CENTER_BUCKET.SUBMITTED}
            isActive={selectedBucket === REQUEST_CENTER_BUCKET.SUBMITTED}
            label={'Submitted'}
            count={counts.submittedCount}
            onClick={() => onBucketClick(REQUEST_CENTER_BUCKET.SUBMITTED)}
          />
          <RequestCenterBucket
            bucket={REQUEST_CENTER_BUCKET.IN_MFR_REVIEW}
            isActive={selectedBucket === REQUEST_CENTER_BUCKET.IN_MFR_REVIEW}
            label={'In Mfr. Review'}
            count={counts.inMfrReviewCount}
            onClick={() => onBucketClick(REQUEST_CENTER_BUCKET.IN_MFR_REVIEW)}
          />
          <div className='bucket flex-column empty_bucket'></div>
        </div>
      }
      {tab === REQUEST_CENTER_TAB.PROCESSED &&
        <div className='buckets-container'>
          <RequestCenterBucket
            bucket={REQUEST_CENTER_BUCKET.ALL}
            isActive={selectedBucket === REQUEST_CENTER_BUCKET.ALL}
            label={'All'}
            count={counts.rejectedCount + counts.approvedCount + counts.approvedAndAdjustedCount}
            onClick={() => onBucketClick(REQUEST_CENTER_BUCKET.ALL)}
          />
          <RequestCenterBucket
            bucket={REQUEST_CENTER_BUCKET.FAILED}
            isActive={selectedBucket === REQUEST_CENTER_BUCKET.FAILED}
            label={'Failed'}
            count={counts.rejectedCount}
            onClick={() => onBucketClick(REQUEST_CENTER_BUCKET.FAILED)}
          />
          <RequestCenterBucket
            bucket={REQUEST_CENTER_BUCKET.ADJUSTED_AND_PAID}
            isActive={selectedBucket === REQUEST_CENTER_BUCKET.ADJUSTED_AND_PAID}
            label={'Adjusted & Paid'}
            count={counts.approvedAndAdjustedCount}
            onClick={() => onBucketClick(REQUEST_CENTER_BUCKET.ADJUSTED_AND_PAID)}
          />
          <RequestCenterBucket
            bucket={REQUEST_CENTER_BUCKET.APPROVED_AND_PAID}
            isActive={selectedBucket === REQUEST_CENTER_BUCKET.APPROVED_AND_PAID}
            label={'Approved & Paid'}
            count={counts.approvedCount}
            onClick={() => onBucketClick(REQUEST_CENTER_BUCKET.APPROVED_AND_PAID)}
          />
        </div>
      }
    </Fragment>
  )
}

export default RequestCenterBuckets;
