import { Toast } from './types';
// default auto close time for toast
export const AUTO_CLOSE = 5000;

export const CREATE_TOAST = 'CREATE_TOAST'
export const SHOW_TOAST = 'SHOW_TOAST'
export const HIDE_TOAST = 'HIDE_TOAST'

export enum TOAST_TYPE {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
}

interface CreateToastAction {
  type: typeof CREATE_TOAST
  payload: {
    toast: Toast
  }
}

interface ShowToastAction {
  type: typeof SHOW_TOAST
  payload: Toast
}

interface HideToastAction {
  type: typeof HIDE_TOAST
  payload: Toast
}

export type ToastActionTypes = ShowToastAction | HideToastAction | CreateToastAction;
