import { VerifyMicroDepositsModel, FundingSourcesModel, PaymentAlertModel } from './types';
import { SortingFilter } from '../../appcommon/types';
import { ErrorModel } from '../../applicationTypes';

export const SHOW_ADD_FUNDS_MODAL = 'SHOW_ADD_FUNDS_MODAL';
export const SHOW_WITHDRAW_FUNDS_MODAL = 'SHOW_WITHDRAW_FUNDS_MODAL';
export const HIDE_ADD_WITHDRAW_FUNDS_MODAL = 'HIDE_ADD_WITHDRAW_FUNDS_MODAL';

export const UPDATE_WITHDRAWN_AMOUNT = 'UPDATE_WITHDRAWN_AMOUNT';
export const UPDATE_WITHDRAWN_AMOUNT_SUCCESS = 'UPDATE_WITHDRAWN_AMOUNT_SUCCESS';
export const UPDATE_WITHDRAWN_AMOUNT_FAILURE = 'UPDATE_WITHDRAWN_AMOUNT_FAILURE';

export const VERIFY_MICRO_DEPOSITS = 'VERIFY_MICRO_DEPOSITS';
export const UPDATE_MICRO_DEPOSIT_STATUS = 'UPDATE_MICRO_DEPOSIT_STATUS';
export const VERIFY_MICRO_DEPOSITS_MODAL_CLOSE = 'VERIFY_MICRO_DEPOSITS_MODAL_CLOSE';
export const RESET_MICRO_DEPOSIT_STATUS = 'RESET_MICRO_DEPOSIT_STATUS';

export const REMOVE_FUNDING_SOURCE = 'REMOVE_FUNDING_SOURCE';
export const REMOVE_FUNDING_SOURCE_SUCCESS = 'REMOVE_FUNDING_SOURCE_SUCCESS';
export const REMOVE_FUNDING_SOURCE_FAILURE = 'REMOVE_FUNDING_SOURCE_FAILURE';

export const ADD_FUNDING_SOURCE = 'ADD_FUNDING_SOURCE'
export const ADD_FUNDING_SOURCE_SUCCESS = 'ADD_FUNDING_SOURCE_SUCCESS'
export const ADD_FUNDING_SOURCE_FAILURE = 'ADD_FUNDING_SOURCE_FAILURE'

export const GET_FUNDING_SOURCE_TOKEN = 'GET_FUNDING_SOURCE_TOKEN'
export const GET_FUNDING_SOURCE_TOKEN_SUCCESS = 'GET_FUNDING_SOURCE_TOKEN_SUCCESS'
export const GET_FUNDING_SOURCE_TOKEN_FAILURE = 'GET_FUNDING_SOURCE_TOKEN_FAILURE'

export const INITIATE_MICRO_DEPOSITS = 'INITIATE_MICRO_DEPOSITS'
export const INITIATE_MICRO_DEPOSITS_SUCCESS = 'INITIATE_MICRO_DEPOSITS_SUCCESS'
export const INITIATE_MICRO_DEPOSITS_FAILURE = 'INITIATE_MICRO_DEPOSITS_FAILURE'

export const RESET_ADD_FUNDING_SOURCE = 'RESET_ADD_FUNDING_SOURCE'
export const DWOLLA_ADD_FUNDING_SOURCE_SUCCESS = 'DWOLLA_ADD_FUNDING_SOURCE_SUCCESS'
export const DWOLLA_ADD_FUNDING_SOURCE_FAILURE = 'DWOLLA_ADD_FUNDING_SOURCE_FAILURE'

export const UPDATE_FUNDING_SOURCE_AMOUNT = 'UPDATE_FUNDING_SOURCE_AMOUNT';
export const UPDATE_FUNDING_SOURCE_AMOUNT_SUCCESS = 'UPDATE_FUNDING_SOURCE_AMOUNT_SUCCESS';
export const UPDATE_FUNDING_SOURCE_AMOUNT_ERROR = 'UPDATE_FUNDING_SOURCE_AMOUNT_ERROR';

export const FIVE_HUNDRED_THOUSAND = 500000;
export const ONE_MILLION = 1000000;
export const FIVE_MILLION = 5000000;
export const TEN_MILLION = 10000000;

export const FETCH_DWOLLA_ENVIRONMENT = 'FETCH_DWOLLA_ENVIRONMENT';
export const FETCH_DWOLLA_ENVIRONMENT_SUCCESS = 'FETCH_DWOLLA_ENVIRONMENT_SUCCESS';
export const FETCH_DWOLLA_ENVIRONMENT_FAILURE = 'FETCH_DWOLLA_ENVIRONMENT_FAILURE';

export const GET_PAYMENT_SUMMARY = 'GET_PAYMENT_SUMMARY';
export const UPDATE_PAYMENT_SUMMARY = 'UPDATE_PAYMENT_SUMMARY';
export const UPDATE_PAYMENT_SUMMARY_ERROR = 'UPDATE_PAYMENT_SUMMARY_ERROR';

export const UPDATE_PAYMENTS_SUMMARY_SORT_FILTERS = 'UPDATE_PAYMENTS_SUMMARY_SORT_FILTERS'

export const PAYMENT_HISTORY_ERROR_HEADER = 'Error loading Payment History';
export const PAYMENT_HISTORY_ERROR_MESSAGE = 'We encountered an error while loading your payment history. Please refresh the page and try again.';

export const GET_PAYMENT_ALERT_SUCCESS='GET_PAYMENT_ALERT_SUCCESS'
export const GET_PAYMENT_ALERT='GET_PAYMENT_ALERT'
export const GET_PAYMENT_ALERT_FAILURE='GET_PAYMENT_ALERT_FAILURE'

interface GetPaymentSummary {
  type: typeof GET_PAYMENT_SUMMARY
}

interface GetPaymentsSummarySuccess {
  type: typeof UPDATE_PAYMENT_SUMMARY,
  payload: {
    dwollaBalance: number;
    fundingSources: FundingSourcesModel[],
    approvedFundingSources: FundingSourcesModel[]
  }
}

interface GetPaymentsSummaryError {
  type: typeof UPDATE_PAYMENT_SUMMARY_ERROR,
  payload: {
    errorStatus: number | null,
    errorAccount: any
  }
}

interface UpdatePaymentsSummarySortFilters {
  type: typeof UPDATE_PAYMENTS_SUMMARY_SORT_FILTERS,
  payload: SortingFilter
}

interface ShowAddFundsModal {
  type: typeof SHOW_ADD_FUNDS_MODAL
}

interface ShowWithdrawFundsModal {
  type: typeof SHOW_WITHDRAW_FUNDS_MODAL
}

interface HideAddWithdrawFundsModal {
  type: typeof HIDE_ADD_WITHDRAW_FUNDS_MODAL
}

interface VerifyMicroDeposits {
  type: typeof VERIFY_MICRO_DEPOSITS,
  payload: VerifyMicroDepositsModel
}


interface UpdateMicroDepositStatus {
  type: typeof UPDATE_MICRO_DEPOSIT_STATUS,
  payload: {
    status: number
  }
}

interface ResetMicroDepositStatus{
  type: typeof RESET_MICRO_DEPOSIT_STATUS
}

interface RemoveFundingSource {
  type: typeof REMOVE_FUNDING_SOURCE,
  payload: {
    sourceId: number,
    bankName: string,
    name: string
  }
}

interface RemoveFundingSourceSuccess {
  type: typeof REMOVE_FUNDING_SOURCE_SUCCESS
}

interface RemoveFundingSourceFailure {
  type: typeof REMOVE_FUNDING_SOURCE_FAILURE,
  payload: ErrorModel
}

interface AddFundingSourceAction {
  type: typeof ADD_FUNDING_SOURCE
}

interface AddFundingSourceSuccessAction {
  type: typeof ADD_FUNDING_SOURCE_SUCCESS
}

interface AddFundingSourceFailureAction {
  type: typeof ADD_FUNDING_SOURCE_FAILURE,
  payload: ErrorModel
}

interface UpdateFundingSourceAmount {
  type: typeof UPDATE_FUNDING_SOURCE_AMOUNT,
  payload: {
    amount: number | undefined,
    sourceId: number | undefined
  }
}

interface UpdateFundingSourceAmountSuccess{
  type: typeof UPDATE_FUNDING_SOURCE_AMOUNT_SUCCESS,
  payload: {
    amount: number
  }
}

interface UpdateFundingSourceAmountError {
  type: typeof UPDATE_FUNDING_SOURCE_AMOUNT_ERROR
  payload: ErrorModel
}

interface UpdateWithdrawnAmount {
  type: typeof UPDATE_WITHDRAWN_AMOUNT,
  payload: {
    amount: number | undefined,
    sourceId: number | undefined
  }
}

interface UpdateWithdrawnAmountSuccess {
  type: typeof UPDATE_WITHDRAWN_AMOUNT_SUCCESS
  payload:{
    amount: number
  }
}

interface UpdateWithdrawnAmountFailure {
  type: typeof UPDATE_WITHDRAWN_AMOUNT_FAILURE
  payload: ErrorModel
}

interface VerifyMicroDepositsModalClose {
  type: typeof VERIFY_MICRO_DEPOSITS_MODAL_CLOSE
}

interface fetchDwollaEnvironmentAction {
  type: typeof FETCH_DWOLLA_ENVIRONMENT
}

interface fetchDwollaEnvironmentSuccessAction {
  type: typeof FETCH_DWOLLA_ENVIRONMENT_SUCCESS,
  payload: string
}

interface fetchDwollaEnvironmentFailureAction {
  type: typeof FETCH_DWOLLA_ENVIRONMENT_FAILURE,
  payload: ErrorModel
}
interface InitiateMicroDepositsAction {
  type: typeof INITIATE_MICRO_DEPOSITS,
  payload: {
    sourceId: number,
    isRetry: boolean | undefined
  }
}

interface InitiateMicroDepositsSuccessAction {
  type: typeof INITIATE_MICRO_DEPOSITS_SUCCESS,
  payload: any
}

interface InitiateMicroDepositsFailureAction {
  type: typeof INITIATE_MICRO_DEPOSITS_FAILURE,
  payload: ErrorModel
}

interface GetFundingSourceTokenAction {
  type: typeof GET_FUNDING_SOURCE_TOKEN
}

interface GetFundingSourceTokenSuccessAction {
  type: typeof GET_FUNDING_SOURCE_TOKEN_SUCCESS,
  payload: any
}

interface GetFundingSourceTokenFailureAction {
  type: typeof GET_FUNDING_SOURCE_TOKEN_FAILURE,
  payload: ErrorModel
}

interface GetPaymentAlertAction{
  type : typeof GET_PAYMENT_ALERT,
}
interface ResetAddFundingSourceAction {
  type: typeof RESET_ADD_FUNDING_SOURCE
}

interface DwollaAddFundingSourceSuccessAction {
  type: typeof DWOLLA_ADD_FUNDING_SOURCE_SUCCESS,
  payload: any
}

interface DwollaAddFundingSourceFailureAction {
  type: typeof DWOLLA_ADD_FUNDING_SOURCE_FAILURE,
  payload: ErrorModel
}

interface GetPaymentAlertSuccessAction {
  type: typeof GET_PAYMENT_ALERT_SUCCESS,
  payload: PaymentAlertModel
}

interface GetPaymentAlertFailureAction{
  type: typeof GET_PAYMENT_ALERT_FAILURE,
  payload: ErrorModel
}

export type PaymentsActionTypes =
  ShowAddFundsModal |
  VerifyMicroDeposits |
  UpdateMicroDepositStatus |
  RemoveFundingSource |
  RemoveFundingSourceFailure |
  ShowWithdrawFundsModal |
  HideAddWithdrawFundsModal |
  UpdateFundingSourceAmount |
  UpdateFundingSourceAmountError |
  VerifyMicroDepositsModalClose |
  RemoveFundingSourceSuccess |
  AddFundingSourceAction |
  AddFundingSourceSuccessAction |
  AddFundingSourceFailureAction|
  fetchDwollaEnvironmentAction |
  fetchDwollaEnvironmentSuccessAction |
  fetchDwollaEnvironmentFailureAction |
  GetPaymentSummary |
  GetPaymentsSummarySuccess |
  GetPaymentsSummaryError |
  UpdatePaymentsSummarySortFilters |
  UpdateWithdrawnAmount |
  UpdateWithdrawnAmountSuccess |
  UpdateWithdrawnAmountFailure |
  UpdateFundingSourceAmountSuccess |
  ResetMicroDepositStatus |
  GetPaymentAlertSuccessAction |
  GetPaymentAlertFailureAction |
  GetPaymentAlertAction |
  InitiateMicroDepositsAction |
  InitiateMicroDepositsSuccessAction |
  InitiateMicroDepositsFailureAction |
  GetFundingSourceTokenAction |
  GetFundingSourceTokenSuccessAction |
  GetFundingSourceTokenFailureAction|
  ResetAddFundingSourceAction |
  DwollaAddFundingSourceSuccessAction |
  DwollaAddFundingSourceFailureAction;
