import React from 'react';
import { Menu } from 'semantic-ui-react';

import PageHeader from '../../../common/navigation/PageHeader';
import { ManufacturerSummaryModel } from '../../../../store/mdrp/disputescenter/types';
import { ManufacturerReviewBannerIcon } from '../../../../themes/kalderos/Common/Icons';

export interface ManufacturerReviewHeaderProps {
  activeIndex: number;
  isLoading: boolean;
  manufacturer: ManufacturerSummaryModel;
  onTabClick: (type: number) => void;
}

const ManufacturerReviewHeader = (props: ManufacturerReviewHeaderProps) => {
  const { activeIndex, isLoading, manufacturer, onTabClick } = props;

  return (
    <div className="mfr-review-header">
      <div className="banner-icon">
        <ManufacturerReviewBannerIcon />
      </div>
      <PageHeader backLink='/states/disputes/open' className="mfr-review-page-header" noBorder text={manufacturer.name} />
      <div className="mfr-review-summary">
        <div>Labelers: <span className="value">{manufacturer.labelerCount}</span></div>
        <div>Number of NDCs: <span className="value">{manufacturer.ndcCount}</span></div>
        <div>Total Claims: <span className="value">{manufacturer.totalClaims}</span></div>
      </div>
      <div className="tab-controller disputes-center-tabs">
        <Menu secondary pointing>
          <Menu.Item 
            active={activeIndex === 0}
            disabled={isLoading}
            link
            name='New Disputes'
            onClick={() => onTabClick(0)}
          />
          <Menu.Item 
            active={activeIndex === 1}
            disabled={isLoading}
            link
            name='Pending Manuf. Response'
            onClick={() => onTabClick(1)}
          >
            Pending Manuf. Response
          </Menu.Item>
          <Menu.Item
            active={activeIndex === 2}
            disabled={isLoading}
            link
            name='Impasse'
            onClick={() => onTabClick(2)}
          />
        </Menu>
      </div>
    </div>
  );
};

export default ManufacturerReviewHeader;
