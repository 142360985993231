import React, {Fragment} from 'react';

import ErrorImage from './errors/ErrorImage';
import { CLIENT_ERROR_HEADER, CLIENT_ERROR_MESSAGE } from '../../store/applicationConstants';
import ClientErrorImage from '../../themes/kalderos/static/media/errors/ClientError.svg';

interface FeatureFlagContainerProps {
  children: React.ReactNode;
  entitledFeatureFlags: string[];
  featureFlag: string;
  includeErrorMessage?: boolean;
}

const FeatureFlagContainer = ({children, entitledFeatureFlags, featureFlag, includeErrorMessage}: FeatureFlagContainerProps) => {
  if (!entitledFeatureFlags.includes(featureFlag)) {
    if (includeErrorMessage) {
      return (
        <ErrorImage
          header={CLIENT_ERROR_HEADER}
          image={ClientErrorImage}
          message={CLIENT_ERROR_MESSAGE}
        />
      )
    }

    return null;
  }

  return (
    <Fragment>
      {children}
    </Fragment>
  )
};

export default FeatureFlagContainer;