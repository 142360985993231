import React, { useState } from 'react'
import cx from 'classnames';
import moment from 'moment';
import { Moment } from 'moment-timezone';
import { AlertCircle } from 'react-feather';
import { Form, Popup } from 'semantic-ui-react'
import { SingleDatePicker } from 'react-dates';
import { ErrorMessage, FormikProps } from 'formik';
import { DiscountProgramFieldsModel } from '../../../store/requestcenter/types';
import { DISCOUNT_PER_UNIT_ENTER_DATA_TOOL_TIP_MESSAGE } from '../../../store/appcommon/constants';
import { RequestFormFieldTypes } from './RequestFormHelper';

interface RequestFromFieldProps {
  field: DiscountProgramFieldsModel;
  formProps: FormikProps<any>;
}

const RequestFormFieldField: React.FunctionComponent<RequestFromFieldProps> = ({ field, formProps }) => {

  const fieldName = field.name;
  const hasError = !!(formProps.errors[field.name] && formProps.touched[field.name]);

  const getDiscountPerUnitLabel = () => {
    return (
      <div className='discount-per-unit-field'>
        Discount/Unit (Optional)
        <Popup inverted trigger={< AlertCircle size={14} className='discount-per-unit-field-tooltip' />}
          position='top center'
          content={DISCOUNT_PER_UNIT_ENTER_DATA_TOOL_TIP_MESSAGE}
          size='tiny' />
      </div>
    )
  }

  if (RequestFormFieldTypes.text.includes(fieldName)) {
    return (
      <div key={fieldName} className='form-column'>
        <Form.Input
          type='text'
          name={fieldName}
          label={field.display}
          placeholder={field.display}
          value={formProps.values[fieldName]}
          onChange={formProps.handleChange}
          onBlur={formProps.handleBlur}
          error={hasError} />
        <ErrorMessage name={fieldName} />
      </div>
    )
  }

  if (RequestFormFieldTypes.number.includes(fieldName) || RequestFormFieldTypes.money.includes(fieldName)) {
    return (
      <div key={fieldName} className='form-column'>
        <Form.Field
          control='input'
          type="number"
          name={fieldName}
          label={fieldName === 'discountPerUnit' ? getDiscountPerUnitLabel() : field.display}
          placeholder={field.display}
          value={formProps.values[fieldName]}
          onChange={formProps.handleChange}
          onBlur={formProps.handleBlur}
          error={hasError} />
        <ErrorMessage name={fieldName} />
      </div>
    )
  }

  if (RequestFormFieldTypes.date.includes(fieldName)) {
    const [focusedInput, setFocusedInput] = useState<boolean>(false);

    const handleDate = async (date: Moment | null, name: string) => {
      const updatedDate = date ? new Date(date.toDate()) : null;
      await formProps.setFieldValue(name, updatedDate); // need to wait for this to finish before setting touched 
      formProps.setFieldTouched(name);
    }

    return (
      <div key={fieldName} className={cx('form-column', 'request-form-date-field', { 'has-error': hasError })}>
        <div className="field">
          <label>{field.display}</label>
          <SingleDatePicker
            id={fieldName}
            isOutsideRange={() => false} // seems like the best way to define "no range"
            numberOfMonths={1}
            noBorder
            required
            date={formProps.values[fieldName] ? moment(formProps.values[fieldName]) : null}
            enableOutsideDays={true}
            focused={focusedInput}
            onFocusChange={({ focused }) => setFocusedInput(focused)}
            onDateChange={(date) => handleDate(date, fieldName)}
          />
        </div>
        <ErrorMessage name={fieldName} />
      </div>
    )
  }

  return null;
}

export default RequestFormFieldField
