import * as React from 'react';
import { connect } from 'react-redux';
import { Select, DropdownProps } from 'semantic-ui-react';
import { RootState } from '../../store/RootState';
import { StateModel } from '../../store/appcommon/types';
import { getStateList } from '../../store/appcommon/actionCreators';

interface StateDropdownProps {
  stateList: StateModel[];
  getStateList: () => void;
  value?: boolean | number | string | (boolean | number | string)[]
  upward?: boolean;
  onChange?: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
}

export class StateDropdown extends React.Component<StateDropdownProps>{

  componentDidMount () {
    if (this.props.stateList.length === 0) {
      this.props.getStateList();
    }
  }

  render () {
    const opts = this.props.stateList.map((s, i) => ({
      key: i,
      text: s.code,
      value: s.code
    }));
    return (
      <Select fluid options={opts} defaultValue={this.props.value} search onChange={this.props.onChange} upward={this.props.upward} />
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  stateList: state.AppCommonState.stateList
});

const mapDispatchToProps = (dispatch: any) => ({
  getStateList: () => dispatch(getStateList()),
});


export default connect(mapStateToProps, mapDispatchToProps)(StateDropdown);