import React from 'react'
import { Table } from 'semantic-ui-react';
import { formatMMDDYYYY } from '../../utils/dateHelper';
import { formatUnits, formatDiscountPerUnit, formatDiscountAmount } from '../../utils/numberFormatHelper';
import { useState } from 'react';
import cx from 'classnames';
import SortIconSet from '../common/SortIconSet';
import { SortingFilter } from '../../store/appcommon/types';
import { formatEmpty, removeExcessCharacters } from '../../utils/dataUtil'
import { DiscountRequestModel } from '../../store/transactionscommon/types';
import Reversal from '../../themes/kalderos/static/media/reversal.svg';

export interface ReversalsTransactionsTableProps {
  transactions: DiscountRequestModel[];
  activeTransactionId?: number;
  canSortStatus?: boolean;
  handleCellSorting: (sort: SortingFilter) => void;
  handleRowClick: (transactionId: number) => void;
}

const ReversalsTransactionsTable: React.FunctionComponent<ReversalsTransactionsTableProps> = (props: ReversalsTransactionsTableProps) => {
  const { transactions, activeTransactionId, canSortStatus, handleCellSorting, handleRowClick } = props;

  const [isScrolledX, setIsScrolledX] = useState(false);

  const handleScroll = () => {
    const scrollVal = document.getElementById('dtWrapper')
    if (scrollVal && scrollVal.scrollLeft <= 0) {
      setIsScrolledX(false)
    } else if (scrollVal && scrollVal.scrollLeft > 0) {
      setIsScrolledX(true)
    }
  }

  return (
    <div id="dtWrapper" onScroll={() => handleScroll()} className="data-table-wrapper">

      <Table className="data-table recipient-transactions-table">
        <Table.Header>
          <Table.Row className="selectable-row">
            <Table.HeaderCell className={cx('eta header-cell sticky-column', { 'hidden': !isScrolledX })}>
              Status
              {canSortStatus && <SortIconSet field="statusTextSort" handleCellSorting={handleCellSorting} />}
            </Table.HeaderCell>
            {/* <Table.HeaderCell className="eta header-cell">
              Medical/Retail
              <SortIconSet field="claimTypeText" handleCellSorting={handleCellSorting} />
            </Table.HeaderCell> */}
            <Table.HeaderCell className="eta header-cell">
              Submitted Date
              <SortIconSet field="submittedDate" handleCellSorting={handleCellSorting} />
            </Table.HeaderCell>
            <Table.HeaderCell className="eta header-cell">
              340B ID
              <SortIconSet field="idCode" handleCellSorting={handleCellSorting} />
            </Table.HeaderCell>
            <Table.HeaderCell className="eta header-cell">
              Manufacturer
              <SortIconSet field="contractOwnerName" handleCellSorting={handleCellSorting} />
            </Table.HeaderCell>
            <Table.HeaderCell className="eta header-cell">
              NDC11
              <SortIconSet field="ndc11" handleCellSorting={handleCellSorting} />
            </Table.HeaderCell>
            <Table.HeaderCell className="eta header-cell">
              Product Name
              <SortIconSet field="ndcName" handleCellSorting={handleCellSorting} />
            </Table.HeaderCell>
            <Table.HeaderCell className="eta header-cell">
              Fill Date
              <SortIconSet field="fillDate" handleCellSorting={handleCellSorting} />
            </Table.HeaderCell>
            <Table.HeaderCell className="eta header-cell">
              Rx Number
              <SortIconSet field="rxNumber" handleCellSorting={handleCellSorting} />
            </Table.HeaderCell>
            {/* <Table.HeaderCell className="eta header-cell pharmacyNPI-header">
              <div className='pharmacy-facility-NPI'>
                Pharmacy NPI<br />(Facility NPI)
              </div>
              <SortIconSet field="pharmacyNpi" handleCellSorting={handleCellSorting} />
            </Table.HeaderCell> */}
            <Table.HeaderCell className="eta header-cell">
              Pharmacy NPI
              <SortIconSet field="pharmacyNpi" handleCellSorting={handleCellSorting} />
            </Table.HeaderCell>
            <Table.HeaderCell className="eta header-cell">
              Prescriber NPI
              <SortIconSet field="prescriberNpi" handleCellSorting={handleCellSorting} />
            </Table.HeaderCell>
            {/* <Table.HeaderCell className="eta header-cell">
              Member ID
              <SortIconSet field="memberId" handleCellSorting={handleCellSorting} />
            </Table.HeaderCell> */}
            <Table.HeaderCell className="eta header-cell">
              Units
              <SortIconSet field="currentUnits" handleCellSorting={handleCellSorting} />
            </Table.HeaderCell>
            <Table.HeaderCell className="eta header-cell">
              Discount/Unit
              <SortIconSet field="currentDiscountPerUnit" handleCellSorting={handleCellSorting} />
            </Table.HeaderCell>
            <Table.HeaderCell className="eta header-cell">
              Discount Amt
              <SortIconSet field="currentAmount" handleCellSorting={handleCellSorting} />
            </Table.HeaderCell>
            <Table.HeaderCell className="eta header-cell">
              Process Date
              <SortIconSet field="processDate" handleCellSorting={handleCellSorting} />
            </Table.HeaderCell>
            <Table.HeaderCell className="eta header-cell">
              Adjustment Code
              <SortIconSet field="adjustedReason" handleCellSorting={handleCellSorting} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {transactions.map((d) => {
            return (
              <Table.Row key={d.id} onClick={() => handleRowClick(d.id)}
                className="selectable-row disReqTableRow_Pendo"
                active={activeTransactionId === d.id}>

                <Table.Cell className={cx('eta sticky-column', { 'hidden': !isScrolledX })}>
                  {d.statusText === 'Submitted' && (
                    <span className="recipient-submitted-label">Submitted</span>
                  )}
                  {d.statusText === 'Paid' && !d.isAdjusted && (
                    <span className="recipient-paid-label">Paid</span>
                  )}
                  {d.statusText === 'Paid' && d.isAdjusted && (
                    <span><span className="recipient-paid-adjust-label">Paid</span><span className="recipient-adjust-paid-label">Adjusted</span></span>
                  )}
                  {d.statusText === 'Failed' && (
                    <span className="recipient-failed-label">Failed</span>
                  )}
                  {d.statusText === 'In Mfr Review' && (
                    <span className="recipient-in-review">In Mfr Review</span>
                  )}
                  {d.statusText === 'Processing' && (
                    <span className='recipient-processing-label'>Processing</span>
                  )}
                  {d.statusText === 'Pending Reversal' && <span className="recipient-reversal-processing">Pending Reversal</span>}
                  {d.statusText === 'Reversed' && <span className="recipient-reversed-transaction">Reversed</span>}
                  {d.statusText === 'Null Reversal' && <span className="recipient-reversed-failed-transaction">Null Reversal</span>}
                  {(d.reverseDiscountRequestId && (d.statusText === 'In Mfr Review' || d.statusText === 'Paid' || (d.statusText === 'Processing'))) && <span className='recipient-pending-reversal-indicator'><img alt='redo-icon' className='redo-icon' src={Reversal} /></span>}
                </Table.Cell>
                {/* <Table.Cell>{d.claimTypeText}</Table.Cell> */}
                <Table.Cell>{formatMMDDYYYY(d.submittedDate)}</Table.Cell>
                <Table.Cell>{d.idCode}</Table.Cell>
                <Table.Cell>{removeExcessCharacters(d.contractOwnerName, 25)}</Table.Cell>
                <Table.Cell>{d.ndc11}</Table.Cell>
                <Table.Cell>{d.ndcName}</Table.Cell>
                <Table.Cell>{formatMMDDYYYY(d.fillDate)}</Table.Cell>
                <Table.Cell>{formatEmpty(d.rxNumber)}</Table.Cell>
                <Table.Cell>{d.pharmacyNpi}</Table.Cell>
                <Table.Cell>{formatEmpty(d.prescriberNpi)}</Table.Cell>
                {/* <Table.Cell>{formatEmpty(d.memberId)}</Table.Cell> */}
                <Table.Cell className={d.isUnitsAdjusted ? 'recipient-amt-value-override' : 'recipient-amt-value'}>{formatUnits(d.currentUnits)}</Table.Cell>
                <Table.Cell className={d.isDiscountPerUnitAdjusted ? 'recipient-amt-value-override' : 'recipient-amt-value'}>{formatDiscountPerUnit(d.currentDiscountPerUnit)}</Table.Cell>
                <Table.Cell className={d.isAdjusted ? 'recipient-amt-value-override' : 'recipient-amt-value'}>{formatDiscountAmount(d.currentAmount)}</Table.Cell>
                <Table.Cell className={(d.statusText === 'Submitted' || d.statusText === 'In Mfr Review') && d.processDate ? 'recipient-date-value' : ''}> {(d.statusText === 'Submitted' || d.statusText === 'In Mfr Review') && d.processDate ? `Exp. ${formatMMDDYYYY(d.processDate)}` : formatMMDDYYYY(d.processDate)}</Table.Cell>
                <Table.Cell>{d.adjustedReason?.code ? d.adjustedReason.code : '- -'}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </div>
  )
}

export default ReversalsTransactionsTable;
