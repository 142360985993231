import {
  AppCommonActionTypes,
  GET_LEGAL_DOCS_SUCCESS,
  GET_STATE_LIST_SUCCESS,
  TOGGLE_LEFT_NAVIGATION,
  GET_340B_IDS,
  GET_340B_IDS_SUCCESS,
  GET_340B_IDS_FAILURE,
  GET_ADJUST_REASONS_SUCCESS,
} from './constants';
import { AppCommonState } from './types';

const initialState: AppCommonState = {
  navigationState: { isLeftNavExpanded: true },
  stateList: [],
  legalDocuments: {
    privacyPolicy: '',
    termsAndConditions: '',
    termsAndConditionsText: ''
  },
  coveredEntities: {
    list: [],
    isLoading: false,
    errorText: ''
  },
  adjustReasons: {
    adjustedReasons: [],
    failureReasons: [],
    autoAdjustedReasons: [],
    automationSummaryReasons: [],
    adjustedReasonsFilter: [],
    failureReasonsFilter: []
  },
}

export function appCommonReducer (
  state = initialState,
  action: AppCommonActionTypes
): AppCommonState {
  switch (action.type) {

    case TOGGLE_LEFT_NAVIGATION:
      return {
        ...state,
        navigationState: { isLeftNavExpanded: action.payload }
      }
    case GET_STATE_LIST_SUCCESS:
      return {
        ...state,
        stateList: action.payload
      }
    case GET_LEGAL_DOCS_SUCCESS:
      return {
        ...state,
        legalDocuments: action.payload
      }
    case GET_340B_IDS:
      return {
        ...state,
        coveredEntities: {
          ...state.coveredEntities,
          isLoading: true
        }
      }
    case GET_340B_IDS_SUCCESS:
      return {
        ...state,
        coveredEntities: {
          ...state.coveredEntities,
          isLoading: false,
          list: action.payload
        }
      }
    case GET_340B_IDS_FAILURE:
      return {
        ...state,
        coveredEntities: {
          ...state.coveredEntities,
          isLoading: false,
          errorText: action.payload.message
        }
      }
    case GET_ADJUST_REASONS_SUCCESS:
      return {
        ...state,
        adjustReasons: action.payload
      }
    default:
      return state
  }
}
