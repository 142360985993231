import React from 'react';
import { Redirect } from 'react-router';
import { isRecipient } from '../../utils/roleUtil';

const ManageUserRoles = ({ roles }: { roles: string[] }) => {
  if (roles.length === 0) return null;

  if (isRecipient(roles)) {
    return <Redirect to='/recipients/requestcenter' />
  } else {
    return <Redirect to='/logout' />
  }
}

export default ManageUserRoles;
