import React, { Fragment } from 'react';
import { Form, Dimmer, Loader } from 'semantic-ui-react';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Formik } from 'formik';
import * as yup from 'yup';

import { RootState } from '../../store/RootState';
import { LoginModel, UserErrorModel } from '../../store/login/types';
import { validateUser, clearUserDetails } from '../../store/login/actionCreators';
import { selectUserIsNewAO, selectUserRequiresOnboarding } from '../../store/onboarding/selectors';

import ManageUserRoles from './ManageUserRoles';
import ManageUserExpiry from './ManageUserExpiry';
import { RequestLogoIconLogin } from '../../themes/kalderos/Common/Icons';
import KalderosLogo from '../../themes/kalderos/static/media/Kalderos_Wordmark_GreyDarker.svg';
import { getEntitledHealthSystems } from '../../store/teammanagement/selectors';
import { HealthSystem } from '../../store/teammanagement/types';
import { getHealthSystemInfo, setPreSelectedHealthSystemId } from '../../store/teammanagement/actionCreators';
import { isAdminAllAccess, isAdminSupport } from '../../utils/roleUtil';

interface LoginProps extends RouteComponentProps {
  validateUser: (loginModel: LoginModel) => void;
  clearUserDetails: () => void;
  error: UserErrorModel;
  isAuthenticated?: boolean;
  roles: string[] | null;
  isLoading: boolean;
  onboardingStatusCallFinished: boolean;
  userIsNewAO: boolean;
  userRequiresOnboarding: boolean;
  selectedHealthSystemId: number;
  entitledHealthSystems: HealthSystem[];
  healthSystemsLoading: boolean;
  healthSystemCallFinished: boolean;
  getHealthSystemInfo: (healthSystemId: number, switchingHs: boolean) => void;
  setPreSelectedHealthSystemId: (preselectedHealthSystemId: number) => void;
}

interface LoginState {
  showPassword: boolean;
}

const initialValues: LoginModel = {
  userName: '',
  password: '',
}

const schema = yup.object().shape({
  userName: yup
    .string()
    .required()
    .max(100),
  password: yup
    .string()
    .required()
    .max(100)
})

export class Login extends React.Component<LoginProps, LoginState>{
  constructor (props: Readonly<LoginProps>) {
    super(props);
    this.state = {
      showPassword: false
    };
  }

  onAOClick () {
    this.props.history.push('/authorizingofficialsignup');
  }

  onSubmitBtnClick = (values: LoginModel) => {
    const searchParams = new URLSearchParams(this.props.location.search);
    if (searchParams.has('healthSystemId')){
      const healthSystemId = searchParams.get('healthSystemId');
      if (healthSystemId) {
        this.props.setPreSelectedHealthSystemId(parseInt(healthSystemId));
      }
    }
    this.props.validateUser(values);
  }

  render () {
    const {
      isAuthenticated,
      roles,
      onboardingStatusCallFinished,
      userIsNewAO,
      userRequiresOnboarding,
      healthSystemsLoading, 
      healthSystemCallFinished
    } = this.props;

    if (isAuthenticated && roles && roles.length > 0) {
      //if a DR user has multiple HS and hasn't selected one yet, just return a blank page for the HS Modal to appear over
      if (this.props.entitledHealthSystems && this.props.entitledHealthSystems.length > 1 && this.props.selectedHealthSystemId === 0) {
        return <Dimmer active inverted><Loader inverted content='Loading' /></Dimmer>;
      }

      if (healthSystemsLoading) {
        return <Dimmer active inverted><Loader inverted content='Loading' /></Dimmer>;
      } 
      
      if (userIsNewAO) {
        return <Redirect to='/onboarding' />        
      }

      if (healthSystemCallFinished && this.props.entitledHealthSystems.length === 0 && !isAdminAllAccess(roles) && !isAdminSupport(roles)) {
        return <Redirect to='/signuperror' />
      }

      if (!onboardingStatusCallFinished) {
        return <Dimmer active inverted><Loader inverted content='Loading' /></Dimmer>;
      }

      if (onboardingStatusCallFinished && userRequiresOnboarding) {
        return <Redirect to='/onboarding' />        
      }

      return <ManageUserRoles roles={roles} />
    }

    if (this.props.error && this.props.error.message === 'The submitted password has expired') {
      return <ManageUserExpiry message={this.props.error} />
    }

    return (
      <div className="login-page app-login bg-request">
        {this.props.isLoading && <Dimmer active inverted><Loader inverted content='Loading' /></Dimmer>}
        <div className="login-page-layout two-column">
          <div className="login-page-column-left">
            <div className="login-welcome-container">
              <img src={KalderosLogo} alt="Kalderos" className="logo" />
              <div className="welcome-header">
                We’ve launched <br />
                Kalderos Request
              </div>
              <Fragment>
                <div className="welcome-text">Kalderos Request enables providers to request drug discounts at the point of sale.</div>
                <div className="welcome-text">If you’re an Authorizing Official (AO) as listed with HRSA, please sign up below.</div>
                <button className="app-button ao-button" type="button" onClick={() => this.onAOClick()}>Sign Up for Kalderos Request</button>
              </Fragment>
            </div>
          </div>
          <div className="login-page-column-right">
            <div className="login-form-container">

              <Formik
                initialValues={initialValues}
                validateOnMount={true}
                validationSchema={schema}
                onSubmit={(values) => {
                  this.onSubmitBtnClick(values);
                }}>
                {props => (

                  <Form className="login-form" error onSubmit={props.handleSubmit}>
                    <div className="login-form-logo">
                      <RequestLogoIconLogin />
                    </div>
                    <div className="login-form-header">Sign in to Kalderos Request</div>
                    <div className="login-form-body">

                      <Form.Input
                        name="userName"
                        label="Email address"
                        type="email"
                        placeholder='Email address'
                        autoComplete="off"
                        value={props.values.userName}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />

                      <Form.Input
                        data-private
                        name="password"
                        label={
                          <div className="password-label">
                            <label>Password</label>
                            <button className={cx('app-button link-text', { 'hidden': !props.values.password })} type="button" onClick={() => this.setState({ showPassword: !this.state.showPassword })}>
                              {this.state.showPassword ? 'Hide' : 'Show'}
                            </button>
                          </div>
                        }
                        type={this.state.showPassword ? 'text' : 'password'}
                        placeholder='Password'
                        autoComplete="off"
                        value={props.values.password}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        className={cx({ 'input-error-field': this.props.error.isError })}
                      />

                      {this.props.error && this.props.error.isError && <div className="input-error-text">{this.props.error.message}</div>}
                    </div>
                    <div className="login-form-footer">
                      <button className="app-button cta" type="submit" disabled={!props.isValid || !props.dirty} >Sign In</button>
                      <Link className="link" to="/forgotpassword">
                        <button className="app-button link-text" type="button">Forgot your password?</button>
                      </Link>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  error: state.LoginState.error,
  isAuthenticated: state.LoginState.user.isAuthenticated,
  roles: state.LoginState.user.roles,
  isLoading: state.LoginState.isLoading,
  onboardingStatusCallFinished: state.OnboardingState.onboardingStatusModel.isLoaded,
  onboardingStep: state.OnboardingState.onboardingStatusModel.currentStep,
  userIsNewAO: selectUserIsNewAO(state),
  userRequiresOnboarding: selectUserRequiresOnboarding(state),
  selectedHealthSystemId: state.TeamManagementState.teamManagementModel.selectedHealthSystemId,
  entitledHealthSystems: getEntitledHealthSystems(state),
  healthSystemsLoading: state.TeamManagementState.teamManagementModel.isLoading,
  healthSystemCallFinished: state.TeamManagementState.teamManagementModel.isLoaded,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    getHealthSystemInfo: (healthSystemId: number, switchingHS: boolean) => dispatch(getHealthSystemInfo(healthSystemId, switchingHS)),
    validateUser: (loginModel: LoginModel) => dispatch(validateUser(loginModel)),
    clearUserDetails: () => dispatch(clearUserDetails()),
    setPreSelectedHealthSystemId: (preselectedHealthSystemId: number) => dispatch(setPreSelectedHealthSystemId(preselectedHealthSystemId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
