import RequestConfig from '../../app.config';
import { LoginModel, UserErrorModel } from '../../store/login/types';
import fetchWrapper from '../FetchWrapper';

export const validateUser = async (loginModel: LoginModel) => {
  try {

    const response = await fetchWrapper(`${await RequestConfig()}/request/api/access/login`, false, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(loginModel)
    });

    if (response.ok) {
      const data = await response.json();
      return data.token;
    }

    const errorResponse = await response.json();
    const errorModel: UserErrorModel = {
      isError: true,
      status: response.status,
      message: errorResponse.Message || response.statusText,
    }
    return errorModel;
  } catch (e) {
    //return error response model
  }
};
