import { DisputeCode } from '../store/applicationTypes';

export const removeExcessCharacters = (value: string, maxCharacters: number) => {
  if (value.length > maxCharacters) {
    return `${value.slice(0, maxCharacters)}...`;
  } else {
    return value;
  }
}

export const formatEmpty = (value: any, placeHolder = '- -') => {
  return value ? value : placeHolder;
}

export const formatRebateQuarter = (value: any, placeHolder = '- -') => {
  return value && value.length === 7 ? `${value.slice(4)} ${value.slice(0, 4)}` : placeHolder;
}

export const formatRebateQuarterDropdown = (value: string) => {
  return `Q${value.slice(4)} ${value.slice(0, 4)}`;
}

export const formatDisputeCodes = (disputes: DisputeCode[], placeHolder = '- -') => {
  const disputeCode = disputes.map((dispute) => dispute.code);
  return disputes.length ? disputeCode.join(', ') : placeHolder;
}

export const convertStringOrUndefinedToString = (input: string | undefined) => (input ? input : '');
export const convertStringOrUndefinedToNumber = (input: string | undefined) => (input ? parseInt(input) : NaN);
