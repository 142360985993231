import React from 'react';
import { useState } from 'react';
import { DISCOUNT_PER_UNIT_GPO_TOOL_TIP_MESSAGE, DISCOUNT_PER_UNIT_TOOL_TIP_MESSAGE } from '../../../store/appcommon/constants';
import { formatUnits, formatDiscountPerUnit, formatDiscountAmount } from '../../../utils/numberFormatHelper';
import { formatMMDDYYYY } from '../../../utils/dateHelper';
import { Divider, Accordion, Icon, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import TransactionLabel from './TransactionLabel';
import { formatEmpty } from '../../../utils/dataUtil';
import { Item } from '../drawer/components/Item';
import SecondaryHeader from '../drawer/components/SecondaryHeader';
import Footer from '../drawer/components/Footer';
import { ArrowRight } from 'react-feather';
import { DiscountRequestModel } from '../../../store/transactionscommon/types';
import { TRANSACTION_STATUS_TEXT } from '../../../store/transactionscommon/constants';

const TransactionDetails = ({ transaction, additionalFieldsExist, isDoubleDrawer }: { transaction: DiscountRequestModel, additionalFieldsExist: boolean, isDoubleDrawer: boolean }) => {
  const [isAddOpen, setAddOpen] = useState(false);
  const handleAddClick = () => {
    if (isAddOpen) {
      setAddOpen(false);
    } else {
      setAddOpen(true);
    }
  }

  const renderDiscountAmount = (transaction: DiscountRequestModel) => {
    const discountAmountText = transaction.isGPO ? 'DISCOUNT AMOUNT\n(GPO OVERRIDE)' : 'DISCOUNT AMOUNT';
    const attachedDiscountRequestOriginalCurrentAmount = transaction.originalDiscountRequestId ? transaction.currentAmount * -1 : null;
    return (           
      <Item label={discountAmountText} value={formatDiscountAmount(isDoubleDrawer ? attachedDiscountRequestOriginalCurrentAmount : transaction.currentAmount)} />
    );
  }

  const renderAmountsSection = (transaction: DiscountRequestModel, isDoubleDrawer: boolean) => {
    if (!transaction.isAdjusted) {
      const attachedDiscountRequestOriginalCurrentUnits = transaction.originalDiscountRequestId ? transaction.currentUnits * -1 : null;
      return (
        <div className='flex-row justify-space-between'>
          <div className='flex-column'>
            <Item label='UNITS' value={formatUnits(isDoubleDrawer ? attachedDiscountRequestOriginalCurrentUnits : transaction.currentUnits)} />
            {
              transaction.isGPO &&
              <Popup inverted 
                trigger={
                  <span style={{display: 'inline-block'}}>
                    <div className='discount-per-unit-hover'>
                      <Item label={'DISCOUNT/UNIT\n(GPO OVERRIDE)'} value={formatDiscountPerUnit(transaction.currentDiscountPerUnit)} />
                    </div>
                  </span>
                }
                hoverable
                position='top center'
                content={DISCOUNT_PER_UNIT_GPO_TOOL_TIP_MESSAGE}
                size='tiny' />
            }
            {
              !transaction.userSuppliedDiscountPerUnit && !transaction.isGPO &&
              <Popup inverted trigger={
                <span style={{display: 'inline-block'}}>
                  <div className='discount-per-unit-hover'>
                    <Item label={'DISCOUNT/UNIT\n(CALCULATED)'} value={formatDiscountPerUnit(transaction.currentDiscountPerUnit)} />
                  </div>
                </span>
              }
              hoverable
              position='top center'
              content={DISCOUNT_PER_UNIT_TOOL_TIP_MESSAGE}
              size='tiny' />
            }
            {
              transaction.userSuppliedDiscountPerUnit && !transaction.isGPO &&
              <Item label={'DISCOUNT/UNIT'} value={formatDiscountPerUnit(transaction.currentDiscountPerUnit)} />
            }
            {renderDiscountAmount(transaction)}
          </div>
        </div>
      );
    }

    const renderDiscountPerUnit = (transaction: any) => {
      if (transaction.isGPO) {
        return (
          <Popup inverted trigger={
            <div className='discount-per-unit-hover'>
              <Item label={adjustedGPODiscountPerUnit} value={formatDiscountPerUnit(transaction.currentDiscountPerUnit)} isAutoAdjusted={transaction.isDiscountPerUnitAdjusted} />
            </div>
          }
          hoverable
          position='top left'
          content={DISCOUNT_PER_UNIT_GPO_TOOL_TIP_MESSAGE}
          size='tiny' />
        );
      } else {
        return (
          <Item label={adjustedGPODiscountPerUnit} value={formatDiscountPerUnit(transaction.currentDiscountPerUnit)} isAutoAdjusted={transaction.isDiscountPerUnitAdjusted} />
        )
      }
    }

    const attachedDiscountRequestRequestedUnits = transaction.originalDiscountRequestId ? transaction.requestedUnits * -1 : null;
    const attachedDiscountRequestRequestedAmount = transaction.originalDiscountRequestId ? transaction.requestedAmount * -1 : null;
    const attachedDiscountRequestOriginalCurrentUnits = transaction.originalDiscountRequestId ? transaction.currentUnits * -1 : null;
    const attachedDiscountRequestOriginalCurrentAmount = transaction.originalDiscountRequestId ? transaction.currentAmount * -1 : null;

    const adjustedGPODOrigDiscountPerUnitCalculated = transaction.isGPO ? 'ORIG. DISCOUNT/UNIT\n(GPO OVERRIDE)' : 'ORIG. DISCOUNT/UNIT\n(CALCULATED)'
    const adjustedGPOOrigDiscountPerUnit = transaction.isGPO ? 'ORIG. DISCOUNT/UNIT\n(GPO OVERRIDE)' : 'ORIG. DISCOUNT/UNIT'
    const adjustedGPOOrigDiscountAmount = transaction.isGPO ? 'ORIG. DISCOUNT AMOUNT\n(GPO OVERRIDE)' : 'ORIG. DISCOUNT AMOUNT'

    const adjustedGPODiscountPerUnit = transaction.isGPO ? 'DISCOUNT/UNIT\n(GPO OVERRIDE)' : 'DISCOUNT/UNIT'
    const adjustedGPODiscountAmount = transaction.isGPO ? 'DISCOUNT AMOUNT\n(GPO OVERRIDE)' : 'DISCOUNT AMOUNT'

    return (
      <div>
        <div className='flex-row justify-space-between'>
          <Item label='UNITS' isAutoAdjusted={transaction.isUnitsAdjusted} value={formatUnits(isDoubleDrawer ? attachedDiscountRequestOriginalCurrentUnits : transaction.currentUnits)} />
          {
            transaction.isUnitsAdjusted && 
            <Item label='ORIG. UNITS' value={formatUnits(isDoubleDrawer ? attachedDiscountRequestRequestedUnits : transaction.requestedUnits)}/>
          }
        </div>
        <div className='flex-row'>
          {
            transaction.adjustedReason &&
            <div className='recipient-transaction-drawer-adjustment-container-outside'>
              <div className='recipient-transaction-drawer-adjustment-container'>
                <div className='zeta recipient-transaction-drawer-adjustment-text'>{transaction.adjustedReason.code}</div>
                <div className='eta recipient-transaction-drawer-adjustment-text-secondary'>{transaction.adjustedReason.message}</div>
              </div>
            </div>
          }
        </div>
        <div className='flex-row justify-space-between'>
          {renderDiscountPerUnit(transaction)}
          {
            transaction.isDiscountPerUnitAdjusted && !transaction.userSuppliedDiscountPerUnit &&
            <span style={{display: 'inline-block'}}>
              <Item label={adjustedGPODOrigDiscountPerUnitCalculated} value={formatDiscountPerUnit(transaction.requestedDiscountPerUnit)} />
            </span>
          }
          {
            transaction.isDiscountPerUnitAdjusted && transaction.userSuppliedDiscountPerUnit &&
            <Item label={adjustedGPOOrigDiscountPerUnit} value={formatDiscountPerUnit(transaction.requestedDiscountPerUnit)} />
          }
        </div>
        <div className='flex-row'>
          {
            transaction.isDiscountPerUnitAdjusted &&
            <div className='recipient-transaction-drawer-adjustment-container-outside'>
              <div className='recipient-transaction-drawer-adjustment-container'>
                <div className='zeta recipient-transaction-drawer-adjustment-text'>{'70-DPU'}</div>
                <div className='eta recipient-transaction-drawer-adjustment-text-secondary'>{'Discount/unit adjusted per contract or program pricing.'}</div>
              </div>
            </div>
          }
        </div>
        <div className='flex-row justify-space-between'>
          <Item label={adjustedGPODiscountAmount} isAutoAdjusted={transaction.isAdjusted} value={formatDiscountAmount(isDoubleDrawer ? attachedDiscountRequestOriginalCurrentAmount : transaction.currentAmount)} />
          {
            transaction.isAdjusted && 
            <Item label={adjustedGPOOrigDiscountAmount} value={formatDiscountAmount(isDoubleDrawer ? attachedDiscountRequestRequestedAmount : transaction.requestedAmount)} />
          }
        </div>
      </div>
    );
  }

  const statusText = isDoubleDrawer ? transaction.attachedDiscountRequestStatusText : transaction.statusText;
  const retail = transaction.claimTypeId === 'Retail'
  return (
    <div>
      {
        (statusText === TRANSACTION_STATUS_TEXT.SUBMITTED || statusText === TRANSACTION_STATUS_TEXT.IN_MFR_REVIEW || statusText === TRANSACTION_STATUS_TEXT.FAILED || statusText === TRANSACTION_STATUS_TEXT.REVIEW) &&
        <div className='flex-row'>
          <Item label={statusText === TRANSACTION_STATUS_TEXT.FAILED ? 'PROCESS DATE' : 'EXP. PROCESS DATE'} value={formatMMDDYYYY(isDoubleDrawer ? transaction.attachedDiscountRequestProcessDate : transaction.processDate)} />
        </div>
      }
      {
        statusText === TRANSACTION_STATUS_TEXT.PROCESSING &&
        <div className='flex-row justify-space-between'>
          <Item label={'PROCESS DATE'} value={formatMMDDYYYY(isDoubleDrawer ? transaction.attachedDiscountRequestProcessDate : transaction.processDate)} />
          <Item label={'EXP. REMITTANCE DATE'} value={formatMMDDYYYY(isDoubleDrawer ? transaction.attachedDiscountRequestRemittanceDate : transaction.remittanceDate)} />
        </div>
      }
      {
        statusText === TRANSACTION_STATUS_TEXT.PAID &&
        <div className='flex-row justify-space-between'>
          <Item label={'PAID DATE'} value={formatMMDDYYYY(isDoubleDrawer ? transaction.attachedDiscountRequestRemittanceDate : transaction.remittanceDate)} />
          <Item label={'PROCESS DATE'} value={formatMMDDYYYY(isDoubleDrawer ? transaction.attachedDiscountRequestProcessDate : transaction.processDate)} />
        </div>
      }
      <div className='flex-row justify-space-between'>
        <Item label='SUBMITTED DATE' value={formatMMDDYYYY(isDoubleDrawer ? transaction.attachedDiscountRequestSubmittedDate : transaction.submittedDate)} />
        <Item label='MANUFACTURER' value={transaction.contractOwnerName} />
      </div>
      <div className='flex-row justify-space-between'>
        <Item label='340B ID' value={transaction.idCode} />
        <Item label='340B NAME' value={transaction.businessName} />
      </div>
      <div className='flex-row justify-space-between'>
        <Item label='NDC11' value={transaction.ndc11} />
        <Item label='FILL DATE' value={formatMMDDYYYY(transaction.fillDate)} />
      </div>
      <div className='flex-row justify-space-between'>
        {retail ?
          <React.Fragment>
            <Item label='PHARMACY NPI' value={transaction.pharmacyNpi} /> 
            <Item label='PHARMACY NAME' value={formatEmpty(transaction.pharmacyName)} />
          </React.Fragment> :
          <React.Fragment>
            <Item label='FACILITY NPI' value={transaction.pharmacyNpi} />
            <Item label='FACILITY NAME' value={formatEmpty(transaction.pharmacyName)} />
          </React.Fragment>
        }
      </div>
      <div className='flex-row justify-space-between'>
        {retail ?
          <React.Fragment>
            <Item label='PRESCRIBER NPI' value={transaction.prescriberNpi} /> 
            <Item label='RX NUMBER' value={transaction.rxNumber} />
          </React.Fragment> :
          <React.Fragment>
            <Item label='PRESCRIBER NPI' value={transaction.prescriberNpi} /> 
            <Item label='PAYER ID/ PAYER CODE' value={formatEmpty(transaction.payerCode)} />
          </React.Fragment>
        }
      </div>
      {!retail && 
        <div className='flex-row justify-space-between'>
          <Item label='MEMBER ID' value={transaction.memberId} /> 
          <Item label='PLAN NAME' value={formatEmpty(transaction.planName)} />
        </div>
      }
      {renderAmountsSection(transaction, isDoubleDrawer)}
      {
        additionalFieldsExist &&
        <Accordion>
          <Accordion.Title active={isAddOpen} onClick={handleAddClick}>
            <div className='epsilon'>{isAddOpen ? 'View Less' : 'View More'} <Icon name={isAddOpen ? 'angle up' : 'angle down'} /></div>
          </Accordion.Title>
          <Accordion.Content active={isAddOpen}>
            <div className='additional-row'>
              {
                transaction.additionalFields.map((fld) =>
                  <Item key={fld.name} label={fld.label} value={fld.formattedValue} />
                )
              }
            </div>
          </Accordion.Content>
        </Accordion>
      }
    </div>
  );
}

const ReversalTransactionDetails = ({ transaction, isDoubleDrawer }: { transaction: any, isDoubleDrawer: boolean }) => {
  return (
    <div className='flex-row justify-space-between'>
      <Item label={'REVERSAL\nSUBMITTED DATE'} value={formatMMDDYYYY(isDoubleDrawer ? transaction.attachedDiscountRequestSubmittedDate : transaction.submittedDate)} />
      <Item label={transaction.statusText === TRANSACTION_STATUS_TEXT.REVERSED ? 'REVERSAL\nPROCESS DATE' : 'EXP. REVERSAL\nPROCESS DATE'} value={formatMMDDYYYY(isDoubleDrawer ? transaction.attachedDiscountRequestProcessDate : transaction.processDate)} />
    </div>
  );
}

export const TransactionDrawerDetails = ({ transaction }: { transaction: any }) => {
  const additionalFieldsExist = transaction.additionalFields && transaction.additionalFields.length > 0;

  if (transaction.originalDiscountRequestId) {
    return <ReversalTransactionDetails transaction={transaction} isDoubleDrawer={false} />
  }

  return <TransactionDetails transaction={transaction} additionalFieldsExist={additionalFieldsExist} isDoubleDrawer={false} />
}

export const TransactionSecondaryDrawerDetails = ({ transaction }: { transaction: any }) => {
  const additionalFieldsExist = transaction.additionalFields && transaction.additionalFields.length > 0;

  if (transaction.originalDiscountRequestId) {
    return <TransactionDetails transaction={transaction} additionalFieldsExist={additionalFieldsExist} isDoubleDrawer={true} />
  }

  return <ReversalTransactionDetails transaction={transaction} isDoubleDrawer={true} />
}

export const TransactionSecondaryDrawer = ({ transaction }: { transaction: DiscountRequestModel }) => {
  const getReversalLinkPath = () => {
    let path = '';
    if (transaction.attachedDiscountRequestStatusText === TRANSACTION_STATUS_TEXT.PENDING_REVERSAL) {
      path = '/recipients/reversals/pending';
    } else {
      path = '/recipients/reversals/processed';
    }
    return path;
  }

  const getOriginalLinkPath = () => {
    let path = '';
    if (transaction.attachedDiscountRequestStatusText === TRANSACTION_STATUS_TEXT.REVIEW) {
      path = '/recipients/requestcenter/awaiting';
    } else if (transaction.attachedDiscountRequestStatusText === TRANSACTION_STATUS_TEXT.PROCESSING) {
      path = '/recipients/requestcenter/processing';
    } else if (transaction.attachedDiscountRequestStatusText === TRANSACTION_STATUS_TEXT.PAID || transaction.attachedDiscountRequestStatusText === TRANSACTION_STATUS_TEXT.FAILED) {
      path = '/recipients/requestcenter/processed';
    }
    return path;
  }

  const renderRecipientTransactionlabel = () => {
    const statusText = transaction.attachedDiscountRequestStatusText;
    if (!transaction.reverseDiscountRequestId && (statusText === TRANSACTION_STATUS_TEXT.PENDING_REVERSAL || statusText === TRANSACTION_STATUS_TEXT.REVERSED || statusText === TRANSACTION_STATUS_TEXT.NULL_REVERSAL)) {
      return null;
    }

    return <TransactionLabel statusText={transaction.attachedDiscountRequestStatusText} isAdjusted={transaction.isAdjusted} />;
  }

  return (
    <div className="drawer-layout">
      <div className="drawer-header-with-body">
        <SecondaryHeader
          header={transaction.ndcName}
          subHeader="PRODUCT NAME"
          headerLabel={renderRecipientTransactionlabel()}
          
        />
        <div className="drawer-body">
          <TransactionSecondaryDrawerDetails transaction={transaction} />
          <Divider />
          <div>
            <div className='flex-row justify-space-between'>
              <Item label={transaction.reverseDiscountRequestId ? 'REVERSAL\nTRANSACTION ID' : 'TRANSACTION ID'} value={transaction.reverseDiscountRequestId ?? transaction.originalDiscountRequestId } />
              <Item label={transaction.reverseDiscountRequestId ? 'REVERSAL\nSUBMITTED BY' : 'SUBMITTED BY'} value={transaction.attachedDiscountRequestCreatedBy} />
            </div>
          </div>
        </div>
      </div>
      <Footer>
        {
          transaction.originalDiscountRequestId && transaction.attachedDiscountRequestStatusText !== TRANSACTION_STATUS_TEXT.SUBMITTED && transaction.attachedDiscountRequestStatusText !== TRANSACTION_STATUS_TEXT.REVERSED &&
        <Link to={getOriginalLinkPath} className="request-link goToOriginalRequest_Pendo">
          Go to Original Request<ArrowRight size={16} />
        </Link>
        }
        {
          transaction.reverseDiscountRequestId &&
      <Link to={getReversalLinkPath} className='request-link goToReversal_Pendo'>
        Go to Reversal<ArrowRight size={16} />
      </Link>
        }
      </Footer>

    </div>);
}