import React from 'react';

import '../../themes/kalderos/Common/ScreenReaderMessage.scss';

export interface ScreenReaderMessageProps {
  children: React.ReactNode
}

const ScreenReaderMessage: React.FunctionComponent<ScreenReaderMessageProps> = ({ children }) => {
  return (
    <span className="screen-reader-message-contents">
      {children}
    </span>
  )
}

export default ScreenReaderMessage;