import React from 'react';
import { Mail } from 'react-feather';
import { RouteComponentProps } from 'react-router-dom';

import { SUPPORT_EMAIL } from '../../store/applicationConstants';

const SignUpError = ({ history }: RouteComponentProps) => {
  const handleOnClick = () => {
    history.push('/logout')
  };

  return (
    <div className="login-page">
      <div className="login-page-layout">
        <div className="login-page-column-right">
          <div className="login-form-container padding-small">
            <div className="login-form-header">Sign Up Error</div>
            <div className="login-form-body">
              <div className="message-box error-box">
                <Mail size={20} />
                <div className="message-box-text">This account is unable to sign in, since it is not assigned to a health system. If you think this was an error, please check with your account admin.</div>
              </div>
              <div>{`If you are still having issues, please contact Kalderos Support at ${SUPPORT_EMAIL} for assistance.`}</div>
            </div>
            <div>
              <button className="app-button cta" onClick={handleOnClick} type='button'>
                Return to Sign-In
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpError;
