import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Icon, Checkbox } from 'semantic-ui-react';
import { RootState } from '../../../store/RootState';
import { makeSelectIsAuthorizingOfficial, makeSelectIsLoading } from '../../../store/onboarding/selectors';
import Layout from '../Layout';
import { getAccountAdminSummary, completeAccountAdminConfirmInfoStep, getControllerStatus, updateOnboardingStep } from '../../../store/onboarding/actionCreators';
import { CoveredEntityInfoModel } from '../../../store/onboarding/types';
import { UserInfoModel } from '../../../store/applicationTypes';
import { AddressInfo } from '../AddressInfo';
import { ONBOARDING_STEPS } from '../../../store/onboarding/constants';

import '../../../themes/kalderos/Onboarding/Recipient/ConfirmCoveredEntity.scss';

interface ConfirmCoveredEntityProps {
  accountAdminInfo?: UserInfoModel;
  healthSystemName: string;
  associatedEntities: CoveredEntityInfoModel[];
  isLoading: boolean;
  errorText: string;
  isAuthorizingOfficial: boolean;
  getAccountAdminSummary: () => void;
  completeAccountAdminConfirmInfoStep: () => void;
  getControllerStatus: () => void;
  updateOnboardingStep: (step: number) => void;
}

interface ConfirmCoveredEntityState {
  confirmChecked: boolean,
}


export const CoveredEntityDetails = ({ model, defaultExpanded }: { model: CoveredEntityInfoModel, defaultExpanded: boolean }) => {

  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  return (
    <div className="covered-entity-details">
      <div className="ce-summary" onClick={() => setIsExpanded(!isExpanded)}>
        <Icon name={isExpanded ? 'caret up' : 'caret down'} className={isExpanded ? 'expanded' : 'collapsed'}></Icon>
        <div className="ce-id" data-private>{model.idCode}</div>
        <div className="ce-name" data-private>{model.name340B}</div>
      </div>
      {isExpanded &&
        <div className="ce-addresses">
          <div className="street-address">
            <div className="address-header">Street Address</div>
            <div data-private>{model.streetAddress?.address1}</div>
            <div data-private>{model.streetAddress?.address2}</div>
            <div data-private>{model.streetAddress?.city} {model.streetAddress?.state} {model.streetAddress?.zip}</div>
          </div>
        </div>
      }
    </div>
  )
}



class ConfirmCoveredEntity extends React.Component<ConfirmCoveredEntityProps, ConfirmCoveredEntityState>{
  constructor (props: ConfirmCoveredEntityProps) {
    super(props);
    this.state = {
      confirmChecked: false
    }
  }

  componentDidMount () {
    this.props.getAccountAdminSummary();
    this.props.getControllerStatus();
  }

  onNextClick = () => {
    this.props.completeAccountAdminConfirmInfoStep();
  }

  onBackClick = () => {
    this.props.updateOnboardingStep(ONBOARDING_STEPS.ACCOUNT_ADMIN_INFO);
  }

  onConfirmCheck = () => {
    this.setState({ confirmChecked: !this.state.confirmChecked });
  }

  render () {
    const { accountAdminInfo, isAuthorizingOfficial, healthSystemName } = this.props;
    const stepNumber = isAuthorizingOfficial ? 5 : 2;

    return (
      <Layout
        isLoading={this.props.isLoading}
        errorText={this.props.errorText}
        stepNumber={stepNumber}>
        {!this.props.isLoading &&
          <Fragment>
            <div className="onboarding-layout-header">
              <div className="title">{stepNumber}. Confirm your information</div>
              <div className="sub-title">
                Please review the information you provided and verify that it&apos;s correct before moving on to setting up payment for your company.
              </div>
            </div>
            <div className="onboarding-layout-children">
              <div className="onboarding-layout-body dr-account-admin-confirmation">
                <div className="account-admin-info">
                  <div className="section-header">Account Admin Information</div>
                  <div className="detail-field">
                    <div className="value" data-private>{accountAdminInfo?.firstName} {accountAdminInfo?.lastName}</div>
                    <div className="label">NAME</div>
                  </div>
                  <div className="detail-field">
                    <div className="value" data-private>{accountAdminInfo?.email}</div>
                    <div className="label">EMAIL</div>
                  </div>
                  <div className="detail-field">
                    <div className="value" data-private>{accountAdminInfo?.jobTitle}</div>
                    <div className="label">JOB TITLE</div>
                  </div>
                  <div className="detail-field multiple">
                    <div>
                      <div className="value" data-private>{accountAdminInfo?.phone}</div>
                      <div className="label">PHONE</div>
                    </div>
                    {accountAdminInfo?.phoneExtension &&
                      <div className="flex-grow">
                        <div className="value" data-private>{accountAdminInfo?.phoneExtension}</div>
                        <div className="label">EXT</div>
                      </div>
                    }
                  </div>
                  <AddressInfo address={accountAdminInfo?.address} label='OFFICE ADDRESS' />
                </div>
                <div className="associated-entities-info">
                  <div className="section-header">Health System &amp; Associated Covered Entities</div>
                  <div className='epsilon health-system-name'>{healthSystemName}</div>
                  <div className='eta hs-label'>HEALTH SYSTEM</div>
                  <div className="covered-entity-list">
                    {this.props.associatedEntities.map((associatedEntity) =>
                      <CoveredEntityDetails key={associatedEntity.idCode} model={associatedEntity} defaultExpanded={false} />
                    )}
                  </div>
                  <div className="confirm-check">
                    <div className="onboarding-checkbox">
                      <Checkbox data-private label="I hereby certify, to the best of my knowledge, that the information provided above is complete and correct."
                        checked={this.state.confirmChecked}
                        onChange={this.onConfirmCheck}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="onboarding-layout-footer">
              <div className="onboarding-layout-navigation">
                <button type="button" className="app-button" onClick={this.onBackClick}>
                  <Icon name="chevron left" size="large" />Back
                </button>
              </div>
              <div className="onboarding-layout-cta">
                <button className="app-button button-dark" disabled={!this.state.confirmChecked} onClick={this.onNextClick} type="button">Confirm and Next</button>
              </div>
            </div>
          </Fragment>
        }
      </Layout>
    )
  }
}


const mapStateToProps = (state: RootState) => ({
  accountAdminInfo: state.OnboardingState.accountAdminConfirmInfoStepModel.accountAdminInfo,
  associatedEntities: state.OnboardingState.accountAdminConfirmInfoStepModel.associatedEntities,
  isLoading: makeSelectIsLoading(state),
  errorText: state.OnboardingState.errorText,
  isAuthorizingOfficial: makeSelectIsAuthorizingOfficial(state),
  healthSystemName: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.healthSystemName,
});

const mapDispatchToProps = (dispatch: any) => ({
  getAccountAdminSummary: () => dispatch(getAccountAdminSummary()),
  completeAccountAdminConfirmInfoStep: () => dispatch(completeAccountAdminConfirmInfoStep()),
  getControllerStatus: () => dispatch(getControllerStatus()),
  updateOnboardingStep: (step: number) => dispatch(updateOnboardingStep(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmCoveredEntity)
