import React from 'react'
import cx from 'classnames';
import { Button, Icon } from 'semantic-ui-react';
import RequestConfig from '../../../app.config';

interface DropzoneProps {
  file: any
  setFile: (file: any) => void;
  hasSizeError: boolean;
  hasFileTypeError: boolean;
}

interface DropzoneState {
  hightlight: boolean;
  retailTemplateUrl: string;
  //medicalTemplateUrl: string;
}

class Dropzone extends React.Component<DropzoneProps, DropzoneState>{
  fileInputRef: React.RefObject<HTMLInputElement>;

  constructor (props: Readonly<DropzoneProps>) {
    super(props);
    this.state = {
      hightlight: false,
      retailTemplateUrl: '',
      //medicalTemplateUrl: ''
    };
    this.fileInputRef = React.createRef<HTMLInputElement>();
  }

  componentDidMount () {
    RequestConfig('discountRequestRetailTemplate')
      .then(url => this.setState({ retailTemplateUrl: url }));
    // RequestConfig('discountRequestMedicalTemplate')
    // .then(url => this.setState({ medicalTemplateUrl: url }));
  }

  openFileDialog = () => {
    this.fileInputRef.current!.click();
  }

  onFilesAdded = (evt: any) => {
    const files = evt.target.files;
    const array = this.fileListToArray(files);
    this.props.setFile(array[0]);
  }

  fileListToArray = (list: any) => {
    const array = [];
    for (let i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  onDragOver = (evt: any) => {
    evt.preventDefault()
    if (this.props.file) return;
    this.setState({ hightlight: true });
  }

  onDragLeave = () => {
    this.setState({ hightlight: false });
  }

  onDrop = (e: any) => {
    e.preventDefault();
    if (this.props.file) return;

    const files = e.dataTransfer.files;
    const array = this.fileListToArray(files);
    this.setState({ hightlight: false });
    this.props.setFile(array[0]);
  }

  clearFile = (e: any) => {
    e.preventDefault();
    this.fileInputRef.current!.value = '';
    this.props.setFile(null);
  }

  getTemplateURL = () => {

  }

  render () {
    const { file, hasSizeError, hasFileTypeError } = this.props;
    return (
      <div className='drop-zone-container'>
        <div className={cx('drop-zone', { 'Highlight': this.state.hightlight, 'has-error': hasFileTypeError || hasSizeError })}
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}>
          <input
            ref={this.fileInputRef}
            className="file-input"
            type="file"
            onChange={this.onFilesAdded}
            accept=".csv"
          />
          {!file ?
            <div className='empty-file flex-column epsilon'>
              <div>Drop your file here</div>
              <div className='or-divider'>or</div>
              <Button secondary onClick={this.openFileDialog}>Choose File</Button>
            </div>
            :
            <div className='file-info'>
              <span className='file-name eta'>{file.name}</span>
              <span className='file-size eta-regular'>{
                file.size <= 1048576
                  ? `${Math.ceil(file.size / 1024)} KB`
                  : `${Math.ceil(file.size / 1048576)} MB`
              }</span>
              <Icon name='times' className='cursor-pointer' onClick={this.clearFile} />
            </div>
          }
        </div>
        {
          !file &&
          <div className='instructions'>
            <div className='delta'>File type(s) accepted: .csv</div>
            <div className="epsilon download"><a href={this.state.retailTemplateUrl} target='_blank' rel='noopener noreferrer' className='disReqDownloadTemplate_Pendo'>Download Retail Template</a></div>
            {/* Temporarily hide Medical Template */}
            {/* <div className="epsilon download"><a href={this.state.medicalTemplateUrl} target='_blank' rel='noopener noreferrer'>Download Medical Template</a></div> */}
          </div>
        }
        {
          (hasFileTypeError || hasSizeError) &&
          <div className='instructions has-error'>
            {hasFileTypeError && <div>File type not supported. Supported file type(s): .csv</div>}
            {hasSizeError && <div>File size too large. Files cannot exceed 100MB.</div>}
          </div>
        }
      </div>

    )
  }
}

export default Dropzone;
