import {
  RecipientTeamManagementActionTypes,
  GET_HEALTH_SYSTEMS,
  GET_HEALTH_SYSTEMS_SUCCESS,
  GET_HEALTH_SYSTEM_INFO,
  GET_HEALTH_SYSTEM_INFO_SUCCESS,
  GET_HEALTH_SYSTEM_INFO_FAILED,
  GET_HEALTH_SYSTEMS_FAILED,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  ASSIGN_USER_CE,
  ASSIGN_USER_CE_SUCCESS,
  ASSIGN_USER_CE_FAILURE,
  ASSIGN_CE_USERS,
  ASSIGN_CE_USERS_SUCCESS,
  ASSIGN_CE_USERS_FAILURE,
  OPEN_ADD_USER_MODAL,
  CLOSE_ADD_USER_MODAL,
  OPEN_ASSIGN_CE_MODAL,
  CLOSE_ASSIGN_CE_MODAL,
  OPEN_EDIT_TEAM_MODAL,
  CLOSE_EDIT_TEAM_MODAL,
  OPEN_CANCEL_INVITE_MODAL,
  CLOSE_CANCEL_INVITE_MODAL,
  UPDATE_USER_ROLE,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_ERROR,
  CANCEL_INVITE,
  CANCEL_INVITE_SUCCESS,
  CANCEL_INVITE_FAILURE,
  GET_CE_LIST_FOR_HS_MODAL,
  GET_CE_LIST_FOR_HS_MODAL_ERROR,
  GET_CE_LIST_FOR_HS_MODAL_SUCCESS,
  OPEN_CHANGE_HEALTH_SYSTEM_MODAL,
  CLOSE_CHANGE_HEALTH_SYSTEM_MODAL,
  UPDATE_SELECTED_HEALTH_SYSTEM_INFO,
  OPEN_ONBOARDING_INVITE_MODAL,
  CLOSE_ONBOARDING_INVITE_MODAL,
  SEND_ONBOARDING_INVITE,
  SEND_ONBOARDING_INVITE_SUCCESS,
  SEND_ONBOARDING_INVITE_FAILURE,
  UPDATE_SELECTED_HEALTH_SYSTEM_INFO_FOR_ONBOARDING,
  UPDATE_SELECTED_HEALTH_SYSTEM_ONBOARDING_ROLES,
  UPDATE_CE_LEAD_CONTACT_SUCCESS,
  UPDATE_CE_LEAD_CONTACT,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  DISABLE_USER_SUCCESS,
  DISABLE_USER_FAILURE,
  DISABLE_USER,
  ENABLE_USER,
  ENABLE_USER_SUCCESS,
  ENABLE_USER_FAILURE,
  SET_PRESELECTED_HEALTH_SYSTEM_ID,
  CLEAR_HEALTH_SYSTEMS_ERROR,
  SET_MOCK_NEW_AO,
  OPEN_SEARCH_HEALTH_SYSTEMS_MODAL,
  CLOSE_SEARCH_HEALTH_SYSTEMS_MODAL,
  SEARCH_HEALTH_SYSTEMS,
  SEARCH_HEALTH_SYSTEMS_SUCCESS,
  SEARCH_HEALTH_SYSTEMS_FAILURE
} from './constants';
import { HealthSystemInfo, PendingUser, EditRoleModel, CoveredEntity, TeamMember, HealthSystemsResponse, HealthSystem, OnboardingUserInviteModel, HealthSystemSearchInput } from './types';
import { ErrorModel } from '../applicationTypes';

export function getHealthSystems (): RecipientTeamManagementActionTypes {
  return {
    type: GET_HEALTH_SYSTEMS
  }
}

export function getHealthSystemsSuccess (healthSystemsResponse: HealthSystemsResponse): RecipientTeamManagementActionTypes {
  return {
    type: GET_HEALTH_SYSTEMS_SUCCESS,
    payload: healthSystemsResponse
  }
}

export function getHealthSystemsFailed (errorModel: ErrorModel): RecipientTeamManagementActionTypes {
  return {
    type: GET_HEALTH_SYSTEMS_FAILED,
    payload: errorModel
  }
}

export function getHealthSystemInfo (healthSystemId: number, switchingHS?: boolean | undefined): RecipientTeamManagementActionTypes {
  return {
    type: GET_HEALTH_SYSTEM_INFO,
    payload: {
      healthSystemId,
      switchingHS
    }
  }
}

export function getHealthSystemInfoSuccess (healthSystemInfo: HealthSystemInfo): RecipientTeamManagementActionTypes {
  return {
    type: GET_HEALTH_SYSTEM_INFO_SUCCESS,
    payload: healthSystemInfo
  }
}

export function getHealthSystemInfoFailed (errorModel: ErrorModel): RecipientTeamManagementActionTypes {
  return {
    type: GET_HEALTH_SYSTEM_INFO_FAILED,
    payload: errorModel
  }
}

export function clearHealthSystemsError (): RecipientTeamManagementActionTypes {
  return {
    type: CLEAR_HEALTH_SYSTEMS_ERROR,
  }
}

export function updateSelectedHealthSystemInfoForOnboarding (selectedHealthSystem: HealthSystem): RecipientTeamManagementActionTypes {
  return {
    type: UPDATE_SELECTED_HEALTH_SYSTEM_INFO_FOR_ONBOARDING,
    payload: selectedHealthSystem
  }
}

export function updateSelectedHealthSystemInfo (selectedHealthSystem: HealthSystem): RecipientTeamManagementActionTypes {
  return {
    type: UPDATE_SELECTED_HEALTH_SYSTEM_INFO,
    payload: selectedHealthSystem
  }
}

export function updateSelectedHealthSystemOnboardingRoles (roles: string[]): RecipientTeamManagementActionTypes {
  return {
    type: UPDATE_SELECTED_HEALTH_SYSTEM_ONBOARDING_ROLES,
    payload: roles
  }
}

export function updateUserRole (editRoleModel: EditRoleModel): RecipientTeamManagementActionTypes {
  return {
    type: UPDATE_USER_ROLE,
    payload: editRoleModel
  }
}

export function updateUserRoleSuccess (): RecipientTeamManagementActionTypes {
  return {
    type: UPDATE_USER_ROLE_SUCCESS
  }
}

export function updateUserRoleError (errorModel: ErrorModel): RecipientTeamManagementActionTypes {
  return {
    type: UPDATE_USER_ROLE_ERROR,
    payload: errorModel
  }
}

export function updateUser (data: any): RecipientTeamManagementActionTypes {
  return {
    type: UPDATE_USER,
    payload: data
  }
}

export function updateUserSuccess (data: any): RecipientTeamManagementActionTypes {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: data
  }
}

export function updateUserError (errorModel: ErrorModel): RecipientTeamManagementActionTypes {
  return {
    type: UPDATE_USER_ERROR,
    payload: errorModel
  }
}

export function openAddUserModal (): RecipientTeamManagementActionTypes {
  return {
    type: OPEN_ADD_USER_MODAL
  }
}

export function closeAddUserModal (): RecipientTeamManagementActionTypes {
  return {
    type: CLOSE_ADD_USER_MODAL,
  }
}

export function openAssignCEModal (userId: number, ceIds: number[]): RecipientTeamManagementActionTypes {
  return {
    type: OPEN_ASSIGN_CE_MODAL,
    payload: {
      userId: userId,
      ceIds: ceIds
    }
  }
}

export function closeAssignCEModal (isCancel: boolean): RecipientTeamManagementActionTypes {
  return {
    type: CLOSE_ASSIGN_CE_MODAL,
    payload: isCancel
  }
}

export function openEditTeamModal (coveredEntity: CoveredEntity): RecipientTeamManagementActionTypes {
  return {
    type: OPEN_EDIT_TEAM_MODAL,
    payload: coveredEntity
  }
}

export function closeEditTeamModal (): RecipientTeamManagementActionTypes {
  return {
    type: CLOSE_EDIT_TEAM_MODAL,
  }
}

export function openCancelInviteModal (user: TeamMember): RecipientTeamManagementActionTypes {
  return {
    type: OPEN_CANCEL_INVITE_MODAL,
    payload: user
  }
}

export function closeCancelInviteModal (): RecipientTeamManagementActionTypes {
  return {
    type: CLOSE_CANCEL_INVITE_MODAL,
  }
}

export function addUser (email: string, role: string): RecipientTeamManagementActionTypes {
  return {
    type: ADD_USER,
    payload: {
      email: email,
      role: role
    }
  }
}

export function addUserSuccess (pendingUser: PendingUser): RecipientTeamManagementActionTypes {
  return {
    type: ADD_USER_SUCCESS,
    payload: pendingUser
  }
}

export function addUserFailure (errorModel: ErrorModel): RecipientTeamManagementActionTypes {
  return {
    type: ADD_USER_FAILURE,
    payload: errorModel
  }
}

export function assignUserCEs (userId: number, ceIds: number[]): RecipientTeamManagementActionTypes {
  return {
    type: ASSIGN_USER_CE,
    payload: {
      userId: userId,
      ceIds: ceIds
    }
  }
}

export function assignUserCEsSuccess (response: string): RecipientTeamManagementActionTypes {
  return {
    type: ASSIGN_USER_CE_SUCCESS,
    payload: response
  }
}

export function assignUserCEsFailure (errorModel: ErrorModel): RecipientTeamManagementActionTypes {
  return {
    type: ASSIGN_USER_CE_FAILURE,
    payload: errorModel
  }
}

export function assignCeUsers (ceId: number, userIds: number[]): RecipientTeamManagementActionTypes {
  return {
    type: ASSIGN_CE_USERS,
    payload: {
      ceId: ceId,
      userIds: userIds
    }
  }
}

export function assignCeUsersSuccess (response: string): RecipientTeamManagementActionTypes {
  return {
    type: ASSIGN_CE_USERS_SUCCESS,
    payload: response
  }
}

export function assignCeUsersFailure (errorModel: ErrorModel): RecipientTeamManagementActionTypes {
  return {
    type: ASSIGN_CE_USERS_FAILURE,
    payload: errorModel
  }
}

export function cancelInvite (userId: number, email: string): RecipientTeamManagementActionTypes {
  return {
    type: CANCEL_INVITE,
    payload: {
      userId: userId,
      email: email
    }
  }
}

export function cancelInviteSuccess (): RecipientTeamManagementActionTypes {
  return {
    type: CANCEL_INVITE_SUCCESS,
  }
}

export function cancelInviteFailure (errorModel: ErrorModel): RecipientTeamManagementActionTypes {
  return {
    type: CANCEL_INVITE_FAILURE,
    payload: errorModel
  }
}

export function getCEListForHSModal (healthSystemId: number): RecipientTeamManagementActionTypes {
  return {
    type: GET_CE_LIST_FOR_HS_MODAL,
    payload: healthSystemId
  }
}

export function getCEListForHSModalError (errorModel: ErrorModel): RecipientTeamManagementActionTypes {
  return {
    type: GET_CE_LIST_FOR_HS_MODAL_ERROR,
    payload: errorModel
  }
}

export function getCEListForHSModalSuccess (coveredEntities: CoveredEntity[]): RecipientTeamManagementActionTypes {
  return {
    type: GET_CE_LIST_FOR_HS_MODAL_SUCCESS,
    payload: {
      coveredEntities
    }
  }
}

export function openChangeHealthSystemsModal (isRequired: boolean): RecipientTeamManagementActionTypes {
  return {
    type: OPEN_CHANGE_HEALTH_SYSTEM_MODAL,
    payload: isRequired
  }
}

export function closeChangeHealthSystemsModal (): RecipientTeamManagementActionTypes {
  return {
    type: CLOSE_CHANGE_HEALTH_SYSTEM_MODAL,
  }
}


export function openSearchHealthSystemsModal (isRequired: boolean): RecipientTeamManagementActionTypes {
  return {
    type: OPEN_SEARCH_HEALTH_SYSTEMS_MODAL,
    payload: isRequired
  }
}

export function closeSearchHealthSystemsModal (): RecipientTeamManagementActionTypes {
  return {
    type: CLOSE_SEARCH_HEALTH_SYSTEMS_MODAL,
  }
}

export function searchHealthSystems (input: HealthSystemSearchInput): RecipientTeamManagementActionTypes {
  return {
    type: SEARCH_HEALTH_SYSTEMS,
    payload: input
  }
}

export function searchHealthSystemsSuccess (result: HealthSystem[]): RecipientTeamManagementActionTypes {
  return {
    type: SEARCH_HEALTH_SYSTEMS_SUCCESS,
    payload: result
  }
}

export function searchHealthSystemsFailure (error: ErrorModel): RecipientTeamManagementActionTypes {
  return {
    type: SEARCH_HEALTH_SYSTEMS_FAILURE,
    payload: error
  }
}

export function openOnboardingInviteModal (role: string): RecipientTeamManagementActionTypes {
  return {
    type: OPEN_ONBOARDING_INVITE_MODAL,
    payload: role
  }
}

export function closeOnboardingInviteModal (): RecipientTeamManagementActionTypes {
  return {
    type: CLOSE_ONBOARDING_INVITE_MODAL,
  }
}

export function sendOnboardingInvite (input: OnboardingUserInviteModel, role: string): RecipientTeamManagementActionTypes {
  return {
    type: SEND_ONBOARDING_INVITE,
    payload: {
      user: input,
      role: role
    }
  }
}

export function sendOnboardingInviteSuccess (): RecipientTeamManagementActionTypes {
  return {
    type: SEND_ONBOARDING_INVITE_SUCCESS
  }
}

export function sendOnboardingInviteFailure (errorModel: ErrorModel): RecipientTeamManagementActionTypes {
  return {
    type: SEND_ONBOARDING_INVITE_FAILURE,
    payload: errorModel
  }
}

export function updateLeadContact (userId: number, ceId: number, id340B: string): RecipientTeamManagementActionTypes {
  return {
    type: UPDATE_CE_LEAD_CONTACT,
    payload: {
      userId: userId,
      ceId: ceId,
      id340B: id340B
    }
  }
}

export function updateLeadContactSuccess (userId: number, ceId: number, id340B: string): RecipientTeamManagementActionTypes {
  return {
    type: UPDATE_CE_LEAD_CONTACT_SUCCESS,
    payload: {
      userId: userId,
      ceId: ceId,
      id340B: id340B
    }
  }
}

export function disableUser (data: any): RecipientTeamManagementActionTypes {
  return {
    type: DISABLE_USER,
    payload: data
  }
}

export function disableUserSuccess (id: number): RecipientTeamManagementActionTypes {
  return {
    type: DISABLE_USER_SUCCESS,
    payload: id
  }
}

export function disableUserFailure (message: string): RecipientTeamManagementActionTypes {
  return {
    type: DISABLE_USER_FAILURE,
    payload: message
  }
}

export function enableUser (data: any): RecipientTeamManagementActionTypes {
  return {
    type: ENABLE_USER,
    payload: data
  }
}

export function enableUserSuccess (id: number): RecipientTeamManagementActionTypes {
  return {
    type: ENABLE_USER_SUCCESS,
    payload: id
  }
}

export function enableUserFailure (message: string): RecipientTeamManagementActionTypes {
  return {
    type: ENABLE_USER_FAILURE,
    payload: message
  }
}

export function setPreSelectedHealthSystemId (healthSystemId: number): RecipientTeamManagementActionTypes {
  return {
    type: SET_PRESELECTED_HEALTH_SYSTEM_ID,
    payload: healthSystemId
  }
}

export function setMockNewAO (): RecipientTeamManagementActionTypes {
  return {
    type: SET_MOCK_NEW_AO
  }
}