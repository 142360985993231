import React from 'react';

import logo from '../../../themes/kalderos/static/media/Payment_Connected.svg';

const OnboardingComplete = () => {
  return (
    <div className="onboarding-layout-children">
      <div className="onboarding-layout-body payment-success">
        <img src={logo} alt="success" />
        <div className="details">
          Your user profile is all set up. You will soon be able to use Kalderos Request once the rest of your company’s account is set up.
        </div>
      </div>
    </div>
  );
};

export default OnboardingComplete;