import RequestConfig from '../../app.config';
import fetchWrapper from '../FetchWrapper';
import { commonResponseHandler, RESPONSE_TYPE } from '../../utils/responseUtils';

export const assignUserRequest = async (healthSystemId: number, coveredEntityId: number, userIds: number[]) => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/healthSystem/assignUsers`, true, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      healthSystemId: healthSystemId,
      coveredEntityId: coveredEntityId,
      userIds: userIds,
    })
  });
  return commonResponseHandler(response, RESPONSE_TYPE.TEXT);
};

export const updateLeadContact = async (coveredEntityId: number, userId: number) => {
  const params = new URLSearchParams({ ceID: coveredEntityId.toString(), userID: userId.toString() });
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/healthSystem/updateLeadContact?${params}`, true, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return commonResponseHandler(response);
};
