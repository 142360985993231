import { AcceptInviteInput, ValidateInviteInput } from './types';
import { UserModel, LoginModel, ValidateInviteResponse } from '../login/types';
import {
  PasswordTypes,
  VALIDATE_RESET_PASSWORD_TOKEN,
  VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  RESET_CHANGE_PASSWORD_STATUS,
  SEND_FORGOT_PASSWORD,
  SEND_FORGOT_PASSWORD_SUCCESS,
  SEND_FORGOT_PASSWORD_ERROR,
  RESET_FORGOT_PASSWORD,
  VALIDATE_INVITE,
  VALIDATE_INVITE_SUCCESS,
  VALIDATE_INVITE_FAILURE,
  ACCEPT_INVITE,
  ACCEPT_INVITE_SUCCESS,
  ACCEPT_INVITE_FAILURE,
} from './constants';
import { ErrorModel } from '../applicationTypes';

export function validateToken (token: string, healthSystemId: number | null): PasswordTypes {
  return {
    type: VALIDATE_RESET_PASSWORD_TOKEN,
    payload: {
      token: token,
      healthSystemId: healthSystemId
    }
  }
}

export function validateTokenSuccess (userModel: UserModel): PasswordTypes {
  return {
    type: VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS,
    payload: userModel
  }
}

export function resetPassword (loginModel: LoginModel): PasswordTypes {
  return {
    type: RESET_PASSWORD,
    payload: loginModel
  }
}

export function resetPasswordSuccess (): PasswordTypes {
  return {
    type: RESET_PASSWORD_SUCCESS,
  }
}

export function resetPasswordError (errorModel: ErrorModel): PasswordTypes {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: errorModel
  }
}

export function changePassword (changePasswordModel: any): PasswordTypes {
  return {
    type: CHANGE_PASSWORD,
    payload: changePasswordModel
  }
}

export function changePasswordError (errorModel: ErrorModel): PasswordTypes {
  return {
    type: CHANGE_PASSWORD_ERROR,
    payload: errorModel
  }
}

export function changePasswordSuccess (): PasswordTypes {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
  }
}

export function resetChangePasswordStatus (): PasswordTypes {
  return {
    type: RESET_CHANGE_PASSWORD_STATUS
  }
}

export function sendRequestForResetPassword (email: string): PasswordTypes{
  return {
    type: SEND_FORGOT_PASSWORD,
    payload: email
  }
}

export function sendRequestForResetPasswordSuccess (): PasswordTypes{
  return {
    type: SEND_FORGOT_PASSWORD_SUCCESS,
  }
}

export function sendRequestForResetPasswordError (errorModel: ErrorModel): PasswordTypes{
  return {
    type: SEND_FORGOT_PASSWORD_ERROR,
    payload: errorModel
  }
}

export function resetForgotPassword (): PasswordTypes{
  return {
    type: RESET_FORGOT_PASSWORD,
  }
}

export function validateInvite (input: ValidateInviteInput): PasswordTypes {
  return {
    type: VALIDATE_INVITE,
    payload: input
  }
}

export function validateInviteSuccess (response: ValidateInviteResponse): PasswordTypes {
  return {
    type: VALIDATE_INVITE_SUCCESS,
    payload: response
  }
}

export function validateInviteFailure (errorModel: ErrorModel): PasswordTypes {
  return {
    type: VALIDATE_INVITE_FAILURE,
    payload: errorModel
  }
}

export function acceptInvite (input: AcceptInviteInput): PasswordTypes {
  return {
    type: ACCEPT_INVITE,
    payload: input
  }
}

export function acceptInviteSuccess (): PasswordTypes {
  return {
    type: ACCEPT_INVITE_SUCCESS,
  }
}

export function acceptInviteFailure (errorModel: ErrorModel): PasswordTypes {
  return {
    type: ACCEPT_INVITE_FAILURE,
    payload: errorModel
  }
}
