import React, { useState } from 'react';
import { Loader, Divider } from 'semantic-ui-react';
import { RemittanceMonthSummary, RemittanceSummaryPeriodByMonth } from '../../../store/remittance/types';
import { ChevronDown, ChevronUp } from 'react-feather';
import RemittanceSummaryPeriod from './RemittanceSummaryPeriod';

interface RemittanceSummaryMonthProps {
  year: number,
  monthPeriod: RemittanceSummaryPeriodByMonth;
  monthSummary?: RemittanceMonthSummary;
  initialIsOpen: boolean;
  isLoading: boolean;
  onOpen: () => void;
  onDetailsClick: (invoiceId: number, coveredEntity: string, periodId: number) => void;

}

const RemittanceSummaryMonth: React.FunctionComponent<RemittanceSummaryMonthProps> = (props) => {
  const { initialIsOpen, isLoading, monthPeriod, monthSummary, onOpen, onDetailsClick } = props;

  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const toggleIsOpen = () => {
    if (!isOpen) {
      onOpen()
    }
    setIsOpen(!isOpen);
  }

  return (
    <div className="month-row">
      <Divider />
      <div className="flex-row">
        <button className="month-accordion-button remittanceSummaryMonth_Pendo" type="button" onClick={toggleIsOpen}>
          {isOpen ? <ChevronUp strokeWidth='2' /> : <ChevronDown strokeWidth='2' />}
          <div className="epsilon remittance-month">{monthPeriod.monthName}</div>
        </button>
      </div>
      {isOpen &&
        <div className="month">

          {isLoading &&
            <div className="loading"><Loader active inline content="Loading" /></div>
          }

          {monthSummary?.periods.map((period, i) => {
            return (
              <RemittanceSummaryPeriod
                key={i}
                initialIsOpen={i === 0}
                remittanceSummary={period}
                onDetailsClick={onDetailsClick}
              />
            )
          })}
        </div>
      }
    </div>
  )
}



export default RemittanceSummaryMonth
