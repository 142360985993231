import React from 'react';
import { Icon } from 'semantic-ui-react';

interface TablePaginationProps {
  pagingStatus: string;
  currentPage: number;
  totalCount: number;
  pageSize: number;
  onPageChange: (newPage: number) => void;
}

const TablePagination: React.FunctionComponent<TablePaginationProps> = (props: TablePaginationProps) => {

  const handlePageLeft = () => {
    if (props.currentPage > 1) {
      props.onPageChange(props.currentPage - 1);
    }
  }

  const handlePageRight = () => {
    if (props.currentPage * props.pageSize < props.totalCount) {
      props.onPageChange(props.currentPage + 1);
    }
  }

  return (
    <div className="table-pagination-container">
      <div className="paging-status">{props.pagingStatus}</div>
      <Icon id="pagination-left" className={props.currentPage === 1 ? 'disabled' : 'cursor-pointer'}
        onClick={handlePageLeft}
        value='page-left'
        name="chevron left" />
      <Icon id="pagination-right" className={props.currentPage * props.pageSize >= props.totalCount ? 'disabled' : 'cursor-pointer'}
        value='page-right'
        onClick={handlePageRight}
        name="chevron right" />
    </div>
  )
}

export default TablePagination;