import { call, put, takeEvery, all } from 'redux-saga/effects'
import {
  GET_LEGAL_DOCS,
  GET_STATE_LIST,
  GET_340B_IDS,
  GET_ADJUST_REASONS,
} from './constants';
import {
  getStateListSuccess,
  getStateListFailure,
  getLegalDocumentsSuccess,
  getLegalDocumentsFailure,
  get340bIdsSuccess,
  get340bIdsFailure,
  getAdjustReasonsSuccess,
  getAdjustReasonsFailure,
} from './actionCreators';
import { fetch340bIds, fetchAdjustReasons, fetchLegalDocuments, fetchStates } from '../../api/appcommon/ReferenceData';
import logRocketUtil from '../../utils/logRocketUtil';
import { AdjustReason, AdjustReasonsModel, CoveredEntityModel, LegalDocumentsModel, StateModel } from './types';

const mapAdjustReasons = (reasons: AdjustReason[]) => {
  const mappedFields = reasons.map((reason: any) => ({
    key: reason.id,
    value: reason.code,
    text: reason.code
  }));

  return mappedFields;
};

export function* getStateListSaga () {
  try {
    const response: StateModel[] = yield call(fetchStates);
    const filteredStates: StateModel[] = response?.filter((state: StateModel) => state.code !== 'XX');
    yield put(getStateListSuccess(filteredStates));
  } catch (e) {
    yield put(getStateListFailure(logRocketUtil(e)));
  }
}

export function* getLegalDocumentsSaga () {
  try {
    const response: LegalDocumentsModel = yield call(fetchLegalDocuments);
    yield put(getLegalDocumentsSuccess(response));
  } catch (e) {
    yield put(getLegalDocumentsFailure(logRocketUtil(e)));
  }
}

export function* get340bIdsSaga () {
  try {
    const response: CoveredEntityModel[] = yield call(fetch340bIds);
    yield put(get340bIdsSuccess(response));
  } catch (e) {
    yield put(get340bIdsFailure(logRocketUtil(e)));
  }
}

export function* getAdjustReasonsSaga () {
  try {
    const response: AdjustReasonsModel = yield call(fetchAdjustReasons);

    const adjustmentOptions = mapAdjustReasons(response.adjustedReasons);
    const failureOptions = mapAdjustReasons(response.failureReasons);
    const autoAdjustedOptions = mapAdjustReasons(response.autoAdjustedReasons);

    const formattedData: AdjustReasonsModel = {
      ...response,
      adjustedReasonsFilter: autoAdjustedOptions.concat(adjustmentOptions),
      failureReasonsFilter: failureOptions
    };

    yield put(getAdjustReasonsSuccess(formattedData));
  } catch (e) {
    yield put(getAdjustReasonsFailure(logRocketUtil(e)))
  }
}

function* watchGetStateListSaga () {
  yield takeEvery(GET_STATE_LIST, getStateListSaga);
}

function* watchGetLegalDocumentsSaga () {
  yield takeEvery(GET_LEGAL_DOCS, getLegalDocumentsSaga);
}

function* watchGet340bIdsSaga () {
  yield takeEvery(GET_340B_IDS, get340bIdsSaga);
}

function* watchGetAdjustReasons () {
  yield takeEvery(GET_ADJUST_REASONS, getAdjustReasonsSaga)
}

export default function* watchAllAppCommon () {
  yield all([
    watchGetStateListSaga(),
    watchGetLegalDocumentsSaga(),
    watchGet340bIdsSaga(),
    watchGetAdjustReasons()
  ])
}
