import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Form, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { RootState } from '../../store/RootState';
import { sendRequestForResetPassword, resetForgotPassword } from '../../store/password/actionCreators';
import * as yup from 'yup';
import { Formik } from 'formik';
import ForgotPasswordSuccess from './ForgotPasswordSuccess';
import { clearUserDetails } from '../../store/login/actionCreators';

interface ForgotPasswordProps extends RouteComponentProps {
  expired: boolean;
  isLoading: boolean;
  forgotPasswordSuccess: boolean;
  sendEmailToApi: (email: string) => void;
  resetForgotPassword: () => void;
  clearUserDetails: () => void;
}

const Schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required()
})

const intialValues: { email: string } = {
  email: ''
}

const ForgotPassword: React.FunctionComponent<ForgotPasswordProps> = ({ expired, isLoading, forgotPasswordSuccess, sendEmailToApi, resetForgotPassword, clearUserDetails, history }) => {

  const [email, setEmail] = useState<string>('');

  const handleSubmit = (values: { email: string }) => {
    setEmail(values.email);
    sendEmailToApi(values.email)
  }

  const handleClickToHomepage = () => {
    clearUserDetails()
    history.push('/')
  }

  if (forgotPasswordSuccess) {
    return <ForgotPasswordSuccess email={email} onReset={resetForgotPassword} onClose={handleClickToHomepage} />
  }

  return (
    <div className="login-page forgot-password">
      <div className="login-page-layout">
        <div className="login-page-column-right">
          <div className="login-form-container">
            {isLoading && <Dimmer className="dimmer" active inverted><Loader inverted content='Loading' /></Dimmer>}

            <Formik
              initialValues={intialValues}
              onSubmit={handleSubmit}
              validationSchema={Schema}
              validateOnMount={true}
            >
              {({ handleSubmit, errors, touched, isValid, dirty, handleChange, handleBlur, values }) => (
                <Form className='login-form' onSubmit={handleSubmit}>
                  <div className="login-form-header">{expired ? 'Expired' : 'Reset'} Password</div>
                  <div className="login-form-body">
                    <div className="login-form-description">
                      {expired && 'Your password has expired. Please provide your email address so that we can send you a link to reset it.'}
                      {!expired && 'Enter your email, and we will send you instructions on how to reset your password.'}
                    </div>
                    <Form.Input
                      name='email'
                      type="email"
                      label="Email Address"
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={cx({ 'input-error-field': errors.email && touched.email && values.email.length > 0 })}
                    />
                    {errors.email && touched.email && values.email.length > 0 &&
                      <div className='input-error-text'>That is not a valid email address</div>
                    }
                  </div>
                  <div className="login-form-footer">
                    <button type="submit" className="app-button cta" id='sendResetLink_Pendo' disabled={!isValid || !dirty}>Send Reset Link</button>
                    <button type="button" className="app-button link-text" onClick={handleClickToHomepage}>Go back to Sign-In</button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.PasswordState.isLoading,
  forgotPasswordSuccess: state.PasswordState.forgotPasswordSuccess,

})

const mapDispatchToProps = (dispatch: any) => {
  return {
    sendEmailToApi: (email: string) => { dispatch(sendRequestForResetPassword(email)) },
    resetForgotPassword: () => { dispatch(resetForgotPassword()) },
    clearUserDetails: () => { dispatch(clearUserDetails()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
