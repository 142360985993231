
import { ToastActionTypes, SHOW_TOAST, HIDE_TOAST } from './constants';
import { Toast, ToastState } from './types';

const initialState: ToastState = {
  toasts: []
}

export function toastReducer (
  state = initialState,
  action: ToastActionTypes
): ToastState {
  switch (action.type) {
    case HIDE_TOAST:
      return {
        ...state,
        toasts: state.toasts.filter((toast: Toast) => toast.timestamp !== action.payload.timestamp)
      }
    case SHOW_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, action.payload]
      }
    default:
      return state
  }
}
