import { call, put, takeEvery, all } from 'redux-saga/effects';
import { VALIDATE_RESET_PASSWORD_TOKEN, RESET_PASSWORD, CHANGE_PASSWORD, SEND_FORGOT_PASSWORD, VALIDATE_INVITE, ACCEPT_INVITE, LINK_NOT_VALID_ERROR_MESSAGE, PLEASE_TRY_AGAIN_ERROR_MESSAGE, CREATING_ACCOUNT_ERROR_MESSAGE } from './constants';
import { validateResetPasswordToken, resetPasswordApi, changePasswordApi, forgotPasswordApi, acceptInviteRecipientApi, validateInviteRecipientApi } from '../../api/password/Password';
import { UserModel, LoginModel } from '../login/types';
import { resetPasswordError, resetPasswordSuccess, changePasswordError, changePasswordSuccess, sendRequestForResetPasswordSuccess, sendRequestForResetPasswordError, validateInviteSuccess, validateInviteFailure, acceptInviteSuccess, acceptInviteFailure, validateTokenSuccess } from './actionCreators';
import { storeUserToken, removeUserToken, storeTemporaryToken, getTemporaryToken } from '../../localstore/LocalStore';
import jwt from 'jsonwebtoken';
import { select } from 'redux-saga-test-plan/matchers';
import { getResetPasswordUser } from './selectors';
import { validateUserSaga } from '../login/sagas';
import { createToast, TOAST_TYPE } from '../toast';
import { AcceptInviteInput } from './types';
import { ErrorModel } from '../applicationTypes';
import logRocketUtil from '../../utils/logRocketUtil';

export function* validateResetPasswordSaga (action: any) {
  removeUserToken();
  try {
    const response = yield call(validateResetPasswordToken, action.payload.token, action.payload.healthSystemId)
    const token = response.token;
    const decoded = jwt.decode(token, { complete: true, json: true }) as { [key: string]: any };
    storeTemporaryToken(token)
    if (decoded !== null) {
      const userModel: UserModel = {
        exp: decoded.payload.exp,
        family_name: decoded.payload.family_name,
        given_name: decoded.payload.given_name,
        userName: decoded.payload.userName,
        roles: decoded.payload.roles,
        isAuthenticated: true,
      }
      yield put(validateTokenSuccess(userModel))
    }
  } catch (e) {
    if (e.message) {
      yield put(resetPasswordError(logRocketUtil(e)))
    } else {
      logRocketUtil(e)
      const errorModel: ErrorModel = {
        message: LINK_NOT_VALID_ERROR_MESSAGE,
        traceId: e.traceId
      }
      yield put(resetPasswordError(errorModel))
    }
  }
}

export function* resetPasswordSaga (action: any) {
  const token = getTemporaryToken() || '';
  storeUserToken(token)
  try {
    yield call(resetPasswordApi, action.payload)
    yield call(validateUserSaga, action);
    yield put(resetPasswordSuccess())
  } catch (e) {
    if (e.message) {
      yield put(resetPasswordError(logRocketUtil(e)))
    } else {
      logRocketUtil(e)
      const errorModel: ErrorModel = {
        message: PLEASE_TRY_AGAIN_ERROR_MESSAGE,
        traceId: e.traceId
      }
      yield put(resetPasswordError(errorModel))
    }
  }
}

export function* changePasswordSaga (action: any) {
  try {
    yield call(changePasswordApi, action.payload)
    yield put(changePasswordSuccess())
  } catch (e) {
    if (e.message) {
      yield put(changePasswordError(logRocketUtil(e)))
    } else {
      logRocketUtil(e)
      const errorModel: ErrorModel = {
        message: PLEASE_TRY_AGAIN_ERROR_MESSAGE,
        traceId: e.traceId
      }
      yield put(changePasswordError(errorModel))
    }
  }
}

export function* fetchForgotPasswordSaga (action: any) {
  try {
    yield call(forgotPasswordApi, action.payload)
    yield put(sendRequestForResetPasswordSuccess());
  } catch (e) {
    yield put(sendRequestForResetPasswordError(logRocketUtil(e)));
  }
}

export function* validateInviteSaga (action: any) {
  try {
    let token = '';
    let isSignedUp = false;

    const response = yield call(validateInviteRecipientApi, action.payload.token, action.payload.healthSystemId)
    token = response.token;
    isSignedUp = response.isSignedUp;

    const decoded = jwt.decode(token, { complete: true, json: true }) as { [key: string]: any };
    removeUserToken();
    storeTemporaryToken(token)
    if (decoded !== null) {
      const userModel: UserModel = {
        exp: decoded.payload.exp,
        family_name: decoded.payload.family_name,
        given_name: decoded.payload.given_name,
        userName: decoded.payload.userName,
        roles: decoded.payload.roles,
        isAuthenticated: true,
      }
      yield put(validateInviteSuccess({ user: userModel, isSignedUp: isSignedUp }))
    }
  } catch (e) {
    if (e.message) {
      yield put(validateInviteFailure(logRocketUtil(e)))
    } else {
      logRocketUtil(e)
      const errorModel: ErrorModel = {
        message: LINK_NOT_VALID_ERROR_MESSAGE,
        traceId: e.traceId
      }
      yield put(validateInviteFailure(errorModel))
    }
  }
}


export function* acceptInviteSaga (action: any) {
  const token = getTemporaryToken() || '';
  storeUserToken(token)
  try {
    const input: AcceptInviteInput = action.payload;
    yield call(acceptInviteRecipientApi, input);

    //auto login using new password for new user sign up
    if (input.password) {
      const user = yield select(getResetPasswordUser);
      const login: LoginModel = {
        userName: user.userName,
        password: input.password,
      };
      yield call(validateUserSaga, { payload: login });
    }

    yield put(acceptInviteSuccess());
  } catch (e) {
    logRocketUtil(e)
    const errorModel: ErrorModel = {
      message: CREATING_ACCOUNT_ERROR_MESSAGE,
      traceId: e.traceId
    }
    yield put(acceptInviteFailure(errorModel));
    yield put(createToast({ text: CREATING_ACCOUNT_ERROR_MESSAGE, type: TOAST_TYPE.ERROR }));
  }
}


function* watchValidateResetPassword () {
  yield takeEvery(VALIDATE_RESET_PASSWORD_TOKEN, validateResetPasswordSaga);
}

function* watchResetPassword () {
  yield takeEvery(RESET_PASSWORD, resetPasswordSaga);
}

function* watchChangePassword () {
  yield takeEvery(CHANGE_PASSWORD, changePasswordSaga);
}

function* watchForgotPassword () {
  yield takeEvery(SEND_FORGOT_PASSWORD, fetchForgotPasswordSaga)
}

function* watchValidateInviteSaga () {
  yield takeEvery(VALIDATE_INVITE, validateInviteSaga);
}

function* watchAcceptInviteSaga () {
  yield takeEvery(ACCEPT_INVITE, acceptInviteSaga);
}

export default function* watchAllPasswords () {
  yield all([
    watchValidateResetPassword(),
    watchResetPassword(),
    watchChangePassword(),
    watchForgotPassword(),
    watchValidateInviteSaga(),
    watchAcceptInviteSaga()
  ])
}
