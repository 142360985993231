import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { RootState } from '../../../store/RootState';
import { connect } from 'react-redux';
import { Minus, Plus } from 'react-feather';
import { Button, Dimmer, Loader } from 'semantic-ui-react';

import cx from 'classnames';
import { Tab } from 'semantic-ui-react';
import PageHeader from '../../common/navigation/PageHeader';
import AssociatedPharmacies from './AssociatedPharmacies';
import EditHistory from './EditHistory';
import AddPharmacyDialog from './AddPharmacyDialog';
import { PharmacyEditHistoryModel, PharmacyManagementDetails, PharmacyManagementModel } from '../../../store/coveredentityinfo/pharmacymanagement/types';
import RestrictedByRole from '../../teammanagement/RestrictedByRole';
import { RECIPIENT_TEAM_MANAGEMENT_USER_ROLES } from '../../../store/teammanagement/constants';
import { CLIENT_ERROR_HEADER, CLIENT_ERROR_MESSAGE, FEATURE_FLAGS } from '../../../store/applicationConstants';
import { clearPharmacyManagement, downloadEditHistory, getPharmacyManagement } from '../../../store/coveredentityinfo/pharmacymanagement/actioncreators';
import FeatureFlagContainer from '../../common/FeatureFlagContainer';
import ErrorImage from '../../common/errors/ErrorImage';
import ClientErrorImage from '../../../themes/kalderos/static/media/errors/ClientError.svg';
import { createToast, Toast, TOAST_TYPE } from '../../../store/toast';

const PharmacyManagementCenterTabMap: { [key: string]: number } = {
  'associatedpharmacies': 0,
  'edithistory': 1
}

export function getPharmacyManagementCenterTabIndex (path: string): number {
  return PharmacyManagementCenterTabMap[path.substr(path.lastIndexOf('/') + 1)];
}

export interface PharmacyManagementCenterProps {
  activeIndex: number;
  associatedPharmacies: PharmacyManagementDetails[];
  associatedPharmaciesModel: PharmacyManagementModel;
  pharmacyEditHistoryModel: PharmacyEditHistoryModel;
  idCode: string;
  coveredEntityName: string;
  featureFlags: string[];
  isError: boolean;
  isLoading: boolean;
  createToast: (toast: Toast) => void;
  clearPharmacyManagement: () => void;
  getPharmacyDetails: (idCode: string) => void;
  downloadEditHistory: () => void;
}

export interface PharmacyManagementCenterState {
  isEditMode: boolean;
  AddPharmacyDialogOpen: boolean;
  isRemoveColumnVisible: boolean;
}

export class PharmacyManagementCenter extends React.Component <PharmacyManagementCenterProps, PharmacyManagementCenterState> {
  constructor (props: Readonly<PharmacyManagementCenterProps>) {
    super(props);

    this.state = {
      isEditMode: false,
      AddPharmacyDialogOpen: false,
      isRemoveColumnVisible: false
    };
  }

  handleEditButtonClick = () => {
    this.setState({ isEditMode: !this.state.isEditMode });

    if (this.state.isRemoveColumnVisible) {
      this.setState({ isRemoveColumnVisible: false });
    }
  }

  handleAddPharmacyClick = () => {
    this.setState({ AddPharmacyDialogOpen: true });
  }

  closeAddPharmacyDialog = () => {
    this.setState({ AddPharmacyDialogOpen: false });
  }

  handleRemovePharmacyClick = () => {
    this.setState({ isRemoveColumnVisible: !this.state.isRemoveColumnVisible });
  }

  handleDownloadEditHistory = () => {
    
    const toast: Toast = {
      text: 'Edit History downloaded.',
      type: TOAST_TYPE.SUCCESS
    };

    this.props.createToast(toast);

    this.props.downloadEditHistory()
  }

  componentDidMount () {
    this.props.getPharmacyDetails(this.props.idCode);
  }

  componentWillUnmount () {
    this.props.clearPharmacyManagement();
  }

  render () {
    const { activeIndex, idCode, associatedPharmaciesModel, pharmacyEditHistoryModel, coveredEntityName, featureFlags, isError, isLoading } = this.props;
    const { isEditMode, isRemoveColumnVisible } = this.state;

    const panes = [
      {
        menuItem: {
          as: Link,
          key: 'associatedpharmacies',
          content: 'Associated Pharmacies',
          to: `/recipients/coveredentityinfo/${idCode}/associatedpharmacies`,
          className: 'ce-tab associatedPharmaciesTab_Pendo'
        },
        render: () => <Tab.Pane><AssociatedPharmacies idCode={idCode} isEditMode={isEditMode} isRemoveColumnVisible={isRemoveColumnVisible} /></Tab.Pane>
      },
      {
        menuItem: {
          as: Link,
          key: 'edithistory',
          id: 'editHistoryTab_Pendo',
          content: 'Edit History',
          to: `/recipients/coveredentityinfo/${idCode}/edithistory`,
          disabled: isEditMode,
          className: 'ce-tab'
        },
        render: () => <Tab.Pane><EditHistory /></Tab.Pane>
      }
    ];

    // const editButtonText = isEditMode ? 'Stop Editing' : 'Edit Covered Entity';

    return (
      <FeatureFlagContainer entitledFeatureFlags={featureFlags} featureFlag={FEATURE_FLAGS.PHARMACY_MANAGEMENT} includeErrorMessage>
        {(isLoading && !coveredEntityName) && <Dimmer active inverted><Loader className="recipient-request-center-loader" inverted content="Loading" /></Dimmer>}

        {(!isLoading && isError) && (
          <ErrorImage
            header={CLIENT_ERROR_HEADER}
            image={ClientErrorImage}
            message={CLIENT_ERROR_MESSAGE}
          />
        )}

        {(!isError && coveredEntityName) && (
          <Fragment>
            <div className={cx('covered-entity-container scroll-page', { 'padding-top': isEditMode })}>
              {isEditMode && (
                <div className="ce-edit-banner">
                  <p>You are editing covered entity {idCode} </p>
                </div>
              )}

              <PageHeader className='ce-center-page-header' text={coveredEntityName} backLink='/recipients/coveredentityinfo/ceinfo' noBorder isEditMode={isEditMode} />

              <div className="ce-pharmacy-summary">
                <div>340B ID: <span className="value">{idCode}</span></div>
                <div><span className="value">{associatedPharmaciesModel.totalCount} Pharmacies</span></div>
              </div>
              {(activeIndex === 1 && pharmacyEditHistoryModel.pharmacyEditViews.length !==0 &&
                <button
                  type='button' 
                  className='edit-history-download-csv-button'
                  onClick={() => this.handleDownloadEditHistory() }>
                  
                  <Plus size='18'></Plus>
                  <div className='text'>
                 Download CSV
                  </div>  
                </button>
              )}
              <Tab menu={{ secondary: true, pointing: true }} panes={panes} activeIndex={this.props.activeIndex} className={cx('tab-controller fit-height divided')} />
            </div>

            {( activeIndex === 0 ) &&
              <RestrictedByRole allowedRoles={[RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN]}>
                <div className="associated-pharmacies-footer">
                  {isLoading && <Dimmer active className="footer-dimmer" inverted></Dimmer>}
                  {isEditMode && (
                    <>
                      <Button id='addPharmacyBtn_Pendo' disabled={isRemoveColumnVisible} className={cx('app-button button-white footer-button add', {'disabled': isRemoveColumnVisible})} onClick={() => this.handleAddPharmacyClick()}>
                        Add Pharmacy
                        <Plus size={16} />
                      </Button>
                      <button id='removePharmacyBtn_Pendo' className={cx('app-button button-white footer-button remove', {'active': isRemoveColumnVisible })} type="button" onClick={() => this.handleRemovePharmacyClick()}>
                        Remove Pharmacy
                        <Minus size={16} />
                      </button>
                    </>
                  )}
                  {/* <button id='editCoveredEntityBtn_Pendo' className={cx('app-button footer-button edit', { 'button-dark': isEditMode, 'button-white': !isEditMode })} type="button" onClick={() => this.handleEditButtonClick()}>
                    {editButtonText}
                  </button> */}
                </div>
              </RestrictedByRole>
            }
            {this.state.AddPharmacyDialogOpen && <AddPharmacyDialog isOpen={true} closeModal={this.closeAddPharmacyDialog} ></AddPharmacyDialog>}
          </Fragment>
        )}
      </FeatureFlagContainer>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  associatedPharmacies: state.PharmacyManagementState.associatedPharmaciesModel.associatedPharmacies,
  associatedPharmaciesModel: state.PharmacyManagementState.associatedPharmaciesModel,
  pharmacyEditHistoryModel: state.PharmacyManagementState.pharmacyEditHistoryModel,
  coveredEntityName: state.PharmacyManagementState.associatedPharmaciesModel.coveredEntityName,
  featureFlags: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.featureFlags,
  isError: state.PharmacyManagementState.associatedPharmaciesModel.isError.isError,
  isLoading: state.PharmacyManagementState.associatedPharmaciesModel.isLoading
});

const mapDispatchToProps = (dispatch: any) => ({
  createToast: (toast: Toast) => dispatch(createToast(toast)),
  clearPharmacyManagement: () => dispatch(clearPharmacyManagement()),
  getPharmacyDetails: (idCode: string) => dispatch(getPharmacyManagement(idCode)),
  downloadEditHistory: () => dispatch(downloadEditHistory())
});


export default connect(mapStateToProps, mapDispatchToProps)(PharmacyManagementCenter);
