import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { RootState } from '../../../store/RootState';
import { hasAdditionalCesToOnboard, makeSelectIsAuthorizingOfficial, selectUserIsNewAO } from '../../../store/onboarding/selectors';
import { connect } from 'react-redux';
import { checkOnboardingStatusSuccess, getCandidateCeList, refreshDataAfterOnboarding, resetOnboardingState, updateOnboardingDismissedWelcome } from '../../../store/onboarding/actionCreators';
import { updateSelectedHealthSystemOnboardingRoles } from '../../../store/teammanagement/actionCreators';
import { ROLES } from '../../../store/applicationConstants';
import { OnboardingStatusResponseModel } from '../../../store/onboarding/types';

interface GoToRequestFooterProps extends RouteComponentProps {
  hasAdditionalCesToOnboard: boolean;
  isAuthorizingOfficial: boolean;
  isLoading: boolean,
  refreshDataAfterOnboarding: () => void;
  resetOnboardingState: () => void;
  updateSelectedHealthSystemOnboardingRoles: (roles: string[]) => void;
  checkOnboardingStatusSuccess: (model: OnboardingStatusResponseModel) => void;
  getCandidateCeList: () => void;
  updateOnboardingDismissedWelcome: (dismissed: boolean) => void;
}

const GoToRequestFooter: React.FunctionComponent<GoToRequestFooterProps> = (props: GoToRequestFooterProps) => {

  useEffect(() => {
    if (props.isAuthorizingOfficial) {
      props.getCandidateCeList();
    }
  }, []);

  function onSetupAnotherClick () {
    //reset AO's roles so any roles added during this setup are not used
    props.updateSelectedHealthSystemOnboardingRoles([ROLES.RECIPIENT_AUTHORIZING_OFFICIAL]);
    props.resetOnboardingState();
    props.updateOnboardingDismissedWelcome(true);
    props.checkOnboardingStatusSuccess({ onboardingCompleted: false, onboardingStatusValue: 1 });
  }

  function onGoToAppClick () {
    props.refreshDataAfterOnboarding();
    props.resetOnboardingState();
    props.history.push('/recipients/requestcenter');
  }

  return (
    <div className="onboarding-layout-footer">
      <Dimmer active={props.isLoading} inverted>
        <Loader inverted content='Loading' />
      </Dimmer>
      <div className="onboarding-layout-navigation">
        {props.hasAdditionalCesToOnboard &&
          <button className="app-button button-white" type="button" onClick={onSetupAnotherClick}>
            Set Up Another Health System
          </button>
        }
      </div>
      <div className="onboarding-layout-cta">
        <button className="app-button button-dark" type="button" onClick={onGoToAppClick}>
          Go to Kalderos Request
        </button>
      </div>
    </div>)
};

const mapStateToProps = (state: RootState) => ({
  hasAdditionalCesToOnboard: hasAdditionalCesToOnboard(state),
  isAuthorizingOfficial: makeSelectIsAuthorizingOfficial(state) || selectUserIsNewAO(state),
  isLoading: state.OnboardingState.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  refreshDataAfterOnboarding: () => dispatch(refreshDataAfterOnboarding()),
  resetOnboardingState: () => dispatch(resetOnboardingState()),
  updateSelectedHealthSystemOnboardingRoles: (roles: string[]) => dispatch(updateSelectedHealthSystemOnboardingRoles(roles)),
  checkOnboardingStatusSuccess: (model: OnboardingStatusResponseModel) => dispatch(checkOnboardingStatusSuccess(model)),
  getCandidateCeList: () => dispatch(getCandidateCeList()),
  updateOnboardingDismissedWelcome: (dismissed: boolean) => dispatch(updateOnboardingDismissedWelcome(dismissed)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoToRequestFooter));