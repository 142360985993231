export const pendoInitialize = (userGUID: string) => {
  if ((window as any).pendo.getVisitorId) {
    const currentVisitorId = (window as any).pendo.getVisitorId();

    if (currentVisitorId.includes('_PENDO_')) {
      (window as any).pendo.initialize({
        visitor: {
          id: userGUID
        }
      });
    } else if (currentVisitorId !== userGUID) {
      (window as any).pendo.identify({
        visitor: {
          id: userGUID
        }
      });

      (window as any).pendo.loadGuides();
    }
  } else {
    (window as any).pendo.initialize({
      visitor: {
        id: userGUID
      }
    });
  }
};


