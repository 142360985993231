const USER_TOKEN = 'USER_TOKEN';
const TEMP_USER_TOKEN = 'TEMP_USER_TOKEN';

export function storeUserToken (value: string) {
  sessionStorage.setItem(USER_TOKEN, value);
}

export function getUserToken () {
  return sessionStorage.getItem(USER_TOKEN);
}

export function removeUserToken () {
  sessionStorage.removeItem(USER_TOKEN)
}

export function ClearAllItemFromLocalStore () {
  sessionStorage.clear();
}

export function storeTemporaryToken (value: string) {
  sessionStorage.setItem(TEMP_USER_TOKEN, value)
}

export function getTemporaryToken () {
  return sessionStorage.getItem(TEMP_USER_TOKEN);
}

export function storeUserHealthSystemId (userId: number, healthSystemId: number)
{
  localStorage.setItem(userId.toString(), healthSystemId.toString());
}

export function getUserHealthSystemId (userId: number)
{
  const storeVal = localStorage.getItem(userId.toString());
  if (storeVal){
    const healthSystemId = parseInt(storeVal);
    if (!isNaN(healthSystemId)){
      return healthSystemId;
    }
  }

  return 0;
}

export default getUserToken;
