import { AccountInfoActionTypes, GET_BUSINESS_INFORMATION_SUCCESS, GET_BUSINESS_INFORMATION_ERROR, GET_BUSINESS_INFORMATION_REQUEST, GET_USER_INFORMATION_ERROR, GET_USER_INFORMATION_REQUEST, GET_USER_INFORMATION_SUCCESS } from './constants';
import { BusinessInfoModel, ErrorModel, UserInfoModel } from '../applicationTypes';

//BUSINESS INFO
export function getBusinessInfo (): AccountInfoActionTypes {
  return {
    type: GET_BUSINESS_INFORMATION_REQUEST
  }
}

export function updateBusinessInfo (businessInfoModel: BusinessInfoModel): AccountInfoActionTypes {
  return {
    type: GET_BUSINESS_INFORMATION_SUCCESS,
    payload: businessInfoModel
  }
}

export function updateBusinessInfoErrors (errorModel: ErrorModel): AccountInfoActionTypes {
  return {
    type: GET_BUSINESS_INFORMATION_ERROR,
    payload: errorModel
  }
}

//USER INFO
export function getUserInfo (): AccountInfoActionTypes {
  return {
    type: GET_USER_INFORMATION_REQUEST
  }
}

export function updateUserInfo (userInfoModel: UserInfoModel): AccountInfoActionTypes {
  return {
    type: GET_USER_INFORMATION_SUCCESS,
    payload: userInfoModel
  }
}

export function updateUserInfoErrors (errorModel: ErrorModel): AccountInfoActionTypes {
  return {
    type: GET_USER_INFORMATION_ERROR,
    payload: errorModel
  }
}