import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { RootState } from '../../../store/RootState';
import PageHeader from '../../common/navigation/PageHeader';
import EmptyTransactions from '../../common/transactions/EmptyTransactions';
import { NO_REMITTANCE_ADVICE_HEADER, NO_REMITTANCE_ADVICE_MESSAGE, SUPPORT_EMAIL_LINK, SUPPORT_EMAIL, SERVER_ERROR_HEADER, SERVER_ERROR_MESSAGE } from '../../../store/applicationConstants';
import { RemittanceMonthSummary, RemittanceSummaryPeriodByYear } from '../../../store/remittance/types';
import { getRemittanceSummaryPeriods, getRemittanceMonthSummary } from '../../../store/remittance/actionCreators';
import { InfoBanner } from '../../common/errors/InfoBanner';
import { Link } from 'react-router-dom';
import { PaymentAlertModel } from '../../../store/payments/common/types';
import { getPaymentAlertInfo } from '../../../store/payments/common/actionCreators';
import RemittanceSummaryMonth from './RemittanceSummaryMonth';

export interface RemittanceSummaryProps extends RouteComponentProps {
  remittancePeriods: RemittanceSummaryPeriodByYear[];
  monthSummaries: RemittanceMonthSummary[];
  paymentAlert: PaymentAlertModel;
  activeHealthSystemId: number;
  isLoading: boolean;
  isMonthLoading: boolean;
  errorText: string;
  getRemittanceSummaryPeriods: () => void;
  getRemittanceMonthSummary: (year: number, month: number) => void;
  getPaymentAlertInfo: () => void;
}

export class RemittanceSummary extends React.Component<RemittanceSummaryProps>{

  componentDidMount () {
    this.props.getPaymentAlertInfo();
    if (this.props.activeHealthSystemId !== 0) {
      this.props.getRemittanceSummaryPeriods();
    }
  }

  componentDidUpdate (prevProps: RemittanceSummaryProps) {
    if (prevProps.activeHealthSystemId !== this.props.activeHealthSystemId) {
      this.props.getRemittanceSummaryPeriods();
    }

    //load first month when list loads
    if (this.props.remittancePeriods.length > 0 && prevProps.remittancePeriods.length === 0) {
      if (this.props.remittancePeriods[0].months?.length > 0) {
        const year = this.props.remittancePeriods[0].year;
        const month = this.props.remittancePeriods[0].months[0].month;
        this.props.getRemittanceMonthSummary(year, month);
      }
    }

  }

  handleMonthOpen = (year: number, month: number) => {
    const summary = this.props.monthSummaries.find(s => s.year === year && s.month === month);
    if (!summary) {
      this.props.getRemittanceMonthSummary(year, month);
    }
  }

  handleDetailsClick = (invoiceId: number, coveredEntity: string, period: number, summary: RemittanceMonthSummary) => {
    let queryString = `coveredentity=${coveredEntity}`;
    if (invoiceId !== 0) {
      queryString += `&remittanceid=${invoiceId}`;
    }
    this.props.history.push(`/recipients/remittance/period/${summary.year}/${summary.month}/${period}?${queryString}`);
  };

  render () {
    const { paymentAlert, remittancePeriods, isLoading, isMonthLoading, monthSummaries, errorText } = this.props;
    return (
      <div className="remittance-advice-home">

        <div className="remittance-advice-home-page-header">
          <PageHeader text="Remittance Advice" />
        </div>

        {paymentAlert?.description?.length > 0 &&
          <div className="info-banner-content">
            <InfoBanner
              title={'You have one or more payments waiting for you.'}
              messageWithNode={<span>Go to <Link className="link" to='/recipients/payments/settings'>Payment Settings</Link> or contact kalderos support at <a href={SUPPORT_EMAIL_LINK}>{SUPPORT_EMAIL}</a> to resolve.</span>}
            />
          </div>
        }

        <div className="remittance-advice-home-main-container">

          {isLoading &&
            <Dimmer active inverted>
              <Loader className="remittance-advice-loader" inverted content='Loading' />
            </Dimmer>
          }

          {!isLoading && remittancePeriods?.length > 0 && !errorText &&
            <div className="remittance-summary-details-year">
              {remittancePeriods.map((yearPeriod, i) => {
                const year = yearPeriod.year;
                return (
                  <div key={year}>
                    <div className="flex-row year">
                      <div className="epsilon year-value">{year}</div>
                    </div>
                    <div className="month-container">
                      {yearPeriod.months?.map((monthPeriod, j) => {
                        const initialIsOpen = i === 0 && j === 0;
                        const month = monthPeriod.month;
                        const summary = monthSummaries.find(s => s.year === year && s.month === month);

                        return (
                          <RemittanceSummaryMonth
                            key={`${year}_${month}`}
                            year={year}
                            monthPeriod={monthPeriod}
                            monthSummary={summary}
                            initialIsOpen={initialIsOpen}
                            isLoading={isMonthLoading && !summary}
                            onOpen={() => this.handleMonthOpen(year, month)}
                            onDetailsClick={(invoiceId, ce, period) => this.handleDetailsClick(invoiceId, ce, period, summary!)}
                          />
                        )

                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          }

          {!isLoading && remittancePeriods.length === 0 && !errorText &&
            <EmptyTransactions emptyTransactionsHeader={NO_REMITTANCE_ADVICE_HEADER} emptyTransactionsMessage={NO_REMITTANCE_ADVICE_MESSAGE} />
          }

          {!isLoading && remittancePeriods.length === 0 && errorText &&
            <EmptyTransactions emptyTransactionsHeader={SERVER_ERROR_HEADER} emptyTransactionsMessage={SERVER_ERROR_MESSAGE} />
          }

        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  remittancePeriods: state.RemittanceState.summary.remittancePeriods,
  monthSummaries: state.RemittanceState.summary.monthSummaries,
  isLoading: state.RemittanceState.summary.isLoading,
  isMonthLoading: state.RemittanceState.summary.isMonthLoading,
  errorText: state.RemittanceState.summary.errorText,
  paymentAlert: state.PaymentsState.common.paymentAlert,
  activeHealthSystemId: state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.healthSystemId
})

const mapDispatchToProps = (dispatch: any) => ({
  getRemittanceSummaryPeriods: () => dispatch(getRemittanceSummaryPeriods()),
  getRemittanceMonthSummary: (year: number, month: number) => dispatch(getRemittanceMonthSummary(year, month)),
  getPaymentAlertInfo: () => dispatch(getPaymentAlertInfo()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RemittanceSummary)
