import * as React from 'react'
import { DwollaBankAccountFormModel } from '../../../store/appcommon/types'
import { RootState } from '../../../store/RootState'
import { connect } from 'react-redux'

export const load = (environment: string): Promise<void> =>
  new Promise((resolve: () => void): void => {
    const s = document.createElement('script')
    s.id = 'dwollaAddFundingSource'
    s.type = 'text/javascript'
    s.async = true
    s.src =
      environment === 'prod'
        ? 'https://cdn.dwolla.com/1/dwolla.min.js'
        : 'https://cdn.dwolla.com/1/dwolla.js'

    s.addEventListener('load', (): void => {
      (window as any).dwolla.configure(environment)
      resolve()
    })

    document.body.appendChild(s)
  })

export const unload = (environment: string): Promise<void> =>
  new Promise((resolve: () => void): void => {
    const s = document.body.querySelector('dwollaAddFundingSource');
    s?.removeEventListener('load', (): void => {
      (window as any).dwolla.configure(environment)
      resolve()
    })
    if (s) {
      document.body.removeChild(s);
    }
  })
interface AddFundingSourceProps {
  onError: (errorMessage: any) => void,
  onSuccess: (fundingSourceId: string) => void,
  dwollaEnvironment: string
  fundingSourceToken: string
  bankInfo: DwollaBankAccountFormModel,
}

type EmbeddedErrors = {
  code: string,
  message: string,
  path: string
}

type DwollaAddBankAccountError = {
  code: string
  message: string
  _embedded: {errors: EmbeddedErrors[]}
}

type DwollaResponse = {
  _links: {
    'funding-source': {
      href: string
    }
  }
}

const pluckFundingSource = (res: DwollaResponse): string => {
  try {
    const href = res._links['funding-source'].href.split('/')
    return href[href.length - 1]
  } catch {
    return ''
  }
}

class AddFundingSource extends React.Component<AddFundingSourceProps> {
  formatErrors = (error: DwollaAddBankAccountError) => {
    const errorMessages = new Array<string>();
    error?._embedded?.errors?.forEach(function (error: EmbeddedErrors) {
      errorMessages.push(error.path);
    })
    const formattedError = {
      code: error.code,
      message: error.message,
      fieldErrors: errorMessages
    };
    this.props.onError(formattedError);
  }

  componentDidMount () {
    const { dwollaEnvironment, fundingSourceToken, bankInfo } = this.props;
    load(dwollaEnvironment)
      .then((): void => {
        // @ts-ignore
        window.dwolla.fundingSources.create(
          fundingSourceToken, bankInfo,
          (err: DwollaAddBankAccountError, response: DwollaResponse): void => {
            if (err) {
              this.formatErrors(err);
            } else {
              this.props.onSuccess(pluckFundingSource(response));
            }
          }
        )
      }).catch((e: string): void => {
        this.props.onError(e)
      })
  }

  componentWillUnmount () {
    const { dwollaEnvironment } = this.props;
    unload(dwollaEnvironment);
  }

  render () {
    return (<div />)
  }
}


const mapStateToProps = (state: RootState) => ({
  dwollaEnvironment: state.PaymentsState.common.dwollaEnvironment.environment,
}); 

export default connect(mapStateToProps)(AddFundingSource);