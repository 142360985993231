import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import { uniq } from 'lodash';
import { RootState } from '../RootState';
import { createToast, TOAST_TYPE } from '../toast';
import {
  REVERSE_TRANSACTIONS,
} from './constants';
import {
  reverseTransactionsFailure,
  reverseTransactionsSuccess,
  getContractElements,
  getContractElementsSuccess,
  getContractElementsFailure,
} from './actionCreators';
import logRocketUtil from '../../utils/logRocketUtil';
import { postReverseTransaction } from '../../api/requestcenter/RecipientTransactions';
import { fetchMultiContractElements } from '../../api/contracts/Contracts';
import { getActiveHealthSystemId } from '../teammanagement/selectors';
import { DiscountRequestFormattedAdditionalFieldsModel, DiscountRequestModel } from '../transactionscommon/types';
import { ContractElementsFieldsModel, ContractElementsModel } from './types';
import { getFormattedFieldValue } from '../../pages/requestcenter/RequestForm/RequestFormHelper';

const selectContractElements = (state: RootState) => state.TransactionsCommon.contractElements;

export function* reverseTransactionSaga (action: any) {
  try {
    const activeHealthSystemId: number = yield select(getActiveHealthSystemId);
    const reversalResult = yield call(postReverseTransaction, action.payload, activeHealthSystemId);

    if (reversalResult.length > 0) {
      yield put(createToast({ text: `${reversalResult.length} transaction${reversalResult.length > 1 ? 's' : ''} reversed.`, type: TOAST_TYPE.SUCCESS }))
      yield put(reverseTransactionsSuccess());
    } else {
      throw new Error()
    }

  } catch (e) {
    yield put(createToast({ text: (e as any).message ?? 'Unable to reverse transaction.', type: TOAST_TYPE.ERROR }));
    yield put(reverseTransactionsFailure(logRocketUtil(e)));

  }
}

export function* addDiscountRequestAdditionalFields (discountRequests: DiscountRequestModel[]) {
  if (discountRequests && discountRequests.length > 0) {
    const contractElements: ContractElementsModel = yield select(selectContractElements);

    // check for any contract ids that haven't been loaded yet
    const currentContractIds = uniq(discountRequests.map((transaction: any) => transaction.contractId)) as number[];
    const existingContractIds = contractElements.retailContractElements.map((contract: any) => contract.contractId) as number[];
    const newContractIds = currentContractIds.filter(contractId => !existingContractIds.includes(contractId));

    if (newContractIds.length > 0) {
      try {
        //fetch new elements and combine with existing
        const activeHealthSystemId: number = yield select(getActiveHealthSystemId);

        //set IsLoading
        yield put(getContractElements());

        //const medicalElements: ContractElementsFieldsModel[] = yield call(fetchMultiContractElements, contractIds, activeHealthSystemId, true);
        const retailElements: ContractElementsFieldsModel[] = yield call(fetchMultiContractElements, newContractIds, activeHealthSystemId, false);
        contractElements.retailContractElements = contractElements.retailContractElements.concat(retailElements);

        yield put(getContractElementsSuccess(contractElements))
      } catch (e) {
        yield put(getContractElementsFailure(logRocketUtil(e)))
      }
    }

    //add additional fields to each record
    discountRequests.forEach((t) => {
      const elementsByContract = contractElements.retailContractElements.find(f => f.contractId === t.contractId);
      let additionalFields: DiscountRequestFormattedAdditionalFieldsModel[] = [];
      if (elementsByContract) {
        additionalFields = elementsByContract.additionalFields.map((cle) => {
          const fieldId = cle.name as keyof DiscountRequestModel;
          const fieldValue = t[fieldId];
          const additionalField: DiscountRequestFormattedAdditionalFieldsModel = {
            name: cle.name,
            value: fieldValue == null ? '- -' : fieldValue,
            formattedValue: getFormattedFieldValue(cle.name, fieldValue),
            label: cle.display
          }
          return additionalField;
        });
      }

      t.additionalFields = additionalFields;
    });
  }
  
  return discountRequests;
}

function* watchReverseTransactionSaga () {
  yield takeEvery(REVERSE_TRANSACTIONS, reverseTransactionSaga);
}

export default function* watchAllTransactionsCommon () {
  yield all([
    watchReverseTransactionSaga(),
  ])
}
