import moment from 'moment-timezone';

export class DateFormats {
  static readonly YYYYMMDD_DASH = 'YYYY-MM-DD';
  static readonly YYYYMMDD_SLASH = 'YYYY/MM/DD';
  static readonly MMDDYYYY_DASH = 'MM-DD-YYYY';
  static readonly MMDDYYYY_SLASH = 'MM/DD/YYYY';
  static readonly MDYYY = 'M/D/YYYY';
  static readonly YYYY = 'YYYY';
  static readonly MD = 'M/D';
  static readonly MMDDYYYY_TIME = 'MM/DD/YYYY - HH:mm'
}

/**
 * Formats a date object to string
 * 
 * @param value - date object to format
 * @param options.format (optional) format string. Default: "YYYY-MM-DD"
 * @param options.emptyVal (optional) value to return when input is empty or invalid. Default: ""
 */
export const formateDate = (value: any, options?: { format?: string, emptyVal?: string }) => {
  if (value && value !== '- -') {
    const date = moment(value);
    if (date.isValid()) {
      return date.format(options?.format ? options?.format : DateFormats.YYYYMMDD_DASH)
    }
  }
  return options?.emptyVal ? options.emptyVal : '';
}

/**
 * Gets the Start unit of a date object
 * 
 * @param unit - the time unit to base Start Of on
 * @param value - the date to base Start Of on
 * @param options.format (optional) format string. Default: ""
 * @param options.emptyVal (optional) value to return when input is empty or invalid. Default: ""
 */
export const getStartOf = (unit: moment.unitOfTime.StartOf, value: any, options?: { format?: string, emptyVal?: string }) => {
  return getStartOrEndOf(true, unit, value, options);
}

/**
 * Gets the End unit of a date object
 * 
 * @param unit - the time unit to base End Of on
 * @param value - the date to base End Of on
 * @param options.format (optional) format string. Default: ""
 * @param options.emptyVal (optional) value to return when input is empty or invalid. Default: ""
 */
export const getEndOf = (unit: moment.unitOfTime.StartOf, value: any, options?: { format?: string, emptyVal?: string }) => {
  return getStartOrEndOf(false, unit, value, options);
}

/**
 * Returns true if the first date comes before the second date
 * @param date1 - first date
 * @param date2 - second date
 */
export const isBeforeDay = (date1: any, date2: any) => {
  if (!moment.isMoment(date1) || !moment.isMoment(date2)) return false;

  const date1Year = date1.year();
  const date1Month = date1.month();

  const date2Year = date2.year();
  const date2Month = date2.month();

  const isSameYear = date1Year === date2Year;
  const isSameMonth = date1Month === date2Month;

  if (isSameYear && isSameMonth) return date1.date() < date2.date() + 1;
  if (isSameYear) return date1Month < date2Month;
  return date1Year < date2Year;
}

/**
 * Returns true if the first date comes after the second date
 * @param date1 - first date
 * @param date2 - second date
 */
export const isAfterDay = (date1: any, date2: any) => {
  if (!moment.isMoment(date1) || !moment.isMoment(date2)) return false;

  const date1Year = date1.year();
  const date1Month = date1.month();

  const date2Year = date2.year();
  const date2Month = date2.month();

  const isSameYear = date1Year === date2Year;
  const isSameMonth = date1Month === date2Month;

  if (isSameYear && isSameMonth) return date1.date() < date2.date() + 1;
  if (isSameYear) return date1Month < date2Month;

  return date1Year < date2Year;
}


const getStartOrEndOf = (startOf: boolean, unit: moment.unitOfTime.StartOf, value: any, options?: { format?: string, emptyVal?: string }) => {
  if (value) {
    const date = moment(value);
    if (date.isValid()) {
      const result = startOf ? date.startOf(unit) : date.endOf(unit);
      return result.format(options?.format ? options?.format : '');
    }
  }
  return options?.emptyVal ? options.emptyVal : '';
}

export const formatYYYYMMDD = (value: any) => formateDate(value, { format: DateFormats.YYYYMMDD_DASH });
export const formatMD = (value: any) => formateDate(value, { format: DateFormats.MD });
export const formatMDYYYY = (value: any) => formateDate(value, { format: DateFormats.MDYYY });
export const formatMMDDYYYY = (value: any) => formateDate(value, { format: DateFormats.MMDDYYYY_SLASH, emptyVal: '- -' });
export const formatYYYY = (value: any) => formateDate(value, { format: DateFormats.YYYY });
export const formatMMDDYYYYTime = (value: any) => formateDate(value, { format: DateFormats.MMDDYYYY_TIME, emptyVal: '- -' });

export const getStartOfTheDayUTC = (value: any) => getStartOf('day', value);
export const getStartOfTheDayNonUTC = (value: any) => getStartOf('day', value, { format: 'YYYY-MM-DDTHH:mm:ss' });
export const getEndOfTheDayUTC = (value: any) => getEndOf('day', value);
export const getEndOfTheDayNonUTC = (value: any) => getEndOf('day', value, { format: 'YYYY-MM-DDTHH:mm:ss' });
export const getStartOfTheYear = (value: any) => getStartOf('year', value);
export const getEndOfTheYear = (value: any) => getEndOf('year', value);
export const get30DaysAgo = (value: any, format = 'YYYY-MM-DD HH:mm') => value ? moment(value).subtract(30, 'days').format(format) : '';