
import { put, takeEvery, all, delay } from 'redux-saga/effects'
import { CREATE_TOAST, AUTO_CLOSE } from './constants';
import { hideToast, showToast } from './actionCreators';

export function* createToastSaga (action: any) {
  const toast = {
    ...action.payload.toast,
    autoClose: action.payload.toast.autoClose ? action.payload.toast.autoClose : AUTO_CLOSE,
    timestamp: Date.now()
  };
  yield put(showToast(toast));
  yield delay(toast.autoClose);
  yield put(hideToast(toast));
}

function* watchCreateToast () {
  yield takeEvery(CREATE_TOAST, createToastSaga);
}

export default function* watchAllToasts () {
  yield all([
    watchCreateToast()
  ])
}

