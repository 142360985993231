import React from 'react';
import cx from 'classnames';

export interface CardItemProps {
  isSubItem?: boolean;
  label?: string;
  value: string | number;
}

const CardItem = (props: CardItemProps) => {
  const { isSubItem, label, value } = props;

  return (
    <div className='card-item'>
      <div className={cx('card-item-value', {'small': isSubItem})}>
        {value}
      </div>

      {label && 
        <div className={cx('card-item-label', {'small': isSubItem})}>
          {label}
        </div>
      }
    </div>
  );
};

export default CardItem;
