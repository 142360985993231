import React, { Fragment } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Table, Dropdown, Button, Message} from 'semantic-ui-react';
import { HealthSystemInfo, TeamMember } from '../../store/teammanagement/types';
import { RECIPIENT_USER_ROLE_OPTIONS } from '../../store/teammanagement/constants';
import EnableUserModal from './Modals/EnableUserModal';
import { formatPhone } from '../../utils/phoneFormatHelper';
import UserAddress from './common/UserAddress';
import TableCellPopup from './common/TableCellPopup';

export interface DisabledUsersProps {
  healthSystem: HealthSystemInfo;
  isLoading: boolean;
  errorText: string;
  enableUser?: (data: object) => void;
}

interface DisabledUsersState {
  expandedRows: number[];
  enableOpen: boolean;
  viewDisabledUsers: boolean;
}

export class DisabledUsers extends React.Component<DisabledUsersProps, DisabledUsersState> {
  constructor (props: DisabledUsersProps) {
    super(props);
    this.state = {
      expandedRows: [],
      viewDisabledUsers: false,
      enableOpen: false,
    };
  }

  setViewDisabledUsers = (view: boolean) => {
    this.setState({ viewDisabledUsers: view })
  };

  openEnableModal = () => {
    this.setState({ enableOpen: true })
  };

  closeEnableModal = () => {
    this.setState({ enableOpen: false })
  };

  handleRowClick = (rowId: number) => {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);
    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter(id => id !== rowId)
      : currentExpandedRows.concat(rowId);
    this.setState({ expandedRows: newExpandedRows });
  }


  callEnableUser = (id: number) => {
    this.setState({ enableOpen: false, expandedRows: [] });
    const data = {
      teamId: this.props.healthSystem.healthSystemId,
      userId: id
    };
    if (this.props.enableUser) {
      this.props.enableUser(data);
    }
  };

  renderItemCaret = (rowId: number) => {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    if (isRowCurrentlyExpanded) {
      return <ChevronUp size={18} />;
    } else {
      return <ChevronDown size={18} />;
    }
  }

  renderDisabledUserRows = () => {
    const { healthSystem } = this.props;
    const { expandedRows } = this.state;
    const users: JSX.Element[] = [];
    if (healthSystem) {
      healthSystem.disabledMembers.forEach((tm: TeamMember, index) => {
        if (tm.isActive) {
          users.push(
            <React.Fragment key={index}>
              <Table.Row className="tm-users tm-disabled-user selectable-row" onClick={() => this.handleRowClick(index)}>
                <Table.Cell width={3}>
                  <div className="tm-name-cell">
                    <div className="icon-container">
                      {this.renderItemCaret(index)}
                    </div>
                    <TableCellPopup
                      content={tm.firstName + ' ' + tm.lastName}
                      id={`tm-disabled-name-${index}`}
                    />
                  </div>
                </Table.Cell>
                <Table.Cell width={4}>
                  <TableCellPopup
                    content={tm.email}
                    id={`tm-disabled-email-${index}`}
                  />
                </Table.Cell>
                <Table.Cell width={2} textAlign="center">
                  {tm.assignedCoveredEntities.length}
                </Table.Cell>
                <Table.Cell width={3} />
                <Table.Cell width={4}>
                  <Dropdown
                    selection
                    disabled={tm.isActive}
                    placeholder='Choose a role...'
                    options={RECIPIENT_USER_ROLE_OPTIONS}
                    value={tm.role}
                  />
                </Table.Cell>
              </Table.Row>
              {expandedRows.includes(index) &&
                <Table.Row className="tm-sub-row disabled-user" verticalAlign="top">
                  <Table.Cell width={3}>
                    <div className="header">Job Title</div>
                    <TableCellPopup
                      content={tm.jobTitle}
                      id={`job-title-${index}`}
                    />
                  </Table.Cell>
                  <Table.Cell width={9}>
                    <div className="detail-group">
                      <div>
                        <div className="header">Primary Phone</div>
                        <div>{formatPhone(tm.phone)} {tm.phoneExtension &&'ext.'+ tm.phoneExtension}</div>
                      </div>
                      <div className="team-management-address container">
                        <div className="header">Office Information</div>
                        {tm.address &&
                          <UserAddress
                            address={tm.address}
                            index={index}
                          />
                        }
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell width={4}>
                    <div>
                      <div className="sub-row-button-cell">
                        <Button disabled={false} className="app-button button-white reactivateUserButton_Pendo" onClick={() => this.openEnableModal()}>
                          Reactivate User
                        </Button>
                      </div>
                      <EnableUserModal 
                        open={this.state.enableOpen} 
                        userId={tm.id}
                        closeModal={this.closeEnableModal}
                        enableUser={this.callEnableUser}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
              }
            </React.Fragment>
          );
        }
      })
    }
    return users;
  }

  render () {
    const { healthSystem} = this.props;
    return (
      <Fragment>
        {  healthSystem.disabledMembers.length === 0 ?
          <div className="tm-page-no-data" data-testid='tm-no-data'>
            <Message>
              <Message.Content>There are no disabled users.</Message.Content>
            </Message>
          </div> :
          this.renderDisabledUserRows()}
      </Fragment>
    )
  }
}

export default DisabledUsers;
