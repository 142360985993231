import {
  RecipientActionTypes,
  GET_REQUEST_CENTER_TRANSACTION_COUNTS_FAILURE,
  GET_REQUEST_CENTER_TRANSACTION_COUNTS_SUCCESS,
  GET_REQUEST_CENTER_TRANSACTION_COUNTS,
  GET_REQUEST_CENTER_TRANSACTIONS_FAILURE,
  GET_REQUEST_CENTER_TRANSACTIONS_SUCCESS,
  GET_REQUEST_CENTER_TRANSACTIONS,
  REQUEST_CENTER_BUCKET,
  REQUEST_CENTER_TAB,
  RESET_REQUEST_CENTER_TRANSACTION_COUNTS,
  RESET_REQUEST_CENTER_TRANSACTIONS,
  UPDATE_REQUEST_CENTER_TRANSACTIONS_BUCKET,
  UPDATE_REQUEST_CENTER_TRANSACTIONS_FILTER,
  UPDATE_REQUEST_CENTER_TRANSACTIONS_PAGE,
  UPDATE_REQUEST_CENTER_TRANSACTIONS_SORT,
  SUBMIT_DISCOUNT_REQUEST,
  SUBMIT_DISCOUNT_REQUEST_SUCCESS,
  SUBMIT_DISCOUNT_REQUEST_FAILURE,
  GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES,
  GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES_SUCCESS,
  GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES_FAILURE,
  GET_DISCOUNT_REQUEST_CONTRACT_FIELDS,
  GET_DISCOUNT_REQUEST_CONTRACT_FIELDS_SUCCESS,
  GET_DISCOUNT_REQUEST_CONTRACT_FIELDS_FAILURE,
  RESET_DISCOUNT_REQUEST_FORM,
  CORRECT_DISCOUNT_REQUEST,
  CORRECT_DISCOUNT_REQUEST_SUCCESS,
  CORRECT_DISCOUNT_REQUEST_FAILURE,
} from './constants';
import { CoveredEntityModel, DiscountProgramModel, DiscountProgramFieldsModel, DiscountRequestFieldSubmitModel, DiscountRequestRequiredFieldsModel, RequestCenterTransactionCountsModel, DiscountRequestSubmitResponseModel } from './types';
import { SortingFilter } from '../appcommon/types';
import { ErrorModel } from '../applicationTypes';
import { DiscountRequestResponseModel, TransactionsFilterModel } from '../transactionscommon/types';


export function getRequestCenterTransactionCounts (tab: REQUEST_CENTER_TAB): RecipientActionTypes {
  return {
    type: GET_REQUEST_CENTER_TRANSACTION_COUNTS,
    payload: tab
  }
}

export function getRequestCenterTransactionCountsSuccess (counts: RequestCenterTransactionCountsModel): RecipientActionTypes {
  return {
    type: GET_REQUEST_CENTER_TRANSACTION_COUNTS_SUCCESS,
    payload: counts
  }
}

export function getRequestCenterTransactionCountsFailure (error: ErrorModel): RecipientActionTypes {
  return {
    type: GET_REQUEST_CENTER_TRANSACTION_COUNTS_FAILURE,
    payload: error
  }
}

export function getRequestCenterTransactions (tab: REQUEST_CENTER_TAB): RecipientActionTypes {
  return {
    type: GET_REQUEST_CENTER_TRANSACTIONS,
    payload: tab

  }
}

export function getRequestCenterTransactionsSuccess (response: DiscountRequestResponseModel): RecipientActionTypes {
  return {
    type: GET_REQUEST_CENTER_TRANSACTIONS_SUCCESS,
    payload: response
  }
}

export function getRequestCenterTransactionsFailure (error: ErrorModel): RecipientActionTypes {
  return {
    type: GET_REQUEST_CENTER_TRANSACTIONS_FAILURE,
    payload: error
  }
}

export function updateRequestCenterTransactionsBucket (bucket: REQUEST_CENTER_BUCKET): RecipientActionTypes {
  return {
    type: UPDATE_REQUEST_CENTER_TRANSACTIONS_BUCKET,
    payload: bucket
  }
}

export function updateRequestCenterTransactionsPage (page: number): RecipientActionTypes {
  return {
    type: UPDATE_REQUEST_CENTER_TRANSACTIONS_PAGE,
    payload: page
  }
}

export function updateRequestCenterTransactionsSort (sortFilter: SortingFilter): RecipientActionTypes {
  return {
    type: UPDATE_REQUEST_CENTER_TRANSACTIONS_SORT,
    payload: sortFilter
  }
}

export function updateRequestCenterTransactionsFilter (tab: REQUEST_CENTER_TAB, filter: TransactionsFilterModel): RecipientActionTypes {
  return {
    type: UPDATE_REQUEST_CENTER_TRANSACTIONS_FILTER,
    payload: {
      tab,
      filter
    }
  }
}

export function resetRequestCenterTransactionCounts (): RecipientActionTypes {
  return {
    type: RESET_REQUEST_CENTER_TRANSACTION_COUNTS
  }
}

export function resetRequestCenterTransactions (): RecipientActionTypes {
  return {
    type: RESET_REQUEST_CENTER_TRANSACTIONS
  }
}

export function getDiscountRequestProgramsAndCoveredEntities (): RecipientActionTypes {
  return {
    type: GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES,
  }
}

export function getDiscountRequestProgramsAndCoveredEntitiesSuccess (discountPrograms: DiscountProgramModel[], coveredEntities: CoveredEntityModel[]): RecipientActionTypes {
  return {
    type: GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES_SUCCESS,
    payload: {
      discountPrograms,
      coveredEntities
    }
  }
}

export function getDiscountRequestProgramsAndCoveredEntitiesFailure (errorModel: ErrorModel): RecipientActionTypes {
  return {
    type: GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES_FAILURE,
    payload: errorModel
  }
}

export function getDiscountRequestContractFields (values: DiscountRequestRequiredFieldsModel): RecipientActionTypes {
  return {
    type: GET_DISCOUNT_REQUEST_CONTRACT_FIELDS,
    payload: values
  }
}

export function getDiscountRequestContractFieldsSuccess (fields: DiscountProgramFieldsModel[]): RecipientActionTypes {
  return {
    type: GET_DISCOUNT_REQUEST_CONTRACT_FIELDS_SUCCESS,
    payload: fields
  }
}

export function getDiscountRequestContractFieldsFailure (errorModel: ErrorModel): RecipientActionTypes {
  return {
    type: GET_DISCOUNT_REQUEST_CONTRACT_FIELDS_FAILURE,
    payload: errorModel
  }
}

export function submitDiscountRequest (fields: DiscountRequestFieldSubmitModel): RecipientActionTypes {
  return {
    type: SUBMIT_DISCOUNT_REQUEST,
    payload: fields
  }
}

export function submitDiscountRequestSuccess (response: DiscountRequestSubmitResponseModel[]): RecipientActionTypes {
  return {
    type: SUBMIT_DISCOUNT_REQUEST_SUCCESS,
    payload: response
  }
}

export function submitDiscountRequestFailure (errors: string[]): RecipientActionTypes {
  return {
    type: SUBMIT_DISCOUNT_REQUEST_FAILURE,
    payload: errors
  }
}

export function correctDiscountRequest (id: number, fields: DiscountRequestFieldSubmitModel): RecipientActionTypes {
  return {
    type: CORRECT_DISCOUNT_REQUEST,
    payload: {
      id,
      fields
    }
  }
}

export function correctDiscountRequestSuccess (response: DiscountRequestSubmitResponseModel[]): RecipientActionTypes {
  return {
    type: CORRECT_DISCOUNT_REQUEST_SUCCESS,
    payload: response
  }
}

export function correctDiscountRequestFailure (errors: string[]): RecipientActionTypes {
  return {
    type: CORRECT_DISCOUNT_REQUEST_FAILURE,
    payload: errors
  }
}

export function resetDiscountRequestForm (): RecipientActionTypes {
  return {
    type: RESET_DISCOUNT_REQUEST_FORM,
  }
}
