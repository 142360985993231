import React from 'react';
import cx from 'classnames';
import { Icon } from 'semantic-ui-react';
import '../../../themes/kalderos/Common/dwolla/DwollaError.scss';
import { SUPPORT_EMAIL_LINK, SUPPORT_EMAIL, SUPPORT_PHONE } from '../../../store/applicationConstants';

export const DwollaError = ({ isModal } : { isModal?: boolean}) => {
  const errorIcon = isModal ? 
    <div className='error-icon-outline huge'><Icon name='exclamation circle' size='huge' className='error-icon huge' /></div>
    : <div className='error-icon-outline big'><Icon name='exclamation circle' size='big' className='error-icon big' /></div>
;
  return (
    <div className={cx('dwolla-error-message', {
      'modal': isModal
    })}>
      {errorIcon}
      <div className="header">We&apos;re having trouble connecting to Dwolla.</div>
      <div className="details">Please contact us at <a className="error-email" href={SUPPORT_EMAIL_LINK}>{SUPPORT_EMAIL}</a> or {SUPPORT_PHONE}. A Kalderos representative will help you complete the onboarding process.
      </div>
    </div>
  );
};