
import jwt from 'jsonwebtoken';
import { call, put, take, takeEvery, all } from 'redux-saga/effects';

import { logRocketIdentify } from '../../logRocketInit';
import { pendoInitialize } from '../../pendoInit';
import { validateUser } from '../../api/login/UserLogin';
import { storeUserToken, removeUserToken } from '../../localstore/LocalStore';

import { startupComplete, updateUserDetails, validateUserError } from './actionCreators';
import { UserModel, UserErrorModel } from './types';
import { SEND_STARTUP_REQUESTS, VALIDATE_USER } from './constants';

import { fetchDwollaEnvironment } from '../payments/common/actionCreators';
import { getHealthSystems } from '../teammanagement/actionCreators';
import { isRecipient, isReviewUser, isPayer } from '../../utils/roleUtil';
import { getLegalDocuments, getAdjustReasons } from '../appcommon/actionCreators';

import { GET_LEGAL_DOCS_FAILURE, GET_LEGAL_DOCS_SUCCESS, GET_ADJUST_REASONS_FAILURE, GET_ADJUST_REASONS_SUCCESS } from '../appcommon/constants';
import { FETCH_DWOLLA_ENVIRONMENT_FAILURE, FETCH_DWOLLA_ENVIRONMENT_SUCCESS } from '../payments/common/constants';
import { GET_HEALTH_SYSTEMS_FAILED, GET_HEALTH_SYSTEMS_SUCCESS } from '../teammanagement/constants';

const defaultLoginError: UserErrorModel = {
  isError: true,
  message: "User can't login at this moment. Please try again later.",
  status: 500
}

const recipientNotEntitledToRequestError: UserErrorModel = {
  isError: true,
  message: "You haven't been granted access to Request yet. Please contact your AO or Request account admin.",
  status: 500
}

export function* validateUserSaga (action: any) {
  try {
    const response = yield call(validateUser, action.payload);
    if (response === undefined) {
      yield put(validateUserError(defaultLoginError))
    } else {
      if (response.isError) {
        yield put(validateUserError(response));
      }
      else {
        const decoded = jwt.decode(response, { complete: true, json: true }) as { [key: string]: any };
        if (decoded !== null) {
          const userModel: UserModel = {
            exp: decoded.payload.exp,
            family_name: decoded.payload.family_name,
            given_name: decoded.payload.given_name,
            userName: decoded.payload.userName,
            roles: decoded.payload.roles,
            isAuthenticated: true,
          }
          if (userModel.roles && userModel.roles.length > 0) {
            if (isReviewUser(userModel.roles) && !isRecipient(userModel.roles)) {
              yield put(validateUserError(recipientNotEntitledToRequestError));
            } else if (isPayer(userModel.roles) && !isRecipient(userModel.roles)) {
              yield put(validateUserError(defaultLoginError));
            } else {
              storeUserToken(response);             
              yield put(updateUserDetails(userModel));
              logRocketIdentify({ email: decoded.payload.userName });
              pendoInitialize(decoded.payload.sub);
              yield call(sendStartupRequestsSaga);
            }
          } else {
            yield put(validateUserError(defaultLoginError));
          }
        }
      }
    }
  } catch (e) {
    removeUserToken();
    yield put(validateUserError(defaultLoginError));
  }
}

export function* sendStartupRequestsSaga () {
  yield put(getLegalDocuments());
  yield put(fetchDwollaEnvironment());
  yield put(getHealthSystems());
  yield put(getAdjustReasons());

  //block until each request finishes
  yield all([
    take([GET_LEGAL_DOCS_SUCCESS, GET_LEGAL_DOCS_FAILURE]),
    take([FETCH_DWOLLA_ENVIRONMENT_SUCCESS, FETCH_DWOLLA_ENVIRONMENT_FAILURE]),
    take([GET_HEALTH_SYSTEMS_SUCCESS, GET_HEALTH_SYSTEMS_FAILED]),
    take([GET_ADJUST_REASONS_SUCCESS, GET_ADJUST_REASONS_FAILURE]),
  ])

  yield put(startupComplete());
}

function* watchValidateUser () {
  yield takeEvery(VALIDATE_USER, validateUserSaga);
}

function* watchSendStartupRequestsSaga () {
  yield takeEvery(SEND_STARTUP_REQUESTS, sendStartupRequestsSaga);
}

export default function* watchAllLogins () {
  yield all([
    watchValidateUser(),
    watchSendStartupRequestsSaga()
  ])
}
