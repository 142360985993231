import React from 'react';

export interface ModalFooterContentProps {
  buttonText: string;
  cancelButtonText: string;
  handleModalClose: () => void;
  handleModalAction: () => void;
}

export const ModalFooterContent: React.FunctionComponent<ModalFooterContentProps> = (props: ModalFooterContentProps) => {

  const { buttonText, cancelButtonText, handleModalAction, handleModalClose } = props;
  return (
    <div className='modal-footer'>
      <button onClick={handleModalClose} className='app-button button-white' type='button'>{cancelButtonText}</button>
      <button onClick={handleModalAction} className='app-button button-dark' type='button'>{buttonText}</button>
    </div>
  )
}


export default ModalFooterContent
