import React from 'react';
import cx from 'classnames';

import '../../../../themes/kalderos/Common/Drawer/Components/Item.scss';
import { formatEmpty } from '../../../../utils/dataUtil';
import DataPopup from '../../DataPopup';

export interface ItemProps {
  value?: string | number | React.ReactNode;
  label: string;
  isGpoOverride?: boolean;
  isOriginal?: boolean;
  isAutoAdjusted?: boolean;
  isManualAdjusted?: boolean;
  displayAsRow?: boolean;
  displayPopup?: boolean;
}

export const Item: React.FunctionComponent<ItemProps> = (props: ItemProps) => {
  const { isAutoAdjusted, isManualAdjusted, isOriginal, displayAsRow, displayPopup } = props;
  const hasIndicator= isAutoAdjusted || isManualAdjusted;
  return (
    <div className={cx('drawer-item', {'no-indicator': !hasIndicator && !displayAsRow, 'drawer-item-row' : displayAsRow} )}>
      {hasIndicator &&
        <div className={cx('adjustment-indicator', {
          'auto-adjusted' : isAutoAdjusted && !isManualAdjusted,
          'manual-adjusted' : isManualAdjusted})}
        />}
      <div className={cx({'item-row' : displayAsRow})}>
        {displayPopup ?
          <div data-private className={cx('item-value', { 'original' : isOriginal })}>
            <DataPopup content={formatEmpty(props.value)} id={props.label} small />
          </div> :
          <div data-private className={cx('item-value', { 'original' : isOriginal })}>
            {formatEmpty(props.value)}
          </div>
        }
        <div className="item-label">{props.label}</div>
        {props.isGpoOverride &&
      <div className={cx('item-value', { 'original' : isOriginal })}>(GPO Override)</div>}
      </div>
    </div>
  )
}

export default Item;
