import {
  GET_RECIPIENT_PAYMENT_HISTORY,
  UPDATE_RECIPIENT_PAYMENT_HISTORY,
  GET_RECIPIENT_PAYMENT_HISTORY_ERROR,
  UPDATE_RECIPIENT_PAYMENTS_HISTORY_SORT_FILTERS,
  UPDATE_RECIPIENT_PAYMENT_HISTORY_PAGE,
  RESET_RECIPIENT_PAYMENT_STATE,
  RETRY_PAYMENTS,
  RETRY_PAYMENTS_SUCCESS,
  RETRY_PAYMENTS_FAILURE,
  GET_CREDITS_PER_MANUFACTURER,
  GET_CREDITS_PER_MANUFACTURER_SUCCESS,
  GET_CREDITS_PER_MANUFACTURER_FAILURE,
  UPDATE_CREDITS_PER_MANUFACTURER_SORT_FILTERS,
  UPDATE_CREDITS_PER_MANUFACTURER_PAGE,
  GET_ASSOCIATED_COVERED_ENTITIES,
  GET_ASSOCIATED_COVERED_ENTITIES_SUCCESS,
  GET_ASSOCIATED_COVERED_ENTITIES_FAILURE,
  UPDATE_ASSOCIATED_COVERED_ENTITIES_SORT,
  GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO,
  GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO_SUCCESS,
  GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO_FAILURE
} from './constants';
import {
  RecipientPaymentsState,
  RecipientPaymentHistoryModel,
  RecipientCoveredEntityBillingInfoModel,
  RecipientCreditsPerManufacturerModel
} from './types';
import { PaymentsRecipientActionTypes } from './constants'

const RecipientPaymentHistoryDefaultState: RecipientPaymentHistoryModel = {
  paymentTransactions: [],
  isPaymentHistoryLoading: false,
  isPaymentHistoryError: false,
  currentPage: 1,
  currentApiPage: 1,
  totalCount: 0,
  sortBy: 'datePaid',
  isSortDesc: true
}

const RecipientCreditsPerManufacturerState: RecipientCreditsPerManufacturerModel = {
  creditsPerManufacturer: [],
  isLoading: false,
  isError: false,
  sortBy: 'id340b',
  isSortDesc: false,
  currentPage: 1,
  currentApiPage: 1,
  totalCount: 0
}

const RecipientCoveredEntityBillingInfoDefaultState: RecipientCoveredEntityBillingInfoModel = {
  coveredEntities: [],
  isBillingInfoLoading: false,
  isBillingInfoError: false,
  sortBy: 'idCode',
  isSortDesc: false
}

const initialState: RecipientPaymentsState = {
  paymentHistory: RecipientPaymentHistoryDefaultState,
  coveredEntityBillingInfo: RecipientCoveredEntityBillingInfoDefaultState,
  creditsPerManufacturerModel: RecipientCreditsPerManufacturerState,
}


export function recipientPaymentsReducer (
  state = initialState,
  action: PaymentsRecipientActionTypes
): RecipientPaymentsState {
  switch (action.type) {

    case GET_RECIPIENT_PAYMENT_HISTORY:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          isPaymentHistoryLoading: true
        }
      }

    case GET_CREDITS_PER_MANUFACTURER:
      return {
        ...state,
        creditsPerManufacturerModel: {
          ...state.creditsPerManufacturerModel,
          isLoading: true
        }
      }

    case GET_CREDITS_PER_MANUFACTURER_SUCCESS:
      return {
        ...state,
        creditsPerManufacturerModel: {
          ...state.creditsPerManufacturerModel,
          creditsPerManufacturer: action.payload.creditsPerManufacturer,
          currentApiPage: action.payload.currentApiPage,
          isLoading: false,
          totalCount: action.payload.totalCount,
        }
      }

    case GET_CREDITS_PER_MANUFACTURER_FAILURE:
      return {
        ...state,
        creditsPerManufacturerModel: {
          ...state.creditsPerManufacturerModel,
          isLoading: false
        }
      }

    case UPDATE_CREDITS_PER_MANUFACTURER_SORT_FILTERS:
      return {
        ...state,
        creditsPerManufacturerModel: {
          ...state.creditsPerManufacturerModel,
          currentPage: 1,
          isLoading: true,
          isSortDesc: !action.payload.isSortAsc,
          sortBy: action.payload.sortBy
        }
      }

    case UPDATE_CREDITS_PER_MANUFACTURER_PAGE:
      return {
        ...state,
        creditsPerManufacturerModel: {
          ...state.creditsPerManufacturerModel,
          currentPage: action.payload
        }
      }

    case UPDATE_RECIPIENT_PAYMENT_HISTORY:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          isPaymentHistoryLoading: false,
          isPaymentHistoryError: false,
          paymentTransactions: action.payload.paymentTransactions,
          currentApiPage: action.payload.page,
          totalCount: action.payload.totalCount
        }
      }

    case GET_RECIPIENT_PAYMENT_HISTORY_ERROR:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          isPaymentHistoryLoading: false,
          isPaymentHistoryError: true
        }
      }

    case UPDATE_RECIPIENT_PAYMENTS_HISTORY_SORT_FILTERS:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          isPaymentHistoryLoading: true,
          sortBy: action.payload.sortBy,
          isSortDesc: !action.payload.isSortAsc,
          currentPage: 1
        }
      }

    case UPDATE_RECIPIENT_PAYMENT_HISTORY_PAGE:
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          currentPage: action.payload
        }
      }

    case RESET_RECIPIENT_PAYMENT_STATE:
      return {
        ...initialState
      }
    case RETRY_PAYMENTS:
      return {
        ...state
      }
    case RETRY_PAYMENTS_SUCCESS:
      return {
        ...state
      }
    case RETRY_PAYMENTS_FAILURE:
      return {
        ...state
      }

    case GET_ASSOCIATED_COVERED_ENTITIES:
      return {
        ...state,
        coveredEntityBillingInfo: {
          ...state.coveredEntityBillingInfo,
          isBillingInfoLoading: true,
          isBillingInfoError: false,
        }
      }

    case GET_ASSOCIATED_COVERED_ENTITIES_SUCCESS:
      return {
        ...state,
        coveredEntityBillingInfo: {
          ...state.coveredEntityBillingInfo,
          coveredEntities: action.payload.coveredEntities,
          isBillingInfoLoading: false,
        }
      }

    case GET_ASSOCIATED_COVERED_ENTITIES_FAILURE:
      return {
        ...state,
        coveredEntityBillingInfo: {
          ...state.coveredEntityBillingInfo,
          isBillingInfoLoading: false,
          isBillingInfoError: true
        }
      }

    case UPDATE_ASSOCIATED_COVERED_ENTITIES_SORT:
      return {
        ...state,
        coveredEntityBillingInfo: {
          ...state.coveredEntityBillingInfo,
          isBillingInfoLoading: true,
          sortBy: action.payload.sortBy,
          isSortDesc: !action.payload.isSortAsc
        }
      }

    case GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO:
      return {
        ...state,
        coveredEntityBillingInfo: {
          ...state.coveredEntityBillingInfo,
          isBillingInfoLoading: true,
          isBillingInfoError: false,
        }
      }

    case GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO_SUCCESS:
      return {
        ...state,
        coveredEntityBillingInfo: {
          ...state.coveredEntityBillingInfo,
          accountAdmin: action.payload.accountAdmin,
          controller: action.payload.controller,
          isBillingInfoLoading: false,
        }
      }

    case GET_COVERED_ENTITY_HEALTH_SYSTEM_INFO_FAILURE:
      return {
        ...state,
        coveredEntityBillingInfo: {
          ...state.coveredEntityBillingInfo,
          isBillingInfoLoading: false,
          isBillingInfoError: true
        }
      }


    default:
      return state
  }
}

