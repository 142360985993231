import { LoginState } from './types';
import { LoginActionTypes, UPDATE_USER_DETAILS, VALIDATE_USER_ERROR, CLEAR_USER_DETAILS, VALIDATE_USER, STARTUP_COMPLETE } from './constants';

const initialState: LoginState = {
  user: {
    userName: null,
    family_name: null,
    given_name: null,
    roles: null,
    exp: 0,
    isAuthenticated: undefined
  },
  error: {
    isError: false,
    message: '',
    status: 0
  },
  isLoading: false,
  startUpComplete: false,
}

export function loginReducer (
  state = initialState,
  action: LoginActionTypes
): LoginState {
  switch (action.type) {
    case VALIDATE_USER:
      return {
        ...state,
        isLoading: true
      }
    case VALIDATE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        user: action.payload.user,
        isLoading: false,
        error: {
          ...state,
          isError: false,
          message: '',
          status: 0,
        }
      }
    case CLEAR_USER_DETAILS:
      return {
        ...state,
        user: {
          userName: null,
          family_name: null,
          given_name: null,
          roles: null,
          exp: 0,
          isAuthenticated: false
        },
      }
    case STARTUP_COMPLETE:
      return {
        ...state,
        startUpComplete: true
      }
    default:
      return state
  }
}
