import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { CreditsPerManufacturerModel } from '../../../store/payments/recipient/types';
import { NO_CREDITS_HEADER, NO_CREDITS_MESSAGE, RECIPIENT_CREDITS_PER_MANUFACTURER_HEADERS } from '../../../store/payments/recipient/constants';
import { RecipientBasicTable } from './index'
import { connect } from 'react-redux';
import { RootState } from '../../../store/RootState';
import { getCreditsPerManufacturer, updateCreditsPerManufacturerPage, updateCreditsPerManufacturerSortFilters } from '../../../store/payments/recipient/actionCreators'
import { SortingFilter } from '../../../store/appcommon/types';
import TablePagination from '../../common/filter/TablePagination';
import { PaginationHelper } from '../../common/paginationHelper';
import { UI_PAGE_SIZE } from '../../../store/applicationConstants';
import EmptyTransactions from '../../common/transactions/EmptyTransactions';
import { getPagedCreditsPerManufacturer } from '../../../store/payments/recipient/paymentsSelectors';

interface CreditsPerManufacturerProps {
  isLoading: boolean;
  isError: boolean;
  creditsPerManufacturer: CreditsPerManufacturerModel[];
  getCreditsPerManufacturer: () => void;
  updateCreditsPerManufacturerSortFilters: (sortFilters: SortingFilter) => void;
  updateCreditsPerManufacturerPage: (pageNumber: number) => void;
  currentPage: number;
  totalCount: number
}

export class CreditsPerManufacturer extends React.Component<CreditsPerManufacturerProps> {

  handleSortColumn = (sort: SortingFilter) => {
    this.props.updateCreditsPerManufacturerSortFilters(sort)
  }

  handlePageChange = (pageNumber: number) => {
    this.props.updateCreditsPerManufacturerPage(pageNumber)
  }

  componentDidMount () {
    this.props.getCreditsPerManufacturer();
  }

  render () {
    const { isLoading, creditsPerManufacturer, currentPage, totalCount } = this.props;
    const pagingStatus = PaginationHelper(UI_PAGE_SIZE, currentPage, totalCount || 0);

    return (
      <div>
        {creditsPerManufacturer?.length !== 0 && <div className='credits-info-banner'>
          These are credits the manufacturer accrues towards your account when the total reversed amount has exceeded the total approved discount amount. Credits will automatically be deducted from paid amounts in your remittance until the credit balance reaches zero.
        </div>}
        <div className="recipient-payments-history">
          {isLoading && <Dimmer active inverted><Loader inverted content='Loading' /></Dimmer>}

          {creditsPerManufacturer?.length > 0 &&
            <div className="recipient-payment-body">
              <TablePagination
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
                pageSize={UI_PAGE_SIZE}
                pagingStatus={pagingStatus}
                totalCount={totalCount}
              />

              <RecipientBasicTable
                table="creditsPerManufacturer"
                data={creditsPerManufacturer}
                headers={RECIPIENT_CREDITS_PER_MANUFACTURER_HEADERS}
                handleSortClick={this.handleSortColumn}
              />
            </div>
          }
          {!isLoading && creditsPerManufacturer?.length === 0 &&
            <EmptyTransactions emptyTransactionsHeader={NO_CREDITS_HEADER} emptyTransactionsMessage={NO_CREDITS_MESSAGE} />
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.PaymentsState.recipient.creditsPerManufacturerModel.isLoading,
  isError: state.PaymentsState.recipient.creditsPerManufacturerModel.isError,
  creditsPerManufacturer: getPagedCreditsPerManufacturer(state),
  currentPage: state.PaymentsState.recipient.creditsPerManufacturerModel.currentPage,
  totalCount: state.PaymentsState.recipient.creditsPerManufacturerModel.totalCount
});

const mapDispatchToProps = (dispatch: any) => ({
  getCreditsPerManufacturer: () => dispatch(getCreditsPerManufacturer()),
  updateCreditsPerManufacturerSortFilters: (sortFilters: SortingFilter) => dispatch(updateCreditsPerManufacturerSortFilters(sortFilters)),
  updateCreditsPerManufacturerPage: (pageNumber: number) => dispatch(updateCreditsPerManufacturerPage(pageNumber))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditsPerManufacturer)