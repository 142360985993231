import React from 'react';
import { AlertTriangle } from 'react-feather';
import '../../../themes/kalderos/Common/Errors/ErrorBanner.scss';

export interface ErrorBannerProps{
  title?: string;
  errorText?: string
  errorContent?: React.ReactNode
}

export const ErrorBanner: React.FunctionComponent<ErrorBannerProps> = ({title, errorText, errorContent}) => {
  return (
    <div className="error-banner">
      <div className="banner-elements">
        <div className="icon-wrapper">
          <AlertTriangle />
        </div>
        <div>
          {title && <div className="title"><b>{title}</b></div>}
          <div className="contents">{errorText || errorContent}</div>
        </div>
      </div>
    </div>
  )
}
