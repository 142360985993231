import { createSelector } from 'reselect';
import { getPagedData } from '../../utils/datatableHelper';
import { RootState } from '../RootState';
import { API_PAGE_SIZE, UI_PAGE_SIZE } from './constants';

const selectTransactions = (state: RootState) => state.RemittanceState.transactions.transactions;
const selectCurrentPage = (state: RootState) => state.RemittanceState.transactions.currentPage;

export const isRemittanceTransactionsLoading = (state: RootState) => state.RemittanceState.transactions.isLoading || state.RemittanceState.counts.isLoading || state.TransactionsCommon.reversalsModal.isLoading;

export const getPagedRemittanceTransactions = createSelector(
  [selectTransactions, selectCurrentPage],
  (transactions, currentPage) => {
    const pagedTransactions = getPagedData(transactions, currentPage, UI_PAGE_SIZE, API_PAGE_SIZE);
    return pagedTransactions
  }
)
