import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Checkbox, Icon } from 'semantic-ui-react';
import cx from 'classnames';
import { Formik } from 'formik';
import * as yup from 'yup';

import Layout from '../../Layout';
import { RootState } from '../../../../store/RootState';
import { CandidateCoveredEntity, CandidateHealthSystem } from '../../../../store/onboarding/types';
import { setSelectedOrphanCes, setHealthSystemName, checkUniqueHealthSystemName } from '../../../../store/onboarding/actionCreators';
import { getOrphanedCes, getCandidateHealthSystems } from '../../../../store/onboarding/selectors';
import HealthSystemInformationModal from './HealthSystemInformationModal';

interface AssociateCoveredEntitiesProps {
  isLoading: boolean,
  errorText: string,
  candidateHealthSystems: CandidateHealthSystem[],
  orphanedCes: CandidateCoveredEntity[],
  selectedHs: CandidateHealthSystem,
  healthSystemNameCheckLoaded: boolean,
  healthSystemNameUnique: boolean,
  healthSystemNameIsLoading: boolean,
  lastNameChecked: string,
  onBack: () => void,
  onNext: () => void,
  setSelectedOrphanCes: (orphanCes: CandidateCoveredEntity[]) => void,
  setHealthSystemName: (name: string) => void
  checkUniqueHealthSystemName: (name: string) => void
}

interface CoveredEntityModel {
  id: string,
  label: React.ReactNode,
  value: string,
  checked: boolean,
  disabled: boolean,
}

const HealthSystemIntroduction: React.FunctionComponent = () => {
  return (
    <div className="sub-title">
    We&apos;ve found the following covered entities associated with your email as the Authorizing Official in the HRSA Database. 
    To set up your account, you will assign the covered entities below to one (or more) Health Systems.
      <HealthSystemInformationModal />
    </div>);
}

const AssociateCoveredEntities: React.FunctionComponent<AssociateCoveredEntitiesProps> = (props: AssociateCoveredEntitiesProps) => {

  const { checkUniqueHealthSystemName, healthSystemNameCheckLoaded, healthSystemNameUnique, onNext } = props;
  const { isExistingHealthSystem } = props.selectedHs;
  const isFirstHealthSystem = props.candidateHealthSystems && props.candidateHealthSystems.length === 0;

  const [selectedIds, setSelectedIds] = useState<string[]>(getInitialSelectedIds());
  const [searchText, setSearchText] = useState<string>('');
  const [healthSystemNameErrorVisible, setHealthSystemNameErrorVisible] = useState<boolean>(false);
  const [clickedNext, setClickedNext] = useState<boolean>(false);
  
  function getInitialSelectedIds () {
    const initialSelection: string[] = props.selectedHs.ceList.map((ce: any) => (
      ce.id340B
    ));
    if (props.selectedHs.orphanCeList && props.selectedHs.orphanCeList.length > 0) {
      const initialSelectedOrphans: string[] = props.selectedHs.orphanCeList.map((ce: any) => (
        ce.id340B
      ));
      initialSelection.push(...initialSelectedOrphans);
    }
    return initialSelection || [];
  }

  function handleSelectedChange (ce: CoveredEntityModel) {
    if (!ce.disabled) {
      const i = selectedIds.indexOf(ce.id);
      const newList = [...selectedIds];
      if (i >= 0) {
        newList.splice(i, 1);
      } else {
        newList.push(ce.id);
      }
      setSelectedIds(newList);
    }
  }

  function handleSearchChange (text: string) {
    setSearchText(text);
  }

  function mapCandidateCoveredEntities (candidates: CandidateCoveredEntity[], editable: boolean): CoveredEntityModel[] {
    return candidates.map(ce => ({
      id: ce.id340B,
      label: <label><span className="ce-id">{ce.id340B}</span> <span>{ce.entityName}</span></label>,
      value: `${ce.id340B} ${ce.entityName}`,
      checked: editable ? (selectedIds ? selectedIds.indexOf(ce.id340B) >= 0 : false) : true,
      disabled: !editable
    }));
  }

  function getCoveredEntityList () {
    let mappedCes: CoveredEntityModel[] = [];

    mappedCes.push(...mapCandidateCoveredEntities(props.selectedHs.ceList, false));
    mappedCes.push(...mapCandidateCoveredEntities(props.orphanedCes, true));

    // todo find out if this is performant with 200 ce's
    // if orphan was saved in selected hs, add a check
    if (props.selectedHs.orphanCeList) {
      props.selectedHs.orphanCeList.forEach((ce: CandidateCoveredEntity) => {
        mappedCes.forEach((mappedCe: CoveredEntityModel) => {
          if (ce.id340B === mappedCe.id && selectedIds.includes(ce.id340B)) { 
            mappedCe.checked = true;
          }
        })
      })
    }

    if (searchText){
      mappedCes = mappedCes.filter(m=> m.value.toLowerCase().includes(searchText.toLowerCase()));
    }

    return mappedCes;
  }

  function onSubmit (values: any) {
    const selectedOrphans = props.orphanedCes.filter(ce => selectedIds.includes(ce.id340B));
    if (selectedOrphans && selectedOrphans.length > 0) {
      props.setSelectedOrphanCes(selectedOrphans);
    }
    if (values.healthSystemName && !props.selectedHs.isExistingHealthSystem) {
      props.setHealthSystemName(values.healthSystemName)
    }

    if (isExistingHealthSystem) {
      onNext();
    } else {
      checkUniqueHealthSystemName(values.healthSystemName.trim());
      setClickedNext(true);
    }
  }
  
  useEffect(() => {
    if (healthSystemNameUnique && clickedNext) {
      onNext();
    } else if (healthSystemNameCheckLoaded && !healthSystemNameUnique) {
      setHealthSystemNameErrorVisible(true);
    }
  }, [healthSystemNameCheckLoaded, healthSystemNameUnique, onNext, clickedNext]);

  return (
    <Layout
      isLoading={props.isLoading || props.healthSystemNameIsLoading}
      errorText={props.errorText}
      stepNumber={1}>
      <Formik
        initialValues={{    
          healthSystemName: props.selectedHs.healthSystemName ? props.selectedHs.healthSystemName : ''
        }}
        validationSchema={
          yup.object().shape({
            healthSystemName: yup
              .string()
              .max(100)
              .required()
          })
        }
        enableReinitialize={true}
        onSubmit={(values) => {onSubmit(values)}}
      >
        {({ isValid, handleChange, handleBlur, handleSubmit, errors, touched, values }) => {
          const isButtonDisabled = !isValid || selectedIds.length === 0 || props.healthSystemNameIsLoading || (!isExistingHealthSystem && values.healthSystemName.trim().length === 0);

          const handleNameChange = () => {
            if (healthSystemNameErrorVisible) {
              setHealthSystemNameErrorVisible(false);
            }
          };

          return (
            <Form className="input-form full" onSubmit={handleSubmit}>
              <div className="onboarding-layout-header">
                <div className="title">1. Associate Covered Entities &amp; Health Systems</div>
                {isFirstHealthSystem ?  
                  <HealthSystemIntroduction /> :
                  <div className="sub-title">
                  Assign covered entities to your Health System and provide a name, if needed.
                  </div> 
                }
              </div>
              <div className="onboarding-layout-children">
                <div className="onboarding-layout-body associate-covered-entities input-form">
                  {isFirstHealthSystem && 
                  <div className="instructional">
                    <div className="title">Health System Set Up:</div>
                    <div className="text"> Name your new Health System and assign its covered entities. You will have the opportunity to return and create additional Health Systems, if needed, at the end of setup.</div>
                  </div>}
                  {!isExistingHealthSystem ?
                    <Fragment>
                      <Form.Input data-private
                        name="healthSystemName" 
                        label="Health System Name" 
                        placeholder="Enter name"
                        value={values.healthSystemName}
                        onChange={(e: any) => {handleChange(e); handleNameChange();}}
                        onBlur={handleBlur}
                        className={cx({'input-error': (errors?.healthSystemName && touched?.healthSystemName) || healthSystemNameErrorVisible})}
                      /> 
                      {errors.healthSystemName && touched.healthSystemName && <div className="error-text">Health system name invalid</div>}
                      {healthSystemNameErrorVisible && <div className="error-text">This health system name is already taken</div>}
                    </Fragment>:
                    <div className="health-system-name">
                      <div className="label">Health System Name</div>
                      <div className="value" data-private>{props.selectedHs.healthSystemName}</div>
                    </div>
                  }
                  <Form.Input data-private
                    type="text"
                    label="Covered Entities"
                    placeholder="Search"
                    onChange={(e, d) => handleSearchChange(d.value)}
                  />
                  <div className="covered-entity-search">
                  
                    { getCoveredEntityList().map(orphan => {
                      return (
                        <div key={orphan.id} className={cx('covered-entity-row onboarding-checkbox', { 'disabled': orphan.disabled })} onClick={() => handleSelectedChange(orphan)}>
                          <Checkbox data-private
                            label={orphan.label}
                            checked={orphan.checked}
                            disabled={orphan.disabled}
                          /> 
                        </div>
                      )
                    })
                    }
                  </div>
                </div>
              </div>

              <div className="onboarding-layout-footer">
                {!isFirstHealthSystem &&
                <div className="onboarding-layout-navigation">
                  <button className="app-button" onClick={()=>props.onBack()} type="button">
                    <Icon name="chevron left" size="large" />Back
                  </button>
                </div>
                }
                <div className="onboarding-layout-cta">
                  <button 
                    className="app-button button-dark"
                    disabled={isButtonDisabled}
                    type="submit">
                    {isExistingHealthSystem ? 'Next' : 'Create Health System'}
                  </button>
                </div>
              </div>
            </Form>
          )}}
      </Formik>
    </Layout>
  );
}


const mapStateToProps = (state: RootState) => ({
  info: state.OnboardingState.authorizingOfficialInfoStepModel.info,
  isLoading: state.OnboardingState.isLoading,
  errorText: state.OnboardingState.errorText,
  candidateHealthSystems: getCandidateHealthSystems(state),
  orphanedCes: getOrphanedCes(state),
  selectedHs: state.OnboardingState.associateCesAndHsStepModel.selectedHealthSystem,
  healthSystemNameCheckLoaded: state.OnboardingState.associateCesAndHsStepModel.checkUniqueHealthSystemName.isLoaded,
  healthSystemNameUnique: state.OnboardingState.associateCesAndHsStepModel.checkUniqueHealthSystemName.unique,
  healthSystemNameIsLoading: state.OnboardingState.associateCesAndHsStepModel.checkUniqueHealthSystemName.isLoading,
  lastNameChecked: state.OnboardingState.associateCesAndHsStepModel.checkUniqueHealthSystemName.name
});

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedOrphanCes: (selected: CandidateCoveredEntity[]) => dispatch(setSelectedOrphanCes(selected)),
  setHealthSystemName: (name: string) => dispatch(setHealthSystemName(name)),
  checkUniqueHealthSystemName: (name: string) => dispatch(checkUniqueHealthSystemName(name))
});


export default connect(mapStateToProps, mapDispatchToProps)(AssociateCoveredEntities);