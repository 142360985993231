import React from 'react'
import { Dropdown } from 'semantic-ui-react';
import { ArrowRight, Plus } from 'react-feather';
import CloseButton from '../common/CloseButton';

const DropdownOption = ({ title, text, onClick, id }: { id: string, title: string, text: string, onClick: () => void }) =>
  <div id={id} className='menu-item' onClick={onClick}>
    <div className='flex-row flex-grow'>
      <div className='flex-column flex-grow'>
        <div className='title epsilon'>{title}</div>
        <div className='text eta-regular'>{text}</div>
      </div>
      <div className='flex-column justify-center'>
        <ArrowRight />
      </div>
    </div>
  </div>

const DropdownHeader = ({ title, onClick }: { title: string, onClick: () => void }) =>
  <div className='menu-header'>
    <div className='flex-row flex-grow'>
      <div className='flex-column flex-grow'>
        <div className='header-title beta'>{title}</div>
      </div>
      <div className='flex-column justify-center'>
        <CloseButton onClick={onClick} />
      </div>
    </div>
  </div>

interface SubmitMenuProps {
  disabled: boolean,
  onFileUploadClick: () => void;
  onManualEntryClick: () => void;
}

//pass props to the div to make the Popup component work when the trigger is a custom component. More info: https://github.com/Semantic-Org/Semantic-UI-React/issues/1902
const SubmitMenu = ({ disabled, onFileUploadClick, onManualEntryClick,  ...props }: SubmitMenuProps) =>
  <div {...props} className='submit-menu'>
    <Dropdown
      id = "disReqSubmitNew_Pendo"
      text='Submit New'
      labeled
      button
      icon={<Plus color='white' size='18'></Plus>}
      header={<DropdownHeader title='Submit New' onClick={() => {}} />}
      disabled={disabled}
      options={[
        <DropdownOption id="disReqUploadFile_Pendo" key={1} title='Upload a File' text='Find a template for your NDC11 and upload.' onClick={onFileUploadClick} />,
        <DropdownOption id="disReqEnterNew_Pendo" key={2} title='Enter Discount Request' text='Add your discount request data manually.' onClick={onManualEntryClick} />
      ]}
    />
  </div>

export default SubmitMenu;
