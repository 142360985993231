import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Tab } from 'semantic-ui-react';
import PageHeader from '../common/navigation/PageHeader';
import HealthSystemInfo from './HealthSystemInfo';
import CoveredEntityInfo from './CoveredEntityInfo';

const CoveredEntityInfoCenterTabMap: { [key: string]: number } = {
  'ceinfo': 0,
  'healthsystem': 1
}

export function getCoveredEntityInfoCenterTabIndex (path: string): number {
  return CoveredEntityInfoCenterTabMap[path.substr(path.lastIndexOf('/') + 1)];
}
export interface CoveredEntityInfoCenterProps {
  activeIndex: number;
}
export class CoveredEntityInfoCenter extends React.Component<CoveredEntityInfoCenterProps> {
  render () {
    const panes = [
      {
        menuItem: {
          as: Link,
          key: 'Covered Entities',
          content: 'Covered Entities',
          to: '/recipients/coveredentityinfo/ceinfo',
        },
        render: () => <Tab.Pane><CoveredEntityInfo /></Tab.Pane>
      },
      {
        menuItem: {
          as: Link,
          key: 'Health System Info',
          content: 'Health System Info',
          to: '/recipients/coveredentityinfo/healthsystem',
        },
        render: () => <Tab.Pane><HealthSystemInfo/></Tab.Pane>
      }
    ];

    return (
      <div className="covered-entity-container v-scroll-auto">
        <PageHeader className='ce-center-page-header' text='Covered Entity’s Information' noBorder />
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} activeIndex={this.props.activeIndex} className={cx('tab-controller fit-height divided')} />
      </div>
    )
  }
}

export default CoveredEntityInfoCenter;
