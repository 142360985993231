import React from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader, Table } from 'semantic-ui-react';

import { getEditHistory } from '../../../store/coveredentityinfo/pharmacymanagement/actioncreators';
import { NO_EDIT_HISTORY_HEADER, NO_EDIT_HISTORY_MESSAGE } from '../../../store/coveredentityinfo/pharmacymanagement/constants';
import { PharmacyEditHistoryModel } from '../../../store/coveredentityinfo/pharmacymanagement/types';
import { RootState } from '../../../store/RootState';
import EmptyTransactions from '../../common/transactions/EmptyTransactions';
import { formatMMDDYYYYTime } from '../../../utils/dateHelper';
import { formatEmpty } from '../../../utils/dataUtil';

export interface EditHistoryProps {
  isLoading: boolean;
  pharmacyEditHistoryModel: PharmacyEditHistoryModel;
  getEditHistory: () => void;
}
export class EditHistory extends React.Component<EditHistoryProps> {

  componentDidMount () {
    this.props.getEditHistory();
  }

  render () {
    const { isLoading, pharmacyEditHistoryModel } = this.props;

    return (
      <>
        {isLoading && <Dimmer active inverted><Loader className="recipient-request-center-loader" inverted content='Details Loading...' /></Dimmer>}

        {!isLoading && pharmacyEditHistoryModel?.pharmacyEditViews.length === 0 &&
          <EmptyTransactions emptyTransactionsHeader={NO_EDIT_HISTORY_HEADER} emptyTransactionsMessage={NO_EDIT_HISTORY_MESSAGE} />
        }
        
        {!isLoading && pharmacyEditHistoryModel?.pharmacyEditViews.length !== 0 &&
          <div className='edit-history'>
            <div id="dtWrapper" className="basic-table-wrapper">
              <Table unstackable className="basic-table">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className="eta header-cell">
                      Date
                    </Table.HeaderCell>
                    <Table.HeaderCell className="eta header-cell">
                      NPI
                    </Table.HeaderCell>
                    <Table.HeaderCell className="eta header-cell">
                      Section
                    </Table.HeaderCell>
                    <Table.HeaderCell className="eta header-cell">
                      Action
                    </Table.HeaderCell>
                    <Table.HeaderCell className="eta header-cell">
                      Value Before
                    </Table.HeaderCell>
                    <Table.HeaderCell className="eta header-cell">
                      Value After
                    </Table.HeaderCell>
                    <Table.HeaderCell className="eta header-cell">
                      Removal Reason
                    </Table.HeaderCell>
                    <Table.HeaderCell className="eta header-cell">
                      User
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {pharmacyEditHistoryModel.pharmacyEditViews.map((i: any, index: number) => {
                    return (
                      <Table.Row key={index} >
                        <Table.Cell width={2}>{formatMMDDYYYYTime(i.date)}</Table.Cell>
                        <Table.Cell width={2}>{i.npi}</Table.Cell>
                        <Table.Cell width={2}>{i.section}</Table.Cell>
                        <Table.Cell width={2}>{i.action}</Table.Cell>
                        <Table.Cell width={2}>{i.valueBefore}</Table.Cell>
                        <Table.Cell width={2}>{i.valueAfter}</Table.Cell>
                        <Table.Cell width={2}>{formatEmpty(i.removalReasons)}</Table.Cell>
                        <Table.Cell width={2}>{i.user}</Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table>
            </div>
          </div>
        }
      </>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.PharmacyManagementState.pharmacyEditHistoryModel.isLoading,
  pharmacyEditHistoryModel: state.PharmacyManagementState.pharmacyEditHistoryModel
});


const mapDispatchToProps = (dispatch: any) => ({
  getEditHistory: () => dispatch(getEditHistory())
});
export default connect(mapStateToProps, mapDispatchToProps)(EditHistory);