import { RootState } from '../RootState'

export const getActiveHealthSystemId = (state: RootState) => state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.healthSystemId
export const getActiveHealthSystemCoveredEntities = (state: RootState) => state.TeamManagementState.teamManagementModel.activeHealthSystemInfo.coveredEntities
export const getActiveHealthSystemRole = (state: RootState) => state.TeamManagementState.teamManagementModel.currentRole;
export const getHealthSystemError = (state: RootState) => state.TeamManagementState.teamManagementModel.errorText;
export const hasVerifiedFundingSource = (state: RootState) => state.TeamManagementState.teamManagementModel.hasVerifiedFundingSource;
export const getAddUserModalNewUserAdded = (state: RootState) => state.TeamManagementState.addUserModal.newUserAdded;
export const getEntitledHealthSystems = (state: RootState) => state.TeamManagementState.entitledHealthSystems;
export const getTeamManagementUserId = (state: RootState) => state.TeamManagementState.userId;
export const getPreSelectedHealthSystemID = (state: RootState) => state.TeamManagementState.preSelectedHealthSystemId;

export const selectHealthSystemOnboardingRoles = (state: RootState) => state.TeamManagementState.teamManagementModel.currentOnboardingRoles;
