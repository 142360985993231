import React from 'react';
import cx from 'classnames';
import { Button, Divider, Popup } from 'semantic-ui-react';
import Header from '../../common/drawer/components/Header';
import PrimaryDrawer from '../../common/drawer/components/PrimaryDrawer';
import Item from '../../common/drawer/components/Item';
import Footer from '../../common/drawer/components/Footer';
import { TransactionDrawerDetails, TransactionSecondaryDrawer } from '../../common/transactions/TransactionsDrawerDetails';
import TransactionLabel from '../../common/transactions/TransactionLabel';
import { DiscountRequestModel } from '../../../store/transactionscommon/types';
import RestrictedByRole from '../../teammanagement/RestrictedByRole';
import { RECIPIENT_TEAM_MANAGEMENT_USER_ROLES } from '../../../store/teammanagement/constants';

interface RemittanceTransactionsDrawerProps {
  selectedTransaction: DiscountRequestModel;
  nextEnabled: boolean;
  prevEnabled: boolean;
  hasVerifiedFundingSource: boolean;
  handleNext: () => void;
  handlePrevious: () => void;
  handleClose: () => void;
  handleCorrect: () => void;
  handleReverse: () => void;
}

export const RemittanceTransactionsDrawer: React.FunctionComponent<RemittanceTransactionsDrawerProps> = (props) => {

  const { selectedTransaction, nextEnabled, prevEnabled, hasVerifiedFundingSource } = props;

  const hasSecondaryDrawer = selectedTransaction !== null && selectedTransaction.statusText !== 'Failed' && !!(selectedTransaction.originalDiscountRequestId || selectedTransaction.reverseDiscountRequestId);
  const isAdminAllAccess = false;
  return (
    <PrimaryDrawer
      isOpen={true}
      closeDrawer={props.handleClose}
      onClickOutside={props.handleClose}
      hasSecondaryDrawer={hasSecondaryDrawer}
      secondaryDrawer={<TransactionSecondaryDrawer transaction={selectedTransaction} />}>

      <div className={cx('drawer-layout', { 'drawer-shadow': hasSecondaryDrawer })}>
        <Header
          header={selectedTransaction.ndcName}
          subHeader={'PRODUCT NAME'}
          isPrevDisabled={!prevEnabled}
          isNextDisabled={!nextEnabled}
          onPrevTransactionClick={props.handlePrevious}
          onNextTransactionClick={props.handleNext}
          onClose={props.handleClose}
          headerLabel={<TransactionLabel statusText={selectedTransaction.statusText} isAdjusted={selectedTransaction.isAdjusted} />}
        />
        <div className='drawer-content'>
          {
            selectedTransaction.statusText === 'Failed' &&
            selectedTransaction.failureReasons.map((reason: any) =>
              <div className='recipient-transaction-drawer-failure-msg' key={reason}>
                <div className='zeta failure-code'>
                  <strong>{reason.code}</strong>
                </div>
                <div className='eta failure-message'>
                  {reason.message}
                </div>
              </div>
            )
          }
          <div className='drawer-body'>
            <TransactionDrawerDetails transaction={selectedTransaction} />
            <Divider />
            <div>
              <div className='flex-row justify-space-between'>
                <Item label={selectedTransaction.originalDiscountRequestId ? `REVERSAL\nTRANSACTION ID` : 'TRANSACTION ID'} value={selectedTransaction.id} />
                <Item label={selectedTransaction.originalDiscountRequestId ? 'REVERSAL\nSUBMITTED BY' : 'SUBMITTED BY'} value={selectedTransaction.createdBy} />
              </div>
            </div>

          </div>
          <Footer>
            <RestrictedByRole allowedRoles={[RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.ADMIN, RECIPIENT_TEAM_MANAGEMENT_USER_ROLES.SUBMITTER]}>
              {!selectedTransaction.reverseDiscountRequestId && !selectedTransaction.originalDiscountRequestId &&
                <div className="flex-row action-buttons-container justify-center">
                  <div className={cx('flex-row action-buttons-container-btn-row', { 'justify-space-between': selectedTransaction.statusText !== 'Failed' }, { 'justify-center': selectedTransaction.statusText === 'Failed' })}>
                    {selectedTransaction.statusText !== 'Failed' &&
                      <Popup
                        position='top center'
                        size='small'
                        inverted
                        disabled={hasVerifiedFundingSource}
                        content={'Discount Requests cannot be reversed until a bank account has been linked and verified'}
                        trigger={
                          <Button basic id='disReqReverse_Pendo'
                            onClick={props.handleReverse}
                            disabled={!hasVerifiedFundingSource || isAdminAllAccess}>
                            {'Reverse'}
                          </Button>
                        }
                      />
                    }
                    <Popup
                      position='top center'
                      size='small'
                      inverted
                      disabled={hasVerifiedFundingSource}
                      content={'Discount Requests cannot be corrected until a bank account has been linked and verified'}
                      trigger={
                        <Button basic id='disReqCorrectAndResbumit_Pendo'
                          onClick={props.handleCorrect}
                          disabled={!hasVerifiedFundingSource || isAdminAllAccess}>
                          {'Correct & Resubmit'}
                        </Button>
                      }
                    />
                  </div>
                </div>
              }
              {selectedTransaction.reverseDiscountRequestId && !selectedTransaction.originalDiscountRequestId &&
                <div className="flex-row action-buttons-container justify-center">
                  <div className="flex-row justify-center action-buttons-container-btn-row">
                    <Popup
                      position='top center'
                      size='small'
                      inverted
                      content={'Correct and Resubmit is not available for this transaction as it has already been reversed'}
                      trigger={
                        <div className='flex-row recipient-transaction-drawer-footer-container'>
                          <Button basic disabled className="drawer-btn">{'Correct & Resubmit'}</Button>
                        </div>
                      }
                    />
                  </div>
                </div>
              }
            </RestrictedByRole>
          </Footer>
        </div>
      </div>
    </PrimaryDrawer>
  )

}

export default RemittanceTransactionsDrawer;
