import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import {
  API_PAGE_SIZE,
  GET_REQUEST_CENTER_TRANSACTION_COUNTS,
  GET_REQUEST_CENTER_TRANSACTIONS,
  REQUEST_CENTER_BUCKET,
  REQUEST_CENTER_TAB,
  UI_PAGE_SIZE,
  SUBMIT_DISCOUNT_REQUEST,
  GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES,
  GET_DISCOUNT_REQUEST_CONTRACT_FIELDS,
  CORRECT_DISCOUNT_REQUEST,
} from './constants';
import {
  getDiscountRequestProgramsAndCoveredEntitiesFailure,
  getDiscountRequestProgramsAndCoveredEntitiesSuccess,
  getDiscountRequestContractFieldsFailure,
  getDiscountRequestContractFieldsSuccess,
  getRequestCenterTransactionCountsFailure,
  getRequestCenterTransactionCountsSuccess,
  getRequestCenterTransactionsFailure,
  getRequestCenterTransactionsSuccess,
  submitDiscountRequestFailure,
  submitDiscountRequestSuccess,
  correctDiscountRequestFailure,
  correctDiscountRequestSuccess,
} from './actionCreators';
import {
  CoveredEntityModel,
  DiscountProgramFieldsResponse,
  DiscountRequestSubmitResponseModel,
  RequestCenterTransactionCountsModel,
  RequestCenterTransactionsFilters,
  RequestCenterTransactionsModel,
} from './types';
import { fetchRecipientTransactions, fetchRecipientTransactionCounts } from '../../api/requestcenter/RecipientTransactions';
import { RootState } from '../RootState';
import { getApiPageToFetch } from '../../utils/datatableHelper';
import { getActiveHealthSystemId } from '../teammanagement/selectors';
import logRocketUtil from '../../utils/logRocketUtil';
import { DiscountRequestResponseModel, TransactionsFilterModel } from '../transactionscommon/types';
import { addDiscountRequestAdditionalFields } from '../transactionscommon/sagas';
import { correctDiscountRequest, fetchAssociatedEntities, fetchDiscountProgramFields, submitDiscountRequest } from '../../api/discountrequests/DiscountRequests';

const getRequestCenterTransactionsState = (state: RootState) => state.RequestCenterState.transactions;
const getRequestCenterFiltersState = (state: RootState) => state.RequestCenterState.filters;

const getRequestCenterDateFiltersQueryString = (tab: REQUEST_CENTER_TAB, filter: TransactionsFilterModel) => {

  if (tab === REQUEST_CENTER_TAB.ALL || tab === REQUEST_CENTER_TAB.PROCESSED) {

    const startDateUTCString = new Date(filter.startDate).toUTCString();
    const startDateUTC = new Date(startDateUTCString);

    const endDateUTCString = new Date(filter.endDate).toUTCString();
    const endDateUTC = new Date(endDateUTCString);

    const dateFilter = [{ 'field': 'submittedDate', 'operator': 'gte', 'value': startDateUTC }, { 'field': 'submittedDate', 'operator': 'lte', 'value': endDateUTC }]
    return dateFilter.map((obj: any) => `Filters=${JSON.stringify(obj)}`).join('&');
  }

  return '';
}

const getRequestCenterTableFiltersQueryString = (tab: REQUEST_CENTER_TAB, bucket: REQUEST_CENTER_BUCKET, filter: TransactionsFilterModel) => {
  const filtersToSend: any = [];

  if (bucket !== REQUEST_CENTER_BUCKET.NONE && bucket !== REQUEST_CENTER_BUCKET.ALL) {
    if (bucket === REQUEST_CENTER_BUCKET.SUBMITTED) {
      filtersToSend.push({ 'field': 'statusText', 'operator': 'eq', 'value': 'Submitted' });
    } else if (bucket === REQUEST_CENTER_BUCKET.IN_MFR_REVIEW) {
      filtersToSend.push({ 'field': 'statusText', 'operator': 'eq', 'value': 'In Mfr Review' });
    } else if (bucket === REQUEST_CENTER_BUCKET.FAILED) {
      filtersToSend.push({ 'field': 'statusText', 'operator': 'eq', 'value': 'Failed' });
    } else if (bucket === REQUEST_CENTER_BUCKET.APPROVED_AND_PAID) {
      filtersToSend.push({ 'field': 'isAdjusted', 'operator': 'eq', 'value': false }, { 'field': 'statusText', 'operator': 'eq', 'value': 'Paid' });
    } else if (bucket === REQUEST_CENTER_BUCKET.ADJUSTED_AND_PAID) {
      filtersToSend.push({ 'field': 'isAdjusted', 'operator': 'eq', 'value': true }, { 'field': 'statusText', 'operator': 'eq', 'value': 'Paid' });
    }
  } else {
    if (tab === REQUEST_CENTER_TAB.ALL) {
      filtersToSend.push({ 'field': 'statusText', 'operator': 'ne', 'value': 'Pending Reversal' }, { 'field': 'statusText', 'operator': 'ne', 'value': 'Reversed' }, { 'field': 'statusText', 'operator': 'ne', 'value': 'Null Reversal' });
    } else if (tab === REQUEST_CENTER_TAB.AWAITING) {
      filtersToSend.push({ 'field': 'statusText', 'operator': 'in', 'value': 'Submitted,In Mfr Review' });
    } else if (tab === REQUEST_CENTER_TAB.PROCESSING) {
      filtersToSend.push({ 'field': 'statusText', 'operator': 'eq', 'value': 'Processing' });
    } else if (tab === REQUEST_CENTER_TAB.PROCESSED) {
      filtersToSend.push({ 'field': 'statusText', 'operator': 'in', 'value': 'Paid,Failed' });
    }
  }

  Object.keys(filter).forEach(key => {
    const val = (filter as { [key: string]: any })[key];
    if (Array.isArray(val)) {
      filtersToSend.push({ 'field': key, 'operator': 'in', 'value': val.join() })
    } else if (val) {
      if (key !== 'startDate' && key !== 'endDate') {
        if (key === 'fillStartDate' || key === 'fillEndDate') {
          const dateUTCString = new Date(val).toUTCString();
          const dateUTC = new Date(dateUTCString);
          const operator = key === 'fillStartDate' ? 'gte' : 'lte';

          filtersToSend.push({ 'field': 'fillDate', 'operator': operator, 'value': dateUTC })
        } else {
          filtersToSend.push({ 'field': key, 'operator': 'like', 'value': val })
        }
      }
    }
  });

  return filtersToSend.map((obj: any) => `Filters=${JSON.stringify(obj)}`).join('&');

}

export function* getRequestCenterTransactionsCountsSaga (action: any) {
  try {
    const tab = action.payload as REQUEST_CENTER_TAB;
    const activeHealthSystemId: number = yield select(getActiveHealthSystemId);
    const filtersState: RequestCenterTransactionsFilters = yield select(getRequestCenterFiltersState);

    const filterQueryString = getRequestCenterDateFiltersQueryString(tab, filtersState[tab])

    const response: RequestCenterTransactionCountsModel = yield call(fetchRecipientTransactionCounts, filterQueryString, activeHealthSystemId);

    yield put(getRequestCenterTransactionCountsSuccess(response));
  } catch (e) {
    yield put(getRequestCenterTransactionCountsFailure(logRocketUtil(e)));
  }
}

export function* getRequestCenterTransactionsSaga (action: any) {
  try {
    const tab: REQUEST_CENTER_TAB = action.payload;
    const activeHealthSystemId: number = yield select(getActiveHealthSystemId);
    const filtersState: RequestCenterTransactionsFilters = yield select(getRequestCenterFiltersState);

    const { bucket, sortBy, isSortAsc, currentPage }: RequestCenterTransactionsModel = yield select(getRequestCenterTransactionsState);
    const pageToFetch = getApiPageToFetch(currentPage, UI_PAGE_SIZE, API_PAGE_SIZE);

    const dateFilterQueryString = getRequestCenterDateFiltersQueryString(tab, filtersState[tab]);
    const tableFilterQueryString = getRequestCenterTableFiltersQueryString(tab, bucket, filtersState[tab])
    const filters = `${tableFilterQueryString}&${dateFilterQueryString}`;

    const response: DiscountRequestResponseModel = yield call(fetchRecipientTransactions, filters, sortBy, isSortAsc, pageToFetch, activeHealthSystemId);
    response.discountRequests = yield call(addDiscountRequestAdditionalFields, response.discountRequests);

    yield put(getRequestCenterTransactionsSuccess(response));
  } catch (e) {
    yield put(getRequestCenterTransactionsFailure(logRocketUtil(e)));
  }
}

export function* getDiscountRequestCoveredEntitiesSaga () {
  try {
    const healthSystemId: number = yield select(getActiveHealthSystemId);

    // const { discountPrograms, coveredEntities }: { discountPrograms: DiscountProgramModel[], coveredEntities: CoveredEntityModel[] } =
    //   yield all({
    //     discountPrograms: call(fetchDiscountPrograms, healthSystemId),
    //     coveredEntities: call(fetchAssociatedEntities, 'idCode', false, healthSystemId)
    //   });

    const coveredEntities: CoveredEntityModel[] = yield call(fetchAssociatedEntities, 'idCode', false, healthSystemId)

    yield put(getDiscountRequestProgramsAndCoveredEntitiesSuccess([], coveredEntities));
  }
  catch (e) {
    yield put(getDiscountRequestProgramsAndCoveredEntitiesFailure(logRocketUtil(e)));
  }
}

export function* getDiscountRequestContractFieldsSaga (action: any) {
  try {
    const response: DiscountProgramFieldsResponse = yield call(fetchDiscountProgramFields, action.payload);
    const fields = response.elements.filter(item => item.name !== 'discountProgram' && item.name !== 'fillDate' && item.name !== 'ndc11');
    yield put(getDiscountRequestContractFieldsSuccess(fields));
  }
  catch (e) {
    const error = logRocketUtil(e);

    //todo: should this go in the API?
    if (error && error.statusCode === 404) {
      error.message = 'NDC11 and Fill Date combination is not eligible for discount request.'
    }

    yield put(getDiscountRequestContractFieldsFailure(error));
  }
}

export function* submitDiscountRequestSaga (action: any) {
  try {
    const activeHealthSystemId: number = yield select(getActiveHealthSystemId);

    const response: DiscountRequestSubmitResponseModel[] = yield call(submitDiscountRequest, action.payload, activeHealthSystemId);

    if (response[0].operationResult === 200) {
      yield put(submitDiscountRequestSuccess(response));
    } else {
      yield put(submitDiscountRequestFailure(response[0].errors));
    }

  } catch (e) {
    const err = logRocketUtil(e);
    yield put(submitDiscountRequestFailure([err.message]));
  }
}

export function* correctDiscountRequestSaga (action: any) {
  try {
    const activeHealthSystemId: number = yield select(getActiveHealthSystemId);

    const response: DiscountRequestSubmitResponseModel[] = yield call(correctDiscountRequest, action.payload.id, action.payload.fields, activeHealthSystemId);
    const transaction = response.find(r => r.additionalInformation['ResultType'] === 'Submitted')!;

    if (transaction.operationResult === 200) {
      yield put(correctDiscountRequestSuccess(response));
    } else {
      yield put(correctDiscountRequestFailure(transaction.errors));
    }

  } catch (e) {
    const err = logRocketUtil(e);
    yield put(correctDiscountRequestFailure([err.message]));
  }
}

function* watchGetRequestCenterTransactionsCountsSaga () {
  yield takeEvery(GET_REQUEST_CENTER_TRANSACTION_COUNTS, getRequestCenterTransactionsCountsSaga);
}

function* watchGetRequestCenterTransactionsSaga () {
  yield takeEvery(GET_REQUEST_CENTER_TRANSACTIONS, getRequestCenterTransactionsSaga);
}

function* watchGetDiscountRequestCoveredEntitiesSaga () {
  yield takeEvery(GET_DISCOUNT_REQUEST_PROGRAMS_AND_COVERED_ENTITIES, getDiscountRequestCoveredEntitiesSaga);
}

function* watchGetDiscountRequestContractFieldsSaga () {
  yield takeEvery(GET_DISCOUNT_REQUEST_CONTRACT_FIELDS, getDiscountRequestContractFieldsSaga);
}

function* watchSubmitDiscountRequestSaga () {
  yield takeEvery(SUBMIT_DISCOUNT_REQUEST, submitDiscountRequestSaga);
}

function* watchCorrectDiscountRequestSaga () {
  yield takeEvery(CORRECT_DISCOUNT_REQUEST, correctDiscountRequestSaga);
}

export default function* watchAllRequestCenter () {
  yield all([
    watchGetRequestCenterTransactionsCountsSaga(),
    watchGetRequestCenterTransactionsSaga(),
    watchGetDiscountRequestCoveredEntitiesSaga(),
    watchGetDiscountRequestContractFieldsSaga(),
    watchSubmitDiscountRequestSaga(),
    watchCorrectDiscountRequestSaga()
  ])
}
