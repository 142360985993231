import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';
import {  CloseIconAlt } from '../../../themes/kalderos/Common/Icons';
import { removeFundingSource } from '../../../store/payments/common/actionCreators';
import { RootState } from '../../../store/RootState';

interface RemoveFundingSourceModalProps {
  sourceId: number;
  name: string;
  bankName: string;
  isFundingSourcesLoading: boolean;
  handleRemovingFundingSource:(sourceId: number, bankName: string, name: string) => void;
}

interface RemoveFundingSourceModalState {
  isRemoveFundingSourceOpen: boolean;
}

export class RemoveFundingSourceModal extends React.Component<RemoveFundingSourceModalProps, RemoveFundingSourceModalState> {
  constructor (props: Readonly<RemoveFundingSourceModalProps>){
    super(props);
    this.state = {
      isRemoveFundingSourceOpen: false
    }
  }

  handleRemoveFundingSource = (fundingSource: number) => {
    this.setState({ isRemoveFundingSourceOpen : false})
    this.props.handleRemovingFundingSource(fundingSource, this.props.bankName, this.props.name)
  }


  render (){
    const { name, bankName, sourceId, isFundingSourcesLoading } = this.props;
    const { isRemoveFundingSourceOpen } = this.state;
    return (
      <Modal className='remove-funding-source-modal' closeOnDimmerClick={false} open={isRemoveFundingSourceOpen} trigger={<button data-testid="modalTrigger" id='removeBankAccount_Pendo' disabled={isFundingSourcesLoading} onClick={() => this.setState({isRemoveFundingSourceOpen : true})} className='remove-act-btn' type="button">Remove</button>}>
        <div className='flex-column remove-funding-source-modal-container'>
          <div className='flex-row remove-funding-header-container'>
            <div className='beta remove-funding-header'>Remove Funding Source</div>
            <Button data-testid="modalClose" onClick={() => this.setState({ isRemoveFundingSourceOpen : false})}>
              <CloseIconAlt />
            </Button>
          </div>
          <div data-private className='delta remove-funding-modal-message'>{`Are you sure you'd like to remove ${name} ${bankName} as a funding source?`}</div>
          <div className='flex-row remove-funding-btn-container'>
            <Button data-testid="removeModalClose" className='app-button button-white' onClick={() => this.setState({ isRemoveFundingSourceOpen: false})}>No, do not remove</Button>
            <Button data-testid="removeOnSubmit"  onClick={() => this.handleRemoveFundingSource(sourceId)} className='app-button button-red'>Yes, remove</Button>
          </div>
        </div>
      </Modal>
    )
  }

}

const mapStateToProps = (state: RootState) => ({
  isFundingSourcesLoading: state.PaymentsState.common.paymentSettings.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  handleRemovingFundingSource: (sourceId: number, bankName: string, name: string) => dispatch(removeFundingSource(sourceId, bankName, name))
});

export default connect( mapStateToProps,  mapDispatchToProps )(RemoveFundingSourceModal);
