import React from 'react'
import { Icon } from 'semantic-ui-react'
import { SortingFilter } from '../../store/appcommon/types';

interface SortIconProps {
  field: string,
  handleCellSorting: (sort: SortingFilter) => void;
  isDisabled?: boolean;
}

const SortIconSet: React.FunctionComponent<SortIconProps> = ({ field, handleCellSorting, isDisabled }: SortIconProps) => {

  const onSortAsc = () => {
    handleCellSorting({ sortBy: field, isSortAsc: true });
  }

  const onSortDesc = () => {
    handleCellSorting({ sortBy: field, isSortAsc: false });
  }

  return (
    <div className="sort-icon-container" >
      <Icon
        onClick={onSortAsc}
        className="sort-caret-up"
        name="caret up"
        id='sortingUp_Pendo'
        disabled={isDisabled}
      />
      <Icon
        onClick={onSortDesc}
        className="sort-caret-down"
        name="caret down"
        id='sortingDown_Pendo'
        disabled={isDisabled}
      />
    </div>
  )
}

export default SortIconSet;
