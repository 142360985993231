import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { clearUserDetails } from '../../store/login/actionCreators';
import { ClearAllItemFromLocalStore } from '../../localstore/LocalStore';
import { ZendeskAPI } from 'react-zendesk';

interface LogoutProps {
  clearToken: boolean;
  clearUserDetails: () => void;
}

export class Logout extends React.Component<LogoutProps>{

  componentDidMount () {
    this.props.clearUserDetails();
    ZendeskAPI('webWidget', 'close');
    if (this.props.clearToken) {
      ClearAllItemFromLocalStore();
    }
  }

  render () {
    return (
      <Redirect to="/" />
    )
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearUserDetails: () => { dispatch(clearUserDetails()) }
  }
}

export default connect(null, mapDispatchToProps)(Logout);
