import RequestConfig from '../../app.config';
import fetchWrapper from '../FetchWrapper';
import { createUrlParametersString } from '../../utils/requestUtil';
import { API_PAGE_SIZE } from '../../store/applicationConstants';
import { commonResponseHandler, RESPONSE_TYPE } from '../../utils/responseUtils';


export const fetchRecipientPaymentHistory = async (sortBy: boolean, isSortDesc: boolean, pageToFetch: number, healthSystemId: number) => {
  const urlParams = createUrlParametersString({ SortField: sortBy, SortDesc: isSortDesc, pageSize: API_PAGE_SIZE, page: pageToFetch, healthSystemId: healthSystemId })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/remittance/payments?${urlParams}`, true)
  return commonResponseHandler(response, RESPONSE_TYPE.JSON)
}


export const fetchRecipientCreditsPerManufacturer = async (sortBy: string, isSortDesc: boolean, pageToFetch: number, healthSystemId: number) => {
  const urlParams = createUrlParametersString({ SortField: sortBy, SortDesc: isSortDesc, pageSize: API_PAGE_SIZE, page: pageToFetch, healthSystemId: healthSystemId })
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/remittance/creditsPerManufacturer?${urlParams}`, true)
  return commonResponseHandler(response, RESPONSE_TYPE.JSON)
}

export const retryPayments = async (healthSystemId: number) => {
  const response = await fetchWrapper(`${await RequestConfig()}/request/v1/customer/retryPayments?healthSystemId=${healthSystemId}`, true,
    {
      method: 'POST',
    }
  );
  return commonResponseHandler(response, RESPONSE_TYPE.TEXT);
}
