import { createSelector } from 'reselect';
import { RootState } from '../RootState';
import { getPagedData } from '../../utils/datatableHelper';
import { API_PAGE_SIZE, UI_PAGE_SIZE } from './constants';

const selectTransactions = (state: RootState) => state.RequestCenterState.transactions.transactions;
const selectCurrentPage = (state: RootState) => state.RequestCenterState.transactions.currentPage;

export const isRequestCenterTransactionsLoading = (state: RootState) => state.RequestCenterState.transactions.isLoading || state.RequestCenterState.counts.isLoading || state.TransactionsCommon.reversalsModal.isLoading;

export const getPagedRequestCenterTransactions = createSelector(
  [selectTransactions, selectCurrentPage],
  (transactions, currentPage) => {
    const pagedTransactions = getPagedData(transactions, currentPage, UI_PAGE_SIZE, API_PAGE_SIZE);
    return pagedTransactions
  }
)