import { AdjustReasonsModel, LegalDocumentsModel, StateModel } from './types';
import { ErrorModel } from '../applicationTypes';

export const GET_STATE_LIST = 'GET_STATE_LIST'
export const GET_STATE_LIST_SUCCESS = 'GET_STATE_LIST_SUCCESS'
export const GET_STATE_LIST_FAILURE = 'GET_STATE_LIST_FAILURE'

export const GET_LEGAL_DOCS = 'GET_LEGAL_DOCS'
export const GET_LEGAL_DOCS_SUCCESS = 'GET_LEGAL_DOCS_SUCCESS'
export const GET_LEGAL_DOCS_FAILURE = 'GET_LEGAL_DOCS_FAILURE'

export const TOGGLE_LEFT_NAVIGATION = 'TOGGLE_LEFT_NAVIGATION'

export const NO_FUNDING_SOURCES_HEADER = 'No Funding Sources'
export const NO_FUNDING_SOURCES_MESSAGE = 'You currently do not have any pending or active funding sources.'

export const DR_NO_LINKED_ACCOUNTS_HEADER = 'No Linked Accounts'
export const DR_NO_LINKED_ACCOUNTS_MESSAGE = 'You currently do not have any active linked accounts.'

export const DISCOUNT_PER_UNIT_TOOL_TIP_MESSAGE = 'Discount/Unit was calculated based on applicable ceiling or sub-ceiling contract price.';
export const DISCOUNT_PER_UNIT_ENTER_DATA_TOOL_TIP_MESSAGE = 'If left blank, Discount/Unit will be calculated from WAC (or GPO) minus the 340B contract price';
export const DISCOUNT_PER_UNIT_REVIEW_AND_SUBMIT_TOOL_TIP_MESSAGE = 'Discount/Unit will be calculated from WAC (or GPO) minus the 340B contract price.';
export const DISCOUNT_PER_UNIT_GPO_TOOL_TIP_MESSAGE = 'Discount/Unit is calculated from the GPO minus 340B Contract price';
export const DISCOUNT_PER_UNIT_CHECKBOX_TOOL_TIP_MESSAGE = 'If you don’t know the Discount/Unit, please select this box and it will be calculated for you.';

export const GPO_OVERRIDE_TOOLTIP = 'Discount/Unit was calculated from the GPO minus the 340B contract price.';

export const GET_340B_IDS = 'GET_340B_IDS'
export const GET_340B_IDS_SUCCESS = 'GET_340B_IDS_SUCCESS'
export const GET_340B_IDS_FAILURE = 'GET_340B_IDS_FAILURE'

export const GET_ADJUST_REASONS = 'GET_ADJUST_REASONS';
export const GET_ADJUST_REASONS_SUCCESS = 'GET_ADJUST_REASONS_SUCCESS';
export const GET_ADJUST_REASONS_FAILURE = 'GET_ADJUST_REASONS_FAILURE';

interface GetStateListAction {
  type: typeof GET_STATE_LIST
}

interface GetStateListSuccessAction {
  type: typeof GET_STATE_LIST_SUCCESS,
  payload: StateModel[]
}

interface GetStateListFailureAction {
  type: typeof GET_STATE_LIST_FAILURE,
  payload: ErrorModel
}

interface GetLegalDocsAction {
  type: typeof GET_LEGAL_DOCS
}

interface GetLegalDocsSuccessAction {
  type: typeof GET_LEGAL_DOCS_SUCCESS,
  payload: LegalDocumentsModel
}

interface GetLegalDocsFailureAction {
  type: typeof GET_LEGAL_DOCS_FAILURE,
  payload: ErrorModel
}

interface ReportToggleLeftNavigation {
  type: typeof TOGGLE_LEFT_NAVIGATION
  payload: boolean
}

interface Get340bIdsAction {
  type: typeof GET_340B_IDS
}

interface Get340bIdsSuccessAction {
  type: typeof GET_340B_IDS_SUCCESS,
  payload: any
}

interface Get340bIdsFailureAction {
  type: typeof GET_340B_IDS_FAILURE,
  payload: ErrorModel
}

interface GetAdjustReasonsAction {
  type: typeof GET_ADJUST_REASONS
}

interface GetAdjustReasonsSuccessAction {
  type: typeof GET_ADJUST_REASONS_SUCCESS,
  payload: AdjustReasonsModel
}

interface GetAdjustReasonsFailureAction {
  type: typeof GET_ADJUST_REASONS_FAILURE,
  payload: ErrorModel,
}

export type AppCommonActionTypes =
  GetStateListAction |
  GetStateListSuccessAction |
  GetStateListFailureAction |
  GetLegalDocsAction |
  GetLegalDocsSuccessAction |
  GetLegalDocsFailureAction |
  ReportToggleLeftNavigation |
  Get340bIdsAction |
  Get340bIdsSuccessAction |
  Get340bIdsFailureAction |
  GetAdjustReasonsAction |
  GetAdjustReasonsSuccessAction |
  GetAdjustReasonsFailureAction;